export class Collegecontact {
    ID:number;
    CLIENT_ID:number;
    COLLEGE_ID:number
    NAME:string;
    EMAIL_ID:string
    MOBILE_NUMBER:string
    PASSWORD:string
    IS_ACTIVE:boolean=true
    DESIGNATION_ID:number
    ROLE_IDS:number[]=[];
    ROLES:number[]=[];
}
