import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addbundlassessmentmap',
  templateUrl: './addbundlassessmentmap.component.html',
  styleUrls: ['./addbundlassessmentmap.component.css']
})
export class AddbundlassessmentmapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
