import { Component, OnInit, Input } from '@angular/core';
import { Collegetype } from 'src/app/Models/CollegeOnboarding/collegetype';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-collegetype',
  templateUrl: './collegetype.component.html',
  styleUrls: ['./collegetype.component.css']
})
export class CollegetypeComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Collegetype;
  @Input() dataList;

  isSpinning = false
  logtext:string = "";
  userId = sessionStorage.getItem('userId');

  constructor(public api: ApiService,public api2: ShikuyaaapiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
  }

  // close(): void {
  //   this.drawerClose();

	// this.logtext = 'CLOSED - Collegetype form';
	// this.api2.addLog('A',this.logtext,this.api2.emailId)
  //         .subscribe(successCode => {
  //           if (successCode['code'] == "200") {
  //            console.log(successCode);
  //           }
  //           else {
	// 	console.log(successCode);
  //           }
  //         });

  // }

  ////

  resetDrawer(collegeTypeMasterPage: NgForm) {
    this.data=new Collegetype();
    // collegeTypeMasterPage.form.reset();

    collegeTypeMasterPage.form.markAsPristine();
    collegeTypeMasterPage.form.markAsUntouched();
  }

  close(collegeTypeMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(collegeTypeMasterPage);
    this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
  }
  //save
  save(addNew: boolean,collegeTypeMasterPage: NgForm): void {

if(this.data.NAME!=undefined && this.data.NAME!="")
{
   if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
  {

    if(this.data.DESCRIPTION=="")
    this.data.DESCRIPTION=" "

    if (this.data.ID) {

      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
      });
  
      if(filterData.length>0)
      {
      this.message.error("Already Record Present in Database", "");
      }
      else
      {
        this.isSpinning = true;
        this.api2.updateCollegetype(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Collegetype information updated Successfully...", "");

	this.logtext = 'Update & Close - Collegetype form - SUCCESS '+ JSON.stringify(this.data)+" KEYWORD [U - Collegetype ]";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

	this.logtext = 'Update & Close - Collegetype form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [U - Collegetype ]";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              this.message.error("Failed to update collegetype information...", "");
              this.isSpinning = false;
            }
          });
      }

     
      }
      else {

     
        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
        });
  
       if(filterData.length>0)
       {
        this.message.error("Already Record Present in Database", "");
       }
       else
       {
              this.isSpinning = true;
        this.api2.createCollegetype(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Collegetype information added successfully...", "");

              if (!addNew)
              {
                this.drawerClose();

	this.logtext = 'Save & Close - Collegetype form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - Collegetype ]";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
        }
              else {
                this.data = new Collegetype();
                this.resetDrawer(collegeTypeMasterPage);
                this.setValues()
                this.api2.getAllCollegetypes(0, 0, 'ID', 'desc', '').subscribe(data => {
                  this.dataList = data['data'];
                }, err => {
                  console.log(err);
                });
	this.logtext = 'Save & New - Collegetype form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - Collegetype ]";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add collegetype information...", "");
	this.isSpinning = false;
	this.logtext = 'Save & Close - Collegetype form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [C - Collegetype ]";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
              
            }
          });
       }
      }
  }
 else
{
  this.message.error("Please Check Name", "");

}
   
    }
    else
    {
      this.message.error("Please Fill All Required Fields", "");
    }
  }
  setValues() {
    this.data.IS_ACTIVE = true
    
  }

}
