import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Documenttype } from 'src/app/Models/CollegeOnboarding/documenttype';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-documenttypes',
  templateUrl: './documenttypes.component.html',
  styleUrls: ['./documenttypes.component.css']
})
export class DocumenttypesComponent implements OnInit {

  formTitle = "Manage Document Types";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];

  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";

  columns: string[][] = [["NAME", "Name"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Documenttype = new Documenttype();
  pageSize2=10

  constructor(public api: ApiService, private cookie: CookieService) { }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else
    {
    this.search();

    this.logtext = "OPENED - DocumentTypes form KEYWORD[O - DocumentTypes] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    }
  }
  
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - DocumentTypes form "+ sort +" "+this.sortKey +" KEYWORD [F - DocumentTypes] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);

      this.logtext = "Filter Applied - DocumentTypes form "+ likeQuery +" KEYWORD [F - DocumentTypes] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }

  

    this.api.getAllDocumentTypes(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });

    this.api.getAllDocumentTypes(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.dataList1 = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Document Type";
    this.drawerData = new Documenttype();
    this.drawerVisible = true;
this.setValues()
    this.logtext = "ADD - DocumentType form KEYWORD [A - DocumentType] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  setValues()
  {
    this.drawerData.IS_ACTIVE=true
  }



  edit(data: Documenttype): void {

    this.drawerTitle = "Update Document Type";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    this.logtext = "EDIT - DocumentType form KEYWORD [E - DocumentType] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });


  }
  drawerClose(): void {
    this.search();
    this.setValues()
    this.drawerVisible = false;
  }
}