import { Component, OnInit, Input } from '@angular/core';
import { Mappedassignment } from 'src/app/Models/AssignmentModule/mappedassignment';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Mappedassignment;
  formTitle="Result"
  searchText:string=""
  dataList=[]
  loadingRecords=false
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  sortValue: string = "desc";
  sortKey: string = "ID";
  logtext:string = "";
  ASSIGNMENT_ID:number
  columns: string[][] =[['STARTED_TIME','Started Time'],['COMPLETED_TIME','Completed Time'],['CONTRAVENTION_COUNT','Contravation Count'],['RESUME_COUNT','Resume Count'],['MARKS','Marks'],['PERCENTAGE','Percentage']]
  columns1: string[][] =[['F_NAME','Started Time'],['M_NAME','Started Time'],['L_NAME','Started Time'],['STARTED_TIME','Started Time'],['COMPLETED_TIME','Completed Time'],['CONTRAVENTION_COUNT','Contravation Count'],['RESUME_COUNT','Resume Count'],['MARKS','Marks'],['PERCENTAGE','Percentage']]
  string1="&#39;<-Single quote,double quote-> &#34;&#92;(ax^2 + bx + c = 0&#92;)&#34;&#39;"

  constructor(public api: ApiService) { }

  ngOnInit() {
  }
  
  getFullName(fname,mname, lname)
  {
    var fname1= fname==null?"":fname
    var mname1=mname==null?"":mname 
    var lname=lname==null?"":lname
    return fname1 +" "+ mname1 +" "+ lname
  }

  getTestStatus(value)
  {
if(value=='S')
return "Started"
else if(value=='V')
return "Violated"
else if(value=='T')
return "Terminated"
else
return "Completed"
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);

  }


  getassignmentId(assignmentId)
  {
this.ASSIGNMENT_ID=assignmentId
this.search()
  }

  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
	
	this.logtext = "Filter Applied - CurrentStatusOfAssignment form "+ sort +" "+this.sortKey +" KEYWORD [F - CurrentStatusOfAssignment] ";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND MAPPED_ASSIGNMENT_MASTER_ID="+this.ASSIGNMENT_ID+ " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) +")"
    }
    else
    {
      likeQuery=" AND MAPPED_ASSIGNMENT_MASTER_ID="+this.ASSIGNMENT_ID
    // likeQuery=""
    }

	this.logtext = "Filter Applied - CurrentStatusOfAssignment form "+ likeQuery +" KEYWORD [F - CurrentStatusOfAssignment] ";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

     console.log(likeQuery)
     
    this.api.getResultAssignment(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      console.log("data")
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList)
    }, err => {
      console.log(err);
    });
  }

}
