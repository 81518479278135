export class AssessmentChapterMaster {
    ID:number=0;
    CLIENT_ID:number=0;
    PARENT_ID:number;
    DEPENDED_CHAPTER_ID:number=0;
    SEQUENCE_NO:number=0;
    NAME:string='';
    DESCRIPTION:string='';
    THUMBNAIL_URL:string='';
    IS_LEAF:boolean=false;
    IS_DEPEND:boolean=false;
    IS_ACTIVE:boolean=true 

}

 