import { Component, OnInit,Input } from '@angular/core';
import { Branch } from 'src/app/Models/BatchMgmt/branch';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Branch;
  @Input() dataList;

  isSpinning = false
  logtext:string = "";
  userId=sessionStorage.getItem('userId');
  isOk = false
  constructor(public api: ApiService, public api2: ShikuyaaapiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
  }
  


  // close(): void {
  //   this.drawerClose();

	// this.logtext = 'CLOSED - Branch form';
	// this.api2.addLog('A',this.logtext,this.api2.emailId)
  //         .subscribe(successCode => {
  //           if (successCode['code'] == "200") {
  //            console.log(successCode);
  //           }
  //           else {
	// 	console.log(successCode);
  //           }
  //         });

  // }

  // save(addNew: boolean): void {

   

    
  //   if(this.data.NAME!=undefined && this.data.NAME!="" && this.data.ABBREVATION!=undefined && this.data.ABBREVATION!="")
  //   {

      
  //     if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
  //     {
  //       if(this.data.DESCRIPTION=="")
  //       this.data.DESCRIPTION=" "
  //       if (this.data.ID) {

  //         var filterData= this.dataList.filter(object => {
  //           return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
  //         });
      
  //         if(filterData.length>0)
  //         {
  //         this.message.error("Already Record Present in Database", "");
  //         }
  //         else
  //         {
  //           this.isSpinning = true;
  //           this.api2.updateBranch(this.data)
  //             .subscribe(successCode => {
  //               if (successCode['code'] == "200") {
  //                 this.message.success("Branch information updated Successfully...", "");
    
  //     this.logtext = 'Update & Close - Branch form - SUCCESS '+ JSON.stringify(this.data)+" KEYWORD [U - Branch ]";
  //     this.api2.addLog('A',this.logtext,this.api2.emailId)
  //             .subscribe(successCode => {
  //               if (successCode['code'] == "200") {
  //                console.log(successCode);
  //               }
  //               else {
  //       console.log(successCode);
  //               }
  //             });
    
  //                 if (!addNew)
  //                   this.drawerClose();
  //                 this.isSpinning = false;
  //               }
  //               else {
    
  //     this.logtext = 'Update & Close - Branch form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [U - Branch ]";
  //     this.api2.addLog('A',this.logtext,this.api2.emailId)
  //             .subscribe(successCode => {
  //               if (successCode['code'] == "200") {
  //                console.log(successCode);
  //               }
  //               else {
  //       console.log(successCode);
  //               }
  //             });
    
  //                 this.message.error("Failed to update branch information...", "");
  //                 this.isSpinning = false;
  //               }
  //             });
  //         }


         
  //       }
  //       else {

  //         var filterData= this.dataList.filter(object => {
  //           return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
  //         });
    
  //        if(filterData.length>0)
  //        {
  //         this.message.error("Already Record Present in Database", "");
  //        }
  //        else
  //        {
  //         this.isSpinning = true;
  
  //         this.api2.createBranch(this.data)
  //           .subscribe(successCode => {
  //             if (successCode['code'] == "200") {
  //               this.message.success("Branch information added successfully...", "");
  
  //               if (!addNew){
  //                 this.drawerClose();
  
  //   this.logtext = 'Save & Close - Branch form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - Branch ]";
  //   this.api2.addLog('A',this.logtext,this.api2.emailId)
  //           .subscribe(successCode => {
  //             if (successCode['code'] == "200") {
  //              console.log(successCode);
  //             }
  //             else {
  //     console.log(successCode);
  //             }
  //           });
  //         }
  //               else {
  //                 this.data = new Branch();
  //                 this.setValues()
  //                 this.api2.getAllBranches(0, 0, 'ID', 'desc', '').subscribe(data => {
  //                   this.dataList = data['data'];
  //                 }, err => {
  //                   console.log(err);
  //                 });
  //   this.logtext = 'Save & New - Branch form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - Branch ]";
  //   this.api2.addLog('A',this.logtext,this.api2.emailId)
  //           .subscribe(successCode => {
  //             if (successCode['code'] == "200") {
  //              console.log(successCode);
  //             }
  //             else {
  //     console.log(successCode);
  //             }
  //           });
  
  //               }
  //               this.isSpinning = false;
  //             }
  //             else {
  //               this.message.error("Failed to add branch information...", "");
  //   this.isSpinning = false;
  //   this.logtext = 'Save & Close - Branch form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [C - Branch ]";
  //   this.api2.addLog('A',this.logtext,this.api2.emailId)
  //           .subscribe(successCode => {
  //             if (successCode['code'] == "200") {
  //              console.log(successCode);
  //             }
  //             else {
  //     console.log(successCode);
  //             }
  //           });
                
  //             }
  //           });
  //        }

          
  //       }
  //     }
  //     else
  //     {
  //       this.message.error("Please Check Name", "");
  //     }

     
  //   }
  //   else
  //   {
  //     this.message.error("Please Fill All Required Fields", "");
  //   }
  
  // }
   ////

   resetDrawer(branchMasterPage: NgForm) {
    this.data=new Branch();
    this.data.IS_ACTIVE = true
    // branchMasterPage.form.reset();
    branchMasterPage.form.markAsPristine();
    branchMasterPage.form.markAsUntouched();
    
  }

  close(branchMasterPage: NgForm) {
    this.drawerClose();
    this.logtext = 'CLOSED - Branch form';
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
    this.resetDrawer(branchMasterPage);
    branchMasterPage.form.reset();

  }
  //save
  save(addNew: boolean,branchMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk=true;

    if (this.data.NAME.trim()=="" &&  this.data.ABBREVATION.trim()==''){
      this.isOk =false 
     this.message.error(" Please Fill All Required Fields", ""); 
    }else

    if(this.data.NAME.trim()=='' || this.data.NAME == null){
      this.isOk =false
      this.message.error('Please Enter Branch Name','')
    }else

    if(this.data.ABBREVATION.trim()=='' || this.data.ABBREVATION == null){
      this.isOk =false
      this.message.error('Please Enter Abbrevation ','')
    // }else 

    // if(this.data.DESCRIPTION.trim()=='' || this.data.DESCRIPTION == null){
    //  this.isOk =false
    //  this.message.error('Please Enter Description','')
}
if(this.isOk)
{
  this.isSpinning=false; 
  // this.data.LATITUDE = this.latitude.toString();
  // this.data.LONGITUDE   = this.longitude.toString();
  this.isSpinning=true; 
if(this.data.ID)
{
    this.api2.updateBranch(this.data)
    .subscribe(successCode => {
      if(successCode['code']=="200")
      {
        this.message.success(' Information updated successfully...', '');
        if(!addNew)
          this.drawerClose();
          this.isSpinning = false;
      }   
      else
      {
        this.message.error(' Failed to update information...', '');
        this.isSpinning = false;
      }
    });
  }
  else{
  
      this.api2.createBranch(this.data)
      .subscribe(successCode => {
        if(successCode['code']=="200")
        {
          this.message.success(' Information save successfully...', '');
          if(!addNew)
         this.drawerClose();
            else
            {
              this.data=new Branch();
              this.resetDrawer(branchMasterPage);
              // this.data.img= '';
              
              this.api2.getAllStudentBranches(1,1,'','desc','').subscribe (data =>{
                // if (data['count']==0){
                //   this.data.SEQUENCE_NO=1;
                // }else
                // {
                //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                // }
              },err=>{
                console.log(err);
              })
            }
            this.isSpinning = false;
          }
           else
           {
            this.message.error(' Failed to save information...', '');
            this.isSpinning = false;
           }
          });
        }
}

// else
// {
//   this.message.error("Please Fill All Required Fields...","");
//   this.isSpinning = false;
// }
  }
  setValues()
  {
    this.data.IS_ACTIVE=true
  }



}
