import { Component, OnInit, ViewChild } from '@angular/core';
import { Section } from 'src/app/Models/MasterSyllabus/section';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { SectionComponent } from '../section/section.component';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit {

  formTitle = "Manage Sections";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  imageurl: any

  columns: string[][] = [["PART_NAME", "Part Name"], ["NAME", "Name"], ["WEIGHTAGE", "Weightage"], ["SEQUENCE_NO", "Sequence No"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Section = new Section();
  @ViewChild(SectionComponent, { static: false }) sectoinVar: SectionComponent;

  constructor(public api: ApiService, private cookie: CookieService) { }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.search();
      this.logtext = "OPENED - Sections form KEYWORD[O - Sections] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);

  }
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Sections form " + sort + " " + this.sortKey + " KEYWORD [F - Sections] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);

      this.logtext = "Filter Applied - Sections form " + likeQuery + " KEYWORD [F - Sections] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }



    this.api.getAllSections(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Section";
    this.drawerData = new Section();
    this.drawerVisible = true;
    this.sectoinVar.reset()
    this.setValue()
    this.logtext = "ADD - Section form KEYWORD [A - Section] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  setValue() {
    this.drawerData.IS_ACTIVE = true;
    this.drawerData.COLOR_CODE = "#ffffff"
    this.drawerData.DESCRIPTION = " "
  }

  edit(data: Section): void {
    this.sectoinVar.reset()

    if (data.ICON_URL != "" && data.ICON_URL != " ") {
      this.sectoinVar.isSpinning = true

      let TYPED_ARRAY
      this.api.getFile(data.ICON_URL).subscribe(data => {
        console.log(data)
        TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        this.imageurl = 'data:image/jpg;base64,' + base64String;
        this.drawerData.ICON_URL_CONVERTED = this.imageurl
        this.sectoinVar.isSpinning = false

      }, err => {
        console.log(err);
      });
    }
    this.drawerTitle = "Update Section";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    this.logtext = "EDIT - Section form KEYWORD [E - Section] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}