import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { Video } from 'src/app/Models/ShikuyaaModels/video';
import { Blockss } from 'src/app/Models/ShikuyaaWebsite/Masters/blockss';
import { ApiService } from 'src/app/Service/api.service';
// import { threadId } from 'worker_threads';
import { VideoquestionsComponent } from '../../VideoQue/videoquestions/videoquestions.component';
import { VideoComponent } from '../video/video.component';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  @Input() drawerCloseapp: Function;
  @Input() data: Blockss;
  formTitle = "Manage Videos";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";

  columns: string[][] = [["CHAPTER_NAME", "Chapter Name"], ["NAME", "Name"], ["URL_KEY", "Video Key"], ["SEQ_NO", "Seq. No."]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Video = new Video();

  //drawer Variables
  drawerVisibleVideoQuestion: boolean;
  drawerTitleVideoQuestion: string;
  @ViewChild(VideoquestionsComponent, { static: false }) videoQuestions: VideoquestionsComponent;

  @ViewChild(VideoComponent, { static: false }) videovar: VideoComponent;
  imageurl: any
  filterValue =''
  filterClass: string = "filter-invisible";
  ChaptersNodes = []
  isloadSpinning = false
  dataList1 = []
  pageSize2 = 10

  constructor(public api: ApiService, private message: NzNotificationService) { }
  CHAPTER_ID=0
  courseID=0
  COURSE_ID=0
  chapters = []
  isSpinning:boolean
  levels: Level[]
  loadchapters(courseId) {

    this.isSpinning = true;
    this.api.getCombinedShikuyaaChapters2(courseId,true).subscribe(localName => {
      this.ChaptersNodes = localName['data'];
      console.log(this.chapters)
       this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }
  ChangeCourse(courseId)
  {

    this.loadchapters(courseId)
  }
  levels1
  loadingLevels = false;
  loadCategories()
  {
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log("courses")
      // console.log(this.levels)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
  }
  loadCourses(event:any) {

    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+event).subscribe(localName => {
      this.levels = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  ngOnInit() {
    this.search();
    this.loadCategories()

    this.logtext = 'OPENED - Videos form KEYWORD[O - Videos] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  onKeyDownEvent(event)
  {
    if(event.key=='Enter')
    {
      event.preventDefault()
    }
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - Videos form"+ sort +" "+this.sortKey +" KEYWORD [F - Videos] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.logtext = 'Filter Applied - Videos form "+ likeQuery +" KEYWORD [F - Videos] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
   
    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery


    console.log("filter in video")

    console.log(filter)

    this.isloadSpinning = true
    this.api.getAllVideoss(this.pageIndex, this.pageSize, this.sortKey, sort, filter,this.COURSE_ID,this.courseID,Number(this.filterValue)).subscribe(data => {
      this.isloadSpinning = false
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.dataList1 = data['data'];
    }, err => {
      console.log(err);
    });

  }

  loadAllChapters() {
    this.api.getAllShikyaaChapterMultiselect().subscribe(localName => {
      this.ChaptersNodes = localName['data'];
      console.log("chapter multi slect")
      console.log(this.ChaptersNodes)
    }, err => {
      console.log(err);
    });
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.checks=''
    this.checks1=''
    this.drawerTitle = "Create New Video";
    this.drawerData = new Video();
    this.drawerVisible = true;
    this.videovar.reset()

    this.logtext = 'ADD - Video form KEYWORD [A - Video] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
      this.api.getAllVideos(0, 0, '', '', '').subscribe(data => {
        this.isloadSpinning = false
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }, err => {
        console.log(err);
      });
      if( this.totalRecords>0)
      {
        this.drawerData.SEQ_NO =  this.totalRecords
        this.drawerData.SEQ_NO = Number( this.totalRecords)+1
      }
      else
      {
        this.drawerData.SEQ_NO = 1
      }
  }
  courseName
  catName
  checks
  checks1
  edit(data: Video): void {
    console.log(data)
    this.checks='edit'
    this.checks1='edit'
    
    this.videovar.reset()
    this.videovar.loadCategories()
    this.videovar.loadchapters(data.COURSE_ID)
   this.catName=data.COURSE_SUB_CATEGORY_NAME
   this.courseName=data.COURSE_NAME
    this.drawerTitle = "Update Video";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    this.logtext = 'EDIT - Video form KEYWORD [E - Video] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });


  }

  getQuestions(data: Video) {
    this.drawerTitleVideoQuestion = "Question For " + data.NAME;
    this.drawerData = Object.assign({}, data);
    this.drawerVisibleVideoQuestion = true;
    this.videoQuestions.getVideoId(data)
    this.logtext = 'EDIT - VideoQuestion form KEYWORD [V - VideoQuestion] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallbackVideoQuestion() {
    return this.drawerCloseVideoQuestion.bind(this);
  }

  drawerCloseVideoQuestion(): void {
    this.search();
    this.drawerVisibleVideoQuestion = false;
  }

  getSecondToMin(duration) {
    let duration1 = this.api.getSecondsToHms(duration)
    return duration1
  }
  changeStatus(data: Video) {
    console.log(data)
    console.log("ale")
    this.api.updateVideo(data)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode == 200) {
          console.log(successCode)
        }
      },
        errorCode => {
          console.log("error")
        });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
   
    this.loadingRecords = true;
   
    if(  this.courseID!=0 || this.COURSE_ID!=0 || this.filterValue!='')
    {
      this.api.getAllVideoss(this.pageIndex, this.pageSize, this.sortKey, '', '',this.COURSE_ID,this.courseID,Number(this.filterValue)).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.isloadSpinning=false
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.isFilterApplied = "primary";
        this.filterClass = "filter-invisible";
      }, err => {
        console.log(err);
      });
    }
      else if(this.courseID==0 && this.COURSE_ID==0 && this.filterValue=='')
      this.message.error('Please selecte filter','')
    // if (this.filterValue.length > 0) {
    //   this.filterValue.forEach(element => {
    //     element = "" + element;
    //     if (element.startsWith("t")) {
    //       courseTypeFilter += element.replace("t", "") + ",";
    //     }
    //     else if (element.startsWith("c")) {
    //       courseFilter += element.replace("c", "") + ",";
    //     }
    //     else {
    //       chapterFilter += element + ",";
    //     }

    //   });

    //   courseTypeFilter = (courseTypeFilter.length > 0) ? "COURSE_TYPE_ID IN (" + courseTypeFilter.substring(0, courseTypeFilter.length - 1) + ") OR " : "";
    //   courseFilter = (courseFilter.length > 0) ? "COURSE_ID IN (" + courseFilter.substring(0, courseFilter.length - 1) + ") OR " : "";
    //   chapterFilter = (chapterFilter.length > 0) ? "CHAPTER_ID IN (" + chapterFilter.substring(0, chapterFilter.length - 1) + ") OR " : "";

    //   this.filterQuery = " AND (" + courseTypeFilter + courseFilter + chapterFilter;

    //   if (this.filterQuery.length > 6)
    //     this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 3) + ")";
    //   else
    //     this.filterQuery = "";
    //   console.log(this.filterQuery);
    //   this.isFilterApplied = "primary";
    //   this.filterClass = "filter-invisible";
    // }
    // else {
    //   this.isFilterApplied = "default";
    // }
  }

  clearFilter() {
    this.loadingRecords = true;
    this.filterValue = ''
    this.courseID=0
    this.COURSE_ID=0
    this.filterQuery=''
    this.api.getAllVideos(1, 10, '', '', '').subscribe(data => {
      this.isloadSpinning = false
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  
  }


}