import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { College } from 'src/app/Models/CollegeOnboarding/college';

@Component({
  selector: 'app-collegetrainingcoursemapping',
  templateUrl: './collegetrainingcoursemapping.component.html',
  styleUrls: ['./collegetrainingcoursemapping.component.css']
})
export class CollegetrainingcoursemappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: College;
  @Input() collegeTrainingCourseMappingData: string[];
  @Input() drawerVisible: boolean;
  isSpinning = false


  
  constructor(public api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {
  }

  close(): void {
    this.drawerClose();
  }

  save()
  {
    // console.log(this.data.ID)
    // this.isSpinning = true;
    //   this.api.addTrainingCourseMapping(this.data.ID,this.collegeTrainingCourseMappingData)
    //   .subscribe(successCode => {
    //     console.log(successCode)
    //     if(successCode['code']==200)
    //     { 
    //         this.message.success("College Training Course Details added Successfully ...", "");
    //         this.drawerClose();
    //         this.isSpinning = false;
    //     }
    //     else
    //     {
    //       this.message.error("College Training Course assigning Failed...", "");
    //       this.isSpinning = false;
    //     }
    //   });
  }
}
