import { Component, OnInit, Input } from '@angular/core';
import { Role } from 'src/app/Models/Commonmodule/role';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-mapquestions',
  templateUrl: './mapquestions.component.html',
  styleUrls: ['./mapquestions.component.css']
})
export class MapquestionsComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Role;
  @Input() questionDetailsData: string[];
  @Input() drawerVisible: boolean;
  isSpinning = false
 
  constructor(public api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {
  }

  close(): void {
    this.drawerClose();
  }

  save()
  {
    this.isSpinning = true;
    console.log(this.data.ID,this.questionDetailsData);
      this.api.addQuestionsToParagraph(this.data.ID,this.questionDetailsData)
      .subscribe(successCode => {
        console.log(successCode)
        if(successCode['code']=="200")
        { 
            this.message.success("Question Details Assigned Successfully ...", "");
            this.drawerClose();
            this.isSpinning = false;
        }
        else
        {
          this.message.error("Question Details assigning Failed...", "");
          this.isSpinning = false;
        }
      });
  }
}

