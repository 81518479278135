import { Component, OnInit, ViewChild } from '@angular/core';
import { MappedassignmentComponent2 } from '../mappedassignment/mappedassignment2.component';
import { ApiService } from 'src/app/Service/api.service';
import { Mappedassignment2 } from 'src/app/Models/AssignmentModule/mappedassignment';
import { CurrentstatusComponent2 } from '../../currentstatus/currentstatus2.component';
import { ResultComponent2 } from '../../result/result2.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-mappedassignments2',
  templateUrl: './mappedassignments2.component.html',
  styleUrls: ['./mappedassignments2.component.css']
})
export class MappedassignmentsComponents2 implements OnInit {

  formTitle = "Manage Mapped Assesments";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  collegeId = sessionStorage.getItem('collegeId')

  logtext: string = "";
  filterClass: string = "filter-invisible";
  MOCK_STATUS  = "U"
  SCOPE = "O"
  ismapSpinning = false
  mappingName: string = ""
  columns: string[][] = [["NAME", "Mapping Name"], ["ENABLE_TIME", "Enable Time"], ['DISABLE_TIME', 'Disable Time'], ["POSITIVE_MARKS", "Positive Marks"], ["NEGATIVE_MARKS", "Negative Marks"], ["TOTAL_QUESTIONS", "Total Questions"]]
  columns1: string[][] = [['COURSE_NAMES','Course Name'],["NAME", "Mapping Name"], ["ENABLE_TIME", "Enable Time"], ['DISABLE_TIME', 'Disable Time'], ["POSITIVE_MARKS", "Positive Marks"], ["NEGATIVE_MARKS", "Negative Marks"], ["TOTAL_QUESTIONS", "Total Questions"]]
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Mappedassignment2 = new Mappedassignment2();
  drawerData2: Mappedassignment2 = new Mappedassignment2();
  drawerData3: Mappedassignment2 = new Mappedassignment2();
  visible = false
  visible1 = false
  visible2 = false

  currentStatusVisible: boolean=false;
  currentStatusTitle: string;
  resultVisible: boolean=false;
  resultTitle: string;

  @ViewChild(MappedassignmentComponent2, { static: false }) assignmentAdd: MappedassignmentComponent2;

  @ViewChild(CurrentstatusComponent2, { static: false }) currentstatus: CurrentstatusComponent2;

  @ViewChild(ResultComponent2, { static: false }) resultShow: ResultComponent2;
  pageSize2 = 10

  constructor(public api: ApiService) { }
  ngOnInit() {
    // if (this.userId == null || this.userName == null || this.roleId == null || this.collegeId == null) {
    //   this.api.logoutForSessionValues()
    // }
    // else {
    this.search();
    this.logtext = "OPENED - Assesments form KEYWORD[O - Assesments] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    // }

  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search(true);
  }

  setToDefault() {
    this.mappingName = ""

  }

  getTotal(timeinseconds) {
    return this.api.getSecondsToHms(timeinseconds)
  }

  check(data) {
    var name = data.MAPPING_NAME;
    if (this.mappingName == name) {
      this.mappingName = name;
      return false;
    }
    else {
      this.mappingName = name;
      return true;
    }

  }

  getMappingNameCount(data, name) {
    return data.filter(object => {
      return object['MAPPING_NAME'] == name;
    });

  }

  onKeyDownEvent(event)
  {if(event.key=='Enter')
this.checkSearch(this.searchText)
  }
  checkSearch(event:any)
  {
    this.api.getAllAssessmentCombinedMappingData(this.pageIndex, this.pageSize, this.sortKey, '',  ' AND MOCK_STATUS = '+this.MOCK_STATUS +event).subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.ismapSpinning = false
      this.filterClass = "filter-invisible";
      this.isFilterApplied = "primary";
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Assesments form " + sort + " " + this.sortKey + " KEYWORD [F - Assesments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery =' ' + "AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
      this.logtext = "Filter Applied - Assesments form " + likeQuery + " KEYWORD [F - Assesments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }
    else {
      likeQuery = ""
    }



    // this.applyFilter()
    // var filter = ""
    // if (likeQuery)
    //   filter = this.filterQuery + likeQuery
    // else

    //   filter = this.filterQuery
    this.visible = false
    this.visible1 = false
    this.visible2 = false
    if (this.MOCK_STATUS  == "'U'")
      this.visible = true
    else if (this.MOCK_STATUS  == "'V'")
      this.visible1 = true
    else if (this.MOCK_STATUS  == "'C'")
      this.visible2 = true

    this.ismapSpinning = true
    this.api.getAllAssessmentCombinedMappingData(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery+" AND MOCK_STATUS = '"+ this.MOCK_STATUS+"'" ).subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.ismapSpinning = false
      this.filterClass = "filter-invisible";
      this.isFilterApplied = "primary";
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.MOCK_STATUS  = "'U'"
    this.SCOPE = "S"
    this.search()
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Assesment";
    this.drawerData = new Mappedassignment2();
    this.assignmentAdd.current = 0
    this.drawerVisible = true;

    this.logtext = "ADD - Assesment form KEYWORD [A - Assesment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  edit(data: Mappedassignment2): void {
    data.RESULT_SHARING_TIME = data.MAPPED_MOCK_MASTER_RESULT_SHARING_TIME
    data.ID = data.MAPPED_MOCK_MASTER_ID
    console.log(data)
    this.drawerTitle = "Update Assesment (" + data.NAME + ")";
    this.drawerData = Object.assign({}, data);


    if (this.drawerData.PLATFORM == 'B')
      this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
      { label: 'Web', value: 'W', checked: true }, { label: 'Desktop', value: 'D', checked: true }]
    else if (this.drawerData.PLATFORM == 'M')
      this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
      { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
    else if (this.drawerData.PLATFORM == 'W')
      this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
      { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
    else
      this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: false },
      { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: true }]
    this.drawerVisible = true;

    this.assignmentAdd.current = 0
    this.assignmentAdd.getDeatilsData(data)
    this.logtext = "EDIT - Assesment form KEYWORD [E - Assesment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }


  mapping(data: Mappedassignment2) {
    this.drawerTitle = "Map Assesment (" + data.NAME + ")";
    this.drawerVisible = true;
    this.assignmentAdd.current = 3
    this.assignmentAdd.NAME = ""
    this.assignmentAdd.assignmentId = data.MAPPED_MOCK_MASTER_ID
    this.assignmentAdd.getDeatilsData(data)
    this.logtext = "Mapping - Assesment form KEYWORD [M - Assesment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  currentStatusClose(): void {
    this.search();
    this.currentStatusVisible = false;
  }
  get currentStatuscloseCallback() {
    return this.currentStatusClose.bind(this);
  }
  currentStatus(data: Mappedassignment2) {
    this.currentStatusTitle = "Current Assesment (" + data.NAME + ")";
    this.drawerData2 = Object.assign({}, data);
    this.currentstatus.getassignmentId(data.MAPPED_MOCK_MASTER_ID)
    this.currentStatusVisible = true
  }
  resultClose(): void {
    this.search();
    this.resultVisible = false;
  }
  get resultcloseCallback() {
    return this.resultClose.bind(this);
  }

  result(data: Mappedassignment2) {
    console.log('Result of Assesment')
    this.resultTitle = "Result of Assesment (" + data.NAME + ")";
    this.drawerData3 = Object.assign({}, data);
    this.resultShow.getassignmentId(data.MAPPED_MOCK_MASTER_ID)
    this.resultVisible = true
  }



}
