export class Shikuyaachapter {
    ID:number;
    CLIENT_ID:number;
    PARENT_ID:number;
    COURSE_ID:number;
    IS_LEAF:boolean=false;
    NAME:string;
    DESCRIPTION:string="";
    THUMBNAIL_URL:string;
    IS_ACTIVE:boolean=true;
    IS_DEPEND:boolean;
    DEPENDED_CHAPTER_ID:number;
    THUMBNAIL_URL_CONVERTED:string
    IS_PRACTICE_FREE:false
    CONTENTS=[]
    SEQUENCE_NO:number=0
    SEQ_NO:number;
    CHAPTER_NAME:string
    PARENT_NAME:String
    COURSE_SUB_CATEGORY_ID:number
    COURSE_SUB_CATEGORY_NAME:string
    COURSE_NAME:string
    
}
