import { ViewChild } from '@angular/core';
import { Component, ElementRef, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { Shikuyaachapter } from 'src/app/Models/ShikuyaaModels/shikuyaachapter';
import { Shikuyaaquestion } from 'src/app/Models/ShikuyaaModels/shikuyaaquestion';
import { ApiService } from 'src/app/Service/api.service';
import * as XLSX from 'xlsx';  
import * as FileSaver from 'file-saver';  
@Component({
  selector: 'app-chapetr-importer',
  templateUrl: './chapetr-importer.component.html',
  styleUrls: ['./chapetr-importer.component.css']
})
export class ChapetrImporterComponent implements OnInit {

  formTitle = "Course Chapter Importer"
  filetxt: File = null
  loadingLevels = false;
  constructor(public api: ApiService, private message: NzNotificationService) { }
  fileContent: string = '';
  levels: Level[]
  subChaptersNodes = []
  questionData = []
  allquestionData = []
  finalJSON = []
  finalJSON1 = []
  dataQuestion1: Shikuyaachapter = new Shikuyaachapter()

  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  isSaveSpinning = false
  diabledButton = true
  logtext: string = ""
  isokfile = true
  id: number
  chapters = []
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;

  ngOnInit() {
    // if (this.userId == null || this.userName == null || this.roleId == null) {
    //   this.api.logoutForSessionValues()
    // }
    // else
    // {
    // this.loadData()

    this.loadData()
    this.loadCourses()
    this.loadCompanies()
  }
  loadCourses() {

    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2").subscribe(localName => {
      this.levels = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  CHAPTER_ID
  courseID
  isSpinning:boolean
  loadchapters(courseId) {
 
    this.isSpinning = true;
    this.api.getCombinedShikuyaaChapters2(courseId,1).subscribe(localName => {
      this.chapters = localName['data'];
      console.log(this.chapters)
       this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }
  ChangeCourse(courseId)
  {
    alert(courseId)
    this.loadchapters(courseId)
  }
  companyVisible:boolean=false
  checkQue(event:any)
  {
if(event=='F')
{
  this.companyVisible=true
}
else{
  this.companyVisible=false
}
  }
  companies=[]
  loadCompanies() {

    
    this.loadingLevels = true;
    this.api.getAllCompanys(0,0,"",""," ").subscribe(localName => {
      this.companies = localName['data'];
      console.log("companies")
      console.log(this.companies)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  loadData() {
    // this.loadingLevels = true;
    // let filter = " AND IS_ACTIVE=1"
    // this.api.getAllLevels(0, 0, 'value', 'asc', filter).subscribe(localName => {
    //   this.levels = localName['data'];
    //   this.loadingLevels = false;
    // }, err => {
    //   console.log(err);
    //   this.loadingLevels = false;
    // });
    // this.api.getCombinedShikuyaaChapters().subscribe(localName => {
    //   this.chapters = localName['data'];
    // }, err => {
    //   console.log(err);

    // });

  }
  
  
  // readAsJson() {  
  //   this.jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });  
  //   this.jsonData = JSON.stringify(this.jsonData);  
  //   const data: Blob = new Blob([this.jsonData], { type: "application/json" });  
  //   FileSaver.saveAs(data, "JsonFile" + new Date().getTime() + '.json');  
  // }  
  // readAsHTML() {  
  //   this.htmlData = XLSX.utils.sheet_to_html(this.worksheet);  
  //   const data: Blob = new Blob([this.htmlData], { type: "text/html;charset=utf-8;" });  
  //   FileSaver.saveAs(data, "HtmlFile" + new Date().getTime() + '.html');  
  // }  
  // readAsText() {  
  //   this.textData = XLSX.utils.sheet_to_txt(this.worksheet);  
  //   const data: Blob = new Blob([this.textData], { type: 'text/plain;charset=utf-8;' });  
  //   FileSaver.saveAs(data, "TextFile" + new Date().getTime() + '.txt');  
  // }  
  arrayBuffer
  filelist
  jsonFormat:any[]=[]
  jsons:any[]=[]
  onFileSelectedTXT(fileList: FileList) {
    this.isokfile = true

    const allowed_types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (!allowed_types.toString().match(fileList[0].type)) {
      this.isokfile = false
      this.message.error("Only Excel File are allowed ( .xlsx)", "");
    }

    if (this.isokfile) {
      this.filetxt = fileList[0];
      this.diabledButton = false
      let fileReader = new FileReader();    
  fileReader.readAsArrayBuffer(this.filetxt);     
  fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});    
      var first_sheet_name = workbook.SheetNames[0];    
      var worksheet = workbook.Sheets[first_sheet_name];    
      console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));    
        var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});     
            this.filelist = [];    
       
            this.jsons=arraylist

    }
  }

  }

  extract() {

    if (this.filetxt) {
     
        this.jsonFormat=this.jsons
        this.questionData = []
        this.finalJSON = []
        let counter = 2;
        let fileData = "";
        let fileReader: FileReader = new FileReader();
        let self = this;
  
  
        fileReader.onloadend = function () {
  
          self.fileContent = fileReader.result as string;
          fileData = self.fileContent
     
  
          fileData = fileData.replace(/\\r/g, '')
          .replace(/\\n/g, '')
          .replace(/\\t/g, '')
          .replace(/\\/g, '&#92;')
          .replace(/\\"/g, '&#34;')
          .replace(/\\'/g, '&#39;')
          .replace(/\[/g, '&#91;')
          .replace(/\]/g, '&#93;');
  
  
          while (fileData != "") {
            let questionObject: Shikuyaachapter = new Shikuyaachapter()
  
            let all = "";
            let is_correct = 0;
            let tempIndex = 0;
            let answer = ""
            let id = (counter - 1).toString();
            if (fileData.indexOf("(" + counter + ")") > 0) {
              tempIndex = fileData.indexOf(")") + 1;
              all = fileData.substring(tempIndex, ((fileData.indexOf("(" + counter + ")") - tempIndex) + tempIndex));
              if (counter >= 10)
                fileData = fileData.substring(all.length + 4);
              else
                fileData = fileData.substring(all.length + 3);
            }
            else {
              all = fileData.substring(fileData.indexOf(")") + 1);
              fileData = "";
            }
  
           
  
          
            let indexOfDescription = all.indexOf("$#description");
  
            let name = ""
            let url_key='' 
            let status 
            let l_type = ""
            let v_type = ""
            let duration 
            let direction = ""
            let thumb_url=''
            let seq
    
  
          
          
                                                                          // all are present
            {
              
              tempIndex = all.indexOf("$#direction") + 11;
              direction = all.substring(tempIndex, ((indexOfDescription - tempIndex) + tempIndex));
  
              direction = all.substring(all.indexOf("$#description") + 13);
  
              counter = counter + 1;
            }
  
            questionObject.ID = counter - 2;
            questionObject.NAME = name.trim()
            questionObject.DESCRIPTION = direction.trim()
            // questionObject.URL_KEY = url_key.trim()
            questionObject.SEQUENCE_NO = seq
            questionObject.THUMBNAIL_URL= thumb_url.trim()
            questionObject.IS_LEAF = duration
            // questionObject.VIDEO_TYPE = v_type.trim()
            // questionObject.TYPE = l_type.trim()
            questionObject.IS_ACTIVE = status
            questionObject.CLIENT_ID = self.api.clientId
            questionObject = Object.assign({}, questionObject)
            self.questionData.push(questionObject)
  
            self.allquestionData = self.questionData
  
          }
        }
        fileReader.readAsText(this.filetxt);
  
      }
      
    else {
      this.message.error("Please Select File...", "");
    }
  }
removed
  deleteRow(data) {

  //   this.jsonFormat.forEach((value,index)=>{
  //     if(this.jsonFormat.length==0)
  //     {
  //       this.message.error('Atleast one record should be present','')
  //     }
  //     else
  //      this.jsonFormat.splice(data,1);

  //  this.jsonFormat=this.jsonFormat    
  // });
  this.removed=this.jsonFormat.splice(data,1);
  // this.jsonFormat=this.jsonFormat    
 this.jsonFormat = this.jsonFormat.filter(object => {
      return object[this.removed] != data
    });
    
   
    // this.jsonFormat.splice(index, 1);
    // this.jsonFormat = this.jsonFormat.filter(object => {
    //   return object[data] != data
    // });
  }
  openWindow()
  {
    window.open('https://docs.google.com/spreadsheets/d/1ji8fTujZ_anO59enE-7JM-NZIrYDvfx7xuf7tPTVVps/edit?usp=sharing')
  }
  cancel()
  {
    this.jsonFormat=[]
    this.CHAPTER_ID=undefined
    this.courseID=''
    this.finalJSON1 = []
    this.jsonFormat = []
    this.CHAPTER_ID=undefined
    this.courseID=undefined
    this.myInputVariable.nativeElement.value = null;
    this.filetxt = null
    this.diabledButton = true
  }
  save() {

    
      var isok = true
     
      if (isok) {

        // this.allquestionData.forEach(element => {
        //   let dataQuestion: Video = new Video()
        //  dataQuestion.NAME=this.dataQuestion1.NAME
        //  dataQuestion.DESCRIPTION=this.dataQuestion1.DESCRIPTION
        //  dataQuestion.URL_KEY=this.dataQuestion1.URL_KEY
        //  dataQuestion.DURATION=this.dataQuestion1.DURATION
        //  dataQuestion.SEQ_NO=this.dataQuestion1.SEQ_NO
        //  dataQuestion.VIDEO_TYPE=this.dataQuestion1.VIDEO_TYPE
        //  dataQuestion.TYPE=this.dataQuestion1.TYPE
        //  dataQuestion.THUMBNAIL_URL=this.dataQuestion1.THUMBNAIL_URL
        //  dataQuestion.IS_ACTIVE=this.dataQuestion1.IS_ACTIVE
        //   dataQuestion.CLIENT_ID = this.api.clientId;
        

          
        //   dataQuestion = Object.assign({}, dataQuestion)
        //   this.finalJSON.push(dataQuestion)

        //   this.finalJSON1 = this.finalJSON

        // });

        console.log('final=',this.jsonFormat)
        this.isSaveSpinning = true
        this.api.courseChapterImporter(this.jsonFormat)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              this.message.success("Chapters Added Successfully ...", "");
              this.finalJSON1 = []
              this.jsonFormat = []
              this.CHAPTER_ID=undefined
              this.courseID=undefined
              this.myInputVariable.nativeElement.value = null;
              this.filetxt = null
              this.diabledButton = true
              this.logtext = "Save - ChaptersImporter form - SUCCESS" + JSON.stringify(this.finalJSON1) + " KEYWORD [C - QuestionImporter] ";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            else {
              this.message.error("Chapters Adding Failed...", "");
              this.finalJSON1 = []
              this.allquestionData = []
              this.logtext = "Save - ChaptersImporter form - ERROR" + JSON.stringify(this.finalJSON1) + " KEYWORD [C - QuestionImporter] ";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
            this.isSaveSpinning = false

          }, err => {
            console.log(err);
            this.message.error("Server Error...", "");
            this.isSaveSpinning = false
          });
      }
      else {
        // this.message.error("Video Url Missing In Your File  ", "");
      }
   
  }


}
