import { Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { Questionparagraph } from 'src/app/Models/MasterSyllabus/questionparagraph';
import { Questiontype } from 'src/app/Models/MasterSyllabus/questiontype';
import { Shikuyaachapter } from 'src/app/Models/ShikuyaaModels/shikuyaachapter';
import { Shikuyaaquestion } from 'src/app/Models/ShikuyaaModels/shikuyaaquestion';
import { ApiService } from 'src/app/Service/api.service';
// import { MathjaxComponent } from 'src/app/pages/mathjax/mathjax.component';
import { DatePipe } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-shikuyaaquestion',
  templateUrl: './shikuyaaquestion.component.html',
  styleUrls: ['./shikuyaaquestion.component.css'],
  providers: [DatePipe]
})
export class ShikuyaaquestionComponent implements OnInit {
 @Input() companyVisible:string
 @Input()   levels1:any
 @Input()   levels2:any
 @Input() checks:any=''
 @Input() checks1:any=''
 @Input() courseName:any

 @Input() catName:any
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter direction here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: ''
  }
  editorConfigDesc: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter description here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: ''
  }
  
  editorConfigOpt: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter option text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: ''
  }
  editorConfigSolution: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter solution here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: ''
  }
  editorConfigQue: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter question here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: ''
  }
  @Input() drawerClose: Function;
  @Input() data: Shikuyaaquestion
  @Input() visibles:any
  COURSE_ID=0
  isSpinning = false
  OptionList = []
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMdd')
  folderNameDesc='QuestionDescriptionImg'
  folderNameQue='QuestionImg'
  folderNameOpt='QuestionOptionImg'
  folderNameSolu='QuestionSolutionImg'
  urlDesc
  urlsDesc
  urlQue
  urlsQue
  urlOpt
  urlsOpt
  urlSolu
  urlsSolu
  questionTypes: Questiontype[]
  levels: Level[]
  paragraphs: Questionparagraph[]
  subChaptersNodes = []
  loadingTypes = false
  loadingLevels = false
  loadingParagraph = false
  questionTypeVisible: boolean
  questionTypeTitle: string = ""
  questionTypeData: Questiontype = new Questiontype()
  folderName='QuestionDirectionImg'
  levelVisible: boolean
  levelTitle: string = ""
  levelsData: Level = new Level()

  OPTION_TEXT = ""
  IS_CORRECT: boolean = false
  SEQUENCE_NO: number = 1
  WEIGHTAGE: number = 0
  i = 2
  visibleType1: boolean
  visibleType2: boolean
  visibleType3: boolean
  visibleCheck1: boolean
  visibleCheck2: boolean
  directionfkey = "8Yxo9YsU"
  descriptionfkey = "91oM5LvP"
  questionfkey = "F16A2Lo7"
  optionfkey = "fcwbO0g2"
  solutionfkey = "wTzHKtFA"
  file_Direction_URL: File = null
  file_Description_URL: File = null
  file_Question_URL: File = null
  file_Solution_URL: File = null
  file_Paragraph_URL: File = null
  file_Options_URL: File = null
  url
  urls
  logtext: string = "";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  urlList = []
  urlVar = {}
  urlOptionList = []
  urlOptionVar = {}

  questionParagraphVisible: boolean;
  questionParagraphTitle: string = ""
  questionParagraphData = new Questionparagraph();
  loadingOptions = false
  iop = 0
  courseNodes=[]


  isokfileDirection = true
  isokfileDescription = true
  isokfileParagraph = true
  isokfileQusetion = true
  isokfileSolutionUrl = true
  isokfileOption = true
  index1 = -1
  imgURL: any;
  public imagePath;
  questionId: number

  chapters: Shikuyaachapter[];
  @ViewChild('direction', { static: false }) myInputVariable: ElementRef;
  @ViewChild('description', { static: false }) myInputVariable1: ElementRef;
  @ViewChild('question', { static: false }) myInputVariable3: ElementRef;
  @ViewChild('solutionimage', { static: false }) myInputVariable4: ElementRef;
  @ViewChild('optionUrl', { static: false }) myInputVariableOption: ElementRef;
  constructor(public api: ApiService, private message: NzNotificationService,private datePipe: DatePipe) { }
  loadchapters(courseId) {
 
    this.isSpinning = true;
    this.api.getParentChaptersNew(courseId).subscribe(localName => {
      this.chapters = localName['data'];
      console.log(this.chapters)
       this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }
  ChangeCourse(courseId)
  {
    // alert(courseId)
    this.loadchapters(courseId)
  }
level2

  loadCategories()
  {
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log('levels',this.levels1 )
      // console.log(this.levels)
      // this.loadingLevels = false;
      // this.data.COURSE_SUB_CATEGORY_ID=this.data.COURSE_SUB_CATEGORY_ID
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
  }

  
  loadCategoriesID(id:any)
  {
    this.api.getCategoryHierarchys(Number(id)).subscribe(localName => {
      this.levels1 = localName['data'];
      console.log('levels',this.levels1 )
      // console.log(this.levels)
      this.loadingLevels = false;
      // this.data.COURSE_SUB_CATEGORY_ID=this.data.COURSE_SUB_CATEGORY_ID
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
  }
  loadCourses(event:any) {

    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+event).subscribe(localName => {
      this.level2 = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      
    
      this.loadQuestionTypes()
      this.loadLevels()
      this.loadQuestionParagraph()
      this.loadCompanies()
      this.loadCats()
     
      // if(this.data.GROUP_TYPE=='F')
      // {
      //   this.companyVisible=true
        
      // }
      // else{
      //   this.companyVisible=false
      // }
      // this.loadSubChpater()
      this.loadCategories()
      
    }
  }

  cats=[]
  loadCats() {

    
    // this.loadingLevels = true;
    this.api.getAllQueCategory(0,0,"",""," ").subscribe(localName => {
      this.cats = localName['data'];
      console.log("companies")
      // console.log(this.companies)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });

  }
  checkQue(event:any)
  {
if(event=='F')
{
  this.companyVisible='true'
}
else{
  this.companyVisible='false'
}
  }
  companies=[]
  loadCompanies() {

    
    this.isSpinning = true;
    this.api.getAllCompanys(0,0,"",""," ").subscribe(localName => {
      this.companies = localName['data'];
      console.log("companies")
      console.log(this.companies)
      this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });

  }
  // loadCourses() {

  //   // this.isSpinning = true;
  //   // this.api.getCombinedCourses().subscribe(localName => {
  //   //   this.courseNodes = localName['data'];
  //   //   console.log("courses")
  //   //   console.log(this.courseNodes)
  //   //   this.isSpinning = false;
  //   // }, err => {
  //   //   console.log(err);
  //   //   this.isSpinning = false;
  //   // });

  //   this.isSpinning = true;
  //   this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2").subscribe(localName => {
  //     this.courseNodes = localName['data'];
  //     console.log("courses")
  //     console.log(this.courseNodes)
  //     this.isSpinning = false;
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });

  // }

  // ChangeCourse(courseId)
  // {
   
  //   this.loadSubChpater(courseId)
  // }

  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.myInputVariable1.nativeElement.value = null;
    this.myInputVariable3.nativeElement.value = null;
    this.myInputVariable4.nativeElement.value = null;

    this.file_Direction_URL = null
    this.file_Description_URL = null
    this.file_Paragraph_URL = null
    this.file_Question_URL = null
    this.file_Solution_URL = null

    this.isokfileDirection = true
    this.isokfileDescription = true
    this.isokfileParagraph = true
    this.isokfileQusetion = true
    this.isokfileSolutionUrl = true
    this.isokfileOption = true
    this.SEQUENCE_NO = 1
    this.OPTION_TEXT = ""
    this.questionId = undefined
  }

  getOptionList(questionId: number) {
    console.log(questionId)
    this.questionId = questionId
    this.loadingOptions = true
    let filter = " AND QUESTION_ID=" + questionId
    this.api.getAllShikuyaaOptions(0, 0, 'SEQUENCE_NO', 'asc', filter)
      .subscribe(successCode => {

        console.log("option data", successCode['data'])
        this.OptionList = successCode['data']
        console.log(this.OptionList)
        this.loadingOptions = false
       

      });
  }

  addNewQuestionType() {
    this.questionTypeVisible = true;
    this.questionTypeTitle = "Create New Question Type";
    this.questionTypeData = new Questiontype();
    this.setValueOfQuestionType()
  }

  questionTypeClose(): void {
    this.loadQuestionTypes()
    this.questionTypeVisible = false;
  }



  get callbackQuestionTypeClose() {
    return this.questionTypeClose.bind(this);
  }

  setValueOfQuestionType() {
    this.questionTypeData.IS_ACTIVE = true
    this.questionTypeData.DESCRIPTION = ""
  }

  loadQuestionTypes() {
    this.loadingTypes = true;
    let filter = " AND IS_ACTIVE=1"
    this.api.getAllQuestionTypes(0, 0, 'ID', 'desc', filter).subscribe(localName => {
      console.log(localName)
      this.questionTypes = localName['data'];
      this.data.QUESTION_TYPE_ID = localName['data'][0]['ID']
      this.loadingTypes = false;
    }, err => {
      console.log(err);
      this.loadingTypes = false;
    });
  }

  addNewLevel() {
    this.levelVisible = true;
    this.levelTitle = "Create New Difficulty Level";
    this.levelsData = new Level();
    this.setValueOfLevel()

  }

  levelClose(): void {
    this.loadLevels()
    this.levelVisible = false;
  }

  get callbackLevelClose() {
    return this.levelClose.bind(this);
  }

  setValueOfLevel() {
    this.levelsData.IS_ACTIVE = true
  }



  loadLevels() {
    this.loadingLevels = true;
    let filter = " AND IS_ACTIVE=1"
    this.api.getAllLevels(0, 0, 'value', 'asc', filter).subscribe(localName => {
      this.levels = localName['data'];
      this.data.LEVEL_ID = localName['data'][0]['ID']
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });
  }

  loadQuestionParagraph() {
    this.loadingParagraph = true;

    this.api.getAllShikuyaaParagraphs(0, 0, 'ID', 'desc', "").subscribe(localName => {
      this.paragraphs = localName['data'];
      //   if(data)
      // this.data.PARAGRAPH_ID = localName['data'][0]['ID']
      this.loadingParagraph = false;
    }, err => {
      console.log(err);
      this.loadingParagraph = false;
    });
  }

  addNewQuestionParagraph() {
    this.questionParagraphVisible = true;
    this.questionParagraphTitle = "Create New Question Paragraph";
    this.questionParagraphData = new Questionparagraph();
  }

  questionParagraphClose(): void {
    this.loadQuestionParagraph()
    this.questionParagraphVisible = false;
  }

  get callbackquestionParagraphClose() {
    return this.questionParagraphClose.bind(this);
  }
  back()
  {
    this.checks=''
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+this.data.COURSE_SUB_CATEGORY_ID).subscribe(localName => {
      this.level2 = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  back1()
  {
    this.checks1=''
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log('levels',this.levels1 )
      // console.log(this.levels)
      // this.loadingLevels = false;
      // this.data.COURSE_SUB_CATEGORY_ID=this.data.COURSE_SUB_CATEGORY_ID
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });

  }
  loadSubChpater(courseId) {
this.checks='edit'
    this.isSpinning = true;
    this.api.getCombinedShikuyaaChapters2(courseId,0).subscribe(localName => {
      this.chapters = localName['data'];
      console.log(this.chapters)
      this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });


  }

  

  typeChange(value) {
    console.log(value)
    this.visibleType1 = false
    this.visibleType2 = false
    this.visibleType3 = false
    this.visibleCheck1 = false
    this.visibleCheck2 = false
    this.OPTION_TEXT = ""
    this.OptionList = []


    switch (value) {
      case 1:
        this.visibleType1 = true
        this.visibleCheck1 = true
        break;
      case 2:
        this.visibleType1 = true
        this.visibleCheck2 = true
        break;
      case 3:
        this.visibleType1 = true
        this.visibleType3 = true
        this.visibleCheck2 = true
        break;
    }

    // this.myInputVariableOption.nativeElement.value = null;
    // this.file_Options_URL=null
  }
  genarateDirectionTHUMBNAIL_URL() {
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Direction_URL.name.split('.').pop();
    var url = this.date1 + number + "." + fileExt
    this.api.onUpload(this.folderName, this.file_Direction_URL, url).subscribe(successCode => {
      if (successCode['code'] == "200") {
       console.log(successCode);
      }
      else {
  console.log(successCode);
      }});
    this.data.DIRECTION_IMG = url;
    console.log("url=",this.data.DIRECTION_IMG)
    return this.data.DIRECTION_IMG
  }
 
  onFileSelectedDIRECTION(event) {
   
  
    this.isokfileDirection = true
    //console.log(event)
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    
    const isLt2M = event.target.files[0].size <= 10485760;
  
  
    //console.log(" size" + isLt2M)
  
    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isokfileDirection = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {                          
          // this.showing=false
        }, 5000);
       
      }
      if (this.isokfileDirection) {
        this.file_Direction_URL = <File>event.target.files[0];
        if (this.file_Direction_URL) {
       
            var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.file_Direction_URL.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.urls=this.date1+number+"."+fileExt
            console.log("url=",this.urls)
            console.log(this.file_Direction_URL)
            this.api.onUploadnew(this.folderName, this.file_Direction_URL, this.urls).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.data.DIRECTION_IMG =   this.urls;
               // this.nextProcess(addNew);
               //console.log('url=',this.imgUrl)
              //  localStorage.setItem('profile_url',this.imgUrl)
              //  this.showImage=false
              //  this.submit()
        
              }
            });
  
         
      }
    }
    else {
      // this.myInputVariable.nativeElement.value = null;
      if (!isLt2M){
        // this.showing1=true
        setTimeout(() => {                          
          // this.showing1=false
        }, 5000);
       
      }
       
  
  
    }
  
  }
  } 

  
  // img for description
  onFileSelectedDESCRIPTION(event) {
    this.isokfileDescription = true
    //console.log(event)
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg']
    
    const isLt2M = event.target.files[0].size <= 10485760;
  
  
    //console.log(" size" + isLt2M)
  
    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isokfileDescription = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {                          
          // this.showing=false
        }, 5000);
       
      }
  
      if (this.isokfileDescription) {
        this.file_Description_URL = <File>event.target.files[0];
        if (this.file_Description_URL) {
       
            var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.file_Description_URL.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.urlDesc=this.date1+number+"."+fileExt
            console.log("urlDesc=",this.urlDesc)
            //console.log(this.fileDataIMAGE)
            this.api.onUploadnew(this.folderNameDesc, this.file_Description_URL, this.urlDesc).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.data.DESCRIPTION_IMG =   this.urlDesc;
               // this.nextProcess(addNew);
               //console.log('url=',this.ATTACHMENT)
               localStorage.setItem('desc_url',this.data.DESCRIPTION_IMG)
              
              //  this.submit()
        
              }
            });
  
         
      }
    }
    else {
      // this.myInputVariable.nativeElement.value = null;
      if (!isLt2M){
      
        setTimeout(() => {                          
         
        }, 5000);
       
      }
       
  
  
    }
  
  }
  } 
  // img for question
  
  // img for option
  onFileSelectedOPTION(event) {
    this.isokfileOption = true
    //console.log(event)
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg']
    
    const isLt2M = event.target.files[0].size <= 10485760;
    
  
    //console.log(" size" + isLt2M)
    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isokfileOption = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {                          
          // this.showing=false
        }, 5000);
       
      }
  
      if (this.isokfileOption) {
        this.file_Options_URL = <File>event.target.files[0];
        if (this.file_Options_URL) {
       
            var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.file_Options_URL.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.urlOpt=this.date1+number+"."+fileExt
            console.log("urlOpt=",this.urlOpt)
            //console.log(this.fileDataIMAGE)
            this.api.onUploadnew(this.folderNameOpt, this.file_Options_URL, this.urlOpt).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.file_Options_URL=this.urlOpt
               
        
              }
            });
  
         
      }
    }
    else {
      // this.myInputVariable.nativeElement.value = null;
      if (!isLt2M){
      
        setTimeout(() => {                          
         
        }, 5000);
       
      }
       
  
  
    }
  
  }
  } 
  // img for solution
  onFileSelectedSOLUTION(event) {
    this.isokfileSolutionUrl = true
    //console.log(event)
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg']
    
    const isLt2M = event.target.files[0].size <= 10485760;
   
  
    //console.log(" size" + isLt2M)
  
    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isokfileSolutionUrl = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {                          
          // this.showing=false
        }, 5000);
      }
  
      if (this.isokfileSolutionUrl) {
        this.file_Solution_URL = <File>event.target.files[0];
        if (this.file_Solution_URL) {
       
            var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.file_Solution_URL.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.urlSolu=this.date1+number+"."+fileExt
            console.log("urlSolu=",this.urlSolu)
            //console.log(this.fileDataIMAGE)
            this.api.onUploadnew(this.folderNameSolu, this.file_Solution_URL, this.urlSolu).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.data.SOLUTION_IMAGE_URL =   this.urlSolu;
               // this.nextProcess(addNew);
               //console.log('url=',this.ATTACHMENT)
              //  localStorage.setItem('desc_url',this.data.OPTIONS)
              
              //  this.submit()
        
              }
            });
  
         
      }
    }
    else {
      // this.myInputVariable.nativeElement.value = null;
      if (!isLt2M){
      
        setTimeout(() => {                          
         
        }, 5000);
       
      }
       
  
  
    }
  
  }
  } 
  // onFileSelectedDirection(event) {

  //   this.isokfileDirection = true
  //   const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  //   if (!allowed_types.toString().match(event.target.files[0].type)) {
  //     this.isokfileDirection = false
  //     this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
  //   }

  //   if (this.isokfileDirection) {
  //     this.file_Direction_URL = <File>event.target.files[0];
  //     this.file_Direction_URL['f_key'] = this.directionfkey
  //     this.file_Direction_URL['url_key'] = "LKEY_DIRECTION_URL"
  //   }

  //   // this.isokfileDirection = true
  //   // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  //   // console.log(event.target.files[0])
  //   // const isLt2M = event.target.files[0].size < 10485760;

  //   // if (isLt2M ) {
  //   //   if (!allowed_types.toString().match(event.target.files[0].type)) {
  //   //     this.isokfileDirection = false
  //   //     this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
  //   //   }

  //   //   if (this.isokfileDirection) {
  //   //       this.file_Direction_URL = <File>event.target.files[0];
  //   //       this.file_Direction_URL['f_key'] = this.directionfkey
  //   //       this.file_Direction_URL['url_key'] = "LKEY_DIRECTION_URL"
  //   //   }
  //   // }
  //   // else {
  //   //   this.myInputVariable.nativeElement.value = null;
  //   //   if(!isLt2M)
  //   //     this.message.error('File Size must be less than 10240kb', '');
  //   // }


  // }

  onFileSelectedDescription(event) {
    this.isokfileDescription = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileDescription = false
      this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    }
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Description_URL.name.split('.').pop();
    // this.urls = 'Profile' + number + "." + fileExt
    this.urlDesc=this.date1+number+"."+fileExt
    console.log("urlSolu=",this.urlSolu)
    //console.log(this.fileDataIMAGE)
    this.api.onUploadnew(this.folderNameDesc, this.file_Description_URL, this.urlDesc).subscribe(res => {
      //console.log(res);
      if (res['code'] == "200") {
        this.data.SOLUTION_IMAGE_URL =   this.urlSolu;
       // this.nextProcess(addNew);
       //console.log('url=',this.ATTACHMENT)
      //  localStorage.setItem('desc_url',this.data.OPTIONS)
      
      //  this.submit()

      }
    });
    // this.isokfileDescription = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // console.log(event.target.files[0])
    // const isLt2M1 = event.target.files[0].size < 10485760;

    // if (isLt2M1) {
    //   if (!allowed_types.toString().match(event.target.files[0].type)) {
    //     this.isokfileDescription = false
    //     this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    //   }

    //   if (this.isokfileDescription) {
    //       this.file_Description_URL = <File>event.target.files[0];
    //   this.file_Description_URL['f_key'] = this.descriptionfkey
    //   this.file_Description_URL['url_key'] = "LKEY_DESCRIPTION_URL"
    //   }
    // }
    // else {
    //   this.myInputVariable1.nativeElement.value = null;
    //   if(!isLt2M1)
    //     this.message.error('File Size must be less than 10240kb', '');
    //       }

  }

  onFileSelectedQUESTION(event) {
    this.isokfileQusetion = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileQusetion = false
      this.message.error("Only Images are allowed In Question ( JPG | PNG | JPEG)", "");
    }

    if (this.isokfileQusetion) {
      this.file_Question_URL = <File>event.target.files[0];
      var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.file_Question_URL.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.urlsQue=this.date1+number+"."+fileExt
            console.log("urlque=",this.urlsQue)
            //console.log(this.fileDataIMAGE)
            this.api.onUploadnew(this.folderNameQue, this.file_Question_URL, this.urlsQue).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.data.QUESTION_URL =   this.urlsQue;
               // this.nextProcess(addNew);
               //console.log('url=',this.ATTACHMENT)
              //  localStorage.setItem('desc_url',this.data.OPTIONS)
              
              //  this.submit()
        
              }
            });
    }

    // this.isokfileQusetion = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // console.log(event.target.files[0])
    // const isLt2M1 = event.target.files[0].size < 10485760;

    // if (isLt2M1) {
    //   if (!allowed_types.toString().match(event.target.files[0].type)) {
    //     this.isokfileQusetion = false
    //     this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    //   }

    //   if (this.isokfileQusetion) {
    //    this.file_Question_URL = <File>event.target.files[0];
    // this.file_Question_URL['f_key'] = this.questionfkey
    // this.file_Question_URL['url_key'] = "LKEY_QUESTION_URL"
    //   }
    // }
    // else {
    //   this.myInputVariable3.nativeElement.value = null;
    //   if(!isLt2M1)
    //     this.message.error('File Size must be less than 10240kb', '');
    //   }



  }

  
  // onFileSelectedParagraph(event) {

  //   this.isokfileParagraph = true
  //   const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  //   if (!allowed_types.toString().match(event.target.files[0].type)) {
  //     this.isokfileParagraph = false
  //     this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
  //   }

  //   if (this.isokfileParagraph) {
  //     this.file_Paragraph_URL = <File>event.target.files[0];
  //     this.file_Paragraph_URL['f_key'] = this.paragraphfkey
  //     this.file_Paragraph_URL['url_key'] = "LKEY_PARAGRAPH_URL"
  //   }   
  // }

  // onFileSelectedOptions(event) {

  //   this.isokfileOption = true
  //   const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  //   if (!allowed_types.toString().match(event.target.files[0].type)) {
  //     this.isokfileOption = false
  //     this.message.error("Only Images are allowed In Option ( JPG | PNG | JPEG)", "");
  //   }
  //   this.imgURL = ""
  //   if (this.isokfileOption) {
  //     this.file_Options_URL = <File>event.target.files[0];
  //     var number = Math.floor(100000 + Math.random() * 900000)
  //     var fileExt = this.file_Options_URL.name.split('.').pop();
  //     // this.urls = 'Profile' + number + "." + fileExt
  //     this.urlOpt=this.date1+number+"."+fileExt
  //     console.log("urlopt=",this.urlOpt)
  //     this.api.onUploadnew(this.folderNameOpt, this.file_Options_URL, this.urlOpt).subscribe(res => {
  //       //console.log(res);
  //       if (res['code'] == "200") {
  //         this.data.OPTIONS =   this.urlOpt;
  //        // this.nextProcess(addNew);
  //        //console.log('url=',this.ATTACHMENT)
  //       //  localStorage.setItem('desc_url',this.data.OPTIONS)
        
  //       //  this.submit()
  
  //       }
  //     });
  //     // this.file_Options_URL = <File>event.target.files[0];
  //     // this.file_Options_URL['f_key'] = this.optionfkey
  //     // this.file_Options_URL['url_key'] = "LKEY_OPTION_URL" + "_" + this.iop
  //     // this.iop++

  //     // var reader = new FileReader();
  //     // this.imagePath = event.target.files;
  //     // reader.readAsDataURL(event.target.files[0]);
  //     // reader.onload = (_event) => {
  //     //   this.imgURL = reader.result;
    

  //   }

  //   // this.isokfileOption = true
  //   // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  //   // console.log(event.target.files[0])
  //   // const isLt2M1 = event.target.files[0].size < 10240;
  //   // const Img = new Image();
  //   // Img.src = URL.createObjectURL(event.target.files[0]);
  //   // Img.onload = (e: any) => {
  //   //   var optionImgHeight = e.path[0].height;
  //   //   var optionImgWeight = e.path[0].width;
  //   //   var optionImgheightwidth:boolean
  //   //   console.log(optionImgHeight, optionImgWeight);

  //   //   if (optionImgHeight == 100 && optionImgWeight == 100)
  //   //   optionImgheightwidth = true
  //   //   else
  //   //   optionImgheightwidth = false

  //   // console.log("diamention match"+ optionImgheightwidth)

  //   // console.log(" size"+isLt2M1)

  //   // if (isLt2M1 && optionImgheightwidth) {
  //   //   if (!allowed_types.toString().match(event.target.files[0].type)) {
  //   //     this.isokfileOption = false
  //   //     this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
  //   //   }

  //   //   if (this.isokfileOption) {
  //   //        this.file_Options_URL = <File>event.target.files[0];
  //   //   this.file_Options_URL['f_key'] = this.optionfkey
  //   //   this.file_Options_URL['url_key'] = "LKEY_OPTION_URL"+"_"+this.iop
  //   // this.iop++

  //   // var reader = new FileReader();
  //   // this.imagePath = event.target.files;
  //   // reader.readAsDataURL(event.target.files[0]); 
  //   // reader.onload = (_event) => { 
  //   //   this.imgURL = reader.result; 
  //   // }
  //   //   }
  //   // }
  //   // else {
  //   //   this.myInputVariableOption.nativeElement.value = null;
  //   //   if(!isLt2M1)
  //   //     this.message.error('File Size must be less than 10240kb', '');

  //   //   if(!optionImgheightwidth)
  //   //     this.message.error('Height and Width must be 100 X 100.', '');
  //   // }

  //   // }

  // }

  addOptions() {
    console.log(this.OptionList)

    if (this.index1 > -1) {
      this.OptionList[this.index1]['OPTION_TEXT'] = this.OPTION_TEXT.replace(/\\r/g, '')
      .replace(/\\n/g, '')
      .replace(/\\t/g, '')
      .replace(/\\/g, '&#92;')
      .replace(/\\"/g, '&#34;')
      .replace(/\\'/g, '&#39;')
      .replace(/\[/g, '&#91;')
      .replace(/\]/g, '&#93;');
      this.OptionList[this.index1]['OPTION_IMAGE_URL'] = this.file_Options_URL
      this.OptionList[this.index1]['URL_KEY'] = this.OptionList[this.index1]['OPTION_IMAGE_URL']['url_key']

      this.index1 = -1
    }
    else {
      var lastElementObject = this.OptionList[this.OptionList.length - 1]

      if (lastElementObject != undefined)
        this.SEQUENCE_NO = lastElementObject['SEQUENCE_NO'] + 1

      if (this.OPTION_TEXT) {
        if (this.isokfileOption) {
          if (this.OptionList.length == 0) {
            this.OptionList = [
              {
                ID: 1,
                OPTION_TEXT: this.OPTION_TEXT.replace(/\\r/g, '')
                .replace(/\\n/g, '')
                .replace(/\\t/g, '')
                .replace(/\\/g, '&#92;')
                .replace(/\\"/g, '&#34;')
                .replace(/\\'/g, '&#39;')
                .replace(/\[/g, '&#91;')
                .replace(/\]/g, '&#93;'),
                OPTION_IMAGE_URL: this.file_Options_URL == null ? "" : this.file_Options_URL,
                URL_KEY: this.file_Options_URL == null ? "" : this.file_Options_URL['url_key'],
                IS_CORRECT: this.IS_CORRECT,
                SEQUENCE_NO: this.SEQUENCE_NO,
                WEIGHTAGE: this.WEIGHTAGE,
                CLIENT_ID: this.api.clientId,
              }
            ];
            this.SEQUENCE_NO++
          }
          else {
            let sameName = this.OptionList.filter(object => {
              return object['OPTION_TEXT'] == this.OPTION_TEXT.replace(/\\r/g, '')
              .replace(/\\n/g, '')
              .replace(/\\t/g, '')
              .replace(/\\/g, '&#92;')
              .replace(/\\"/g, '&#34;')
              .replace(/\\'/g, '&#39;')
              .replace(/\[/g, '&#91;')
              .replace(/\]/g, '&#93;');
            });
            if (sameName.length == 0) {

              this.OptionList = [
                ...this.OptionList,
                {
                  ID: this.i,
                  OPTION_TEXT: this.OPTION_TEXT.replace(/\\r/g, '')
                  .replace(/\\n/g, '')
                  .replace(/\\t/g, '')
                  .replace(/\\/g, '&#92;')
                  .replace(/\\"/g, '&#34;')
                  .replace(/\\'/g, '&#39;')
                  .replace(/\[/g, '&#91;')
                  .replace(/\]/g, '&#93;'),
                  OPTION_IMAGE_URL: this.file_Options_URL == null ? "" : this.file_Options_URL,
                  URL_KEY: this.file_Options_URL == null ? "" : this.file_Options_URL['url_key'],
                  IS_CORRECT: this.IS_CORRECT,
                  SEQUENCE_NO: this.SEQUENCE_NO,
                  WEIGHTAGE: this.WEIGHTAGE,
                  CLIENT_ID: this.api.clientId,
                }
              ];
              this.i++;
              this.SEQUENCE_NO++

            }
            else {
              this.message.error("Same Option Found", "");
            }
          }

        }
        else {
          this.message.error("Only Images are allowed In Option ( JPG | PNG | JPEG)", "");
        }

      }
      else {
        this.message.error("Please Enter Option Name", "");
      }

    }
    this.OPTION_TEXT = ""
    this.myInputVariableOption.nativeElement.value = '';
    this.file_Options_URL = null
    this.isokfileOption = true
    console.log(this.OptionList)

  }

  deleteOption(data) {
    const index = this.OptionList.indexOf(data);
    this.OptionList.splice(index, 1);
    this.OptionList = this.OptionList.filter(object => {
      return object['ID'] != this.data['ID']
    });
  }
  news(event:any)
  {
    // alert(event)
  }
  changeValue() {
    this.OptionList.forEach(element => {
      element['IS_CORRECT'] = false
    });
  }

  editRow(data, i1: number) {
    this.index1 = i1
    this.OPTION_TEXT = data.OPTION_TEXT;
    this.file_Options_URL = data.OPTION_IMAGE_URL

  }

  save(addNew: boolean = false) {

    //  this.isSpinning = true
    //   if(this.OptionList.length>0)
    //   {
    //   this.OptionList.forEach(element => {
    //     if(this.file_Options_URL)
    //       this.pushLkey(element['OPTION_IMAGE_URL'], this.optionfkey, 'OPTION_LKEY'+element['ID'])
    //       else
    //       {
    //       element['OPTION_IMAGE_URL']=""
    //       this.urlOptionVar['OPTION_LKEY'+element['ID']]=""
    //       }
    //   });
    // }
    // this.urlOptionList.push(this.urlOptionVar)
    for(let i=0;i<this.OptionList.length;i++)
    {
  
      if(this.OptionList[i]["IS_CORRECT"]==true)
      {
        this.data.ANSWER_TEXT=this.OptionList[i]['OPTION_TEXT']
        // alert('correct answer text='+this.data.ANSWER_TEXT)
      }
    }
    this.data.QUESTION = this.data.QUESTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');


    if (this.data.DIRECTION == "")
      this.data.DIRECTION = " "
    else if (this.data.DIRECTION != undefined)
      this.data.DIRECTION = this.data.DIRECTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');

    if (this.data.DESCRIPTION == "")
      this.data.DESCRIPTION = " "
    else if (this.data.DESCRIPTION != undefined)
      this.data.DESCRIPTION = this.data.DESCRIPTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');

    if (this.data.SOLUTION == "")
      this.data.SOLUTION = " "
    else if (this.data.SOLUTION != undefined)
      this.data.SOLUTION = this.data.SOLUTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');

    if (this.data.SOLUTION_VIDEO_URL == "")
      this.data.SOLUTION_VIDEO_URL = " "

    this.OptionList.forEach(element => {
      element['OPTION_TEXT'] = element['OPTION_TEXT'].replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');
    });


    if (this.file_Direction_URL)
      this.urlList.push(this.data.DIRECTION_IMG)
    // if (this.file_Description_URL)
    //   this.urlList.push(this.urlDesc)
    
    // if (this.file_Question_URL)
    //   this.urlList.push(this.urlQue)
    // if (this.file_Solution_URL)
    //   this.urlList.push(this.urlSolu)

    console.log("url list")
    console.log(this.urlList)

    if (this.OptionList.length > 0) {

      this.OptionList.forEach(element => {
        if (element['OPTION_IMAGE_URL'] != null && element['OPTION_IMAGE_URL'] != "")
          this.urlList.push(element['OPTION_IMAGE_URL'])
      });
    }


    // if (this.urlList.length > 0) {

    //   if (this.isokfileDirection && this.isokfileDescription && this.isokfileParagraph && this.isokfileQusetion && this.isokfileSolutionUrl) {
    //     this.isSpinning = true
    //     console.log("in add bulk",this.urlList)
    //     this.api.onUploadNewMethodBulk(this.urlList)
    //       .subscribe(successCode => {
    //         console.log(successCode['data'])
    //         if (successCode['code'] == 200) {

    //           console.log(this.urlList.length)
    //           if (this.urlList.length == 1) {
    //             if (this.urlList[0]['url_key'] == "LKEY_DIRECTION_URL")
    //               this.data.DIRECTION_IMG = successCode['data'][0]['L_KEY']

    //             if (this.urlList[0]['url_key'] == "LKEY_DESCRIPTION_URL")
    //               this.data.DESCRIPTION_IMG = successCode['data'][0]['L_KEY']

    //             if (this.urlList[0]['url_key'] == "LKEY_QUESTION_URL")
    //               this.data.QUESTION_URL = successCode['data'][0]['L_KEY']

    //             if (this.urlList[0]['url_key'] == "LKEY_SOLUTION_URL")
    //               this.data.SOLUTION_IMAGE_URL = successCode['data'][0]['L_KEY']
    //           }
    //           else {
    //             this.data.DIRECTION_IMG = successCode['data']['LKEY_DIRECTION_URL']
    //             this.data.DESCRIPTION_IMG = successCode['data']['LKEY_DESCRIPTION_URL']
    //             this.data.QUESTION_URL = successCode['data']['LKEY_QUESTION_URL']
    //             this.data.SOLUTION_IMAGE_URL = successCode['data']['LKEY_SOLUTION_URL']


    //             Object.keys(successCode['data']).forEach(key => {
    //               console.log(key)
    //               this.OptionList.forEach(element => {
    //                 if (element['OPTION_IMAGE_URL'] != null) {

    //                   if (element['URL_KEY'] == key) {

    //                     element['OPTION_IMAGE_URL'] = successCode['data'][key]
    //                   }
    //                 }
    //               });
    //             });
    //           }



    //           if (this.data.ID)
    //             this.updateData(false)
    //           else
    //             this.updateData(addNew)
    //         }
    //         else {
    //           console.log(successCode)
    //         }
    //       });
    //   }
    //   else {
    //     if (this.isokfileDirection == false)
    //       this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileDescription == false)
    //       this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileQusetion == false)
    //       this.message.error("Only Images are allowed In Qusetion ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileParagraph == false)
    //       this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileSolutionUrl == false)
    //       this.message.error("Only Images are allowed In solution ( JPG | PNG | JPEG)", "");

    //   }
    // }
   
      console.log("in out")
      if (this.isokfileDirection && this.isokfileDescription && this.isokfileParagraph && this.isokfileQusetion ) {
        this.isSpinning = true

        if (this.data.ID)
          this.updateData(false)
        else
          this.updateData(addNew)
      }
      else {
        if (this.isokfileDirection == false)
          this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
        if (this.isokfileDescription == false)
          this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
        if (this.isokfileQusetion == false)
          this.message.error("Only Images are allowed In Qusetion ( JPG | PNG | JPEG)", "");
        if (this.isokfileParagraph == false)
          this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
        if (this.isokfileSolutionUrl == false)
          this.message.error("Only Images are allowed In solution ( JPG | PNG | JPEG)", "");

      }
    

  }

  save1(addNew: boolean = false) {

    //  this.isSpinning = true
    //   if(this.OptionList.length>0)
    //   {
    //   this.OptionList.forEach(element => {
    //     if(this.file_Options_URL)
    //       this.pushLkey(element['OPTION_IMAGE_URL'], this.optionfkey, 'OPTION_LKEY'+element['ID'])
    //       else
    //       {
    //       element['OPTION_IMAGE_URL']=""
    //       this.urlOptionVar['OPTION_LKEY'+element['ID']]=""
    //       }
    //   });
    // }
    // this.urlOptionList.push(this.urlOptionVar)
    // for(let i=0;i<=this.OptionList.length;i++)
    // {
    //   if(this.OptionList[i]['IS_CORRECT']==true)
    //   {
    //     this.data.ANSWER_TEXT=this.OptionList[i]['OPTION_TEXT']
     
    //   }
    // }
    this.data.QUESTION = this.data.QUESTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');


    if (this.data.DIRECTION == "")
      this.data.DIRECTION = " "
    else if (this.data.DIRECTION != undefined)
      this.data.DIRECTION = this.data.DIRECTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');

    if (this.data.DESCRIPTION == "")
      this.data.DESCRIPTION = " "
    else if (this.data.DESCRIPTION != undefined)
      this.data.DESCRIPTION = this.data.DESCRIPTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');

    if (this.data.SOLUTION == "")
      this.data.SOLUTION = " "
    else if (this.data.SOLUTION != undefined)
      this.data.SOLUTION = this.data.SOLUTION.replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');

    if (this.data.SOLUTION_VIDEO_URL == "")
      this.data.SOLUTION_VIDEO_URL = " "

    this.OptionList.forEach(element => {
      element['OPTION_TEXT'] = element['OPTION_TEXT'].replace(/\\r/g, '')
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\/g, '&#92;')
        .replace(/\\"/g, '&#34;')
        .replace(/\\'/g, '&#39;')
        .replace(/\[/g, '&#91;')
        .replace(/\]/g, '&#93;');
    });


    if (this.file_Direction_URL)
      this.urlList.push(this.data.DIRECTION_IMG)
    // if (this.file_Description_URL)
    //   this.urlList.push(this.urlDesc)
    
    // if (this.file_Question_URL)
    //   this.urlList.push(this.urlQue)
    // if (this.file_Solution_URL)
    //   this.urlList.push(this.urlSolu)

    console.log("url list")
    console.log(this.urlList)

    if (this.OptionList.length > 0) {

      this.OptionList.forEach(element => {
        if (element['OPTION_IMAGE_URL'] != null && element['OPTION_IMAGE_URL'] != "")
          this.urlList.push(element['OPTION_IMAGE_URL'])
      });
    }


    // if (this.urlList.length > 0) {

    //   if (this.isokfileDirection && this.isokfileDescription && this.isokfileParagraph && this.isokfileQusetion && this.isokfileSolutionUrl) {
    //     this.isSpinning = true
    //     console.log("in add bulk")
    //     // this.api.onUploadNewBulk(this.urlList)
    //     //   .subscribe(successCode => {
    //     //     console.log(successCode['data'])
    //     //     if (successCode['code'] == 200) {

    //     //       console.log(this.urlList.length)
    //     //       if (this.urlList.length == 1) {
    //     //         if (this.urlList[0]['url_key'] == "LKEY_DIRECTION_URL")
    //     //           this.data.DIRECTION_IMG = successCode['data'][0]['L_KEY']

    //     //         if (this.urlList[0]['url_key'] == "LKEY_DESCRIPTION_URL")
    //     //           this.data.DESCRIPTION_IMG = successCode['data'][0]['L_KEY']

    //     //         if (this.urlList[0]['url_key'] == "LKEY_QUESTION_URL")
    //     //           this.data.QUESTION_URL = successCode['data'][0]['L_KEY']

    //     //         if (this.urlList[0]['url_key'] == "LKEY_SOLUTION_URL")
    //     //           this.data.SOLUTION_IMAGE_URL = successCode['data'][0]['L_KEY']
    //     //       }
    //     //       else {
    //     //         this.data.DIRECTION_IMG = successCode['data']['LKEY_DIRECTION_URL']
    //     //         this.data.DESCRIPTION_IMG = successCode['data']['LKEY_DESCRIPTION_URL']
    //     //         this.data.QUESTION_URL = successCode['data']['LKEY_QUESTION_URL']
    //     //         this.data.SOLUTION_IMAGE_URL = successCode['data']['LKEY_SOLUTION_URL']


    //     //         Object.keys(successCode['data']).forEach(key => {
    //     //           console.log(key)
    //     //           this.OptionList.forEach(element => {
    //     //             if (element['OPTION_IMAGE_URL'] != null) {

    //     //               if (element['URL_KEY'] == key) {

    //     //                 element['OPTION_IMAGE_URL'] = successCode['data'][key]
    //     //               }
    //     //             }
    //     //           });
    //     //         });
    //     //       }



    //     //       if (this.data.ID)
    //     //         this.updateData1(false)
    //     //       else
    //     //         this.updateData1(addNew)
    //     //     }
    //     //     else {
    //     //       console.log(successCode)
    //     //     }
    //     //   });
    //   }
    //   else {
    //     if (this.isokfileDirection == false)
    //       this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileDescription == false)
    //       this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileQusetion == false)
    //       this.message.error("Only Images are allowed In Qusetion ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileParagraph == false)
    //       this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileSolutionUrl == false)
    //       this.message.error("Only Images are allowed In solution ( JPG | PNG | JPEG)", "");

    //   }
    // }
    // else {
      console.log("in out")
      if (this.isokfileDirection && this.isokfileDescription && this.isokfileParagraph && this.isokfileQusetion && this.isokfileSolutionUrl) {
        this.isSpinning = true

        if (this.data.ID)
          this.updateData1(false)
        else
          this.updateData1(addNew)
      }
      else {
        if (this.isokfileDirection == false)
          this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
        if (this.isokfileDescription == false)
          this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
        if (this.isokfileQusetion == false)
          this.message.error("Only Images are allowed In Qusetion ( JPG | PNG | JPEG)", "");
        if (this.isokfileParagraph == false)
          this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
        if (this.isokfileSolutionUrl == false)
          this.message.error("Only Images are allowed In solution ( JPG | PNG | JPEG)", "");

      }
    

  }
  updateData(addNew: boolean=false) {

    this.data.optionList = this.OptionList
    if (this.data.QUESTION_TYPE_ID == 1 || this.data.QUESTION_TYPE_ID == 2)
      this.data.IS_SEQUENCE_MARKING = false
    if (this.data.PARAGRAPH_ID == undefined)
      this.data.PARAGRAPH_ID = 0


    // when after save & close array will blank
    console.log("quetion models")
    console.log(this.data)
    console.log(this.OptionList)
   this.data.COURSE_SUB_CATEGORY_ID=this.data.COURSE_SUB_CATEGORY_ID
   this.data.COURSE_ID=this.data.COURSE_ID
    if (this.data.ID) {
      this.api.updateShikuyaaQuestion(this.data)
        .subscribe(successCode => {
          console.log(this.data)
          console.log(successCode)
          if (successCode['code'] == 200) {
            this.message.success("Question information updated Successfully...", "");
            this.logtext = 'Update & Close - Question form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Question ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.urlList = []
            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {
            this.logtext = 'Update & Close - Question form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Question ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.message.error("Failed to update Question information...", "");
            this.isSpinning = false;
          }
        });
    }
    else {
      this.data.COURSE_SUB_CATEGORY_ID=this.data.COURSE_SUB_CATEGORY_ID
      this.data.COURSE_ID=this.data.COURSE_ID
      this.api.createShikuyaaQuestion(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.message.success("Question information added successfully...", "");
            if (!addNew) {
              this.drawerClose();
              this.setValues()
              this.logtext = 'Save & Close - Question form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            else {
              this.data.ID = undefined
              this.data.DIRECTION_IMG = ""
              this.data.DESCRIPTION_IMG = ""
              this.data.QUESTION = ""
              this.data.QUESTION_URL = ""
              this.data.SOLUTION_IMAGE_URL = ""
              this.data.ANSWER_TEXT = ""
              this.data.OPTIONS = []
              this.urlList = []

              this.setValues()
              this.logtext = 'Save & New - Question form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add Question information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - Question form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
        });
    }
  }
  updateData1(addNew: boolean) {

    this.data.optionList = this.OptionList
    if (this.data.QUESTION_TYPE_ID == 1 || this.data.QUESTION_TYPE_ID == 2)
      this.data.IS_SEQUENCE_MARKING = false
    if (this.data.PARAGRAPH_ID == undefined)
      this.data.PARAGRAPH_ID = 0


    // when after save & close array will blank
    console.log("quetion models")
    console.log(this.data)
    console.log(this.OptionList)
    if (this.data.ID) {
      this.api.updateShikuyaaQuestion1(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == 200) {
            this.message.success("Question information updated Successfully...", "");
            this.logtext = 'Update & Close - Question form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Question ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.urlList = []
            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {
            this.logtext = 'Update & Close - Question form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Question ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.message.error("Failed to update Question information...", "");
            this.isSpinning = false;
          }
        });
    }
    else {
      this.api.createShikuyaaQuestion1(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.message.success("Question information added successfully...", "");
            if (!addNew) {
              this.drawerClose();
              this.setValues()
              this.logtext = 'Save & Close - Question form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            else {
              this.data.ID = undefined
              this.data.DIRECTION_IMG = ""
              this.data.DESCRIPTION_IMG = ""
              this.data.QUESTION = ""
              this.data.QUESTION_URL = ""
              this.data.SOLUTION_IMAGE_URL = ""
              this.data.ANSWER_TEXT = ""
              this.data.OPTIONS = []
              this.urlList = []

              this.setValues()
              this.logtext = 'Save & New - Question form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add Question information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - Question form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
        });
    }
  }



  setValues() {
    this.data.IS_RANDOMIZATION_OPTION = true
    // this.data.DIRECTION = "No"
    // this.data.DESCRIPTION = "No"
    // this.data.SOLUTION = "No"
    // this.data.SOLUTION_VIDEO_URL = "No"
    this.OPTION_TEXT = ""
    this.OptionList = []
    this.urlList = []
  }


  close() {
    this.drawerClose()
  }


  getUrl(url: string) {
    console.log(url)
    this.api.getFile(url).subscribe(data => {
      console.log("response of file")
      console.log(data)
      if (data['code'] == 200) {
        const TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        const linkSource = "data:image/jpeg;base64," + base64String;
        let pdfWindow = window.open("")
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + linkSource + "'></iframe>")
      }
      else {
        this.message.error(data['message'], "");

      }
    }, err => {
      console.log(err);
    });

    this.logtext = "VIEW OptionURl - QuestionMaster form KEYWORD [V - QuestionMaster] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  clear(typeimag) {
    if (typeimag == "D")
      this.file_Direction_URL = null
    else if (typeimag == "S")
      this.file_Description_URL = null
    else if (typeimag == "Q")
      this.file_Question_URL = null
    else if (typeimag == "O")
      this.file_Options_URL = null
    else if (typeimag == "U")
      this.file_Solution_URL = null


  }

  
}
