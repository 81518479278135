import { Component, OnInit, Input } from '@angular/core';
import { Instruction } from 'src/app/Models/AssignmentModule/instruction';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css']
})
export class InstructionComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Instruction;
    isSpinning = false
  logtext:string = "";
userId=Number(sessionStorage.getItem('userId'));
dataList=[]

  constructor(public api: ApiService,private message: NzNotificationService) {
  }

  ngOnInit() {
  this.loadData()
  }
  

  loadData()
  {
    this.api.getAllInstructions(0, 0, 'ID', 'desc', "").subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }


  close(): void {
    this.drawerClose();

	this.logtext = 'CLOSED - Instruction form';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

  }

  save(addNew: boolean): void {

  
    if(this.data.NAME!=undefined && this.data.NAME!="" && this.data.INSTRUCTION_TEXT!=undefined && this.data.INSTRUCTION_TEXT!="")
{
  if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true) 
      {

        
      if (this.data.ID) {

  
        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
        });
    
        if(filterData.length>0)
        {
        this.message.error("Already Record Present in Database", "");
        }
        else
        {
        
        this.isSpinning = true;

        this.api.updateInstruction(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Instruction information updated Successfully...", "");

	this.logtext = 'Update & Close - Instruction form - SUCCESS '+ JSON.stringify(this.data)+" KEYWORD [U - Instruction ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

	this.logtext = 'Update & Close - Instruction form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [U - Instruction ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              this.message.error("Failed to update instruction information...", "");
              this.isSpinning = false;
            }
          });
        }
      }
      else {

        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
        });
  
       if(filterData.length>0)
       {
        this.message.error("Already Record Present in Database", "");
       }
       else
       {
        this.isSpinning=true
        this.api.createInstruction(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Instruction information added successfully...", "");

              if (!addNew)
              {
                this.drawerClose();

	this.logtext = 'Save & Close - Instruction form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - Instruction ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
        }
              else {
                this.data = new Instruction();
                this.loadData()
    this.data.IS_DEFAULT_SELECTED=true
	this.logtext = 'Save & New - Instruction form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - Instruction ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add instruction information...", "");
	this.isSpinning = false;
	this.logtext = 'Save & Close - Instruction form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [C - Instruction ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
              
            }
          });
        }
      }
    }
    else
    {
    this.message.error("Please Check Name", "");

    }
    }
    else
    {
    this.message.error("Please Fill All Required Fields", "");

    }

  }


}
