export class Assignment {
    ID:number;
    CLIENT_ID:number;
    GROUP_ID:number;
    NAME:string;
    DESCRIPTION:string="";
    POSITIVE_MARKS:number=0;
    NEGATIVE_MARKS:number=0;
    PLATFORM:string="M";
    CONTRAVENTION_COUNT:number=0;
    VALIDATION_COUNT:number=0;
    IS_VALIDATION_COMPULSORY:boolean=true;
    VALIDATION_METHOD:string="";
    RANDOMIZE_QUESTIONS:boolean=true;
    RESULTS_SHARING_STATUS:boolean=true;
    RESULT_SHARING_TIME:number=0;
    SECTION_SWITCHING:string="O";
    TIMER_TYPE:string="T";
    TEST_SUBMISSION:boolean=true;
    TOTAL_QUESTIONS:number=0;
    TOTAL_TIME:number=0;
    COURSE_CATEGORY_ID:any
    COURSE_SUB_CATEGORY_ID:any
    platforms= [
        { label: 'Mobile', value: 'M', checked: true },
        { label: 'Web', value: 'W', checked: false },
      ];
}
export class Assignment2 {
  ID:number;
  CLIENT_ID:number;
  GROUP_ID:number;
  NAME:string;
  DESCRIPTION:string="";
  POSITIVE_MARKS:number=0;
  NEGATIVE_MARKS:number=0;
  PLATFORM:string="M";
  CONTRAVENTION_COUNT:number=0;
  VALIDATION_COUNT:number=0;
  IS_VALIDATION_COMPULSORY:boolean=true;
  VALIDATION_METHOD:string="";
  RANDOMIZE_QUESTIONS:boolean=true;
  RESULTS_SHARING_STATUS:boolean=true;
  RESULT_SHARING_TIME:number=0;
  SECTION_SWITCHING:string="O";
  TIMER_TYPE:string="T";
  IS_DEDICATED:boolean=false;
  TEST_SUBMISSION:boolean=true;
  TOTAL_QUESTIONS:number=0;
  TOTAL_TIME:number=0;
  COURSE_CATEGORY_ID:any
  COURSE_SUB_CATEGORY_ID:any
  platforms= [
      { label: 'Mobile', value: 'M', checked: true },
      { label: 'Web', value: 'W', checked: false },
      { label: 'Desktop', value: 'D', checked: false },
    ];
}
