import { Component } from '@angular/core';
import { ApiService } from './Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { User } from './Models/Commonmodule/user';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  //layout variable
  isCollapsed = false;
  isLogedIn = false;
  message1;
  confirmModal?: NzModalRef; // For testing by now
  //menu variable
  menus = []
  resetVisible: boolean = false

  //session getting value
  userId = sessionStorage.getItem('userId')
  roleId = Number(sessionStorage.getItem('roleId'))
  // roleId=0
  USERNAME = sessionStorage.getItem('userName')

  collegeId = Number(sessionStorage.getItem('collegeId'))

  roleIdLength = Number(sessionStorage.getItem('roleIdsLength'))

  //idle state variable
  timedOut = false
  count: number = 0
  startPage: string = ""
  visibleDashboard = false
  selectPage = ""
  password: string
  confirmPassword: string
  passwordVerify: string
  Verified: boolean = false
  verifyLoading: boolean = false
  okdisabled = true
  userData: User = new User()
  okLoading = false
  parentId: number
  pageName: string = ""

  constructor(private modal: NzModalService,private idle: Idle, private keeplive: Keepalive, private router: Router, public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
    idle.setIdle(7200);
    idle.setTimeout(30);

    idle.onIdleStart.subscribe(() => {
      console.log("started")
      this.timedOut = true;
    });

    idle.onTimeout.subscribe(() => {
      this.timedOut = false;
      //remove session and all cookie varibles 
      this.logout()
      console.log("time out")
    });


    idle.onTimeoutWarning.subscribe((countdown: any) => {
      console.log("idle time out" + countdown)
      this.count = countdown
    });

    this.reset();
    this.loggerInit();
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  continue() {
    this.reset()
  }

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Do you want to logout?',
      // nzContent: 'When clicked the OK button, this dialog will be closed after 1 second',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          this.logout()
        }).catch(() => console.log('Oops errors!'))
    });
  }
  resetTimeOut() {
    this.message.success("Logout Successfully","")
    this.cookie.delete('token')
    this.cookie.delete('supportKey')
    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    sessionStorage.clear()
    window.location.reload();
  }


  loggerInit() {
    if (this.cookie.get('supportKey') === '' || this.cookie.get('supportKey') === null) {

      this.api.loggerInit().subscribe(data => {
        if (data['code'] == 200) {
          this.cookie.set('supportKey', data["data"][0]["supportkey"], 365, "", "", false, "Strict");
          console.log(data["data"][0]["supportkey"])
        }
      }, err => {
        console.log(err);
      });
    }
    else {
    }
  }

  ngOnInit() {

    if (this.cookie.get('token') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
      //  this.isLogedIn=true;
    }
    else {
      if (this.userId || this.roleId != 0) {
        this.isLogedIn = true;
        console.log(this.roleId)
        if (this.roleId != 21) {
          // let filter = " AND ID=" + this.roleId
          this.api.getAllRoles(0, 0, '', '', '').subscribe(data => {
            console.log("roles")

            console.log(data)
            if (this.roleId != 0)
              this.parentId = data['data'][0]['PARENT_ID']
            if (data['count'] > 0) {
              // if (data['data'][0]['START_PAGE'] != undefined) {
              //   console.log("data")
              //   this.selectPage = data['data'][0]['START_PAGE']
              // }

              // if (this.selectPage != '/selectpage') {
              //   this.selectPage = ""
              //   this.router.navigateByUrl('/selectrole')
              // }
            //   else if (this.selectPage == '/selectpage' && this.collegeId > 0) {
            //     this.router.navigateByUrl('/dashboard')
            //   }
            //   else if (this.selectPage == '/selectpage' && this.collegeId == 0) {
            //     this.router.navigateByUrl('/selectpage')
            //   }
            //   console.log(this.selectPage)
            }

          });
        }
        else {

        }


        // this.accessPageForRedirect()
        this.loadForms()
      }
      else {
        this.api.logoutForSessionValues()
      }
    }
    this.api.receiveMessage()
    this.message1 = this.api.currentMessage
  }

  // accessPageForRedirect() {
  //   if (this.roleId != 0) {
  //     let url = window.location.href
  //     var arr = url.split("/")
  //     let validPage = "/" + arr[3]
  //     this.api.getCheckAccessOfForm(this.roleId, validPage).subscribe(data => {
  //       if (data['data'] == true) {
  //         this.pageName = validPage
  //         console.log(this.pageName)
  //         if (validPage == "/selectpage")
  //           this.router.navigateByUrl('/dashboard')
  //         else
  //           this.router.navigateByUrl(validPage)
  //       }
  //       else {
  //         if (validPage != "/login") {
  //           this.api.logoutForSessionValues()
  //         }
  //       }
  //     });
  //   }
  // }



  loadForms() {

    this.api.getForms(this.roleId).subscribe(data => {

      if (data['code'] == 200) {

        data['data'].forEach(element => {
          element['children'].sort(this.sortFunction)

          if (element['children'].length == 0)
            delete element['children']
        });

        this.menus = data['data'].sort(this.sortFunction)
      }
    });
  }

  sortFunction(a, b) {
    var dateA = a.SEQ_NO
    var dateB = b.SEQ_NO
    return dateA > dateB ? 1 : -1;
  };

  logout() {
    this.api.addLog('A', this.api.dateforlog + "" + 'Logout Successfully ', this.api.emailId).subscribe(data => {
      
    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
    this.resetTimeOut()
  }

  openSelect() {
    // sessionStorage.("collegeId")
    this.collegeId = 0
    sessionStorage.setItem('collegeId', '0')
    this.router.navigateByUrl('/selectpage')
  }

  openSelectRolePage() {
    sessionStorage.setItem('roleId', '0')
    this.selectPage = ""
    sessionStorage.setItem('collegeId', '0')
    // this.router.navigateByUrl('/selectrole')
    window.location.reload()
  }

  resetModel() {
    this.resetVisible = true
    this.passwordVerify = ""
    this.password = ""
    this.confirmPassword = ""
    this.Verified = false
  }
  UpdateCancel() {
    this.resetVisible = false
  }

  updateConfirmValidator(conformpass) {
    if (this.password == conformpass)
      this.okdisabled = false
    else
      this.okdisabled = true
  }


  verify() {
    this.verifyLoading = true
    var filter = " AND ID=" + this.userId + " AND PASSWORD=" + this.passwordVerify
    this.api.getAllUsers(0, 0, "ID", "desc", filter).subscribe(data => {
      this.verifyLoading = false
      if (data['count'] > 0) {
        this.Verified = true
        this.userData = Object.assign({}, data['data'][0])
        this.getRoleData()
        console.log(this.userData)
      }
      else
        this.message.error("Your Password is incorrect", "");
    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.verifyLoading = false
      this.message.error("Server Not Found", "");
    });
  }

  getRoleData() {
    let roleIds = ""
    let filter = " AND USER_ID=" + this.userId
    this.api.getAllUserMappingData(0, 0, '', '', filter).subscribe(data1 => {
      data1['data'].forEach(element => {
        roleIds = roleIds + element['ROLE_ID'] + ","
      });
      let userId1 = roleIds.substring(0, roleIds.length - 1)
      var arrOfRoleId = userId1.split(',').map(function (item) {
        return parseInt(item, 10);
      });
      if (userId1 == "") {
        this.userData.ROLE_DATA = []
      }
      else {
        this.userData.ROLE_DATA = arrOfRoleId
      }
    }, err => {
      console.log(err);
    });
  }


  UpdateOk() {
    this.okLoading = true
    let roleIds = ""
    if (this.password == "" && this.confirmPassword == "")
      this.message.error("Please Enter Password", "");
    else {

      this.userData.PASSWORD = this.confirmPassword
      console.log(this.userData.ROLE_DATA)
      console.log(this.userData)

      this.api.updateUser(this.userData)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.resetVisible = false;
            this.okLoading = false;
          }
          else {
            this.message.error("Failed to update user information...", "");
            this.okLoading = false;
          }
        }, err => {
          console.log(err);
          if (err['ok'] == false)
            this.okLoading = false;
          this.message.error("Server Not Found", "");
        });
    }
  }


}
