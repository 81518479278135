import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CompanyModel } from 'src/app/Models/ShikuyaaModels/company-model'
import { ApiService } from 'src/app/Service/api.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-companies-master',
  templateUrl: './companies-master.component.html',
  styleUrls: ['./companies-master.component.css']
})
export class CompaniesMasterComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: CompanyModel;
    isSpinning = false
  logtext:string = "";


 date = new Date();
 date1 = this.datePipe.transform(this.date, 'yyyyMMdd')
fileDataLOGO_URL:File=null
 folderName= "CompanyLogo"
  isOkFile:boolean=true
  constructor(private datePipe: DatePipe,public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
  
  }
  


  close(): void {
    this.drawerClose();

	this.logtext = 'CLOSED - CompanyModel form';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

  }
  names
  save(addNew: boolean): void {

    this.isSpinning = true;
  
      if (this.data.ID) {

	 if (this.fileDataLOGO_URL) {
 if (this.data.LOGO_URL == "") {
  this.data.LOGO_URL = this.genarateKeyLOGO_URL();
}
   else {
 var str = this.data.LOGO_URL.substring(this.data.LOGO_URL.lastIndexOf('/') + 1).split('.')
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  var url = str[0] + "." + fileExt;
  this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url).subscribe(successCode => {
    if (successCode['code'] == "200") {
     console.log(successCode);
    }
    else {
console.log(successCode);
    }
  });
   this.data.LOGO_URL =  url;
 }
  }
 else { 
 this.data.LOGO_URL = ""
  }
  if (!this.isValid(this.data.NAME) ) {
  
    this.names=this.data.NAME
   
    this.message.error("Invalid Company Name", "");
  }
  if (!this.isValidSq(this.data.SEQUENCE_NO) ) {
    
    this.names=this.data.NAME
   
    this.message.error("Only numbers are allowed", "");
  }
        this.api.updateCompanys(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Comapny information updated Successfully...", "");

	this.logtext = 'Update & Close - CompanyModel form - SUCCESS '+ JSON.stringify(this.data)+" KEYWORD [U - CompanyModel ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

	this.logtext = 'Update & Close - CompanyModel form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [U - CompanyModel ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              this.message.error("Failed to update Company information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {
 if (this.fileDataLOGO_URL) {
 this.data.LOGO_URL = this.genarateKeyLOGO_URL()
}
  else {
  this.data.LOGO_URL = "";
 }
  if (!this.isValid(this.data.NAME) ) {
  
  this.names=this.data.NAME
 
  this.message.error("Invalid Company Name", "");
}
if (!this.isValidSq(this.data.SEQUENCE_NO) ) {
  
  this.names=this.data.NAME
 
  this.message.error("Only numbers are allowed", "");
}
        this.api.createCompanys(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Company information added successfully...", "");

              if (!addNew)
	{
                this.drawerClose();

	this.logtext = 'Save & Close - Company form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - CompanyModel ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
	}

              else {
                this.data = new CompanyModel();
	this.logtext = 'Save & New - CompanyModel form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - CompanyModel ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add Company information...", "");
	this.isSpinning = false;
	this.logtext = 'Save & Close - CompanyModel form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [C - CompanyModel ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
              
            }
          });
      }
  }

  isValid(names) {
    const expression =/^[^\s]+(\s.*)?$/;
    
    return expression.test(String("" + names).toLowerCase())

  }
  check()
  {
    if (!this.isValid(this.data.NAME) ) {
  
      this.names=this.data.NAME
     
      this.message.error("Invalid Company Name", "");
    }
  }
  isValidSq(names) {
    const expression =/^[0-9]$/;
    return expression.test(String("" + names).toLowerCase())
  
  }
 genarateKeyLOGO_URL() {
 
  
  const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  const isLt2M = this.fileDataLOGO_URL.size <= 10485760;
  
 
  //console.log(" size" + isLt2M)
 
  if (isLt2M) {
    if (!allowed_types.toString().match(this.fileDataLOGO_URL.type)) {
      this.isOkFile = false
      this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
      // this.showing=true
      setTimeout(() => {                          
        // this.showing=false
      }, 5000);
     
    }
   }
  var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  console.log(this.fileDataLOGO_URL)
  var number = Math.floor(100000 + Math.random() * 900000)  
 
   var url = this.date1 + number + "." + fileExt
 if(allowed_types.toString().match(this.fileDataLOGO_URL.type))
 {
   this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url).subscribe(successCode => {
     if (successCode['code'] == "200") {
      console.log(successCode);
     }
     else {
 console.log(successCode);
     }
   });
 }
    // this.data.LOGO_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
    this.data.LOGO_URL =  url;
 
    return this.data.LOGO_URL
  }
 

 onFileSelectedLOGO_URL(event)
  {
 this.fileDataLOGO_URL=<File>event.target.files[0];
 const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
 const isLt2M = event.target.files[0].size <= 10485760;
 

 //console.log(" size" + isLt2M)

 if (isLt2M) {
   if (!allowed_types.toString().match(event.target.files[0].type)) {
     this.isOkFile = false
     this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
     // this.showing=true
     setTimeout(() => {                          
       // this.showing=false
     }, 5000);
    
   }
   var thumbnailImgHeight: number
    var thumbnailImgWeight: number
    var isheightwidth: boolean

    const Img = new Image();
    Img.src = URL.createObjectURL(event.target.files[0]);
    Img.onload = (e: any) => {
      thumbnailImgHeight = e.path[0].height;
      thumbnailImgWeight = e.path[0].width;

      if (thumbnailImgHeight == 70 && thumbnailImgWeight == 230)
        isheightwidth = true
      else
        isheightwidth = false


      if (this.isOkFile && isheightwidth) {
        this.fileDataLOGO_URL = <File>event.target.files[0];
      }
      else {
        if (!isheightwidth)
          this.message.error('Height and Width must be 230 X 70.', '');

      }
    }
  }
 var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
 console.log(this.fileDataLOGO_URL)
 var number = Math.floor(100000 + Math.random() * 900000)  

  var url = this.date1 + number + "." + fileExt
if(allowed_types.toString().match(event.target.files[0].type))
{
  this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url).subscribe(successCode => {
    if (successCode['code'] == "200") {
     console.log(successCode);
    }
    else {
console.log(successCode);
    }
  });
}
   // this.data.LOGO_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
   this.data.LOGO_URL =  url;

   return this.data.LOGO_URL
 }

}
