import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { College } from 'src/app/Models/CollegeOnboarding/college';

@Component({
  selector: 'app-collegebranchmapping',
  templateUrl: './collegebranchmapping.component.html',
  styleUrls: ['./collegebranchmapping.component.css']
})
export class CollegebranchmappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: College;
  @Input() collegeBranchMappingData: string[];
  @Input() drawerVisible: boolean;
  isSpinning = false
  logtext:string=""
  searchText:string=""
  
  constructor(public api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {

  }

  close(): void {
    this.drawerClose();
  }

  save()
  {
    console.log(this.data.ID)
    console.log(this.collegeBranchMappingData)
    this.isSpinning = true;
      this.api.addCollegeBranchMapping(this.data.ID,this.collegeBranchMappingData)
      .subscribe(successCode => {
        console.log(successCode)
        if(successCode['code']==200)
        { 
            this.message.success("College branch Details added Successfully ...", "");
            this.drawerClose();
            this.isSpinning = false;
            this.logtext = 'Save & Close - CollegeBranchMapping form - SUCCESS - ' + "CollegeId="+this.data.ID+" "+ JSON.stringify(this.collegeBranchMappingData) + " KEYWORD [C - CollegeBranchMapping ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
        }
        else
        {
          this.message.error("College branch Details assigning Failed...", "");
          this.isSpinning = false;
          this.logtext = 'Save & Close - Failed To Map CollegeBranchMapping form - ERROR - ' + "CollegeId="+this.data.ID+" "+ JSON.stringify(this.collegeBranchMappingData) + " KEYWORD [F- CollegeBranchMapping ]";
          this.api.addLog('A', this.logtext, this.api.emailId)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                console.log(successCode);
              }
              else {
                console.log(successCode);
              }
            });
        }
      });
  
  
    }
}
