import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-bundle-detailed-summary",
  templateUrl: "./bundle-detailed-summary.component.html",
  styleUrls: ["./bundle-detailed-summary.component.css"],
})
export class BundleDetailedSummaryComponent implements OnInit {
  columns: string[][] = [
    ["IS_STARTED", " is picked"],
    ["STARTED_TIME", " Started Time "],
    ["MAPPED_TIME", " Mapped Time"],
    ["IS_ACTIVE", " Is Active"],
    ["BUNDLE_NAME", " Bundle Name"],
    ["TOTAL_MOCKS", " Total Mocks"],
    ["EMAIL_ID", " Email id"],
    ["MOBILE_NO", " Mobile no"],
    ["STUDENT_NAME", " Student Name "],
    ["TOTAL_MOCKS_SOLVED", " Total Mocks Solved"],
    ["BRANCH_NAME", " Branch Name"],
    ["COLLEGE_NAME", " COllege Name"],
    ["YEAR_NAME", " Year Name"],
  ];

  isExportloading = false;
  formTitle = " Bundle Detailed Summary ";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  bundleData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";
  filterValue: any = 1;
  trySwitch: boolean = false;
  filterClass: string = "filter-invisible";
  isloadSpinning = false;
  dataList1 = [];
  loadingmocks = false;
  query: any;
  pageSize2 = 10;

  constructor(public api: ApiService, private message: NzNotificationService,private _exportService: ExportService,
    private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.api
      .getBundleMappingData(this.pageIndex, this.pageSize, this.sortKey, "", "")
      .subscribe(
        (data) => {
          this.isloadSpinning = false;
          this.loadingRecords = false;
          if (data["code"] == "200") {
            this.totalRecords = data["count"];
            this.bundleData = data["data"];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  applyFilter() {
    console.log("Value:", this.filterValue);
    // this.isSpinning=true
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    // if (
    //   this.filterValue != '' && this.trySwitch !=0
    // ) {
    // this.filterQuery =
    //   " AND BUNDLE_ID = '" +
    //   this.filterValue +
    //   "'" +
    //   " AND IS_STARTED=" +
    //   this.trySwitch;

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }
    this.api
      .getBundleDetailedSummaryReport(
        0,
        0,
        this.sortKey,
        sort,
        this.filterQuery,
        this.filterValue,
        this.trySwitch
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = "primary";
          this.totalRecords = data["count"];
          this.dataList = data["data"];
          // this.isSpinning = false;
          this.filterClass = "filter-invisible";
          // this.search();
        },
        (err) => {
          console.log(err);
        }
      );
    // } else {
    //   this.message.error(" Please Select Bundle Name", "");
    // }

    // this.dataList=[];
  }

  downloadExcel() {
    // excel
    this.isloadSpinning = true;
    this.api
      .getBundleDetailedSummaryReport(
        0,
        0,
        this.sortKey,
        "",
        this.filterQuery,
        this.filterValue,
        this.trySwitch
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.isloadSpinning = false;
            this.dataList1 = data["data"];
            console.log("this.dataList1:", this.dataList1);
            const element = window.document.getElementById("downloadFile");
            if (element != null) element.click();
          }
        },
        (err) => {
          console.log(err);
          this.isloadSpinning = false;
        }
      );
  }

  onKeyDownEvent(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  clearFilter() {
    // this.COLLEGE_ID = ''
    this.filterValue = "";
    this.filterQuery = "";
    this.trySwitch = false;
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext =
        'Filter Applied - Course Assessment Details"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
      this.api
        .addLog("A", this.logtext, this.api.emailId)
        .subscribe((successCode) => {
          if (successCode["code"] == "200") {
            console.log(successCode);
          } else {
            console.log(successCode);
          }
        });
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
      console.log("likeQuery" + likeQuery);
    }

    this.logtext =
      "Filter Applied - Course Assessment Details " +
      likeQuery +
      " KEYWORD [F - Chapters] ";
    this.api
      .addLog("A", this.logtext, this.api.emailId)
      .subscribe((successCode) => {
        if (successCode["code"] == "200") {
          console.log(successCode);
        } else {
          console.log(successCode);
        }
      });

    var filter = "";
    if (likeQuery) filter = this.filterQuery + likeQuery;
    else filter = this.filterQuery;
    this.query = filter;
    console.log(filter);
    this.isloadSpinning = true;
    this.api
      .getBundleDetailedSummaryReport(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        filter,
        this.filterValue,
        this.trySwitch
      )
      .subscribe(
        (data) => {
          this.isloadSpinning = false;
          this.loadingRecords = false;
          if (data["code"] == "200") {
            this.totalRecords = data["count"];
            this.dataList = data["data"];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || "";
    const sortOrder = (currentSort && currentSort.value) || "desc";
    console.log(currentSort);

    console.log("sortOrder :" + sortOrder);
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search(false);
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  convertInExcel() {

    this.api
    .getBundleDetailedSummaryReport(
      0,
      0,
      this.sortKey,
      "",
      this.filterQuery,
      this.filterValue,
      this.trySwitch
    )
    .subscribe(
      (data) => {
        if (data["code"] == 200) {
          this.isloadSpinning = false;
          this.dataList1 = data["data"];
          // console.log("this.dataList1:", this.dataList1);
          // const element = window.document.getElementById("downloadFile");
          // if (element != null) element.click();
          if (this.dataList1.length > 0) {
            for (var i = 0; i < this.dataList1.length; i++) {
              obj1['Student Name'] = this.dataList1[i]['STUDENT_NAME'];
              obj1['College Name'] = this.dataList1[i]['COLLEGE_NAME'];
              obj1['Branch Name'] = this.dataList1[i]['BRANCH_NAME'];
              obj1['Passing Year'] = this.dataList1[i]['YEAR_NAME'];
              obj1['Email Id'] = this.dataList1[i]['EMAIL_ID'];
              obj1['Mobile No'] = this.dataList1[i]['MOBILE_NO'];
              obj1['Bundle Name'] = this.dataList1[i]['BUNDLE_NAME'];
              obj1['Started Time'] = this.dataList1[i]['STARTED_TIME'];
              obj1['Mapped Time'] = this.dataList1[i]['MAPPED_TIME'];
              obj1['Total Mocks'] = this.dataList1[i]['TOTAL_MOCKS'];
              obj1['Total Mocks Solved'] = this.dataList1[i]['TOTAL_MOCKS_SOLVED'];
              obj1['Is Started'] = this.dataList1[i]['IS_STARTED'];
              obj1['Is Active'] = this.dataList1[i]['IS_ACTIVE'];
      
      
              arry1.push(Object.assign({}, obj1));
              if (i == this.dataList1.length - 1) {
                this._exportService.exportExcel(
                  arry1,
                  'Bundle Detailed Summary Report' +
                    this.datePipe.transform(new Date(), 'yyyy-MM-dd')
                );
              }
            }
          } else {
            this.message.error('There is a no data', '');
          }
        }
      },
      (err) => {
        console.log(err);
        this.isloadSpinning = false;
      }
    );
    var arry1 = [];
    var obj1: any = new Object();
   
      
    
  }

}
