import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../Service/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  EMAIL_ID = "";
  PASSWORD = "";
  supportKey = "";
  isLogedIn = false;

  passwordVisible = false;
  isloginSpinning = false
  userId = sessionStorage.getItem('userId')

  userName = sessionStorage.getItem('userName')
  roleId = sessionStorage.getItem('roleId')

  emailId = ""
  constructor(private msg: NzMessageService,private router: Router, public api: ApiService, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit(): void {
    if (this.cookie.get('token') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
   
      //this.api.logoutForSessionValues()
      this.router.navigate(['/login'])
    }
    else {
      if (this.userId == null || this.userName == null || this.roleId == null)
        this.api.logoutForSessionValues()
      else {
        this.isLogedIn = true;
        // this.message.info('logged in' ,"")
        
        this.router.navigate(['/dashboard'])
      }
    }
    //const userId = '1';
    this.api.requestPermission(this.userId)
  }
  rememberMe()
  {
    
  }
  createBasicMessage(): void {
    this.msg.info('Loggedin Successfully', 
    {
      nzDuration:10000
    });
  }
  login(): void {
    var isok=true
    if(this.EMAIL_ID=="")
    {
      isok=false
      this.message.error(" Username field can't be blank", "");
    }
    else if(this.PASSWORD=="")
    {
      isok=false
      this.message.error("Password field can't be blank", "");
    }
    else  if (this.EMAIL_ID == "" && this.PASSWORD == "")
    {
      isok=false
      this.message.error("Username and password field cant be blank.", "");
    }

    if(isok) {

      this.isloginSpinning = true

      this.api.login(this.EMAIL_ID, this.PASSWORD).subscribe(data => {
        console.log("called")
        console.log('Data:',data)
        // localStorage.setItem('Data::',JSON.stringify(data))
        if (data['code'] == 200) {
          this.cookie.set('token', data["data"][0]["token"], 365, "", "", false, "Strict");
          sessionStorage.setItem("userId", data["data"][0]['UserData'][0]['USER_ID'])
          sessionStorage.setItem("userName", data["data"][0]['UserData'][0]['NAME'])
          sessionStorage.setItem("emailId", data["data"][0]['UserData'][0]['EMAIL_ID'])
          var a = sessionStorage.setItem("collegeID", data["data"][0]['UserData'][0]['COLLEGE_ID']) 
          console.log('IDDDD:',a);
          // sessionStorage.setItem("roleIdsLength", data["data"][0]['UserData'][0]['ROLE_DETAILS'].length)
          // if (data["data"][0]['UserData'][0]['ROLE_DETAILS'].length == 1)
          sessionStorage.setItem("roleId", data["data"][0]['UserData'][0]['ROLE_ID'])
          // sessionStorage.setItem("roleId", '1')
          // else
          //   sessionStorage.setItem("roleId", "0")
          
        //  this.createBasicMessage()
          this.isloginSpinning = false

          this.emailId = sessionStorage.getItem("emailId")
          this.message.success("Logged in successfully","")
          this.api.addLog('L', "Logged in Successfully ", this.emailId).subscribe(data => {
            window.location.reload();


          }, err => {
            console.log(err);
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
        }
        else if (data['code'] == 404) {
          this.message.error(data['message'], "")
          this.isloginSpinning = false
          this.api.addLog('A', data['message'] + " " + "Login Failed with EmailId= " + this.EMAIL_ID + " and Password=" + this.PASSWORD, "0").subscribe(data => {
            console.log(data)
          }, err => {
            console.log(err);
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
            this.isloginSpinning = false
          });
        }

      }, err => {
        this.message.error("Server not found", "");
        this.isloginSpinning = false
      });
    }
  }



}
