import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-app-details-report',
  templateUrl: './student-app-details-report.component.html',
  styleUrls: ['./student-app-details-report.component.css']
})
export class StudentAppDetailsReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
