import { Component, OnInit, Input } from '@angular/core';
import { Collegecontact } from 'src/app/Models/CollegeOnboarding/collegecontact';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Designation } from 'src/app/Models/CollegeOnboarding/designation';
import { Role } from 'src/app/Models/Commonmodule/role';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-contactdetail',
  templateUrl: './contactdetail.component.html',
  styleUrls: ['./contactdetail.component.css']
})
export class ContactdetailComponent implements OnInit {

  @Input() drawerCloseContact: Function;
  @Input() data: Collegecontact;
  @Input() COLLEGE_ID: number
  designations: Designation[]
  roles: Role[]
  designationLoading = false
  loadingFilterRoles = false
  passwordVisible = false;
  password: string;
  isSpinning = false
  logtext: string = "";
  userId = Number(this.cookie.get('userId'));
  disableValue1 = false
  disableValue = false
  dataList=[]
  nameValue:string="None"
  valueString:number=0

  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.loadData()
    this.loadAllContants()
  }

  loadAllContants()
  {
    this.api.getAllCollegeContactDetails(0, 0, '', '', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  loadData() {
    this.designationLoading = true
    this.api.getAllDesignations(0, 0, '', '', ' AND IS_ACTIVE=1').subscribe(data => {
      this.designations = data['data'];
      this.designationLoading = false
    }, err => {
      console.log(err);
    });

    this.loadingFilterRoles = true
    var filter = "AND PARENT_ID=2"
    this.api.getAllRoles(0, 0, '', '', filter).subscribe(data => {
      this.roles = data['data'];
      this.loadingFilterRoles = false
    }, err => {
      console.log(err);
    });
  }
  getRoleId(value) {


    if (value == "0")
      this.disableValue = true
    else if (value != "0")
      this.disableValue = false

  }
  isValidMobile(mobile)
  {
      const expression = /^[6-9]\d{9}$/;
      return expression.test(String(""+mobile).toLowerCase())
  }
  isValidEmail(email)
  {
      const expression = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
      return expression.test(String(email).toLowerCase())
  }
  save(addNew: boolean): void {
      console.log(this.data.ROLE_IDS)
    if (this.data.NAME != undefined && this.data.NAME != "" && this.data.EMAIL_ID != undefined && this.data.EMAIL_ID != "" && this.data.MOBILE_NUMBER != undefined && this.data.MOBILE_NUMBER != "" && this.data.DESIGNATION_ID != undefined && this.data.PASSWORD != undefined && this.data.PASSWORD != "" && this.data.ROLE_IDS.length>0 ) {
      var stringRoles = this.data.ROLE_IDS.toString()
      console.log("roles")
      console.log(this.data.ROLE_IDS)

      console.log("stringroles")
      console.log(stringRoles)
      console.log(this.data)
      if(this.isValidMobile(this.data.MOBILE_NUMBER) && this.isValidEmail(this.data.EMAIL_ID) && /^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)
     {    
        if (stringRoles.match(",0") || stringRoles.match("0,") || stringRoles == "") {
          this.message.error("Role must be either None or other roles", "");
        }
        else {
  
          if (this.data.ID) {

            var filterData= this.dataList.filter(object => {
              return object['EMAIL_ID'].toLowerCase() == this.data.EMAIL_ID.toLowerCase() && object['ID']!=this.data.ID
            });
      
            var filterData1= this.dataList.filter(object => {
              return object['MOBILE_NUMBER'] == this.data.MOBILE_NUMBER && object['ID']!=this.data.ID
            });
      
            console.log(filterData)

            console.log(filterData1)

            if(filterData.length==0 && filterData1.length==0)
            {
          this.isSpinning = true;

              this.api.updateCollegeContactDetails(this.data)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  this.message.success("College Contact Details information updated Successfully...", "");
  
                  this.logtext = 'Update & Close - CollegeContactDetails form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - CollegeContactDetails ]";
                  this.api.addLog('A', this.logtext, this.api.emailId)
                    .subscribe(successCode => {
                      if (successCode['code'] == "200") {
                        console.log(successCode);
                      }
                      else {
                        console.log(successCode);
                      }
                    });
                  if (!addNew)
                    this.drawerCloseContact();
                  this.isSpinning = false;
                }
                else {
                  this.logtext = 'Update & Close - CollegeContactDetails form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - CollegeContactDetails ]";
                  this.api.addLog('A', this.logtext, this.api.emailId)
                    .subscribe(successCode => {
                      if (successCode['code'] == "200") {
                        console.log(successCode);
                      }
                      else {
                        console.log(successCode);
                      }
                    });
                  this.message.error("Failed to update College Contact Details information...", "");
                  this.isSpinning = false;
                }
              });
            }
            else
            {
              if(filterData.length>0)
              {
               this.message.error("Email Id Already Present in Database", "");
              }
              if(filterData1.length>0)
              {
               this.message.error("Mobile Number Already Present in Database", "");
              }
            }
           



          }
          else {

            var filterData= this.dataList.filter(object => {
              return object['EMAIL_ID'].toLowerCase() == this.data.EMAIL_ID.toLowerCase() 
            });
      
            var filterData1= this.dataList.filter(object => {
              return object['MOBILE_NUMBER'] == this.data.MOBILE_NUMBER 
            });
            console.log(this.COLLEGE_ID)
            this.data.COLLEGE_ID = this.COLLEGE_ID
            if(filterData.length==0 || filterData.length==0)
            {
              this.isSpinning = true;

              this.api.createCollegeContactDetails(this.data)
              .subscribe(successCode => {
                console.log(successCode)
                if (successCode['code'] == "200") {
                  this.message.success("College Contact Details information added successfully...", "");
  
                  if (!addNew) {
  
                    this.drawerCloseContact();
  
                    this.logtext = 'Save & Close - CollegeContactDetails form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - CollegeContactDetails ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });
                  }
                  else {
                    this.data = new Collegecontact();
                    this.data.IS_ACTIVE = true
                    this.loadAllContants()
                    this.logtext = 'Save & New - CollegeContactDetails form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - CollegeContactDetails ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });
                  }
                  this.isSpinning = false;
                }
                else {
                  this.message.error("Failed to add College Contact Details information...", "");
                  this.isSpinning = false;
                  this.logtext = 'Save & Close - CollegeContactDetails form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - CollegeContactDetails ]";
                  this.api.addLog('A', this.logtext, this.api.emailId)
                    .subscribe(successCode => {
                      if (successCode['code'] == "200") {
                        console.log(successCode);
                      }
                      else {
                        console.log(successCode);
                      }
                    });
  
                }
              });
             
            }
            else
            {
              if(filterData.length>0)
              {
               this.message.error("Email Id Already Present in Database", "");
              }
              if(filterData1.length>0)
              {
               this.message.error("Mobile Number Already Present in Database", "");
              }
            }
           
          }
  
        }
     

    
     }
     else
     {
      if(!this.isValidMobile(this.data.MOBILE_NUMBER))
      this.message.error("Please Enter Valid Mobile Number...", "");
      else if(!this.isValidEmail(this.data.EMAIL_ID))
      this.message.error("Please Enter Valid Email Id...", "");
      else if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == false)
      this.message.error("Please Enter Valid Name...", "");

     }

   

    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }


  close() {
    this.drawerCloseContact()
  }
}
