import { Component, OnInit, Input } from '@angular/core';
import { Assignmentgroup } from '../../../../../Models/AssignmentModule/assignmentgroup';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-assignmentgroup2',
  templateUrl: './assignmentgroup2.component.html',
  styleUrls: ['./assignmentgroup2.component.css']
})
export class AssignmentgroupComponent2 implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Assignmentgroup;
  isSpinning = false
  logtext: string = "";
  @Input() dataList = []

  constructor(public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.loadData()
  }

  loadData() {
    this.api.getAllMockGroups(0, 0, 'ID', 'desc', "").subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  // close(): void {
  //   this.drawerClose();
  //   this.logtext = 'CLOSED - AssesmentGroup form';
  //   this.api.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }
  datas:any
////

resetDrawer(mockGroupPage: NgForm) {
  this.data=new Assignmentgroup();
  // mockGroupPage.form.reset();

  mockGroupPage.form.markAsPristine();
  mockGroupPage.form.markAsUntouched();
}

close(mockGroupPage: NgForm) {
  this.drawerClose();
  mockGroupPage.form.reset();
  this.resetDrawer(mockGroupPage);
  this.api.addLog('A', this.logtext, this.api.emailId)
  .subscribe(successCode => {
    if (successCode['code'] == "200") {
      console.log(successCode);
    }
    else {
      console.log(successCode);
    }
  });

}
//save
save(addNew: boolean,mockGroupPage: NgForm): void {

    if (this.data.NAME != undefined && this.data.NAME != "") {
      if (this.data.DESCRIPTION == "")
        this.data.DESCRIPTION = " "
      if (/[^\s]/.test(this.data.NAME) == true  ) {

        if(this.data.DESCRIPTION!='')
        {
          // if(/^(?:\s*[.\-_]*[a-zA-Z0-9]{1,}[.\-_]*\s*)$/.test(this.data.DESCRIPTION)==false ){
          // this.message.error("Please Enter Valid Description", "");
          // this.data.DESCRIPTION=''
          // }
         
          // else{
           
          
              if (this.data.ID) {
      
                var filterData = this.dataList.filter(object => {
                   return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
                });
      
                if (filterData.length > 0) {
                  this.message.error("Name already exists", "");
                }
                else {
                  this.isSpinning = true;
      
                  this.api.updateMockGroup(this.data)
                    .subscribe(successCode => {
                      if (successCode['code'] == "200") {
                        this.message.success("AssesmentGroup information updated Successfully...", "");
      
                        this.logtext = "Update & Close - AssesmentGroup form - SUCCESS" + JSON.stringify(this.data) + " KEYWORD [U - AssesmentGroup ]";
                        this.api.addLog('A', this.logtext, this.api.emailId)
                          .subscribe(successCode => {
                            if (successCode['code'] == "200") {
                              console.log(successCode);
                            }
                            else {
                              console.log(successCode);
                            }
                          });
      
                        if (!addNew)
                          this.drawerClose();
                        this.isSpinning = false;
                      }
                      else {
      
                        this.logtext = "Update & Close - AssesmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [U - AssesmentGroup ]";
                        this.api.addLog('A', this.logtext, this.api.emailId)
                          .subscribe(successCode => {
                            if (successCode['code'] == "200") {
                              console.log(successCode);
                            }
                            else {
                              console.log(successCode);
                            }
                          });
      
                        this.message.error("Failed to update AssesmentGroup information...", "");
                        this.isSpinning = false;
                      }
                    });
                }
              }
              else {
               
                  // var filterData = this.dataList.filter(object => {
                  //   return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() ;
                   
                  // });
                
               
                 
                  this.isSpinning = true;
                  var filterData = this.dataList.filter(object => {
                    return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() ;
                    console.log(object['NAME'])
              
                   
                  });
                  this.datas=filterData
                 
                 if(filterData.length>0)
                {
                  this.message.error("Name already exists", "");
                  this.data.NAME=''
                  this.isSpinning=false
                }
                else{
                  this.api.createMockGroup(this.data)
                  .subscribe(successCode => {
                    console.log(successCode)
                    if (successCode['code'] == "200") {
                      this.message.success("AssesmentGroup information added successfully...", "");
      
                      if (!addNew) {
                        this.drawerClose();
      
                        this.logtext = "Save & Close - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                        this.api.addLog('A', this.logtext, this.api.emailId)
                          .subscribe(successCode => {
                            if (successCode['code'] == "200") {
                              console.log(successCode);
                            }
                            else {
                              console.log(successCode);
                            }
                          });
                      }
      
                      else {
                        this.data = new Assignmentgroup();
                        this.resetDrawer(mockGroupPage);
                        // this.loadData()
                        this.logtext = "Save & New - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                        this.api.addLog('A', this.logtext, this.api.emailId)
                          .subscribe(successCode => {
                            if (successCode['code'] == "200") {
                              console.log(successCode);
                            }
                            else {
                              console.log(successCode);
                            }
                          });
      
                      }
                      this.isSpinning = false;
                    }
                    else {
                      this.message.error("Failed to add AssesmentGroup information..", "");
                      this.isSpinning = false;
                      this.logtext = "Save & Close - AssesmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
      
                    }
                  });
                }
                
            
                
                // else {
                //   this.isSpinning = true;
                //  if(filterData.length==0)
                //  {
                //   this.api.createMockGroup(this.data)
                //   .subscribe(successCode => {
                //     console.log(successCode)
                //     if (successCode['code'] == "200") {
                //       this.message.success("AssesmentGroup information added successfully...", "");
      
                //       if (!addNew) {
                //         this.drawerClose();
      
                //         this.logtext = "Save & Close - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                //         this.api.addLog('A', this.logtext, this.api.emailId)
                //           .subscribe(successCode => {
                //             if (successCode['code'] == "200") {
                //               console.log(successCode);
                //             }
                //             else {
                //               console.log(successCode);
                //             }
                //           });
                //       }
      
                //       else {
                //         this.data = new Assignmentgroup();
                //         // this.loadData()
                //         this.logtext = "Save & New - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                //         this.api.addLog('A', this.logtext, this.api.emailId)
                //           .subscribe(successCode => {
                //             if (successCode['code'] == "200") {
                //               console.log(successCode);
                //             }
                //             else {
                //               console.log(successCode);
                //             }
                //           });
      
                //       }
                //       this.isSpinning = false;
                //     }
                //     else {
                //       this.message.error("Failed to add AssesmentGroup information...", "");
                //       this.isSpinning = false;
                //       this.logtext = "Save & Close - AssesmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                //       this.api.addLog('A', this.logtext, this.api.emailId)
                //         .subscribe(successCode => {
                //           if (successCode['code'] == "200") {
                //             console.log(successCode);
                //           }
                //           else {
                //             console.log(successCode);
                //           }
                //         });
      
                //     }
                //   });
                //  }
                //  else{}
                // }
              }
              
          // }
        }
        else{
          
            if (this.data.ID) {
    
              var filterData = this.dataList.filter(object => {
                 return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
              });
    
              if (filterData.length > 0) {
                this.message.error("Name already exists", "");
              }
              else {
                this.isSpinning = true;
    
                this.api.updateMockGroup(this.data)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      this.message.success("AssesmentGroup information updated Successfully...", "");
    
                      this.logtext = "Update & Close - AssesmentGroup form - SUCCESS" + JSON.stringify(this.data) + " KEYWORD [U - AssesmentGroup ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
    
                      if (!addNew)
                        this.drawerClose();
                      this.isSpinning = false;
                    }
                    else {
    
                      this.logtext = "Update & Close - AssesmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [U - AssesmentGroup ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
    
                      this.message.error("Failed to update AssesmentGroup information...", "");
                      this.isSpinning = false;
                    }
                  });
              }
            }
            else {
             
                // var filterData = this.dataList.filter(object => {
                //   return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() ;
                 
                // });
              
             
               
                this.isSpinning = true;
                var filterData = this.dataList.filter(object => {
                  return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() ;
                  console.log(object['NAME'])
            
                 
                });
                this.datas=filterData
               
               if(filterData.length>0)
              {
                this.message.error("Name already exists", "");
                this.data.NAME=''
                this.isSpinning=false
              }
              else{
                this.api.createMockGroup(this.data)
                .subscribe(successCode => {
                  console.log(successCode)
                  if (successCode['code'] == "200") {
                    this.message.success("AssesmentGroup information added successfully...", "");
    
                    if (!addNew) {
                      this.drawerClose();
    
                      this.logtext = "Save & Close - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
                    }
    
                    else {
                      this.data = new Assignmentgroup();
                      // this.loadData()
                      this.logtext = "Save & New - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
    
                    }
                    this.isSpinning = false;
                  }
                  else {
                    this.message.error("Failed to add AssesmentGroup information...", "");
                    this.isSpinning = false;
                    this.logtext = "Save & Close - AssesmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });
    
                  }
                });
              }
              
          
              
              // else {
              //   this.isSpinning = true;
              //  if(filterData.length==0)
              //  {
              //   this.api.createMockGroup(this.data)
              //   .subscribe(successCode => {
              //     console.log(successCode)
              //     if (successCode['code'] == "200") {
              //       this.message.success("AssesmentGroup information added successfully...", "");
    
              //       if (!addNew) {
              //         this.drawerClose();
    
              //         this.logtext = "Save & Close - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
              //         this.api.addLog('A', this.logtext, this.api.emailId)
              //           .subscribe(successCode => {
              //             if (successCode['code'] == "200") {
              //               console.log(successCode);
              //             }
              //             else {
              //               console.log(successCode);
              //             }
              //           });
              //       }
    
              //       else {
              //         this.data = new Assignmentgroup();
              //         // this.loadData()
              //         this.logtext = "Save & New - AssesmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
              //         this.api.addLog('A', this.logtext, this.api.emailId)
              //           .subscribe(successCode => {
              //             if (successCode['code'] == "200") {
              //               console.log(successCode);
              //             }
              //             else {
              //               console.log(successCode);
              //             }
              //           });
    
              //       }
              //       this.isSpinning = false;
              //     }
              //     else {
              //       this.message.error("Failed to add AssesmentGroup information...", "");
              //       this.isSpinning = false;
              //       this.logtext = "Save & Close - AssesmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [C - AssesmentGroup ]";
              //       this.api.addLog('A', this.logtext, this.api.emailId)
              //         .subscribe(successCode => {
              //           if (successCode['code'] == "200") {
              //             console.log(successCode);
              //           }
              //           else {
              //             console.log(successCode);
              //           }
              //         });
    
              //     }
              //   });
              //  }
              //  else{}
              // }
            }
            }
      }
      else {
        this.message.error("Please Enter Valid Name", "");
        this.data.NAME=''
      }
    }
    else {
      if (this.data.NAME == undefined || this.data.NAME == "")
        this.message.error("Please Enter Name", "");
    }

  }

similardata()
{
  if (this.data.ID) {

  
  }

  else {
    var filterData = this.dataList.filter(object => {
      return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() ;

     
    });
  

  if (filterData.length > 0) {
    this.message.error("Name already exists", "");
  }
  }}
}