import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TPOMaster } from 'src/app/Models/AssessmeentModels/tpomaster';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { ApiService } from 'src/app/Service/api.service';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';

@Component({
  selector: 'app-addtpo',
  templateUrl: './addtpo.component.html',
  styleUrls: ['./addtpo.component.css']
})
export class AddtpoComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  // data: BundleMaster = new BundleMaster;
  @Input() data: TPOMaster;

  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  collegedataSpinning = false;
  isOk=true;
  emailpattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt=/[a-zA-Z][a-zA-Z ]+/
  mobpattern=/^[6-9]\d{9}$/ 
  @Input () collegeName: College[] = [];
  collegeName2: College[] = [];
  passwordVisible = false;
  dataList :any []= [];
  loadingRecords = true;
  totalRecords = 1; 
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc"; 
  sortKey: string = "id";
  searchText: string = "";
  columns: string[][] = [["NAME", " TPO Name "],["COLLEGE_NAME"," College Name "]]
  listdata1 = []
  @ Input() listdata2 = []

  constructor(private api:ApiService, private api2:ShikuyaaapiService,  private message: NzNotificationService) { }

 

  ngOnInit(): void {
    this.loadColleges()
    this.loadTPOlist()
    // this.search22()
  }

  //Colleges Name
   loadColleges() {
    this.collegedataSpinning = true;
    this.api2.getAllStudentColleges(0, 0, '', ' desc', '').subscribe(
      (datas) => {
        var datacode
        datacode = datas['code'];
// console.log("code:",datacode);

        if(datacode=="200")
        {
        // this.api.getAllcouponuseddetailedreport(0,0,'','',' AND STATUS=1').subscribe(data =>{
        this.collegeName = datas['data'];
        this.collegeName2 = datas['data'];
        this.collegedataSpinning = false;
        }
      },
      (err) => {
        console.log(err);
        this.isSpinning = false;
      }
    );
  }

  loadTPOlist(){
      this.api.getAllTPO(0, 0, 'ID', 'desc', "").subscribe(data => {
        if (data['code'] == 200) {
          this.listdata1 = data['data'];
          console.log(this.listdata1)
        }
      }, err => {
        console.log(err);
      });
  }
  // close(): void {
  //   this.drawerClose();
  // }

   //// Only number
   omit(event:any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ////

  resetDrawer(bundleMasterPage: NgForm) {
    this.data=new TPOMaster();
    // bundleMasterPage.form.reset();

    bundleMasterPage.form.markAsPristine();
    bundleMasterPage.form.markAsUntouched();
  }

  close(bundleMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(bundleMasterPage);
    bundleMasterPage.form.reset();
  
  }
  //save
  save(addNew: boolean,bundleMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk=true;

    if ( this.data.COLLEGE_ID<0 && this.data.NAME.trim()==""  && this.data.MOBILE_NO == undefined 
        && this.data.EMAIL_ID.trim()=='' && this.data.PASSWORD.trim() == '' ){
      this.isOk =false 
     this.message.error(" Please fill all required fields", ""); 
    }else

    if(this.data.COLLEGE_ID==null || this.data.COLLEGE_ID < 0){
      this.isOk =false
      this.message.error('Please select college name','')
    }else 

    if(this.data.NAME==null || this.data.NAME.trim()==''){
     this.isOk =false
     this.message.error('Please enter TPO name','')
    // }else 

    // if(this.data.NAME==null || this.data.NAME.trim()==''){
    //  this.isOk =false
    //  this.message.error('Please enter TPO name','')
    }else 
    
    if(this.data.MOBILE_NO== null || this.data.MOBILE_NO== undefined){
      this.isOk =false
      this.message.error('Please enter contact number','')
    }else 
    if(!this.mobpattern.test(this.data.MOBILE_NO.toString())){
      this.message.error('Please enter valid contact number ','')
    }else

    if(this.data.MOBILE_NO.toString().length < 10){
      this.isOk =false
      this.message.error('Please enter valid mobile number','')
    }else

    if(this.data.EMAIL_ID==null || this.data.EMAIL_ID.trim()==''){
      this.isOk =false
      this.message.error('Please enter mail id','')
    }else 

    if(!this.emailpattern.test(this.data.EMAIL_ID)){
      this.isOk =false
      this.message.error('Please enter valid mail id','')
    }else 

    if(this.data.PASSWORD == null || this.data.PASSWORD.trim()==''){
     this.isOk =false
     this.message.error('Please enter password','')     
    // }else if (this.data.PASSWORD.length < 8 ) {
    //   this.isOk = false;
    //   this.message.error('Password Must Be 8 Character Long', '');
}
if(this.isOk)
{
  this.data.ROLE_ID = 2
  this.isSpinning=false; 
  // this.data.LATITUDE = this.latitude.toString();
  // this.data.LONGITUDE   = this.longitude.toString();
  this.isSpinning=true; 
if(this.data.ID)
{
  var bundleData2 = this.listdata2.filter(obj => {
    return obj.COLLEGE_ID == this.data.COLLEGE_ID;
  });
  console.log('bundleData2',bundleData2.length);
  console.log('this.listdata1',this.listdata2);
  
  if (bundleData2.length == 0) {
    this.api.updateTPO(this.data)
    .subscribe(successCode => {
      if(successCode.code=="200")
      {
        this.message.success(' Information updated successfully...', '');
        if(!addNew)
          this.drawerClose();
          this.isSpinning = false;
      }   
      else
      {
        if(successCode.code=="401"){
          this.message.error(' Entered Mobile Number or Email Already Present...', '');
          this.isSpinning = false;
          }else {
            this.message.error(' Failed To Update Information...', '');
            this.isSpinning = false;
          }
        }
    });
  } else {
    this.message.error("TPO Already Assign To This College...", "");
    this.isSpinning = false;
  }
}
  else{
      var bundleData = this.listdata1.filter(obj => {
        return obj.COLLEGE_ID == this.data.COLLEGE_ID;
      });  
  console.log('bundleData2',bundleData.length);
  console.log('this.listdata1',this.listdata1);

      if (bundleData.length == 0) {  
      this.api.createTPO(this.data)
      .subscribe(successCode => {
        if(successCode.code=="200")
        {
          this.message.success(' Information Save Successfully...', '');
          this. loadTPOlist()
          if(!addNew)
         this.drawerClose();
            else
            {
              this.data=new TPOMaster();
              this.resetDrawer(bundleMasterPage);
               this. loadTPOlist();

              // this.data.img= '';
              
              this.api.getAllTPO(1,1,'','desc','').subscribe (data =>{
                // if (data['count']==0){
                //   this.data.SEQUENCE_NO=1;
                // }else
                // {
                //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                // }
              },err=>{
                console.log(err);
              })
            }
            this.isSpinning = false;
          }
           else
           {
            if(successCode.code=="401"){
              this.message.error(' Entered Mobile Number or Email Already Present...', '');
              this.isSpinning = false;
              }else {
                this.message.error(' Failed To Save Information...', '');
                this.isSpinning = false;
              }
           }
          });
        } else {
          this.message.error("TPO Already Assign To This College...", "");
          this.isSpinning = false;
        }
        }
}

// else
// {
//   this.message.error("Please Fill All Required Fields...","");
//   this.isSpinning = false;
// }
  }
  keyup(event:any) {
    // this.search22(this.data.COLLEGE_ID);
  }  

  search22(data:any) {
     
    console.log('ID:',data);
    if(data !=undefined && data != '')
    this.collegeName = this.collegeName2.filter(items=>{
     return items.NAME.toLocaleLowerCase().includes(data.toLocaleLowerCase())
    })

 
  }
}
