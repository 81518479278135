import { Directive, Input, HostListener } from "@angular/core";
import { ExportService } from "../Service/export.service";

@Directive({
  selector: "[appExport]",
})
export class ExportDirective {
  constructor(private exportService: ExportService) { }

  @Input("appExport") dataList: any[];
  @Input() fileName: string;
  converted;

  @HostListener("click", ["$event"]) onClick($event) {
    if (this.fileName == "PasskeyReport") {
      this.dataList = this.dataList.map(
        ({
          TRAINING_COURSE_NAME: TrainingCourse,
          NAME: Name,
          PASSKEY: Passkey,
          PASSKEY_EXPIRY_TIME: Expiry,
          STUDENT_COUNT: Students,
        }) => ({ TrainingCourse, Name, Passkey, Expiry, Students })
      );
      this.converted = this.dataList;
      //this.converted =  this.rekey(this.dataList, { TRAINING_COURSE_NAME: 'Course Name',NAME: 'Batch Name',PASSKEY: 'Passkey',PASSKEY_EXPIRY_TIME: 'Passkey Expiry'});
    } else if (this.fileName == "QuestionCategory") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, IS_ACTIVE: Status, SEQ_NO: Sequence_No }) => ({
          Name,
          Sequence_No,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "liveCategory") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, IS_ACTIVE: Status, SEQ_NO: Sequence_No }) => ({
          Name,
          Sequence_No,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "LiveClasses") {
      this.dataList = this.dataList.map(
        ({
          NAME: Name,
          VIDEO_URL: Video_URL,
          THUMBNAIL_URL: Thumbnail_Url,
          SHORT_DESCRIPTION: Short_Description,
          LONG_DESCRIPTION: Long_Description,
          START_DATE_TIME: Start_Date,
          END_DATE_TIME: End_Date,
        }) => ({
          Name,
          Video_URL,
          Thumbnail_Url,
          Short_Description,
          Long_Description,
          Start_Date,
          End_Date,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "webinarRegistrationreport") {
      this.dataList = this.dataList.map(
        ({
          WEBINAR_NAME: Webinar_name,
          STUDENT_NAME: Student_Name,
          MOBILE_NO: Mobile_No,
          MOBILE_VERIFICATION_DATETIME: Mobile_Varified_Date,
          EMAIL_ID: EmailID,
          EMAIL_VERIFICATION_DATETIME: Email_Varified_Date,
          IS_ENROLLED_STATUS: Registration_Status,
          DEVICE_ID: Device_ID,
        }) => ({
          Webinar_name,
          Student_Name,
          Mobile_No,
          Mobile_Varified_Date,
          EmailID,
          Email_Varified_Date,
          Registration_Status,
          Device_ID,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "MobileOtpReport") {
      this.dataList = this.dataList.map(
        ({
          MOBILE_NO: Mobile_No,
          OTP: OTP,
          REQUESTED_DATETIME: Requested_Date_Time,
          STATUS: Status,
        }) => ({ Mobile_No, OTP, Requested_Date_Time, Status })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "EmailOtpReport") {
      this.dataList = this.dataList.map(
        ({
          EMAIL_ID: Email_ID,
          OTP: OTP,
          REQUESTED_DATETIME: Requested_Date_Time,
          STATUS: Status,
        }) => ({ Email_ID, OTP, Requested_Date_Time, Status })
      );
      this.converted = this.dataList;
    }
    // [['','Student Name'],['','Mobile Number'],['','OTP Verified Datetime'],['','Email Id'],['','OTP Verified DateTime'],['','Registration Status'],['','Device Id']]
    else if (this.fileName == "CourseAssessmentsPartDetails") {
      this.dataList = this.dataList.map(
        ({
          PART_NAME: Part_Name,
          TOTAL_QUESTIONS: Total_Questions,
          TOTAL_TIME: Total_Time,
          L1_QUESTIONS: L1_Questions,
          L2_QUESTIONS: L2_Questions,
          L3_QUESTIONS: L3_Questions,
          L4_QUESTIONS: L4_Questions,
          L5_QUESTIONS,
          L5_Questions,
          L1_TIME: L1_Time,
          L2_TIME: L2_Time,
          L3_TIME: L3_Time,
          L4_TIME: L4_Time,
          L5_TIME: L5_Time,
        }) => ({
          Part_Name,
          Total_Questions,
          Total_Time,
          L1_Questions,
          L2_Questions,
          L3_Questions,
          L4_Questions,
          L5_Questions,
          L1_Time,
          L2_Time,
          L3_Time,
          L4_Time,
          L5_Time,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "PartReport") {
      this.dataList = this.dataList.map(
        ({
          SECTIONS: Sections,
          CHAPTERS: Chapters,
          SUB_CHAPTERS: SubChapters,
          PRACTICE_QUESTIONS: PracticeQuestionCount,
          TOTAL_VIDEOS: TotalVideos,
          TOTAL_VIDEO_TIME: TotalVideoTime,
        }) => ({
          Sections,
          Chapters,
          SubChapters,
          PracticeQuestionCount,
          TotalVideos,
          TotalVideoTime,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "courseCategory") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, ICON: Icon, SEQUENCE_NO: Sequence_Number }) => ({
          Name,
          Icon,
          Sequence_Number,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "companies") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, LOGO_URL: Logo, SEQUENCE_NO: Sequence_Number }) => ({
          Name,
          Logo,
          Sequence_Number,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentCoursePracticeSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          COURSE_NAME: Course_Name,
          TOTAL_PRACTICE_QUESTIONS: Total_Questions,
          P_SOLVED: solved,
          P_CORRECT: Correct,
          P_INCORRECT: Incorrect,
          P_PERCENTAGE: Percentage,
          P_ACCURACY: Accuracy,
          P_COMPLETION_PERCENTAGE: Completion_Percentage,
        }) => ({
          Student_Name,
          Course_Name,
          Total_Questions,
          solved,
          Correct,
          Incorrect,
          Percentage,
          Accuracy,
          Completion_Percentage,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SectionReport") {
      this.dataList = this.dataList.map(
        ({
          CHAPTERS: Chapters,
          SUB_CHAPTERS: SubChapters,
          PRACTICE_QUESTIONS: PracticeQuestionCount,
          TOTAL_VIDEOS: TotalVideos,
          TOTAL_VIDEO_TIME: TotalVideoTime,
        }) => ({
          Chapters,
          SubChapters,
          PracticeQuestionCount,
          TotalVideos,
          TotalVideoTime,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "ChapterReport") {
      this.dataList = this.dataList.map(
        ({
          NAME: ChapterName,
          SUBCHAPTER_COUNT: SubChapterCount,
          TOTAL_CHAPTER_PRACTICE_QUESTION: PracticeQuestionCount,
          TOTAL_VIDEOS: TotalVideos,
          TOTAL_VIDEO_TIME: TotalVideoTime,
        }) => ({
          ChapterName,
          SubChapterCount,
          PracticeQuestionCount,
          TotalVideos,
          TotalVideoTime,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SubChapterReport") {
      this.dataList = this.dataList.map(
        ({
          PART_NAME: Part_Name,
          SECTION_NAME: Section_Name,
          CHAPTER_NAME: Chapter_Name,
          NAME: Name,
          DESCRIPTION: Description,
          AQG_TEMPLATE_COUNT: AQGTemplates,
          CLASSWORK_QUESTION_COUNT: Classwork_Questions,
          HOMEWORK_QUESTION_COUNT: Homework_questions,
          PRACTICE_QUESTION_COUNT: Practice_Questions,
          ASSIGNMENT_QUESTION_COUNT: Assignmnet_Questions,
          COMBAT_QUESTION_COUNT: Combat_Questions,
          AQG_QUESTION_COUNT: AQG_Questions,
        }) => ({
          Part_Name,
          Section_Name,
          Chapter_Name,
          Name,
          Description,
          AQGTemplates,
          Classwork_Questions,
          Homework_questions,
          Practice_Questions,
          Assignmnet_Questions,
          Combat_Questions,
          AQG_Questions,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "BatchReport") {
      this.dataList = this.dataList.map(
        ({
          NAME: Name,
          PASSKEY: Passkey,
          PASSKEY_EXPIRY_TIME: PasskeyExpiry,
          STUDENT_COUNT: Count,
        }) => ({ Name, Passkey, PasskeyExpiry, Count })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "BatchStudentReport") {
      this.dataList = this.dataList.map(
        ({
          BATCH_NAME: Batch,
          STUDENT_NAME: StudentName,
          EMAIL_ID: EmailId,
          MOBILE_NO: Mobile,
          JOINING_DATE: RegistrationDate,
          PRN: PRN,
        }) => ({ Batch, StudentName, EmailId, Mobile, RegistrationDate, PRN })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "MasterSessionReport") {
      this.dataList = this.dataList.map(
        ({
          PART_NAME: Part_Name,
          SECTION_NAME: Section_Name,
          CHAPTER_NAME: Chapter_Name,
          NAME: Name,
          SUB_CHAPTER_COUNT: SubChapters,
          IDLE_DELIVERY_TIME: Idle_Delivery_Time,
          IDLE_SESSIONS_COUNT: Idle_Session_Count,
          SEQUENCE_NO: SequenceNo,
          PUBLISH_CHAPTER: Publish_Chapter,
        }) => ({
          Part_Name,
          Section_Name,
          Chapter_Name,
          Name,
          SubChapters,
          Idle_Delivery_Time,
          Idle_Session_Count,
          SequenceNo,
          Publish_Chapter,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "BatchSessionReport") {
      this.dataList = this.dataList.map(
        ({
          PART_NAME: Part_Name,
          SECTION_NAME: Section_Name,
          CHAPTER_NAME: Chapter_Name,
          BATCH_NAME: Batch_Name,
          NAME: Name,
          SUB_CHAPTER_COUNT: SubChapters,
          DELIVERY_TIME: Idle_Delivery_Time,
          SESSIONS_COUNTER: Idle_Session_Count,
          SEQUENCE_NO: SequenceNo,
        }) => ({
          Part_Name,
          Section_Name,
          Chapter_Name,
          Batch_Name,
          Name,
          SubChapters,
          Idle_Delivery_Time,
          Idle_Session_Count,
          SequenceNo,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "MasterSessionDetailsReport") {
      this.dataList = this.dataList.map(
        ({
          PART_NAME: Part_name,
          SECTION_NAME: Section_Name,
          CHAPTER_NAME: Chapter_Name,
          SUB_CHAPTER_NAME: Sub_chapter_Name,
          SESSION_NAME: Session_Name,
          TYPE_NAME: Type,
          WEIGHTAGE: Weight,
          L1_CLASSWORK_QUESTIONS_COUNT: L1Questions,
          L2_CLASSWORK_QUESTIONS_COUNT: L2Questions,
          L3_CLASSWORK_QUESTIONS_COUNT: L3Questions,
          L4_CLASSWORK_QUESTIONS_COUNT: L4Questions,
          L5_CLASSWORK_QUESTIONS_COUNT: L5Questions,
        }) => ({
          Part_name,
          Section_Name,
          Chapter_Name,
          Sub_chapter_Name,
          Session_Name,
          Type,
          Weight,
          L1Questions,
          L2Questions,
          L3Questions,
          L4Questions,
          L5Questions,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "BatchSessionDetailsReport") {
      this.dataList = this.dataList.map(
        ({
          PART_NAME: Part_name,
          SECTION_NAME: Section_Name,
          CHAPTER_NAME: Chapter_Name,
          SUB_CHAPTER_NAME: Sub_chapter_Name,
          BATCH_NAME: Batch_Name,
          BATCH_SESSION_SYLLABUS_NAME: Session_Name,
          WEIGHTAGE: Weight,
          L1_CLASSWORK_QUESTIONS_COUNT: L1Questions,
          L2_CLASSWORK_QUESTIONS_COUNT: L2Questions,
          L3_CLASSWORK_QUESTIONS_COUNT: L3Questions,
          L4_CLASSWORK_QUESTIONS_COUNT: L4Questions,
          L5_CLASSWORK_QUESTIONS_COUNT: L5Questions,
        }) => ({
          Part_name,
          Section_Name,
          Chapter_Name,
          Sub_chapter_Name,
          Batch_Name,
          Session_Name,
          Weight,
          L1Questions,
          L2Questions,
          L3Questions,
          L4Questions,
          L5Questions,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseTypes") {
      this.dataList = this.dataList.map(({ NAME, SEQUENCE_NO }) => ({
        NAME,
        SEQUENCE_NO,
      }));
      this.converted = this.rekey(this.dataList, {
        NAME: "Name",
        SEQUENCE_NO: "Sequence No",
      });
    } else if (this.fileName == "Courses") {
      this.dataList = this.dataList.map(
        ({
          COURSE_TYPE_NAME: Course_type,
          NAME: Name,
          SEQUENCE_NO: Sequence_No,
          ABBREVATION: Abbreviation,
          START_DATE: Start_Date,
          END_DATE: End_Date,
          COURSE_FEE: Course_Fee,
          SUBSCRIBERS: Subscribers,
          RATINGS: Rating,
        }) => ({
          Course_type,
          Name,
          Sequence_No,
          Abbreviation,
          START_DATE: Start_Date,
          End_Date,
          Course_Fee,
          Subscribers,
          Rating,
        })
      );
      this.converted = this.dataList;
      // this.converted =  this.rekey(this.dataList, { COURSE_TYPE_NAME:'Course Type', NAME: 'Name', SEQUENCE_NO: 'Sequence No', ABBREVATION: 'Abbreviation', START_DATE: 'Start Date', END_DATE: 'End Date', COURSE_FEE:'Course Fee', SUBSCRIBERS:'Subcribers', RATINGS:'Ratings'});
    } else if (this.fileName == "CourseChapters") {
      console.log(this.dataList);
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          CHAPTER_PARENT_NAME: Chapter_Parent_Name,
          NAME: Name,
          DEPENDED_CHAPTER_NAME: Dependent_Chapter_Name,
          DESCRIPTION: Description,
        }) => ({
          Course_Name,
          Chapter_Parent_Name,
          Name,
          Dependent_Chapter_Name,
          DESCRIPTION: Description,
        })
      );
      this.converted = this.dataList;

      //this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course', CHAPTER_PARENT_NAME: 'Parent Name', NAME: 'Name', DEPENDED_CHAPTER_NAME: 'Dependent Chapter Name', DESCRIPTION: 'Description'});
    } else if (this.fileName == "Documents") {
      this.dataList = this.dataList.map(
        ({ SHIKUYAA_CHAPTER_NAME, NAME, SEQ_NO }) => ({
          SHIKUYAA_CHAPTER_NAME,
          NAME,
          SEQ_NO,
        })
      );
      this.converted = this.rekey(this.dataList, {
        SHIKUYAA_CHAPTER_NAME: "Course",
        NAME: "Name",
        SEQ_NO: "Sequence No",
      });
    } else if (this.fileName == "VideoQuestionTypes") {
      this.dataList = this.dataList.map(({ NAME }) => ({ NAME }));
      this.converted = this.rekey(this.dataList, { NAME: "Name" });
    } else if (this.fileName == "Videos") {
      this.dataList = this.dataList.map(
        ({ CHAPTER_NAME, NAME, DESCRIPTION, URL_KEY, DURATION }) => ({
          CHAPTER_NAME,
          NAME,
          DESCRIPTION,
          URL_KEY,
          DURATION,
        })
      );
      this.converted = this.rekey(this.dataList, {
        CHAPTER_NAME: "Chapter ",
        NAME: "Name",
        DESCRIPTION: "Description",
        URL_KEY: "Url Key",
        DURATION: "Duration",
      });
    } else if (this.fileName == "LiveVideos") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME,
          NAME,
          DESCRIPTION,
          START_DATE_TIME,
          DISABLE_DATE_TIME,
          VIDEO_KEY,
        }) => ({
          COURSE_NAME,
          NAME,
          DESCRIPTION,
          START_DATE_TIME,
          DISABLE_DATE_TIME,
          VIDEO_KEY,
        })
      );
      this.converted = this.rekey(this.dataList, {
        COURSE_NAME: "Course",
        NAME: "Name",
        DESCRIPTION: "Description",
        START_DATE_TIME: "Start Time",
        DISABLE_DATE_TIME: "Disable Time",
        VIDEO_KEY: "Video Key",
      });
    } else if (this.fileName == "CourseParagraph") {
      this.dataList = this.dataList.map(({ PARAGRAPH_TEXT }) => ({
        PARAGRAPH_TEXT,
      }));
      this.converted = this.rekey(this.dataList, {
        PARAGRAPH_TEXT: "Paragraph Text",
      });
    } else if (this.fileName == "CourseQuestions") {
      this.dataList = this.dataList.map(
        ({
          CHAPTER_NAME,
          QUESTION_TYPE_NAME,
          LEVEL_NAME,
          DIRECTION,
          DESCRITION,
          QUESTION,
          SOLUTION,
          SOLUTION_VIDEO_URL,
        }) => ({
          CHAPTER_NAME,
          QUESTION_TYPE_NAME,
          LEVEL_NAME,
          DIRECTION,
          DESCRITION,
          QUESTION,
          SOLUTION,
          SOLUTION_VIDEO_URL,
        })
      );
      this.converted = this.rekey(this.dataList, {
        CHAPTER_NAME: "Chapter Name",
        QUESTION_TYPE_NAME: "Question Type Name",
        LEVEL_NAME: "Level Name",
        DIRECTION: "Direction",
        DESCRITION: "Description",
        QUESTION: "Question",
        SOLUTION: "Solution",
        SOLUTION_VIDEO_URL: "Solution Video Url",
      });
    } else if (this.fileName == "CouponFacilities") {
      this.dataList = this.dataList.map(({ NAME }) => ({ NAME }));
      this.converted = this.rekey(this.dataList, { NAME: "Name" });
    } else if (this.fileName == "Coupons") {
      this.dataList = this.dataList.map(
        ({
          NAME,
          START_DATE,
          EXPIRY_DATE,
          MAX_USES_COUNT,
          COUPON_CODE,
          COUPON_VALUE,
          COUPON_MAX_VALUE,
          PERUSER_MAX_COUNT,
          MIN_CART_AMOUNT,
        }) => ({
          NAME,
          START_DATE,
          EXPIRY_DATE,
          MAX_USES_COUNT,
          COUPON_CODE,
          COUPON_VALUE,
          COUPON_MAX_VALUE,
          PERUSER_MAX_COUNT,
          MIN_CART_AMOUNT,
        })
      );
      this.converted = this.rekey(this.dataList, {
        NAME: "Name",
        START_DATE: "Start Date",
        EXPIRY_DATE: "Expiry Date",
        MAX_USES_COUNT: "Max Uses Count",
        COUPON_CODE: "Coupon Code",
        COUPON_VALUE: "Coupon Value",
        COUPON_MAX_VALUE: "Coupon Max Value",
        PERUSER_MAX_COUNT: "Per User Max Count",
        MIN_CART_AMOUNT: "Min Cart Amount",
      });
    } else if (this.fileName == "PredefinedMock") {
      this.dataList = this.dataList.map(
        ({ COURSE_NAME, NAME, PRICE, NO_OF_QUESTION, TOTAL_TIME }) => ({
          COURSE_NAME,
          NAME,
          PRICE,
          NO_OF_QUESTION,
          TOTAL_TIME,
        })
      );
      this.converted = this.rekey(this.dataList, {
        COURSE_NAME: "Course Name",
        NAME: "Name",
        PRICE: "Price",
        NO_OF_QUESTION: "No Of Questions",
        TOTAL_TIME: "Total Time",
      });
    } else if (this.fileName == "StudentCourseList") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          F_NAME: F_Name,
          M_NAME: M_Name,
          L_NAME: L_Name,
          MOBILE_NO: Mobile,
          WHATS_APP_NO: WhatsApp,
          EMAIL_ID: Email,
          REGISTRATION_DATE: Registration_date,
          PURCHASE_DATE: Purchase_Date,
          REFERENCE_COUPON_CODE: Reference_Coupon_Code,
          MAIN_WALLET_BALANCE: Main_Wallet_Balance,
          COURSE_PAID_AMOUNT: Course_Paid_Amount,
          POINTS: Points,
        }) => ({
          Course_Name,
          F_Name,
          M_Name,
          L_Name,
          Mobile,
          WhatsApp,
          Email,
          Registration_date,
          Purchase_Date,
          Reference_Coupon_Code,
          Main_Wallet_Balance,
          Course_Paid_Amount,
          Points,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "VideoStatsList") {
      this.dataList = this.dataList.map(
        ({
          VIDEO_NAME: Video_Name,
          SHARE_COUNT: Share_Count,
          DOUBTS_COUNT: Doubts_Count,
          WACHED_USER_COUNT: Watched_User_count,
          DURATION: Duration,
          AVG_VIDEO_WATCHED: Ang_Video_Watched,
        }) => ({
          Video_Name,
          Share_Count,
          Doubts_Count,
          Watched_User_count,
          Duration,
          Ang_Video_Watched,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "CourseSummery") {
      this.dataList = this.dataList.map(
        ({
          NAME: Name,
          FREE_TRIAL_TAKENS: Free_Trial_Taken,
          SUBSCRIBERS: Subcribers,
          RATINGS: Ratings,
          VIDEO_COUNT: Videos_Count,
          LIVE_COMPLETED_VIDEOS: Live_Completed_Videos,
          MOCK_COUNT: Mock_count,
          CHAPTERS_COUNT: Chapter_Count,
          QUESTIONS_COUNT: Question_Count,
          REFERENCE_COUPON_CODE: Reference_Coupon_Code,
          TOTAL_STUDENT_COUNT: Total_Student_Count,
        }) => ({
          Name,
          Free_Trial_Taken,
          Subcribers,
          Ratings,
          Videos_Count,
          Live_Completed_Videos,
          Mock_count,
          Chapter_Count,
          Question_Count,
          Reference_Coupon_Code,
          Total_Student_Count,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "ChapterSummery") {
      this.dataList = this.dataList.map(
        ({
          NAME: Name,
          CHILDREN_CHAPTER_: Children_Count,
          TOTAL_PRACTICE_QUESTIONS: Practice_Que_count,
          TOTAL_PREDEFINED_MOCK_QUESTIONS: Predefined_Mock_Que_count,
          TOTAL_LIVE_MOCK_QUESTIONS: Live_Mock_Que_Count,
          TOTAL_DOCUMENTS: Document_Count,
          VIDEO_COUNT: Video_Count,
        }) => ({
          Name,
          Children_Count,
          Practice_Que_count,
          Predefined_Mock_Que_count,
          Live_Mock_Que_Count,
          Document_Count,
          Video_Count,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "LiveVideoSummery") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          NAME: Name,
          LIKE_COUNT: Like_Count,
          DISLIKE_COUNT: Dislike_Count,
          START_DATE_TIME: Start_Date,
          DISABLE_DATE_TIME: End_Date,
          DURATION: Duration,
          TOTAL_LIVE_TIME: Total_Live_time,
          TOTAL_OFFLINE_TIME: Total_Offline_Time,
          TOTAL_TIME: Total_Watched,
          AVG_TIME: Avg_Watched_Time,
          TOTAL_STUDENTS: Total_Students,
          WATCHED_COUNT: Total_Watched_Video_Students,
          TOTAL_COMMENTS: Total_Chat,
          TOTAL_POLES: Total_Poll,
          TOTAL_FFF: Total_ffff,
        }) => ({
          Course_Name,
          Name,
          Like_Count,
          Dislike_Count,
          Start_Date,
          END_DATE: End_Date,
          Duration,
          Total_Live_time,
          Total_Offline_Time,
          Total_Watched,
          Total_Watched_Video_Students,
          Avg_Watched_Time,
          Total_Students,
          Total_Chat,
          Total_Poll,
          Total_ffff,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "VideoSummery") {
      this.dataList = this.dataList.map(
        ({
          CHAPTER_NAME: Course_Name,
          NAME: Name,
          LIKE_COUNT: Like_Count,
          DISLIKE_COUNT: Dislike_Count,
          TOTAL_TIME: Total_Watched,
          AVG_TIME: Avg_Watched_Time,
          TOTAL_REGISTERED_STUDENTS: Registered_Students,
          TOTAL_STUDENTS: Total_Students,
          WATCHED_COUNT: Total_Watched_Video_Students,
          TOTAL_QUESTIONS: Total_Questions,
        }) => ({
          Course_Name,
          Name,
          Like_Count,
          Dislike_Count,
          Registered_Students,
          Total_Watched,
          Total_Watched_Video_Students,
          Avg_Watched_Time,
          Total_Students,
          Total_Questions,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "StudentVideoSummery") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Name,
          VIDEOS: Total_Videos,
          COMPLETE_WATCHED_VIDEOS: COmpleted_Videos,
          TOTAL_DURATION: Duration,
          COMPLETE_WATCHED_SEC: Watched_Duration,
          QUESTIONS: Total_Questions,
          SOLVED_QUESTIONS: Solved_QUestions,
          LIKES: Like_Count,
          DISLIKES: Dislike_Count,
        }) => ({
          Name,
          Like_Count,
          Dislike_Count,
          Total_Videos,
          Total_Questions,
          COmpleted_Videos,
          Watched_Duration,
          Duration,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "StudentPracticeSummery") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          P_QUESTIONS: Total_questions,
          SOLVED: solved_questions,
          CORRECT: Correct,
          INCORRECT: Incorrect,
          PERCENTAGE: percentage,
          PERFORMANCE_PERCENTAGE: performance_index,
          AVG_SECONDS: Average_time,
          SOLVED_SECONDS: Total_time,
        }) => ({
          Student_Name,
          Total_questions,
          solved_questions,
          Correct,
          Incorrect,
          percentage,
          performance_index,
          Average_time,
          Total_time,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "PracticeSummery") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          NAME: Chapter_Name,
          TOTAL_CHAPTER_PRACTICE_QUESTION: Total_Questions,
          AVG_SOLVED_QUESTIONS: Average_solved_count,
          REGISTERED_STUDENTS: Course_Student_Count,
          SOLVED_STUDENTS: solved_Student_Count,
          COMPLETE_SOLVED_STUDENTS: Completly_solved_Count,
          AVG_PERCENTAGE: Average_Percentage,
          AVG_PERFORMANCE_PERCENTAGE: Average_Performance_Index,
        }) => ({
          Course_Name,
          Chapter_Name,
          Total_Questions,
          Average_solved_count,
          Course_Student_Count,
          solved_Student_Count,
          Completly_solved_Count,
          Average_Percentage,
          Average_Performance_Index,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "LiveVideoReport") {
      this.dataList = this.dataList.map(
        ({
          LIVE_VIDEO_NAME: Name,
          F_NAME: First_Name,
          M_NAME: Middle_Name,
          L_NAME: Last_Name,
          MOBILE_NO: Mobile_No,
          LIVE_TIME: Online_Time,
          OFFLINE_TIME: Offline_Time,
        }) => ({
          Name,
          First_Name,
          Middle_Name,
          Last_Name,
          Mobile_No,
          Online_Time,
          Offline_Time,
        })
      );
      this.converted = this.dataList;

      // this.converted =  this.rekey(this.dataList, { COURSE_NAME:'Course Name', F_NAME:'First Name', M_NAME:'Middle Name', L_NAME:'Last Name',MOBILE_NO:'Mobile No', WHATS_APP_NO:'Whats App No',EMAIL_ID:'Email Id',REGISTRATION_DATE:'Registration Date',PURCHASE_DATE:'Purchase Date',REFERENCE_COUPON_CODE:'Reference Coupon Code',MAIN_WALLET_BALANCE:'Main Wallet Balance',COURSE_PAID_AMOUNT:'Course Paid Amount',POINTS:'Points'});
    } else if (this.fileName == "SessionReport") {
      this.dataList = this.dataList.map(
        ({
          DATE: Date,
          START_TIME_END_TIME_TEXT: Time,
          NAME: Name,
          TRAINER_NAME: Trainer_Name,
          BATCH_NAME: Batch_Name,
          TOTAL_STUDENTS: Total_Students,
          START_TIME: Start_Time,
          COMPLETED_TIME: Completed_Time,
          TRAINER_RATINGS: Trainer_Ratings,
        }) => ({
          Date,
          Time,
          Name,
          Trainer_Name,
          Batch_Name,
          Total_Students,
          Start_Time,
          Completed_Time,
          Trainer_Ratings,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "MandayCalculationReport") {
      this.dataList = this.dataList.map(
        ({
          SR_NO: SrNo,
          TRAINER_NAME: Trainer_Name,
          DATE: Date,
          COLLEGE_NAME: College_Name,
          TIME_SPAN: Time_Span,
          MANDAYS: Mandays,
        }) => ({ SrNo, Trainer_Name, Date, College_Name, Time_Span, Mandays })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "RenumerationCalculationReport") {
      this.dataList = this.dataList.map(
        ({
          SR_NO: SrNo,
          TRAINER_NAME: Trainer_Name,
          DATE: Date,
          COLLEGE_NAME: College_Name,
          TIME_SPAN: Time_Span,
          MANDAYS: Mandays,
          EXTRA_HRS: ExtraHrs,
        }) => ({
          SrNo,
          Trainer_Name,
          Date,
          College_Name,
          Time_Span,
          Mandays,
          ExtraHrs,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "RatingsReport") {
      this.dataList = this.dataList.map(
        ({
          SR_NO: SrNo,
          TRAINER_NAME: Trainer_Name,
          COLLEGE_NAME: College_Name,
          DATE: Date,
          BATCH_NAME: Batch_Name,
          AVERAGE_RATING: Avearage_Rating_Per_Session,
          STUDENT_COUNT: Student_Count,
          AVERAGE_RATING_PERCENTAGE: Average_Rating_Percentage,
          AVERAGE_RATING_PERCENTAGE1: Average_Rating_Percentage1,
        }) => ({
          SrNo,
          Trainer_Name,
          College_Name,
          Date,
          Batch_Name,
          Avearage_Rating_Per_Session,
          Student_Count,
          Average_Rating_Percentage,
          Average_Rating_Percentage1,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SuggeestionReport") {
      this.dataList = this.dataList.map(
        ({
          SR_NO: SrNo,
          TRAINER_NAME: Trainer_Name,
          DATE: Date,
          COLLEGE_NAME: College_Name,
          BATCH_NAME: Batch_Name,
          SUGGEESTION_COUNT: Suggeestion_Count,
          SUGGEESTIONS: Suggeestion,
        }) => ({
          SrNo,
          Trainer_Name,
          Date,
          College_Name,
          Batch_Name,
          Suggeestion_Count,
          Suggeestion,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SyllabusCompletionTrackReport") {
      // this.dataList=this.dataList.map(({
      //   SR_NO:SrNo,TRAINER_NAME:Trainer_Name,DATE:Date,COLLEGE_NAME:College_Name,BATCH_NAME:Batch_Name,SUGGESTION_COUNT:Suggeestion_Count,SUGGESSTION:Suggeestion}) =>
      //  ({SrNo,Trainer_Name,Date,College_Name,Batch_Name,Suggeestion_Count,Suggeestion}));
      //  this.converted =  this.dataList
    } else if (this.fileName == "StudentRegistration") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          DOB: DOB,
          GENDER: Gender,
          ADDRESS: Address,
          CITY: City,
          PINCODE: Pincode,
          PRN: Prn_No,
          REGISTRATION_DATE: Registration_Date,
          VERSION: App_Version,
          YEAR_NAME: Passing_Year,
          COLLEGE_NAME: College_Name,
          BRANCH_NAME: Branch_Name,
          COLLEGE_STATE_NAME: State_Name,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          DOB,
          Gender,
          Address,
          City,
          Pincode,
          Prn_No,
          Registration_Date,
          App_Version,
          Passing_Year,
          College_Name,
          Branch_Name,
          State_Name,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentCourseJoining") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          COURSE_NAME: Course_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          COURSE_PURCHASED: Course_Prchased,
          PURCHASE_DATE: Prchase_Date,
          IS_FREE_TRIAL_TAKEN: Free_Trial_Taken,
          FREE_TRIAL_DATE: Free_Trial_Date,
          STATUS: Status,
        }) => ({
          Student_Name,
          Course_Name,
          Email,
          Mobile,
          Course_Prchased,
          Prchase_Date,
          Free_Trial_Taken,
          Free_Trial_Date,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentRegCompleted") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          MOBILE_NO: Mobile,
          REGISTRATION_DATE: Registration_Date,
        }) => ({ Student_Name, Mobile, Registration_Date })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentRegPending") {
      this.dataList = this.dataList.map(
        ({
          MOBILE_NO: Mobile,
          CREATED_MODIFIED_DATE: Entered_Date,
          MOBILE_VERIFICATION_DATETIME: Verified_date,
        }) => ({ Mobile, Entered_Date, Verified_date })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          IS_VERIFIED: Verified,
          IS_EMAIL_VERIFIED: Email_Verified,
          IS_MOBILE_VERIFIED: Mobile_Verified,
          YEAR_NAME: Passing_Year,
          COLLEGE_NAME: College_Name,
          BRANCH_NAME: Branch_Name,
          COLLEGE_STATE_NAME: State_Name,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Verified,
          Email_Verified,
          Mobile_Verified,
          Passing_Year,
          College_Name,
          Branch_Name,
          State_Name,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentAppUsage") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          CURR_COURSE_NAME: current_course,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          REGISTRATION_DATE: Registration_Date,
          STATUS: Status,
          YEAR_NAME: Passing_Year,
          COLLEGE_NAME: College_Name,
          BRANCH_NAME: Branch_Name,
          COLLEGE_STATE_NAME: State_Name,
          LIVE_SECONDS: Live_Video_Watched_time,
          VIDEO_SECONDS: SelfPaced_Video_Watched_time,
          PRACTICE_SECONDS: Practice_Solving_Used_time,
          MOCK_SECONDS: Assessment_Solving_Used_time,
          TOTAL_SECONDS: Total_Usage,
        }) => ({
          Student_Name,
          current_course,
          Email,
          Mobile,
          Registration_Date,
          Status,
          Passing_Year,
          College_Name,
          Branch_Name,
          State_Name,
          Live_Video_Watched_time,
          SelfPaced_Video_Watched_time,
          Practice_Solving_Used_time,
          Assessment_Solving_Used_time,
          Total_Usage,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentAppDetails") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          CURR_COURSE_NAME: current_course,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          VERSION: Latest_Version,
          REGISTRATION_DATE: Registration_Date,
        }) => ({
          Student_Name,
          current_course,
          Email,
          Mobile,
          Latest_Version,
          Registration_Date,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentCourseStats") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          TOTAL_COURSES_COUNT: Current_Count,
          PURCHASED_COURSES_COUNT: Paid_Courses_Count,
          FREE_COURSES_COUNT: Enrolled_Course_Count,
          FREE_TRIAL_TAKEN_COUNT: Free_Trial_Taken_Count,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Current_Count,
          Paid_Courses_Count,
          Enrolled_Course_Count,
          Free_Trial_Taken_Count,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentCourseSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          COURSE_NAME: Current_Course,
          IS_PURCHASED: Is_Purchased,
          PURCHASE_DATE: Purchased_Date,
          IS_FREE_TRIAL_TAKEN: Is_Free_Tial_Taken,
          FREE_TRIAL_DATE: Free_Trial_Date,
          TOTAL_PRACTICE_QUESTIONS: Total_Practice_Questions,
          P_SOLVED: Solved_Practice_Questions,
          TOTAL_LIVE_VIDEOES: Total_Live_Videos,
          WATCHED_LIVE: Watched_Live_Videos,
          TOTAL_VIDEOS: Total_Preloaded_Videos,
          WATCHED_VIDEOS: Watched_Preloaded_Videos,
          TOTAL_CHAPTERS: Total_Chapters_Videos,
        }) => ({
          Student_Name,
          Current_Course,
          Is_Purchased,
          Purchased_Date,
          Is_Free_Tial_Taken,
          Free_Trial_Date,
          Total_Practice_Questions,
          Solved_Practice_Questions,
          Total_Live_Videos,
          Watched_Live_Videos,
          Total_Preloaded_Videos,
          Watched_Preloaded_Videos,
          Total_Chapters_Videos,
        })
      );
      this.converted = this.dataList;

      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          COURSE_NAME: Course_Name,
          TOTAL_PRACTICE_QUESTIONS: Total_Questions,
          P_SOLVED: solved,
          P_CORRECT: Correct,
          P_INCORRECT: In_Correct,
          P_PERCENTAGE: Correct_Percentage,
          P_ACCURACY: Accuracy,
          P_COMPLETION_PERCENTAGE: Completion_Percentage,
        }) => ({
          Student_Name,
          Course_Name,
          Total_Questions,
          solved,
          Correct,
          In_Correct,
          Correct_Percentage,
          Accuracy,
          Completion_Percentage,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Studentcoursevideosummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          COURSE_NAME: Course_Name,
          TOTAL_VIDEO_COUNT: Total_Videos,
          COMPLETED_VIDEOS: Completed_Videos,
          TOTAL_VIDEO_TIME: Total_Time,
          AVERAGE_WATCHED_TIME: Avg_Watched_Time,
          TOTAL_WATCHED_VIDEO_TIME: Total_Watched_Time,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Course_Name,
          Total_Videos,
          Completed_Videos,
          Total_Time,
          Avg_Watched_Time,
          Total_Watched_Time,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentCourseLiveVideoSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          COURSE_NAME: Course_Name,
          TOTAL_LIVE_VIDEOS: Total_Live_Videos,
          WATCHED_LIVE_VIDEOS: Watched_Live_Videos,
          TOTAL_POLE_QUESTION: Total_Pole_Questions,
          TOTAL_SOLVED_POLE_QUESTIONS: Solved_Pole_Questions,
          TOTAL_FFF_QUESTION: Total_Fastest_Finger_Questions,
          TOTAL_SOLVED_FFF_QUESTIONS: Solved_Fastest_Finger_Questions,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Course_Name,
          Total_Live_Videos,
          Watched_Live_Videos,
          Total_Pole_Questions,
          Solved_Pole_Questions,
          Total_Fastest_Finger_Questions,
          Solved_Fastest_Finger_Questions,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Studentcourseassesmentsummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          COURSE_NAME: Course_Name,
          TOTAL_MOCK_TESTS: Total_Assesments,
          COMPLETED_MOCKS: Solved_Assesments,
          TOTAL_QUESTIONS: Total_Questions,
          TOTAL_SOLVED_QUESTIONS: Solved_Questions,
          AVG_PERCENTAGE: Percentage,
          TOTAL_SECONDS: Total_Time,
          TOTAL_SOLVED_SECONDS: Taken_Time,
          ACCURACY_PERCENT: Accuracy,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Course_Name,
          Total_Assesments,
          Solved_Assesments,
          Total_Questions,
          Solved_Questions,
          Percentage,
          Total_Time,
          Taken_Time,
          Accuracy,
        })
      );

      this.converted = this.dataList;
    } else if (this.fileName == "Studentpracticedetails") {
      this.dataList = this.dataList.map(
        ({
          CHAPTER_NAME: Chapter_Name,
          STUDENT_NAME: studentName,
          TOTAL_CHAPTER_PRACTICE_QUESTION: TotalQuestions,
          P_CORRECT_CS: Solved,
          TOTAL_SENCONDS_FOR_SOLVED_QUESTIONS: Total_Time,
          P_SOLVED_SECONDS: Solved_Time,
          P_PERFORMANCE: Performance_Percentage,
          P_PERCENTAGE_CS: Percentage,
          P_SECONDS: TotalSeconds,
        }) => ({
          Chapter_Name,
          TotalQuestions,
          studentName,
          Solved,
          Total_Time,
          Solved_Time,
          Percentage,
          TotalSeconds,
          Performance_Percentage,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Studentvideodetails") {
      this.dataList = this.dataList.map(
        ({
          CHAPTER_NAME: Chapter_Name,
          STUDENT_NAME: stundetName,
          VIDEO_NAME: Video_Name,
          DURATION: Total_Time,
          WATCHED_SECONDS: Watch_Time,
          AVG_VIDEO_WATCHED: Avg_Video_Watched,
        }) => ({
          Chapter_Name,
          stundetName,
          Video_Name,
          Total_Time,
          Watch_Time,
          Avg_Video_Watched,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Studentlivevideodetails") {
      this.dataList = this.dataList.map(
        ({
          LIVE_VIDEO_NAME: Live_Video_Name,
          F_NAME: Fname,
          COURSE_NAME: courseName,
          LIVE_TIME: Total_Time,
          WATCHED_SECONDS: Watched_Seconds,
          TOTAL_COMMENTS_BY_STUDENT: Comment_Count,
          TOTAL_POLES: Total_Poles,
          TOTAL_POLES_ANSWERED: Answered_Poles,
          TOTAL_FFF_QUESTION: Total_Fastest_Finger,
          TOTAL_SOLVED_FFF_QUESTIONS: Solved_Fastest_Finger,
        }) => ({
          Live_Video_Name,
          Total_Time,
          Fname,
          courseName,
          Watched_Seconds,
          Comment_Count,
          Total_Poles,
          Answered_Poles,
          Total_Fastest_Finger,
          Solved_Fastest_Finger,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Studentassesmentdetails") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: student_name,
          MAPPED_NAME: Assesment_Name,
          TOTAL: Total_Questions,
          SOLVED: Solved_Questions,
          TOTAL_SEC: Total_Time,
          SOLVED_SEC: Solved_Time,
          MARKS: Total_Marks,
          PERCENTAGE: Percentage,
          ACCURACY: Accuracy,
          PERFORMANCE: Performance,
        }) => ({
          student_name,
          Assesment_Name,
          Total_Questions,
          Solved_Questions,
          Total_Time,
          Solved_Time,
          Total_Marks,
          Percentage,
          Accuracy,
          Performance,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentLiveVideoCommentsDetails") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          LIVE_VIDEO_NAME: Video_Name,
          COMMENT_TEXT: Comment,
          TIME_STAMP: Time,
          STATUS: Status,
        }) => ({
          Student_Name,
          Video_Name,
          Comment,
          Time,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentLiveVideoPoleDetails") {
      this.dataList = this.dataList.map(
        ({
          LIVE_NAME: Live_Video_Name,
          STUDENT_NAME: Student_Name,
          POLE_NAME: Pole_Name,
          POLE_TYPE: Pole_Type,
          QUESTION: Question,
          OPTION_1: Option1,
          OPTION_2: Option2,
          OPTION_3: Option3,
          OPTION_4: Option4,
          OPTION_5: Option5,
          IS_SOLVED: Is_Solved,
          USER_ANSWER: User_Answer,
        }) => ({
          Live_Video_Name,
          Student_Name,
          Pole_Name,
          Pole_Type,
          Question,
          Option1,
          Option2,
          Option3,
          Option4,
          Option5,
          Is_Solved,
          User_Answer,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentLiveVideoFFFDetails") {
      this.dataList = this.dataList.map(
        ({
          QUESTION: Fastest_Finger_Question,
          IS_SOLVED: Is_Solved,
          USER_ANSWER: User_Answer,
          SECONDS: Solved_Time,
          IS_CORRECT: Is_Correct,
        }) => ({
          Fastest_Finger_Question,
          Is_Solved,
          User_Answer,
          Solved_Time,
          Is_Correct,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentPaymentReport") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          TRANSACTION_DATE: Payment_Date,
          TOTAL_AMOUNT: Total_Amount,
        }) => ({
          Student_Name,
          Payment_Date,
          Total_Amount,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentEmailDetail") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          SUBJECT: Subject,
          EMAIL_TEXT: Email_Message,
          STATUS: Status,
        }) => ({
          Student_Name,
          Email,
          Subject,
          Email_Message,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentEmailSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          SUCCESS_COUNT: Success_Count,
          FAILED_COUNT: Failed_Count,
          TOTAL_EMAIL_SENT: Delivered_Count,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Success_Count,
          Failed_Count,
          Delivered_Count,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentSMSDetail") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          MOBILE_NO: Mobile,
          SMS_TEXT: Message,
          STATUS: Status,
        }) => ({
          Student_Name,
          Mobile,
          Message,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentSMSSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          SUCCESS_COUNT: Success_Count,
          FAILED_COUNT: Failed_Count,
          TOTAL_EMAIL_SENT: Delivered_Count,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Success_Count,
          Failed_Count,
          Delivered_Count,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentCouponSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile,
          TOTAL_COUPONS_USED: Total_Coupons_Used,
          TOTAL_COUPONS_USED_FOR_COURSE_BUY: Used_For_Course_Buy,
          TOTAL_COUPONS_USED_FOR_MOCK_BUY: Used_For_Mock_Buy,
        }) => ({
          Student_Name,
          Email,
          Mobile,
          Total_Coupons_Used,
          Used_For_Course_Buy,
          Used_For_Mock_Buy,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentCouponDetail") {
      this.dataList = this.dataList.map(
        ({
          COUPON_NAME: Coupon_Name,
          COUPON_CODE: Coupon_Code,
          COUPON_VALUE: Coupon_Value,
          MIN_CART_AMOUNT: Min_Cart_Amount,
        }) => ({
          Coupon_Name,
          Coupon_Code,
          Coupon_Value,
          Min_Cart_Amount,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentPaymentDetail") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: course_Name,
          STUDENT_NAME: Student_Name,
          TRANSACTION_DATE: Payment_Date,
          TOTAL_AMOUNT: Total_Amount,
          COUPON_CODE: Coupan_Used,
          APPLIED_WALLET_BALANCE: wallet_balance,
          NET_AMOUNT: net_amount,
        }) => ({
          Student_Name,
          Payment_Date,
          Total_Amount,
          Coupan_Used,
          course_Name,
          wallet_balance,
          net_amount,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentWalletSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          MAIN_WALLET_BALANCE: Main_Wallet_Balance,
          VIRTUAL_WALLET_BALANCE: Virtual_Wallet_Balance,
          TOTAL_TRANSACTIONS: Total_Wallet_Transactions,
        }) => ({
          Student_Name,
          Main_Wallet_Balance,
          Virtual_Wallet_Balance,
          Total_Wallet_Transactions,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentWalletDetail") {
      this.dataList = this.dataList.map(
        ({
          DATE: Date,
          TRANSACTION_TYPE: Transaction_Type,
          TYPE: Type,
          PARTICULAR: Particular,
          AMOUNT: Amount,
        }) => ({
          Date,
          Transaction_Type,
          Type,
          Particular,
          Amount,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseSummary") {
      this.dataList = this.dataList.map(
        ({
          NAME: Course_Name,
          STUDENT_COUNT: Student_Count,
          COURSE_FEE: Course_Amount,
          FREE_ENROLLED: Free_Enrolled,
          PAID_ENROLLED: Paid_Enrolled,
          COUPONS_USED: Coupons_Used,
          TOTAL_COLLECTED_AMOUNT: Collected_Amount,
        }) => ({
          Course_Name,
          Student_Count,
          Course_Amount,
          Free_Enrolled,
          Paid_Enrolled,
          Coupons_Used,
          Collected_Amount,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CoursePayment") {
      this.dataList = this.dataList.map(
        ({
          TRANSACTION_DATE: Date_Time,
          COURSE_NAME: Course_Name,
          STUDENT_NAME: Student_Count,
          COUPON_AMOUNT: Coupon_Amount,
          TOTAL_AMOUNT: Total_Amount,
          COUPON_CODE: Coupons_Used,
          APPLIED_WALLET_BALANCE: Wallet_Amount,
        }) => ({
          Date_Time,
          Course_Name,
          Student_Count,
          Coupon_Amount,
          Total_Amount,
          Coupons_Used,
          Wallet_Amount,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseStructureSummary") {
      this.dataList = this.dataList.map(
        ({
          NAME: Course_Name,
          TOTAL_CHAPTERS: Total_Chapter,
          TOTAL_QUESTIONS: Total_Practice_Question,
          TOTAL_VIDEOS: Watchable_Videos,
          TOTAL_VIDEO_DURATION: Total_Videos_Time,
          LIVE_VIDEO_TOTAL_TIME: Live_Video_Total_Time,
          TOTAL_LIVE_VIDEOS: Live_Video_Count,
          TOTAL_MOCKS: Assesment_Count,
          TOTAL_MOCKS_QUESTIONS: Assesment_Question_Count,
        }) => ({
          Course_Name,
          Total_Chapter,
          Total_Practice_Question,
          Watchable_Videos,
          Total_Videos_Time,
          Live_Video_Total_Time,
          Live_Video_Count,
          Assesment_Count,
          Assesment_Question_Count,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseChapterSummary") {
      this.dataList = this.dataList.map(
        ({
          NAME: Chapter_Name,
          TOTAL_QUESTINOS: Practice_Question,
          TOTAL_VIDEOS: Total_Videos,
          SUB_CHAPTER_COUNT: Subchapters,
          ACCURACY_PERCENT: Avg_Accuracy_of_Practice_Questions,
          AVERAGE_PERCENTAGE_OF_PRACTICE_QUESTIONS:
          Avg_Percentage_of_Practice_Questions,
          TOTAL_VIDEO_TIME: Total_Video_Time,
        }) => ({
          Chapter_Name,
          Practice_Question,
          Total_Videos,
          Subchapters,
          Avg_Accuracy_of_Practice_Questions,
          Avg_Percentage_of_Practice_Questions,
          Total_Video_Time,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseVideoSummary") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          VIDEO_NAME: Video_Name,
          URL_KEY: Video_URL,
          AVG_VIDEO_WATCHED: Average_Watch_Time,
          DURATION: Total_Video_Watch_Time,
        }) => ({
          Course_Name,
          Video_Name,
          Video_URL,
          Average_Watch_Time,
          Total_Video_Watch_Time,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseLiveVideoSummary") {
      this.dataList = this.dataList.map(
        ({
          NAME: Live_Video_Name,
          AVG_WACHED_SECONDS: Watched_Seconds,
          TOTAL_COMMENTS: Comment_Count,
          TOTAL_POLES: Total_Poles,
          TOTAL_USERS_ANSWERED_FOR_POLES: Answered_Poles,
          TOTAL_FFF: Total_Fastest_Fingers,
          TOTAL_USERS_ANSWERED_FOR_FFF: Solved_Fastest_Fingers,
        }) => ({
          Live_Video_Name,
          Watched_Seconds,
          Comment_Count,
          Total_Poles,
          Answered_Poles,
          Total_Fastest_Fingers,
          Solved_Fastest_Fingers,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseLiveVideoStudentDetails") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          LIVE_VIDEO_NAME: Live_Video_Name,
          F_NAME: Student_Name,
          LIVE_TIME: Live_Time,
          OFFLINE_TIME: Offline_Time,
        }) => ({
          Course_Name,
          Live_Video_Name,
          Student_Name,
          Live_Time,
          Offline_Time,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseLiveVideoPoleDetails") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          LIVE_NAME: Video_Name,
          POLE_NAME: Pole_Name,
          QUESTION: Question,
          OPTION_1: Option1,
          OPTION_2: Option2,
          OPTION_3: Option3,
          OPTION_4: Option4,
          OPTION_5: Option5,
          TOTAL_USERS_ANSWERD: Student_Count,
          STATUS: Pole_Status,
          POLE_TYPE: Pole_Type,
          PUBLISHED_ON: Published_On,
          COMPLETED_ON: Completed_On,
          RESULT_DISPLAYED_ON: Result_Displayed_On,
          TOTAL_VIEWS: Total_Views,
          TOTAL_ANSWERED: Total_Answered,
          OPTION_1_ANSWERED: Option1_Answered,
          OPTION_2_ANSWERED: Option2_Answered,
          OPTION_3_ANSWERED: Option3_Answered,
          OPTION_4_ANSWERED: Option4_Answered,
          OPTION_5_ANSWERED: Option5_Answered,
        }) => ({
          Course_Name,
          Video_Name,
          Pole_Name,
          Question,
          Option1,
          Option2,
          Option3,
          Option4,
          Option5,
          Student_Count,
          Pole_Status,
          Pole_Type,
          Published_On,
          Completed_On,
          Result_Displayed_On,
          Total_Views,
          Total_Answered,
          Option1_Answered,
          Option2_Answered,
          Option3_Answered,
          Option4_Answered,
          Option5_Answered,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseLiveVideoPoleStudentDetails") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          LIVE_NAME: Live_Video_Name,
          POLE_NAME: Pole_Name,
          POLE_TYPE: Pole_Type,
          QUESTION: Question,
          OPTION_1: Option1,
          OPTION_2: Option2,
          OPTION_3: Option3,
          OPTION_4: Option4,
          OPTION_5: Option5,
          OPTION_SELECTED: Option_Selected,
        }) => ({
          Student_Name,
          Live_Video_Name,
          Pole_Name,
          Pole_Type,
          Question,
          Option1,
          Option2,
          Option3,
          Option4,
          Option5,
          Option_Selected,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseLiveVideoFFFDetails") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          LIVE_NAME: Video_Name,
          FFF_NAME: Fastest_Finger_Name,
          QUESTION: Question,
          OPTION_1: Option1,
          OPTION_2: Option2,
          OPTION_3: Option3,
          OPTION_4: Option4,
          OPTION_5: Option5,
          TOTAL_USERS_ANSWERD: Student_Count,
          STATUS: Pole_Status,
          POLE_TYPE: Pole_Type,
          PUBLISHED_ON: Published_On,
        }) => ({
          Course_Name,
          Video_Name,
          Fastest_Finger_Name,
          Question,
          Option1,
          Option2,
          Option3,
          Option4,
          Option5,
          Student_Count,
          Pole_Status,
          Pole_Type,
          Published_On,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseLiveVideoFFFStudentDetails") {
      this.dataList = this.dataList.map(
        ({
          LIVE_NAME: Video_Name,
          STUDENT_NAME: Student_Name,
          FFF_NAME: Fastest_Finger_Name,
          QUESTION: Question,
          OPTION_1: Option1,
          OPTION_2: Option2,
          OPTION_3: Option3,
          OPTION_4: Option4,
          OPTION_5: Option5,
          USER_ANSWER: User_Answer,
          SECONDS: Seconds,
          IS_CORRECT: Is_Correct,
        }) => ({
          Video_Name,
          Student_Name,
          Fastest_Finger_Name,
          Question,
          Option1,
          Option2,
          Option3,
          Option4,
          Option5,
          User_Answer,
          Seconds,
          Is_Correct,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseLiveVideoComments") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          LIVE_VIDEO_NAME: Video_Name,
          STUDENT_NAME: Student_Name,
          COMMENT_TEXT: Comment,
          TIME_STAMP: Comment_Datetime,
        }) => ({
          Course_Name,
          Video_Name,
          Student_Name,
          Comment,
          Comment_Datetime,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseAssesmentDetails") {
      this.dataList = this.dataList.map(
        ({
          CHAPTER_NAME: Chapter_Name,
          L1_QUESTIONS: L1_Questions,
          L1_TIME: L1_Time,
          L2_QUESTIONS: L2_Questions,
          L2_TIME: L2_Time,
          L3_QUESTIONS: L3_Questions,
          L3_TIME: L3_Time,
          L4_QUESTIONS: L4_Questions,
          L4_TIME: L4_Time,
          L5_QUESTIONS: L5_Questions,
          L5_TIME: L5_Time,
          TOTAL_TIME: Total_Time,
          TOTAL_QUESTION: Total_Questions,
        }) => ({
          Chapter_Name,
          L1_Questions,
          L1_Time,
          L2_Questions,
          L2_Time,
          L3_Questions,
          L3_Time,
          L4_Questions,
          L4_Time,
          L5_Questions,
          L5_Time,
          Total_Time,
          Total_Questions,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseAssesmentSummary") {
      this.dataList = this.dataList.map(
        ({
          NAME: Assesment_Name,
          PLATFORM: Web,
          POSITIVE_MARKS: Positive_Marks,
          NEGATIVE_MARKS: Negative_Marks,
          TOTAL_TIME: Total_Time,
          TOTAL_STUDENTS: Student_Count,
          NO_OF_QUESTION: Total_Questions,
          AVG_COMPLETED_PERCENTAGE: Avg_Percentag,
          CONTRAVENTION_COUNT: Violation_Count,
          TOTAL_TERMINATED_STUDENTS: Terminated_Count,
          TOTAL_COMPLETED_STUDENTS: Completed_Count,
        }) => ({
          Assesment_Name,
          Web,
          Positive_Marks,
          Negative_Marks,
          Total_Time,
          Student_Count,
          Total_Questions,
          Avg_Percentag,
          Violation_Count,
          Terminated_Count,
          Completed_Count,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseAssessmentsStudentDetails") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          TOTAL_QUESTIONS_GENERATED: Total_Questions,
          SOLVED: Solved,
          CORRECT: Correct,
          INCORRECT: In_Correct,
          TOTAL_SECONDS: Total_Time_Seconds,
          TOTAL_POSITIVE_MARKS: Positive_Mark,
          TOTAL_NEGATIVE_MARKS: Negative_Mark,
          TOTAL_STUDENT_MARKS: Total_Mark,
          AVG_PERCENTAGE: Percentage,
        }) => ({
          Student_Name,
          Total_Questions,
          Solved,
          Correct,
          In_Correct,
          Total_Time_Seconds,
          Positive_Mark,
          Negative_Mark,
          Total_Mark,
          Percentage,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseCouponDetails") {
      this.dataList = this.dataList.map(
        ({
          COUPON_NAME: Coupan_Name,
          COUPON_CODE: Coupan_Code,
          COUPON_VALUE: Coupan_Value,
          EXPIRY_DATE: Expiry_Date,
          MIN_CART_AMOUNT: Min_Cart_Amount,
          START_DATE: Start_Date,
        }) => ({
          Coupan_Name,
          Coupan_Code,
          Coupan_Value,
          Expiry_Date,
          Min_Cart_Amount,
          Start_Date,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseCouponStudentDetails") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile_No,
          COUPON_CODE: Coupon_code,
          COUPON_NAME: Coupon_name,
          COUPON_VALUE: Coupon_Value,
        }) => ({
          Student_Name,
          Email,
          Mobile_No,
          Coupon_name,
          Coupon_code,
          Coupon_Value,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseWalletTransactionSummary") {
      this.dataList = this.dataList.map(
        ({
          NAME: Course_Name,
          TOTAL_STUDENTS: Total_Students,
          TOTAL_PAYMENT: Total_Payments,
        }) => ({
          Course_Name,
          Total_Students,
          Total_Payments,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CourseWalletTransactionDetail") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          DATE: Date,
          AMOUNT: Amount,
          PARTICULAR: Particular,
          TYPE: Type,
          TRANSACTION_TYPE: Transaction_Type,
        }) => ({
          Student_Name,
          Date,
          Amount,
          Particular,
          Type,
          Transaction_Type,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SMSSummary") {
      this.dataList = this.dataList.map(
        ({
          TOTAL_MOBILE_NUMBERS: Total_Mobile_Nos,
          SUCCESS_MOBILE_NUMBERS: Success_Mobile_Numbers,
          FAILED_MOBILE_NUMBERS: Failed_Mobile_Numbers,
        }) => ({
          Total_Mobile_Nos,
          Success_Mobile_Numbers,
          Failed_Mobile_Numbers,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SMSDetailed") {
      this.dataList = this.dataList.map(
        ({
          MOBILE_NO: Mobile_No,
          SMS_TEXT: SMS_Text,
          STATUS: Status,
          TIMESTAMP: Delivered_Datetime,
        }) => ({
          Mobile_No,
          SMS_Text,
          Status,
          Delivered_Datetime,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "EmailDetailed") {
      this.dataList = this.dataList.map(
        ({
          EMAIL_ID: Email_Id,
          SUBJECT: Subject,
          EMAIL_TEXT: Email_Text,
          STATUS: Status,
          TIMESTAMP: Delivered_Datetime,
        }) => ({
          Email_Id,
          Subject,
          Email_Text,
          Status,
          Delivered_Datetime,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "EmailSummary") {
      this.dataList = this.dataList.map(
        ({
          TOTAL_EMAILS: Total_Emails,
          SUCCESS_EMAILS: Success,
          FAILED_EMAILS: Failed,
        }) => ({
          Total_Emails,
          Success,
          Failed,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "PaymentSummary") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_COUNT: Total_Students,
          TOTAL_AMOUNT: Total_Amount,
          COURSE_BUY_COUNT: Course_Buy,
          COUPONS_USED_COUNT: Coupon_Used,
          WALLET_USED_COUNT: Wallet_Balance_Used,
        }) => ({
          Total_Students,
          Total_Amount,
          Course_Buy,
          Coupon_Used,
          Wallet_Balance_Used,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "notificationAcknowledgement") {
      this.dataList = this.dataList.map(
        ({
          TYPE: Type,
          SEND_TO: Send_To,
          NOTIFICATION_PAYLOAD: Notification_Payload,
          RESPONSE: Response,
        }) => ({
          Type,
          Send_To,
          Notification_Payload,
          Response,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "FreeTrialStudentDetail") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          FREE_TRIAL_DATE: Free_Trial_Date,
          COURSE_NAME: Course_Name,
        }) => ({
          Student_Name,
          Free_Trial_Date,
          Course_Name,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "WalletTransactionDetail") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          DATE: Date,
          TRANSACTION_TYPE: Transaction_Type,
          TYPE: Type,
          PARTICULAR: Particular,
          AMOUNT: Amount,
        }) => ({
          Student_Name,
          Date,
          Transaction_Type,
          Type,
          Particular,
          Amount,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CollegeAssocites") {
      this.dataList = this.dataList.map(
        ({ TITLE: Title, STATUS_STRING: Status }) => ({
          Title,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Careers") {
      this.dataList = this.dataList.map(
        ({
          DESIGNATION_NAME: Designation_Name,
          DESCRIPTION: Description,
          RESPONSIBILITY: Responsibility,
          ELIGIBILITY: Eligibility,
          SKILLS: Skills,
          POSITION: Position,
        }) => ({
          Designation_Name,
          Description,
          Responsibility,
          Eligibility,
          Skills,
          Position,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "DesignationWeb") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, POSTS: Posts, STATUS_STRING: Status }) => ({
          Name,
          Posts,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Team") {
      this.dataList = this.dataList.map(
        ({
          NAME: Name,
          DESIGNATION: Designation,
          TYPE: Type,
          STATUS_STRING: Status,
        }) => ({
          Name,
          Designation,
          Type,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Associates") {
      this.dataList = this.dataList.map(
        ({ TITLE: Title, DETAILS: Details, STATUS_STRING: Status }) => ({
          Title,
          Details,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "ChapterKnowledgeBase") {
      this.dataList = this.dataList.map(
        ({
          CHAPTER_ID: Chapter_Id,
          TITLE: Title,
          STATUS_STRING: Status,
          CONTENTS: Contents,
          CHAPTER_NAME: Chapter_Name,
        }) => ({
          Chapter_Id,
          Title,
          Status,
          Contents,
          Chapter_Name,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Contact") {
      this.dataList = this.dataList.map(
        ({
          USER_NAME: User_Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile_Number,
          QUERY: Query,
        }) => ({
          User_Name,
          Email,
          Mobile_Number,
          Query,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Enrollment") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          COLLEGE_NAME: College_Name,
          BRANCH_NAME: Branch_Name,
          LIVE_MASTER_NAME: Live_Name,
          NAME: Name,
          EMAIL_ID: Email,
          MOBILE_NO: Mobile_Number,
          LOCATION: Location,
          PLATFORM_STRING: Platform,
        }) => ({
          Student_Name,
          College_Name,
          Branch_Name,
          Live_Name,
          Name,
          Email,
          Mobile_Number,
          Location,
          Platform,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Menu") {
      this.dataList = this.dataList.map(
        ({ PARENT_NAME: Parent_Name, NAME: Name, STATUS_STRING: Status }) => ({
          Parent_Name,
          Name,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Resume") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, STATUS_STRING: Status }) => ({
          Name,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Review") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: UserName,
          STATUS_STRING: Status,
          REVIEW: Review,
          CREATED_DATE: Created_Date,
        }) => ({
          UserName,
          Status,
          Review,
          Created_Date,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Seo") {
      this.dataList = this.dataList.map(
        ({
          TITLE: Title,
          DESCRIPTION: Description,
          META_KEYWORDS: Meta_keywords,
          META_DESCRIPTION: Meta_Description,
          REFERENCES: References,
        }) => ({
          Title,
          Description,
          Meta_keywords,
          Meta_Description,
          References,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SuccessStories") {
      this.dataList = this.dataList.map(
        ({
          DESIGNATION: Designation,
          DESCRIPTION: Description,
          LOCATION: Location,
          STATUS_STRING: Status,
        }) => ({
          Designation,
          Description,
          Location,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Weidget") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, STATUS_STRING: Status }) => ({
          Name,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Category") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, STATUS_STRING: Status }) => ({
          Name,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Blog") {
      this.dataList = this.dataList.map(
        ({
          CATEGORY_NAME: Category_Name,
          DESIGNATION: Designation,
          TITLE: Title,
          LOCATION: Location,
          REFERENCES_URL: References_Url,
          SEQ_NO: Seq_No,
          RATING: Rating,
          POSTED_DATE: Posted_Date,
          APPROVAL_STRING: Approval_Status,
          STATUS_STRING: Status,
        }) => ({
          Category_Name,
          Designation,
          Title,
          Location,
          References_Url,
          Seq_No,
          Rating,
          Posted_Date,
          Approval_Status,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Blog") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          COMMENT: Comment,
          POSTED_DATE_TIME: Posted_Date_Time,
          STATUS_STRING: Status,
        }) => ({
          Student_Name,
          Comment,
          Posted_Date_Time,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "BlogComments") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          COMMENT: Comment,
          POSTED_DATE_TIME: Posted_Date_Time,
          STATUS_STRING: Status,
        }) => ({
          Student_Name,
          Comment,
          Posted_Date_Time,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "JobApplication") {
      this.dataList = this.dataList.map(
        ({
          F_NAME: F_Name,
          L_Name: L_Name,
          EMAIL_ID: Email_Id,
          MOBILE_NO: Mobile_No,
          PREFERED_LOCATION: Prefered_Location,
          CITY: City,
          QUALIFICATION: Qualification,
          EXPERIENCE: Experience,
          RESUME_URL: Resume,
          EXPECTED_SALARY: Expected_Salary,
          SKILLS: Skills,
          REFERENCES: References,
        }) => ({
          F_Name,
          L_Name,
          Email_Id,
          Mobile_No,
          Prefered_Location,
          City,
          Qualification,
          Experience,
          Resume,
          Expected_Salary,
          Skills,
          References,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Game") {
      this.dataList = this.dataList.map(
        ({
          COURSE_NAME: Course_Name,
          TITLE: Title,
          STATUS_STRING: Status,
        }) => ({
          Course_Name,
          Title,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "StudentGameProgress") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME: Student_Name,
          SCORE: Score,
          LEVEL: Level,
          SUB_LEVEL1: Sub_Level_1,
          SUB_LEVEL2: Sub_Level_2,
          IS_COMPLETED_STRING: Is_Completed,
        }) => ({
          Student_Name,
          Score,
          Level,
          Sub_Level_1,
          Sub_Level_2,
          Is_Completed,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Pages") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, STATUS_STRING: Status }) => ({
          Name,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "AppPromotion") {
      this.dataList = this.dataList.map(
        ({
          BLOCK_NAME: Block_Name,
          SLOGAN: Slogan,
          BUTTON_TEXT: Button_Text,
          BUTTON_URL: Button_Url,
          BUTTON_COLOR: Button_Color,
        }) => ({
          Block_Name,
          Slogan,
          Button_Text,
          Button_Url,
          Button_Color,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Type") {
      this.dataList = this.dataList.map(
        ({ NAME: Name, DESCRIPTION: Description, STATUS_STRING: Status }) => ({
          Name,
          Description,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "AboutPage") {
      this.dataList = this.dataList.map(
        ({
          STATUS_STRING: Status,
          TITLE: Title,
          IMG_URL: Img_Url,
          EXTRA_IMG_URL: Extra_Img_Url,
          IS_COMPONENT_VISIBLE_STRING: Is_Component_Visible,
          BUTTON_URL: Button_Url,
          BUTTON_TEXT: Button_Text,
        }) => ({
          Status,
          Title,
          Img_Url,
          Extra_Img_Url,
          Is_Component_Visible,
          Button_Url,
          Button_Text,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SocialLink") {
      this.dataList = this.dataList.map(
        ({ TITLE: Title, ICON_URL: Icon_Url, ICON_LINK: Icon_lINK }) => ({
          Title,
          Icon_Url,
          Icon_lINK,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Company") {
      this.dataList = this.dataList.map(
        ({
          NAME: Name,
          BUTTON_TEXT: Button_Text,
          BUTTON_URL: Button_Url,
          STATUS_STRING: Status,
          DETAILS: Details,
          IMG_URL: Img_Url,
        }) => ({
          Name,
          Button_Text,
          Button_Url,
          Status,
          Details,
          Img_Url,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "SiteMap") {
      this.dataList = this.dataList.map(
        ({
          HEADING_ID: Heading_Id,
          SITE_NAME: Site_Name,
          SITE_NAME_LINK: Site_Name_Link,
          STATUS_STRING: Status,
        }) => ({
          Heading_Id,
          Site_Name,
          Site_Name_Link,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Sitemapheading") {
      this.dataList = this.dataList.map(
        ({
          PARENT_ID: Parent_Id,
          NAME: Name,
          LINK: Link,
          STATUS_STRING: Status,
        }) => ({
          Parent_Id,
          Name,
          Link,
          Status,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "CareerPage") {
      this.dataList = this.dataList.map(
        ({
          IMG_URL: Img_Url,
          HEADING: Heading,
          BUTTON_TEXT: Button_Text,
          BUTTON_URL: Button_Url,
          STATUS_STRING: Status,
          DETAILS: Details,
        }) => ({
          Img_Url,
          Heading,
          Button_Text,
          Status,
          Button_Url,
          Details,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "ContactPage") {
      this.dataList = this.dataList.map(
        ({
          HEADING: Heading,
          ICON: Icon,
          SEQUENCE: Sequence,
          STATUS_STRING: Status,
          DETAILS: Details,
        }) => ({
          Heading,
          Icon,
          Status,
          Sequence,
          Details,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "PolicyDetails") {
      this.dataList = this.dataList.map(
        ({ HEADING: Heading, STATUS_STRING: Status, DETAILS: Details }) => ({
          Heading,
          Status,
          Details,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Trainer") {
      this.dataList = this.dataList.map(
        ({
          NAME: Name,
          STATUS_STRING: Status,
          DESIGNATION: Designation,
          IMG_URL: Img_Url,
          SUBJECT: Subject,
        }) => ({
          Name,
          Status,
          Designation,
          Img_Url,
          Subject,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "PageForms") {
      this.dataList = this.dataList.map(
        ({
          BLOCK_NAME: Block_Name,
          FEILD_NAME: fieldName,
          IS_VISIBLE: IsVisible,
          IS_COMPULSORY: IsCompulsory,
        }) => ({
          Block_Name,
          fieldName,
          IsVisible,
          IsCompulsory,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Footer") {
      this.dataList = this.dataList.map(
        ({
          STATUS_STRING: Status,
          TITLE: Title,
          IMG_URL: Img_Url,
          DESCRIPTION: Description,
          IS_FORM_VISIBLE: Is_FORM_Visible,
          BUTTON_URL: Button_Url,
          BUTTON_TEXT: Button_Text,
        }) => ({
          Status,
          Title,
          Img_Url,
          Is_FORM_Visible,
          Description,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "FooterPageLink") {
      this.dataList = this.dataList.map(
        ({
          STATUS_STRING: Status,
          NAME: Name,
          CONTENT_TITLE: Content_Title,
          ROUTE_LINK: Route_Url,
          SEQUENCE_NUMBER: Sequence_Number,
          IS_FORM_VISIBLE: Is_FORM_Visible,
          BUTTON_URL: Button_Url,
          BUTTON_TEXT: Button_Text,
        }) => ({
          Status,
          Content_Title,
          Route_Url,
          Sequence_Number,
          Name,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "NavBarContent") {
      this.dataList = this.dataList.map(
        ({
          STATUS_STRING: Status,
          IS_BUTTON_VISIBLE: Is_Button_Visible,
          BUTTON_URL: Button_Url,
          BUTTON_TEXT: Button_Text,
        }) => ({
          Status,
          Is_Button_Visible,
          Button_Url,
          Button_Text,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "ButtonLink") {
      this.dataList = this.dataList.map(
        ({ TEXT: Text, LINK_URL: Link_Url }) => ({
          Text,
          Link_Url,
        })
      );
      this.converted = this.dataList;
    } else if (this.fileName == "Gamified Registeration Report") {
      // alert('hi')
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME,
          MOBILE_NO,
          LAST_OPEN_DATETIME,
          FREE_GAME_TAKEN_DATE,
          PURCHASED_DATE,
          PROFILE_COMPLETION_PERCENTAGE,
          IS_PURCHASED,
        }) => ({
          STUDENT_NAME,
          MOBILE_NO,
          LAST_OPEN_DATETIME,
          FREE_GAME_TAKEN_DATE,
          PURCHASED_DATE,
          PROFILE_COMPLETION_PERCENTAGE,
          IS_PURCHASED,
        })
      );
      this.converted = this.rekey(this.dataList, {
        STUDENT_NAME: "Student Name",
        MOBILE_NO: " Mobile Number ",
        LAST_OPEN_DATETIME: " Last Opened Date Time ",
        FREE_GAME_TAKEN_DATE: " Free Trial Taken Date Time ",
        PURCHASED_DATE: " Paid Version Taken Date Time",
        PROFILE_COMPLETION_PERCENTAGE: " Profile Completion",
        IS_PURCHASED: " Status ",
      });
      console.log(this.converted);
    } else if (this.fileName == "Coupon Summary Report") {
      this.dataList = this.dataList.map(
        ({
          NAME,
          COUPON_CODE,
          COUPON_USED_COUNT,
          START_DATE,
          EXPIRY_DATE,
          COUPON_VALUE,
        }) => ({
          NAME,
          COUPON_CODE,
          COUPON_USED_COUNT,
          START_DATE,
          EXPIRY_DATE,
          COUPON_VALUE,
        })
      );
      this.converted = this.rekey(this.dataList, {
        NAME: " Coupon Name ",
        COUPON_CODE: " Coupon Code",
        COUPON_USED_COUNT: " Used Count",
        START_DATE: "Start Date Time",
        EXPIRY_DATE: "End Date Time",
        COUPON_VALUE: " Coupon Value ",
      });
      console.log(this.converted);
    } else if (this.fileName == "Coupon Used Detailed Report") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME,
          MOBILE_NO,
          ITEM_NAME,
          COUPON_NAME,
          APPLIED_TIME,
          COUPON_VALUE,
          TAKER_NAME,
          GIVER_NAME,
          GIVEN_DATE,
          REFUNDED_DATE,
          IS_SHORTAGE,
          ADDITIONAL_REMARKES,
          TOTAL_ITEMS,
          TOTAL_RETURNED_ITEMS,
        }) => ({
          STUDENT_NAME,
          MOBILE_NO,
          ITEM_NAME,
          COUPON_NAME,
          APPLIED_TIME,
          COUPON_VALUE,
          TAKER_NAME,
          GIVER_NAME,
          GIVEN_DATE,
          REFUNDED_DATE,
          IS_SHORTAGE,
          ADDITIONAL_REMARKES,
          TOTAL_ITEMS,
          TOTAL_RETURNED_ITEMS,
        })
      );
      this.converted = this.rekey(this.dataList, {
        STUDENT_NAME: " Student Name",
        MOBILE_NO: " Mobile Number ",
        ITEM_NAME: " Course Name",
        COUPON_NAME: " Coupon Name ",
        APPLIED_TIME: " Used Date Time",
        COUPON_VALUE: " Coupon Value ",
      });
      console.log(this.converted);
    } else if (this.fileName == "Subscribed Student Detail Report ") {
      this.dataList = this.dataList.map(
        ({ STUDENT_NAME, MOBILE_NO, EMAIL_ID, CREATED_MODIFIED_DATE }) => ({
          STUDENT_NAME,
          MOBILE_NO,
          EMAIL_ID,
          CREATED_MODIFIED_DATE,
        })
      );
      this.converted = this.rekey(this.dataList, {
        STUDENT_NAME: " Student Name",
        MOBILE_NO: " Mobile Number",
        EMAIL_ID: "Email ID",
        CREATED_MODIFIED_DATE: " Date and Time",
      });
      console.log(this.converted);
    } else if (this.fileName == "Online Bundle Summary") {
      this.dataList = this.dataList.map(
        ({
          BUNDLE_NAME,
          SUBSCRIBERS,
          TOTAL_MOCKS,
          TOTAL_MOCKS_SOLVED,
        }) => ({
          BUNDLE_NAME,
          SUBSCRIBERS,
          TOTAL_MOCKS,
          TOTAL_MOCKS_SOLVED,
        })
      );
      this.converted = this.rekey(this.dataList, {
        BUNDLE_NAME: " Bundle Name",
        SUBSCRIBERS: " Subscribers",
        TOTAL_MOCKS: "Total Assessments",
        TOTAL_MOCKS_SOLVED: " Solved Assessment",
      });
    } else if (this.fileName == "College Bundle Summary") {
      this.dataList = this.dataList.map(
        ({
          BUNDLE_NAME,
          SUBSCRIBERS,
          MAPPED_STUDENTS,
          PICKED_STUDENTS,
          TOTAL_MOCKS,
          TOTAL_MOCKS_SOLVED,
        }) => ({
          BUNDLE_NAME,
          SUBSCRIBERS,
          MAPPED_STUDENTS,
          PICKED_STUDENTS,
          TOTAL_MOCKS,
          TOTAL_MOCKS_SOLVED,
        })
      );
      this.converted = this.rekey(this.dataList, {
        BUNDLE_NAME: " Bundle Name",
        SUBSCRIBERS: " Subscribers",
        MAPPED_STUDENTS: " Mapped Student",
        PICKED_STUDENTS: " Picked Student",
        TOTAL_MOCKS: "Total Assessments",
        TOTAL_MOCKS_SOLVED: " Solved Assessment",
      });
    } else if (this.fileName == "College Summary") {
      this.dataList = this.dataList.map(
        ({
          NAME,
          COLLEGE_NAME,
          TOTAL_BUNDLES,
          MAX_STUDENTS,
          MAPPED_STUDENTS,
          PICKED_STUDENTS,
        }) => ({
          NAME,
          COLLEGE_NAME,
          TOTAL_BUNDLES,
          MAX_STUDENTS,
          MAPPED_STUDENTS,
          PICKED_STUDENTS,
        })
      );
      this.converted = this.rekey(this.dataList, {
        NAME: " TPO Name",
        COLLEGE_NAME: " College Name",
        TOTAL_BUNDLES: " Total Bundles",
        MAX_STUDENTS: " Max Students",
        MAPPED_STUDENTS: "Mapped Students ",
        PICKED_STUDENTS: " Picked Students",
      });
    } else if (this.fileName == "Student Bundle Summary") {

      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME,
          COLLEGE_MAPPED_BUNDLES,
          COLLEGE_PICKED_BUNDLES,
          ONLINE_BUNDLES,
          TOTAL_MOCKS,
          TOTAL_MOCKS_SOLVED,
        }) => ({
          STUDENT_NAME,
          COLLEGE_MAPPED_BUNDLES,
          COLLEGE_PICKED_BUNDLES,
          ONLINE_BUNDLES,
          TOTAL_MOCKS,
          TOTAL_MOCKS_SOLVED,
        })
      );
      //Student Bundle Summary
      this.converted = this.rekey(this.dataList, {
        STUDENT_NAME: " Student Name",
        COLLEGE_MAPPED_BUNDLES: " College Mapped Bundles",
        COLLEGE_PICKED_BUNDLES: " College Picked Bundles ",
        ONLINE_BUNDLES: " Online Bundles",
        TOTAL_MOCKS: "Total Assessment ",
        TOTAL_MOCKS_SOLVED: " Total Assessment Solved",
      });
    } else if (this.fileName == "Student Assessment Details") {
      this.dataList = this.dataList.map(
        ({
          STUDENT_NAME,
          MOCK_NAME,
          TOTAL,
          SOLVED,
          CORRECT,
          INCORRECT,
          TOTAL_SEC,
          SOLVED_SEC,
          AVG_SEC,
          MARKS,
          PERCENTAGE,
          ACCURACY,
          PERFORMANCE,
          PERFORMANCE_INDEX,
        }) => ({
          STUDENT_NAME,
          MOCK_NAME,
          TOTAL,
          SOLVED,
          CORRECT,
          INCORRECT,
          TOTAL_SEC,
          SOLVED_SEC,
          AVG_SEC,
          MARKS,
          PERCENTAGE,
          ACCURACY,
          PERFORMANCE,
          PERFORMANCE_INDEX,
        })
      );
      this.converted = this.rekey(this.dataList, {
        STUDENT_NAME: " Student Name",
        MOCK_NAME: " Assessment Name",
        TOTAL: " Total ",
        SOLVED: " Solved",
        CORRECT: "Correct ",
        INCORRECT: " Incorrect",
        TOTAL_SEC: " Total Sec.",
        SOLVED_SEC: " Solved Sec.e",
        AVG_SEC: " Average Sec.",
        MARKS: " Marks",
        PERCENTAGE: "Percentage ",
        ACCURACY: " Accuracy",
        PERFORMANCE: "Performance ",
        PERFORMANCE_INDEX: " Performance Index",
      });
    } else if (this.fileName == "Student Registration Summary") {
      this.dataList = this.dataList.map(
        ({
          STUDENTS,
          ONLINE_STUDENTS,
          COLLEGE_MAPPED_STUDENTS,
          COLLEGE_PICKED_STUDENTS,
        }) => ({
          STUDENTS,
          ONLINE_STUDENTS,
          COLLEGE_MAPPED_STUDENTS,
          COLLEGE_PICKED_STUDENTS,
        })
      );
      this.converted = this.rekey(this.dataList, {
        STUDENTS: " Student Name",
        ONLINE_STUDENTS: " Online Student Count",
        COLLEGE_MAPPED_STUDENTS: " College Mapped Students Count ",
        COLLEGE_PICKED_STUDENTS: " College Picked Students Count",
      });
      console.log(this.converted);
    }
    this.exportService.exportExcel(this.converted, this.fileName);
  }

  rekey(arr, lookup) {
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      for (var fromKey in lookup) {
        var toKey = lookup[fromKey];
        var value = obj[fromKey];
        if (value) {
          obj[toKey] = value;
          delete obj[fromKey];
        }
      }
    }
    return arr;
  }
}
