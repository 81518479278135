import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Assignment } from 'src/app/Models/AssignmentModule/assignment';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
import { Assignmentgroup } from 'src/app/Models/AssignmentModule/assignmentgroup';
import { Passingyear } from 'src/app/Models/BatchMgmt/passingyear';
import { Division } from 'src/app/Models/BatchMgmt/division';
import { Branch } from 'src/app/Models/BatchMgmt/branch';
import { Mappedassignment } from 'src/app/Models/AssignmentModule/mappedassignment';
import { Part } from 'src/app/Models/MasterSyllabus/part';
import { Section } from 'src/app/Models/MasterSyllabus/section';
import { Chapter } from 'src/app/Models/MasterSyllabus/chapter';
import { MappedquestiondataComponent } from '../mappedquestiondata/mappedquestiondata.component';

@Component({
  selector: 'app-mappedassignment',
  templateUrl: './mappedassignment.component.html',
  styleUrls: ['./mappedassignment.component.css'],
  providers: [DatePipe]
})
export class MappedassignmentComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Mappedassignment;
  current = 0;
  assignmentGroups: Assignmentgroup[]
  loadingGroups = false
  loadingInstructionsData = false
  instructionsData = []
  logtext: string = "";
  userId = sessionStorage.getItem('userId')
  loadingSaveAssignments = false
  loadingMapInstructions = false
  assignmentId: number
  assignmentDetails = []
  TOTAL_PARTS_SELECTED: number = 0
  TOTAL_SECTION_SELECTED: number = 0
  TOTAL_CHAPTER_SELECTED: number = 0
  TOTAL_SUBCHAPTERS: number = 0
  TOTAL_QUESTIONS: number = 0
  CLASSWORK_QUESTIONS: number = 0
  HOMEWORK_QUESTIONS: number = 0
  ASSIGNMNETS_QUESTIONS:number=0
  TOTAL_TEMPLATES: number = 0
  TIME: string = "0"
  addTime: number = 0
  PRACTICE_QUESTIONS: number = 0
  AQGS: number = 0
  questionDetailsData = []
  drawerTitle: string = ""
  drawerVisible: boolean = false
  scope
  ENABLE_TIME
  DISABLE_TIME
  IS_RESULT_SHARING_TIME = true
  RESULT_SHARING_TIME
  PASSKEY = ""
  LINK = ""

  filterBranches: string[] = ['AL']
  filterPassingYear: string[] = ['AL']
  filterDivision: string[] = ['AL']
  passingYears: Passingyear[]
  divisions: Division[]
  branches: Branch[]
  batchDetailsData = []
  checkAll = false
  BATCH_DISABLE_TIME: Date
  BATCH_ENABLE_TIME: Date
  BATCH_IS_PUBLISHED = false
  BATCH_PUBLISHED_TIME: Date
  BATCH_IS_RESULT_SHARING_TIME = false
  loadingMapQuestions = false
  loadingSaveClose = false
  L1_TIME_DEFAULT: number
  L2_TIME_DEFAULT: number
  L3_TIME_DEFAULT: number
  L4_TIME_DEFAULT: number
  L5_TIME_DEFAULT: number
  BATCH_RESULT_SHARING_TIME: Date
  nodeObject = []
  isLocadingAssignmentDetails = false
  today = new Date();
  loadingBranches = false
  loadingPassingYears = false
  loadingDivisions = false
  loadingFilter = false
  loadbatchDetailsFilter = true
  loadingMapAssignment = false
  collegeId = sessionStorage.getItem('collegeId')
  modeType: string = ""
  branchfilterQuery = ""
  NAME: string = ""
  totalQuestions: number
  totalTime
  defaultCheckedKeys = [];
  loadingParts = false
  parts: Part[]
  PART_ID: number
  loadingSections = false
  sections: Section[]
  SECTION_ID: number
  loadingChapters = false
  chapters: Chapter[]
  CHAPTER_ID: number
  isStatsSpinning = false
  assignmentDetailsCount = []
  visibleData = false
  TYPE: string
  disableButton = false
  flag:boolean=false
  @ViewChild(MappedquestiondataComponent,{static:false} ) questionData: MappedquestiondataComponent ;
  questionDataLength:number=0
  TOTAL_TIME_IN_SECOND:number=0
  constructor(public api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.loadAssignmentGroups()
  }

  loadAssignmentGroups() {
    this.loadingGroups = true;
    this.api.getAllAssignmentGroups(0, 0, '', '', '').subscribe(localName => {
      this.assignmentGroups = localName['data'];
      this.loadingGroups = false;
    }, err => {
      console.log(err);
      this.loadingGroups = false;
    });
  }

  cancel() {
    this.current -= 1;
  }

  saveAssignments() {


    this.loadingSaveAssignments = true
    if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == false)
      this.data.PLATFORM = 'M'
    else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == true)
      this.data.PLATFORM = 'W'
    else if (this.data.platforms[1]['checked'] == true && this.data.platforms[1]['checked'] == true)
      this.data.PLATFORM = 'B'

    //create assignment method
    if (this.data.ID) {
console.log(this.data)
      this.api.updateMappedAssignment(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.message.success("Mapped Assignment information updated Successfully...", "");
            this.current += 1;
            this.modeType = "U"
            console.log(successCode['data'])
            this.assignmentId=successCode['data']['MAPPED_ASSIGNMENT_MASTER_ID']
            console.log(this.assignmentId)
            this.loadInstructionData(this.assignmentId)
            this.loadingSaveAssignments = false
            this.logtext = 'Update & Close - Mapped Assignment form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Mapped Assignment ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
          else {
            this.logtext = 'Update & Close - Mapped Assignment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - AssignmentGroup ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.message.error("Failed to update information...", "");
            this.loadingSaveAssignments = false;
          }
        }, err => {
          console.log(err);
          this.loadingSaveAssignments = false;
        });
    }
    // this.current += 1;
  }

  changeIntructionSelect(value, data) {
    if (value)
      data.IS_SELECTED = 1;
    else
      data.IS_SELECTED = 0
  }

  // getAssignmentId() {
  //   if (this.data.ID) {
     
  //     this.assignmentId = this.data.MAPPED_ASSIGNMENT_MASTER_ID
    
  //   }


  // }

  loadInstructionData(assignmentId) { 
    console.log(assignmentId)
    this.loadingInstructionsData = true;
    this.api.getMappedAssignmentInstructions(assignmentId).subscribe(data => {
      data['data']=data['data'].sort(this.sortFunction1)
      const getPlayerMax_on = cod => data['data'].reduce((a,c)=>((+a[cod])<(+c[cod]))?c:a)

      const maxvalue = getPlayerMax_on('SEQ_NO')
      console.log(maxvalue)

      console.log(maxvalue['SEQ_NO'])
      let seqNo=maxvalue['SEQ_NO']+1
      data['data'].forEach(element => {
        if(element['SEQ_NO']==0)
        {
          element['SEQ_NO']=seqNo
          seqNo++
        }
        
      });

      console.log(data['data'])

      this.instructionsData =  data['data'].sort(this.sortFunction)
    this.loadingInstructionsData = false;

    }, err => {
      console.log(err);
      this.loadingInstructionsData = false;
    });
  }
  sortFunction1(a, b) {
    var dateA = a.ID
    var dateB = b.ID
    return dateA > dateB ? 1 : -1;
  };

  sortFunction(a, b) {
    var dateA = a.SEQ_NO
    var dateB = b.SEQ_NO
    return dateA > dateB ? 1 : -1;
  };
  saveMapInstructions() {
    var data1= this.instructionsData.filter(object => {
      return object['IS_SELECTED'] == 1;
    });

    console.log(data1.length)

    if(data1.length>0)
    {
    this.loadingMapInstructions = true;
    this.api.addMappedAssignmentInstructions(this.assignmentId, this.instructionsData)
      .subscribe(successCode => {

        if (successCode['code'] == "200") {
          this.message.success("Instruction Mapped Successfully ...", "");
          this.loadingMapInstructions = false;
          this.current += 1;
          this.visibleData = false
          this.loadParts()
          this.loadAllStats()
        }
        else {
          this.message.error("Instruction Mapping Failed...", "");
          this.loadingMapInstructions = false;
        }
      }, err => {
        console.log(err);
        this.loadingMapInstructions = false;
      });
    }
    else
    {
      this.message.error("Please Select Instructions...", "");
    }
    // this.loadQuestionsData()

    // this.current += 1;
  }

  loadParts() {
    this.loadingParts = true;
    this.api.getAllParts(0, 0, 'ID', 'asc', '').subscribe(localName => {
      this.parts = localName['data'];
      this.PART_ID = localName['data'][0]['ID']
      this.partChange(this.PART_ID)
      this.loadingParts = false;
    }, err => {
      console.log(err);
      this.loadingParts = false;
    });
  }

  partChange(partId) {
    let filter = " AND PART_ID=" + partId
    this.loadingSections = true;
    this.api.getAllSections(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      this.sections = localName['data'];
      this.SECTION_ID=undefined
      this.CHAPTER_ID=undefined
      this.loadingSections = false;
    }, err => {
      console.log(err);
      this.loadingSections = false;
    });
  }

  sectionChange(sectionId) {
    let filter = " AND SECTION_ID=" + sectionId
    this.loadingChapters = true;
    this.api.getAllChapters(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      this.CHAPTER_ID=undefined
      this.chapters = localName['data'];
      this.loadingChapters = false;
    }, err => {
      console.log(err);
      this.loadingChapters = false;
    });
  }

  loadAllStats() {
    this.isStatsSpinning = true
    let filter = " AND MAPPED_ASSIGNMENT_MASTER_ID=" + this.assignmentId
    this.api.getAllMappedAssignmentDetailsMapping(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      console.log("assignment details gird localName")
      console.log(localName)
      this.assignmentDetailsCount = localName['data'];
      this.TOTAL_QUESTIONS=0
      this.TIME="0"
      this.getAllStats()
      this.isStatsSpinning = false;
    }, err => {
      console.log(err);
      this.isStatsSpinning = false;
    });
  }
  getAllStats()
  {
    let l1time=0
    let l2time=0
    let l3time=0
    let l4time=0
    let l5time=0
    let totalTime=0
    this.assignmentDetailsCount.forEach(element => {
     this.TOTAL_QUESTIONS= this.TOTAL_QUESTIONS+ element['L1_QUESTIONS'] + element['L2_QUESTIONS'] +  element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS'] 
     l1time= l1time+element['L1_QUESTIONS'] * element['L1_TIME']
     l2time=l2time+element['L2_QUESTIONS'] * element['L2_TIME']
     l3time=l3time+element['L3_QUESTIONS'] * element['L3_TIME']
     l4time=l4time+element['L4_QUESTIONS'] * element['L4_TIME']
     l5time=l5time+element['L5_QUESTIONS'] * element['L5_TIME']
     totalTime=l1time+l2time+l3time+l4time+l5time
     this.TOTAL_TIME_IN_SECOND=totalTime
     this.TIME=  this.api.getSecondsToHms(totalTime)
    });
    console.log(this.TOTAL_QUESTIONS,this.TOTAL_TIME_IN_SECOND)

    console.log(this.flag)

if(this.flag)
{
  this.data.TOTAL_QUESTIONS=this.TOTAL_QUESTIONS
  this.data.TOTAL_TIME=this.TOTAL_TIME_IN_SECOND

  console.log(this.data)
  this.api.updateMappedAssignment(this.data)
  .subscribe(successCode => {
    console.log(successCode)
    if (successCode['code'] == "200") {
     // this.message.success("Assignment information updated Successfully...", "");
   
      this.logtext = 'Update & Close - Assignment form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Assignment ]";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }
    else {
      this.logtext = 'Update & Close - Assignment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - AssignmentGroup ]";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
      this.message.error("Failed to update information...", "");
    }
  });

}
   
  }

  loadAssignmentDeatils() {
    if(this.SECTION_ID!=undefined && this.CHAPTER_ID!=undefined )
    {
    this.isLocadingAssignmentDetails = true
    console.log("in mapped assignment")
    this.api.getMappedAssignmentDetails(this.assignmentId, this.PART_ID, this.SECTION_ID, this.CHAPTER_ID).subscribe(data => {
      console.log("details JSON in filter data in mapping assignment")
      console.log(data)
      if (data['code'] == 200) {
        this.assignmentDetails = data['data'];
        this.TOTAL_QUESTIONS = data['data1'][0]['TOTAL_QUESTIONS']
        this.TIME = this.api.getSecondsToHms(data['data1'][0]['TOTAL_TIME'])
        console.log(data['data1'])
       
        //this.getStats(this.assignmentDetails)
        this.TOTAL_QUESTIONS=0
        this.TIME="0"
        this.getAllStats()
        this.flag=false
        this.isLocadingAssignmentDetails = false
        this.visibleData = true
      }
      else {
        this.message.error("Failed To Get  ...", "");
        this.assignmentDetails = []
        this.isLocadingAssignmentDetails = false
      }
    }, err => {
      this.assignmentDetails = []
      this.isLocadingAssignmentDetails = false
      console.log(err);
    });
  }
  else
  {
    this.message.error("Please Select Section & Chapter...", "");
  }
  }

  viewQuestion(data) {
    console.log(data)
    this.TYPE = ""
    let filter = " AND MAPPED_ASSIGNMENT_DETAILS_MAPPING_ID=" + data.ID
    this.api.getAllMappedAssignmentQuestions(0, 0, 'ID', 'asc', filter).subscribe(data => {
      console.log(data)
      this.questionDetailsData = data['data'];
      this.questionDataLength=data['count']
      console.log(this.questionDetailsData)
      this.drawerTitle = "Question For ";
      this.drawerVisible = true;
    }, err => {
      console.log(err);
    });

  }

  getStats(data) {
    this.resetValues()
    console.log('mapping update data')
    if (this.modeType == "C") {

    } else {

      data.forEach(element => {
        if (element['checked']) {
          //part
          if (element['key'].startsWith("p")) {
            this.TOTAL_PARTS_SELECTED = this.TOTAL_PARTS_SELECTED + 1

          }
        }
        if (element['children']) {
          //sections
          element['children'].forEach(element => {

            if (element['checked']) {
              if (element['key'].startsWith("s")) {
                this.TOTAL_SECTION_SELECTED = this.TOTAL_SECTION_SELECTED + 1
              }
            }

            if (element['children']) {
              //chapters
              element['children'].forEach(element => {
                if (element['checked']) {
                  if (element['key'].startsWith("c")) {
                    this.TOTAL_CHAPTER_SELECTED = this.TOTAL_CHAPTER_SELECTED + 1
                  }
                }

                if (element['children']) {
                  element['children'].forEach(element => {
                    if (element['checked']) {
                      if (element['key'].startsWith("r")) {
                        this.TOTAL_SUBCHAPTERS = this.TOTAL_SUBCHAPTERS + 1
                      }
                    }

                    if (element['children']) {
                      element['children'].forEach(element => {

                        if (element['checked'] && element['key'].startsWith("Classwork")) {
                          this.CLASSWORK_QUESTIONS = this.CLASSWORK_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Homework")) {
                          this.HOMEWORK_QUESTIONS = this.HOMEWORK_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Practice")) {
                          this.PRACTICE_QUESTIONS = this.PRACTICE_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Assignment")) {
                          this.ASSIGNMNETS_QUESTIONS = this.ASSIGNMNETS_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("AQG")) {
                          this.AQGS = this.AQGS + 1
                          if (element['children']) {
                            element['children'].forEach(element => {
                              if (element['checked'] && element['key'].startsWith == 'T')
                                this.TOTAL_TEMPLATES = this.TOTAL_TEMPLATES + 1
                            });
                          }
                        }

                      });
                    }

                  });
                }


              });
            }



          });
        }

      });


    }
  }

  changeLevelTimeChange(seconds, levelText) {
    switch (levelText) {
      case "L1":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L1_TIME'] = seconds


          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L1_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L1_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L1_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L1_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L1_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L2":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L2_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L2_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L2_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L2_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L2_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L2_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L3":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L3_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L3_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L3_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L3_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L3_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L3_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L4":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L4_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L4_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L4_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L4_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L4_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L4_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L5":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L5_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L5_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L5_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L5_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L5_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L5_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;
    }
  }
  getcheckChange(event?) {
   
    var keys = event.keys
    this.resetValues()
    if (keys.length > 0) {
      keys.forEach(element => {
        if (element.startsWith("p")) {
          this.TOTAL_PARTS_SELECTED = this.TOTAL_PARTS_SELECTED + 1
        }
        else if (element.startsWith("s")) {
          this.TOTAL_SECTION_SELECTED = this.TOTAL_SECTION_SELECTED + 1
        }
        else if (element.startsWith("c")) {
          this.TOTAL_CHAPTER_SELECTED = this.TOTAL_CHAPTER_SELECTED + 1
        }
        else if (element.startsWith("r")) {
          this.TOTAL_SUBCHAPTERS = this.TOTAL_SUBCHAPTERS + 1
        }
        else if (element.startsWith("Classwork")) {
          this.CLASSWORK_QUESTIONS = this.CLASSWORK_QUESTIONS + 1
        }
        else if (element.startsWith("Homework")) {
          this.HOMEWORK_QUESTIONS = this.HOMEWORK_QUESTIONS + 1
        }
        else if (element.startsWith("Practice")) {
          this.PRACTICE_QUESTIONS = this.PRACTICE_QUESTIONS + 1
        }
        else if (element.startsWith("Assignment")) {
          this.ASSIGNMNETS_QUESTIONS = this.ASSIGNMNETS_QUESTIONS + 1
        }
        else if (element.startsWith("AQG")) {
          this.AQGS = this.AQGS + 1
        }
        else if (element.startsWith("T")) {
          this.TOTAL_TEMPLATES = this.TOTAL_TEMPLATES + 1
        }

      });

    }
    else {
      this.resetValues()
    }

    if (event.node.origin.checked) {
      if (event.node.origin.children != []) {
        if (event.node.origin.children.length >= 0) {
          if (event.node.origin.children != []) {
            event.node.origin.children.forEach(element => {
              element['disabled'] = true
              if (element['children'] != []) {
                element['children'].forEach(element => {
                  element['disabled'] = true
                  if (element['children'] != []) {
                    element['children'].forEach(element => {
                      element['disabled'] = true
                      if (element['children'] != []) {
                        element['children'].forEach(element => {
                          element['disabled'] = true
                          if (element['children'] != []) {
                            element['children'].forEach(element => {
                              element['disabled'] = true
                            });
                          }
                        });
                      }

                    });
                  }

                });
              }
            });
          }
        }
      }
    }
    else {
      console.log(event.node.origin.checked)
      if (event.node.origin.children != []) {
        if (event.node.origin.children.length >= 0) {
          event.node.origin.children.forEach(element => {
            element['disabled'] = false
            if (element['children'] != []) {
              this.disableButton = false
              element['children'].forEach(element => {
                element['disabled'] = false
                if (element['children'] != []) {
                  element['children'].forEach(element => {
                    element['disabled'] = false
                    if (element['children'] != []) {
                      element['children'].forEach(element => {
                        element['disabled'] = false
                        if (element['children'] != []) {
                          element['children'].forEach(element => {
                            element['disabled'] = false
                          });
                        }
                      });
                    }

                  });
                }

              });
            }
          });
        }
      }
    }
  
    console.log(this.assignmentDetails)

    var count = 0
    let partcheck=false
    let sectioncheck=false
    let chaptercheck=false
    let subchaptercheck=false
    let othercheck=false
    this.assignmentDetails.forEach(element => {
      partcheck=element['checked']
      if (element['children'] != [])
        element['children'].forEach(element => {
          sectioncheck=element['checked']

          if (element['disabled'] == true && element['checked'] == true)
            count++;
          else {
            if (element['children'] != []) {
              element['children'].forEach(element => {
                chaptercheck=element['checked']
                if (element['disabled'] == true && element['checked'] == true)
                  count++;
                else
                  if (element['children'] != []) {
                    element['children'].forEach(element => {
                      if(element['checked'])
                      subchaptercheck=element['checked']
 
                      if (element['disabled'] == true && element['checked'] == true)
                        count++;
                      else {
                        if (element['children'] != []) {
                          element['children'].forEach(element => {
                            if(element['checked'])
                            othercheck=element['checked'] 
                            if (element['disabled'] == true && element['checked'] == true)
                              count++;
                          });
                        }
                      }
                    });
                  }
              });
            }
          }

        });
    });

    if(partcheck)
    if(sectioncheck || chaptercheck || subchaptercheck || othercheck)
    count++
    if(sectioncheck )
    if( chaptercheck || subchaptercheck || othercheck)
    count++
    if(chaptercheck)
    if(subchaptercheck || othercheck)
    count++
    

    if (count == 0)
      this.disableButton = false
    else {
      this.disableButton = true
    }

    console.log(this.assignmentDetails)

    console.log(count)
  }

  resetValues() {
    this.TOTAL_PARTS_SELECTED = 0
    this.TOTAL_SECTION_SELECTED = 0
    this.TOTAL_CHAPTER_SELECTED = 0
    this.TOTAL_SUBCHAPTERS = 0
    this.CLASSWORK_QUESTIONS = 0
    this.PRACTICE_QUESTIONS = 0
    this.HOMEWORK_QUESTIONS = 0
    this.TOTAL_TEMPLATES = 0
    this.AQGS = 0
    this.ASSIGNMNETS_QUESTIONS=0
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    //this.loadAssignmentDeatils()
    this.drawerVisible = false;
    console.log(this.assignmentDetails)
  }

  getQuestionClick(data, node, levelId) {
    console.log(data)
    if(data.length>0)
    {
      this.nodeObject = node
      this.questionDetailsData = data
      this.drawerTitle = "Question For ";
      this.drawerVisible = true;
    }
    else
    {
      
    let partId = 0
    let sectionId = 0
    let chapterId = 0
    let subchapterId = 0
    let groupType = "N"
    if (node['key'].startsWith("p"))
      partId = node['key'].substring(1)
    if (node['key'].startsWith("s"))
      sectionId = node['key'].substring(1)
    if (node['key'].startsWith("c"))
      chapterId = node['key'].substring(1)
    if (node['key'].startsWith("r"))
      subchapterId = node['key'].substring(1)

    if (node['key'].startsWith("Classwork")) {
      groupType = "C"
      subchapterId = node['key'].substring(9)
    }
    if (node['key'].startsWith("Homework")) {
      groupType = "H"
      subchapterId = node['key'].substring(8)
    }
    if (node['key'].startsWith("Practice")) {
      groupType = "P"
      subchapterId = node['key'].substring(8)
    }
    if (node['key'].startsWith("Assignment")) {
      groupType = "A"
      subchapterId = node['key'].substring(10)
    }
    console.log(partId, sectionId, chapterId, subchapterId, groupType, levelId, this.assignmentId)

    this.isLocadingAssignmentDetails=true
    this.api.getMappedAssignmentQuestionDetails(this.assignmentId,Number(partId),Number(sectionId),Number(chapterId),Number(subchapterId),groupType,Number(levelId))
    .subscribe(successCode => {
      console.log(successCode)
      if (successCode['code'] == "200") {
        this.isLocadingAssignmentDetails = false
        this.nodeObject = node
        this.questionDetailsData = successCode['data'] 
        this.questionData.checkAll=false
        this.questionData.selectedQuesCount=0
        this.questionData.getDataList(this.questionDetailsData)
        this.questionData.getCheckSelectedQuestionCount()
        this.questionDataLength=successCode['data'].length
        this.TYPE = "SAVE"
        this.drawerTitle = "Question For ";
        this.drawerVisible = true;
      
      }
      else {
        this.message.error("Assignment Details Getting Failed...", "");
        this.isLocadingAssignmentDetails = false;
      }
    }, err => {
      console.log(err);
      this.isLocadingAssignmentDetails = false;
    });

  }
   

  }

  getTotalQuestionCount(value) {
    this.getTotalStats()
    // this.TOTAL_QUESTIONS=value.L1_QUESTIONS + value.L2_QUESTIONS + value.L3_QUESTIONS + value.L4_QUESTIONS + value.L5_QUESTIONS 
    // console.log(value['L1_QUESTIONS'])
    // console.log(Number(value['L1_QUESTIONS']) + Number(value['L2_QUESTIONS']) + Number(value['L3_QUESTIONS']) + Number(value['L4_QUESTIONS']) + Number(value['L5_QUESTIONS']))
  // this.TOTAL_QUESTIONS = Number(value['L1_QUESTIONS']) + Number(value['L2_QUESTIONS']) + Number(value['L3_QUESTIONS']) + Number(value['L4_QUESTIONS']) + Number(value['L5_QUESTIONS'])
    // console.log(this.TOTAL_QUESTIONS)
    //this.TOTAL_QUESTIONS = this.TOTAL_QUESTIONS + value

  }

  getTotalTime(value) {
    // this.addTime = this.addTime + value
    // this.TIME = this.api.getSecondsToHms(this.addTime)
  }

  getTime(l1ques,l1Time,l2ques, l2Time,l3ques, l3Time,l4ques ,l4Time,l5ques, l5Time) {
    return (l1ques*l1Time)+ (l2ques*l2Time) + (l3ques*l3Time) + (l4ques*l4Time) + (l5ques*l5Time)
  }  
  
  getTotalStats() {
    console.log(this.assignmentDetails)
    let totalQuestions = 0
    let totalTime = 0
    let totalTimel1 = 0
    let totalTimel2 = 0
    let totalTimel3 = 0
    let totalTimel4 = 0
    let totalTimel5 = 0
    this.assignmentDetails.forEach(element => {
      //part
      if (element['checked']) {
        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
      }
      if (element['children'] != []) {
        //sections
        element['children'].forEach(element => {
          if (element['checked']) {
            totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
            totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
            totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
            totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
            totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
            totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
            totalTime = totalTime +totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
          }
          if (element['children'] != []) {
            //chapter
            element['children'].forEach(element => {
              if (element['checked']) {
                totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                totalTime = totalTime+ totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
              }
              if (element['children'] != []) {
                //subchapter
                element['children'].forEach(element => {
                  if (element['checked']) {
                    totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                    totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                    totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                    totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                    totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                    totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                    totalTime = totalTime+ totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                  }
                  if (element['children'] != []) {
                    //classwork
                    element['children'].forEach(element => {
                      if (element['checked'] && element['key'].startsWith("Classwork")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime+ totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                      }
                      if (element['checked'] && element['key'].startsWith("Homework")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime+totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                      }
                      if (element['checked'] && element['key'].startsWith("Practice")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime+totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                                            }
                                            if (element['checked'] && element['key'].startsWith("Assignment")) {
                                              totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                                              totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                                              totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                                              totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                                              totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                                              totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                                              totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                                            }
                      if (element['checked'] && element['key'].startsWith("AQG")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        if (element['children']) {
                          element['children'].forEach(element => {
                            if (element['checked'] && element['key'].startsWith == 'T')
                              totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                            totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                            totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                            totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                            totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                            totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                            totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                          });
                        }
                      }

                    });

                  }


                });
              }

            });
          }

        });
      }
    });
    this.totalQuestions = totalQuestions
    this.totalTime = totalTime
    this.TOTAL_QUESTIONS=this.totalQuestions 

  }

  getQuestionType(questionType) {
    if (questionType == "C")
      return "Classwork"
    else if (questionType == "H")
      return "Homework"
    else if (questionType == "P")
      return "Practice"
    else if (questionType == "A")
      return "Assignment"
    else if (questionType == "N")
      return "-"
  }

  saveMapQuestions() {

    this.getTotalStats()
    this.TOTAL_QUESTIONS = this.totalQuestions
    this.TIME = this.api.getSecondsToHms(this.totalTime)
    this.loadingMapAssignment = true
    this.api.addMappedAssignmentDetails(this.PART_ID,this.SECTION_ID,this.CHAPTER_ID,this.assignmentId, this.assignmentDetails, this.totalQuestions, this.totalTime)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.message.success("Assignment Details Mapped Successfully ...", "");
          this.loadingMapAssignment = false
          this.flag=true
          this.loadAllStats()
          this.resetValues()
          this.visibleData=false
        }
        else {
          this.message.error("Assignment Details Mapped Mapping Failed...", "");
          this.loadingMapAssignment = false;
        }
      }, err => {
        console.log(err);
        this.loadingMapAssignment = false;
      });


    // this.getTotalStats()
    // this.TOTAL_QUESTIONS = this.totalQuestions

    // this.TIME = this.api.getSecondsToHms(this.totalTime)
    // console.log(this.totalTime)
    // console.log(this.TIME)
    // // let tTime = this.api.getSecondsToHms(this.totalTime)


    // if (flag == "onlysave")
    //   this.loadingMapQuestions = true
    // else
    //   this.loadingMapAssignment = true

    // this.api.addAssignmentDetails(this.assignmentId, this.assignmentDetails, this.totalQuestions, this.totalTime)
    //   .subscribe(successCode => {
    //     if (successCode['code'] == "200") {
    //       this.message.success("Assignment Details Mapped Successfully ...", "");
    //       if (flag == "onlysave") {
    //         this.drawerClose()
    //         this.loadingMapQuestions = false;
    //       }
    //       else {
    //         this.current += 1;
    //         this.loadingMapAssignment = false
    //       }
    //       this.getDeatilsData()
    //     }
    //     else {
    //       this.message.error("Assignment Details Mapped Mapping Failed...", "");
    //       this.loadingMapQuestions = false;
    //     }
    //   }, err => {
    //     console.log(err);
    //     this.loadingMapQuestions = false;
    //   });
  }

  saveAndNext() {
    this.current += 1;
    this.getDeatilsData()
  }

  getDeatilsData() {
    console.log(this.assignmentId)
    // let filter=" AND COLLEGE_ID="+this.collegeId
    let filter = " AND COLLEGE_ID= " + this.collegeId
    this.api.getMappedAssignmentMappingData(this.assignmentId, filter).subscribe(data => {
      console.log("mapping data")
      console.log(data)
      if (data['code'] == 200) {

        this.scope = data['data'][0]['SCOPE']
        this.NAME = data['data'][0]['MAPPING_NAME']
        console.log(this.scope)
        if (data['data'][0]['SCOPE'] == 'O') {
          this.NAME = data['data'][0]['MAPPING_NAME']
          this.ENABLE_TIME = data['data'][0]['ENABLE_TIME']
          this.DISABLE_TIME = data['data'][0]['DISABLE_TIME']
          this.IS_RESULT_SHARING_TIME = data['data'][0]['IS_RESULT_SHARING_ENABLED'] == 0 ? false : true
          this.RESULT_SHARING_TIME = data['data'][0]['RESULT_SHARING_TIME']
          this.PASSKEY = data['data'][0]['PASSKEY']
          this.LINK = data['data'][0]['LINK']
        }
        else {
          this.filterBranches = ['AL']
          this.filterDivision = ['AL']
          this.filterPassingYear = ['AL']
          this.BATCH_ENABLE_TIME = undefined
          this.BATCH_DISABLE_TIME = undefined
          this.BATCH_IS_RESULT_SHARING_TIME = false
          this.BATCH_RESULT_SHARING_TIME = undefined
          this.BATCH_IS_PUBLISHED = false
          this.BATCH_PUBLISHED_TIME = undefined
          this.getBatchDetailsData()
          this.loadBranches()
        }
      }
      else {
        this.message.error("Failed To Get  ...", "");
        this.loadingMapQuestions = false;
      }
    }, err => {
      this.loadingMapQuestions = false;
      console.log(err);
    });
  }

  loadBranches() {
    //get all mapped branches to college
    this.loadingBranches = true

    let filter = " AND COLLEGE_ID=" + this.collegeId
    this.api.getAllCollegeBranches(0, 0, '', '', filter).subscribe(data => {
      this.branches = data['data'];
      this.loadingBranches = false
    }, err => {
      console.log(err);
    });
  }

  getBranchIdss(value) {
    this.loadPassingYears(value)
  }

  loadPassingYears(value) {
    let filterQuery = ""
    this.loadingPassingYears = true

    let barnchstring: string = "";
    if (value != undefined) {
      value.forEach(passingYears => {
        barnchstring += "'" + passingYears + "'" + ",";
      }); (value)

      if (barnchstring.toString() != "") {
        if (barnchstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " BRANCH_ID IN (" + barnchstring.substring(0, barnchstring.length - 1) + ") ";
        }
      }
    }

    this.branchfilterQuery = filterQuery
    let filter = "AND ID in (select YEAR_ID from view_college_training_course_branch_year_mapping where " + filterQuery + ")"
    this.api.getAllPassingYears(0, 0, '', '', filter).subscribe(data => {
      console.log("year")
      this.passingYears = data['data'];
      console.log(this.passingYears)
      this.loadingPassingYears = false

    }, err => {
      console.log(err);
    });
  }

  getPassingYearIdss(value) {
    this.loadDivisions(value)
  }

  loadDivisions(value) {
    this.loadingDivisions = true
    let filterQuery = ""
    let passingyearstring: string = "";
    if (value != undefined) {
      value.forEach(passingYears => {
        passingyearstring += "'" + passingYears + "'" + ",";
      }); (value)

      if (passingyearstring.toString() != "") {
        if (passingyearstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " YEAR_ID IN (" + passingyearstring.substring(0, passingyearstring.length - 1) + ") ";
        }
      }
    }

    let filter = " AND ID in (select DIVISION_ID from view_college_training_course_branch_year_division_mapping where " + this.branchfilterQuery + " and " + filterQuery + ")"
    this.api.getAllDivisions(0, 0, '', '', filter).subscribe(data => {
      this.divisions = data['data'];
      this.loadingDivisions = false
    }, err => {
      console.log(err);
    });

    // this.api.getAllCollegeBranchesPassingYearsDivisions(0, 0, '', '', filter).subscribe(data => {
    //   this.divisions = data['data'];
    //   console.log("divisions")
    //   console.log(this.divisions)
    //   this.loadingDivisions = false
    // }, err => {
    //   console.log(err);
    // });


  }

  getBatchDetailsData() {
    this.loadbatchDetailsFilter = true
    this.loadingFilter = true

    let filterQuery = ""
    console.log(this.filterBranches)
    console.log(this.filterPassingYear)
    console.log(this.filterDivision)

    //Branch Filter

    let branchesString: string = "";
    if (this.filterBranches != undefined) {
      this.filterBranches.forEach(branches => {
        branchesString += "'" + branches + "'" + ",";
      }); (this.filterBranches)

      if (branchesString.toString() != "") {
        if (branchesString.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " AND BRANCH_ID IN (" + branchesString.substring(0, branchesString.length - 1) + ") AND ";
        }
      }
    }

    let passingyearstring: string = "";
    if (this.filterPassingYear != undefined) {
      this.filterPassingYear.forEach(passingYears => {
        passingyearstring += "'" + passingYears + "'" + ",";
      }); (this.filterPassingYear)

      if (passingyearstring.toString() != "") {
        if (passingyearstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += "PASSING_YEAR_ID IN (" + passingyearstring.substring(0, passingyearstring.length - 1) + ") AND ";
        }
      }
    }

    let divisionsString: string = "";
    if (this.filterDivision != undefined) {
      this.filterDivision.forEach(divisions => {
        divisionsString += "'" + divisions + "'" + ",";
      }); (this.filterDivision)

      if (divisionsString.toString() != "") {
        if (divisionsString.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += "DIVISION_ID IN (" + divisionsString.substring(0, divisionsString.length - 1) + ") AND ";
        }
      }
    }

    if (filterQuery == "") {

      filterQuery = " AND COLLEGE_ID= " + this.collegeId
    }
    else {
      filterQuery = filterQuery.substring(0, filterQuery.length - 5);

    }


    this.api.getMappedAssignmentMappingData(this.assignmentId, filterQuery).subscribe(data => {
      console.log("filter data")
      console.log(data)
      this.batchDetailsData = data['data1']
      this.loadingFilter = false
      this.loadbatchDetailsFilter = false
    }, err => {
      console.log(err);
    });
  }

  checkAllBranchesChanged(value, data) {
    if (value) {
      data.forEach(element => {
        element['IS_SELECTED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_SELECTED'] = 0
      });
    }
  }

  checkAllIsPublishChanged(value, data) {
    if (value) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['IS_PUBLISH_ENABLED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_PUBLISH_ENABLED'] = 0
      });
    }
  }

  checkAllIsResultTimeSharingChanged(value, data) {
    if (value) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['IS_RESULT_SHARING_ENABLED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_RESULT_SHARING_ENABLED'] = 0
      });
    }
  }

  getChangeEnableTime(enableTime, data) {
    if (enableTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['ENABLE_TIME'] = enableTime
      });
    }
    else {
      data.forEach(element => {
        element['ENABLE_TIME'] = undefined
      });
    }
  }

  getChangeDisableTime(disableTime, data) {
    if (disableTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['DISABLE_TIME'] = disableTime
      });
    }
    else {
      data.forEach(element => {
        element['DISABLE_TIME'] = undefined
      });
    }
  }

  getChangeResultSharingTime(resultsharingTime, data) {
    if (resultsharingTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1 && element['IS_RESULT_SHARING_ENABLED'] == 1)
          element['RESULT_SHARING_TIME'] = resultsharingTime
      });
    }
    else {
      data.forEach(element => {
        element['RESULT_SHARING_TIME'] = undefined
      });
    }
  }

  getChangePublishedTime(publishedTime, data) {
    if (publishedTime != undefined) {

      data.forEach(element => {
        if (element['IS_SELECTED'] == 1 && element['IS_PUBLISH_ENABLED'] == 1)
          element['PUBLISH_TIME'] = publishedTime
      });
    }
    else {
      data.forEach(element => {
        element['PUBLISH_TIME'] = undefined
      });
    }
  }


  changeBatchCheck(value, data) {
    if (value)
      data.IS_SELECTED = 1
    else
      data.IS_SELECTED = 0
  }

  changePublished(value, data) {
    if (value)
      data.IS_PUBLISH_ENABLED = 1
    else
      data.IS_PUBLISH_ENABLED = 0
  }

  changeResultSharingTime(value, data) {
    if (value)
      data.IS_RESULT_SHARING_ENABLED = 1
    else
      data.IS_RESULT_SHARING_ENABLED = 0
  }

  // disabledDate = (current: Date): boolean => {
  //   // Can not select days before today and today

  //   return differenceInCalendarDays(current, this.today) < 0;
  // };
  getSharingTime(value) {
    if (!value) {
      this.RESULT_SHARING_TIME = ""
    }
  }
  save() {

    var finalData = []

    if (this.scope == "O") {
      if (new Date(this.ENABLE_TIME) >= this.today || new Date(this.DISABLE_TIME) >= this.today || new Date(this.RESULT_SHARING_TIME) >= this.today) {

        if (new Date(this.DISABLE_TIME) >= new Date(this.ENABLE_TIME) || (new Date(this.RESULT_SHARING_TIME) >= new Date(this.DISABLE_TIME) && new Date(this.RESULT_SHARING_TIME) >= new Date(this.ENABLE_TIME))) {
          this.ENABLE_TIME = this.datePipe.transform(this.ENABLE_TIME, "yyyy-MM-dd HH:mm:ss")
          this.DISABLE_TIME = this.datePipe.transform(this.DISABLE_TIME, "yyyy-MM-dd HH:mm:ss")
          var isSharingTime = this.IS_RESULT_SHARING_TIME ? 1 : 0
          if (this.RESULT_SHARING_TIME != "")
            this.RESULT_SHARING_TIME = this.datePipe.transform(this.RESULT_SHARING_TIME, "yyyy-MM-dd HH:mm:ss")
          else
            this.RESULT_SHARING_TIME = null

          finalData = [{ ENABLE_TIME: this.ENABLE_TIME, DISABLE_TIME: this.DISABLE_TIME, IS_RESULT_SHARING_ENABLED: isSharingTime, RESULT_SHARING_TIME: this.RESULT_SHARING_TIME, PASSKEY: this.PASSKEY, LINK: this.LINK, CLIENT_ID: this.api.clientId }]
          this.saveMapping(finalData)
        }
        else {
          this.message.error("Please Check Time...", "");
        }
      }
      else {
        this.message.error("Invalid Date Selected ...", "");
      }
    }
    else {
      if (this.NAME != "") {
        this.batchDetailsData.forEach(element => {
          element['ENABLE_TIME'] = this.datePipe.transform(element['ENABLE_TIME'], "yyyy-MM-dd HH:mm:ss")
          element['DISABLE_TIME'] = this.datePipe.transform(element['DISABLE_TIME'], "yyyy-MM-dd HH:mm:ss")
          element['RESULT_SHARING_TIME'] = this.datePipe.transform(element['RESULT_SHARING_TIME'], "yyyy-MM-dd HH:mm:ss")
          element['PUBLISH_TIME'] = this.datePipe.transform(element['PUBLISH_TIME'], "yyyy-MM-dd HH:mm:ss")
        });
        finalData = this.batchDetailsData
        this.saveMapping(finalData)
      }
      else {
        this.message.error("Please Enter Name ...", "");
      }
    }


  }

  saveMapping(finalData) {
    this.loadingSaveClose = true
    this.api.addMappedAssignmentMapping(this.NAME, this.scope, this.assignmentId, finalData)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.message.success("Assignment Mapping Successfully ...", "");
          this.loadingSaveClose = false
          this.drawerClose()
          this.NAME = ""
        }
        else {
          this.message.error("Assignment Mapping Failed...", "");
          this.loadingSaveClose = false
        }
      }, err => {
        console.log(err);
        this.message.error("Server Error...", "");
        this.loadingSaveClose = false
      });
  }


}

