export class Mappedassignment {
    ID:number;
    MAPPED_ASSIGNMENT_MASTER_ID:number
    CLIENT_ID:number;
    ASSIGNMENT_ID:number;
    GROUP_ID:number;
    NAME:string;
    DESCRIPTION:string;
    POSITIVE_MARKS:number;
    NEGATIVE_MARKS:number;
    PLATFORM:string;
    CONTRAVENTION_COUNT:number;
    VALIDATION_COUNT:number;
    IS_VALIDATION_COMPULSORY:boolean;
    VALIDATION_METHOD:string;
    RANDOMIZE_QUESTIONS:boolean;
    RESULTS_SHARING_STATUS:boolean;
    RESULT_SHARING_TIME:number;
    SECTION_SWITCHING:string;
    TIMER_TYPE:string;
    TEST_SUBMISSION:boolean;
    TOTAL_QUESTIONS:number;
    TOTAL_TIME:number=0;
    MAPPED_ASSIGNMENT_MASTER_RESULT_SHARING_TIME:number
    platforms= [
        { label: 'Mobile', value: 'M', checked: true },
        { label: 'Web', value: 'W', checked: false },
      ];
}

export class Mappedassignment2 {
  ID:number;
  MAPPED_MOCK_MASTER_ID:number
  CLIENT_ID:number;
  ASSIGNMENT_ID:number;
  GROUP_ID:number;
  NAME:string;
  DESCRIPTION:string;
  POSITIVE_MARKS:number;
  NEGATIVE_MARKS:number;
  PLATFORM:string;
  CONTRAVENTION_COUNT:number;
  VALIDATION_COUNT:number;
  IS_VALIDATION_COMPULSORY:boolean;
  VALIDATION_METHOD:string;
  RANDOMIZE_QUESTIONS:boolean;
  RESULTS_SHARING_STATUS:boolean;
  RESULT_SHARING_TIME:number;
  SECTION_SWITCHING:string;
  TIMER_TYPE:string;
  TEST_SUBMISSION:boolean;
  TOTAL_QUESTIONS:number;
  TOTAL_TIME:number=0;
  MAPPED_MOCK_MASTER_RESULT_SHARING_TIME:number
  IS_DEDICATED:boolean=false;
  platforms= [
      { label: 'Mobile', value: 'M', checked: true },
      { label: 'Web', value: 'W', checked: false },
      { label: 'Desktop', value: 'D', checked: false },
    ];
}

