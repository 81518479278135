import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { State } from 'src/app/Models/CollegeOnboarding/state';
import { Region } from 'src/app/Models/CollegeOnboarding/region';
import { District } from 'src/app/Models/CollegeOnboarding/district';
import { CollegeComponent } from '../college/college.component';
import { DocumentsComponent } from '../Documents/documents/documents.component';
import { Collegetype } from 'src/app/Models/CollegeOnboarding/collegetype';
import { OnboardComponent } from '../onboard/onboard.component';
import { CollegeholidayComponent } from '../collegeholiday/collegeholiday.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-colleges',
  templateUrl: './colleges.component.html',
  styleUrls: ['./colleges.component.css']
})
export class CollegesComponent implements OnInit {

  formTitle = "Manage Colleges";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  STATE_ID = "0"
  REGION_ID = "0"
  DISTRICT_ID = "0"
  COLLEGE_TYPE_ID=['0']
  imageurl:any
  states: State[];
  regions: Region[];
  ditricts: District[];
  collegeTypes: Collegetype[];
  loadingStates = false
  loadingRegions = false
  loadingDistricts = false
  loadingCollegeTypes=false
  filterClass: string = "filter-invisible";
  applyLoading = false
  columns: string[][] = [["NAME", "Name"], ['CITY', 'City']]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: College = new College();

  DocumentsdrawerVisible:boolean
  DocumentsdrawerTitle:string=""
  collegeId:number
  modelVisible:boolean
  modelTitle:string=""

  drawerData2:string
  drawerVisible1: boolean;
  drawerTitle1: string;
  //drawerData1: College = new College();

  drawerDataBranchMapping:string
  drawerVisibleBranchMapping: boolean;
  drawerTitleBranchMapping: string;

  holidayCalenderTitle:string
  holidayCalenderVisible:boolean

  filterValue=[]
  districtNodes=[]

  @ViewChild(CollegeComponent, { static: false }) college: CollegeComponent;
  @ViewChild(DocumentsComponent, { static: false }) collegeDocument: DocumentsComponent;
  @ViewChild(OnboardComponent, { static: false }) onboard: OnboardComponent;
  @ViewChild(CollegeholidayComponent, { static: false }) collegeHolidayCompoent: CollegeholidayComponent;
  pageSize2=10

  constructor(public api: ApiService, private cookie: CookieService) { }

  ngOnInit() {
    
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else
    {
    this.search();
    this.loadAllDistrict()
    this.logtext = "OPENED - Colleges form KEYWORD[O - Colleges] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    }
  }

  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }


  getName(value)
  {
    if(value==0)
    return "Onboard"
    else
    return "Onboarded"
  }

  manageContants(data:College)
  {
   
    this.college.loadCollegeData(data.ID,data.ABBREVATION)
    this.logtext = "MANAGE_CONTACT - CollegeContact form KEYWORD [E - CollegeContact] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  manageDocuments(data:College)
  {
    this.collegeId=data.ID
   this.DocumentsdrawerVisible=true
   this.DocumentsdrawerTitle="Manage Documents for "+ data.ABBREVATION
this.collegeDocument.search(data.ID)
  }

  UpdateOnboarded(data:College)
  {
    this.modelVisible=true
    this.onboard.EMAIL_IDS=undefined
    this.modelTitle="College Onboarding notify following People"
    this.drawerData = Object.assign({}, data);
  }


  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Colleges form "+ sort +" "+this.sortKey +" KEYWORD [F - Colleges] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

    }

 

    //this.loadAllDistricts()
    this.loadcollegeTypes()
    this.applyFilter()
    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery
  
      this.logtext = "Filter Applied - Colleges form "+ filter +" KEYWORD [F - Colleges] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    this.applyLoading = true
    this.api.getAllStudentColleges(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
      this.loadingRecords = false;
      this.applyLoading = false
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }


  loadAllDistrict() {
    this.api.getAllDistrict().subscribe(localName => {
      this.districtNodes = localName['data'];
    }, err => {
      console.log(err);
    });
  }

  loadcollegeTypes() {
    this.loadingCollegeTypes = true;
    this.api.getAllCollegetypes(0, 0, '', '', '').subscribe(localName => {
      console.log(localName)
      this.collegeTypes = localName['data'];
      this.loadingCollegeTypes = false;
    }, err => {
      console.log(err);
      this.loadingCollegeTypes = false;
    });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {


    let stateFilter="";
    let regionFilter="";
    let districtFilter="";

  
  if(!this.COLLEGE_TYPE_ID.toString().match('0'))
  {
    this.filterQuery="AND TYPE_ID IN ("+this.COLLEGE_TYPE_ID+")"
    
  console.log(this.COLLEGE_TYPE_ID.toString())
  }



    if(this.filterValue.length>0)
    {
    this.filterValue.forEach(element => {
      element="" + element;
      if(element.startsWith("s"))
      {
          stateFilter+= element.replace("s","") + ",";
      }
      else if(element.startsWith("r"))
      {
        regionFilter+= element.replace("r","") + ",";
      }
      else
      {
        districtFilter+= element + ",";
      }
    });
    
    stateFilter=(stateFilter.length>0)?"COLLEGE_STATE_ID IN (" +  stateFilter.substring(0,stateFilter.length-1) + ") OR ":"";
    regionFilter=(regionFilter.length>0)?"COLLEGE_REGION_ID IN (" +  regionFilter.substring(0,regionFilter.length-1) + ") OR ":"";
    districtFilter=(districtFilter.length>0)?"DISTRICT_ID IN (" +  districtFilter.substring(0,districtFilter.length-1) + ") OR ":"";

    this.filterQuery= " AND (" + stateFilter + regionFilter + districtFilter;

    

    if(this.filterQuery.length>6)
      this.filterQuery=  this.filterQuery.substring(0,this.filterQuery.length-3) + ")";
    else
      this.filterQuery ="";

      console.log(this.filterQuery)
  this.isFilterApplied = "primary";
  this.filterClass = "filter-invisible";



    }
    else{
      this.isFilterApplied = "default";
    }
  }

  clearFilter() {
    this.filterQuery = ""
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.COLLEGE_TYPE_ID=['0']
    this.filterValue=[]
    this.search()
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    
    this.drawerTitle = "Create New College";
    this.drawerData = new College();
    this.drawerVisible = true;
    this.college.reset()
    this.setValues()
    this.logtext = "ADD - College form KEYWORD [A - College] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  setValues() {
    this.drawerData.IS_ACTIVE = true
  }


  edit(collegedata: College): void {
    console.log(collegedata.LOGO_URL)
    this.college.reset()

    if(collegedata.LOGO_URL!="" && collegedata.LOGO_URL!=" ")
    {
      this.college.isSpinning=true
      let TYPED_ARRAY
      this.api.getFile(collegedata.LOGO_URL).subscribe(data => {
        TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
      const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
        return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        this.imageurl = 'data:image/jpg;base64,' + base64String;
        this.drawerData.LOGO_URL_CONVERTED=this.imageurl
      this.college.isSpinning=false

        }, err => {
        console.log(err);
      });
    }
   
console.log(collegedata)
    this.drawerTitle = "Update College";
    this.drawerData = Object.assign({}, collegedata);
    this.drawerVisible = true;

    this.logtext = "EDIT - College form KEYWORD [E - College] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }


  drawerClose(): void {
    
    this.search();
    this.drawerVisible = false;
  }


  get DocumentscloseCallback() {
    return this.DocumentsdrawerClose.bind(this);
  }

  DocumentsdrawerClose(): void {
    this.search();
    this.DocumentsdrawerVisible = false;
  }


  get OnboardCloseCallback() {
  return this.modelClose.bind(this);
}

save()
{
this.onboard.save()
}

modelClose()
{
  this.search()
  this.modelVisible = false;
}

mapCollegeTypes(collegedata: College)
{
  this.api.getCollegeTypeMapping(collegedata.ID).subscribe(data => {
    console.log(data);
   this.drawerData2 = data['data'];
    }, err => {
    console.log(err);
  }); 
  this.drawerTitle1 = "Assign College Types for " + collegedata.ABBREVATION + "";
  this.drawerData = Object.assign({}, collegedata);
  this.drawerVisible1 = true;
}

get closeCallback1() {
  return this.drawerClose1.bind(this);
}

drawerClose1(): void {
  this.search();
  this.drawerVisible1 = false;
}


get closeCallbackBranchMapping() {
  return this.drawerCloseBranchMapping.bind(this);
}

drawerCloseBranchMapping(): void {
  this.search();
  this.drawerVisibleBranchMapping = false;
}


mapBranches(collegedata: College)
{
  this.api.getBranchMapping(collegedata.ID).subscribe(data => {
    console.log(data);
   this.drawerDataBranchMapping = data['data'];
    }, err => {
    console.log(err);
  }); 
  this.drawerTitleBranchMapping = "Assign Branches for " + collegedata.ABBREVATION + "";
  this.drawerData = Object.assign({}, collegedata);
  this.drawerVisibleBranchMapping = true;
}
  

get closeCallbackHolidayCalender() {
  return this.drawerCloseholidayCalender.bind(this);
}

drawerCloseholidayCalender(): void {
  this.search();
  this.holidayCalenderVisible = false;
}

holidayCalender(data:College)
{
  this.holidayCalenderTitle = "Holiday Calender For " + data.ABBREVATION + "";
  this.drawerData = Object.assign({}, data);
  this.holidayCalenderVisible = true;
  this.collegeHolidayCompoent.applyFilter(data.ID)
}



} 
