export class CollegeMaster {
    ID:number;
    CLIENT_ID:number;
    NAME:string='';
    DISTRICT_ID:number
    COLLEGE_STATE_ID:number
    COLLEGE_REGION_ID:number
    ABBREVATION:string='';
    LOGO_URL:string=''; 
    LOGO_URL_CONVERTED:string='';
    ADDRESS:string='';
    CITY:string='';
    PINCODE:number;
    LONGITUDE:number;
    LATITUDE:number;
    IS_ACTIVE:boolean=true;
    IS_ONBOARDED:boolean
    TRAINING_DEFAULT_VEHICAL_DEPARTURE_TIME:Date

}
