import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { State } from 'src/app/Models/CollegeOnboarding/state';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService} from 'ng-zorro-antd/notification';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: State;
  isSpinning = false
  logtext: string = "";
  userId =sessionStorage.getItem('userId');
  @Input() dataList;
@Input() drawerCloseState:Function


  constructor(public api: ApiService, public api2: ShikuyaaapiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {

  }

  close(stateMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(stateMasterPage);
    this.logtext = 'CLOSED - State form';
    this.api2.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  // close(): void {
  //   this.drawerClose();
  //   //this.drawerCloseState()
  //   this.logtext = 'CLOSED - State form';
  //   this.api2.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }
  resetDrawer(stateMasterPage: NgForm) {
    this.data=new State();
    // stateMasterPage.form.reset();
    stateMasterPage.form.markAsPristine();
    stateMasterPage.form.markAsUntouched();
  }

  ///save
  save(addNew: boolean,stateMasterPage: NgForm): void {

    if(this.data.NAME!=undefined && this.data.NAME!="")
    {

      if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
      {
      
       
      
        if (this.data.ID) {
          var filterData= this.dataList.filter(object => {
            return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
          });

          if(filterData.length>0)
          {
          this.message.error("Already Record Present in Database", "");
          }
          else
          {
            this.isSpinning = true;
            this.api2.updateState(this.data)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  this.message.success("State information updated Successfully...", "");
      
                  this.logtext = 'Update & Close - State form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - State ]";
                  this.api.addLog('A', this.logtext, this.api2.emailId)
                    .subscribe(successCode => {
                      if (successCode['code'] == "200") {
                        console.log(successCode);
                      }
                      else {
                        console.log(successCode);
                      }
                    });
      
                  if (!addNew)
                    this.drawerClose();
                  this.isSpinning = false;
                }
                else {
      
                  this.logtext = 'Update & Close - State form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - State ]";
                  this.api2.addLog('A', this.logtext, this.api2.emailId)
                    .subscribe(successCode => {
                      if (successCode['code'] == "200") {
                        console.log(successCode);
                      }
                      else {
                        console.log(successCode);
                      }
                    });
      
                  this.message.error("Failed to update state information...", "");
                  this.isSpinning = false;
                }
              });
          }

        }
        else {
    
          var filterData= this.dataList.filter(object => {
            return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
          });
    
         if(filterData.length>0)
         {
          this.message.error("Already Record Present in Database", "");
         }
         else
         {
          this.isSpinning = true;
          this.api2.createState(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("State information added successfully...", "");
  
              if (!addNew) {
                this.drawerClose();
                this.logtext = 'Save & Close - State form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - State ]";
                this.api2.addLog('A', this.logtext, this.api2.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.data = new State();
                this.resetDrawer(stateMasterPage);
                  this.setValues()
                  this.api2.getAllStudentStates(0, 0, 'ID', 'desc', '').subscribe(data => {
                    this.dataList = data['data'];
                  }, err => {
                    console.log(err);
                  });
                this.logtext = 'Save & New - State form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - State ]";
                this.api2.addLog('A', this.logtext, this.api2.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
  
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add state information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - State form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - State ]";
              this.api2.addLog('A', this.logtext, this.api2.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
  
            }
          });
         }
          
        }
      
  
      }
      else
      {
        this.message.error("Please Check Name", "");
      }
    
  }
  else
  {
    this.message.error("Please Fill All Required Fields", "");
  }
  }

  setValues()
  {
    this.data.IS_ACTIVE = true
  }


}