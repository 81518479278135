import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-studassessmentdetail',
  templateUrl: './studassessmentdetail.component.html',
  styleUrls: ['./studassessmentdetail.component.css']
})
export class StudassessmentdetailComponent implements OnInit {

  formTitle = "Student Assessment Details ";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";
  filterValue = ""
  filterClass: string = "filter-invisible";
  // STUDENT_NAME,MAPPED_NAME,TOTAL,SOLVED,CORRECT,INCORRECT,TOTAL_SEC,SOLVED_SEC,AVG_SEC,MARKS,PERCENTAGE,ACCURACY,PERFORMANCE
  //,total questions,solved ques,total time,solved time,total marks,marks,percentage,accuracy,performance
  columns: string[][] = [['STUDENT_NAME','Student Name'],["MOCK_NAME", "Assesment Name"],['TOTAL','Total Questions'],['SOLVED','Solved Questions'],
  ["TOTAL_SEC", "Total Time"],['SOLVED_SEC','Solved Time'],['MARKS','Marks'],['PERCENTAGE','Percentage'],
  ['ACCURACY','Accuracy'],['PERFORMANCE_INDEX','Performance']]
  loadingStudents = false
  students = []
  cold:string[][]=[['MAPPED_NAME',''],['TOTAL_QUESTIONS',''],['TOTAL_TIME','']]
  STUDENT_ID =''
  coursesNodes = []
  isloadSpinning = false
  dataList1 = []
  pageSize2 = 10
  selectedDate: Date[] = []
  value1: string = ""
  value2: string = ""
  dateFormat = 'dd/MM/yyyy';
  datePipe = new DatePipe("en-US");
  loadingcourse = false
   isExportloading = false
  constructor(public api: ApiService, private message: NzNotificationService) { }
  ngOnInit() {
    this.selectedDate = [new Date(), new Date()]
    this.changeDate(this.selectedDate)
   
    this.loadAllCourses();

    this.logtext = 'OPENED - Student Assesment Details KEYWORD[O - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }
  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd")
  }
  loadAllCourses() {
    // this.filterClass = "filter-visible";
    this.loadingcourse = true
    this.students = []
   
    this.api.getAllCourses(0, 0, 'ID', 'ASC', ' AND COURSE_TYPE_ID = 2').subscribe(localName => {
      this.loadingcourse = false
      this.coursesNodes = localName['data'];
      this.filterValue = ''
      this.loadingStudents = true
      this.api.getAllCourseStudentList(0, 0, 'id', 'asc', ' AND COURSE_ID =  '+this.filterValue).subscribe(data => {
        this.loadingStudents = false
        this.students = data['data'];

      }, err => {
        console.log(err);
      });
     
    }, err => {
      console.log(err);
    });
  }

  loadAllCourses2(event) {
    this.students = []
      this.filterValue = event
      this.loadingStudents = true
      this.api.getAllCourseStudentList(0, 0, 'id', 'asc', ' AND COURSE_ID =  '+this.filterValue).subscribe(data => {
        this.loadingStudents = false
        this.students = data['data'];

      }, err => {
        console.log(err);
      });      
  }

  
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search(false);
  }

  onKeyDownEvent(event)
  {if(event.key=='Enter')
  {
    event.preventDefault()
  }

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - Student Assesment Details"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
    }

    this.logtext = 'Filter Applied - Student Assesment Details '+ likeQuery +' KEYWORD [F - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

    console.log(filter)
    this.isloadSpinning = true

    this.api.getAllStudentAssesmentDetailsReport(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {

    // this.api.getStudentAssesmentDetailsReport(this.pageIndex, this.pageSize, this.sortKey, sort, filter,this.filterValue,this.STUDENT_ID).subscribe(data => {
        this.isloadSpinning = false
      this.loadingRecords = false;
      if(data['code']=='200'){
      this.totalRecords = data['count'];
     
      this.dataList = data['data'];
      if (data['count'] >0) { 
      
        this.isExportloading = true
  
        this.dataList1 = data['data']; }
     
    
      }
     
    }, err => {
      console.log(err);
    });
    
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    if (this.STUDENT_ID == undefined  ) {
      this.message.error('Please Select filters ', '')
    }
    else {
    this.filterQuery = ""
    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
    this.search(true)
    }
  }
  clearFilter() {
    this.filterValue =''
    this.selectedDate = [new Date(), new Date()]
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.STUDENT_ID=''
   this.search(true)
  }

  

}
