import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHours'
})
export class SecondsToHoursPipe implements PipeTransform {

  transform(value: string): string {
    if (value != null || value == '') {
      const sec = parseInt(value, 10);

      let hours = Math.floor(sec / 3600);
      let minutes = Math.floor((sec - (hours * 3600)) / 60);
      let seconds = sec - (hours * 3600) - (minutes * 60);
      let days = hours/6
      if (hours < 10) { hours = 0 + hours; }
      if (minutes < 10) { minutes = 0 + minutes; }
      if (seconds < 10) { seconds = 0 + seconds; }
// days+'d'+' '+
      var totalWorklogTime = hours + 'h ' +' '+ minutes + 'm ' +' '+ seconds + 's';
      return totalWorklogTime;
    }
  }

}
