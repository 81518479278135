import { Component, OnInit } from '@angular/core';
import { Questionparagraph } from 'src/app/Models/MasterSyllabus/questionparagraph';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-questionparagraphs',
  templateUrl: './questionparagraphs.component.html',
  styleUrls: ['./questionparagraphs.component.css']
})
export class QuestionparagraphsComponent implements OnInit {

  formTitle = "Manage Question Paragraphs";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  columns: string[][] = [["PARAGRAPH_TEXT", "Paragraph Text"]]
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Questionparagraph = new Questionparagraph();

  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Questionparagraph = new Questionparagraph();
  drawerData2: string[];
  imageurl: any
  pageSize2=10

  constructor(public api: ApiService, private cookie: CookieService) { }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.search();
      this.logtext = "OPENED - QuestionParagraphs form KEYWORD[O - QuestionParagraphs] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
      this.logtext = "Filter Applied - QuestionParagraphs form "+ sort +" "+this.sortKey +" KEYWORD [F - QuestionParagraphs] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
      this.logtext = "Filter Applied - QuestionParagraphs form "+ likeQuery +" KEYWORD [F - QuestionParagraphs] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }

 

    this.api.getAllQuestionParagraphs(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Question Paragraph";
    this.drawerData = new Questionparagraph();
    this.drawerVisible = true;

    this.logtext = "ADD - QuestionParagraph form KEYWORD [A - QuestionParagraph] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }
  edit(data: Questionparagraph): void {
    if ( data.PARAGRAPH_TYPE=="I" && data.PARAGRAPH_IMAGE_VIDEO_URL != "") {
      let TYPED_ARRAY
      this.api.getFile(data.PARAGRAPH_IMAGE_VIDEO_URL).subscribe(data => {
        console.log(data)
        TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        this.imageurl = 'data:image/jpg;base64,' + base64String;
        this.drawerData.PARAGRAPH_IMAGE_VIDEO_URL_CONVERTED = this.imageurl
      }, err => {
        console.log(err);
      });
    }

    console.log(data)

    this.drawerTitle = "Update Question Paragraph";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    this.logtext = "EDIT - QuestionParagraph form KEYWORD [E - QuestionParagraph] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });


  }

  mapQuestions(data: Questionparagraph) {
    this.api.getQuestionsOfParagraph(data.ID).subscribe(data => {
      console.log(data['data']);
      this.drawerData2 = data['data'];
    }, err => {
      console.log(err);
    });
    this.drawerTitle1 = "Question Details";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}
