export class Part {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    DESCRIPTION:string;
    IS_ACTIVE:boolean;
    WEIGHTAGE:number;
    ICON_URL:string;
    COLOR_CODE:string;
    SEQUENCE_NO:number;
    ICON_URL_CONVERTED:string;
}
