export class Assignmentdetails {
    ID: number;
    CLIENT_ID: number;
    ASSIGNMENT_ID: number;
    PART_ID: number;
    SECTION_ID: number;
    CHAPTER_ID: number;
    SUBCHAPTER_ID: number;
    QUESTION_TYPE: string;
    AQG_ID: number;
    L1_QUESTIONS: number;
    L1_TIME: number;
    L2_QUESTIONS: number;
    L2_TIME: number;
    L3_QUESTIONS: number;
    L3_TIME: number;
    L4_QUESTIONS: number;
    L4_TIME: number;
    L5_QUESTIONS: number;
    L5_TIME: number;
}

export class Assignmentdetails2 {
    ID: number;
    CLIENT_ID: number;
    MOCK_MASTER_ID: number;
    CHAPTER_ID: number;
    QUESTION_TYPE: string;
    AQG_ID: number;
    L1_QUESTIONS: number;
    L1_TIME: number;
    L2_QUESTIONS: number;
    L2_TIME: number;
    L3_QUESTIONS: number;
    L3_TIME: number;
    L4_QUESTIONS: number;
    L4_TIME: number;
    L5_QUESTIONS: number;
    L5_TIME: number;
    MOCK_DETAILS_ID: number;
    L1_QUESTIONS_DATA = []
    L2_QUESTIONS_DATA = []
    L3_QUESTIONS_DATA = []
    L4_QUESTIONS_DATA = []
    L5_QUESTIONS_DATA = []
}
