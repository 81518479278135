import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Coupanfacility } from 'src/app/Models/ShikuyaaModels/coupanfacility';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-couponfacility',
  templateUrl: './couponfacility.component.html',
  styleUrls: ['./couponfacility.component.css']
})
export class CouponfacilityComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Coupanfacility;
    isSpinning = false
  logtext:string = "";
  dataList=[]


  constructor(public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
  this.loadCouponfacility()
  }
  
  loadCouponfacility()
  {
    this.api.getAllCouponfacilities(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  // close(): void {
  //   this.drawerClose();

	// this.logtext = 'CLOSED - CouponFacility form';
	// this.api.addLog('A',this.logtext,this.api.emailId)
  //         .subscribe(successCode => {
  //           if (successCode['code'] == "200") {
  //            console.log(successCode);
  //           }
  //           else {
	// 	console.log(successCode);
  //           }
  //         });

  // }

////

resetDrawer(couponFacilityPage: NgForm) {
  this.data=new Coupanfacility();
  // couponFacilityPage.form.reset();

  couponFacilityPage.form.markAsPristine();
  couponFacilityPage.form.markAsUntouched();
}

close(couponFacilityPage: NgForm) {
  this.drawerClose();
  this.resetDrawer(couponFacilityPage);
  couponFacilityPage.form.reset();
  this.logtext = 'CLOSED - CouponFacility form';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });


}
//save
save(addNew: boolean,couponFacilityPage: NgForm): void {

if(this.data.NAME!=undefined && this.data.NAME!="")
  {
    if (/^[a-zA-Z0-9\s-,._-]*$/.test(this.data.NAME) == true) {


  
      if (this.data.ID) {
        var filterData = this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
        });

        if (filterData.length > 0) {
          this.message.error("Name Already Record Present in Database", "");
        }
        else {
          
    this.isSpinning = true;
        this.api.updateCouponFacility(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("CouponFacility information updated Successfully...", "");

	this.logtext = 'Update & Close - CouponFacility form - SUCCESS '+ JSON.stringify(this.data)+" KEYWORD [U - CouponFacility ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

	this.logtext = 'Update & Close - CouponFacility form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [U - CouponFacility ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              this.message.error("Failed to update couponFacility information...", "");
              this.isSpinning = false;
            }
          });
        }
        }
      else {
        var filterData = this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
        });

        if (filterData.length > 0) {
          this.message.error("Name Already Record Present in Database", "");
        }
        else {

          this.isSpinning = true;
        this.api.createCouponFacility(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("CouponFacility information added successfully...", "");

              if (!addNew)
	{
                this.drawerClose();

	this.logtext = 'Save & Close - CouponFacility form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - CouponFacility ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
	}

              else {
                this.data = new Coupanfacility();
                this.resetDrawer(couponFacilityPage);
	this.logtext = 'Save & New - CouponFacility form - SUCCESS - '+ JSON.stringify(this.data)+" KEYWORD [C - CouponFacility ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add couponFacility information...", "");
	this.isSpinning = false;
	this.logtext = 'Save & Close - CouponFacility form - ERROR - '+ JSON.stringify(this.data)+" KEYWORD [C - CouponFacility ]";
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
              
            }
          });
        }
        }
    }
    else
    {
      this.message.error("Name is invalid value", "");
    }
    }
    else
    {
      this.message.error("Please Enter Name", "");

    }
  }


}