import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { Documenttype } from 'src/app/Models/CollegeOnboarding/documenttype';

@Component({
  selector: 'app-documenttype',
  templateUrl: './documenttype.component.html',
  styleUrls: ['./documenttype.component.css']
})
export class DocumenttypeComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Documenttype;
  @Input() dataList;

  isSpinning = false
  logtext: string = "";
  userId = sessionStorage.getItem('userId');

  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
  }

  close(): void {
    this.drawerClose();
    this.logtext = 'CLOSED - DocumentType form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  setValues()
  {
    this.data.IS_ACTIVE=true
  }

  save(addNew: boolean): void {
    if (this.data.NAME != undefined && this.data.NAME != "") {

      if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
      {
        if(this.data.DESCRIPTION=="")
        this.data.DESCRIPTION=" "

      if (this.data.ID) {


        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
        });
    
        if(filterData.length>0)
        {
        this.message.error("Already Record Present in Database", "");
        }
        else
        {
         
        this.isSpinning = true;
        this.api.updateDocumentType(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("DocumentType information updated Successfully...", "");

              this.logtext = 'Update & Close - DocumentType form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - DocumentType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

              this.logtext = 'Update & Close - DocumentType form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - DocumentType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              this.message.error("Failed to update documentType information...", "");
              this.isSpinning = false;
            }
          });
        
        }
      }
      else {

        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
        });
  
       if(filterData.length>0)
       {
        this.message.error("Already Record Present in Database", "");
       }
       else
       {
        this.isSpinning = true;

        this.api.createDocumentType(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("DocumentType information added successfully...", "");
              if (!addNew) {
                this.drawerClose();
                this.logtext = 'Save & Close - DocumentType form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - DocumentType ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.data = new Documenttype();
                this.setValues()
                this.api.getAllDocumentTypes(0, 0, 'ID', 'desc', '').subscribe(data => {
                  this.dataList = data['data'];
                }, err => {
                  console.log(err);
                });
                this.logtext = 'Save & New - DocumentType form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - DocumentType ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add documentType information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - DocumentType form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - DocumentType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
          });
       }
        
      }
    }
    else
{
  this.message.error("Please Check Name", "");

}

    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }


}