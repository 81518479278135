import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Coupan } from 'src/app/Models/ShikuyaaModels/coupan';
import { Coupontype } from 'src/app/Models/ShikuyaaModels/coupontype';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css'],
  providers: [DatePipe]
})
export class CouponComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Coupan;
  isSpinning = false
  logtext: string = "";
  dataList=[]
  couponTypes:Coupontype[]
  loadingCouponTypes=false
  
  constructor(public api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
this.loadCoupons()
this.loadCouponTypes()
  }

  loadCouponTypes()  {
    this.api.getAllCoupontypes(0, 0, 'ID', 'desc', ' AND IS_ACTIVE=1').subscribe(data => {
      this.couponTypes = data['data'];
    }, err => {
      console.log(err);
    });
  }

  loadCoupons()  {
    this.api.getAllCoupons(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  // close(): void {
  //   this.drawerClose();

  //   this.logtext = 'CLOSED - Coupon form';
  //   this.api.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }

   ////

   resetDrawer(couponMasterPage: NgForm) {
    this.data=new Coupan();
    // couponMasterPage.form.reset();

    couponMasterPage.form.markAsPristine();
    couponMasterPage.form.markAsUntouched();
  }

  close(couponMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(couponMasterPage);
    couponMasterPage.form.reset();
    this.logtext = 'CLOSED - Coupon form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  
  }
  //save
  save(addNew: boolean,couponMasterPage: NgForm): void {
  
    if (this.data.NAME != "" && this.data.NAME != undefined && this.data.START_DATE != undefined && this.data.EXPIRY_DATE != undefined && this.data.MAX_USES_COUNT != undefined && this.data.MAX_USES_COUNT.toString() != "" && this.data.COUPON_CODE != undefined && this.data.COUPON_CODE != "" && this.data.COUPON_VALUE != undefined && this.data.COUPON_VALUE.toString() != "" && this.data.PERUSER_MAX_COUNT != undefined && this.data.PERUSER_MAX_COUNT.toString() != "" && this.data.COUPON_MAX_VALUE != undefined && this.data.COUPON_MAX_VALUE.toString() != "" && this.data.MIN_CART_AMOUNT != undefined && this.data.MIN_CART_AMOUNT.toString() != "") {
      this.data.START_DATE = this.datePipe.transform(this.data.START_DATE, "yyyy-MM-dd HH:mm:ss")
      this.data.EXPIRY_DATE = this.datePipe.transform(this.data.EXPIRY_DATE, "yyyy-MM-dd HH:mm:ss")


     
   
      this.isSpinning = true;
      console.log(this.data)
      if (this.data.ID) {

            
        var filterData1 = this.dataList.filter(object => {
          return object['NAME'] == this.data.NAME && object['ID'] != this.data.ID
          
        });
  
        var returnData1 =  this.dataList.filter(object => {
          return object['COUPON_CODE'] == this.data.COUPON_CODE && object['ID'] != this.data.ID
        });
       
       
        if (filterData1.length > 0) {
          this.message.error("Name Is Already  Present in Database", "");
          this.isSpinning = false;
          this.data.NAME=''
        }
        else if (returnData1.length > 0 ) {
          this.message.error("Coupon Code Is Already  Present in Database", "");
          this.isSpinning = false;
          this.data.COUPON_CODE=''
        }
        else if(filterData1.length > 0 )
        {
          this.message.error("Same Record Found in Database", "");
          this.isSpinning = false;
        
        }
        else{
          this.api.updateCoupon(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Coupon information updated Successfully...", "");

              this.logtext = 'Update & Close - Coupon form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Coupon ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

              this.logtext = 'Update & Close - Coupon form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Coupon ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              this.message.error("Failed to update coupon information...", "");
              this.isSpinning = false;
            }
          });
        }
      
        }
        
      else {
      
        var filterData = this.dataList.filter(object => {
          return object['NAME'] == this.data.NAME
          
        });
  
        var returnData =  this.dataList.filter(object => {
          return object['COUPON_CODE'] == this.data.COUPON_CODE  && object['ID'] != this.data.ID
        
        });
        if (filterData.length > 0) {
          this.message.error("Name Is Already  Present in Database", "");
          this.isSpinning = false;
          this.data.NAME=''
        }
        else if (returnData.length > 0 ) {
          this.message.error("Coupon Code Is Already  Present in Database", "");
          this.isSpinning = false;
          this.data.COUPON_CODE=''
        }
        else if(filterData.length > 0)
        {
          this.message.error("Same Record Found in Database", "");
          this.isSpinning = false;
        
        }
        else{
          this.api.createCoupon(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Coupon information added successfully...", "");
  
              if (!addNew) {
                this.drawerClose();
                this.logtext = 'Save & Close - Coupon form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Coupon ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
  
              else {
                this.data = new Coupan();
                this.resetDrawer(couponMasterPage);
                this.logtext = 'Save & New - Coupon form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Coupon ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
  
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add coupon information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - Coupon form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Coupon ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
  
            }
          });
        }
     
      
        
    }
    }
    else {
      if(this.data.NAME == "" || this.data.NAME == undefined)
      this.message.error("Please Enter Name", "");
      else if( this.data.START_DATE == undefined)
      this.message.error("Please Select Start Date", "");
      else if( this.data.EXPIRY_DATE == undefined)
      this.message.error("Please Select Expiry Date", "");
      else if( this.data.MAX_USES_COUNT == undefined || this.data.MAX_USES_COUNT.toString() == "")
      this.message.error("Please Enter Max Uses Count", "");
      else if( this.data.COUPON_CODE == undefined || this.data.COUPON_CODE == "")
      this.message.error("Please Enter Coupon Code", "");
      else if(this.data.COUPON_VALUE == undefined || this.data.COUPON_VALUE.toString() == "")
      this.message.error("Please Enter Coupon Value", "");
      else if(this.data.PERUSER_MAX_COUNT == undefined || this.data.PERUSER_MAX_COUNT.toString() == "")
      this.message.error("Please Enter Per User Max Count", "");
      else if(this.data.COUPON_VALUE_TYPE=="P")
      {
        if(this.data.COUPON_MAX_VALUE==undefined || this.data.COUPON_MAX_VALUE.toString()=="")
        this.message.error("Please Enter Coupan Max Value", "");
      }
      else if(this.data.COUPON_VALUE_TYPE=="A")
      {
        this.data.COUPON_MAX_VALUE=0
      }
      else if(this.data.MIN_CART_AMOUNT == undefined || this.data.MIN_CART_AMOUNT.toString() == "")
      {
        this.message.error("Please Enter Min Cart Amount", "");
      }


    }
  }
  similarData() {

    var filterData = this.dataList.filter(object => {
      return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase()
    });

    if (filterData.length > 0) {
      this.isSpinning = false;
      if (filterData.length > 0)
        this.message.error("Name Is Already Present in Database", "");

      return false
    }
    else {
      return true
    }



  }
  similarData1() {

    var filterData = this.dataList.filter(object => {
      return object['COUPON_CODE'] == this.data.COUPON_CODE && object['ID'] != this.data.ID
    });

    if (filterData.length > 0) {
      this.isSpinning = false;
      if (filterData.length > 0)
        this.message.error("Coupon Code Is Already Present in Database", "");

      return false
    }
    else {
      return true
    }



  }
}