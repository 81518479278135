import { Component, OnInit, ViewChild } from '@angular/core';
import { District } from 'src/app/Models/CollegeOnboarding/district';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { DistrictComponent } from '../district/district.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';

@Component({
  selector: 'app-districts',
  templateUrl: './districts.component.html',
  styleUrls: ['./districts.component.css']
})
export class DistrictsComponent implements OnInit {

  formTitle = "Manage Districts";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];

  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  filterClass: string = "filter-invisible";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  regionsNodes=[]
  filterValue=[]

  @ViewChild(DistrictComponent,{static:false} ) district: DistrictComponent ;
  columns: string[][] = [["REGION_STATE_NAME", "State Name"], ["REGION_NAME", "Region Name"], ["NAME", "Name"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: District = new District();
  pageSize2=10
  
  constructor(public api: ApiService, public api2: ShikuyaaapiService, private cookie: CookieService) { }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api2.logoutForSessionValues()
    }
    else
    {
    this.search();
    this.loadAllRegions()
    this.logtext = "OPENED - Districts form KEYWORD[O - Districts] ";
    this.api2.addLog('A', this.logtext, this.api2.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    }
  }


  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Districts form "+ sort +" "+this.sortKey +" KEYWORD [F - Districts] ";
      this.api2.addLog('A', this.logtext, this.api2.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);

      this.logtext = "Filter Applied - Districts form "+ likeQuery +" KEYWORD [F - Districts] ";
    this.api2.addLog('A', this.logtext, this.api2.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    }

    

      this.applyFilter()
      var filter = ""
      if (likeQuery)
        filter = this.filterQuery + likeQuery
      else
        filter = this.filterQuery

    this.api2.getAllDistricts(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
     console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
    
    this.api2.getAllDistricts(0, 0, 'ID', 'desc', '').subscribe(data => {
       this.dataList1 = data['data'];
     }, err => {
       console.log(err);
     });
  }

  loadAllRegions()
  {
    this.api2.getAllRegion().subscribe(localName => {
      console.log(localName)
      this.regionsNodes = localName['data'];
    }, err => {
      console.log(err);
    });
  }
  applyFilter() {
    this.filterQuery = ""

      let stateFilter="";
      let regionFilter="";
      if(this.filterValue.length>0)
      {
      this.filterValue.forEach(element => {
        element="" + element;
        if(element.startsWith("s"))
        {
          stateFilter+= element.replace("s","") + ",";
        }
        else
        {
          regionFilter+= element + ",";
        }
      });
      
      stateFilter=(stateFilter.length>0)?"REGION_STATE_ID IN (" +  stateFilter.substring(0,stateFilter.length-1) + ") OR ":"";
      regionFilter=(regionFilter.length>0)?"REGION_ID IN (" +  regionFilter.substring(0,regionFilter.length-1) + ") OR ":"";

      this.filterQuery= " AND (" + stateFilter + regionFilter ;

      if(this.filterQuery.length>6)
        this.filterQuery=  this.filterQuery.substring(0,this.filterQuery.length-3) + ")";
      else
        this.filterQuery ="";
      console.log(this.filterQuery);
    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
      }
      else{
        this.isFilterApplied = "default";
      }
  }

  clearFilter()
  {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.filterValue=[]
    this.search()
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New District";
    this.drawerData = new District();
    this.drawerVisible = true;
    this.setValues()
    this.logtext = "ADD - District form KEYWORD [A - District] ";
    this.api2.addLog('A', this.logtext, this.api2.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  setValues()
  {
    this.drawerData.IS_ACTIVE=true
  }

  edit(data: District): void {
    this.drawerTitle = "Update District";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.logtext = "EDIT - District form KEYWORD [E - District] ";
    this.api2.addLog('A', this.logtext, this.api2.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
