import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { ExportDirective } from './directives/export.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, AsyncPipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { UserComponent } from './pages/CommonModule/Users/user/user.component';
import { UsersComponent } from './pages/CommonModule/Users/users/users.component';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { RolesComponent } from './pages/CommonModule/Roles/roles/roles.component';
import { RoleComponent } from './pages/CommonModule/Roles/role/role.component';
import { FormsComponent } from './pages/CommonModule/Forms/forms/forms.component'
import { FormComponent } from './pages/CommonModule/Forms/form/form.component'
import { RoledetailsComponent } from './pages/CommonModule/Roles/roledetails/roledetails.component';
import { BundleDetailedSummaryComponent } from './pages/Assessment/Reports/AssessmentReports/BundleDetailedSummary/bundle-detailed-summary/bundle-detailed-summary.component';
// import { PartComponent } from './pages/MasterSyllabus/Parts/part/part.component';
// import { PartsComponent } from './pages/MasterSyllabus/Parts/parts/parts.component';
// import { SectionsComponent } from './pages/MasterSyllabus/Sections/sections/sections.component';
// import { SectionComponent } from './pages/MasterSyllabus/Sections/section/section.component';
// import { ChaptersComponent } from './pages/MasterSyllabus/Chapters/chapters/chapters.component';
// import { ChapterComponent } from './pages/MasterSyllabus/Chapters/chapter/chapter.component';
// import { QuestiontypesComponent } from './pages/MasterSyllabus/QuestionTypes/questiontypes/questiontypes.component';
// import { QuestiontypeComponent } from './pages/MasterSyllabus/QuestionTypes/questiontype/questiontype.component';
// import { LevelsComponent } from './pages/MasterSyllabus/Levels/levels/levels.component';
// import { LevelComponent } from './pages/MasterSyllabus/Levels/level/level.component';
// import { StatesComponent } from './pages/CollegeOnboarding/States/states/states.component';
// import { StateComponent } from './pages/CollegeOnboarding/States/state/state.component';
// import { RegionsComponent } from './pages/CollegeOnboarding/Regions/regions/regions.component';
// import { RegionComponent } from './pages/CollegeOnboarding/Regions/region/region.component';
// import { DistrictsComponent } from './pages/CollegeOnboarding/Districts/districts/districts.component';
// import { DistrictComponent } from './pages/CollegeOnboarding/Districts/district/district.component';
// import { CollegetypesComponent } from './pages/CollegeOnboarding/CollegeTypes/collegetypes/collegetypes.component';
// import { CollegetypeComponent } from './pages/CollegeOnboarding/CollegeTypes/collegetype/collegetype.component';
// import { DesignationComponent } from './pages/CollegeOnboarding/Designations/designation/designation.component';
// import { DesignationsComponent } from './pages/CollegeOnboarding/Designations/designations/designations.component';
// import { DocumenttypeComponent } from './pages/CollegeOnboarding/DocumentType/documenttype/documenttype.component';
// import { DocumenttypesComponent } from './pages/CollegeOnboarding/DocumentType/documenttypes/documenttypes.component';
// import { BranchComponent } from './pages/BatchMgmt/Branches/branch/branch.component';
// import { BranchesComponent } from './pages/BatchMgmt/Branches/branches/branches.component';
// import { PassingyearsComponent } from './pages/BatchMgmt/PassingYears/passingyears/passingyears.component';
// import { PassingyearComponent } from './pages/BatchMgmt/PassingYears/passingyear/passingyear.component';
// import { DivisionsComponent } from './pages/BatchMgmt/Divisions/divisions/divisions.component';
// import { DivisionComponent } from './pages/BatchMgmt/Divisions/division/division.component';
// import { CoursetypesComponent } from './pages/BatchMgmt/CourseTypes/coursetypes/coursetypes.component';
// import { CoursetypeComponent } from './pages/BatchMgmt/CourseTypes/coursetype/coursetype.component';
// import { VehicalComponent } from './pages/ResourcePlanning/Vehicals/vehical/vehical.component';
// import { VehicalsComponent } from './pages/ResourcePlanning/Vehicals/vehicals/vehicals.component';
// import { QuestionparagraphComponent } from './pages/MasterSyllabus/QuestionParagraph/questionparagraph/questionparagraph.component';
// import { QuestionparagraphsComponent } from './pages/MasterSyllabus/QuestionParagraph/questionparagraphs/questionparagraphs.component';
// import { CoursesComponent } from './pages/BatchMgmt/Courses/courses/courses.component';
// import { CourseComponent } from './pages/BatchMgmt/Courses/course/course.component';
// import { TrainingcoursesComponent } from './pages/BatchMgmt/TrainingCourses/trainingcourses/trainingcourses.component';
// import { TrainingcourseComponent } from './pages/BatchMgmt/TrainingCourses/trainingcourse/trainingcourse.component';
// import { HolidaycalenderComponent } from './pages/SessionModule/Holidays/holidaycalender/holidaycalender.component';
// import { SubchaptersComponent } from './pages/MasterSyllabus/SubChapter/subchapters/subchapters.component';
// import { SubchapterComponent } from './pages/MasterSyllabus/SubChapter/subchapter/subchapter.component';
// import { MapquestionsComponent } from './pages/MasterSyllabus/QuestionParagraph/mapquestions/mapquestions.component';
import { Users1Component } from './pages/CommonModule/Try/users1.component';
// import { CollegeComponent } from './pages/CollegeOnboarding/College/college/college.component';
// import { CollegesComponent } from './pages/CollegeOnboarding/College/colleges/colleges.component';
// import { ContactdetailsComponent } from './pages/CollegeOnboarding/College/contactdetails/contactdetails/contactdetails.component';
// import { ContactdetailComponent } from './pages/CollegeOnboarding/College/contactdetails/contactdetail/contactdetail.component';
// import { DocumentsComponent } from './pages/CollegeOnboarding/College/Documents/documents/documents.component'
// import { DocumentComponent } from './pages/CollegeOnboarding/College/Documents/document/document.component';
// import { OnboardComponent } from './pages/CollegeOnboarding/College/onboard/onboard.component';
// import { CollegetypemappingComponent } from './pages/CollegeOnboarding/College/collegetypemapping/collegetypemapping.component';
// import { CollegebranchmappingComponent } from './pages/CollegeOnboarding/College/collegebranchmapping/collegebranchmapping.component';
// import { CollegetrainingcoursemappingComponent } from './pages/CollegeOnboarding/College/collegetrainingcoursemapping/collegetrainingcoursemapping.component';
// import { CollegeholidayComponent } from './pages/CollegeOnboarding/College/collegeholiday/collegeholiday.component';
// import { ProfeatureComponent } from './pages/StudentOnboarding/ProFeatures/profeature/profeature.component';
// import { BatchcreationComponent } from './pages/BatchMgmt/Batches/BatchCreation/batchcreation.component';
// import { BatchComponent } from './pages/BatchMgmt/Batches/batch/batch.component';
// import { BatcheditComponent } from './pages/BatchMgmt/Batches/batchedit/batchedit.component';
// import { CreatesubbatchesComponent } from './pages/BatchMgmt/Batches/createsubbatches/createsubbatches.component';
// import { MastersessionsComponent } from './pages/MasterSyllabus/MasterSession/mastersessions/mastersessions.component';
// import { MastersessionComponent } from './pages/MasterSyllabus/MasterSession/mastersession/mastersession.component';
// import { SyllabusbatchgroupComponent } from './pages/BatchwiseSyllabus/SyllabusBatchGroup/syllabusbatchgroup/syllabusbatchgroup.component';
// import { SyllabusbatchgroupsComponent } from './pages/BatchwiseSyllabus/SyllabusBatchGroup/syllabusbatchgroups/syllabusbatchgroups.component';
// import { SyllabusbatchgroupmappingComponent } from './pages/BatchwiseSyllabus/SyllabusBatchGroup/syllabusbatchgroupmapping/syllabusbatchgroupmapping.component';
// import { BatchsyllabusComponent } from './pages/BatchwiseSyllabus/SyllabusMapping/batchsyllabus/batchsyllabus.component';
// import { AddbatchsyllabusComponent } from './pages/BatchwiseSyllabus/SyllabusMapping/addbatchsyllabus/addbatchsyllabus.component';
// import { SchedulingbatchgroupComponent } from './pages/BatchScheduling/SchedulingBatchGroup/schedulingbatchgroup/schedulingbatchgroup.component';
// import { SchedulingbatchgroupsComponent } from './pages/BatchScheduling/SchedulingBatchGroup/schedulingbatchgroups/schedulingbatchgroups.component';
// import { SchedulebatchesComponent } from './pages/BatchScheduling/BatchSchedule/schedulebatches/schedulebatches.component';
// import { SchedulebatchComponent } from './pages/BatchScheduling/BatchSchedule/schedulebatch/schedulebatch.component';
// import { SchedulingbatchmappingComponent } from './pages/BatchScheduling/SchedulingBatchGroup/schedulingbatchmapping/schedulingbatchmapping.component';
// import { ResourcebatchgroupComponent } from './pages/ShikuyaaCourse/ResourceBatchGroup/resourcebatchgroup/resourcebatchgroup.component';
// import { ResourcebatchgroupsComponent } from './pages/ShikuyaaCourse/ResourceBatchGroup/resourcebatchgroups/resourcebatchgroups.component';
// import { ResourcebatchgroupbatchmappingComponent } from './pages/ShikuyaaCourse/ResourceBatchGroup/resourcebatchgroupbatchmapping/resourcebatchgroupbatchmapping.component';
// import { ResourcebatchgrouptrainermappingComponent } from './pages/ShikuyaaCourse/ResourceBatchGroup/resourcebatchgrouptrainermapping/resourcebatchgrouptrainermapping.component';
// import { ResourceallocationComponent } from './pages/ResourcePlanning/ResourceAllocation/resourceallocation/resourceallocation.component';
// import { AssignresourceComponent } from './pages/ResourcePlanning/ResourceAllocation/assignresource/assignresource.component';
// import { VehicleallocationComponent } from './pages/ResourcePlanning/VehicleAllocation/vehicleallocation/vehicleallocation.component';
// import { SessionsComponent } from './pages/SessionModule/Sessions/sessions/sessions.component';
// import { SessionComponent } from './pages/SessionModule/Sessions/session/session.component';
// import { LinkreadComponent } from './pages/CommonModule/linkread/linkread.component';
// import { SelectpageComponent } from './pages/selectpage/selectpage.component';
// import { QuestionComponent } from './pages/MasterSyllabus/QuestionMaster/question/question.component';
// import { QuestionsComponent } from './pages/MasterSyllabus/QuestionMaster/questions/questions.component';
// import { AssignvehicleComponent } from './pages/ResourcePlanning/VehicleAllocation/assignvehicle/assignvehicle.component';
// import { SyllabustrackingbatchwiseComponent } from './pages/SyllabusTrackingReport/syllabustrackingbatchwise/syllabustrackingbatchwise.component';
import { SelectrolepageComponent } from './pages/CommonModule/selectrolepage/selectrolepage.component';
import { AccesspageComponent } from './pages/CommonModule/accesspage/accesspage.component';
// import { SessionattendanceComponent } from './pages/SessionModule/sessionattendance/sessionattendance.component';
// import { QuestionimporterComponent } from './pages/MasterSyllabus/questionimporter/questionimporter.component';
import { AssignmentgroupComponent } from './pages/AssignmentModule/AssignmentGroup/assignmentgroup/assignmentgroup.component';
import { InstructionsComponent } from './pages/AssignmentModule/Instructions/instructions/instructions.component';
import { InstructionComponent } from './pages/AssignmentModule/Instructions/instruction/instruction.component';
import { AssignmentgroupsComponent } from './pages/AssignmentModule/AssignmentGroup/assignmentgroups/assignmentgroups.component';
import { AssignmentsComponent } from './pages/AssignmentModule/AssignmentsCreation/assignments/assignments.component';
import { AssignmentComponent } from './pages/AssignmentModule/AssignmentsCreation/assignment/assignment.component';
import { QuestionsdataComponent } from './pages/AssignmentModule/AssignmentsCreation/questionsdata/questionsdata.component';
import { MappedassignmentsComponent } from './pages/AssignmentModule/MappedAssignments/mappedassignments/mappedassignments.component';
import { MappedassignmentComponent } from './pages/AssignmentModule/MappedAssignments/mappedassignment/mappedassignment.component';
import { MappedquestiondataComponent } from './pages/AssignmentModule/MappedAssignments/mappedquestiondata/mappedquestiondata.component';

import { AssignmentgroupComponent2 } from './pages/ShikuyaaForms/AssignmentModule/AssignmentGroup/assignmentgroup/assignmentgroup2.component';
import { InstructionsComponent2 } from './pages/ShikuyaaForms/AssignmentModule/Instructions/instructions/instructions2.component';
import { InstructionComponent2 } from './pages/ShikuyaaForms/AssignmentModule/Instructions/instruction/instruction2.component';
import { AssignmentgroupsComponent2 } from './pages/ShikuyaaForms/AssignmentModule/AssignmentGroup/assignmentgroups/assignmentgroups2.component';
import { AssignmentsComponent2 } from './pages/ShikuyaaForms/AssignmentModule/AssignmentsCreation/assignments/assignments2.component';
import { AssignmentComponent2 } from './pages/ShikuyaaForms/AssignmentModule/AssignmentsCreation/assignment/assignment2.component';
import { QuestionsdataComponent2 } from './pages/ShikuyaaForms/AssignmentModule/AssignmentsCreation/questionsdata/questionsdata2.component';
import { MappedassignmentsComponents2 } from './pages/ShikuyaaForms/AssignmentModule/MappedAssignments/mappedassignments/mappedassignments2.component';
import { MappedassignmentComponent2 } from './pages/ShikuyaaForms/AssignmentModule/MappedAssignments/mappedassignment/mappedassignment2.component';
import { MappedquestiondataComponent2 } from './pages/ShikuyaaForms/AssignmentModule/MappedAssignments/mappedquestiondata/mappedquestiondata2.component';

// import { PasskeyreportComponent } from './pages/GridReport/passkeyreport/passkeyreport.component';
// import { BatchreportComponent } from './pages/GridReport/batchreport/batchreport.component';
// import { BatchstudentreportComponent } from './pages/GridReport/batchstudentreport/batchstudentreport.component';
// import { PartreportComponent } from './pages/GridReport/partreport/partreport.component';
// import { SectionreportComponent } from './pages/GridReport/sectionreport/sectionreport.component';
// import { ChapterreportComponent } from './pages/GridReport/chapterreport/chapterreport.component';
// import { SubchapterreportComponent } from './pages/GridReport/subchapterreport/subchapterreport.component';
// import { MastersessionreportComponent } from './pages/GridReport/mastersessionreport/mastersessionreport.component';
// import { MastersessiondetailsreportComponent } from './pages/GridReport/mastersessiondetailsreport/mastersessiondetailsreport.component';
// import { BatchsessionreportComponent } from './pages/GridReport/batchsessionreport/batchsessionreport.component';
// import { BatchsessiondetailsreportComponent } from './pages/GridReport/batchsessiondetailsreport/batchsessiondetailsreport.component';
// import { MastersyllabusreportComponent } from './pages/GridReport/mastersyllabusreport/mastersyllabusreport.component';
// import { BatchsyllabusreportComponent } from './pages/GridReport/batchsyllabusreport/batchsyllabusreport.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ResetpasswordComponent } from './pages/CommonModule/resetpassword/resetpassword.component';
import { CurrentstatusComponent } from './pages/AssignmentModule/currentstatus/currentstatus.component';
import { ResultComponent } from './pages/AssignmentModule/result/result.component';

import { CurrentstatusComponent2 } from './pages/ShikuyaaForms/AssignmentModule/currentstatus/currentstatus2.component';
import { ResultComponent2 } from './pages/ShikuyaaForms/AssignmentModule/result/result2.component';

// import { NotificationComponent } from './pages/Notifications/notification/notification.component';
// import { NotificationsComponent } from './pages/Notifications/notifications/notifications.component';
import { ShikuyaachaptersComponent } from './pages/ShikuyaaForms/Chapters/shikuyaachapters/shikuyaachapters.component';
import { ShikuyaachapterComponent } from './pages/ShikuyaaForms/Chapters/shikuyaachapter/shikuyaachapter.component';
import { VideosComponent } from './pages/ShikuyaaForms/Videos/PredefinedVideos/videos/videos.component';
import { VideoComponent } from './pages/ShikuyaaForms/Videos/PredefinedVideos/video/video.component';
// import { ShikuyaadocumentComponent } from './pages/ShikuyaaForms/Documents/shikuyaadocument/shikuyaadocument.component';
// import { ShikuyaadocumentsComponent } from './pages/ShikuyaaForms/Documents/shikuyaadocuments/shikuyaadocuments.component';
import { ShikuyaaparagraphsComponent } from './pages/ShikuyaaForms/Paragraphs/shikuyaaparagraphs/shikuyaaparagraphs.component';
import { ShikuyaaparagraphComponent } from './pages/ShikuyaaForms/Paragraphs/shikuyaaparagraph/shikuyaaparagraph.component';
import { VideoquestiontypeComponent } from './pages/ShikuyaaForms/Videos/VideoQuestionTypes/videoquestiontype/videoquestiontype.component';
import { VideoquestiontypesComponent } from './pages/ShikuyaaForms/Videos/VideoQuestionTypes/videoquestiontypes/videoquestiontypes.component';
// import { PredefinedmocksComponent } from './pages/ShikuyaaForms/Mocks/predefinedmocks/predefinedmocks.component';
// import { PredefinedmockComponent } from './pages/ShikuyaaForms/Mocks/predefinedmock/predefinedmock.component';
// import { LivevideoComponent } from './pages/ShikuyaaForms/LiveVideo/livevideo/livevideo.component';
// import { LivevideosComponent } from './pages/ShikuyaaForms/LiveVideo/livevideos/livevideos.component';
import { CouponComponent } from './pages/ShikuyaaForms/Coupon/Couponss/coupon/coupon.component';
import { CouponsComponent } from './pages/ShikuyaaForms/Coupon/Couponss/coupons/coupons.component';
import { CouponfacilityComponent } from './pages/ShikuyaaForms/Coupon/couponfacilitys/couponfacility/couponfacility.component';
import { CouponfacilitiesComponent } from './pages/ShikuyaaForms/Coupon/couponfacilitys/couponfacilities/couponfacilities.component';
import { VideoquestionComponent } from './pages/ShikuyaaForms/Videos/VideoQue/videoquestion/videoquestion.component';
import { VideoquestionsComponent } from './pages/ShikuyaaForms/Videos/VideoQue/videoquestions/videoquestions.component';
import { ShikuyaaquestionComponent } from './pages/ShikuyaaForms/ShikuyaaQuestions/shikuyaaquestion/shikuyaaquestion.component';
import { ShikuyaaquestionsComponent } from './pages/ShikuyaaForms/ShikuyaaQuestions/shikuyaaquestions/shikuyaaquestions.component';
import { CouponfacilitymappingComponent } from './pages/ShikuyaaForms/Coupon/couponfacilitymapping/couponfacilitymapping.component';
// import { CoursestudentlistComponent } from './pages/ShikuyaaForms/Reports/coursestudentlist/coursestudentlist.component';
// import { VideostatslistComponent } from './pages/ShikuyaaForms/Reports/videostatslist/videostatslist.component';
// import { ViewcommentsComponent } from './pages/ShikuyaaForms/LiveVideo/viewcomments/viewcomments.component';
// import { ChaptersummeryComponent } from './pages/ShikuyaaForms/Reports/chaptersummery/chaptersummery.component';
// import { CoursesummeryComponent } from './pages/ShikuyaaForms/Reports/coursesummery/coursesummery.component';
// import { PoleComponent } from './pages/ShikuyaaForms/LiveVideo/pole/pole.component';
// import { PolesComponent } from './pages/ShikuyaaForms/LiveVideo/poles/poles.component';
// import { LivepolemappingComponent } from './pages/ShikuyaaForms/LiveVideo/livepolemapping/livepolemapping.component';
// import { LivevideosummeryComponent } from './pages/ShikuyaaForms/Reports/livevideosummery/livevideosummery.component';
// import { LivevideoreportComponent } from './pages/ShikuyaaForms/Reports/livevideoreport/livevideoreport.component';
// import { UserresponseComponent } from './pages/ShikuyaaForms/LiveVideo/userresponse/userresponse.component';
// import { NotificationmastersettingComponent } from './pages/ShikuyaaForms/LiveVideo/NotificationSettings/notificationmastersetting/notificationmastersetting.component';
// import { NotificationmastersettingsComponent } from './pages/ShikuyaaForms/LiveVideo/NotificationSettings/notificationmastersettings/notificationmastersettings.component';
// import { TrainersComponent } from './pages/ResourcePlanning/Trainer/trainers/trainers.component';
// import { TrainerComponent } from './pages/ResourcePlanning/Trainer/trainer/trainer.component';
// import { SessionreportComponent } from './pages/GridReport/sessionreport/sessionreport.component';
// import { EditbatchsyllabusComponent } from './pages/BatchwiseSyllabus/SyllabusMapping/editbatchsyllabus/editbatchsyllabus.component';
// import { MandayscalculationComponent } from './pages/RequiredReports/mandayscalculation/mandayscalculation.component';
// import { RenumerationcalculationComponent } from './pages/RequiredReports/renumerationcalculation/renumerationcalculation.component';
// import { RatingsComponent } from './pages/RequiredReports/ratings/ratings.component';
// import { SuggeestioncountComponent } from './pages/RequiredReports/suggeestioncount/suggeestioncount.component';
// import { SyllabuscompletiontrackComponent } from './pages/RequiredReports/syllabuscompletiontrack/syllabuscompletiontrack.component';
// import { FffquestionComponent } from './pages/ShikuyaaForms/LiveVideo/FFF/fffquestion/fffquestion.component';
// import { FffquestionsComponent } from './pages/ShikuyaaForms/LiveVideo/FFF/fffquestions/fffquestions.component';
// import { LivefffmappingComponent } from './pages/ShikuyaaForms/LiveVideo/FFF/livefffmapping/livefffmapping.component';
// import { LivefffuserresponsesComponent } from './pages/ShikuyaaForms/LiveVideo/FFF/livefffuserresponses/livefffuserresponses.component';
// import { LivescreenComponent } from './pages/ShikuyaaForms/LiveVideo/livescreen/livescreen.component';
// import { EnrollmentsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/enrollments/enrollments.component';
// import { TypemasterComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Type1/typemaster/typemaster.component';
// import { TypemastersComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Type1/typemasters/typemasters.component';
// import { FootercontentComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/FooterContent1/footercontent/footercontent.component';
// import { FootercontentsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/FooterContent1/footercontents/footercontents.component';
// import { FooterlinkComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/FooterPageLink/footerlink/footerlink.component';
// import { FooterlinksComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/FooterPageLink/footerlinks/footerlinks.component';

// import { BlogwriterComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/blogwriter/blogwriter/blogwriter.component';
// import { BlogwritersComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/blogwriter/blogwriters/blogwriters.component';


import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { CoupontypeComponent } from './pages/ShikuyaaForms/Coupon/coupontype/coupontype.component';
import { CoupontypesComponent } from './pages/ShikuyaaForms/Coupon/coupontypes/coupontypes.component';
// import { StudentregistrationComponent } from './pages/ShikuyaaForms/Reports/studentregistration/studentregistration.component';
// import { StudentregistrationsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentregistrations/studentregistrations.component';
// import { StudentcoursejoiningComponent } from './pages/ShikuyaaForms/Reports/studentcoursejoining/studentcoursejoining.component';
// import { StudentcoursepaymentComponent } from './pages/ShikuyaaForms/Reports/studentcoursepayment/studentcoursepayment.component';
// import { ShikuyaavideostatComponent } from './pages/ShikuyaaForms/Reports/shikuyaavideostat/shikuyaavideostat.component';
// import { ShikuyaavideowatchtimeComponent } from './pages/ShikuyaaForms/Reports/shikuyaavideowatchtime/shikuyaavideowatchtime.component';
import { AdmindashboardComponent } from './pages/CommonModule/admindashboard/admindashboard.component';
// import { StudentvideoquestionsummaryComponent } from './pages/ShikuyaaForms/Reports/studentvideoquestionsummary/studentvideoquestionsummary.component';
// import { StudentappusagereportComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentappusagereport/studentappusagereport.component';
// import { StudentcoursestatsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcoursestats/studentcoursestats.component';
// import { StudentcoursesummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcoursesummary/studentcoursesummary.component';
// import { StudentcoursepracticesummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcoursepracticesummary/studentcoursepracticesummary.component';
// import { StudentcoursevideosummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcoursevideosummary/studentcoursevideosummary.component';
// import { StudentcourselivevideosummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcourselivevideosummary/studentcourselivevideosummary.component';
// import { StudentcourseassesmentsummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcourseassesmentsummary/studentcourseassesmentsummary.component';
// import { StudentpracticedetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentpracticedetails/studentpracticedetails.component';
// import { StudentvideodetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentvideodetails/studentvideodetails.component';
// import { StudentlivevideodetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentlivevideodetails/studentlivevideodetails.component';
// import { StudentassesmentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentassesmentdetails/studentassesmentdetails.component';
// import { StudentchapterpracticequestiondetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentchapterpracticequestiondetails/studentchapterpracticequestiondetails.component';
// import { StudentvideoquestiondetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentvideoquestiondetails/studentvideoquestiondetails.component';
// import { StudentlivevideocommentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentlivevideocommentdetails/studentlivevideocommentdetails.component';
// import { StudentlivevideopoledetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentlivevideopoledetails/studentlivevideopoledetails.component';
// import { StudentlivevidefffdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentlivevidefffdetails/studentlivevidefffdetails.component';
// import { StudentassessmentquestiondetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentassessmentquestiondetails/studentassessmentquestiondetails.component';
// import { StudentemailsummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentemailsummary/studentemailsummary.component';
// import { StudentsmssummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentsmssummary/studentsmssummary.component';
// import { StudentpaymentComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentpayment/studentpayment.component';
// import { StudentemaildetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentemaildetails/studentemaildetails.component';
// import { StudentsmsdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentsmsdetails/studentsmsdetails.component';
// import { CoursesummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursesummary/coursesummary.component';
// import { CoursepaymentreportComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursepaymentreport/coursepaymentreport.component';
// import { CoursestructuresummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursestructuresummary/coursestructuresummary.component';
// import { CoursechaptersummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursechaptersummary/coursechaptersummary.component';
// import { CoursepracticequestiondetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursepracticequestiondetails/coursepracticequestiondetails.component';
// import { CoursevideosummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursevideosummary/coursevideosummary.component';
// import { CourselivevideosummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/courselivevideosummary/courselivevideosummary.component';
// import { CourselivevideostudentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courselivevideostudentdetails/courselivevideostudentdetails.component';
// import { CourselivevideopoledetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courselivevideopoledetails/courselivevideopoledetails.component';
// import { CourselivevideopolestudentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courselivevideopolestudentdetails/courselivevideopolestudentdetails.component';
// import { CourselivevideofffdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courselivevideofffdetails/courselivevideofffdetails.component';
// import { CourselivevideofffstudentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courselivevideofffstudentdetails/courselivevideofffstudentdetails.component';
// import { CourselivevideocommentsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courselivevideocomments/courselivevideocomments.component';
// import { CourseassessmentsummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/courseassessmentsummary/courseassessmentsummary.component';
// import { CourseassessmentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courseassessmentdetails/courseassessmentdetails.component';
// import { CourseassessmentstudentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/courseassessmentstudentdetails/courseassessmentstudentdetails.component';
// import { CoursecoupondetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursecoupondetails/coursecoupondetails.component';
// import { CoursecouponstudentdetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursecouponstudentdetails/coursecouponstudentdetails.component';
// import { CoursewallettransactionsummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursewallettransactionsummary/coursewallettransactionsummary.component';
// import { CoursewallettransactiondetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursewallettransactiondetails/coursewallettransactiondetails.component';
// import { SmssummaryComponent } from './pages/ShikuyaaForms/SystemReports/smssummary/smssummary.component';
// import { EmailsummaryComponent } from './pages/ShikuyaaForms/SystemReports/emailsummary/emailsummary.component';
// import { EmaildetailsComponent } from './pages/ShikuyaaForms/SystemReports/emaildetails/emaildetails.component';
// import { SmsdetailsComponent } from './pages/ShikuyaaForms/SystemReports/smsdetails/smsdetails.component';
// import { PaymentsummaryComponent } from './pages/ShikuyaaForms/SystemReports/paymentsummary/paymentsummary.component';
// import { NotificationreportComponent } from './pages/ShikuyaaForms/SystemReports/notificationreport/notificationreport.component';
// import { WallettransactiondetailComponent } from './pages/ShikuyaaForms/SystemReports/wallettransactiondetail/wallettransactiondetail.component';
// import { StudentassesmentdesignedComponent } from './pages/ShikuyaaForms/PrintableReports/studentassesmentdesigned/studentassesmentdesigned.component';
// import { AppregistrationpendingComponent } from './pages/ShikuyaaForms/StudentWiseReports/appregistrationpending/appregistrationpending.component';
// import { SliderinfoComponent } from './pages/ShikuyaaForms/SliderInfo/sliderinfo/sliderinfo.component';
// import { SliderComponent } from './pages/ShikuyaaForms/SliderInfo/slider/slider.component';
// import { FreetrialstudentdetailComponent } from './pages/ShikuyaaForms/SystemReports/freetrialstudentdetail/freetrialstudentdetail.component';
// import { StudentpaymentdetailComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentpaymentdetail/studentpaymentdetail.component';
// import { StudentwalletsummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentwalletsummary/studentwalletsummary.component';
// import { StudentcouponsummaryComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcouponsummary/studentcouponsummary.component';
// import { StudentcoupondetailedComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentcoupondetailed/studentcoupondetailed.component';
// import { StudentwalletdetailedComponent } from './pages/ShikuyaaForms/StudentWiseReports/studentwalletdetailed/studentwalletdetailed.component';
// import { EmailtemplatesComponent } from './pages/ShikuyaaForms/EmailTemplate/emailtemplates/emailtemplates.component';
// import { EmailtemplateComponent } from './pages/ShikuyaaForms/EmailTemplate/emailtemplate/emailtemplate.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { CoursevideoquestiondetailsComponent } from './pages/ShikuyaaForms/StudentWiseReports/coursevideoquestiondetails/coursevideoquestiondetails.component';
registerLocaleData(en);
// import { MathjaxComponent } from './pages/mathjax/mathjax.component';

// import { BranchwiseliveassessmentsummaryComponent } from './pages/StudentResultAnalysis/branchwiseliveassessmentsummary/branchwiseliveassessmentsummary.component';
// import { CollegewisebranchwiseliveassessmentsummaryComponent } from './pages/StudentResultAnalysis/collegewisebranchwiseliveassessmentsummary/collegewisebranchwiseliveassessmentsummary.component';
// import { CollegewiseliveassessmentsummaryComponent } from './pages/StudentResultAnalysis/collegewiseliveassessmentsummary/collegewiseliveassessmentsummary.component';
// import { CompletedliveassessmentdetailedreportComponent } from './pages/StudentResultAnalysis/completedliveassessmentdetailedreport/completedliveassessmentdetailedreport.component';
// import { Courseassessmentdetails1Component } from './pages/StudentResultAnalysis/courseassessmentdetails1/courseassessmentdetails1.component';
// import { LiveassessmentsummaryComponent } from './pages/StudentResultAnalysis/liveassessmentsummary/liveassessmentsummary.component';
// import { NotstartedliveassessmentdetailedreportComponent } from './pages/StudentResultAnalysis/notstartedliveassessmentdetailedreport/notstartedliveassessmentdetailedreport.component';
// import { ResumedliveassessmentdetailedreportComponent } from './pages/StudentResultAnalysis/resumedliveassessmentdetailedreport/resumedliveassessmentdetailedreport.component';
// import { StartedliveassessmentdetailedreportComponent } from './pages/StudentResultAnalysis/startedliveassessmentdetailedreport/startedliveassessmentdetailedreport.component';
// import { TerminatedliveassessmentdetailedreportComponent } from './pages/StudentResultAnalysis/terminatedliveassessmentdetailedreport/terminatedliveassessmentdetailedreport.component';
// import { VoilatedtedliveassessmentdetailedreportComponent } from './pages/StudentResultAnalysis/voilatedtedliveassessmentdetailedreport/voilatedtedliveassessmentdetailedreport.component';
// import { Associates1Component } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Associates/associates1/associates1.component';
// import { AssociateComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Associates/associate/associate.component';
// import { ChapterbaseComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/ChapterBase/chapterbase/chapterbase.component';
// import { ChapterbasesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/ChapterBase/chapterbases/chapterbases.component';
// import { GameComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Games/game/game.component';
// import { GamesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Games/games/games.component';
// import { JobapplicationComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/JobApplication/jobapplication/jobapplication.component';
// import { JobapplicationsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/JobApplication/jobapplications/jobapplications.component';
// import { MenuComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Menus/menu/menu.component';
// import { MenusComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Menus/menus/menus.component';
// import { ResumeComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Resume/resume/resume.component';
// import { ResumesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Resume/resumes/resumes.component';
// import { ReviewComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Reviews/review/review.component';
// import { ReviewsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Reviews/reviews/reviews.component';
// import { SeoComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Seo/seo/seo.component';
// import { SeosComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Seo/seos/seos.component';
// import { SuccessstoriesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SuccessStories/successstories/successstories.component';
// import { SuccessstoryComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SuccessStories/successstory/successstory.component';
// import { TeamsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Teams/teams/teams.component';
// import { TeamComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Teams/team/team.component';
// import { WiegetComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Wiegets/wieget/wieget.component';
// import { WiegetsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Wiegets/wiegets/wiegets.component';
// import { BlogcategoryComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/BlogCategory/blogcategory/blogcategory.component';
// import { BlogcategoriesComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/BlogCategory/blogcategories/blogcategories.component';
// import { BlogsComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/Blogs/blogs/blogs.component';
// import { BlogComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/Blogs/blog/blog.component';
// import { CareerComponent } from './pages/ShikuyaawebsiteForms/CareerDetails/Career/career/career.component';
// import { CareersComponent } from './pages/ShikuyaawebsiteForms/CareerDetails/Career/careers/careers.component';
// import { ContactsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Contact/contacts/contacts.component';
// import { DesignationwebComponent } from './pages/ShikuyaawebsiteForms/CareerDetails/Designationweb/designationweb/designationweb.component';
// import { DesignationwebsComponent } from './pages/ShikuyaawebsiteForms/CareerDetails/Designationweb/designationwebs/designationwebs.component';
// import { StudentgameprogressComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Games/studentgameprogress/studentgameprogress.component';
// import { CollegeassociteComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/collegeassociates1/collegeassocite/collegeassocite.component';
// import { CollegeassociatesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/collegeassociates1/collegeassociates/collegeassociates.component';
// import { BlogcommentComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/blogcomment/blogcomment.component';
// import { GridblogsComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/AddBlogs/gridblogs/gridblogs.component';
// import { AddblogsComponent } from './pages/ShikuyaawebsiteForms/BlogsDetails/AddBlogs/addblogs/addblogs.component';
// import { PageComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/WebPages/page/page.component';
// import { PagesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/WebPages/pages/pages.component';
// import { BlocksComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Blockss/blocks/blocks.component';
// import { BlockComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Blockss/block/block.component';
// import { AssignblocksComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/WebPages/assignblocks/assignblocks.component';
// import { ApppromotionsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/AppPromotion1/apppromotions/apppromotions.component';
// import { ApppromotionComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/AppPromotion1/apppromotion/apppromotion.component';
// import { AboutshikuyaaComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/AboutShikuyaa/aboutshikuyaa/aboutshikuyaa.component';
// import { AboutshikuyaasComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/AboutShikuyaa/aboutshikuyaas/aboutshikuyaas.component';
// import { BannerComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Banner/banner/banner.component';
// import { BannersComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Banner/banners/banners.component';
// import { FeatureComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Features1/feature/feature.component';
// import { FeaturesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Features1/features/features.component';
// import { TypewidgetmappingComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Type1/typewidgetmapping/typewidgetmapping.component';
// import { PageformsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/PageForm1/pageforms/pageforms.component';
// import { PageformComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/PageForm1/pageform/pageform.component';
// import { SitemapsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SiteMapM/sitemaps/sitemaps.component';
// import { SitemapComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SiteMapM/sitemap/sitemap.component';
// import { SitemapheadingComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SiteMapHeading/sitemapheading/sitemapheading.component';
// import { SitemapheadingsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SiteMapHeading/sitemapheadings/sitemapheadings.component';

// import { CareerPageComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/CareerPageM/careerpage/careerpage.component';
// import { CareerpagesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/CareerPageM/careerpages/careerpages.component';
// import { ContactpageComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/ContactPageM/contactpage/contactpage.component';
// import { ContactpagesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/ContactPageM/contactpages/contactpages.component';
// import { PolicydetailComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Policy/policydetail/policydetail.component';
// import { PolicydetailsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Policy/policydetails/policydetails.component';
// import { AboutpageComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/AboutPage/aboutpage/aboutpage.component';
// import { AboutpagesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/AboutPage/aboutpages/aboutpages.component';
// import { TrainermasterComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/TrainerMaster/trainermaster/trainermaster.component';
// import { TrainermastersComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/TrainerMaster/trainermasters/trainermasters.component';
// import { SociallinkComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SodialLinkss/sociallink/sociallink.component';
// import { SociallinksComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/SodialLinkss/sociallinks/sociallinks.component';
// import { CompaniesComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Companiesss/companies/companies.component';
// import { CompanyComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Companiesss/company/company.component';

// import { NavbarcontentComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Navbar/navbarcontent/navbarcontent.component';
// import { NavbarcontentsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/Navbar/navbarcontents/navbarcontents.component';
// import { CourseuserreviewComponent } from './pages/ShikuyaawebsiteForms/courseuserreview/courseuserreview.component';
// import { CourseuserreviewsComponent } from './pages/ShikuyaawebsiteForms/courseuserreviews/courseuserreviews.component';
// import { ButtonlinksComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/ButtonLink/buttonlinks/buttonlinks.component';
// import { ButtonlinkComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/ButtonLink/buttonlink/buttonlink.component';

// import { InstructorComponent } from './pages/ShikuyaawebsiteForms/instructor/instructor.component';
// import { InstructorsComponent } from './pages/ShikuyaawebsiteForms/instructors/instructors.component';

// import { InstructorlinkComponent } from './pages/ShikuyaawebsiteForms/instructorlink/instructorlink.component';
// import { InstructorlinksComponent } from './pages/ShikuyaawebsiteForms/instructorlinks/instructorlinks.component';
// import { WebinarvideoComponent } from './pages/ShikuyaawebsiteForms/Webinar/webinarvideo/webinarvideo.component';
// import { WebinarvideoesComponent } from './pages/ShikuyaawebsiteForms/Webinar/webinarvideoes/webinarvideoes.component';
// import { InstructureComponent } from './pages/ShikuyaawebsiteForms/instructure/instructure.component';
// import { InstructormapComponent } from './pages/ShikuyaawebsiteForms/InstructorMapping/instructormap/instructormap.component';
// import { CourseitemmappingComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/CourseItemMapp/courseitemmapping/courseitemmapping.component';
// import { CourseitemmappingsComponent } from './pages/ShikuyaawebsiteForms/WebsiteMasters/CourseItemMapp/courseitemmappings/courseitemmappings.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import{ApiService} from 'src/app/Service/api.service';
import{ExportService} from 'src/app/Service/export.service';
// import { CourseCategoryMasterComponent } from './pages/CourseCategory/course-category-master/course-category-master.component';
// import { CourseCategoryGridMasterComponent } from './pages/CourseCategory/course-category-grid-master/course-category-grid-master.component';
import{CompaniesMasterComponent} from 'src/app/pages/ShikuyaaForms/ShikuyaaQuestions/companies-master/companies-master.component'
import { CompanyMastersComponent } from 'src/app/pages/ShikuyaaForms/ShikuyaaQuestions/company-masters/company-masters.component'
import { DatePipe } from '@angular/common';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ChangeQuestionPipe } from './change-question.pipe';

// import { CourseAssessmentPartDetailReportComponent } from './course-assessment-part-detail-report/course-assessment-part-detail-report.component';
import { StudentAppDetailsReportComponent } from './ShikuyaaForms/StudentWiseReports/student-app-details-report/student-app-details-report.component';
// import { StudentAppDetailsReportsComponent } from './student-app-details-reports/student-app-details-reports.component';
// import { StudentRegCompletedReportComponent } from './student-reg-completed-report/student-reg-completed-report.component';
// import { StudentRegPendingReportComponent } from './student-reg-pending-report/student-reg-pending-report.component';
// import { VideoImporterComponent } from './video-importer/video-importer.component';
// import { LiveClassesComponent } from './live-classes/live-classes.component';
// import { LiveClasseComponent } from './live-classe/live-classe.component';
import { SecondsToHoursPipe } from './seconds-to-hours.pipe';
// import { VideoSUmmaryReportComponent } from './video-summary-report/video-summary-report.component';
// import { StudentVideoSummaryReportComponent } from './student-video-summary-report/student-video-summary-report.component';
// import { StudentPracticeSummaryReportComponent } from './student-practice-summary-report/student-practice-summary-report.component';
// import { PracticeSummaryReportComponent } from './practice-summary-report/practice-summary-report.component';
import { ChapetrImporterComponent } from './chapetr-importer/chapetr-importer.component';
// import { AddbundlemasterComponent } from './pages/Assessment/addbundlemaster/addbundlemaster.component';
// import { BundlemasterlistComponent } from './pages/Assessment/bundlemasterlist/bundlemasterlist.component';
import { AddbundlemappingComponent } from './pages/Assessment/BundleMapping/addbundlemapping/addbundlemapping.component';
import { BundlemappinglistComponent } from './pages/Assessment/BundleMapping/bundlemappinglist/bundlemappinglist.component';
import { AddbundlcollegeemappingComponent } from './pages/Assessment/BundleCollegeMapping/addbundlcollegeemapping/addbundlcollegeemapping.component';
import { BundlecollegemappinglistComponent } from './pages/Assessment/BundleCollegeMapping/bundlecollegemappinglist/bundlecollegemappinglist.component';
import { AddbundlassessmentmapComponent } from './pages/Assessment/BundleAssessmentMap/addbundlassessmentmap/addbundlassessmentmap.component';
import { BundleassessmentmaplistComponent } from './pages/Assessment/BundleAssessmentMap/bundleassessmentmaplist/bundleassessmentmaplist.component';
import { AddbundlstudentmapComponent } from './pages/Assessment/BundleStudentMap/addbundlstudentmap/addbundlstudentmap.component';
import { BundlestudentmaplistComponent } from './pages/Assessment/BundleStudentMap/bundlestudentmaplist/bundlestudentmaplist.component';
import { BundlemasterlistComponent } from './pages/Assessment/BundleMaster/bundlemasterlist/bundlemasterlist.component';
import { AddbundlemasterComponent } from './pages/Assessment/BundleMaster/addbundlemaster/addbundlemaster.component';
// import { CollegesmapComponent } from './pages/Assessment/BundleMaster/collegesmap/collegesmap.component';
import { BundleassessmentmapComponent } from './pages/Assessment/BundleMaster/bundleassessmentmap/bundleassessmentmap.component';
import { StudentmapComponent } from './pages/Assessment/BundleMaster/studentmap/studentmap.component';
import { AddtpoComponent } from './pages/Assessment/TPOMaster/addtpo/addtpo.component';
import { TpolistComponent } from './pages/Assessment/TPOMaster/tpolist/tpolist.component';
import { CollegesComponent } from './pages/Assessment/CollegeMaster/College/colleges/colleges.component';
import { CollegetypesComponent } from './pages/Assessment/CollegeMaster/CollegeTypes/collegetypes/collegetypes.component';
import { DesignationsComponent } from './pages/Assessment/CollegeMaster/Designations/designations/designations.component';
import { DistrictsComponent } from './pages/Assessment/CollegeMaster/Districts/districts/districts.component';
import { DocumenttypesComponent } from './pages/Assessment/CollegeMaster/DocumentType/documenttypes/documenttypes.component';
import { RegionsComponent } from './pages/Assessment/CollegeMaster/Regions/regions/regions.component';
import { StatesComponent } from './pages/Assessment/CollegeMaster/States/states/states.component';
import { StateComponent } from './pages/Assessment/CollegeMaster/States/state/state.component';
import { RegionComponent } from './pages/Assessment/CollegeMaster/Regions/region/region.component';
import { DocumenttypeComponent } from './pages/Assessment/CollegeMaster/DocumentType/documenttype/documenttype.component';
import { DistrictComponent } from './pages/Assessment/CollegeMaster/Districts/district/district.component';
import { DesignationComponent } from './pages/Assessment/CollegeMaster/Designations/designation/designation.component';
import { CollegetypeComponent } from './pages/Assessment/CollegeMaster/CollegeTypes/collegetype/collegetype.component';
import { CollegeComponent } from './pages/Assessment/CollegeMaster/College/college/college.component';
import { CollegebranchmappingComponent } from './pages/Assessment/CollegeMaster/College/collegebranchmapping/collegebranchmapping.component';
import { CollegeholidayComponent } from './pages/Assessment/CollegeMaster/College/collegeholiday/collegeholiday.component';
import { CollegetrainingcoursemappingComponent } from './pages/Assessment/CollegeMaster/College/collegetrainingcoursemapping/collegetrainingcoursemapping.component';
import { CollegetypemappingComponent } from './pages/Assessment/CollegeMaster/College/collegetypemapping/collegetypemapping.component';
// import { AddcollegeComponent } from './CollegeMaster/addcollege/addcollege.component';
import { CollegelistComponent } from './pages/Assessment/CollegeMaster/collegelist/collegelist.component';
import { AddcollegeComponent } from './pages/Assessment/CollegeMaster/addcollege/addcollege.component';
import { BranchComponent } from './pages/Assessment/Branches/branch/branch.component';
import { BranchesComponent } from './pages/Assessment/Branches/branches/branches.component';
import { QuestionComponent } from './pages/Assessment/QuestionMaster/question/question.component';
import { QuestionsComponent } from './pages/Assessment/QuestionMaster/questions/questions.component';
import { QuestiontypesComponent } from './pages/Assessment/QuestionTypes/questiontypes/questiontypes.component';
import { QuestiontypeComponent } from './pages/Assessment/QuestionTypes/questiontype/questiontype.component';
import { QuestionparagraphComponent } from './pages/Assessment/QuestionParagraph/questionparagraph/questionparagraph.component';
import { QuestionparagraphsComponent } from './pages/Assessment/QuestionParagraph/questionparagraphs/questionparagraphs.component';
import { MapquestionsComponent } from './pages/Assessment/QuestionParagraph/mapquestions/mapquestions.component';
import { ChaptersComponent } from './pages/Assessment/Chapters/chapters/chapters.component';
import { ChapterComponent } from './pages/Assessment/Chapters/chapter/chapter.component';
import { SectionsComponent } from './pages/Assessment/Sections/sections/sections.component';
import { SectionComponent } from './pages/Assessment/Sections/section/section.component';
import { LevelComponent } from './pages/Assessment/Levels/level/level.component';
import { LevelsComponent } from './pages/Assessment/Levels/levels/levels.component';
import { AddchapterComponent } from './pages/Assessment/ChapterMaster/addchapter/addchapter.component';
import { ChapterlistComponent } from './pages/Assessment/ChapterMaster/chapterlist/chapterlist.component';
import { AddquestionComponent } from './pages/Assessment/QuestionMaster/addquestion/addquestion.component';
import { QuestionlistComponent } from './pages/Assessment/QuestionMaster/questionlist/questionlist.component';
import { DocumentsComponent } from './pages/Assessment/CollegeMaster/College/Documents/documents/documents.component';
import { DocumentComponent } from './pages/Assessment/CollegeMaster/College/Documents/document/document.component';
import { ContactdetailComponent } from './pages/Assessment/CollegeMaster/College/contactdetails/contactdetail/contactdetail.component';
import { ContactdetailsComponent } from './pages/Assessment/CollegeMaster/College/contactdetails/contactdetails/contactdetails.component';
import { OnboardComponent } from './pages/Assessment/CollegeMaster/College/onboard/onboard.component';
// import { CollegesmapComponent } from './pages/Assessment/BundleMaster/collegesmap/collegesmap.component';
import { LinkreadComponent } from './pages/CommonModule/linkread/linkread.component';
import { ShikuyaaapiService } from './Service/shikuyaaapi.service';
import { CollegesmapComponent } from './pages/Assessment/BundleMapping/collegesmap/collegesmap.component';
import { QuestionmasterimporterComponent } from './pages/Assessment/QuestionMaster/questionmasterimporter/questionmasterimporter.component';
import { AssessmentchapterimporterComponent } from './pages/Assessment/ChapterMaster/assessmentchapterimporter/assessmentchapterimporter.component';
import { CollegebranchmapComponent } from './pages/Assessment/CollegeMaster/collegebranchmap/collegebranchmap.component';
import { ShikuyaaquestionimporterComponent } from './pages/ShikuyaaForms/ShikuyaaQuestions/shikuyaaquestionimporter/shikuyaaquestionimporter.component';
import { CollegebundlelistComponent } from './pages/Assessment/CollegeBundleMaster/collegebundlelist/collegebundlelist.component';
import { CollegebundlestudentmapComponent } from './pages/Assessment/CollegeBundleMaster/collegebundlestudentmap/collegebundlestudentmap.component';
import { StudassessmentdetailComponent } from './pages/Assessment/Reports/studassessmentdetail/studassessmentdetail.component';
import { StudcourseassessmentsummaryComponent } from './pages/Assessment/Reports/studcourseassessmentsummary/studcourseassessmentsummary.component';
import { CourseassessmentsummaryComponent } from './pages/Assessment/Reports/courseassessmentsummary/courseassessmentsummary.component';
import { CourseassessmentdetailComponent } from './pages/Assessment/Reports/courseassessmentdetail/courseassessmentdetail.component';
import { CourseassessmentstuddetailComponent } from './pages/Assessment/Reports/courseassessmentstuddetail/courseassessmentstuddetail.component';
import { CourseassessmentpartdetailComponent } from './pages/Assessment/Reports/courseassessmentpartdetail/courseassessmentpartdetail.component';
import { AssessmentReportsComponent } from './pages/Assessment/Reports/assessment-reports/assessment-reports.component';
import { OnlinebundlesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/onlinebundlesummary/onlinebundlesummary.component';
import { CollegesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/collegesummary/collegesummary.component';
import { CollegebundlesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/collegebundlesummary/collegebundlesummary.component';
import { StudentassessmentdetailsComponent } from './pages/Assessment/Reports/AssessmentReports/studentassessmentdetails/studentassessmentdetails.component';
import { StudentbundlesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/studentbundlesummary/studentbundlesummary.component';
import { StudentregsummaryComponent } from './pages/Assessment/Reports/AssessmentReports/studentregsummary/studentregsummary.component';
// import { LinkreadComponent } from './pages/CommonModule/linkread/linkread.component';
// import { FreeQueCategoryComponent } from './free-que-category/free-que-category.component';
// import { FreeQueCategoryGridComponent } from './free-que-category-grid/free-que-category-grid.component';
// import { LiveClassesCategoryComponent } from './live-classes-category/live-classes-category.component';
// import { LiveClassesCategoryGridComponent } from './live-classes-category-grid/live-classes-category-grid.component';
// import { MapMetasComponent } from './map-metas/map-metas.component';
// import { MapMetasgridComponent } from './map-metasgrid/map-metasgrid.component';
// import { MapMetaComponent } from './map-meta/map-meta.component';
// import { TagsMasterComponent } from './tags-master/tags-master.component';
// import { TagsMastergridComponent } from './tags-mastergrid/tags-mastergrid.component';
// import { RegistrationWebinarComponent } from './registration-webinar/registration-webinar.component';
// import { MobileOtpReportComponent } from './mobile-otp-report/mobile-otp-report.component';
// import { EmailOtpReportComponent } from './email-otp-report/email-otp-report.component';
// import { GamifiedDetailedReportComponent } from './gamified-detailed-report/gamified-detailed-report.component';
// import { GamifiedRegistrationReportComponent } from './gamified-registration-report/gamified-registration-report.component';
// import { CouponSummaryReportsComponent } from './coupon-summary-reports/coupon-summary-reports.component';
// import { CouponDetailedReportComponent } from './coupon-detailed-report/coupon-detailed-report.component';
// import { DeleteUserComponent } from './delete-user/delete-user.component';
// import { SubscribedStudentsComponent } from './subscribed-students/subscribed-students.component';
// import { RemoveUserComponent } from './remove-user/remove-user.component';
// import { MobileApkComponent } from './mobile-apk/mobile-apk.component';
// import { NzCardModule } from 'ng-zorro-antd/card';
@NgModule({
  declarations: [
    AppComponent,
    ExportDirective,
    LoginComponent,
    UserComponent,
    // MathjaxComponent,
    UsersComponent,
    DashboardComponent,
    RolesComponent,
    RoleComponent,
    FormsComponent,
    FormComponent,
    RoledetailsComponent,
    // PartComponent,
    // PartsComponent,
    SectionsComponent,
    SectionComponent,
    ChaptersComponent,
    ChapterComponent,
    QuestiontypesComponent,
    QuestiontypeComponent,
    LevelsComponent,
    LevelComponent,
    StatesComponent,
    StateComponent,
    RegionsComponent,
    RegionComponent,
    DistrictsComponent,
    DistrictComponent,
    CollegetypesComponent,
    CollegetypeComponent,
    DesignationComponent,
    DesignationsComponent,
    DocumenttypeComponent,
    DocumenttypesComponent,
    BranchComponent,
    BranchesComponent,
    // PassingyearsComponent,
    // PassingyearComponent,
    // DivisionsComponent,
    // DivisionComponent,
    // CoursetypesComponent,
    // CoursetypeComponent,
    // VehicalComponent,
    // VehicalsComponent,
    QuestionparagraphComponent,
    QuestionparagraphsComponent,
    // CoursesComponent,
    // CourseComponent,
    // TrainingcoursesComponent,
    // TrainingcourseComponent,
    // HolidaycalenderComponent,
    // SubchaptersComponent,
    // SubchapterComponent,
    MapquestionsComponent,
    Users1Component,
    CollegeComponent,
    CollegesComponent,
    ContactdetailsComponent,
    ContactdetailComponent,
    DocumentsComponent,
    DocumentComponent,
    // AppregistrationpendingComponent,
    OnboardComponent,
    CollegetypemappingComponent,
    CollegebranchmappingComponent,
    CollegetrainingcoursemappingComponent,
    CollegeholidayComponent,
    BundleDetailedSummaryComponent,
    // ProfeatureComponent,
    // BatchcreationComponent,
    // BatchComponent,
    // BatcheditComponent,
    // CreatesubbatchesComponent,
    // MastersessionsComponent,
    // MastersessionComponent,
    // SyllabusbatchgroupComponent,
    // SyllabusbatchgroupsComponent,
    // SyllabusbatchgroupmappingComponent,
    // BatchsyllabusComponent,
    // AddbatchsyllabusComponent,
    // SchedulingbatchgroupComponent,
    // SchedulingbatchgroupsComponent,
    // SchedulebatchComponent,
    // SchedulebatchesComponent,
    // SchedulingbatchmappingComponent,
    // ResourcebatchgroupComponent,
    // ResourcebatchgroupsComponent,
    // ResourcebatchgroupbatchmappingComponent,
    // ResourcebatchgrouptrainermappingComponent,
    // ResourceallocationComponent,
    // AssignresourceComponent,
    // VehicleallocationComponent,
    // SessionsComponent,
    // SessionComponent,
    LinkreadComponent,
    // SelectpageComponent,
    QuestionComponent,
    QuestionsComponent,
    // AssignvehicleComponent,
    // SyllabustrackingbatchwiseComponent,
    SelectrolepageComponent,
    AccesspageComponent,
    // SessionattendanceComponent,
    // QuestionimporterComponent,
    AssignmentgroupComponent,
    InstructionsComponent,
    InstructionComponent,
    AssignmentgroupsComponent,
    AssignmentsComponent,
    AssignmentComponent,
    QuestionsdataComponent,
    MappedassignmentsComponent,
    MappedassignmentComponent,
    MappedquestiondataComponent,
    // PasskeyreportComponent,
    // BatchreportComponent,
    // BatchstudentreportComponent,
    // PartreportComponent,
    // SectionreportComponent,
    // ChapterreportComponent,
    // SubchapterreportComponent,
    // MastersessionreportComponent,
    // MastersessiondetailsreportComponent,
    // BatchsessionreportComponent,
    // BatchsessiondetailsreportComponent,
    // MastersyllabusreportComponent,
    // BatchsyllabusreportComponent,
    ResetpasswordComponent,
    CurrentstatusComponent,
    ResultComponent,
    // NotificationComponent,
    // NotificationsComponent,
    ShikuyaachaptersComponent,
    ShikuyaachapterComponent,
    VideosComponent,
    VideoComponent,
    // ShikuyaadocumentComponent,
    // ShikuyaadocumentsComponent,
    ShikuyaaparagraphsComponent,
    ShikuyaaparagraphComponent,
    VideoquestiontypeComponent,
    VideoquestiontypesComponent,
    // PredefinedmocksComponent,
    // PredefinedmockComponent,
    // LivevideoComponent,
    // LivevideosComponent,
    CouponComponent,
    CouponsComponent,
    CouponfacilityComponent,
    CouponfacilitiesComponent,
    VideoquestionComponent,
    VideoquestionsComponent,
    ShikuyaaquestionComponent,
    ShikuyaaquestionsComponent,
    CouponfacilitymappingComponent,
    ShikuyaaquestionimporterComponent,
    // CoursestudentlistComponent,
    // VideostatslistComponent,
    // ViewcommentsComponent,
    // ChaptersummeryComponent,
    // CoursesummeryComponent,
    // PoleComponent,
    // PolesComponent,
    // LivepolemappingComponent,
    // LivevideosummeryComponent,
    // LivevideoreportComponent,
    // UserresponseComponent,
    // NotificationmastersettingComponent,
    // NotificationmastersettingsComponent,
    // TrainersComponent,
    // TrainerComponent,
    // SessionreportComponent,
    // EditbatchsyllabusComponent,
    // MandayscalculationComponent,
    // RenumerationcalculationComponent,
    // FffquestionComponent,
    // FffquestionsComponent,
    // LivefffmappingComponent,
    // LivefffuserresponsesComponent,
    // LivescreenComponent,
    // RatingsComponent,
    // SuggeestioncountComponent,
    // SyllabuscompletiontrackComponent,
    CoupontypeComponent,
    CoupontypesComponent,
    // StudentregistrationsComponent,
    // StudentregistrationComponent,
    // StudentcoursejoiningComponent,
    // StudentcoursepaymentComponent,
    // ShikuyaavideostatComponent,
    // ShikuyaavideowatchtimeComponent,
    AdmindashboardComponent,
    // StudentvideoquestionsummaryComponent,
    // StudentappusagereportComponent,
    // StudentcoursestatsComponent,
    // StudentcoursesummaryComponent,
    // StudentcoursepracticesummaryComponent,
    // StudentcoursevideosummaryComponent,
    // StudentcourselivevideosummaryComponent,
    // StudentcourseassesmentsummaryComponent,
    // StudentpracticedetailsComponent,
    // StudentvideodetailsComponent,
    // StudentlivevideodetailsComponent,
    // StudentassesmentdetailsComponent,
    // StudentchapterpracticequestiondetailsComponent,
    // StudentvideoquestiondetailsComponent,
    // StudentlivevideocommentdetailsComponent,
    // StudentlivevideopoledetailsComponent,
    // StudentlivevidefffdetailsComponent,
    // StudentassessmentquestiondetailsComponent,
    // StudentemailsummaryComponent,
    // StudentsmssummaryComponent,
    // StudentpaymentComponent,
    // StudentemaildetailsComponent,
    // StudentsmsdetailsComponent,
    // CoursesummaryComponent,
    // CoursepaymentreportComponent,
    // CoursestructuresummaryComponent,
    // CoursechaptersummaryComponent,
    // CoursepracticequestiondetailsComponent,
    // CoursevideosummaryComponent,
    // CourselivevideosummaryComponent,
    // CourselivevideostudentdetailsComponent,
    // CourselivevideopoledetailsComponent,
    // CourselivevideopolestudentdetailsComponent,
    // CourselivevideofffdetailsComponent,
    // CourselivevideofffstudentdetailsComponent,
    // CourselivevideocommentsComponent,
    // CourseassessmentsummaryComponent,
    // CourseassessmentdetailsComponent,
    // CourseassessmentstudentdetailsComponent,
    // CoursecoupondetailsComponent,
    // CoursecouponstudentdetailsComponent,
    // CoursewallettransactionsummaryComponent,
    // CoursewallettransactiondetailsComponent,
    // SmssummaryComponent,
    // EmailsummaryComponent,
    // EmaildetailsComponent,
    // SmsdetailsComponent,
    // PaymentsummaryComponent,
    // NotificationreportComponent,
    // WallettransactiondetailComponent,
    // StudentassesmentdesignedComponent,
    // SliderinfoComponent,
    // SliderComponent,
    // FreetrialstudentdetailComponent,
    // StudentpaymentdetailComponent,
    // StudentwalletsummaryComponent,
    // StudentcouponsummaryComponent,
    // StudentcoupondetailedComponent,
    // StudentwalletdetailedComponent,
    // EmailtemplatesComponent,
    // EmailtemplateComponent,
    // CoursevideoquestiondetailsComponent,
    AssignmentgroupComponent2,
    InstructionsComponent2,
    InstructionComponent2,
    AssignmentgroupsComponent2,
    AssignmentsComponent2,
    AssignmentComponent2,
    QuestionsdataComponent2 ,
    MappedassignmentsComponents2,
    MappedassignmentComponent2,
    MappedquestiondataComponent2,
    CurrentstatusComponent2,
    ResultComponent2,
    // BranchwiseliveassessmentsummaryComponent,
    // CollegewisebranchwiseliveassessmentsummaryComponent,
    // CollegewiseliveassessmentsummaryComponent,
    // CompletedliveassessmentdetailedreportComponent,
    // CompletedliveassessmentdetailedreportComponent,
    // LiveassessmentsummaryComponent,
    // NotstartedliveassessmentdetailedreportComponent,
    // ResumedliveassessmentdetailedreportComponent,
    // Courseassessmentdetails1Component,
    // StartedliveassessmentdetailedreportComponent,
    // TerminatedliveassessmentdetailedreportComponent,
    // VoilatedtedliveassessmentdetailedreportComponent,
    // Associates1Component,
    // AssociateComponent,
    // ChapterbaseComponent,
    // ChapterbasesComponent,
    // GameComponent,
    // GamesComponent,
    // JobapplicationComponent,
    // JobapplicationsComponent,
    // MenuComponent,
    // MenusComponent,
    // ResumeComponent,
    // ResumesComponent,
    // ReviewComponent,
    // ReviewsComponent,
    // SeoComponent,
    // SeosComponent,
    // SuccessstoriesComponent,
    // SuccessstoryComponent,
    // TeamsComponent,
    // TeamComponent,
    // WiegetComponent,
    // WiegetsComponent,
    // BlogcategoryComponent,
    // BlogcategoriesComponent,
    // BlogsComponent,
    // BlogComponent,
    // CareerComponent,
    // CareersComponent,
    // ContactsComponent,
    // DesignationwebComponent,
    // DesignationwebsComponent,
    // EnrollmentsComponent,
    // StudentgameprogressComponent,
    // CollegeassociteComponent,
    // CollegeassociatesComponent,
    // BlogcommentComponent,
    // GridblogsComponent,
    // AddblogsComponent,
    // PageComponent,
    // PagesComponent,
    // BlocksComponent,
    // BlockComponent,
    // AssignblocksComponent,
    // ApppromotionsComponent,
    // ApppromotionComponent,
    // AboutshikuyaaComponent,
    // AboutshikuyaasComponent,
    // BannerComponent,
    // BannersComponent,
    // FeatureComponent,
    // FeaturesComponent,
    // TypemasterComponent,
    // TypemastersComponent,
    // TypewidgetmappingComponent,
    // PageformsComponent,
    // PageformComponent,
    // SitemapsComponent,
    // SitemapComponent,
    // SitemapheadingComponent,
    // SitemapheadingsComponent,
    // CareerPageComponent,
    // CareerpagesComponent,
    // ContactpageComponent,
    // ContactpagesComponent,
    // PolicydetailsComponent,
    // PolicydetailComponent,
    // AboutpageComponent,
    // AboutpagesComponent,
    // TrainermasterComponent,
    // TrainermastersComponent,
    // SociallinkComponent,
    // SociallinksComponent,
    // CompaniesComponent,
    // CompanyComponent,
    // FootercontentComponent,
    // FootercontentsComponent,
    // FooterlinkComponent,
    // FooterlinksComponent,
    // NavbarcontentComponent,
    // NavbarcontentsComponent,
    // CourseuserreviewComponent,
    // CourseuserreviewsComponent,
    // ButtonlinksComponent,
    // ButtonlinkComponent,
    // InstructorComponent,
    // InstructorsComponent,
    // BlogwriterComponent,
    // BlogwritersComponent,
    // InstructorlinkComponent,
    // InstructorlinksComponent,
    // WebinarvideoComponent,
    // WebinarvideoesComponent,
    // InstructureComponent,
    // InstructormapComponent,
    // CourseitemmappingComponent,
    // CourseitemmappingsComponent,
    // CourseCategoryMasterComponent,
    // CourseCategoryGridMasterComponent,
    CompaniesMasterComponent,
    CompanyMastersComponent,
    AutofocusDirective,
    ChangeQuestionPipe,
    // CourseAssessmentPartDetailReportComponent,
    StudentAppDetailsReportComponent,
    // StudentAppDetailsReportsComponent,
    // StudentRegCompletedReportComponent,
    // StudentRegPendingReportComponent,
    // VideoImporterComponent,
    // LiveClassesComponent,
    // LiveClasseComponent,
    SecondsToHoursPipe,
    // VideoSUmmaryReportComponent,
    // StudentVideoSummaryReportComponent,
    // StudentPracticeSummaryReportComponent,
    // PracticeSummaryReportComponent,
ChapetrImporterComponent,
    AddbundlemasterComponent,
    BundlemasterlistComponent,
    AddbundlemappingComponent,
    BundlemappinglistComponent,
    AddbundlcollegeemappingComponent,
    BundlecollegemappinglistComponent,
    AddbundlassessmentmapComponent,
    BundleassessmentmaplistComponent,
    AddbundlstudentmapComponent,
    BundlestudentmaplistComponent,
    CollegesmapComponent,
    BundleassessmentmapComponent,
    StudentmapComponent,
    AddtpoComponent,
    TpolistComponent,
    AddcollegeComponent,
    CollegelistComponent,
    AddchapterComponent,
    ChapterlistComponent,
    AddquestionComponent,
    QuestionlistComponent,
    QuestionmasterimporterComponent,
    AssessmentchapterimporterComponent,
    CollegebranchmapComponent,
    CollegebundlelistComponent,
    CollegebundlestudentmapComponent,
    StudassessmentdetailComponent,
    StudcourseassessmentsummaryComponent,
    CourseassessmentsummaryComponent,
    CourseassessmentdetailComponent,
    CourseassessmentstuddetailComponent,
    CourseassessmentpartdetailComponent,
    AssessmentReportsComponent,
    OnlinebundlesummaryComponent,
    CollegesummaryComponent,
    CollegebundlesummaryComponent,
    StudentassessmentdetailsComponent,
    StudentbundlesummaryComponent,
    StudentregsummaryComponent,
    
// FreeQueCategoryComponent,
// FreeQueCategoryGridComponent,
// LiveClassesCategoryComponent,
// LiveClassesCategoryGridComponent,
// MapMetasComponent,
// MapMetasgridComponent,
// MapMetaComponent,
// TagsMasterComponent,
// TagsMastergridComponent,
// RegistrationWebinarComponent,
// MobileOtpReportComponent,
// EmailOtpReportComponent,
// GamifiedDetailedReportComponent,
// GamifiedRegistrationReportComponent,
// CouponSummaryReportsComponent,
// CouponDetailedReportComponent,
// DeleteUserComponent,
// SubscribedStudentsComponent,
// RemoveUserComponent,
// MobileApkComponent
  ],
  imports: [
    NzCardModule,
    NzModalModule,
    NzPopconfirmModule,
    NzToolTipModule,
    BrowserModule,
    AngularEditorModule,
    Ng2SearchPipeModule,
    AppRoutingModule,
    IconsProviderModule,
    NzEmptyModule,
    FormsModule,
    HttpClientModule,
    NzFormModule,
    NzInputModule,
    NzTableModule,
    NzDrawerModule,
    NzStatisticModule,
    NzSpinModule,
    NzSelectModule,
    NzDropDownModule,
    NzIconModule,
    NzNotificationModule,
    NzButtonModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzTreeSelectModule,
    NzRadioModule,
    NzDividerModule,
    NzTagModule,
    NzModalModule,
    NzPopoverModule,
    NzCheckboxModule,
    NgApexchartsModule,
    NzMessageModule,
    NzBadgeModule,
    NzStepsModule,
    NzCardModule,
    NzCommentModule,
    NzListModule,
    NzToolTipModule,
    NzAutocompleteModule,
    NzCalendarModule,
    NzTreeModule,
    NzTabsModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    NzLayoutModule,
    NzMenuModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US },SecondsToHoursPipe,DatePipe, CookieService, AsyncPipe,ApiService,ExportService,ShikuyaaapiService],
  bootstrap: [AppComponent],
  schemas: [
    
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
