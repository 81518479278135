import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Chapter } from 'src/app/Models/MasterSyllabus/chapter';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.css']
})
export class ChapterComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Chapter;
  isSpinning = false
  logtext: string = "";
  fileData_ICON_URL: File = null
  fkey = "vSF07qkT"
  userId = sessionStorage.getItem('userId');
  sectionsNodes = []
  dataList = []
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  isokfile = true
  data1:Chapter=new Chapter()
  
  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {

  }


  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.fileData_ICON_URL = null
    this.isokfile = true
  }

  ngOnInit() {
    this.loadAllSections()
    this.loadAllChapters()
  }

  loadAllChapters() {
    this.api.getAllChapters(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  loadAllSections() {
    this.api.getCombinedSections().subscribe(localName => {
      console.log("sections")
      console.log(localName)
      this.sectionsNodes = localName['data'];
    }, err => {
      console.log(err);
    });
  }

  sectionChange(sectionId)
  {
    let filter=" AND SECTION_ID="+sectionId
    this.api.getAllChapters(0, 0, 'ID', 'desc', filter).subscribe(data => {
      if(data['count']>0)
      {
        this.data1 = data['data'][0];
        this.data.SEQUENCE_NO= Number(this.data1.SEQUENCE_NO) + 1
      }
      else
      {
        this.data.SEQUENCE_NO=1
      }
    
    }, err => {
      console.log(err);
    });
  }

  close(): void {
    this.drawerClose();
    this.logtext = 'CLOSED - Chapter form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  save(addNew: boolean): void {

    if (this.data.NAME != undefined && this.data.NAME != "" && this.data.SUB_WEIGHTAGE != undefined && this.data.SUB_WEIGHTAGE.toString() != "" && this.data.WEIGHTAGE != undefined && this.data.WEIGHTAGE.toString() != "") {
      if(this.data.DESCRIPTION=="")
      this.data.DESCRIPTION=" "
      var returnData = this.similarData()
      console.log(returnData)
      if (this.isokfile) {
        if (returnData) {
          this.isSpinning = true;
          if (this.fileData_ICON_URL) {
            console.log(this.fileData_ICON_URL)
            var fileExt = this.fileData_ICON_URL.name.split('.').pop();
            var lkey = ""
            this.api.onUploadNewMethod(this.fileData_ICON_URL, fileExt, this.fkey)
              .subscribe(successCode => {
                console.log(successCode)
                if (successCode['code'] == 200) {
                  lkey = successCode['data'][0]['L_KEY']
                  this.data.ICON_URL = lkey
                  this.updateData(addNew)
                }
                else {
                  console.log(successCode)
                }
              });
          }
          else {
            if (this.data.ID)
              this.updateData(false)
            else {
              this.data.ICON_URL = " "
              this.updateData(addNew)
            }
          }
        }
      }
      else {
        this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
      }
    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }

  similarData() {
    if (this.data.ID) {

      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
      });

      var filterData1 = this.dataList.filter(object => {
        return object['SEQUENCE_NO'] == this.data.SEQUENCE_NO && object['SECTION_ID'] == this.data.SECTION_ID && object['ID'] != this.data.ID
      });

      var filterData2 = this.dataList.filter(object => {
        return object['COLOR_CODE'] == this.data.COLOR_CODE && object['ID'] != this.data.ID
      });

      console.log(this.dataList)
      console.log(filterData,filterData1,filterData2)

      if (filterData.length > 0 || filterData1.length > 0 || filterData2.length > 0) {
        if (filterData.length > 0)
          this.message.error("Name Already Present in Database", "");

        if (filterData1.length > 0)
          this.message.error("Sequence Already Present in Database", "");

        if (filterData2.length > 0)
          this.message.error("Color Code Already Present in Database", "");

        return false
      }
      else {
        return true
      }
    }
    else {
      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase()
      });

      var filterData1 = this.dataList.filter(object => {
        return object['SEQUENCE_NO'] == this.data.SEQUENCE_NO && object['SECTION_ID'] == this.data.SECTION_ID
      });

      var filterData2 = this.dataList.filter(object => {
        return object['COLOR_CODE'] == this.data.COLOR_CODE
      });

      if (filterData.length > 0 || filterData1.length > 0 || filterData2.length > 0) {
        if (filterData.length > 0)
          this.message.error("Name Already Present in Database", "");

        if (filterData1.length > 0)
          this.message.error("Sequence Already Present in Database", "");

        if (filterData2.length > 0)
          this.message.error("Color Code Already Present in Database", "");

        return false
      }
      else {
        return true
      }
    }
  }
  removeIcon()
  {
    this.data.ICON_URL=""
  }
  updateData(addNew: boolean) {


    if (this.data.ID) {

      if(this.data.ICON_URL=="")
      this.data.ICON_URL=" "

      console.log(this.data)
      this.api.updateChapter(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Chapter information updated Successfully...", "");
            this.sectionChange(this.data.SECTION_ID)
            this.logtext = 'Update & Close - Chapter form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Chapter ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            if (!addNew)
              this.fileData_ICON_URL = null
            this.isSpinning = false;
            this.drawerClose();

          }
          else {

            this.logtext = 'Update & Close - Chapter form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Chapter ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            this.message.error("Failed to update chapter information...", "");
            this.isSpinning = false;
          }
        });

    }
    else {

      this.api.createChapter(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Chapter information added successfully...", "");
            this.sectionChange(this.data.SECTION_ID)
            if (!addNew) {
              this.fileData_ICON_URL = null

              this.drawerClose();

              this.logtext = 'Save & Close - Chapter form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
            else {
              this.reset()
           
              this.data.NAME = ""
              this.data.IS_ACTIVE = true;
              this.data.COLOR_CODE = "#ffffff"
              this.data.DESCRIPTION = " "
              this.data.SEQUENCE_NO = undefined
              this.data.ICON_URL = " "
              this.data.SUB_WEIGHTAGE = undefined
              this.data.WEIGHTAGE = undefined
              this.loadAllChapters()

              this.logtext = 'Save & New - Chapter form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add chapter information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - Chapter form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

          }
        });

    }
  }

  getUrl(url) {
    var newTab = window.open();
    newTab.document.body.innerHTML = '<img src="' + url + '" width="500px" height="100%" style="left: 408px;position: absolute;"> ';
  }
  
  onFileSelected_ICON_URL(event) {
    this.isokfile = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfile = false
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    }

    if (this.isokfile) {
      this.fileData_ICON_URL = <File>event.target.files[0];
    }

  }


}