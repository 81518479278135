import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router,public api: ApiService,private message: NzNotificationService) { }

  ngOnInit() {
  //   this.api.addLog('L', 'Login Successfully '+this.api.dateforlog,this.api.emailId).subscribe(data => {
  //  }, err => {
  //    console.log(err);
  //    if (err['ok'] == false)
  //    this.message.error("Server Not Found", "");
  //  });
    this.router.navigateByUrl('/dashboard')
  
  }

}
