export class User {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    EMAIL_ID:string;
    MOBILE_NUMBER:string;
    IS_ACTIVE:boolean;
    PASSWORD:string;
    ROLE_DATA:number[]
   USER_ID:number
   USER_NAME:string
   USER_EMAIL_ID:string
   USER_MOBILE:string
   USER_STATUS:boolean
   USER_PASSWORD:string
   
}
