import { Component, OnInit, Input } from '@angular/core';
import { Assignmentgroup } from '../../../../Models/AssignmentModule/assignmentgroup';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-assignmentgroup',
  templateUrl: './assignmentgroup.component.html',
  styleUrls: ['./assignmentgroup.component.css']
})
export class AssignmentgroupComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Assignmentgroup;
  isSpinning = false
  logtext: string = "";
  dataList=[]

  constructor(public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
   this.loadData()
  }

loadData()
{
  this.api.getAllAssignmentGroups(0, 0, 'ID', 'desc', "").subscribe(data => {
    this.dataList = data['data'];
  }, err => {
    console.log(err);
  });
}

  close(): void {
    this.drawerClose();
    this.logtext = 'CLOSED - AssignmentGroup form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  save(addNew: boolean): void {
   
    if(this.data.NAME!=undefined && this.data.NAME!="")
    {
      if(this.data.DESCRIPTION=="")
      this.data.DESCRIPTION=" "
      if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
      {


    if (this.data.ID) {

      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
      });

      if(filterData.length>0)
          {
          this.message.error("Already Record Present in Database", "");
          }
          else
          {
    this.isSpinning = true;

      this.api.updateAssignmentGroup(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("AssignmentGroup information updated Successfully...", "");

            this.logtext = "Update & Close - AssignmentGroup form - SUCCESS" + JSON.stringify(this.data) + " KEYWORD [U - AssignmentGroup ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {

            this.logtext = "Update & Close - AssignmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [U - AssignmentGroup ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            this.message.error("Failed to update assignmentGroup information...", "");
            this.isSpinning = false;
          }
        });
      }
    }
    else {
      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
      });

     if(filterData.length>0)
     {
      this.message.error("Already Record Present in Database", "");
     }
     else
     {
    this.isSpinning = true;
      this.api.createAssignmentGroup(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.message.success("AssignmentGroup information added successfully...", "");

            if (!addNew) {
              this.drawerClose();

              this.logtext = "Save & Close - AssignmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssignmentGroup ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }

            else {
              this.data = new Assignmentgroup();
              this.loadData()
              this.logtext = "Save & New - AssignmentGroup form - SUCCESS - " + JSON.stringify(this.data) + " KEYWORD [C - AssignmentGroup ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add assignmentGroup information...", "");
            this.isSpinning = false;
            this.logtext = "Save & Close - AssignmentGroup form - ERROR - " + JSON.stringify(this.data) + " KEYWORD [C - AssignmentGroup ]";
            this.api.addLog('A', this.logtext,this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

          }
        });
      }
    }
  }
  else
  {
    this.message.error("Please Check Name", "");
  }
  }
  else
  {
    this.message.error("Please Fill All Required Fields", "");

  }

  }


}