import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-studcourseassessmentsummary',
  templateUrl: './studcourseassessmentsummary.component.html',
  styleUrls: ['./studcourseassessmentsummary.component.css']
})
export class StudcourseassessmentsummaryComponent implements OnInit {

  formTitle = "Student Course Assessment  Summary";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  COURSE_ID:''
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";
  filterValue = " "
  filterClass: string = "filter-invisible";
   isExportloading = false
  columns: string[][] = [["STUDENT_NAME", "Student Name"], ["EMAIL_ID", "Email"], ["MOBILE_NO", "Mobile"], ['COURSE_NAME', 'Course Name'],
  ["TOTAL_MOCK_TESTS", "Total Assesments"], ["COMPLETED_MOCKS","Solved Assesments"], ['TOTAL_QUESTIONS', 'Total Questions'], ['TOTAL_SOLVED_QUESTIONS', 'Solved Questions'],
  ['AVG_PERCENTAGE', 'Percentage'], ['TOTAL_SECONDS', 'Total Time (Sec.)'], ['TOTAL_SOLVED_SECONDS', 'Taken Time'], ['ACCURACY_PERCENT', 'Accuracy']]
  coursesNodes = []
  isloadSpinning = false
  dataList1 = []
  pageSize2 = 10
  selectedDate: Date[] = []
  value1: string = ""
  value2: string = ""
  dateFormat = 'dd/MM/yyyy';
  datePipe = new DatePipe("en-US");
  loadingcourse = false
  constructor(public api: ApiService, private message: NzNotificationService) { }
  ngOnInit() {
    this.selectedDate = [new Date(), new Date()]
    this.changeDate(this.selectedDate)
    this.loadAllCourses();

    this.logtext = 'OPENED - Student Course Assesment  Summary KEYWORD[O - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }
  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd")
  }

  loadAllCourses() {
    this.loadingcourse = true
    this.api.getAllCourses(0, 0, 'ID', 'ASC', ' AND COURSE_TYPE_ID = 2').subscribe(localName => {
      this.loadingcourse = false
      this.coursesNodes = localName['data'];
      
    }, err => {
      console.log(err);
    });
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search(false);
  }

  onKeyDownEvent(event)
  {if(event.key=='Enter')
  {
    event.preventDefault()
  }

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
this.search1(true)
  }
  search1(reset: boolean = false) {
    this.isloadSpinning = false
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - Student Course Assesment  Summary"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
    }

    this.logtext = 'Filter Applied - Student Course Assesment  Summary '+ likeQuery +' KEYWORD [F - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

    console.log(filter)
    // this.isloadSpinning = true
    this.api.getAlllStudentCourseAssesmentSummaryReport(this.pageIndex, this.pageSize, '', sort, filter).subscribe(data => {
   
      this.loadingRecords = false;
      if (data['code'] == '200') {
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        if (data['count'] >'0') {  this.isExportloading = true
         
          this.dataList1 = data['data']; }
        this.isExportloading = true
      }
      
    }, err => {
      console.log(err);
    });
   
    
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - Student Course Assesment  Summary"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
    }

    this.logtext = 'Filter Applied - Student Course Assesment  Summary '+ likeQuery +' KEYWORD [F - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

    console.log(filter)
    this.isloadSpinning = true
    this.api.getAlllStudentCourseAssesmentSummaryReport(this.pageIndex, this.pageSize, '', sort, filter).subscribe(data => {
      this.isloadSpinning = false
      this.loadingRecords = false;
      if (data['code'] == '200') {
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        if (data['count'] >'0') {  this.isExportloading = true
         
          this.dataList1 = data['data']; }
        
      
      }
      
    }, err => {
      console.log(err);
    });
   
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }


  applyFilter() {
    this.filterQuery = ''
 
    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
    this.search(true)
  }

  clearFilter() {
    this.filterValue =''
    this.COURSE_ID=''
    this.filterQuery=''
    this.selectedDate = [new Date(), new Date()]
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";

    this.search(true)
  }



}
