import { NgModule } from '@angular/core';
import { ChapetrImporterComponent } from './chapetr-importer/chapetr-importer.component';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { UsersComponent } from './pages/CommonModule/Users/users/users.component';
import { RolesComponent } from './pages/CommonModule/Roles/roles/roles.component';
import { LoginComponent } from './login/login.component';
import { FormsComponent } from './pages/CommonModule/Forms/forms/forms.component';
import { Users1Component } from './pages/CommonModule/Try/users1.component';
import { LinkreadComponent } from './pages/CommonModule/linkread/linkread.component';
import { SelectrolepageComponent } from './pages/CommonModule/selectrolepage/selectrolepage.component';
import { AccesspageComponent } from './pages/CommonModule/accesspage/accesspage.component';
// import { QuestionimporterComponent } from './pages/MasterSyllabus/questionimporter/questionimporter.component';
import { AssignmentgroupsComponent } from './pages/AssignmentModule/AssignmentGroup/assignmentgroups/assignmentgroups.component';
import { InstructionsComponent } from './pages/AssignmentModule/Instructions/instructions/instructions.component';
import { AssignmentsComponent } from './pages/AssignmentModule/AssignmentsCreation/assignments/assignments.component';
import { MappedassignmentsComponent } from './pages/AssignmentModule/MappedAssignments/mappedassignments/mappedassignments.component';
import { ResetpasswordComponent } from './pages/CommonModule/resetpassword/resetpassword.component';
// import { NotificationsComponent } from './pages/Notifications/notifications/notifications.component';
import { ShikuyaachaptersComponent } from './pages/ShikuyaaForms/Chapters/shikuyaachapters/shikuyaachapters.component';
import { VideosComponent } from './pages/ShikuyaaForms/Videos/PredefinedVideos/videos/videos.component';
// import { ShikuyaadocumentsComponent } from './pages/ShikuyaaForms/Documents/shikuyaadocuments/shikuyaadocuments.component';
import { ShikuyaaparagraphsComponent } from './pages/ShikuyaaForms/Paragraphs/shikuyaaparagraphs/shikuyaaparagraphs.component';
import { VideoquestiontypesComponent } from './pages/ShikuyaaForms/Videos/VideoQuestionTypes/videoquestiontypes/videoquestiontypes.component';
// import { PredefinedmocksComponent } from './pages/ShikuyaaForms/Mocks/predefinedmocks/predefinedmocks.component';
// import { LivevideosComponent } from './pages/ShikuyaaForms/LiveVideo/livevideos/livevideos.component';
import { CouponfacilitiesComponent } from './pages/ShikuyaaForms/Coupon/couponfacilitys/couponfacilities/couponfacilities.component';
import { CouponsComponent } from './pages/ShikuyaaForms/Coupon/Couponss/coupons/coupons.component';
import { ShikuyaaquestionsComponent } from './pages/ShikuyaaForms/ShikuyaaQuestions/shikuyaaquestions/shikuyaaquestions.component';
import { ShikuyaaquestionimporterComponent } from './pages/ShikuyaaForms/ShikuyaaQuestions/shikuyaaquestionimporter/shikuyaaquestionimporter.component';
import { CoupontypesComponent } from './pages/ShikuyaaForms/Coupon/coupontypes/coupontypes.component';
import { AdmindashboardComponent } from './pages/CommonModule/admindashboard/admindashboard.component';
import { AssignmentsComponent2 } from './pages/ShikuyaaForms/AssignmentModule/AssignmentsCreation/assignments/assignments2.component';
import { InstructionsComponent2 } from './pages/ShikuyaaForms/AssignmentModule/Instructions/instructions/instructions2.component';
import { AssignmentgroupsComponent2 } from './pages/ShikuyaaForms/AssignmentModule/AssignmentGroup/assignmentgroups/assignmentgroups2.component';
import { MappedassignmentsComponents2 } from './pages/ShikuyaaForms/AssignmentModule/MappedAssignments/mappedassignments/mappedassignments2.component';
import { CompanyMastersComponent } from 'src/app/pages/ShikuyaaForms/ShikuyaaQuestions/company-masters/company-masters.component'
import { BundlemasterlistComponent } from './pages/Assessment/BundleMaster/bundlemasterlist/bundlemasterlist.component';
import { BundlemappinglistComponent } from './pages/Assessment/BundleMapping/bundlemappinglist/bundlemappinglist.component';
import { TpolistComponent } from './pages/Assessment/TPOMaster/tpolist/tpolist.component';
import { CollegetypesComponent } from './pages/Assessment/CollegeMaster/CollegeTypes/collegetypes/collegetypes.component';
import { DesignationsComponent } from './pages/Assessment/CollegeMaster/Designations/designations/designations.component';
import { DistrictsComponent } from './pages/Assessment/CollegeMaster/Districts/districts/districts.component';
import { DocumenttypesComponent } from './pages/Assessment/CollegeMaster/DocumentType/documenttypes/documenttypes.component';
import { RegionsComponent } from './pages/Assessment/CollegeMaster/Regions/regions/regions.component';
import { StatesComponent } from './pages/Assessment/CollegeMaster/States/states/states.component';
import { CollegelistComponent } from './pages/Assessment/CollegeMaster/collegelist/collegelist.component';
import { BranchesComponent } from './pages/Assessment/Branches/branches/branches.component';
import { QuestionsComponent } from './pages/Assessment/QuestionMaster/questions/questions.component';
import { QuestiontypesComponent } from './pages/Assessment/QuestionTypes/questiontypes/questiontypes.component';
import { QuestionparagraphsComponent } from './pages/Assessment/QuestionParagraph/questionparagraphs/questionparagraphs.component';
import { ChaptersComponent } from './pages/Assessment/Chapters/chapters/chapters.component';
import { SectionsComponent } from './pages/Assessment/Sections/sections/sections.component';
import { LevelsComponent } from './pages/Assessment/Levels/levels/levels.component';
import { ChapterlistComponent } from './pages/Assessment/ChapterMaster/chapterlist/chapterlist.component';
import { QuestionlistComponent } from './pages/Assessment/QuestionMaster/questionlist/questionlist.component';
import { QuestionmasterimporterComponent } from './pages/Assessment/QuestionMaster/questionmasterimporter/questionmasterimporter.component';
import { AssessmentchapterimporterComponent } from './pages/Assessment/ChapterMaster/assessmentchapterimporter/assessmentchapterimporter.component';
import { CollegebundlelistComponent } from './pages/Assessment/CollegeBundleMaster/collegebundlelist/collegebundlelist.component';
import { StudassessmentdetailComponent } from './pages/Assessment/Reports/studassessmentdetail/studassessmentdetail.component';
import { StudcourseassessmentsummaryComponent } from './pages/Assessment/Reports/studcourseassessmentsummary/studcourseassessmentsummary.component';
import { CourseassessmentsummaryComponent } from './pages/Assessment/Reports/courseassessmentsummary/courseassessmentsummary.component';
import { OnlinebundlesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/onlinebundlesummary/onlinebundlesummary.component';
import { CollegebundlesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/collegebundlesummary/collegebundlesummary.component';
import { CollegesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/collegesummary/collegesummary.component';
import { StudentassessmentdetailsComponent } from './pages/Assessment/Reports/AssessmentReports/studentassessmentdetails/studentassessmentdetails.component';
import { StudentbundlesummaryComponent } from './pages/Assessment/Reports/AssessmentReports/studentbundlesummary/studentbundlesummary.component';
import { StudentregsummaryComponent } from './pages/Assessment/Reports/AssessmentReports/studentregsummary/studentregsummary.component';
import { BundleDetailedSummaryComponent } from './pages/Assessment/Reports/AssessmentReports/BundleDetailedSummary/bundle-detailed-summary/bundle-detailed-summary.component';

const routes: Routes = [

 
 { path: 'chapterImporter', component: ChapetrImporterComponent },
 { path: 'assessmentChapterImporter', component: AssessmentchapterimporterComponent },
  // { path: 'studentregcompleted', component: StudentRegCompletedReportComponent },
  // { path: 'studentappdetailsreport', component: StudentAppDetailsReportsComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'courseAssessmentPartDetailsReport', component: CourseAssessmentPartDetailReportComponent },
  // { path: 'courseCategory', component: CourseCategoryGridMasterComponent },
  { path: 'companies', component: CompanyMastersComponent },
 
  // { path: 'coms', component: CompaniesComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'users', component: UsersComponent },
  // { path: 'liveSession', component: LiveClassesComponent },
  { path: 'roles', component: RolesComponent },
  { path: 'forms', component: FormsComponent },
  // { path: 'parts', component: PartsComponent },
  { path: 'sections', component: SectionsComponent },
  { path: 'chapters', component: ChapterlistComponent },
  { path: 'questiontypes', component: QuestiontypesComponent },
  { path: 'levels', component: LevelsComponent },
 
  { path: 'branches', component: BranchesComponent },
  
  { path: 'questionparagraphs', component: QuestionparagraphsComponent },
  
  { path: 'try', component: Users1Component },
  
  { path: 'studentReport/123dssdfv', component: LinkreadComponent },
  // { path: 'selectpage', component: SelectpageComponent },
  { path: 'questions', component: QuestionsComponent },
  // { path: 'vehicleallocation', component: VehicleallocationComponent },
  // { path: 'syllabustrackbatchwise', component: SyllabustrackingbatchwiseComponent },
  { path: 'selectrole', component: SelectrolepageComponent },
  { path: 'pagenotfound', component: AccesspageComponent },
  // { path: 'questionimporter', component: QuestionimporterComponent },
  { path: 'assignmentgroup', component: AssignmentgroupsComponent },
  { path: 'assignmentinstructions', component: InstructionsComponent },
  { path: 'assignments', component: AssignmentsComponent },
  { path: 'mappedassignments', component: MappedassignmentsComponent },
  
  { path: 'resetpassword', component: ResetpasswordComponent },
  // { path: 'notifications', component: NotificationsComponent },
  { path: 'topics', component: ShikuyaachaptersComponent },
  // { path: 'chapterdocuments', component: ShikuyaadocumentsComponent },
  { path: 'videoquestiontype', component: VideoquestiontypesComponent },
  { path: 'videos', component: VideosComponent },
  { path: 'paragraphs', component: ShikuyaaparagraphsComponent },
  // { path: 'predefinedmocks', component: PredefinedmocksComponent },
  // { path: 'livevideos', component: LivevideosComponent },
  { path: 'coupons', component: CouponsComponent },
  { path: 'couponfacilities', component: CouponfacilitiesComponent },
  { path: 'shikuyaaquestions', component: ShikuyaaquestionsComponent },
  { path: 'shikuyaaquestionimporter', component: ShikuyaaquestionimporterComponent }, 
  { path: 'assessmentquestionimporter', component: QuestionmasterimporterComponent }, 
 
  { path: 'coupontypes', component: CoupontypesComponent },
  
  { path: 'home', component:AdmindashboardComponent },
  
  { path: 'studentcourseassesmentsummary', component:StudcourseassessmentsummaryComponent},
 
  { path: 'studentassesmentdetails', component:StudassessmentdetailComponent},
  
  { path: 'courseassessmentsummary', component:CourseassessmentsummaryComponent},
  
  { path: 'mockgroups', component: AssignmentgroupsComponent2 },
  { path: 'mockinstructions', component: InstructionsComponent2 },
  { path: 'mocks', component: AssignmentsComponent2 },
  { path: 'mappedmocks', component: MappedassignmentsComponents2 },


  { path: 'bundlemaster', component: BundlemasterlistComponent },
  { path: 'bundlemap', component: BundlemappinglistComponent }, 
  { path: 'tpomaster', component: TpolistComponent },
  { path: 'colleges', component: CollegelistComponent },
  { path: 'collegestype', component: CollegetypesComponent },
  { path: 'designation', component: DesignationsComponent },
  { path: 'districts', component: DistrictsComponent },
  { path: 'documenttype', component: DocumenttypesComponent },
  { path: 'regions', component: RegionsComponent },
  { path: 'states', component: StatesComponent },  
  { path: 'newcollege', component: CollegelistComponent }, 
  { path: 'questionmaster', component: QuestionlistComponent }, 
  { path: 'collegebundle', component: CollegebundlelistComponent }, 
  
  { path: 'onlinebundlesummary', component: OnlinebundlesummaryComponent }, 
  { path: 'collegebundlesummary', component: CollegebundlesummaryComponent }, 
  { path: 'collegesummary', component: CollegesummaryComponent }, 
  { path: 'studentassessmentdetail', component: StudentassessmentdetailsComponent }, 
  { path: 'studentbundlesummary', component: StudentbundlesummaryComponent }, 
  { path: 'studentregsummary', component: StudentregsummaryComponent }, 
  { path: 'bundledetailed', component: BundleDetailedSummaryComponent }, 


];

@NgModule({
  imports: [CommonModule,RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
