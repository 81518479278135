import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Region } from 'src/app/Models/CollegeOnboarding/region';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService} from 'ng-zorro-antd/notification';
import { State } from 'src/app/Models/CollegeOnboarding/state';
import { CookieService } from 'ngx-cookie-service';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Region;
  @Input() dataList

  isSpinning = false
  logtext: string = "";
  states: State[];
  userId = sessionStorage.getItem('userId');

  stateData: State = new State()
  stateVisible: boolean
  stateTitle: string = ""

  loadingStates: boolean
  datastateList1=[]
  
  constructor(public api: ApiService, public api2: ShikuyaaapiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.loadStates();
    this.getAllStates()
  }

  loadStates() {
    this.loadingStates = true;
    let filter=" AND IS_ACTIVE=1"
    this.api2.getAllStudentStates(0, 0, '', '', filter).subscribe(localName => {
      this.states = localName['data'];
      this.data.STATE_ID = localName['data'][0]['ID']
      this.loadingStates = false;
    }, err => {
      console.log(err);
      this.loadingStates = false;
    });
  }

  close(regionMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(regionMasterPage);
    this.logtext = 'CLOSED - Region form';
    this.api2.addLog('A', this.logtext, this.api2.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }
  // close(): void {
  //   this.drawerClose();

  //   this.logtext = 'CLOSED - Region form';
  //   this.api2.addLog('A', this.logtext, this.api2.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }
  resetDrawer(regionMasterPage: NgForm) {
    this.data=new Region();
    // regionMasterPage.form.reset();

    regionMasterPage.form.markAsPristine();
    regionMasterPage.form.markAsUntouched();
  }

  ///save
  save(addNew: boolean,regionMasterPage: NgForm): void {

console.log('ID:',this.data.STATE_ID);
console.log('Name:',this.data.NAME);



    if (this.data.STATE_ID!=undefined && this.data.NAME!=undefined && this.data.NAME!="") {
      // if(this.data.STATE_ID==undefined && this.data.STATE_ID==null){
      //   this.message.error("Please Select State","")
      // }
   

      if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
      {

        if(this.data.DESCRIPTION=="" || this.data.DESCRIPTION==undefined)
        this.data.DESCRIPTION=" "

      if (this.data.ID) {
        
        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
        });

        if(filterData.length>0)
        {
        this.message.error("Already Record Present in Database", "");
        }
        else
        {

          this.isSpinning = true;
          this.api2.updateRegion(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Region information updated Successfully...", "");

              this.logtext = 'Update & Close - Region form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Region ]";
              this.api2.addLog('A', this.logtext, this.api2.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

              this.logtext = 'Update & Close - Region form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Region ]";
              this.api2.addLog('A', this.logtext, this.api2.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              this.message.error("Failed to update region information...", "");
              this.isSpinning = false;
            }
          });
        }
       
      }
      else {

        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
        });
  
       if(filterData.length>0)
       {
        this.message.error("Already Record Present in Database", "");
       }
       else
       {
         

        this.isSpinning = true;
        this.api2.createRegion(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Region information added successfully...", "");

            if (!addNew) {
              this.drawerClose();

              this.logtext = 'Save & Close - Region form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Region ]";
              this.api2.addLog('A', this.logtext, this.api2.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
            else {
             this.data = new Region();
              // this.data.NAME=""
              // this.data.IS_ACTIVE=true
              // this.data=new TPOMaster();
              this.resetDrawer(regionMasterPage);
              // this.data.img= '';
              this.api2.getAllRegions(0, 0, 'ID', 'desc', '').subscribe(data => {
                this.dataList = data['data'];
              }, err => {
                console.log(err);
              });
              this.logtext = 'Save & New - Region form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Region ]";
              this.api2.addLog('A', this.logtext, this.api2.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add region information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - Region form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Region ]";
            this.api2.addLog('A', this.logtext, this.api2.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
        });
       }
       
      }
    }
    else
    {
      this.message.error("Please Check Name", "");
    }
    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }

  }

  addNewState() {
    this.stateVisible = true;
    this.stateTitle = "Create New State";
    this.stateData = new State();
    this.setValueOfState()
  }


  stateClose(): void {
    this.loadStates() 
    this.stateVisible = false;
  }

  get callbackStateClose() {
    return this.stateClose.bind(this);
  }



  setValueOfState() {
    this.stateData.IS_ACTIVE = true
  }

  getAllStates()
  {
    this.api2.getAllStudentStates(0, 0,'ID', 'desc',"").subscribe(data => {
      this.datastateList1 = data['data'];
    }, err => {
      console.log(err);
    });
  }

}
