import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Questionmaster } from 'src/app/Models/MasterSyllabus/questionmaster';
import { Questiontype } from 'src/app/Models/MasterSyllabus/questiontype';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { Questionparagraph } from 'src/app/Models/MasterSyllabus/questionparagraph';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Questionmaster;
  isSpinning = false
  OptionList = []

 isOk = true
 display = false

  questionTypes: Questiontype[]
  levels: Level[]
  paragraphs: Questionparagraph[]
  chaptersNodes = []
  loadingTypes = false
  loadingLevels = false
  loadingParagraph = false
  questionTypeVisible: boolean
  questionTypeTitle: string = ""
  questionTypeData: Questiontype = new Questiontype()

  levelVisible: boolean
  levelTitle: string = ""
  levelsData: Level = new Level()

  OPTION_TEXT = ""
  IS_CORRECT: boolean = false
  SEQUENCE_NO: number = 1
  WEIGHTAGE: number = 0
  i = 2
  visibleType1: boolean
  visibleType2: boolean
  visibleType3: boolean
  visibleCheck1: boolean
  visibleCheck2: boolean
  directionfkey = "DubLrVNQ"
  descriptionfkey = "MNI79BiF"
  paragraphfkey = "VzPxkc3m"
  questionfkey = "GKxxqgw3"
  optionfkey = "37F57cHm"
  solutionfkey = "IlcBlwQA"
  file_Direction_URL: File = null
  file_Description_URL: File = null
  file_Question_URL: File = null
  file_Solution_URL: File = null
  file_Paragraph_URL: File = null
  file_Options_URL: File = null

  logtext: string = "";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  urlList = []
  urlVar = {}
  urlOptionList = []
  urlOptionVar = {}

  questionParagraphVisible: boolean;
  questionParagraphTitle: string = ""
  questionParagraphData = new Questionparagraph();
  loadingOptions = false 
  directionImg = false 

  iop = 0

  @ViewChild('direction', { static: false }) myInputVariable: ElementRef;
  @ViewChild('description', { static: false }) myInputVariable1: ElementRef;
  @ViewChild('paragraph', { static: false }) myInputVariable2: ElementRef;
  @ViewChild('question', { static: false }) myInputVariable3: ElementRef;
  @ViewChild('solutionimage', { static: false }) myInputVariable4: ElementRef;
  @ViewChild('optionUrl', { static: false }) myInputVariableOption: ElementRef;

  isokfileDirection = true
  isokfileDescription = true
  isokfileParagraph = true
  isokfileQusetion = true
  isokfileSolutionUrl = true
  isokfileOption = true
  index1 = -1
  imgURL: any="";
  public imagePath;
  questionId: number
  // datePipe: any;
  constructor( public datePipe: DatePipe,public api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.loadQuestionTypes()
      this.loadLevels()
      this.loadQuestionParagraph()
      this.loadSubChpater()
    }
  }

  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.myInputVariable1.nativeElement.value = null;
    // this.myInputVariable2.nativeElement.value = null;
    this.myInputVariable3.nativeElement.value = null;
    this.myInputVariable4.nativeElement.value = null;

    this.file_Direction_URL = null
    this.file_Description_URL = null
    this.file_Paragraph_URL = null
    this.file_Question_URL = null
    this.file_Solution_URL = null

    this.isokfileDirection = true
    this.isokfileDescription = true
    this.isokfileParagraph = true
    this.isokfileQusetion = true
    this.isokfileSolutionUrl = true
    this.isokfileOption = true
    this.SEQUENCE_NO = 1
    this.OPTION_TEXT = ""
    this.questionId = undefined
    this.iop=0
    this.imgURL=""
  }

  getOptionList(questionId: number) {
    console.log(questionId)
    this.questionId = questionId
    this.loadingOptions = true
    let filter = " AND QUESTION_ID=" + questionId
    this.api.getAllAssessmentOptions(0, 0, 'SEQUENCE_NO', 'asc', filter)
      .subscribe(successCode => {
        console.log(successCode['data'])
        this.OptionList = successCode['data']
        console.log(this.OptionList)
        this.loadingOptions = false

      });
  }

  addNewQuestionType() {
    this.questionTypeVisible = true;
    this.questionTypeTitle = "Create New Question Type";
    this.questionTypeData = new Questiontype();
    this.setValueOfQuestionType()
  }

  questionTypeClose(): void {
    this.loadQuestionTypes()
    this.questionTypeVisible = false;
  }



  get callbackQuestionTypeClose() {
    return this.questionTypeClose.bind(this);
  }

  setValueOfQuestionType() {
    this.questionTypeData.IS_ACTIVE = true
    this.questionTypeData.DESCRIPTION = ""
  }

  loadQuestionTypes() {
    this.loadingTypes = true;
    let filter = " AND IS_ACTIVE=1"
    this.api.getAllQuestionTypes(0, 0, 'ID', 'desc', filter).subscribe(localName => {
      console.log(localName)
      this.questionTypes = localName['data'];
      this.data.QUESTION_TYPE_ID = localName['data'][0]['ID']
      this.loadingTypes = false;
    }, err => {
      console.log(err);
      this.loadingTypes = false;
    });
  }

  addNewLevel() {
    this.levelVisible = true;
    this.levelTitle = "Create New Difficulty Level";
    this.levelsData = new Level();
    this.setValueOfLevel()

  }

  levelClose(): void {
    this.loadLevels()
    this.levelVisible = false;
  }

  get callbackLevelClose() {
    return this.levelClose.bind(this);
  }

  setValueOfLevel() {
    this.levelsData.IS_ACTIVE = true
      this.levelsData.NAME = "";
    this.levelsData.DESCRIPTION = ""

  }



  loadLevels() {
    this.loadingLevels = true;
    let filter = " AND IS_ACTIVE=1"
    this.api.getAllLevels(0, 0, 'value', 'asc', filter).subscribe(localName => {
      this.levels = localName['data'];
      this.data.LEVEL_ID = localName['data'][0]['ID']
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });
  }

  loadQuestionParagraph() {
    this.loadingParagraph = true;

    this.api.getAllShikuyaaParagraphs(0, 0, 'ID', 'desc', "").subscribe(localName => {
      this.paragraphs = localName['data'];
      //   if(data)
      // this.data.PARAGRAPH_ID = localName['data'][0]['ID']
      this.loadingParagraph = false;
    }, err => {
      console.log(err);
      this.loadingParagraph = false;
    });
  }

  addNewQuestionParagraph() {
    this.questionParagraphVisible = true;
    this.questionParagraphTitle = "Create New Question Paragraph";
    this.questionParagraphData = new Questionparagraph();
  }

  questionParagraphClose(): void {
    this.loadQuestionParagraph()
    this.questionParagraphVisible = false;
  }

  get callbackquestionParagraphClose() {
    return this.questionParagraphClose.bind(this);
  }

  loadSubChpater() {
    this.api.getAllChapterHierarchys(0).subscribe(localName => {
      if(localName['count']==0)
      {
        this.message.error('No Chapetrs Found','')
      }
      else{
        this.chaptersNodes = localName['data'];
        // console.log('Chapter:',this.subChaptersNodes)
      }
     
    }, err => {
      console.log(err);
    });

  }
  // loadSubChpater() {
    
  //   this.api.getSubchapterForNode().subscribe(localName => {
  //     this.subChaptersNodes = localName['data'];
  //   }, err => {
  //     console.log(err);
  //   });

  //   // this.api.getAllSubChapter().subscribe(localName => {
  //   //   this.subChaptersNodes = localName['data'];
  //   // }, err => {
  //   //   console.log(err);
  //   // });
  // }

  typeChange(value) {
    console.log(value)
    this.visibleType1 = false
    this.visibleType2 = false
    this.visibleType3 = false
    this.visibleCheck1 = false
    this.visibleCheck2 = false
    this.OPTION_TEXT = ""
    this.OptionList = []


    switch (value) {
      case 1:
        this.visibleType1 = true
        this.visibleCheck1 = true
        break;
      case 2:
        this.visibleType1 = true
        this.visibleCheck2 = true
        break;
      case 3:
        this.visibleType1 = true
        this.visibleType3 = true
        this.visibleCheck2 = true
        break;
    }

    // this.myInputVariableOption.nativeElement.value = null;
    // this.file_Options_URL=null
  }
 
  urlDesc
  urlDe
  urlQue
  urlOpt
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMdd')
  folderNameDesc='DirectionUrl'
  folderNameDess='DescriptionUrl'
  folderNameQue='QuestionUrl'
  folderNameSolu='SolutionUrl'
  folderNameOpt='OptionUrl'
  urllSolu
  onFileSelectedDirection(event) {
    this.isSpinning = true 
       this.isokfileDirection = true 
  

    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // if (!allowed_types.toString().match(event.target.files[0].type)) {
    //   this.isokfileDirection = false
    //   this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
    // }

    // if (this.isokfileDirection) {
    //   this.file_Direction_URL = <File>event.target.files[0];
    //   this.file_Direction_URL['f_key'] = this.directionfkey
    //   this.file_Direction_URL['url_key'] = "LKEY_DIRECTION_URL"
    // }

    console.log(event);
    this.isokfileDescription = true
    this.file_Description_URL = <File>event.target.files[0];
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileDescription = false
      this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    }
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Description_URL.name.split('.').pop();
    // this.urls = 'Profile' + number + "." + fileExt
    this.urlDesc=this.date1 + number+"."+fileExt
    // console.log("urlSolu=",this.urlSolu)
    //console.log(this.fileDataIMAGE)
    this.api.onUploadnew(this.folderNameDesc, this.file_Description_URL, this.urlDesc).subscribe(res => {
      //console.log(res);
      if (res['code'] == "200") {
    this.isSpinning = false 
        this.data.DIRECTION_URL=   this.urlDesc;
       // this.nextProcess(addNew);
       //console.log('url=',this.ATTACHMENT)
      //  localStorage.setItem('desc_url',this.data.OPTIONS)
      
      //  this.submit()

      }
    });
    // if (
    //   event.target.files[0].type == 'image/jpeg' ||
    //   event.target.files[0].type == 'image/jpg' ||
    //   event.target.files[0].type == 'image/png'
    // ) {
    //   this.fileURL = <File>event.target.files[0];
    // } else {
    //   this.message.error(' Please Select Only JPEG/ JPG/ PNG File', '');
    //   this.fileURL = null;
    //   this.data.LOGO_URL= '';

    // }

  }

  onFileSelectedDescription(event) {
    this.isSpinning = true 
    // this.isokfileDirection = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // if (!allowed_types.toString().match(event.target.files[0].type)) {
    //   this.isokfileDirection = false
    //   this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
    // }

    // if (this.isokfileDirection) {
    //   this.file_Direction_URL = <File>event.target.files[0];
    //   this.file_Direction_URL['f_key'] = this.directionfkey
    //   this.file_Direction_URL['url_key'] = "LKEY_DIRECTION_URL"
    // }

    console.log(event);
    this.isokfileDescription = true
    this.file_Direction_URL = <File>event.target.files[0];
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileDescription = false
      this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    }
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Direction_URL.name.split('.').pop();
    // this.urls = 'Profile' + number + "." + fileExt
    this.urlDe=this.date1 + number+"."+fileExt
    // console.log("urlSolu=",this.urlSolu)
    //console.log(this.fileDataIMAGE)
    this.api.onUploadnew(this.folderNameDess, this.file_Direction_URL, this.urlDe).subscribe(res => {
      //console.log(res);
      if (res['code'] == "200") {
        this.isSpinning = false 
        this.data.DESCRIPTION_URL=   this.urlDe;
       // this.nextProcess(addNew);
      //  console.log('url=',this.data.DESCRIPTION_URL)
      //  localStorage.setItem('desc_url',this.data.OPTIONS)
      
      //  this.submit()

      }
    });
  }

  onFileSelectedQuestion(event) {
    this.isSpinning = true 
    this.isokfileQusetion = true

    this.file_Question_URL = <File>event.target.files[0];
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileQusetion = false
      this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    }
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Question_URL.name.split('.').pop();
    // this.urls = 'Profile' + number + "." + fileExt
    this.urlQue=this.date1 + number+"."+fileExt
    // console.log("urlSolu=",this.urlSolu)
    //console.log(this.fileDataIMAGE)
    this.api.onUploadnew(this.folderNameQue, this.file_Question_URL, this.urlQue).subscribe(res => {
      //console.log(res);
      if (res['code'] == "200") {
        this.isSpinning = false 
        this.data.QUESTION_URL=   this.urlQue;
       // this.nextProcess(addNew);
       //console.log('url=',this.ATTACHMENT)
      //  localStorage.setItem('desc_url',this.data.OPTIONS)
      
      //  this.submit()

      }
    });
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // if (!allowed_types.toString().match(event.target.files[0].type)) {
    //   this.isokfileQusetion = false
    //   this.message.error("Only Images are allowed In Question ( JPG | PNG | JPEG)", "");
    // }

    // if (this.isokfileQusetion) {
    //   this.file_Question_URL = <File>event.target.files[0];
    //   this.file_Question_URL['f_key'] = this.questionfkey
    //   this.file_Question_URL['url_key'] = "LKEY_QUESTION_URL"
    // }

  }

  onFileSelectedSolutionUrl(event) {
    this.isSpinning = true 
    // this.isokfileSolutionUrl = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // if (!allowed_types.toString().match(event.target.files[0].type)) {
    //   this.isokfileSolutionUrl = false
    //   this.message.error("Only Images are allowed In Solution ( JPG | PNG | JPEG)", "");
    // }

    // if (this.isokfileSolutionUrl) {
    //   this.file_Solution_URL = <File>event.target.files[0];
    //   this.file_Solution_URL['f_key'] = this.solutionfkey
    //   this.file_Solution_URL['url_key'] = "LKEY_SOLUTION_URL"
    // }

    console.log(event);
    this.isokfileSolutionUrl = true
    this.file_Solution_URL = <File>event.target.files[0];
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileSolutionUrl = false
      this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    }
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Solution_URL.name.split('.').pop();
    // this.urls = 'Profile' + number + "." + fileExt
    this.urllSolu=this.date1 + number+"."+fileExt
    // console.log("urlSolu=",this.urlSolu)
    //console.log(this.fileDataIMAGE)
    this.api.onUploadnew(this.folderNameSolu, this.file_Solution_URL, this.urllSolu).subscribe(res => {
      //console.log(res);
      if (res['code'] == "200") {
        this.isSpinning = false 
        this.data.SOLUTION_IMAGE_URL=   this.urllSolu;
       // this.nextProcess(addNew);
      //  console.log('url=',this.data.DESCRIPTION_URL)
      //  localStorage.setItem('desc_url',this.data.OPTIONS)
      
      //  this.submit()

      }
    });
  }

  onFileSelectedParagraph(event) {

    this.isokfileParagraph = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileParagraph = false
      this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
    }

    if (this.isokfileParagraph) {
      this.file_Paragraph_URL = <File>event.target.files[0];
      this.file_Paragraph_URL['f_key'] = this.paragraphfkey
      this.file_Paragraph_URL['url_key'] = "LKEY_PARAGRAPH_URL"
    }



  }

  onFileSelectedOptions(event) {
    this.isSpinning = true 
    // this.isokfileOption = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // if (!allowed_types.toString().match(event.target.files[0].type)) {
    //   this.isokfileOption = false
    //   this.message.error("Only Images are allowed In Option ( JPG | PNG | JPEG)", "");
    // }
    // this.imgURL = ""
    // if (this.isokfileOption) {
    //   this.file_Options_URL = <File>event.target.files[0];
    //   this.file_Options_URL['f_key'] = this.optionfkey
    //   this.file_Options_URL['url_key'] = "LKEY_OPTION_URL" + "_" + this.iop
    //   this.iop++

    //   var reader = new FileReader();
    //   this.imagePath = event.target.files;
    //   reader.readAsDataURL(event.target.files[0]);
    //   reader.onload = (_event) => {
    //     this.imgURL = reader.result;
    //   }

    // }

    console.log(event);
    this.isokfileOption = true
    this.file_Options_URL = <File>event.target.files[0];
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileOption = false
      this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    }
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Options_URL.name.split('.').pop();
    // this.urls = 'Profile' + number + "." + fileExt
    this.urlOpt=this.date1 + number+"."+fileExt
    // console.log("urlSolu=",this.urlSolu)
    //console.log(this.fileDataIMAGE)
    this.api.onUploadnew(this.folderNameOpt, this.file_Options_URL, this.urlOpt).subscribe(res => {
      //console.log(res);
      if (res['code'] == "200") {
        this.isSpinning = false 
        // this.data.OPTIONS =   this.urlOpt;
       // this.nextProcess(addNew);
      //  console.log('url=',this.data.DESCRIPTION_URL)
      //  localStorage.setItem('desc_url',this.data.OPTIONS)
      
      //  this.submit()

      }
    });

  }

  addOptions() {
  

    if (this.index1 > -1) {
      this.OptionList[this.index1]['OPTION_TEXT'] = this.OPTION_TEXT
      this.OptionList[this.index1]['OPTION_IMAGE_URL'] = this.urlOpt
      this.OptionList[this.index1]['URL_KEY'] = this.OptionList[this.index1]['OPTION_IMAGE_URL']['url_key']
      
      this.index1 = -1
    }
    else {
      var lastElementObject = this.OptionList[this.OptionList.length - 1]

      if (lastElementObject != undefined)
        this.SEQUENCE_NO = lastElementObject['SEQUENCE_NO'] + 1

      if (this.OPTION_TEXT) {
        if (this.isokfileOption) {
          if (this.OptionList.length == 0) {
            this.OptionList = [
              {
                 
                OPTION_TEXT: this.OPTION_TEXT,
                OPTION_IMAGE_URL: this.file_Options_URL==null?"":this.urlOpt,
                URL_KEY: this.file_Options_URL == null ? "" : this.file_Options_URL['url_key'],
                IS_CORRECT: this.IS_CORRECT,
                SEQUENCE_NO: this.SEQUENCE_NO,
                WEIGHTAGE: this.WEIGHTAGE,
                CLIENT_ID: this.api.clientId,
                QUESTION_ID: this.data.ID,
              }
            ];
            this.SEQUENCE_NO++
          }
          else {
            let sameName = this.OptionList.filter(object => {
              return object['OPTION_TEXT'] == this.OPTION_TEXT
            });
            if (sameName.length == 0) {

              this.OptionList = [
                ...this.OptionList,
                {
                   
                  OPTION_TEXT: this.OPTION_TEXT,
                  OPTION_IMAGE_URL: this.file_Options_URL==null?"":this.urlOpt,
                  URL_KEY: this.file_Options_URL == null ? "" : this.file_Options_URL['url_key'],
                  IS_CORRECT: this.IS_CORRECT,
                  SEQUENCE_NO: this.SEQUENCE_NO,
                  WEIGHTAGE: this.WEIGHTAGE,
                  CLIENT_ID: this.api.clientId,
                  QUESTION_ID: lastElementObject.QUESTION_ID,
                }
              ];
              this.i++;
              this.SEQUENCE_NO++

            }
            else {
              this.message.error("Same Option Found", "");
            }
          }

        }
        else {
          this.message.error("Only Images are allowed In Option ( JPG | PNG | JPEG)", "");
        }

      }
      else {
        this.message.error("Please Enter Option Name", "");
      }

    }
    this.OPTION_TEXT = ""
    this.myInputVariableOption.nativeElement.value = '';
    this.file_Options_URL = null
    this.isokfileOption = true
    console.log(this.OptionList)

  }

  deleteOption(data) {
    const index = this.OptionList.indexOf(data);
    this.OptionList.splice(index, 1);
    this.OptionList = this.OptionList.filter(object => {
      return object['ID'] != this.data['ID']
    });
  }

  changeValue() {
    this.OptionList.forEach(element => {
      element['IS_CORRECT'] = false
    });
  }

  editRow(data, i1: number) {
    this.index1 = i1
    this.OPTION_TEXT = data.OPTION_TEXT
    this.file_Options_URL = data.OPTION_IMAGE_URL

  }
  ////

  resetDrawer(questionMasterPage: NgForm) {
    this.data=new Questionmaster();
    // questionMasterPage.form.reset();

    questionMasterPage.form.markAsPristine();
    questionMasterPage.form.markAsUntouched();
  }

  close(questionMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(questionMasterPage);
    questionMasterPage.form.reset();
  
  }
  //save
  save(addNew: boolean,questionMasterPage: NgForm): void {

    //  this.isSpinning = true
    //   if(this.OptionList.length>0)
    //   {
    //   this.OptionList.forEach(element => {
    //     if(this.file_Options_URL)
    //       this.pushLkey(element['OPTION_IMAGE_URL'], this.optionfkey, 'OPTION_LKEY'+element['ID'])
    //       else
    //       {
    //       element['OPTION_IMAGE_URL']=""
    //       this.urlOptionVar['OPTION_LKEY'+element['ID']]=""
    //       }
    //   });
    // }
    // this.urlOptionList.push(this.urlOptionVar)

    // this.data.QUESTION = this.data.QUESTION.replace(/[\n\r\t]/g, '');
    

    if (this.data.DIRECTION == "" )
      this.data.DIRECTION = " "
      else if (this.data.DIRECTION != undefined )
      this.data.DIRECTION = this.data.DIRECTION.replace(/[\n\r\t]/g, '');

    if (this.data.DESCRIPTION == "")
      this.data.DESCRIPTION = " "
      else if (this.data.DESCRIPTION != undefined )
      this.data.DESCRIPTION = this.data.DESCRIPTION.replace(/[\n\r\t]/g, '');


    if (this.data.SOLUTION == "")
      this.data.SOLUTION = " "
      else if (this.data.SOLUTION != undefined)
      this.data.SOLUTION = this.data.SOLUTION.replace(/[\n\r\t]/g, '');

    if (this.data.SOLUTION_VIDEO_URL == "")
      this.data.SOLUTION_VIDEO_URL = " "

      this.OptionList.forEach(element => {
        element['OPTION_TEXT']=element['OPTION_TEXT'].replace(/[\n\r\t]/g, '');
      });

    if (this.file_Direction_URL)
      this.urlList.push(this.file_Direction_URL)
    if (this.file_Description_URL)
      this.urlList.push(this.file_Description_URL)
    if (this.file_Paragraph_URL)
      this.urlList.push(this.file_Paragraph_URL)
    if (this.file_Question_URL)
      this.urlList.push(this.file_Question_URL)
    if (this.file_Solution_URL)
      this.urlList.push(this.file_Solution_URL)


    if (this.OptionList.length > 0) {

      this.OptionList.forEach(element => {
       if(element['OPTION_IMAGE_URL']!=null && element['OPTION_IMAGE_URL']!="")
       this.urlList.push(element['OPTION_IMAGE_URL'])
      });
    }
  
  //  alert(this.urlList.length) 
    // if (this.urlList.length > 0) {
    //   if (this.isokfileDirection && this.isokfileDescription && this.isokfileParagraph && this.isokfileQusetion && this.isokfileSolutionUrl) {
    //     this.isSpinning = true
    //     this.api.onUploadNewMethodBulk(this.urlList)
    //       .subscribe(successCode => {
    //         console.log(successCode['data'])
    //         if (successCode['code'] == 200) {

    //           console.log(this.urlList.length)
    //           if(this.urlList.length==1)
    //           { 
    //             if(this.urlList[0]['url_key']=="LKEY_DIRECTION_URL")
    //             this.data.DIRECTION_URL=successCode['data'][0]['L_KEY']

    //             if(this.urlList[0]['url_key']=="LKEY_DESCRIPTION_URL")
    //             this.data.DESCRIPTION_URL=successCode['data'][0]['L_KEY']

    //             if(this.urlList[0]['url_key']=="LKEY_PARAGRAPH_URL")
    //             this.data.PARAGRAPH_IMAGE_VIDEO_URL=successCode['data'][0]['L_KEY']

    //             if(this.urlList[0]['url_key']=="LKEY_QUESTION_URL")
    //             this.data.QUESTION_URL=successCode['data'][0]['L_KEY']

    //             if(this.urlList[0]['url_key']=="LKEY_SOLUTION_URL")
    //             this.data.SOLUTION_IMAGE_URL=successCode['data'][0]['L_KEY']
    //           }
    //           else
    //           {

    //             this.data.DIRECTION_URL = successCode['data']['LKEY_DIRECTION_URL']
    //             this.data.DESCRIPTION_URL = successCode['data']['LKEY_DESCRIPTION_URL']
    //             this.data.PARAGRAPH_IMAGE_VIDEO_URL = successCode['data']['LKEY_PARAGRAPH_URL']
    //             this.data.QUESTION_URL = successCode['data']['LKEY_QUESTION_URL']
    //             this.data.SOLUTION_IMAGE_URL = successCode['data']['LKEY_SOLUTION_URL']
  
                
    //             Object.keys(successCode['data']).forEach(key => {
    //               console.log(key)
    //               this.OptionList.forEach(element => {
    //                 if (element['OPTION_IMAGE_URL'] != null) {
    //                   console.log(element['URL_KEY'], key)
    //                   if (element['URL_KEY'] == key) {
    //                     console.log("same key found")
    //                     element['OPTION_IMAGE_URL'] = successCode['data'][key]
    //                   }
    //                 }
    //               });
    //             });
    //           }




    //           if (this.data.ID)
    //             this.updateData(false)
    //           else
    //             this.updateData(addNew)
    //         }
    //         else {
    //           console.log(successCode)
    //         }
    //       });
    //   }
    //   else {
    //     if (this.isokfileDirection == false)
    //       this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileDescription == false)
    //       this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileQusetion == false)
    //       this.message.error("Only Images are allowed In Qusetion ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileParagraph == false)
    //       this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
    //     if (this.isokfileSolutionUrl == false)
    //       this.message.error("Only Images are allowed In solution ( JPG | PNG | JPEG)", "");

    //   }
    // }
    // else {
 
        if (this.data.ID)
          this.updateData(false)
        else
          this.updateData(addNew)
      // if (this.isokfileDirection && this.isokfileDescription && this.isokfileParagraph && this.isokfileQusetion && this.isokfileSolutionUrl) {
      //   this.isSpinning = true




      
      // }
      // else {
      //   if (this.isokfileDirection == false)
      //     this.message.error("Only Images are allowed In Direction ( JPG | PNG | JPEG)", "");
      //   if (this.isokfileDescription == false)
      //     this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
      //   if (this.isokfileQusetion == false)
      //     this.message.error("Only Images are allowed In Qusetion ( JPG | PNG | JPEG)", "");
      //   if (this.isokfileParagraph == false)
      //     this.message.error("Only Images are allowed In Paragraph ( JPG | PNG | JPEG)", "");
      //   if (this.isokfileSolutionUrl == false)
      //     this.message.error("Only Images are allowed In solution ( JPG | PNG | JPEG)", "");

      // }
    // }

  }

  updateData(addNew: boolean) {
    console.log("QUESTION_TYPE_ID",this.data.QUESTION_TYPE_ID)
    console.log("LEVEL_ID",this.data.LEVEL_ID)
    console.log("CHAPTER_ID",this.data.CHAPTER_ID)
    console.log("QUESTION",this.data.QUESTION)
    console.log("OPTIONS",this.data.OPTIONS)


    // alert('g')
    this.data.optionList = this.OptionList
    
    console.log("new question url")
    console.log(this.data.optionList)
    this.isOk=true;
    if (this.data.QUESTION_TYPE_ID == 1 || this.data.QUESTION_TYPE_ID == 2)
      this.data.IS_SEQUENCE_MARKING = false
    if (this.data.PARAGRAPH_ID == undefined)
      this.data.PARAGRAPH_ID = 0

      this.data.GROUP_TYPE = 'B'
    // when after save & close array will blank
    console.log(this.data)
    if(this.data.QUESTION_TYPE_ID == undefined && this.data.LEVEL_ID == undefined &&
      this.data.CHAPTER_ID == undefined && this.data.QUESTION == "" && 
      this.OptionList.length == 0  ){
        this.message.error(" Please Fill All Required Fields...", "");
        this.isOk =false 
        // this.isSpinning = false;
    }else

    if(this.data.QUESTION_TYPE_ID==undefined || this.data.QUESTION_TYPE_ID == null){
      this.isOk =false
      this.message.error('Please Select Question Type','')
    }else

    if(this.data.LEVEL_ID==undefined || this.data.LEVEL_ID == null){
      this.isOk =false
      this.message.error('Please Select Level','')
    }else

    if(this.data.CHAPTER_ID==undefined || this.data.CHAPTER_ID == null){
      this.isOk =false
      this.message.error('Please Select Chapter Name','')
    }else

    if(this.data.QUESTION.trim()=="" || this.data.QUESTION == null){
      this.isOk =false
      this.message.error('Please Enter Question','') 
    }else

    if(this.OptionList.length <= 0){
      this.isOk =false
      this.message.error('Please Add Option','') 
    }
      if(this.isOk){  

    //  if(this.data.QUESTION.trim()!="" || this.data.QUESTION != null){
    //  var optionarray = this.OptionList.length;
    //   if(optionarray >= 1){
      var c = 0
        for(var i=0;i< this.OptionList.length; i++){
         
          if(this.OptionList[i].IS_CORRECT==false){
            var b = []
            c ++ 
            for(var j=0;j< this.OptionList.length; j++){
              b.push(this.OptionList.values)
              
            }
            console.log('b:',b.length);
            console.log('c:',c);
          }        
          } 
          if (c == b.length) {
            this.message.error(" Please Select At Least One Option...", "");    
          } else {
            this.display = true
          }
          if (this.display == true){
            // this.OptionList[i].IS_CORRECT== true
            // var a =[]
            // a.push(this.OptionList.length)
            // console.log('a:',a.length);
            // console.log('this.OptionList:',this.OptionList.length);  
            
            if (this.data.ID) {
              this.isSpinning = true;
              // this.data.GROUP_TYPE = 'B'
              console.log("update")
        
              this.api.updateAssessmentQuestion(this.data)
                .subscribe(successCode => {
                  console.log(successCode)
                  if (successCode['code'] == 200) {
                    this.message.success("Question information updated Successfully...", "");
                    this.logtext = 'Update & Close - Question form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Question ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });
                      this.urlList=[]
                    if (!addNew)
                      this.drawerClose();
                    this.isSpinning = false;
        
                   
        
                  }
                  else {
                    this.logtext = 'Update & Close - Question form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Question ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });
                    this.message.error("Failed to update Question information...", "");
                    this.isSpinning = false;
                  }
                });
            }
            else {
              this.isSpinning = true;

              // this.data.GROUP_TYPE = 'B'
              console.log("create",this.data.CHAPTER_ID)
              console.log("OptionList",this.OptionList)
              
              this.api.createAssessmentQuestion(this.data)
                .subscribe(successCode => {
                  console.log(successCode) 
                  if (successCode['code'] == "200") {
                    this.message.success("Question information added successfully...", "");
                    if (!addNew) {
                      this.drawerClose();
                      this.setValues()
                 
                      this.logtext = 'Save & Close - Question form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
        
                    }
                    else {
                      // this.data=new Questionmaster();
                      // this.resetDrawer(questionMasterPage);
                      this.data.ID = undefined
                      this.data.DIRECTION = ""
                      this.data.DESCRIPTION = ""
                      this.data.SOLUTION = ""
                      this.data.SOLUTION_VIDEO_URL = ""
                      this.data.PARAGRAPH_ID = null
        
                      this.data.DIRECTION_URL = ""
                      this.data.DESCRIPTION_URL = ""
                      this.data.QUESTION = ""
                      this.data.QUESTION_URL = ""
                      this.data.SOLUTION_IMAGE_URL = ""
                      this.data.ANSWER_TEXT = ""
                      this.data.PARAGRAPH_IMAGE_VIDEO_URL
                      this.data.OPTIONS = []
                      this.urlList=[]
                      this.setValues()
        
                      this.logtext = 'Save & New - Question form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
                    }
                    this.isSpinning = false;
                  }
                  else {
                    this.message.error("Failed to add Question information...", "");
                    this.isSpinning = false;
                    this.logtext = 'Save & Close - Question form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Question ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });
                  }
                });
            }
         
  // }
}else{

}
      
    //   }  else{
    //   this.message.error(" Please Add Option...", "");
    //   this.isSpinning = false;
    // }
  //  } else{
  //     this.message.error(" Please Add Question...", "");
  //     this.isSpinning = false;
  //   }
  }
  //  else {
  //   this.message.error("Failed to add Question information...", "");
  //   this.isSpinning = false;
  // }
  }



  setValues() {
    this.data.RANDOMIZE_OPTIONS = true
    // this.data.DIRECTION = "No"
    // this.data.DESCRIPTION = "No"
    // this.data.SOLUTION = "No"
    // this.data.SOLUTION_VIDEO_URL = "No"
    this.OPTION_TEXT = ""
    this.OptionList = []
    this.urlList = []
  }


  // close() {
  //   this.drawerClose()
  // }


  getUrl(url: string) {
    console.log(url)
    this.api.getFile(url).subscribe(data => {
      console.log("response of file")
      console.log(data)
      if (data['code'] == 200) {
        const TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        const linkSource = "data:image/jpeg;base64," + base64String;
        let pdfWindow = window.open("")
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + linkSource + "'></iframe>")
      }
      else {
        this.message.error(data['message'], "");

      }
    }, err => {
      console.log(err);
    });

    this.logtext = "VIEW OptionURl - QuestionMaster form KEYWORD [V - QuestionMaster] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  clear(o){
    this.file_Options_URL=null
  }
}
