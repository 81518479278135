import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Videoquestion } from 'src/app/Models/ShikuyaaModels/videoquestion';
import { Videoquestiontype } from 'src/app/Models/ShikuyaaModels/videoquestiontype';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-videoquestion',
  templateUrl: './videoquestion.component.html',
  styleUrls: ['./videoquestion.component.css']
})
export class VideoquestionComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Videoquestion;
  @Input() videoduration: number;
  isSpinning = false
  logtext: string = "";
  data1:Videoquestion = new Videoquestion()
  videoquestions: Videoquestiontype[];


  constructor(public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.loadvideoquestions();

  }

  loadvideoquestions() {
    this.isSpinning = true;
    this.api.getAllVideoquestionType(0, 0, '', '', ' and IS_ACTIVE=1').subscribe(localName => {
      this.videoquestions = localName['data'];
      console.log(this.videoquestions)
      this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }
  sectionChange(sectionId)
  {
    this.data.VIDEO_QUESTION_TYPE_ID = sectionId; 
    let filter=" AND VIDEO_QUESTION_TYPE_ID="+sectionId
    this.api.getAllVideoquestions(0, 0, 'SEQUENCE_NO', 'desc', filter).subscribe(data => {
      if(data['count']>0)
      {
        this.data1 = data['data'][0];
        this.data.SEQUENCE_NO= Number(this.data1.SEQUENCE_NO) + 1
      }
      else
      {
        this.data.SEQUENCE_NO=1
      }
    
    }, err => {
      console.log(err);
    });
  }

  close(): void {
    this.drawerClose();

    this.logtext = 'CLOSED - VideoQuestion form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  save(addNew: boolean): void {

    console.log(this.data)

    if (this.data.VIDEO_QUESTION_TYPE_ID != undefined && this.data.QUESTION_POPUP_SECONDS != undefined && this.data.QUESTION_POPUP_SECONDS.toString() != "" && this.data.SEQUENCE_NO != undefined && this.data.SEQUENCE_NO.toString() != "" && this.data.QUESTION != undefined && this.data.QUESTION != "" && this.data.OPTION_1 != undefined && this.data.OPTION_1 != "" && this.data.OPTION_2 != undefined && this.data.OPTION_2 != "" && this.data.OPTION_3 != undefined && this.data.OPTION_3 != "" && this.data.OPTION_4 != undefined && this.data.OPTION_4 != "" && this.data.ANSWER != undefined && this.data.ANSWER != "") {
      if (this.data.QUESTION_POPUP_SECONDS < this.videoduration){
      this.isSpinning = true;
      if (this.data.ID) {
        this.api.updateVideoQuestion(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Video Question information updated Successfully...", "");
              this.sectionChange(this.data.VIDEO_QUESTION_TYPE_ID)
              this.logtext = 'Update & Close - VideoQuestion form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - VideoQuestion ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

              this.logtext = 'Update & Close - VideoQuestion form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - VideoQuestion ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              this.message.error("Failed to update video Question information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {

        this.api.createVideoQuestion(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
   

              this.message.success("Video Question information added successfully...", "");
              this.sectionChange(this.data.VIDEO_QUESTION_TYPE_ID)
              if (!addNew) {
                this.drawerClose();

                this.logtext = 'Save & Close - VideoQuestion form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - VideoQuestion ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }

              else {
                this.data = new Videoquestion();
                this.logtext = 'Save & New - VideoQuestion form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - VideoQuestion ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add video Question information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - VideoQuestion form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - VideoQuestion ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
          });
      }
      }else {
        this.message.error("Question Popup Time must be less than video duration", "");
      }
    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }

  }


}