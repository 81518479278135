import { Component, OnInit, Input } from '@angular/core';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.css']
})
export class LevelComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Level;

  isSpinning = false
  logtext: string = "";
  userId =sessionStorage.getItem('userId');
  dataList=[]

  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.loadAllLevels()
  }

  loadAllLevels()
  {
    this.api.getAllLevels(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }



  // close(): void {
  //   this.drawerClose();

  //   this.logtext = 'CLOSED - Level form';
  //   this.api.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }

 ////

 resetDrawer(levelMasterPage: NgForm) {
  this.data=new Level();
  // levelMasterPage.form.reset();

  levelMasterPage.form.markAsPristine();
  levelMasterPage.form.markAsUntouched();
}

close(levelMasterPage: NgForm) {
  this.drawerClose();
  this.resetDrawer(levelMasterPage);
  this.logtext = 'CLOSED - Level form';
  this.api.addLog('A', this.logtext, this.api.emailId)
    .subscribe(successCode => {
      if (successCode['code'] == "200") {
        console.log(successCode);
      }
      else {
        console.log(successCode);
      }
    });

}
//save
save(addNew: boolean,levelMasterPage: NgForm): void {
    if (this.data.NAME != undefined && this.data.NAME != "" && this.data.VALUE != undefined && this.data.VALUE.toString() != "" && this.data.SECONDS != undefined && this.data.SECONDS.toString() != "") {


      if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
      {
     

      if (this.data.ID) {

        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
        });

        if(filterData.length>0)
        {
        this.message.error("Already Record Present in Database", "");
        }
        else
      {
        this.isSpinning = true;
        this.api.updateLevel(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Level information updated Successfully...", "");

            this.logtext = 'Update & Close - Level form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Level ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {

            this.logtext = 'Update & Close - Level form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Level ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.message.error("Failed to update level information...", "");
            this.isSpinning = false;
          }
        });
      }


      
      }
      else {
        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase()
        });

        if(filterData.length>0)
        {
        this.message.error("Already Record Present in Database", "");
        }
        else
      {
        this.isSpinning = true;
        this.api.createLevel(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Level information added successfully...", "");

              if (!addNew) {
                this.drawerClose();

                this.logtext = 'Save & Close - Level form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Level ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.data = new Level();
                this.resetDrawer(levelMasterPage);
                this.setValues()
                this.loadAllLevels()
                this.logtext = 'Save & New - Level form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Level ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add level information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - Level form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Level ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
          });
        }
      }
    }
    else
    {
      this.message.error("Please Check Name", "");
    }
    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }

  setValues()
  {
    this.data.DESCRIPTION = " "
    this.data.IS_ACTIVE = true
  }


}