import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CollegeMaster } from 'src/app/Models/AssessmeentModels/collegemaster';
import { ApiService } from 'src/app/Service/api.service';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';

@Component({
  selector: 'app-addcollege',
  templateUrl: './addcollege.component.html',
  styleUrls: ['./addcollege.component.css']
})
export class AddcollegeComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: CollegeMaster = new CollegeMaster();
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  districtsNodes = []
  fileURL: File = null
  fkey = "H83IDzNn"
  lkey = ""
   retriveimgUrl;


  // namepatt=/^[a-zA-Z \-\']+/
  // fileURL:File = null
  constructor(
    public api: ApiService,public api2: ShikuyaaapiService,
    private message: NzNotificationService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.loadAllDistricts()
  }

  loadAllDistricts() {
    this.api2.getCombinedDistricts().subscribe(localName => {
      this.districtsNodes = localName['data'];
    }, err => {
      console.log(err);
    });

  }

  // close(): void {
  //   this.drawerClose();
  // }
  // close(collegeMasterPage: NgForm) {
  //   this.drawerClose();
  //   this.resetDrawer(collegeMasterPage);
  //   collegeMasterPage.form.reset();
  // }
  // resetDrawer(collegeMasterPage: NgForm) {
  //   this.data=new CollegeMaster();
  //   collegeMasterPage.form.markAsPristine();
  //   collegeMasterPage.form.markAsUntouched();

  // } 
   //// Only number
   omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
    //// Only number and dot 
    onlynumdot(event:any) {
      event = (event) ? event : window.event;
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 46 || charCode > 57)) {
          return false;
      }
      return true;
  }
  //save
  resetDrawer(collegeMasterPage: NgForm) {
    this.data=new CollegeMaster();
    // collegeMasterPage.form.reset();

    collegeMasterPage.form.markAsPristine();
    collegeMasterPage.form.markAsUntouched();
  }

  close(collegeMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(collegeMasterPage);
  }
  //save
  save(addNew: boolean,collegeMasterPage: NgForm): void {
   
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.DISTRICT_ID == undefined &&
      this.data.NAME.trim() == '' &&
      this.data.ADDRESS.trim() == '' &&
      this.data.CITY.trim() == '' &&
      this.data.PINCODE == undefined &&
      this.data.LOGO_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error(' Please Fill All Required Fields ', '');
    } else if (this.data.DISTRICT_ID == null || this.data.DISTRICT_ID <=0) {
      this.isOk = false;
      this.message.error(' Please Select District ', '');

    } else if (this.data.NAME == null || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter College Name ', '');

    } else if (this.data.ABBREVATION.trim() == '' || this.data.ABBREVATION ==null) {
      this.isOk = false;
      this.message.error('Please Enter Abbrevation ', '');
      // } else
      // if (!this.namepatt.test(this.data.NAME_MR)) {
      //   this.isOk = false;
      //   this.message.error('Please Enter Valid Marathi Name', '');
    } else if (this.data.ADDRESS == null || this.data.ADDRESS.trim()=='') {
      this.isOk = false;
      this.message.error('Please Enter Address', '');

    } else if (this.data.CITY == null || this.data.CITY.trim()=='') {
      this.isOk = false;
      this.message.error('Please Enter City Name', '');

    } else if (this.data.PINCODE == undefined || this.data.PINCODE == null) {
      this.isOk = false;
      this.message.error('Please Enter Pincode', '');
      
    } else if (
      this.data.LOGO_URL == undefined ||
      this.data.LOGO_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Select Logo', '');
    }
    if (this.fileURL) {
      var fileExt = this.fileURL.name.split('.').pop();
      var lkey = ""
      console.log(this.fileURL)
      console.log(fileExt)
      console.log(this.fkey)

      this.api2.onUploadNewMethod(this.fileURL, fileExt, this.fkey)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == 200) {
            lkey = successCode['data'][0]['L_KEY']
            this.data.LOGO_URL = lkey
            // this.updateData()
          }
          else {
            console.log(successCode)
            this.isSpinning = false;
          }
        });
    }
    if (this.isOk) { 
      //  this.isSpinning=false;

      this.isSpinning = true;
 
      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.LOGO_URL != undefined &&
            this.data.LOGO_URL.trim() != '' 
          ) {
            var arr = this.data.LOGO_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }
          // this.api2
          //   .onUpload('WebsiteBannerImage', this.fileURL, url)
          //   .subscribe((successCode) => {
          //     if (successCode.code == '200') {
                this.data.LOGO_URL = url;
                // appkeys.retriveimgUrl + 'WebsiteBannerImage/' + url;
                if (this.data.ID) {
                  this.api2
                    .updateCollege(this.data)
                    .subscribe((successCode) => {
                      if (successCode['code'] == '200') {
                        this.message.success(
                          'Information updated successfully...',
                          ''
                        );
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to update information...', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api2
                    .createCollege(this.data)
                    .subscribe((successCode) => {
                      if (successCode['code'] == '200') {
                        this.message.success('Information save successfully...', '');
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new CollegeMaster();
                          this.resetDrawer(collegeMasterPage);
                          
                          // this.data.STATUS==true;
                          // this.data.IMG_URL==null;
                          this.fileURL= null;
                          // this.api2.getAllwebsiteBanner(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
                          //   if (data['count']==0){
                          //     this.data.SEQUENCE_NO=1;
                          //   }else
                          //   {
                          //     this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                          //   }
                          // },err=>{
                          //   console.log(err);
                          // })
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to save information...', '');
                        this.isSpinning = false;
                      }
                    });
                }
              // } else {
              //   this.message.error('बॅनर फोटो जोडण्यात अयशस्वी...', '');
              //   this.isSpinning = false;
              // }
            // });
        } else if (this.data.LOGO_URL == null || this.data.LOGO_URL == '') {
          this.message.error(' Please Select Logo...', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api2.updateCollege(this.data).subscribe((successCode) => {
              if (successCode['code'] == '200') {
                this.message.success('Information updated successfully...', '');
                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed to update information...', '');
                this.isSpinning = false;
              }
            });
          } else {
            this.api2.createCollege(this.data).subscribe((successCode) => {
              if (successCode['code'] == '200') {
                this.message.success('Information save successfully...', '');
                if (!addNew) this.drawerClose();
                else {
                  this.data = new CollegeMaster();
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed to save information...', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }

    //  else
    //  {
    //    this.message.error("Please Fill All Required Fields...","");
    //    this.isSpinning = false;

    //  }
  }
  ////
  ////
  //Choose image
  isokfileDescription:boolean
  file_Description_URL=null
  urlDesc
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMdd')
  folderName='CollegeImages'
  onFileSelected(event: any) {
    console.log(event);
    this.isokfileDescription = true
    this.file_Description_URL = <File>event.target.files[0];
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfileDescription = false
      this.message.error("Only Images are allowed In Description ( JPG | PNG | JPEG)", "");
    }
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.file_Description_URL.name.split('.').pop();
    // this.urls = 'Profile' + number + "." + fileExt
    this.urlDesc=this.date1 + number+"."+fileExt
    // console.log("urlSolu=",this.urlSolu)
    //console.log(this.fileDataIMAGE)
    this.api2.onUploadnew(this.folderName, this.file_Description_URL, this.urlDesc).subscribe(res => {
      //console.log(res);
      if (res['code'] == "200") {
        this.data.LOGO_URL=   this.urlDesc;
       // this.nextProcess(addNew);
       //console.log('url=',this.ATTACHMENT)
      //  localStorage.setItem('desc_url',this.data.OPTIONS)
      
      //  this.submit()

      }
    });
    // if (
    //   event.target.files[0].type == 'image/jpeg' ||
    //   event.target.files[0].type == 'image/jpg' ||
    //   event.target.files[0].type == 'image/png'
    // ) {
    //   this.fileURL = <File>event.target.files[0];
    // } else {
    //   this.message.error(' Please Select Only JPEG/ JPG/ PNG File', '');
    //   this.fileURL = null;
    //   this.data.LOGO_URL= '';

    // }
  }
  removeImage() {
    this.data.LOGO_URL='';
    this.fileURL=null;   
    
  }
}
