export class BundleMapping {
    ID:number=0;
    BUNDLE_ID:number=0;
    TYPE:string="";
    START_DATE:any;
    END_DATE:any;
    PRICE:number=0;
    BUNDLE_NAME:string='';
    TYPE_NAME:string='';
    IS_ACTIVE:boolean = true;
    IS_MAPPED:any;
} 

 