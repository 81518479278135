import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Course } from 'src/app/Models/BatchMgmt/course';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { Shikuyaachapter } from 'src/app/Models/ShikuyaaModels/shikuyaachapter';
import { Video } from 'src/app/Models/ShikuyaaModels/video';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
  providers: [DatePipe]
})
export class VideoComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Video;
  @Input() checks:any=''
  @Input() checks1:any=''
  @Input() courseName:any
 
  @Input() catName:any
  isSpinning = false
  logtext: string = "";
  COURSE_ID=0
  chapters: Shikuyaachapter[];
  levels1
  loadingLevels = false;
  loadCategories()
  {
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log("courses")
      // console.log(this.levels)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
  }
  fileDataTHUMBNAIL_URL: File = null
  fkey = "YLnvASlZ"
  isokfile = true
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  dataList = []
  thumbnailImgHeight: number
  thumbnailImgWeight: number
  isheightwidth: boolean
  folderName = "CVideoThumbnail"
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  datas: Video = new Video()
  courseNodes:Course[]
  constructor(public api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) {
  }
  nodess = [
    {
      title: 'parent 1',
      key: '100',
      children: [
        {
          title: 'parent 1-0',
          key: '1001',
          children: [
            { title: 'leaf 1-0-0', key: '10010', isLeaf: true },
            { title: 'leaf 1-0-1', key: '10011', isLeaf: true }
          ]
        },
        {
          title: 'parent 1-1',
          key: '1002',
          children: [{ title: 'leaf 1-1-0', key: '10020', isLeaf: true }]
        }
      ]
    }
  ];
  back()
  {
    this.checks=''
    // this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+this.data.COURSE_SUB_CATEGORY_ID).subscribe(localName => {
    //   this.level2 = localName['data'];
    //   console.log("courses")
    //   console.log(this.levels)
    //   this.loadingLevels = false;
    // }, err => {alert
    //   console.log(err);
    //   this.loadingLevels = false;
    // });

  }
  back1()
  {
    this.checks1=''
    this.checks=''
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log("courses")
      // console.log(this.levels)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });

  }
  ngOnInit() {
   this.loadchapters(1);
    this.loadVideos()
    this.loadCategories()
    
  }

  // loadCourses() {

  //   // this.isSpinning = true;
  //   // this.api.getCombinedCourses().subscribe(localName => {
  //   //   this.courseNodes = localName['data'];
  //   //   console.log("courses")
  //   //   console.log(this.courseNodes)
  //   //   this.isSpinning = false;
  //   // }, err => {
  //   //   console.log(err);
  //   //   this.isSpinning = false;
  //   // });

  //   this.isSpinning = true;
  //   this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2").subscribe(localName => {
  //     this.courseNodes = localName['data'];
  //     console.log("courses")
  //     console.log(this.courseNodes)
  //     this.isSpinning = false;
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });

  // }
  levels: Level[]
  loadCourses(event:any) {

    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+event).subscribe(localName => {
      this.levels = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  ChangeCourse(courseId)
  {
    this.loadchapters(courseId)
  }

  totalRecords
  loadVideos() {
    this.api.getAllVideos(0, 0, '', '', '').subscribe(localName => {
      this.totalRecords=localName['count']
      this.dataList = localName['data']
    
      
    }, err => {
      console.log(err);
    });
  }

  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.fileDataTHUMBNAIL_URL = null
    this.isokfile = true
  }
  checkSeq(event)
  {
    if(this.totals==event)
    this.message.error('Sequnce Number Already There Choose Another One','')
  
  }
  totals
  sectionChange(sectionId) {
    // alert(sectionId)
    this.data.CHAPTER_ID = sectionId;
    let filter = " AND CHAPTER_ID=" + sectionId
    this.api.getAllVideos(0, 0, 'SEQ_NO', 'desc', filter).subscribe(data => {
      console.log(data)
      if (data['count'] > 0) {

        this.datas = data['data'][0];
        this.data.SEQ_NO = Number(this.datas.SEQ_NO) + 1
        this.totals=this.data.SEQ_NO
        console.log(this.data.SEQ_NO)
      }
      else {
        this.data.SEQ_NO = 1
      }

    }, err => {
      console.log(err);
    });


  }

  loadchapters(courseId) {
    this.isSpinning = true;
    this.api.getCombinedShikuyaaChapters2(courseId,1).subscribe(localName => {
      this.chapters = localName['data'];
      console.log(this.chapters)
       this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }



  close(): void {
    this.drawerClose();

    this.logtext = 'CLOSED - Video form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }


  similarData() {
    if (this.data.ID) {

      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['CHAPTER_ID']==this.data.CHAPTER_ID
      });

      if (filterData.length > 0) {
        this.message.error("Name Already Present in Chapter", "");
        return false
      }
      else {
        return true
      }
    }
    else {
      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['CHAPTER_ID']==this.data.CHAPTER_ID
      });

      if (filterData.length > 0) {
        this.message.error("Name Already Present in Chapter", "");
        return false
      }
      else {
        return true
      }
    }
  }

  // save(addNew: boolean): void {
  //   console.log(this.data)
  //   if (this.data.CHAPTER_ID != undefined && this.data.NAME != undefined && this.data.NAME != "" && this.data.URL_KEY!=undefined && this.data.URL_KEY!="" && this.data.DURATION!=undefined && this.data.DURATION.toString()!="" ) {
  //     if (/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true) {

  //       var returnData = this.similarData()
  //       if (this.isokfile) {
  //         if (returnData) {
  //           this.isSpinning = true;
  //           if (this.fileDataTHUMBNAIL_URL) {


  //             var fileExt = this.fileDataTHUMBNAIL_URL.name.split('.').pop();
  //             var lkey = ""
  //             this.api.onUploadNewMethod(this.fileDataTHUMBNAIL_URL, fileExt, this.fkey)
  //               .subscribe(successCode => {
  //                 console.log(successCode)
  //                 if (successCode['code'] == 200) {
  //                   lkey = successCode['data'][0]['L_KEY']
  //                   this.data.THUMBNAIL_URL = lkey
  //                   this.updateData(addNew)
  //                 }
  //                 else {
  //                   console.log(successCode)
  //                 }
  //               });

  //           }
  //           else {

  //             this.isSpinning = true;
  //             if (this.data.ID)
  //               this.updateData(false)
  //             else {
  //               this.data.THUMBNAIL_URL = " "
  //               this.updateData(addNew)
  //             }
  //           }
  //         }
  //       }
  //       else {
  //         this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
  //       }
  //     }
  //     else
  //     {
  //       this.message.error("Name is invalid value", "");


  //     }
  //   }
  //   else {
  //     this.message.error("Please Fill All Required Fields", "");
  //   }

  // }

  save(addNew: boolean): void {
    console.log(this.data)
    if (this.data.CHAPTER_ID != undefined && this.data.NAME != undefined && this.data.NAME != "" && this.data.URL_KEY != undefined && this.data.URL_KEY != "" && this.data.DURATION != undefined && this.data.DURATION.toString() != "" && this.data.SEQ_NO != undefined && this.data.SEQ_NO > 0 ) {
      if (/^[a-zA-Z0-9 &$]*$/.test(this.data.NAME) == true) {

        var returnData = this.similarData()
        if (this.isokfile) {
       
            this.isSpinning = true;
            
            if (this.data.ID) {

             
              console.log("in update")
              this.api.updateVideo(this.data)
                .subscribe(successCode => {
                  console.log(successCode)
                  if (successCode['code'] == "200") {
                    console.log(successCode)
                    this.message.success("Video information updated Successfully...", "");
                    this.sectionChange(this.data.CHAPTER_ID)
                    this.logtext = 'Update & Close - Video form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Video ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });

                    if (!addNew)
                      this.drawerClose();
                    this.isSpinning = false;
                  }
                  else {

                    this.logtext = 'Update & Close - Video form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Video ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });

                    this.message.error("Failed to update video information...", "");
                    this.isSpinning = false;
                  }
                });
            }
            else {

             
              if (returnData) {
              this.api.createVideo(this.data)
                .subscribe(successCode => {
                  console.log(successCode)

                  if (successCode['code'] == "200") {
                    this.message.success("Video information added successfully...", "");
                    this.sectionChange(this.data.CHAPTER_ID)
                    if (!addNew) {
                      this.drawerClose();

                      this.logtext = 'Save & Close - Video form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Video ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
                    }

                    else {
                      //  this.data = new Video();
                      this.data.NAME = ""
                      this.data.DESCRIPTION = ""
                      this.data.TYPE = 'F';
                      this.data.IS_ACTIVE = true;
                      this.data.THUMBNAIL_URL = ""
                      this.data.THUMBNAIL_URL_CONVERTED = ""
                      this.data.DURATION = undefined
                      this.data.URL_KEY = ""
                      this.data.VIDEO_TYPE = "V"
                      this.data.SEQ_NO = undefined


                      this.logtext = 'Save & New - Video form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Video ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });

                    }
                    this.isSpinning = false;
                  }
                  else {
                    this.message.error("Failed to add video information...", "");
                    this.isSpinning = false;
                    this.logtext = 'Save & Close - Video form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Video ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });

                  }
                });
              }
            }
          
        }
        else {
          this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
        }
      }
      else {
        this.message.error("Name is invalid value", "");


      }
    }
    else {
      if (this.data.CHAPTER_ID == undefined)
        this.message.error("Please Select Chapter Name", "");
      else if (this.data.NAME == undefined || this.data.NAME == "")
        this.message.error("Please Enter Name", "");
      else if (this.data.URL_KEY == undefined || this.data.URL_KEY == "")
        this.message.error("Please Enter Url Key", "");
      else if (this.data.DURATION == undefined || this.data.DURATION.toString() == "")
        this.message.error("Please Enter Duration", "");
      else if (this.data.SEQ_NO == undefined || this.data.SEQ_NO.toString() == "")
        this.message.error("Please Enter Sequence Number", "");
    
    }

  }

  genarateKeyThumbnail_URL() {
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.fileDataTHUMBNAIL_URL.name.split('.').pop();
    var url = this.date1 + number + "." + fileExt
    console.log("dsf")
    console.log(this.fileDataTHUMBNAIL_URL)
    this.api.onUpload(this.folderName, this.fileDataTHUMBNAIL_URL, url)
    //  this.data.THUMBNAIL_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
    this.data.THUMBNAIL_URL = url;

    return this.data.THUMBNAIL_URL
  }

  updateData(addNew: boolean) {
    if (this.data.DESCRIPTION == "")
      this.data.DESCRIPTION = " "
    if (this.data.ID) {
      this.api.updateVideo(this.data)

        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.message.success("Video information updated Successfully...", "");
            addNew=false
            this.logtext = 'Update & Close - Video form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Video ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                 
                }
                else {
                  console.log(successCode);
                }
              });

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {

            this.logtext = 'Update & Close - Video form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Video ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                  
                }
                else {
                  console.log(successCode);
                }
              });

            this.message.error("Failed to update video information...", "");
            this.isSpinning = false;
          }
        });
    }
    else {
      this.api.createVideo(this.data)
        .subscribe(successCode => {
          console.log(successCode)

          if (successCode['code'] == "200") {
            this.message.success("Video information added successfully...", "");
            addNew=false
            if (!addNew) {
              this.drawerClose();

              this.logtext = 'Save & Close - Video form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Video ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }

            else {
              this.data = new Video();
              this.logtext = 'Save & New - Video form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Video ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add video information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - Video form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Video ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

          }
        });
    }
  }
url
  onFileSelectedTHUMBNAIL_URL(event) {
    this.isokfile = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    console.log(event.target.files[0])
    //10485760
    const isLt2M = event.target.files[0].size <= 10485760;
    console.log(event.target.files[0].size)
    console.log(isLt2M)

    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isokfile = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {                          
          // this.showing=false
        }, 5000);
       
      }
  
      if (this.isokfile) {
        this.fileDataTHUMBNAIL_URL = <File>event.target.files[0];
       
        if (this.fileDataTHUMBNAIL_URL) {
       
            var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.fileDataTHUMBNAIL_URL.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.url=this.date1+number+"."+fileExt
            console.log("urlpara=",this.url)
            //console.log(this.fileDataIMAGE)
            this.api.onUploadnew(this.folderName, this.fileDataTHUMBNAIL_URL, this.url).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.fileDataTHUMBNAIL_URL=this.url
                this.data.THUMBNAIL_URL=this.url
        
              }
            });
  
         
      }
    }
    else {
      // this.myInputVariable.nativeElement.value = null;
      if (!isLt2M){
      
        setTimeout(() => {                          
         
        }, 5000);
       
      }
       
  
  
    }
  
  }

  }

  clear() {
    this.fileDataTHUMBNAIL_URL = null
  }
}