import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CompanyModel } from 'src/app/Models/ShikuyaaModels/company-model';
@Component({
  selector: 'app-company-masters',
  templateUrl: './company-masters.component.html',
  styleUrls: ['./company-masters.component.css']
})
export class CompanyMastersComponent implements OnInit {

  formTitle = "Manage Company";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
   logtext:string = "";

  columns: string[][] =[ ["NAME","Name"],["SEQUENCE_NO","Sequence No"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: CompanyModel = new CompanyModel();
  constructor(private api: ApiService,private message: NzNotificationService) { }
  ngOnInit() {
    this.search();

	this.logtext = 'OPENED - Company form KEYWORD[O - Company] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);

  }
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
	
	this.logtext = 'Filter Applied - Company form"+ sort +" "+this.sortKey +" KEYWORD [F - Company] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

	this.logtext = 'Filter Applied - Company form "+ likeQuery +" KEYWORD [F - Company] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

    this.api.getAllCompanys(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Company";
    this.drawerData = new  CompanyModel();
    this.drawerVisible = true;

	this.logtext = 'ADD - Company form KEYWORD [A - Company] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });
      this.api.getAllCompanys(0, 0, '', '', '').subscribe(data => {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }, err => {
        console.log(err);
      });
      if( this.totalRecords>0)
      {
        this.drawerData.SEQUENCE_NO =  this.dataList[0]['SEQUENCE_NO']
        this.drawerData.SEQUENCE_NO = Number(this.dataList[0]['SEQUENCE_NO'])+1
      }
      else
      {
        this.drawerData.SEQUENCE_NO = 1
      }
  }
  edit(data:  CompanyModel): void {

    this.drawerTitle = "Update Company";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

	this.logtext = 'EDIT - Company form KEYWORD [E - Company] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });


  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
