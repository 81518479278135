import { Component, OnInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CollegeMaster } from 'src/app/Models/AssessmeentModels/collegemaster';
import { ApiService } from 'src/app/Service/api.service';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';

@Component({
  selector: 'app-collegelist',
  templateUrl: './collegelist.component.html',
  styleUrls: ['./collegelist.component.css']
})
export class CollegelistComponent implements OnInit {

  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: CollegeMaster = new CollegeMaster();
  formTitle = " College List";
  dataList :any []= [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", " College Name "],["CITY"," City Name "]]
  drawerDataBranchMapping:string
  drawerVisibleBranchMapping: boolean;
  drawerTitleBranchMapping: string;
  constructor( private api: ApiService, private api2: ShikuyaaapiService) { }

  ngOnInit(): void {
  this.loadingRecords = false;
  this.search();

  }

  keyup(event:any) {
    this.search();
  }  
  
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api2.getAllStudentColleges(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    //   if(this.totalRecords==0){
    //     data.SEQUENCE_NO=1;
    //   }else{
    //     data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
    //   }
    // }, err => {
    //   console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = " Add New College ";
    this.drawerData = new CollegeMaster();
    // this.api2.getAllwebsiteBanner(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
    //   if (data['count']==0){
    //     this.drawerData.SEQUENCE_NO=1;
    //   }else
    //   {
    //     this.drawerData.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
    //   }
    // },err=>{
    //   console.log(err);
    // })
    // this.drawerData.STATUS=true;
    this.drawerVisible = true;
  }
  edit(data: CollegeMaster): void {
    this.drawerTitle = " Update College Information ";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'asc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  mapBranches(collegedata: CollegeMaster)
  {
    this.api2.getBranchMapping(collegedata.ID).subscribe(data => {
      console.log(data);
     this.drawerDataBranchMapping = data['data'];
      }, err => {
      console.log(err);
    }); 
    this.drawerTitleBranchMapping = "Assign Branches for " + collegedata.ABBREVATION + "";
    this.drawerData = Object.assign({}, collegedata);
    this.drawerVisibleBranchMapping = true;
  }

  get closeCallbackBranchMapping() {
    return this.drawerCloseBranchMapping.bind(this);
  }
  
  drawerCloseBranchMapping(): void {
    this.search();
    this.drawerVisibleBranchMapping = false;
  }
  
}
