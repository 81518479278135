export class Collegedocument {
    ID:number;
    CLIENT_ID:number;
    COLLEGE_ID:number
    NAME:string;
    DOCUMENT_TYPE_ID:number
    DOCUMENT_URL:string
    IS_ACTIVE:boolean=true
    USERS=[]
}
