import { Component, OnInit, ViewChild } from '@angular/core';
import { Assignment2 } from 'src/app/Models/AssignmentModule/assignment';
import { ApiService } from 'src/app/Service/api.service';
import { AssignmentComponent2 } from '../assignment/assignment2.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-assignments2',
  templateUrl: './assignments2.component.html',
  styleUrls: ['./assignments2.component.css']
})
export class AssignmentsComponent2 implements OnInit {  
  formTitle = "Manage Assessment";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  collegeId=sessionStorage.getItem("collegeId")

  logtext: string = "";

  columns: string[][] = [["GROUP_NAME", "Group Name"], ["NAME", "Name"], ["POSITIVE_MARKS", "Positive Marks"], ["NEGATIVE_MARKS", "Negative Marks"], ["TOTAL_QUESTIONS", "Total Questions"],['TOTAL_TIME','Total Time']]
  columns1: string[][] = [["GROUP_NAME", "Group Name"], ["NAME", "Name"], ["POSITIVE_MARKS", "Positive Marks"], ["NEGATIVE_MARKS", "Negative Marks"], ["TOTAL_QUESTIONS", "Total Questions"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Assignment2 = new Assignment2();

  @ViewChild(AssignmentComponent2, { static: false }) assignmentAdd: AssignmentComponent2;
  pageSize2=10
  
  constructor(public api: ApiService,private message: NzNotificationService) { 
   
  }
  ngOnInit() {
    // if (this.userId == null || this.userName == null || this.roleId == null || this.collegeId == null) {
    //   this.api.logoutForSessionValues()
    // }
    // else {
      this.search();
      this.logtext = "OPENED - Assesments form KEYWORD[O - Assesments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    // }

  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Assesments form "+ sort +" "+this.sortKey +" KEYWORD [F - Assesments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      console.log(likeQuery)
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      this.logtext = "Filter Applied - Assesments form "+ likeQuery +" KEYWORD [F - Assesments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }
    this.api.getAllMocks(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
      if(err['ok']==false)
      this.message.error("Server Not Found", "");
    });
  }
  
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Assessment";
    this.drawerData = new Assignment2();
    this.assignmentAdd.current = 0
    this.drawerVisible = true;

    this.logtext = "ADD - Assesment form KEYWORD [A - Assesment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  edit(data: Assignment2): void {
    console.log(data)
    this.drawerTitle = "Update Assessment (" + data.NAME + ")";
    this.drawerData = Object.assign({}, data);

    if (this.drawerData.PLATFORM == 'B')
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
    { label: 'Web', value: 'W', checked: true }, { label: 'Desktop', value: 'D', checked: true }]
  else if (this.drawerData.PLATFORM == 'M')
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
    { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
  else if (this.drawerData.PLATFORM == 'W')
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
    { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
  else
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: false },
    { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: true }]

    this.drawerVisible = true;

    this.assignmentAdd.current = 0
    //  this.assignmentAdd.loadAssignmentDeatils()

    this.logtext = "EDIT - Assesment form KEYWORD [E - Assesment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }
  getTotal(timeinseconds) {
    return this.api.getSecondsToHm(timeinseconds)
  }

  mapping(data: Assignment2) {
    this.drawerData = Object.assign({}, data);
    console.log(this.drawerData);
    if (this.drawerData.PLATFORM == 'B')
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
    { label: 'Web', value: 'W', checked: true }, { label: 'Desktop', value: 'D', checked: true }]
  else if (this.drawerData.PLATFORM == 'M')
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
    { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
  else if (this.drawerData.PLATFORM == 'W')
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
    { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
  else
    this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: false },
    { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: true }]
   
    this.drawerTitle = "Map Assesment (" + this.drawerData.NAME + ")";
   
    this.assignmentAdd.current = 3
    this.assignmentAdd.NAME = this.drawerData.NAME
 
   
    this.assignmentAdd.assignmentId = this.drawerData.ID
    this.assignmentAdd.getDeatilsData()
    this.drawerVisible = true;
  }

  drawerClose(): void {
  
    this.drawerVisible = false;
    this.search();
  }
}