import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Collegecontact } from 'src/app/Models/CollegeOnboarding/collegecontact';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { DocumentsComponent } from '../../Documents/documents/documents.component';
import { ContactdetailComponent } from '../contactdetail/contactdetail.component';

@Component({
  selector: 'app-contactdetails',
  templateUrl: './contactdetails.component.html',
  styleUrls: ['./contactdetails.component.css']
})
export class ContactdetailsComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() collegeId: number;
  @Input() name: string;
  ContactDetaildrawerData: Collegecontact = new Collegecontact();
  ContactDetaildrawerTitle: string = ""
  ContactDetaildrawerVisible: boolean

  DocumentsdrawerTitle: string = ""
  DocumentsdrawerVisible: boolean
  dataList: Collegecontact[]
  filterQuery: string = ""
  logtext: string = "";
  userId = Number(this.cookie.get('userId'));
  loadingContacts = false
  searchText: string = ""
  columns: string[][] = [["NAME", "Name"], ['MOBILE_NUMBER', 'Number'], ['EMAIL_ID', 'Number'],['DESIGNATION_NAME','Designation Name']]
  @ViewChild(DocumentsComponent, { static: false }) collegeDocument: DocumentsComponent;
  @ViewChild(ContactdetailComponent, { static: false }) collegeContact: ContactdetailComponent;

  constructor(public api: ApiService, private cookie: CookieService) { }

  ngOnInit() {

  }

  search(collegeId) {

    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }

    this.loadingContacts = true
    var filter = ""
    this.filterQuery = "AND COLLEGE_ID=" + collegeId
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery
    console.log(filter)

    this.logtext = "Filter Applied - CollegeContact form " + filter + " KEYWORD [F - CollegeContact] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });



    this.api.getAllCollegeContactDetails(0, 0, '', '', filter).subscribe(data => {
      this.dataList = data['data'];
      this.loadingContacts = false
    }, err => {
      console.log(err);
    });
  }


  add() {
    this.ContactDetaildrawerTitle = "Create New Contact";
    this.ContactDetaildrawerVisible = true;
    this.ContactDetaildrawerData = new Collegecontact();
   this.collegeContact.disableValue=false
    this.logtext = "ADD - CollegeContact form KEYWORD [A - CollegeContact] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  edit(data: Collegecontact) {
    console.log("dart")
    console.log(data)
    let roleIds = ""
    let filter = " AND COLLEGE_CONTACT_ID=" + data.ID
    this.api.getAllUserMappingData(0, 0, '', '', filter).subscribe(data1 => {
      console.log(data1)
      data1['data'].forEach(element => {
        roleIds = roleIds + element['ROLE_ID'] + ","
      });
      let userId1 = roleIds.substring(0, roleIds.length - 1)
      var arrOfRoleId = userId1.split(',').map(function (item) {
        return parseInt(item, 10);
      });

      if (userId1 == "") {
        data.ROLE_IDS = []
      }
      else {
        data.ROLE_IDS = arrOfRoleId
      }

      this.ContactDetaildrawerTitle = "Update Contact";
      this.ContactDetaildrawerData = Object.assign({}, data);
      this.ContactDetaildrawerVisible = true;
      this.logtext = "EDIT - CollegeContact form KEYWORD [E - CollegeContact] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    }, err => {
      console.log(err);
    });

  }


  ContactDetaildrawerClose(): void {
    console.log(this.collegeId)
    this.ContactDetaildrawerVisible = false
    this.search(this.collegeId)
  }

  get ContactDetailcloseCallback() {
    return this.ContactDetaildrawerClose.bind(this);
  }


  DocumentsdrawerClose(): void {
    console.log(this.collegeId)
    this.DocumentsdrawerVisible = false
    this.search(this.collegeId)
  }

  get DocumentscloseCallback() {
    return this.DocumentsdrawerClose.bind(this);
  }


  close() {
    this.drawerClose()
  }

  manageDocuments(collegeId, name) {
    this.logtext = "ManageDocuments - CollegeContact form KEYWORD [E - CollegeContact] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    this.getData(collegeId, name)

  }


  getData(collegeId, name) {
    console.log(collegeId, name)
    console.log("College contact data")
    this.DocumentsdrawerTitle = "Manage Documents For " + name
    this.DocumentsdrawerVisible = true
    this.collegeId = collegeId
    this.collegeDocument.search(collegeId)
    //this.search(collegeId)
  }

}
