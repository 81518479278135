import { Component, OnInit } from '@angular/core';
import { Region } from 'src/app/Models/CollegeOnboarding/region';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.css']
})
export class RegionsComponent implements OnInit {

  formTitle = "Manage Regions";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataList1 = [];

  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId=sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext:string = "";

  columns: string[][] =[ ["STATE_NAME","State Name"],["NAME","Name"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Region = new Region();
  pageSize2=10

  constructor(public api: ApiService,public api2: ShikuyaaapiService, private cookie: CookieService) { }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api2.logoutForSessionValues()
    }
    else
    {
    this.search();
     
	this.logtext = "OPENED - Regions form KEYWORD[O - Regions] ";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
        }
  }
  
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
	
	this.logtext = "Filter Applied - Regions form "+ sort +" "+this.sortKey +" KEYWORD [F - Regions] ";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);

      this.logtext = "Filter Applied - Regions form "+ likeQuery +" KEYWORD [F - Regions] ";
      this.api2.addLog('A',this.logtext,this.api2.emailId)
              .subscribe(successCode => {
                console.log(successCode)
                if (successCode['code'] == "200") {
                 console.log(successCode);
                }
                else {
        console.log(successCode);
                }
          });
    }



    this.api2.getAllRegions(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];      
    }, err => {
      console.log(err);
    });

    this.api2.getAllRegions(0, 0, 'ID', 'desc', '').subscribe(data => {
   
      this.dataList1 = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Region";
    this.drawerData = new  Region();
    this.drawerVisible = true;
 
	this.logtext = "ADD - Region form KEYWORD [A - Region] ";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

  }

  
  edit(data:  Region): void {

    this.drawerTitle = "Update Region";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

	this.logtext = "EDIT - Region form KEYWORD [E - Region] ";
	this.api2.addLog('A',this.logtext,this.api2.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });


  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

 
}