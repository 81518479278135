export class Coupan {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    DESCRIPTION:string;
    START_DATE:string;
    EXPIRY_DATE:string;
    MAX_USES_COUNT:number;
    COUPON_CODE:string;
    COUPON_VALUE:number;
    PERUSER_MAX_COUNT:number;
    IS_LEARNER_SPECIFIC:boolean=false;
    COUPON_VALUE_TYPE:string="A";
    COUPON_MAX_VALUE:number;
    MIN_CART_AMOUNT:number;
    COUPON_TYPE_ID:number
    
}
