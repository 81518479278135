import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AssessmentChapterMaster } from 'src/app/Models/AssessmeentModels/chapter';
import { Shikuyaachapter } from 'src/app/Models/ShikuyaaModels/shikuyaachapter';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addchapter',
  templateUrl: './addchapter.component.html',
  styleUrls: ['./addchapter.component.css'] 
})
export class AddchapterComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: AssessmentChapterMaster = new AssessmentChapterMaster();
  @Input()
  drawerVisible: boolean = false;
  @Input() drawerData
  isSpinning = false;
  isSpinning2 = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  @Input () parentChapters : AssessmentChapterMaster[] 
  chapters: Shikuyaachapter[]
  fileURL: File = null
  fkey = "H83IDzNn"
  lkey = ""
  dependstatus:any
   listdata1 = []
  @ Input() listdata2 = []
  // imgurl= appkeys.retriveimgUrl;


  // namepatt=/^[a-zA-Z \-\']+/
  // fileURL:File = null
  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.ChangeParentName()
    this.dependChapter()
    this.isSpinning2 = true;
    this.getallorg1()
    // this.getallorg2()
  }

  ChangeParentName() {
    this.api.getAllChapterHierarchys(1).subscribe(localName => {
      if(localName['count']==0)
      {
        this.message.error('No Chapetrs Found','')
      }
      else{
        this.chapters = localName['data'];
        console.log('Chapter:',this.chapters)
      }
     
    }, err => {
      console.log(err);
    });

  }

  dependChapter() {
    this.api.getAllChapterHierarchys(1).subscribe(localName => {
      // alert()
      this.parentChapters = localName['data']; 
    }, err => {
      console.log(err);
    });

  }

  // close(): void {
  //   this.drawerClose();
  // }

  close(chapterMasterPage: NgForm) {
    this.drawerClose();
    // this.resetDrawer(chapterMasterPage); 
    chapterMasterPage.form.reset();
    chapterMasterPage.form.markAsUntouched();
    this.api.getAllChapterHierarchys(1).subscribe(localName => {
      // alert()
      this.parentChapters = localName['data']; 
    }, err => {
      console.log(err);
    });
    // this.drawerData.PARENT_ID = undefined
  
  }
  resetDrawer(chapterMasterPage: NgForm) {
    this.data=new AssessmentChapterMaster();
    chapterMasterPage.form.markAsPristine();
    chapterMasterPage.form.markAsUntouched();
  }
   //// Only number
   omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
    //// Only number and dot 
    onlynumdot(event:any) {
      event = (event) ? event : window.event;
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 46 || charCode > 57)) {
          return false;
      }
      return true;
  }

  ChangeDepend(event){
    this.dependstatus = event;
    console.log('Depend:',this.dependstatus);
    
  }

   /////check double record
   getallorg1() {
    this.api.getAllAssessmentChapters(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1)
      }
    }, err => {
      console.log(err);
    });
  }

  // getallorg2() {
  //   this.api.getAllAssessmentChapters(0, 0, 'ID', 'desc', " AND ID !=" + this.data.ID).subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.listdata2 = data['data'];
  //       console.log(this.listdata2)
  //     }
  //   }, err => {
  //     console.log(err);
  //   });
  // }
  //save
  

   save(addNew: boolean,chapterMasterPage: NgForm): void {            
    this.isSpinning = false;
    this.isOk=true;
    if (
      this.data.PARENT_ID == undefined &&
      this.data.NAME.trim() == '' &&
      this.data.SEQUENCE_NO != undefined
    ) {
      this.isOk = false;
      this.message.error(' Please Fill All Required Fields ', '');
    } else if (this.data.PARENT_ID == undefined || this.data.PARENT_ID < 0) {
      this.isOk = false;
      this.message.error(' Please Select Parent Name ', '');

    } else if (this.data.PARENT_ID ==0 && (this.data.NAME == null || this.data.NAME.trim() == '')) {
      this.isOk = false;
      this.message.error('Please Enter Parent Name ', '');
  
    } else if (this.data.PARENT_ID >=1 && (this.data.NAME == null || this.data.NAME.trim() == '')) {
      this.isOk = false;
      this.message.error('Please Enter Chapter Name ', '');
  
    } else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO <=0) {
      this.isOk = false;
      this.message.error(' Please Enter Sequence Number ', '');
        
    } else if (this.dependstatus == true && (this.data.DEPENDED_CHAPTER_ID == null || this.data.DEPENDED_CHAPTER_ID <=0)) {
      this.isOk = false;
      this.message.error(' Please Select Dependant Chapter Name ', '');
}
if(this.isOk)
{
  // this.isSpinning=false; 
  // this.data.LATITUDE = this.latitude.toString();
  // this.data.LONGITUDE   = this.longitude.toString();
  this.isSpinning=true; 
  if (this.data.ID) {
    var chapterData2 = this.listdata2.filter(obj => {
      return obj.NAME == this.data.NAME;
    });
    console.log('this.listdata2:',this.listdata2);

    console.log('Name:',chapterData2);
    console.log('Name:',chapterData2.length);
    
    if (chapterData2.length == 0) {
      if(this.data.PARENT_ID != this.data.ID){
    this.api.updateAssessmentChapter(this.data)
    .subscribe(successCode => {
      if(successCode.code=="200")
      {
        this.message.success(' Information updated successfully...', '');
        if(!addNew)
          this.drawerClose();
          this.isSpinning = false;
          this.drawerData.PARENT_ID = 0;
      }   
      else
      {
        this.message.error(' Failed to update information...', '');
        this.isSpinning = false;
      }
    });
  }
  else {
    this.message.error(" Parent Name and Chapter Name Is Same. Please Enter Other Chapter Name or Select Other Parent Name...", "");
    this.isSpinning = false;
  }
} else {
    this.message.error(" Chapter Name Already Exist. Please Enter Other Chapter Name...", "");
    this.isSpinning = false;
  }
  }
  else {
    var chapterData = this.listdata1.filter(obj => {
      return obj.NAME == this.data.NAME;
    });
    console.log('this.listdata1:',this.listdata1);
    
    console.log('Name2:',chapterData);
    console.log('Name2:',chapterData.length);
    
    console.log(this.data);

    if (chapterData.length == 0) {
  
      this.api.createAssessmentChapter(this.data)
      .subscribe(successCode => {
        if(successCode.code=="200")
        {
          this.message.success(' Information save successfully...', '');
          if(!addNew)
         this.drawerClose();
            else
            {
              this.data=new AssessmentChapterMaster();
              this.resetDrawer(chapterMasterPage);
              // this.data.img= '';
              this.api.getAllChapterHierarchys(1).subscribe(localName => {
                // alert()
                this.parentChapters = localName['data']; 
              }, err => {
                console.log(err);
              });
              this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
                // if (data['count']==0){
                //   this.data.SEQUENCE_NO=1;
                // }else
                // {
                //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                // }
              },err=>{
                console.log(err);
              })
            }
            this.isSpinning = false;
          }
           else
           {
            this.message.error(' Failed to save information...', '');
            this.isSpinning = false;
           }
          });
        } else {
          this.message.error("Chapter Name Already Exist. Please Enter Other Chapter Name...", "");
          this.isSpinning = false;
        }
        }
}
  }
  ////
  ////
  //Choose image
  // onFileSelected(event: any) {
  //   console.log(event);

  //   if (
  //     event.target.files[0].type == 'image/jpeg' ||
  //     event.target.files[0].type == 'image/jpg' ||
  //     event.target.files[0].type == 'image/png'
  //   ) {
  //     this.fileURL = <File>event.target.files[0];
  //   } else {
  //     this.message.error(' Please Select Only JPEG/ JPG/ PNG File', '');
  //     this.fileURL = null;
  //     this.data.LOGO_URL= '';

  //   }
  // }
  // removeImage() {
  //   this.data.LOGO_URL='';
  //   this.fileURL=null;   
    
  // }
  
}
