import { Input, ViewChild } from "@angular/core";
import { Component, ElementRef, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Level } from "src/app/Models/MasterSyllabus/level";
import { Questionmaster } from "src/app/Models/MasterSyllabus/questionmaster";
import { Questions } from "src/app/Models/MasterSyllabus/questions";
import { Questiontype } from "src/app/Models/MasterSyllabus/questiontype";
import { Shikuyaaquestion } from "src/app/Models/ShikuyaaModels/shikuyaaquestion";
import { ApiService } from "src/app/Service/api.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-questionmasterimporter",
  templateUrl: "./questionmasterimporter.component.html",
  styleUrls: ["./questionmasterimporter.component.css"],
})
export class QuestionmasterimporterComponent implements OnInit {
  formTitle = "Question Importer";
  data: Questionmaster = new Questionmaster();
  filetxt: File = null;
  loadingLevels = false;
  constructor(
    public cookie: CookieService,
    public api: ApiService,
    public _http: HttpClient,
    private message: NzNotificationService,
    private sanitizer: DomSanitizer
  ) {}
  fileContent: string = "";
  levels: Level[];
  levels1: Level[];
  subChaptersNodes = [];
  questionData = [];
  allquestionData = [];
  finalJSON = [];
  finalJSON1 = [];
  dataQuestion1: Shikuyaaquestion = new Shikuyaaquestion();

  userId = sessionStorage.getItem("userId");
  userName = sessionStorage.getItem("userName");
  roleId = sessionStorage.getItem("roleId");
  isSaveSpinning = false;
  diabledButton = true;
  logtext: string = "";
  isokfile = true;
  id: number;
  chapters = [];
  questionTypes: Questiontype[];
  chaptersNodes = [];
  loadingTypes = false;
  loadingParagraph = false;
  title = 'done';
  fileUrl;
  // any="https://assessmentportaldemo.shikuyaa.com:7928/static/Samples/question_importer.txt"
  @ViewChild("inputFile", { static: false }) myInputVariable: ElementRef;

  ngOnInit() {
    // if (this.userId == null || this.userName == null || this.roleId == null) {
    //   this.api.logoutForSessionValues()
    // }
    // else
    // {
    // this.loadData()
    // }
    // this.loadData()
    // this.loadCourses()
    // this.loadCompanies()
    // this.loadCats()
    this.loadQuestionTypes();
    this.loadLevels();
    this.loadSubChpater();
  }

  loadQuestionTypes() {
    this.loadingTypes = true;
    let filter = " AND IS_ACTIVE=1";
    this.api.getAllQuestionTypes(0, 0, "ID", "desc", filter).subscribe(
      (localName) => {
        console.log(localName);
        this.questionTypes = localName["data"];
        console.log("QType:", this.questionTypes);
        // this.data.QUESTION_TYPE_ID = localName['data'][0]['ID']
        this.loadingTypes = false;
      },
      (err) => {
        console.log(err);
        this.loadingTypes = false;
      }
    );
  }

  loadSubChpater() {
    this.api.getAllChapterHierarchys(0).subscribe(
      (localName) => {
        if (localName["count"] == 0) {
          this.message.error("No Chapetrs Found", "");
        } else {
          this.chaptersNodes = localName["data"];
          // console.log('Chapter:',this.subChaptersNodes)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  loadLevels() {
    this.loadingLevels = true;
    let filter = " AND IS_ACTIVE=1";
    this.api.getAllLevels(0, 0, "value", "asc", filter).subscribe(
      (localName) => {
        this.levels = localName["data"];
        console.log("Level:", this.levels);
        // this.data.LEVEL_ID = localName['data'][0]['ID']
        this.loadingLevels = false;
      },
      (err) => {
        console.log(err);
        this.loadingLevels = false;
      }
    );
  }
  loadCourses() {
    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getCategoryHierarchy().subscribe(
      (localName) => {
        this.levels1 = localName["data"];
        console.log("courses");
        console.log(this.levels);
        this.loadingLevels = false;
      },
      (err) => {
        console.log(err);
        this.loadingLevels = false;
      }
    );
  }
  CHAPTER_ID;

  loadchapters(courseId) {
    // this.isSpinning = true;
    this.api.getCombinedShikuyaaChapters2(courseId, 1).subscribe(
      (localName) => {
        this.chapters = localName["data"];
        console.log(this.chapters);
        //  this.isSpinning = false;
      },
      (err) => {
        console.log(err);
        // this.isSpinning = false;
      }
    );
  }
  ChangeCourse(courseId) {
    this.loadchapters(courseId);
  }
  companyVisible: boolean = false;
  checkQue(event: any) {
    if (event == "F") {
      this.companyVisible = true;
    } else {
      this.companyVisible = false;
    }
  }
  companies = [];
  loadCompanies() {
    // this.loadingLevels = true;
    this.api.getAllCompanys(0, 0, "", "", " ").subscribe(
      (localName) => {
        this.companies = localName["data"];
        console.log("companies");
        console.log(this.companies);
        // this.loadingLevels = false;
      },
      (err) => {
        console.log(err);
        // this.loadingLevels = false;
      }
    );
  }
  cats = [];
  loadCats() {
    // this.loadingLevels = true;
    this.api.getAllQueCategory(0, 0, "", "", " ").subscribe(
      (localName) => {
        this.cats = localName["data"];
        console.log("companies");
        // console.log(this.companies)
        // this.loadingLevels = false;
      },
      (err) => {
        console.log(err);
        // this.loadingLevels = false;
      }
    );
  }
  loadData() {
    this.loadingLevels = true;
    let filter = " AND IS_ACTIVE=1";
    this.api.getAllLevels(0, 0, "value", "asc", filter).subscribe(
      (localName) => {
        this.levels = localName["data"];
        this.loadingLevels = false;
      },
      (err) => {
        console.log(err);
        this.loadingLevels = false;
      }
    );
  }
  loadchapterss(courseId) {
    // this.isSpinning = true;
    this.api.getParentChaptersNew(courseId).subscribe(
      (localName) => {
        this.chapters = localName["data"];
        console.log(this.chapters);
        //  this.isSpinning = false;
      },
      (err) => {
        console.log(err);
        // this.isSpinning = false;
      }
    );
  }
  getCHapetrs(event: any) {
    // this.api.getCombinedShikuyaaChapters1(event).subscribe(localName => {
    //   this.chapters = localName['data'];
    // }, err => {
    //   console.log(err);

    // });

    this.loadingLevels = true;
    this.api
      .getAllCourses(
        0,
        0,
        "ID",
        "desc",
        " AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID=" + event
      )
      .subscribe(
        (localName) => {
          this.level2 = localName["data"];
          console.log("courses");
          console.log(this.levels);
          this.loadingLevels = false;
        },
        (err) => {
          console.log(err);
          this.loadingLevels = false;
        }
      );
  }
  datas = [
    {
      disabled: true,
      key: 1,
      title: "emp grad pro",
      children: [
        {
          title: "course category",
          disabled: true,
          key: 2,
          children: [
            {
              title: "course sub category",
              disabled: false,
              key: 3,
            },
          ],
        },
      ],
    },
  ];
  onFileSelectedTXT(fileList: FileList) {
    this.isokfile = true;
    const allowed_types = ["text/plain"];
    if (!allowed_types.toString().match(fileList[0].type)) {
      this.isokfile = false;
      this.message.error("Only Text File are allowed ( .txt)", "");
    }

    if (this.isokfile) {
      this.filetxt = fileList[0];
      this.diabledButton = false;
    }
  }
  level2;

  extract() {
    console.log(this.filetxt);
    if (this.filetxt) {
      this.questionData = [];
      this.finalJSON = [];
      let counter = 2;
      let fileData = "";
      let fileReader: FileReader = new FileReader();
      let self = this;

      fileReader.onloadend = function () {
        self.fileContent = fileReader.result as string;
        fileData = self.fileContent;

        fileData = fileData
          .replace(/\\r/g, "")
          .replace(/\\n/g, "")
          .replace(/\\t/g, "")
          .replace(/\\/g, "&#92;")
          .replace(/\\"/g, "&#34;")
          .replace(/\\'/g, "&#39;")
          .replace(/\[/g, "&#91;")
          .replace(/\]/g, "&#93;");

        while (fileData != "") {
          let questionObject: Questions = new Questions();

          let all = "";
          let is_correct = 0;
          let tempIndex = 0;
          let answer = "";
          let id = (counter - 1).toString();
          if (fileData.indexOf("(" + counter + ")") > 0) {
            tempIndex = fileData.indexOf(")") + 1;
            all = fileData.substring(
              tempIndex,
              fileData.indexOf("(" + counter + ")") - tempIndex + tempIndex
            );
            if (counter >= 10) fileData = fileData.substring(all.length + 4);
            else fileData = fileData.substring(all.length + 3);
          } else {
            all = fileData.substring(fileData.indexOf(")") + 1);
            fileData = "";
          }

          if (all.indexOf("*(a)") > 0) {
            answer = "A";
          } else if (all.indexOf("*(b)") > 0) answer = "B";
          else if (all.indexOf("*(c)") > 0) answer = "C";
          else if (all.indexOf("*(d)") > 0) answer = "D";
          else if (all.indexOf("*(e)") > 0) answer = "E";

          let indexOfA =
            answer == "A" ? all.indexOf("*(a)") : all.indexOf("(a)");
          let indexOfB =
            answer == "B" ? all.indexOf("*(b)") : all.indexOf("(b)");
          let indexOfC =
            answer == "C" ? all.indexOf("*(c)") : all.indexOf("(c)");
          let indexOfD =
            answer == "D" ? all.indexOf("*(d)") : all.indexOf("(d)");
          let indexOfE =
            answer == "E" ? all.indexOf("*(e)") : all.indexOf("(e)");
          let indexOfDirection = all.indexOf("$#direction");
          let indexOfDescription = all.indexOf("$#description");

          let question = "";
          let optionA = "";
          let optionB = "";
          let optionC = "";
          let optionD = "";
          let optionE = "";
          let direction = "";
          let description = "";

          if (
            indexOfE == -1 &&
            indexOfDirection == -1 &&
            indexOfDescription == -1
          ) {
            //only 4 options
            tempIndex = 0;
            question = all.substring(tempIndex, indexOfA - tempIndex);
            tempIndex = all.indexOf("(a)") + 3;
            optionA = all.substring(
              tempIndex,
              indexOfB - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(b)") + 3;
            optionB = all.substring(
              tempIndex,
              indexOfC - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(c)") + 3;
            optionC = all.substring(
              tempIndex,
              indexOfD - tempIndex + tempIndex
            );
            optionD = all.substring(all.indexOf("(d)") + 3);
            counter = counter + 1;
          } else if (indexOfDirection == -1 && indexOfDescription == -1) {
            // only 5 options
            tempIndex = 0;
            question = all.substring(tempIndex, indexOfA - tempIndex);
            tempIndex = all.indexOf("(a)") + 3;
            optionA = all.substring(
              tempIndex,
              indexOfB - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(b)") + 3;
            optionB = all.substring(
              tempIndex,
              indexOfC - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(c)") + 3;
            optionC = all.substring(
              tempIndex,
              indexOfD - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(d)") + 3;
            optionD = all.substring(
              tempIndex,
              indexOfE - tempIndex + tempIndex
            );
            optionE = all.substring(all.indexOf("(e)") + 3);
            counter = counter + 1;
          } else if (indexOfE == -1 && indexOfDescription == -1) {
            //only 4 options and direction present
            tempIndex = 0;
            question = all.substring(tempIndex, indexOfA - tempIndex);
            tempIndex = all.indexOf("(a)") + 3;
            optionA = all.substring(
              tempIndex,
              indexOfB - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(b)") + 3;
            optionB = all.substring(
              tempIndex,
              indexOfC - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(c)") + 3;
            optionC = all.substring(
              tempIndex,
              indexOfD - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(d)") + 3;
            optionD = all.substring(
              tempIndex,
              indexOfDirection - tempIndex + tempIndex
            );
            direction = all.substring(all.indexOf("$#direction") + 11);
            counter = counter + 1;
          } else if (indexOfE == -1 && indexOfDirection == -1) {
            // only 4 options and description present
            tempIndex = 0;
            question = all.substring(tempIndex, indexOfA - tempIndex);
            tempIndex = all.indexOf("(a)") + 3;
            optionA = all.substring(
              tempIndex,
              indexOfB - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(b)") + 3;
            optionB = all.substring(
              tempIndex,
              indexOfC - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(c)") + 3;
            optionC = all.substring(
              tempIndex,
              indexOfD - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(d)") + 3;
            optionD = all.substring(
              tempIndex,
              indexOfDescription - tempIndex + tempIndex
            );
            description = all.substring(all.indexOf("$#description") + 13);
            counter = counter + 1;
          } else if (indexOfE == -1) {
            // 4 options and direction and description present
            tempIndex = 0;
            question = all.substring(tempIndex, indexOfA - tempIndex);
            tempIndex = all.indexOf("(a)") + 3;
            optionA = all.substring(
              tempIndex,
              indexOfB - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(b)") + 3;
            optionB = all.substring(
              tempIndex,
              indexOfC - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(c)") + 3;
            optionC = all.substring(
              tempIndex,
              indexOfD - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(d)") + 3;
            optionD = all.substring(
              tempIndex,
              indexOfDirection - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("$#direction") + 11;
            direction = all.substring(
              tempIndex,
              indexOfDescription - tempIndex + tempIndex
            );
            description = all.substring(all.indexOf("$#description") + 13);
            counter = counter + 1;
          } // all are present
          else {
            tempIndex = 0;
            question = all.substring(tempIndex, indexOfA - tempIndex);
            tempIndex = all.indexOf("(a)") + 3;
            optionA = all.substring(
              tempIndex,
              indexOfB - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(b)") + 3;
            optionB = all.substring(
              tempIndex,
              indexOfC - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(c)") + 3;
            optionC = all.substring(
              tempIndex,
              indexOfD - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(d)") + 3;
            optionD = all.substring(
              tempIndex,
              indexOfE - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("(e)") + 3;
            optionE = all.substring(
              tempIndex,
              indexOfDirection - tempIndex + tempIndex
            );
            tempIndex = all.indexOf("$#direction") + 11;
            direction = all.substring(
              tempIndex,
              indexOfDescription - tempIndex + tempIndex
            );

            description = all.substring(all.indexOf("$#description") + 13);

            counter = counter + 1;
          }

          questionObject.ID = counter - 2;
          questionObject.DIRECTION = direction.trim();
          questionObject.DESCRIPTION = description.trim();
          questionObject.QUESTION = question.trim();
          questionObject.OPTION_A = optionA.trim();
          questionObject.OPTION_B = optionB.trim();
          questionObject.OPTION_C = optionC.trim();
          questionObject.OPTION_D = optionD.trim();
          questionObject.OPTION_E = optionE.trim();
          questionObject.ANSWER = answer;
          questionObject.CLIENT_ID = self.api.clientId;
          questionObject = Object.assign({}, questionObject);
          self.questionData.push(questionObject);

          self.allquestionData = self.questionData;
        }
      };
      fileReader.readAsText(this.filetxt);
    } else {
      this.message.error("Please Select File...", "");
    }
  }

  deleteRow(data) {
    const index = this.allquestionData.indexOf(data);
    this.allquestionData.splice(index, 1);
    this.allquestionData = this.allquestionData.filter((object) => {
      return object["ID"] != data;
    });
  }

  save() {
    console.log("all data", this.allquestionData);
    console.log("Level:", this.dataQuestion1.LEVEL_ID);
    console.log("Chapter:", this.dataQuestion1.CHAPTER_ID);

    if (
      this.dataQuestion1.LEVEL_ID != undefined &&
      this.dataQuestion1.CHAPTER_ID != undefined
    ) {
      this.dataQuestion1.GROUP_TYPE = "B";
      var isok = true;
      this.allquestionData.forEach((element) => {
        if (element["ANSWER"] == "" || element["ANSWER"] == " ") {
          isok = false;
          this.id = element["ID"];
        }
      });
      console.log(isok);

      if (isok) {
        this.allquestionData.forEach((element) => {
          let dataQuestion: Shikuyaaquestion = new Shikuyaaquestion();
          dataQuestion.CHAPTER_ID = this.dataQuestion1.CHAPTER_ID;
          dataQuestion.LEVEL_ID = this.dataQuestion1.LEVEL_ID;
          // dataQuestion.COURSE_CATEGORY_ID=this.dataQuestion1.COURSE_ID
          dataQuestion.GROUP_TYPE = this.dataQuestion1.GROUP_TYPE;
          dataQuestion.QUESTION_TYPE_ID = this.dataQuestion1.QUESTION_TYPE_ID;
          dataQuestion.PARAGRAPH_ID = 0;
          dataQuestion.DIRECTION = element["DIRECTION"];
          dataQuestion.DIRECTION_IMG = "";
          dataQuestion.DESCRIPTION = element["DESCRIPTION"];
          dataQuestion.DESCRIPTION_IMG = "";
          dataQuestion.IS_RANDOMIZATION_OPTION = true;
          dataQuestion.IS_SEQUENCE_MARKING = false;
          dataQuestion.QUESTION = element["QUESTION"];
          dataQuestion.QUESTION_URL = "";
          dataQuestion.SOLUTION = "";
          dataQuestion.SOLUTION_IMAGE_URL = "";
          dataQuestion.SOLUTION_VIDEO_URL = "";
          dataQuestion.ANSWER_TEXT = "";
          dataQuestion.CLIENT_ID = this.api.clientId;
          dataQuestion.COMPANY_ID = 0;
          dataQuestion.CATEGORY_ID = 0;

          if (element["OPTION_E"] == "") {
            dataQuestion.OPTIONS = [
              {
                OPTION_TEXT: element["OPTION_A"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "A" ? 1 : 0,
                SEQUENCE_NO: 1,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_B"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "B" ? 1 : 0,
                SEQUENCE_NO: 2,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_C"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "C" ? 1 : 0,
                SEQUENCE_NO: 3,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_D"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "D" ? 1 : 0,
                SEQUENCE_NO: 4,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
            ];
          } else {
            dataQuestion.OPTIONS = [
              {
                OPTION_TEXT: element["OPTION_A"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "A" ? 1 : 0,
                SEQUENCE_NO: 1,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_B"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "B" ? 1 : 0,
                SEQUENCE_NO: 2,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_C"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "C" ? 1 : 0,
                SEQUENCE_NO: 3,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_D"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "D" ? 1 : 0,
                SEQUENCE_NO: 4,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_E"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "E" ? 1 : 0,
                SEQUENCE_NO: 5,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
            ];
          }

          dataQuestion = Object.assign({}, dataQuestion);
          this.finalJSON.push(dataQuestion);

          this.finalJSON1 = this.finalJSON;
        });

        console.log("final=", this.finalJSON1);
        this.isSaveSpinning = true;
        this.api.assessmentQuestionsImporter(this.finalJSON1).subscribe(
          (successCode) => {
            console.log(successCode);
            if (successCode["code"] == "200") {
              this.message.success("Questions Added Successfully ...", "");
              this.finalJSON1 = [];
              this.allquestionData = [];
              this.myInputVariable.nativeElement.value = null;
              this.filetxt = null;
              this.logtext =
                "Save - QuestionImporter form - SUCCESS" +
                JSON.stringify(this.finalJSON1) +
                " KEYWORD [C - QuestionImporter] ";
              this.api
                .addLog("A", this.logtext, this.api.emailId)
                .subscribe((successCode) => {
                  if (successCode["code"] == "200") {
                    console.log(successCode);
                  } else {
                    console.log(successCode);
                  }
                });
            } else {
              this.message.error("Questions Adding Failed...", "");
              this.finalJSON1 = [];
              this.allquestionData = [];
              this.logtext =
                "Save - QuestionImporter form - ERROR" +
                JSON.stringify(this.finalJSON1) +
                " KEYWORD [C - QuestionImporter] ";
              this.api
                .addLog("A", this.logtext, this.api.emailId)
                .subscribe((successCode) => {
                  if (successCode["code"] == "200") {
                    console.log(successCode);
                  } else {
                    console.log(successCode);
                  }
                });
            }
            this.isSaveSpinning = false;
          },
          (err) => {
            console.log(err);
            this.message.error("Server Error...", "");
            this.isSaveSpinning = false;
          }
        );
      } else {
        this.message.error(
          "Answer Text is missing on sr No " + this.id + "",
          ""
        );
      }
    } else {
      this.message.error("Please Fill All Required Fields...", "");
    }
  }

  save1() {
    console.log("all data", this.allquestionData);
    if (
      (this.dataQuestion1.LEVEL_ID != undefined &&
        this.dataQuestion1.GROUP_TYPE != undefined) ||
      this.dataQuestion1.CHAPTER_ID != undefined ||
      this.dataQuestion1.COMPANY_ID != undefined
    ) {
      var isok = true;
      this.allquestionData.forEach((element) => {
        if (element["ANSWER"] == "" || element["ANSWER"] == " ") {
          isok = false;
          this.id = element["ID"];
        }
      });
      console.log(isok);

      if (isok) {
        this.allquestionData.forEach((element) => {
          let dataQuestion: Shikuyaaquestion = new Shikuyaaquestion();
          dataQuestion.CHAPTER_ID = 0;
          // dataQuestion.COURSE_CATEGORY_ID=this.dataQuestion1.COURSE_ID
          dataQuestion.LEVEL_ID = this.dataQuestion1.LEVEL_ID;
          dataQuestion.GROUP_TYPE = this.dataQuestion1.GROUP_TYPE;
          dataQuestion.QUESTION_TYPE_ID = this.dataQuestion1.QUESTION_TYPE_ID;
          dataQuestion.PARAGRAPH_ID = 0;
          dataQuestion.DIRECTION = element["DIRECTION"];
          dataQuestion.DIRECTION_IMG = "";
          dataQuestion.DESCRIPTION = element["DESCRIPTION"];
          dataQuestion.DESCRIPTION_IMG = "";
          dataQuestion.IS_RANDOMIZATION_OPTION = true;
          dataQuestion.IS_SEQUENCE_MARKING = false;
          dataQuestion.QUESTION = element["QUESTION"];
          dataQuestion.QUESTION_URL = "";
          dataQuestion.SOLUTION = "";
          dataQuestion.SOLUTION_IMAGE_URL = "";
          dataQuestion.SOLUTION_VIDEO_URL = "";
          dataQuestion.ANSWER_TEXT = "";
          dataQuestion.CLIENT_ID = this.api.clientId;
          dataQuestion.COMPANY_ID = this.dataQuestion1.COMPANY_ID;
          dataQuestion.CATEGORY_ID = this.dataQuestion1.CATEGORY_ID;

          if (element["OPTION_E"] == "") {
            dataQuestion.OPTIONS = [
              {
                OPTION_TEXT: element["OPTION_A"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "A" ? 1 : 0,
                SEQUENCE_NO: 1,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_B"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "B" ? 1 : 0,
                SEQUENCE_NO: 2,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_C"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "C" ? 1 : 0,
                SEQUENCE_NO: 3,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_D"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "D" ? 1 : 0,
                SEQUENCE_NO: 4,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
            ];
          } else {
            dataQuestion.OPTIONS = [
              {
                OPTION_TEXT: element["OPTION_A"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "A" ? 1 : 0,
                SEQUENCE_NO: 1,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_B"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "B" ? 1 : 0,
                SEQUENCE_NO: 2,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_C"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "C" ? 1 : 0,
                SEQUENCE_NO: 3,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_D"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "D" ? 1 : 0,
                SEQUENCE_NO: 4,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
              {
                OPTION_TEXT: element["OPTION_E"],
                OPTION_IMAGE_URL: "",
                IS_CORRECT: element["ANSWER"] == "E" ? 1 : 0,
                SEQUENCE_NO: 5,
                WEIGHTAGE: 0,
                CLIENT_ID: this.api.clientId,
              },
            ];
          }

          dataQuestion = Object.assign({}, dataQuestion);
          this.finalJSON.push(dataQuestion);

          this.finalJSON1 = this.finalJSON;
        });

        console.log("final=", this.finalJSON1);
        this.isSaveSpinning = true;
        this.api.assessmentQuestionsImporter(this.finalJSON1).subscribe(
          (successCode) => {
            console.log(successCode);
            if (successCode["code"] == "200") {
              this.message.success("Questions Added Successfully ...", "");
              // this.dataQuestion1.COMPANY_ID=undefined
              // this.dataQuestion1.CHAPTER_ID=undefined

              this.finalJSON1 = [];
              this.allquestionData = [];
              this.myInputVariable.nativeElement.value = null;
              this.filetxt = null;
              this.logtext =
                "Save - QuestionImporter form - SUCCESS" +
                JSON.stringify(this.finalJSON1) +
                " KEYWORD [C - QuestionImporter] ";
              this.api
                .addLog("A", this.logtext, this.api.emailId)
                .subscribe((successCode) => {
                  if (successCode["code"] == "200") {
                    console.log(successCode);
                  } else {
                    console.log(successCode);
                  }
                });
            } else {
              this.message.error("Questions Adding Failed...", "");
              this.finalJSON1 = [];
              this.allquestionData = [];
              this.logtext =
                "Save - QuestionImporter form - ERROR" +
                JSON.stringify(this.finalJSON1) +
                " KEYWORD [C - QuestionImporter] ";
              this.api
                .addLog("A", this.logtext, this.api.emailId)
                .subscribe((successCode) => {
                  if (successCode["code"] == "200") {
                    console.log(successCode);
                  } else {
                    console.log(successCode);
                  }
                });
            }
            this.isSaveSpinning = false;
          },
          (err) => {
            console.log(err);
            this.message.error("Server Error...", "");
            this.isSaveSpinning = false;
          }
        );
      } else {
        this.message.error(
          "Answer Text is missing on sr No " + this.id + "",
          ""
        );
      }
    } else {
      this.message.error("Please Fill All Required Fields...", "");
    }
  }

  // const data = 'some text';
  downloadTXT() {
    // const link = document.createElement('a');
    // link.setAttribute('target', '_blank');
    // link.setAttribute('href', 'https://assessmentportaldemo.shikuyaa.com:7928/static/Samples/question_importer.txt');
    // link.setAttribute('download', `products.csv`);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
 
    let a = document.createElement('a');
    a.download = 'Sample File'
    a.href = 'assets/question_importer.txt'; 
    a.click();
    // document.body.removeChild(a);


  //   var httpHeaders = new HttpHeaders({
  //     "Access-Control-Allow-Origin": "*",
  //     "Content-Type": "application/json",
  //     apikey: "PZ7dhaxybciDnwmtdNu8qYMWH9laylVl",
  //     applicationkey: "mbyEDKA6P97HwQJJ",
  //     deviceid: this.cookie.get("deviceId"),
  //     supportkey: this.cookie.get("supportKey"),
  //     Token: this.cookie.get("token"),
  //   });
  //   var options = {
  //     headers: httpHeaders,
  //   };
  //   this._http
  //     .get(
  //       "https://assessmentportal.shikuyaa.com:7928/static/Samples/question_importer.txt",
  //       options
  //     )
  //     .subscribe((res) => {
  //       console.log((<any>res)._body);
  //       const blob = new Blob([(<any>res)._body], { type: "application/*" });
  //       this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
  //         window.URL.createObjectURL(blob)
  //       );
  //       console.log("downloadTXT()", this.fileUrl);
  //       // window.open(this.fileUrl);
  //       FileSaver.saveAs(blob, "sample.txt");
  //     });
  //   //   console.log('downloadTXT()')
  }

  show(event:any){
    console.log(event,'event');
    

  }
}
