import { Component, OnInit, ViewChild } from '@angular/core';
import { Chapter } from 'src/app/Models/MasterSyllabus/chapter';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { ChapterComponent } from '../chapter/chapter.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.css']
})
export class ChaptersComponent implements OnInit {

  formTitle = "Manage Chapters";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  imageurl:any
  filterClass: string = "filter-invisible";
  columns: string[][] = [["PART_NAME", "Part Name"], ["SECTION_NAME", "Section Name"], ["NAME", "Name"], ["WEIGHTAGE", "Weightage"], ["SUB_WEIGHTAGE", "Sub Weightage"], ["SEQUENCE_NO", "Sequence No"]]
  sectionsNodes=[]
  filterValue=[]
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Chapter = new Chapter();
  @ViewChild(ChapterComponent,{static:false} ) chaptervar: ChapterComponent ;
  pageSize2=10
  constructor(public api: ApiService, private cookie: CookieService) { }
  ngOnInit() {

    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else
    {
    this.search();
    this.loadAllSection()
    this.logtext = "OPENED - Chapters form KEYWORD[O - Chapters] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    }
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Chapters form "+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

 


      this.applyFilter()
      var filter = ""
      if (likeQuery)
        filter = this.filterQuery + likeQuery
      else
        filter = this.filterQuery

        console.log(filter)
        this.logtext = "Filter Applied - Chapters form "+ filter +" KEYWORD [F - Chapters] ";
        this.api.addLog('A', this.logtext, this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              console.log(successCode);
            }
            else {
              console.log(successCode);
            }
          });

    this.api.getAllChapters(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
     console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  loadAllSection()
  {
    this.api.getAllSection().subscribe(localName => {
      console.log(localName)
      this.sectionsNodes = localName['data'];
    }, err => {
      console.log(err);
    });
  }

  applyFilter() {
    this.filterQuery = ""

      let partFilter="";
      let sectionFilter="";
      if(this.filterValue.length>0)
      {
      this.filterValue.forEach(element => {
        element="" + element;
        if(element.startsWith("p"))
        {
            partFilter+= element.replace("p","") + ",";
        }
        else
        {
          sectionFilter+= element + ",";
        }
      });
      
      partFilter=(partFilter.length>0)?"PART_ID IN (" +  partFilter.substring(0,partFilter.length-1) + ") OR ":"";
      sectionFilter=(sectionFilter.length>0)?"SECTION_ID IN (" +  sectionFilter.substring(0,sectionFilter.length-1) + ") OR ":"";

      this.filterQuery= " AND (" + partFilter + sectionFilter ;

      if(this.filterQuery.length>6)
        this.filterQuery=  this.filterQuery.substring(0,this.filterQuery.length-3) + ")";
      else
        this.filterQuery ="";
      console.log(this.filterQuery);
    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
      }
      else{
        this.isFilterApplied = "default";
      }
  }

  clearFilter()
  {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.filterValue=[]
    this.search()
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Chapter";
    this.drawerData = new Chapter();
    this.drawerVisible = true;
    this.chaptervar.reset()
    this.setValue()
    this.logtext = "ADD - Chapter form KEYWORD [A - Chapter] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  setValue() {
    this.drawerData.IS_ACTIVE = true;
    this.drawerData.COLOR_CODE = "#ffffff"
    this.drawerData.DESCRIPTION = " "
    this.drawerData.SEQUENCE_NO = undefined
  }

  edit(data: Chapter): void {
    this.chaptervar.reset()
   
    if(data.ICON_URL!="" && data.ICON_URL!=" ")
    {
    this.chaptervar.isSpinning=true
      let TYPED_ARRAY
      this.api.getFile(data.ICON_URL).subscribe(data => {
        console.log(data)
      TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
      const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
        return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        this.imageurl = 'data:image/jpg;base64,' + base64String;
        this.drawerData.ICON_URL_CONVERTED=this.imageurl
    this.chaptervar.isSpinning=false

        }, err => {
        console.log(err);
      });
    }
    this.drawerTitle = "Update Chapter";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.logtext = "EDIT - Chapter form KEYWORD [E - Chapter] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });


  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}