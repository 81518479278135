import { Component, OnInit,Input } from '@angular/core';
import { User } from 'src/app/Models/Commonmodule/user';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Role } from 'src/app/Models/Commonmodule/role';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: User;
  passwordVisible = false;
  password: string;
  isSpinning = false
  dataList : User;
  roleLoading=false
  roles: Role[];
  ROLE_IDS:number[]

  roleId = sessionStorage.getItem("roleId")
  constructor(public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
  this.loadRoles()
  }
  
  loadRoles() {
    var Filter=""
    if(this.roleId!="1")
    Filter=" AND PARENT_ID="+this.roleId;
    else
    Filter=""
    this.roleLoading = true;
    this.api.getAllRoles(0, 0, '', '', Filter).subscribe(roles => {
      this.roles = roles['data'];
      this.roleLoading = false;
    }, err => {
      console.log(err);
      this.roleLoading = false;
    });
  }

  close(): void {
    this.drawerClose();
  }

  isValidMobile(mobile)
  {
      const expression = /^[6-9]\d{9}$/;
      return expression.test(String(""+mobile).toLowerCase())
  }
  isValidEmail(email)
  {
      const expression = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
      return expression.test(String(email).toLowerCase())
  }

  save(addNew: boolean): void {
    
    if(this.isValidMobile(this.data.MOBILE_NUMBER) && this.isValidEmail(this.data.EMAIL_ID) && /^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)
    {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateUser(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("User information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to update user information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {
        this.api.createUser(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("User information added successfully...", "");
              if (!addNew)
                this.drawerClose();
              else {
                this.data = new User();
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add user information...", "");
              this.isSpinning = false;
            }
          });
      }
    }
    else
    {
      if(!this.isValidMobile(this.data.MOBILE_NUMBER))
      this.message.error("Please Enter Valid Mobile Number...", "");
      else if(!this.isValidEmail(this.data.EMAIL_ID))
      this.message.error("Please Enter Valid Email Id...", "");
      else if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == false)
      this.message.error("Please Enter Valid Name...", "");

     
    }
  
  }

  searchEmail(emailId): void 
  {
    var likeQuery="EMAIL_ID="+emailId+"";
    this.api.getAllUsers(0, 0,"", "", likeQuery).subscribe(data => {
      console.log(data)
        this.dataList = data['data'];
      }, err => {
        console.log(err);
      if(err['ok']==false)
        this.message.error("Server Not Found", "");
      });
  }
}
