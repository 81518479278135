import { Component, OnInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Instruction2 } from 'src/app/Models/AssignmentModule/instruction';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-instructions2',
  templateUrl: './instructions2.component.html',
  styleUrls: ['./instructions2.component.css']
})
export class InstructionsComponent2 implements OnInit {

  formTitle = "Manage Instructions";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  listdata2 = []
  columns: string[][] = [['NAME', 'Name'], ['INSTRUCTION_TEXT', 'Instruction Text']]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Instruction2 = new Instruction2();
  pageSize2 = 10

  constructor(public api: ApiService) { }
  ngOnInit() {

    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.search();
      this.logtext = "OPENED - Instructions form KEYWORD[O - Instructions] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Instructions form " + sort + " " + this.sortKey + " KEYWORD [F - Instructions] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      this.logtext = "Filter Applied - Instructions form " + likeQuery + " KEYWORD [F - Instructions] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }


    this.api.getAllMockInstructions(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Instruction";
    this.drawerData = new Instruction2();
    this.drawerData.IS_DEFAULT_SELECTED = true
    this.drawerVisible = true;

    this.logtext = "ADD - Instruction form KEYWORD [A - Instruction] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }
  edit(data: Instruction2): void {

    this.drawerTitle = "Update Instruction";
    this.drawerData = Object.assign({}, data);
    this.drawerData.ALLOWED_FOR_DESKTOP = data.ALLOWED_FOR_DESKTOP ? true : false
    this.drawerData.ALLOWED_FOR_MOBILE = data.ALLOWED_FOR_MOBILE ? true : false
    this.drawerData.ALLOWED_FOR_WEB = data.ALLOWED_FOR_WEB ? true : false
    this.api.getAllMockInstructions(0, 0, 'ID', 'desc', " AND ID !=" + data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log(this.listdata2)
      }
    }, err => {
      console.log(err);
    });

    this.drawerVisible = true;

    this.logtext = "EDIT - Instruction form KEYWORD [E - Instruction] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });


  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}