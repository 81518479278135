export class Video {
    ID:number;
    CLIENT_ID:number;
    CHAPTER_ID:number;
    COURSE_ID:number
    NAME:string;
    DESCRIPTION:string;
    TYPE:string='P';
    IS_ACTIVE:boolean=true;
    THUMBNAIL_URL:string;
    THUMBNAIL_URL_CONVERTED:string
    DURATION:number;
    URL_KEY:string;
    VIDEO_TYPE:string="Y"
    SEQ_NO:number
    COURSE_SUB_CATEGORY_ID:number
    COURSE_SUB_CATEGORY_NAME:string
    COURSE_NAME:string
}
