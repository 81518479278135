import { Component, Input, OnInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Video } from 'src/app/Models/ShikuyaaModels/video';
import { Videoquestion } from 'src/app/Models/ShikuyaaModels/videoquestion';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-videoquestions',
  templateUrl: './videoquestions.component.html',
  styleUrls: ['./videoquestions.component.css']
})
export class VideoquestionsComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Video;
  formTitle = "Manage Video Questions"
  dataList = []
  videoId:number
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";
  columns: string[][] = [["VIDEO_QUESTION_TYPE_NAME", "Question Type"],["QUESTION", "Question"], ["OPTION_1", "Option 1"],["OPTION_2", "Option 2"],["OPTION_3", "Option 3"],["OPTION_4", "Option 4"],["ANSWER", "Answer"],["SEQUENCE_NO", "Sequence No"],["QUESTION_POPUP_SECONDS", "Question Popup(in Sec)"]]
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Videoquestion = new Videoquestion();
  pageSize2=10

  constructor(public api: ApiService) { }

  ngOnInit() {
  }

  getVideoId(data:Video)
  {
    this.videoId=data.ID
    this.search()
  }

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - VideosQuestion form"+ sort +" "+this.sortKey +" KEYWORD [F - VideosQuestion] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND VIDEO_ID="+this.videoId + "(";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
    }
    else
    {
      likeQuery=" AND VIDEO_ID="+this.videoId
    }

    this.logtext = 'Filter Applied - VideosQuestion form "+ likeQuery +" KEYWORD [F - VideosQuestion] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    this.api.getAllVideoquestions(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  //Drawer Methods
  get closeCallbackQuestion() {
    return this.drawerCloseQuestion.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Video Question";
    this.drawerData = new  Videoquestion();
    this.drawerData.VIDEO_ID=this.videoId
    this.drawerVisible = true;

	this.logtext = 'ADD - VideoQuestion form KEYWORD [A - VideoQuestion] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

  }
  edit(data:  Videoquestion): void {

    this.drawerTitle = "Update Video Question";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

	this.logtext = 'EDIT - VideoQuestion form KEYWORD [E - VideoQuestion] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });


  }
  drawerCloseQuestion(): void {
    this.search();
    this.drawerVisible = false;
  }

}
