import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BundleMapping } from 'src/app/Models/AssessmeentModels/bundlemapping';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';

@Component({ 
  selector: 'app-addbundlemapping',
  templateUrl: './addbundlemapping.component.html',
  styleUrls: ['./addbundlemapping.component.css']
})
export class AddbundlemappingComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  // data: BundleMaster = new BundleMaster;
  @Input() data: BundleMapping;

  @Input() 
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk=true;
  emailpattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt=/[a-zA-Z][a-zA-Z ]+/
  mobpattern=/^[6-9]\d{9}$/ 
  today= new Date();
  endOpen = false;
  startOpen = false;
  dateFormat = "dd-MM-yyyy HH:mm:ss"; 
  bundle:any[]=[]
  constructor(private api:ApiService, private message: NzNotificationService, private datePipe:DatePipe) { }

 

  ngOnInit(): void {
    this.loadAllBundle()
  }

  // close(): void {
  //   this.drawerClose();
  // }
  loadAllBundle() {
    this.api.getAllBundle(0, 0, 'ID', 'desc', ' AND IS_ACTIVE=1').subscribe(data => {
      this.bundle = data['data'];
    }, err => {
      console.log(err);
    });
  }
   //// Only number
   omit(event:any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ////

  resetDrawer(bundleMap: NgForm) {
    this.data=new BundleMapping();
    // bundleMap.form.reset();

    bundleMap.form.markAsPristine();
    bundleMap.form.markAsUntouched();
  }

  close(bundleMap: NgForm) {
    this.drawerClose();
    this.resetDrawer(bundleMap);
  }
  //save
  save(addNew: boolean,bundleMap: NgForm): void {
    this.isSpinning = false;
    this.isOk=true;

    if ( this.data.BUNDLE_ID==0 && this.data.TYPE.trim()==""  
        && this.data.START_DATE != undefined && this.data.END_DATE != undefined
        && this.data.PRICE== 0 ){
      this.isOk =false 
     this.message.error(" Please fill all required fields", ""); 
    }else

    if(this.data.BUNDLE_ID==undefined || this.data.BUNDLE_ID < 0){
      this.isOk =false
      this.message.error('Please select bundle name','')
    }else 

    if(this.data.TYPE==null || this.data.TYPE.trim()==''){
     this.isOk =false
     this.message.error('Please select map type','')
    }else 

    if(this.data.START_DATE==undefined || this.data.START_DATE==null){
     this.isOk =false
     this.message.error('Please select valid from date','')
    }else 

    if(this.data.END_DATE==undefined || this.data.END_DATE==null){
     this.isOk =false
     this.message.error('Please select valid to date','')
    }else 

    if(this.data.PRICE==null || this.data.PRICE<=0){
     this.isOk =false
     this.message.error('Please enter amount','')
     
    // } else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO <=0) {
    //   this.isOk = false;
    //   this.message.error(' Please Enter Sequence Number ', '');
}
if(this.isOk)
{
  this.isSpinning=false; 
  if (this.data.START_DATE==undefined){
    this.data.START_DATE=null;
  }else{
    this.data.START_DATE=this.datePipe.transform(this.data.START_DATE,"yyyy/MM/dd HH:mm:ss"); 
  }
  if (this.data.END_DATE==undefined){
    this.data.END_DATE=null;
  }else{
    this.data.END_DATE=this.datePipe.transform(this.data.END_DATE,"yyyy/MM/dd HH:mm:ss"); 
  }
  this.isSpinning=true; 
if(this.data.ID)
{
    this.api.updateBundleMap(this.data)
    .subscribe(successCode => {
      if(successCode.code=="200")
      {
        this.message.success(' Information updated successfully...', '');
        if(!addNew)
          this.drawerClose();
          this.isSpinning = false;
      }   
      else
      {
        if(successCode.code=="401")
        {
          this.message.error(' Selected bundle is already mapped ...', '');
          this.isSpinning = false;
        } else
        {
        this.message.error(' Failed to update information...', '');
        this.isSpinning = false;
        }
      }
    });
  }
  else{
  
      this.api.createBundleMap(this.data)
      .subscribe(successCode => {
        if(successCode.code=="200")
        {
          this.message.success(' Information save successfully...', '');
          if(!addNew)
         this.drawerClose();
            else
            {
              this.data=new BundleMapping();
              this.resetDrawer(bundleMap);
              // this.data.img= '';
              
              this.api.getAllBundleMap(1,1,'','desc','').subscribe (data =>{
                // if (data['count']==0){
                //   this.data.SEQUENCE_NO=1;
                // }else
                // {
                //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                // }
              },err=>{
                console.log(err);
              })
            }
            this.isSpinning = false;
          }
           else
           {
            if(successCode.code=="401")
            {
              this.message.error(' Selected bundle is already mapped ...', '');
              this.isSpinning = false;
            } else  if(successCode.code=="400"){
            this.message.error(' Failed to save information...', '');
            this.isSpinning = false;
            }
            else{}
           }
          });
        }
}

// else
// {
//   this.message.error("Please Fill All Required Fields...","");
//   this.isSpinning = false;
// }
  }

  disabledDate = (current: Date): boolean =>
  differenceInCalendarDays(current, this.today) < 0;

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }

  // dates: any = [];

  // disabledEndDate2 = (current: Date): any => {
  //   let index = this.dates.findIndex(
  //     (date: any) => date === moment(current).format('YYYY-MM-DD')
  //   );
  //   return index === -1 && true;
  // };
  // startDateChange() {
  //   var startDate = this.datePipe.transform(this.data.START_DATE, 'yyyy-MM-dd');
  //   var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  //   console.log(this.getDaysArray(startDate, endDate));
  //   console.log(this.dates);
  // }

  // getDaysArray(start: any, end: any) {
  //   for (
  //     var arr = [], dt = new Date(start);
  //     dt <= new Date(end);
  //     dt.setDate(dt.getDate() + 1)
  //   ) {
  //     arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
  //     this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
  //   }
  //   return arr;
  // }

}

