import { Component, OnInit, Input } from '@angular/core';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Collegeholidaycalender } from 'src/app/Models/CollegeOnboarding/collegeholidaycalender';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-collegeholiday',
  templateUrl: './collegeholiday.component.html',
  styleUrls: ['./collegeholiday.component.css'],
  providers: [DatePipe]
})
export class CollegeholidayComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: College;
  month=new Date().getFullYear().toString() + "-" + (new Date().getMonth() +1 ).toString()

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "asc";
  sortKey: string = "DATE";
  searchText: string = "";
  filterClass: string = "filter-invisible";
  initFilter = true;
  isFilterApplied: string = "default";
  columns: string[][] =[["DATE","Date"],["IS_HOLIDAY","IS Holiday"],["HOLIDAY_REASON","Reason"]]
  filterQuery:string=""
  COLLEGE_ID
  isSpinningApply:boolean
  footer = true;
  logtext:string=""
  today=new Date()
  pageSize2=10
  constructor(public api: ApiService,private message: NzNotificationService,private datePipe: DatePipe) { }

  ngOnInit() {
    console.log(this.today)
  }

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }


  search(reset: boolean = false,id?) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log(sort)
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });
      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }
else
{
  this.filterQuery=""
  this.applyFilter(this.COLLEGE_ID)
}
   
this.logtext = "Filter Applied - CollegeHolidays form "+ this.filterQuery +" KEYWORD [F - CollegeHolidays] ";
this.api.addLog('A', this.logtext, this.api.emailId)
  .subscribe(successCode => {
    if (successCode['code'] == "200") {
      console.log(successCode);
    }
    else {
      console.log(successCode);
    }
  });
    this.api.getAllCollegeHolidayCalender(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
     console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
     
    }, err => {
      console.log(err);
    });
  }

  showFilter()
  {
    if (this.filterClass === "filter-visible")
    this.filterClass = "filter-invisible";
  else
    this.filterClass = "filter-visible";
  }

  

  applyFilter(id?)
{
  console.log(this.month)
  
  this.month=this.datePipe.transform(this.month,"yyyy-MM")
  console.log(this.month)
  this.isSpinningApply=true
 this.COLLEGE_ID=id
  var sort: string;
  try {
    sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  } catch (error) {
    sort = "";
  }
  
  this.filterQuery="AND COLLEGE_ID="+this.COLLEGE_ID+" "
  this.filterQuery+="AND DATE like"+ "'%" +this.month+ "%'"

console.log(this.filterQuery)

this.logtext = "Filter Applied - CollegeHolidays form "+ this.filterQuery +" KEYWORD [F - CollegeHolidays] ";
this.api.addLog('A', this.logtext, this.api.emailId)
  .subscribe(successCode => {
    if (successCode['code'] == "200") {
      console.log(successCode);
    }
    else {
      console.log(successCode);
    }
  });

this.api.getAllCollegeHolidayCalender(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
  console.log(data)
   this.totalRecords = data['count'];
   this.dataList = data['data'];
   this.loadingRecords = false;
   this.filterClass = "filter-invisible";
   this.isFilterApplied="primary"
   this.isSpinningApply=false
 }, err => {
   console.log(err);
 });
}


  clearFilter()
  {
    this.month=new Date().getFullYear().toString() + "-" + (new Date().getMonth() +1 ).toString()
    this.isFilterApplied="default"
    this.filterClass = "filter-invisible";
    this.applyFilter(this.COLLEGE_ID)
  }

  edit(data:Collegeholidaycalender)
  {
    
      if(data.HOLIDAY_REASON!="")
      {
        console.log(data.DATE,data.IS_HOLIDAY,data.HOLIDAY_REASON)
        this.api.updateCollegeHolidayCalender(data)
        .subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("updated Successfully...", "");
          }
          else {
            this.message.error("Failed to update...", "");
          }
        });
      }
      else
      {
        this.message.error("Please Enter Holiday Reason...", "");
  
      }
    
   


  }
  getname(date)
  {
    
let todayDate=this.datePipe.transform(this.today,"yyyy-MM-dd")

    if(date>todayDate)
    return "update"
    else
    return ""
  }
}
