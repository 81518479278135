import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { BundleMapping } from "src/app/Models/AssessmeentModels/bundlemapping";
import { BundleMaster } from "src/app/Models/AssessmeentModels/bundlemaster";
import { CollegeMap } from "src/app/Models/AssessmeentModels/collegemap";
import { StudentMapping } from "src/app/Models/AssessmeentModels/studentmapping";
import { Branch } from "src/app/Models/BatchMgmt/branch";
import { ApiService } from "src/app/Service/api.service";
import { ShikuyaaapiService } from "src/app/Service/shikuyaaapi.service";

@Component({
  selector: "app-bundlemasterlist",
  templateUrl: "./bundlemasterlist.component.html",
  styleUrls: ["./bundlemasterlist.component.css"],
})
export class BundlemasterlistComponent implements OnInit {
  drawerVisible!: boolean;
  drawerVisible2!: boolean;
  drawerVisible3!: boolean;
  drawerVisible4!: boolean;
  @Input() dataList: BundleMapping[] = [];

  drawerTitle!: string;
  drawerData: BundleMaster = new BundleMaster();
  drawerData2: any = new CollegeMap();
  drawerData3: any = new CollegeMap();
  drawerData4: any = new StudentMapping();
  formTitle = " Bundle List ";
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  assessmentTotalRecords = 1;
  assessmentPageIndex = 1;
  assessmentPageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  listdata2 = []
  branches: Branch[] = [];
  time:any
  columns: string[][] = [
    ["NAME", " Bundle Name "],
    ["DESCRIPTION", " Description "],
    ["SEQ_NO"," Sequence Number "]
  ];

  checkedAssess = false;
  indeterminateAssess = false;
  setOfCheckedIdAssess = new Set<number>();
  collegeid:any;
  constructor(public api: ApiService,public api2:ShikuyaaapiService,private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search();
  }

  keyup(event: any) {
    this.search();
  }
 
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc";
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
      console.log("likeQuery" + likeQuery);
    }
    this.api
      .getAllBundle(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery
      )
      .subscribe(
        (data) => {
          this.loadingRecords = false;
          this.totalRecords = data["count"];
          this.dataList = data["data"];
          // if(this.totalRecords==0){
          //   data.SEQUENCE_NO=1;
          // }else{
          //   data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
          // }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = " Add New Bundle ";
    this.drawerData = new BundleMaster();
    this.drawerData.NAME = "";
    this.drawerData.DESCRIPTION = "";
    this.drawerData.THUMBNAIL_IMAGE = "";
    this.drawerData.BANNER_IMAGE = "";

    this.api.getAllBundle(1,1,'SEQ_NO','desc','').subscribe (data =>{
      if (data['count']==0){
        this.drawerData.SEQ_NO=1;
      }else
      {
        this.drawerData.SEQ_NO=data['data'][0]['SEQ_NO']+1;
      }
    },err=>{
      console.log(err);
    })
    // this.drawerData.STATUS=true;
    this.drawerVisible = true;
  }
  edit(data: BundleMaster): void {
    this.drawerTitle = " Update Bundle Master";
    this.drawerData = Object.assign({}, data);
    this.api.getAllBundle(0, 0, 'ID', 'desc', " AND ID !=" + data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log(this.listdata2)
      }
    }, err => {
      console.log(err);
    });
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || "id";
    const sortOrder = (currentSort && currentSort.value) || "desc";
    console.log(currentSort);

    console.log("sortOrder :" + sortOrder);
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  collegeName = [];
  mapcollege(data2: BundleMaster): void {
    console.log("id:", data2);
    this.drawerTitle = " Map College ";
    // this.drawerData = new BundleMaster();
    this.drawerData2 = Object.assign({}, data2);
    this.collegeName = [];
    this.api.getAllMappedCollege(0,0,'','',this.drawerData2.ID,'').subscribe(
      (data) => {
        this.collegeName = data["data"];
          this.collegeName.forEach((item) => {
          if (item.IS_ACTIVE) {
            this.setOfCheckedIdAssess.add(item.COLLEGE_ID);
          } else {
            this.setOfCheckedIdAssess.delete(item.COLLEGE_ID);
          }
        });
        this.checkedAssess = this.collegeName.every((item) => {
          this.setOfCheckedIdAssess.has(item.COLLEGE_ID);
        });
        this.indeterminateAssess =
          this.collegeName.some((item) =>
            this.setOfCheckedIdAssess.has(item.COLLEGE_ID)
          ) && !this.checkedAssess;
      },
      (err) => {
        console.log(err);
      }
    );

   
    this.drawerVisible2 = true;
  }

  drawerClose2(): void {
    this.search();
    this.drawerVisible2 = false;
  }

  get closeCallback2() {
    return this.drawerClose2.bind(this);
  }

  mapAssessment = []; 
  mapassessment(data3: BundleMaster): void {
    this.time = new Date()
    this.time = this.datePipe.transform(this.time, "yyyy-MM-dd HH:mm:ss")

    console.log('a',this.time);
    this.drawerTitle = " Map New Assessment ";
    this.drawerData3 = Object.assign({}, data3);
    
    this.mapAssessment = [];
    this.api.getAllAssessment(this.assessmentPageIndex,this.assessmentPageSize,'','desc',this.drawerData3.ID,'').subscribe(
      (data) => {
        this.assessmentTotalRecords = data['count'];
        console.log('this.totalRecords',this.totalRecords);

        this.mapAssessment = data["data"];
        this.mapAssessment.forEach((item) => {
          if (item.IS_ACTIVE) {
            this.setOfCheckedIdAssess.add(item.MOCK_ID);
          } else {
            this.setOfCheckedIdAssess.delete(item.MOCK_ID);
          }
        });
        this.checkedAssess = this.mapAssessment.every((item) => {
          this.setOfCheckedIdAssess.has(item.MOCK_ID);
        });
        this.indeterminateAssess =
          this.mapAssessment.some((item) =>
            this.setOfCheckedIdAssess.has(item.MOCK_ID)
          ) && !this.checkedAssess;

        this.drawerVisible3 = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  drawerClose3(): void {
    this.search();
    this.drawerVisible3 = false;
  }

  get closeCallback3() {
    return this.drawerClose3.bind(this);
  }

  studentName=[]
  mapstudent(data3: BundleMaster): void {
    this.drawerTitle = " Map Students ";
    // this.drawerData = new BundleMaster();
    this.drawerData4 = Object.assign({}, data3);
    this.collegeid= sessionStorage.getItem('collegeID')
    // console.log('Drawerdata:',collegeid);
    this.collegeName = []
    this.api.getAllMappedCollegelist(0,0,'','',this.collegeid).subscribe(
      (data) => {
        this.collegeName = data["data"];
        // this.api2.getAllStudentBranches(0,0,'','',' AND COLLEGE_ID = '+this.collegeid).subscribe(
          this.api2.getAllStudentBranches(0,0,'','','').subscribe(
          (data) => {
            // this.api.getAllcouponuseddetailedreport(0,0,'','',' AND STATUS=1').subscribe(data =>{
            this.branches = data['data'];
            console.log('rrrr',this.branches)
            this.drawerVisible4 = true;
          },
          (err) => {
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );

  

  }

  drawerClose4(): void {
    this.search();
    this.drawerVisible4 = false;
  }

  get closeCallback4() {
    return this.drawerClose4.bind(this);
  }
}
