import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Coupanfacility } from 'src/app/Models/ShikuyaaModels/coupanfacility';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-couponfacilities',
  templateUrl: './couponfacilities.component.html',
  styleUrls: ['./couponfacilities.component.css']
})
export class CouponfacilitiesComponent implements OnInit {

  formTitle = "Manage Coupon Facilities";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
   logtext:string = "";

  columns: string[][] =[ ["NAME","Name"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Coupanfacility = new Coupanfacility();
  dataList1=[]
  pageSize2=10
  
  constructor(public api: ApiService,private message: NzNotificationService) { }
  ngOnInit() {
    this.search();

	this.logtext = 'OPENED - Couponfacilities form KEYWORD[O - Couponfacilities] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
  }
  onKeyDownEvent(event)
  {if(event.key=='Enter')
  {
    event.preventDefault()
  }

    // this.isFilterApplied = "default";
    // this.filterClass = "filter-invisible";
this.search(true)
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
	
	this.logtext = 'Filter Applied - Couponfacilities form"+ sort +" "+this.sortKey +" KEYWORD [F - Couponfacilities] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

	this.logtext = 'Filter Applied - Couponfacilities form "+ likeQuery +" KEYWORD [F - Couponfacilities] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

    this.api.getAllCouponfacilities(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });

    this.api.getAllCouponfacilities(0, 0, this.sortKey, sort, likeQuery).subscribe(data => {
      this.dataList1 = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Coupon Facility";
    this.drawerData = new  Coupanfacility();
    this.drawerVisible = true;

	this.logtext = 'ADD - CouponFacility form KEYWORD [A - CouponFacility] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

  }
  edit(data:  Coupanfacility): void {

    this.drawerTitle = "Update Coupon Facility";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

	this.logtext = 'EDIT - CouponFacility form KEYWORD [E - CouponFacility] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });


  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}