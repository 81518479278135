import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BundleMaster } from 'src/app/Models/AssessmeentModels/bundlemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addbundlemaster',
  templateUrl: './addbundlemaster.component.html',
  styleUrls: ['./addbundlemaster.component.css']
})
export class AddbundlemasterComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  // data: BundleMaster = new BundleMaster;
  @Input() data: BundleMaster;

  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  @ViewChild('inputFile1', { static: false }) myInputVariable1: ElementRef;
  isOk=true;
  emailpattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt=/[a-zA-Z][a-zA-Z ]+/
  mobpattern=/^[6-9]\d{9}$/ 
  fileDataTHUMBNAIL_IMAGE: File = null
  fileDataBANNER_IMAGE: File = null
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  folderName = "BundleThumbnail" 
  folderName2 = "BundleBanner" 
  listdata1 = []
  @ Input() listdata2 = []
  constructor(public api:ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

 

  ngOnInit(): void {
    this.getallbundledata()
  }
  /////check double record
  getallbundledata() {
    this.api.getAllBundle(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1)
      }
    }, err => {
      console.log(err);
    });
  }
  // close(): void {
  //   this.drawerClose();
  // }

  ////// Allow only character and number
  alphaNumOnly(event:any) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
      if(charCode > 31 && (charCode <= 47 && charCode >= 33) || (charCode <= 64 && charCode >= 58) || (charCode <= 96 && charCode >= 91) || (charCode <= 126 && charCode >= 123)){
      return false;
    }
    return true;
  }
   //// Only number
   omit(event:any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ////

  resetDrawer(bundleMasterPage: NgForm) {
    this.data=new BundleMaster();
    // bundleMasterPage.form.reset();
    this.fileDataTHUMBNAIL_IMAGE =  null
    this.fileDataBANNER_IMAGE = null
    bundleMasterPage.form.markAsPristine();
    bundleMasterPage.form.markAsUntouched();
  }

  close(bundleMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(bundleMasterPage);
    bundleMasterPage.form.reset();

  }
  //save
  save(addNew: boolean,bundleMasterPage: NgForm): void {
    console.log('THUMBNAIL_IMAGE',this.data.THUMBNAIL_IMAGE);
    console.log('BANNER_IMAGE',this.data.BANNER_IMAGE);
    
    this.isSpinning = false;
    this.isOk=true;

    if (this.data.NAME.trim()==""  && this.data.DESCRIPTION.trim() == "" && this.data.SEQ_NO!=undefined
     && this.data.THUMBNAIL_IMAGE=='' && this.data.BANNER_IMAGE==''){
      this.isOk =false 
     this.message.error(" Please fill all required fields", ""); 
    }else

    if(this.data.NAME==null || this.data.NAME.trim()==''){
      this.isOk =false
      this.message.error('Please enter bundle name','')
    }else 

    if(this.data.DESCRIPTION==null || this.data.DESCRIPTION.trim()==''){
     this.isOk =false
     this.message.error('Please enter description','')
    }else 

    if(this.data.SEQ_NO== undefined || this.data.SEQ_NO<=0){
      this.isOk =false
      this.message.error('Please enter sequence number','')
    }else 

    if(this.data.THUMBNAIL_IMAGE=='' || this.data.THUMBNAIL_IMAGE==null){
     this.isOk =false
     this.message.error('Please select thumbnail image','')
    }else 

    if(this.data.BANNER_IMAGE=='' || this.data.BANNER_IMAGE==null){
     this.isOk =false
     this.message.error('Please selct banner url','')
}
if(this.isOk)
{
  this.isSpinning=false; 
  // this.data.LATITUDE = this.latitude.toString();
  // this.data.LONGITUDE   = this.longitude.toString();
  this.isSpinning=true; 
if(this.data.ID)
{
  var bundleData2 = this.listdata2.filter(obj => {
    return obj.NAME == this.data.NAME;
  });
  console.log('bundleData2',bundleData2.length); 
  console.log('this.listdata1',this.listdata2);
  
  if (bundleData2.length == 0) {
    this.api.updateBundle(this.data)
    .subscribe(successCode => {
      if(successCode.code=="200")
      {
        this.message.success(' Information updated successfully...', '');
        if(!addNew)
          this.drawerClose();
          this.isSpinning = false;
      }   
      else
      {
        this.message.error(' Failed to update information...', '');
        this.isSpinning = false;
      }
    });
  } else {
    this.message.error("Bundle Name Already Exist. Please Enter Other Bundle Name...", "");
    this.isSpinning = false;
  }
}
  else{
      var bundleData = this.listdata1.filter(obj => {
        return obj.NAME == this.data.NAME;
      });  
  console.log('bundleData2',bundleData.length);
  console.log('this.listdata1',this.listdata1);

      if (bundleData.length == 0) {
      this.api.createBundle(this.data)
      .subscribe(successCode => {
        if(successCode.code=="200")
        {
          this.message.success(' Information save successfully...', '');
          if(!addNew)
         this.drawerClose();
            else
            {
              this.data=new BundleMaster();
              this.resetDrawer(bundleMasterPage);
              // this.data.img= '';
              
              this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
                if (data['count']==0){
                  this.data.SEQ_NO=1;
                }else
                {
                  this.data.SEQ_NO=data['data'][0]['SEQ_NO']+1;
                }
              },err=>{
                console.log(err);
              })
            }
            this.isSpinning = false;
          }
           else
           {
            this.message.error(' Failed to save information...', '');
            this.isSpinning = false;
           }
          });
        } else {
          this.message.error("Bundle Name Already Exist. Please Enter Other Bundle Name...", "");
          this.isSpinning = false;
        }
        }
}

// else
// {
//   this.message.error("Please Fill All Required Fields...","");
//   this.isSpinning = false;
// }
  }
  logoUrl
  thumbImg
  bannerUrl
  onFileSelectedTHUMBNAIL_IMAGE(event) {

    this.isOk = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg']

    const isLt2M = event.target.files[0].size <= 10485760;

    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isOk = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {
          // this.showing=false
        }, 5000);

      }

      if (this.isOk) {
        this.fileDataTHUMBNAIL_IMAGE = <File>event.target.files[0];
        this.fileDataTHUMBNAIL_IMAGE = <File>event.target.files[0];
        if (this.fileDataTHUMBNAIL_IMAGE) {

          var number = Math.floor(100000 + Math.random() * 900000)
          var fileExt = this.fileDataTHUMBNAIL_IMAGE.name.split('.').pop();
          // this.urls = 'Profile' + number + "." + fileExt
          this.thumbImg = this.date1 + number + "." + fileExt
          console.log("urlthumb=", this.thumbImg)
          //console.log(this.fileDataIMAGE)
          this.api.onUploadnew(this.folderName, this.fileDataTHUMBNAIL_IMAGE, this.thumbImg).subscribe(res => {
            //console.log(res);
            if (res['code'] == "200") {

              this.data.THUMBNAIL_IMAGE = this.thumbImg

            }
          });


        }
      }
      else {
        // this.myInputVariable.nativeElement.value = null;
        if (!isLt2M) {

          setTimeout(() => {

          }, 5000);

        }





      }
    }
    this.isOk = true
    var thumbnailImgHeight: number
    var thumbnailImgWeight: number
    var isheightwidth: boolean

    const Img = new Image();
    Img.src = URL.createObjectURL(event.target.files[0]);
    Img.onload = (e: any) => {
      thumbnailImgHeight = e.path[0].height;
      thumbnailImgWeight = e.path[0].width;

      if (thumbnailImgHeight == 720 && thumbnailImgWeight == 1280)
        isheightwidth = true
      else
        isheightwidth = false


      if (this.isOk && isheightwidth) {
        this.fileDataTHUMBNAIL_IMAGE = <File>event.target.files[0];
      }
      else {
        if (!isheightwidth) {
          this.message.error('Height and Width must be 1280 X 720.', '');
          this.data.THUMBNAIL_IMAGE = undefined
          this.fileDataTHUMBNAIL_IMAGE = undefined
        }


      }
    }
  }
  clearThumbnailImage() {
    this.fileDataTHUMBNAIL_IMAGE = null
    this.data.THUMBNAIL_IMAGE = ''
  }
  OnClick(url) {
    window.open(url, "_blank")
  }

  onFileSelectedBANNER_IMAGE(event) {
    this.isOk = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg']

    const isLt2M = event.target.files[0].size <= 10485760;

    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isOk = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {
          // this.showing=false
        }, 5000);

      }

      if (this.isOk) {
        this.fileDataBANNER_IMAGE = <File>event.target.files[0];
        this.fileDataBANNER_IMAGE = <File>event.target.files[0];
        if (this.fileDataBANNER_IMAGE) {

          var number = Math.floor(100000 + Math.random() * 900000)
          var fileExt = this.fileDataBANNER_IMAGE.name.split('.').pop();
          // this.urls = 'Profile' + number + "." + fileExt
          this.bannerUrl = this.date1 + number + "." + fileExt
          console.log("urlbanner=", this.bannerUrl)
          //console.log(this.fileDataIMAGE)
          this.api.onUploadnew(this.folderName2, this.fileDataBANNER_IMAGE, this.bannerUrl).subscribe(res => {
            //console.log(res);
            if (res['code'] == "200") {

              this.data.BANNER_IMAGE = this.bannerUrl

            }
          });


        }
      }
      else {
        // this.myInputVariable.nativeElement.value = null;
        if (!isLt2M) {

          setTimeout(() => {

          }, 5000);

        }





      }
    }
    this.isOk = true
    var thumbnailImgHeight: number
    var thumbnailImgWeight: number
    var isheightwidth: boolean

    const Img = new Image();
    Img.src = URL.createObjectURL(event.target.files[0]);
    Img.onload = (e: any) => {
      thumbnailImgHeight = e.path[0].height;
      thumbnailImgWeight = e.path[0].width;

      if (thumbnailImgHeight == 500 && thumbnailImgWeight == 1920)
        isheightwidth = true
      else
        isheightwidth = false

      if (this.isOk && isheightwidth) {
        this.fileDataBANNER_IMAGE = <File>event.target.files[0];
      }
      else {
        if (!isheightwidth) {
          this.message.error('Height and Width must be 1920 X 500.', '');
          this.fileDataBANNER_IMAGE = undefined
          this.data.BANNER_IMAGE = undefined
        }
      }
    }

    // this.isokfile = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // if (!allowed_types.toString().match(event.target.files[0].type)) {
    //   this.isokfile = false
    //   this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    // }

    // if(this.isokfile) {
    //   this.fileDataBANNER_IMAGE = <File>event.target.files[0];
    // }

  }
  clearBannerImage() {
    this.fileDataBANNER_IMAGE = null
    this.data.BANNER_IMAGE = ''
  }
  
}
