export class Videoquestion {
    ID:number;
    CLIENT_ID:number;
    VIDEO_ID:number;
    DIRECTION:string="";
    DESCRIPTION:string="";
    QUESTION:string;
    OPTION_1:string;
    OPTION_2:string;
    OPTION_3:string;
    OPTION_4:string;
    ANSWER:string;
    QUESTION_POPUP_SECONDS:number;
    SEQUENCE_NO:number;
    IS_ACTIVE:boolean=true;
    VIDEO_QUESTION_TYPE_ID:number;
}
