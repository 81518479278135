import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-studentassessmentdetails',
  templateUrl: './studentassessmentdetails.component.html',
  styleUrls: ['./studentassessmentdetails.component.css']
})
export class StudentassessmentdetailsComponent implements OnInit {

  isExportloading = false
  formTitle = " Student Assessment Details ";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";
  studentValue: any
  bundleValue: any
  assessmentValue: any
  query: any
  filterClass: string = "filter-invisible";
  //chapter name,l1 questions,l1 time,l2 question,l2 time,l3 question,l3 time, l4 question,l4 time,l5 question,l5 time, total time,total question
  columns: string[][] = [['STUDENT_NAME', ' Student Name'], ['MOCK_NAME', ' Assessment Name '], ['TOTAL', ' Total '],
  ['SOLVED', ' Solved'], ['CORRECT', ' Correct'],
  ['INCORRECT', ' Incorrect'], ['TOTAL_SEC', ' Total Sec.'],
  ['SOLVED_SEC', ' Solved Sec.'], ['AVG_SEC', ' Average Sec.'],
  ['MARKS', ' Marks'], ['PERCENTAGE', ' Percentage'],
  ['ACCURACY', ' Accuracy'], ['PERFORMANCE', ' Performance'],
  ['PERFORMANCE_INDEX', ' Performance Index']]

  coursesNodes = []
  isloadSpinning = false
  dataList1 = []
  pageSize2 = 10
  selectedDate: Date[] = []
  value1: string = ""
  value2: string = ""
  dateFormat = 'dd/MM/yyyy';
  datePipe = new DatePipe("en-US");
  loadingcourse = false
  loadingmocks = false
  loadingmapbundle = false
  loadingbundle = false
  loadingstudent = false
  COLLEGE_ID = ''
  mocks = []
  student = []
  bundle = []
  mappedbundle = []
  constructor(public api: ApiService, private message: NzNotificationService) { }
  ngOnInit() {
    this.selectedDate = [new Date(), new Date()]
    this.changeDate(this.selectedDate)

    this.logtext = 'OPENED - Course Assessment Details KEYWORD[O - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    this.loadingstudent = true
    this.api.getAllStudentListReport(0, 0, 'id', 'asc', '').subscribe(data => {
      // this.loadingmocks = false
      if (data['code'] == 200) {
        this.student = data['data'];
        this.loadingstudent = false
      } else {
        this.message.error(" Failed To Get Information", "");
        this.loadingstudent = false
      }

    }, err => {
      console.log(err);
    });

    this.loadingbundle = true
    this.api.getAllBundleMap(0, 0, 'id', 'asc', ' AND IS_ACTIVE = 1').subscribe(data => {
      // this.loadingmocks = false
      if (data['code'] == 200) {
        this.bundle = data['data'];
        this.loadingbundle = false
      } else {
        this.message.error(" Failed To Get Information", "");
        this.loadingbundle = false
      }
    }, err => {
      console.log(err);
    });

  }

  loadMappedData(event) {
    this.loadingmapbundle = true
    this.api.getAllAssessmentCombinedMappingData(0, 0, 'id', 'asc', ' AND BUNDLE_ID = ' + event).subscribe(data => {
      // this.loadingmocks = false
      if (data['code'] == 200) {
        this.mappedbundle = data['data'];
        this.loadingmapbundle = false
      } else {
        this.message.error(" Failed To Get Information", "");
        this.loadingmapbundle = false
      }

    }, err => {
      console.log(err);
    });
  }
  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd")
  }


  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || '';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search(false);
  }

  onKeyDownEvent(event) {
    if (event.key == 'Enter') {
      event.preventDefault()
    }

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - Course Assessment Details"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
    }

    this.logtext = 'Filter Applied - Course Assessment Details ' + likeQuery + ' KEYWORD [F - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery
    this.query = filter
    console.log(filter)
    this.isloadSpinning = true
    this.api.getAllStudAssessmentDetailReport(this.pageIndex, this.pageSize, this.sortKey, sort, 0, 0).subscribe(data => {
      this.isloadSpinning = false
      this.loadingRecords = false;
      if (data['code'] == '200') {
        this.totalRecords = data['count'];
        this.dataList = data['data'];

      }
    }, err => {
      console.log(err);
    });

  }

  downloadExcel() {
    console.log('this.studentValue', this.studentValue);
    console.log('this.assessmentValue', this.assessmentValue);
    this.studentValue=Number(this.studentValue);
    this.assessmentValue=Number(this.assessmentValue);

    // excel
    if (this.studentValue != undefined && this.assessmentValue != undefined) {
      this.api.getAllStudAssessmentDetailReport(0, 0, this.sortKey, '', Number(this.studentValue), this.assessmentValue).subscribe(data => {
        if (data['code'] == 200) {
          this.dataList1 = data['data'];
          console.log('this.dataList1:', this.dataList1);
          const element = window.document.getElementById('downloadFile');
          //  if (element != null) element.focus();
          setTimeout(() => {
            if (element != null) element.click();
          }, 3000);

        }
      },
        (err) => {
          console.log(err);
        }
      );
    } else if (this.studentValue != undefined && this.assessmentValue == undefined) {
      this.api.getAllStudAssessmentDetailReport(0, 0, this.sortKey, '', this.studentValue, 0).subscribe(data => {
        if (data['code'] == 200) {
          this.dataList1 = data['data'];
          console.log('this.dataList1:', this.dataList1);
          const element = window.document.getElementById('downloadFile');
          //  if (element != null) element.focus();
          setTimeout(() => {
            if (element != null) element.click();
          }, 3000);
        }
      },
        (err) => {
          console.log(err);
        }
      );
    } else if (this.studentValue == undefined && this.assessmentValue != undefined) {
      this.api.getAllStudAssessmentDetailReport(0, 0, this.sortKey, '', 0, this.assessmentValue).subscribe(data => {
        if (data['code'] == 200) {
          this.dataList1 = data['data'];
          console.log('this.dataList1:', this.dataList1);
          const element = window.document.getElementById('downloadFile');
          //  if (element != null) element.focus();
          setTimeout(() => {
            if (element != null) element.click();
          }, 3000);

        }
      },
        (err) => {
          console.log(err);
        }
      );
    } else if (this.studentValue == undefined && this.assessmentValue == undefined) {
      this.api.getAllStudAssessmentDetailReport(0, 0, this.sortKey, '', 0, 0).subscribe(data => {
        if (data['code'] == 200) {
          this.dataList1 = data['data'];
          console.log('this.dataList1:', this.dataList1);
          const element = window.document.getElementById('downloadFile');
          //  if (element != null) element.focus();
          setTimeout(() => {
            if (element != null) element.click();
          }, 3000);

        }
      },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }


  applyFilter() {
    console.log('Value1:', this.studentValue)
    console.log('Value2:', this.bundleValue)
    console.log('Value3:', this.assessmentValue)

    // this.isSpinning=true
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    if (
      this.studentValue != undefined && this.assessmentValue != undefined
    ) {
      this.filterQuery =
        " AND STUDENT_ID = '" +
        Number(this.studentValue) + "'" +
        " AND MOCK_ID = " + this.assessmentValue

      var likeQuery = "";

      if (this.searchText != "") {
        likeQuery = " AND";
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });
        likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      }
      this.api
        .getAllStudAssessmentDetailReport(
          0, 0,
          this.sortKey,
          sort,
          Number(this.studentValue),
          this.assessmentValue
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.isFilterApplied = 'primary';
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            // this.isSpinning = false;
            this.filterClass = 'filter-invisible';
            // this.search();
          },
          (err) => {
            console.log(err);
          }
        );
    } else if (this.studentValue != undefined && this.assessmentValue == undefined) {
      this.api
        .getAllStudAssessmentDetailReport(
          0, 0,
          this.sortKey,
          sort,
          Number(this.studentValue),
          0
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.isFilterApplied = 'primary';
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            // this.isSpinning = false;
            this.filterClass = 'filter-invisible';
            // this.search();
          },
          (err) => {
            console.log(err);
          }
        );
    }
    else if (this.studentValue == undefined && this.assessmentValue != undefined) {
      this.api
        .getAllStudAssessmentDetailReport(
          0, 0,
          this.sortKey,
          sort,
          0,
          this.assessmentValue
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.isFilterApplied = 'primary';
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            // this.isSpinning = false;
            this.filterClass = 'filter-invisible';
            // this.search();
          },
          (err) => {
            console.log(err);
          }
        );
    } else
      if (this.bundleValue != undefined && this.assessmentValue == undefined) {
        this.message.error('Please Select Assessment Name Also', '')
      } else {
        this.message.error('Please Select Filter  Value', '')
      }

    // this.dataList=[];
  }


  clearFilter() {
    this.COLLEGE_ID = ''
    this.studentValue = ""
    this.bundleValue = ""
    this.assessmentValue = ""
    this.filterQuery = ""
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true)
  }



}
