export class Questionmaster {
    ID:number;
    CLIENT_ID:number;
    SUB_CHAPTER_ID:number;
    CHAPTER_ID:number;
    QUESTION_TYPE_ID:any;
    LEVEL_ID:any;
    GROUP_TYPE:string;
    PARAGRAPH_ID:number;
    DIRECTION:string;
    DIRECTION_URL:string;
    DIRECTION_URL_CONVERTED:string;
    DESCRIPTION:string;
    DESCRIPTION_URL:string;
    DESCRIPTION_URL_CONVERTED:string;

    RANDOMIZE_OPTIONS:boolean
    IS_SEQUENCE_MARKING:boolean
    QUESTION:string="";
    QUESTION_URL:string;
    QUESTION_URL_CONVERTED:string;

    SOLUTION:string;
    SOLUTION_IMAGE_URL:string;
    SOLUTION_IMAGE_URL_CONVERTED:string;
    SOLUTION_VIDEO_URL:string;
    ANSWER_TEXT:string;
    PARAGRAPH_IMAGE_VIDEO_URL:string
    PARAGRAPH_IMAGE_VIDEO_URL_CONVERTED:string
    optionList=[]
    OPTIONS=[]
}
