import { Component, OnInit, Input } from '@angular/core';
import { Mappedassignment } from 'src/app/Models/AssignmentModule/mappedassignment';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-currentstatus2',
  templateUrl: './currentstatus2.component.html',
  styleUrls: ['./currentstatus2.component.css']
})
export class CurrentstatusComponent2 implements OnInit {


  @Input() drawerClose: Function;
  @Input() data: Mappedassignment;
  formTitle = "Current Status"
  searchText: string = ""
  dataList = []
  loadingRecords = false
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  sortValue: string = "desc";
  sortKey: string = "ID";
  logtext: string = "";
  ASSIGNMENT_ID: number
  columns: string[][] = [['Total', 'Total'], ['STARTED', 'Started'], ['COMPLETED', 'Completed'], ['TERMINATED', 'Terminated'], ['VIOLATED', 'Violated']]
  Total: number = 0
  STARTED: number = 0
  COMPLETED: number = 0
  TERMINATED: number = 0
  VIOLATED: number = 0


  constructor(public api: ApiService) { }

  ngOnInit() {

  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);

  }


  getassignmentId(assignmentId) {
    console.log(assignmentId)
    this.ASSIGNMENT_ID = assignmentId
    this.search()
  }

  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - CurrentStatusOfAssesment form " + sort + " " + this.sortKey + " KEYWORD [F - CurrentStatusOfAssesment] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND MAPPED_ASSIGNMENT_MASTER_ID=" + this.ASSIGNMENT_ID + " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }
    else {
      //likeQuery=" AND MAPPED_ASSIGNMENT_MASTER_ID="+this.ASSIGNMENT_ID
      likeQuery = ""
    }

    this.logtext = "Filter Applied - CurrentStatusOfAssignment form " + likeQuery + " KEYWORD [F - CurrentStatusOfAssignment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    console.log(likeQuery)

    this.api.getCurrentStatusMock(this.ASSIGNMENT_ID).subscribe(data => {
      console.log(data)
      // if(data['count']>0)
      // {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.Total = data['data'][0]['Total']
      this.STARTED = data['data'][0]['STARTED']
      this.COMPLETED = data['data'][0]['COMPLETED']
      this.TERMINATED = data['data'][0]['TERMINATED']
      this.VIOLATED = data['data'][0]['VIOLATED']
      // }
    }, err => {
      console.log(err);
    });
  }

}
