import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Collegedocument } from 'src/app/Models/CollegeOnboarding/collegedocument';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Documenttype } from 'src/app/Models/CollegeOnboarding/documenttype';
import { User } from 'src/app/Models/Commonmodule/user';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() COLLEGE_ID: number
  EMAIL_IDS = []
  @Input() data: Collegedocument
  logtext: string = "";
  userId = Number(this.cookie.get('userId'));
  isSpinning = false
  documentTypes: Documenttype[]
  users: User[]
  loadingFilterEmailIds = false
  documentTypesLoading = false
  fileData_ICON_URL: File = null
  fkey = "hYBJsvja"
  dataList = []
  isokfile=false
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;

  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    console.log("in document init")
    this.loadData()
    this.loadDocuments()
  }

  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.fileData_ICON_URL=null
    this.isokfile=true
  }

  loadDocuments() {

    this.api.getAllCollegeDocuments(0, 0, '', '', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  loadData() {
    console.log("load call")


    this.documentTypesLoading = true
    this.api.getAllDocumentTypes(0, 0, '', '', ' AND IS_ACTIVE=1').subscribe(data => {
      this.documentTypes = data['data'];
      this.documentTypesLoading = false
    }, err => {
      console.log(err);
    });

    this.loadingFilterEmailIds = true
    var filter = " AND ID in (select USER_ID from USER_ROLE_MAPPING WHERE ROLE_ID in (1,5,6,7,9,10))"
    console.log(filter)
    this.api.getAllUsers(0, 0, '', '', filter).subscribe(data => {
      console.log(this.users )
      this.users = data['data'];
      this.loadingFilterEmailIds = false
    }, err => {
      console.log(err);
    });

  }

  save(addNew): void {
    console.log(this.data)
    console.log(this.fileData_ICON_URL)

    
    var returnData=this.similarData()
    if (this.data.NAME != undefined && this.data.NAME != "" && this.data.DOCUMENT_TYPE_ID != undefined && this.fileData_ICON_URL!=null && this.data.USERS.length > 0) {

      if(this.isokfile)
      {
        if(returnData)
        {
      if (this.fileData_ICON_URL) {
        var fileExt = this.fileData_ICON_URL.name.split('.').pop();
        var lkey = ""
        this.api.onUploadNewMethod(this.fileData_ICON_URL, fileExt, this.fkey)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == 200) {
              lkey = successCode['data'][0]['L_KEY']
              this.data.DOCUMENT_URL = lkey
              console.log(addNew)
              this.updateData(addNew)
            }
            else {
              console.log(successCode)
            }
          });
      }
      else {
        if (this.data.ID)
          this.updateData(false)
        else {
          this.data.DOCUMENT_URL = ""
          this.updateData(addNew)
        }
      }
    }
    }
    else
    {
      this.message.error("Only pdf files are allowed (.pdf)", "");
    }

    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }

  updateData(addNew: boolean) {
    this.data.COLLEGE_ID = this.COLLEGE_ID
    console.log(this.data)

    console.log(this.EMAIL_IDS)


    if (this.data.ID) {

      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
      });

      if (filterData.length > 0) {
        this.message.error("Already Record Present in Database", "");
      }
      else {
        this.isSpinning = true;
        this.api.updateCollegeDocument(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == 200) {
              this.message.success("CollegeDocument information updated Successfully...", "");

              this.logtext = 'Update & Close - CollegeDocument form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - CollegeDocument ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.logtext = 'Update & Close - CollegeDocument form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - CollegeDocument ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
              this.message.error("Failed to update College Document information...", "");
              this.isSpinning = false;
            }
          });
      }

    }
    else {

      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase()
      });

      if (filterData.length > 0) {
        this.message.error("Already Record Present in Database", "");
      }
      else {
        this.isSpinning = true;
        this.api.createCollegeDocument(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == 200) {
              this.message.success("CollegeDocument information added successfully...", "");

              if (!addNew) {
                this.drawerClose();
                this.logtext = 'Save & Close - CollegeDocument form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - CollegeDocument ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == 200) {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.data = new Collegedocument();
                this.data.IS_ACTIVE = true
                this.loadDocuments()
                this.logtext = 'Save & New - CollegeDocument form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - CollegeDocument ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add CollegeDocument information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - CollegeDocument form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - CollegeDocument ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
          });
      }

    }
  }

  similarData()
  {
    if (this.data.ID) {

      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
      });


      if(filterData.length>0)
      {
       if(filterData.length>0) 
      this.message.error("Name Already Present in Database", "");
     
      return false
      }
      else
      {
        return true
      }
    }
    else
    {
      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() 
      });

      if(filterData.length>0 )
      {
        this.isSpinning = false;
       if(filterData.length>0) 
      this.message.error("Name Already Present in Database", "");
     
      return false
      }
      else
      {
        return true
      }
    }


  }

  onFileSelected_DOCUMENT_LOGO_URL(event) {
    this.isokfile = true
    const allowed_types = ['application/pdf'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfile = false
      this.message.error("Only pdf files are allowed (.pdf)", "");
    }

    if (this.isokfile) {
      this.fileData_ICON_URL = <File>event.target.files[0];
    }
    

  }

  close() {
    this.drawerClose()
  }
}
