import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CourseCategory } from 'src/app/Models/Category/course-category'
import { FreeQueCategory } from 'src/app/free-que-category'
import { LiveClassesCategory } from 'src/app/live-classes-category'
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { User } from '../Models/Commonmodule/user';
import { Role } from '../Models/Commonmodule/role';
import { Form } from '../Models/Commonmodule/form';
import { LiveSessions } from '../src/app/Model/live-sessions';
import { Roledetails } from '../Models/Commonmodule/roledetails';
import { Part } from '../Models/MasterSyllabus/part';
import { Section } from '../Models/MasterSyllabus/section';
import { Chapter } from '../Models/MasterSyllabus/chapter';
import { Questiontype } from '../Models/MasterSyllabus/questiontype';
import { Level } from '../Models/MasterSyllabus/level';
import { State } from '../Models/CollegeOnboarding/state';
import { Region } from '../Models/CollegeOnboarding/region';
import { District } from '../Models/CollegeOnboarding/district';
import { Collegetype } from '../Models/CollegeOnboarding/collegetype';
import { Designation } from '../Models/CollegeOnboarding/designation';
import { Documenttype } from '../Models/CollegeOnboarding/documenttype';
import { Branch } from '../Models/BatchMgmt/branch';
import { Passingyear } from '../Models/BatchMgmt/passingyear';
import { Division } from '../Models/BatchMgmt/division';
import { Coursetype } from '../Models/BatchMgmt/coursetype';
import { Vehicle } from '../Models/ResourcePlanning/vehicle';
import { Questionparagraph } from '../Models/MasterSyllabus/questionparagraph';
import { Course } from '../Models/BatchMgmt/course';
import { Trainingcourse } from '../Models/BatchMgmt/trainingcourse';
import { Holidaycalender } from '../Models/SessionModule/holidaycalender';
import { Subchapter } from '../Models/MasterSyllabus/subchapter';
import { College } from '../Models/CollegeOnboarding/college';
import { Collegecontact } from '../Models/CollegeOnboarding/collegecontact';
import { Collegedocument } from '../Models/CollegeOnboarding/collegedocument';
import { Collegetypemapping } from '../Models/CollegeOnboarding/collegetypemapping';
import { Collegebranchmapping } from '../Models/CollegeOnboarding/collegebranchmapping';
import { Collegetrainingcoursemapping } from '../Models/CollegeOnboarding/collegetrainingcoursemapping';
import { Collegeholidaycalender } from '../Models/CollegeOnboarding/collegeholidaycalender';
import { Batch } from '../Models/BatchMgmt/batch';
import { Mastersession } from '../Models/MasterSyllabus/mastersession';
import { Mastersessiondetails } from '../Models/MasterSyllabus/mastersessiondetails';
import { Syllabusbatchgroup } from '../Models/BatchwiseSyllabus/syllabusbatchgroup';
import { Batchleveltimer } from '../Models/BatchwiseSyllabus/batchleveltimer';
import { Schedulingbatchgroup } from '../Models/BatchScheduling/schedulingbatchgroup';
import { Resourcebatchgroup } from '../Models/ShikuyaaCourse/resourcebatchgroup';
import { Batchschedule } from '../Models/BatchScheduling/batchschedule';
import { Resouceallocation } from '../Models/ResourcePlanning/resouceallocation';
import { Questionmaster } from '../Models/MasterSyllabus/questionmaster';
import { Assignmentgroup } from '../Models/AssignmentModule/assignmentgroup';
import { Instruction, Instruction2 } from '../Models/AssignmentModule/instruction';
import { Assignment, Assignment2 } from '../Models/AssignmentModule/assignment';
import { Mappedassignment, Mappedassignment2 } from '../Models/AssignmentModule/mappedassignment';
import { Passkeyreport } from '../Models/GridReports/passkeyreport';
import { Batchreport } from '../Models/GridReports/batchreport';
import { Batchstudentreport } from '../Models/GridReports/batchstudentreport';
import { Partreport } from '../Models/GridReports/partreport';
import { Scetionreport } from '../Models/GridReports/scetionreport';
import { Chapterreport } from '../Models/GridReports/chapterreport';
import { Subchapterreport } from '../Models/GridReports/subchapterreport';
import { Mastersessionreport } from '../Models/GridReports/mastersessionreport';
import { Mastersessiondetailsreport } from '../Models/GridReports/mastersessiondetailsreport';
import { Batchsessionreport } from '../Models/GridReports/batchsessionreport';
import { Batchsessiodetailsnreport } from '../Models/GridReports/batchsessiodetailsnreport';
import { Mastersyllabusreport } from '../Models/GridReports/mastersyllabusreport';
import { Batchsyllabusreport } from '../Models/GridReports/batchsyllabusreport';
import { Notification } from '../Models/Notification/notification';
import { Shikuyaachapter } from '../Models/ShikuyaaModels/shikuyaachapter';
import { Document } from '../Models/ShikuyaaModels/document';
import { Video } from '../Models/ShikuyaaModels/video';
import { Videoquestiontype } from '../Models/ShikuyaaModels/videoquestiontype';
import { Paragraph } from '../Models/ShikuyaaModels/paragraph';
import { Predefinedmock } from '../Models/ShikuyaaModels/predefinedmock';
import { Livevideo } from '../Models/ShikuyaaModels/livevideo';
import { Coupanfacility } from '../Models/ShikuyaaModels/coupanfacility';
import { Coupan } from '../Models/ShikuyaaModels/coupan';
import { Videoquestion } from '../Models/ShikuyaaModels/videoquestion';
import { Shikuyaaquestion } from '../Models/ShikuyaaModels/shikuyaaquestion';
import { Livevideocomments } from '../Models/ShikuyaaModels/livevideocomments';
import { Pole } from '../Models/ShikuyaaModels/pole';
import { Livenotificationsetting } from '../Models/ShikuyaaModels/livenotificationsetting';
import { Livefffmapping, Livepolemapping } from '../Models/ShikuyaaModels/livepolemapping';
import { Trainer } from '../Models/ResourcePlanning/trainer';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Fffquestionmaster } from '../Models/ShikuyaaModels/fffquestionmaster';
import { Coupontype } from '../Models/ShikuyaaModels/coupontype';
import { Assignmentdetails, Assignmentdetails2 } from '../Models/AssignmentModule/assignmentdetails';
import { Slider } from '../Models/ShikuyaaModels/slider';
import { Emailtemplate } from '../Models/ShikuyaaModels/emailtemplate';
import { Associates } from '../Models/ShikuyaaWebsite/Masters/Associates';
import { Seo } from '../Models/ShikuyaaWebsite/Masters/Seo';
import { Team } from '../Models/ShikuyaaWebsite/Masters/Team';
import { Weidget } from '../Models/ShikuyaaWebsite/Masters/Weidget';
import { Review } from '../Models/ShikuyaaWebsite/Masters/Review';
import { KnowledgebaseCategory } from '../Models/ShikuyaaWebsite/Masters/KnowledgebaseCategory';
import { JobApplication } from '../Models/ShikuyaaWebsite/Masters/JobApplication';
import { SuccessStories } from '../Models/ShikuyaaWebsite/Masters/SuccessStories';
import { Blogs } from '../Models/ShikuyaaWebsite/Masters/Blog';
import { Menu } from '../Models/ShikuyaaWebsite/Masters/menu';
import { Contact } from '../Models/ShikuyaaWebsite/Contact';
import { Category } from '../Models/ShikuyaaWebsite/Masters/category';
import { DesignationWeb } from '../Models/ShikuyaaWebsite/Masters/designationweb';
import { Resume } from '../Models/ShikuyaaWebsite/Masters/resume';
import { ChapterKnowledgeBase } from '../Models/ShikuyaaWebsite/Masters/chapterknowledgebase';
import { Career } from '../Models/ShikuyaaWebsite/Masters/carrier';
import { Game } from '../Models/ShikuyaaWebsite/Masters/game';
import { Enrollment } from '../Models/ShikuyaaWebsite/Masters/enrollment';
import { StudentGameProgress } from '../Models/ShikuyaaWebsite/Masters/studentgameprogress';
import { CollegeAssociates } from '../Models/ShikuyaaWebsite/Masters/collegeassociate';
import { Page } from '../Models/ShikuyaaWebsite/Masters/page';
import { Blockss } from '../Models/ShikuyaaWebsite/Masters/blockss';
import { AppPromotionMaster } from '../Models/ShikuyaaWebsite/Masters/AppPromotionMaster';
import { AboutShikuyaa } from '../Models/ShikuyaaWebsite/Masters/aboutshikuyaa';
import { Banner } from '../Models/ShikuyaaWebsite/Masters/banner';
import { Feature } from '../Models/ShikuyaaWebsite/Masters/feature';
import { Type } from '../Models/ShikuyaaWebsite/Masters/typemaster';
import { PageForm } from '../Models/ShikuyaaWebsite/Masters/PageForm';
import { Company } from '../Models/ShikuyaaWebsite/Masters/company';
import { CareerPage } from '../Models/ShikuyaaWebsite/Masters/careerpage';
import { ContactPage } from '../Models/ShikuyaaWebsite/Masters/contactpage';
import { PolicyDetails } from '../Models/ShikuyaaWebsite/Masters/policydetail';
import { SiteMap } from '../Models/ShikuyaaWebsite/Masters/sitemap';
import { Sitemapheading } from '../Models/ShikuyaaWebsite/Masters/sitemapheading';
import { AboutPage } from '../Models/ShikuyaaWebsite/Masters/AboutPage';
import { Trainer1 } from '../Models/ShikuyaaWebsite/Masters/trainermaster';
import { SocialLink } from '../Models/ShikuyaaWebsite/Masters/sociallink';
import { Footer } from '../Models/ShikuyaaWebsite/Masters/footercontent';
import { FooterPageLink } from '../Models/ShikuyaaWebsite/Masters/footerlink';
import { NavBarContent } from '../Models/ShikuyaaWebsite/Masters/navbar';
import { Instructor } from '../Models/ShikuyaaWebsite/Masters/instructor';
import { CourseUserReview } from '../Models/ShikuyaaWebsite/Masters/courseuserreview';
import { InstructorLinks } from '../Models/ShikuyaaWebsite/Masters/instructorlink';
import { ButtonLink } from '../Models/ShikuyaaWebsite/Masters/buttonlink';
import { Webinar } from '../Models/ShikuyaaWebsite/webinar';
import { BlogWriter } from '../Models/ShikuyaaWebsite/Masters/blogwriter';
import { CourseItemMap } from '../Models/ShikuyaaWebsite/Masters/courseitemmap';
import{CompanyModel}from'../Models/ShikuyaaModels/company-model'
// import { StudentvideodetailsComponent } from '../pages/ShikuyaaForms/StudentWiseReports/studentvideodetails/studentvideodetails.component';
import{MetaTag} from 'src/app/meta-tag'
import{LivesessionTag} from 'src/app/livesession-tag'
@Injectable({
  providedIn: 'root'
})
export class ShikuyaaapiService {
  getCategoryHierarchyID(id: any) {
    throw new Error('Method not implemented.');
  }

  currentMessage = new BehaviorSubject(null);
  cloudID
  clientId = 1

  // headers for forms all
  httpHeaders = new HttpHeaders();
  options = {
    headers: this.httpHeaders
  };

  // headers for forms file upload call
  httpHeaders1 = new HttpHeaders();
  options1 = {
    headers: this.httpHeaders1
  };

  httpHeaders123 = new HttpHeaders();
  options123 = {
    headers: this.httpHeaders123
  };

  //// Baseurl For Production
  baseUrl = "https://admin.shikuyaa.com:5056/"

  //// Baseurl For Testing
// baseUrl = "https://panel.shikuyaa.com:5056/"



// baseUrl = "http://9b30-103-58-152-79.ngrok.io/"
// baseUrl = "http://18aa-122-177-19-226.ngrok.io/"
// 

  gmUrl = "https://gm.tecpool.in:8078/"
  // baseUrl = "http://a4b74911e6b7.ngrok.io/"

  imgUrl = this.baseUrl + "api/web/upload/"
  imgUrlnew = this.baseUrl + "api/upload/"
  retriveimgUrl = this.baseUrl + "static/";
  url = this.baseUrl + "api/"
  // url1 = this.baseUrl1 + "api/"
  dateforlog = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
  emailId = sessionStorage.getItem('emailId')
  userId = Number(sessionStorage.getItem('userId'))
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
 socketUrl = "https://admin.shikuyaa.com:5056"
  // socketUrl = "https://panel.shikuyaa   .com:5056"

  constructor(private cookie: CookieService, private message: NzNotificationService, private httpClient: HttpClient, private angularFireMessaging: AngularFireMessaging) {
    if (this.cookie.get('deviceId') === '' || this.cookie.get('deviceId') === null) {
      var deviceId = this.randomstring(16)
      this.cookie.set('deviceId', deviceId.toString(), 365, "", "", false, "Strict");
    }
    // else if(this.userId == null || this.userName == null || this.roleId == null)
    // {
    //   this.logoutForSessionValues()
    // }

    this.httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
    });
    this.options = {
      headers: this.httpHeaders
    };

    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }
  isFloat(inputString) {
    var regex = /^[0-9.,\b]+$/;

    var c = regex.test(inputString)
    if (c)
      return 'rightalign';
    else
      return 'leftalign';
  }
  getSecondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    if (h == 0)
      return m + " m " + s + " s "
    else
      return h + " h " + m + " m ";
  }
  getSecondsToHm(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    if (h == 0)
      return m + " m " 
    else
      return h + " h " + m + " m ";
  }

  nativeGlobal() { return window }
  //generate string as we pass length - LOGIN_FROMS
  randomstring(L) {
    var s = '';
    var randomchar = function () {
      var n = Math.floor(Math.random() * 62);
      if (n < 10) return n; //1-10
      if (n < 36) return String.fromCharCode(n + 55); //A-Z
      return String.fromCharCode(n + 61); //a-z
    }
    while (s.length < L) s += randomchar();
    return s;
  }

  generateRandomNumber(n) {
    return Math.floor(Math.random() * (9 * Math.pow(10, n - 1))) + Math.pow(10, n - 1);
  }

  logoutForSessionValues() {
    this.cookie.delete("supportKey")
    this.cookie.delete("token")
    sessionStorage.clear();
    window.location.reload()
  }

  //firebase methods - FIREBASE
  receiveMessage() {
    // this.angularFireMessaging.message.subscribe(
    //   (payload) => {
    //     console.log("new message received. ", payload);
    //     this.message.info(payload['data']['title'], payload['data']['body'])
    //     this.currentMessage.next(payload);
    //   })
  }

  requestPermission(userId) {
    // this.angularFireMessaging.requestToken.subscribe(
    //   (token) => {
    //     this.cloudID = token
    //     //this.updateToken(userId, token);
    //   },
    //   (err) => {
    //     console.error('Unable to get permission to notify.', err);
    //   }
    // );
  }




  // implement logger - LOGGER
  loggerInit() {
    this.httpHeaders1 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': 'SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };

    var data = {
      CLIENT_ID: this.clientId
    };
    return this.httpClient.post(this.gmUrl + "device/init", JSON.stringify(data), this.options1);
  }

  addLog(type, text, userId): Observable<number> {
    this.httpHeaders1 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': 'SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
     
    });
    this.options1 = {
      headers: this.httpHeaders1
    };
    var data = {
      LOG_TYPE: type,
      LOG_TEXT: this.dateforlog + " " + text,
      USER_ID: userId,
      CLIENT_ID: this.clientId
    };
    return this.httpClient.post<number>(this.gmUrl + "device/addLog", JSON.stringify(data), this.options1);
  }

  // login method - LOGIN
  login(email: string, password: string) {
    this.httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
    });
    this.options = {
      headers: this.httpHeaders
    };
    var data = {
      username: email,
      password: password,
    };
    return this.httpClient.post(this.baseUrl + "user/login", JSON.stringify(data), this.options);
  }


  onUpload(folderName, selectedFile, filename) :any{
    this.httpHeaders1 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'Token': this.cookie.get('token'),
      'supportkey': this.cookie.get('supportKey'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData()
    fd.append("Image", selectedFile, filename)
    // console.log("Image",this.imgUrl + folderName, fd, this.options1 )
    return this.httpClient.post(this.imgUrl + folderName, fd, this.options1)
      

  }
  onUploadnew(folderName, selectedFile, filename) :any{
    this.httpHeaders1 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'Token': this.cookie.get('token'),
      'supportkey': this.cookie.get('supportKey'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData()
    fd.append("Image", selectedFile, filename)
    
    return this.httpClient.post(this.imgUrlnew + folderName,fd, this.options1)
      

  }
  onUpload2(folderName, selectedFile, filename) {
    this.httpHeaders1 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'Token': this.cookie.get('token'),
      'supportkey': this.cookie.get('supportKey'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData()
    fd.append("Image", selectedFile, filename)
    return this.httpClient.post(this.baseUrl + 'upload/' + folderName, fd, this.options1)

  }
  // upload image with key - UPLOAD_IMAGE
  onUploadNewMethod(selectedFile, ext, fKey) {
    this.httpHeaders1 = new HttpHeaders({
      //'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'f_key': fKey,
      'f_ext': ext,
      'supportkey': this.cookie.get('supportKey'),
      'apikey': 'SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'Token': this.cookie.get('token'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };
    const fd = new FormData()
    fd.append("F_DATA", selectedFile)
    fd.append("F_EXT", ext)
    fd.append("F_KEY", fKey)
    return this.httpClient.post(this.gmUrl + 'file/upload', fd, this.options1)
  }
  onUploadNewBulk(folderName,selectedFile,filename) {
    this.httpHeaders1 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'Token': this.cookie.get('token'),
      'supportkey': this.cookie.get('supportKey'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData()
    fd.append("Image", selectedFile, filename)
    return this.httpClient.post(this.baseUrl + 'File/uploadBulk' + folderName, fd, this.options1)

  }


  onUploadNewMethodBulk(selectedFile) {
    //  console.log(selectedFile[0],selectedFile[0].name.split('.').pop(),selectedFile[0].f_key)
    this.httpHeaders1 = new HttpHeaders({
      //'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'f_key': selectedFile[0].f_key,
      'f_ext': selectedFile[0].name.split('.').pop(),
      'supportkey': this.cookie.get('supportKey'),
      'apikey': 'SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'Token': this.cookie.get('token'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData()
    console.log(selectedFile, selectedFile.length)
    if (selectedFile.length > 1) {
      for (let file of selectedFile) {
        let name = file.f_key + "_" + file.url_key + "." + file.name.split('.').pop();
        fd.append("F_DATA", file, name)
      }
      return this.httpClient.post(this.gmUrl + 'File/uploadBulk', fd, this.options1)
    }
    else {
      var list
      //  let name=selectedFile[0].f_key+"_"+selectedFile[0].url_key+"."+selectedFile[0].name.split('.').pop();

      fd.append("F_DATA", selectedFile[0])
      fd.append("F_EXT", selectedFile[0].name.split('.').pop())
      fd.append("F_KEY", selectedFile[0].f_key)
      let url_key = selectedFile[0].url_key
      console.log("URL_KEY " + url_key)
      var singleData = this.httpClient.post(this.gmUrl + 'file/upload', fd, this.options1)
      return singleData
    }


  }


  //retrive file - GETTING_FILE
  getFile(lkey) {
    this.httpHeaders1 = new HttpHeaders({
      'Accept': 'application/pdf',
      'Access-Control-Allow-Origin': '*',
      'supportkey': this.cookie.get('supportKey'),
      'apikey': 'SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'Token': this.cookie.get('token'),
    });
    this.options1 = {
      headers: this.httpHeaders1
    };

    var data = {
      L_KEY: lkey
    };
    return this.httpClient.post<any>(this.gmUrl + 'file/getFile', data, this.options1)
  }

  // onUpload(folderName,selectedFile,filename){
  //   console.log(this.httpHeaders1)
  //   const fd=new FormData()
  //   fd.append("Image",selectedFile,filename)
  //   console.log("selected file"+JSON.stringify(selectedFile))
  //   console.log("form data: "+fd)
  //   this.httpClient.post(this.imgUrl+folderName,fd ,this.options1)
  //     .subscribe(res => {
  //       console.log(res);
  //     });
  // }
  // uploadSlider


  //get all Form For login menu
  getForms(roleId: number) {
    var data = {
      ROLE_ID: roleId,
    };
    return this.httpClient.post<Roledetails>(this.url + "user/getForms", JSON.stringify(data), this.options);
  }

  getCheckAccessOfForm(roleId: number, link: string) {
    var data = {
      ROLE_ID: roleId,
      LINK: link
    };
    return this.httpClient.post<Roledetails>(this.url + "roleDetails/checkAccess", JSON.stringify(data), this.options);
  }
  getAllPartByCourse(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Form[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Form[]>(this.url + "shikuyaaChapter/get", JSON.stringify(data), this.options);
  }
  //methods for form related opearation  - FORM
  getAllForms(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Form[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Form[]>(this.url + "form/get", JSON.stringify(data), this.options);
  }

  createForm(form: Form): Observable<number> {
    form.CLIENT_ID = this.clientId
    return this.httpClient.post<number>(this.url + "form/create/", JSON.stringify(form), this.options);
  }

  updateForm(form: Form): Observable<number> {
    form.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "form/update/", JSON.stringify(form), this.options);
  }
  //method for student colleges
getAllStudentColleges(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CompanyModel[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
  };
  return this.httpClient.post<CompanyModel[]>(this.url + "shikuyaaCollege/get", JSON.stringify(data), this.options);
}
//method for student branch
getAllStudentBranches(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CompanyModel[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
  };
  return this.httpClient.post<CompanyModel[]>(this.url + "shikuyaaBranch/get", JSON.stringify(data), this.options);
}
//method for student State
getAllStudentStates(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CompanyModel[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
  };
  return this.httpClient.post<CompanyModel[]>(this.url + "shikuyaaState/get", JSON.stringify(data), this.options);
}
//method for student years
getAllStudentYears(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CompanyModel[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
  };
  return this.httpClient.post<CompanyModel[]>(this.url + "shikuyaaYear/get", JSON.stringify(data), this.options);
}
//methos for company
getAllCompanys(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CompanyModel[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    
  };
  return this.httpClient.post<CompanyModel[]>(this.url + "company/get", JSON.stringify(data), this.options);
}

createCompanys(company: CompanyModel): Observable<number> {

  company.CLIENT_ID=this.clientId;

  return this.httpClient.post<number>(this.url + "company/create", JSON.stringify(company), this.options);
}

updateCompanys(company: CompanyModel): Observable<number> {
  
  company.CLIENT_ID=this.clientId;
 
  return this.httpClient.put<number>(this.url + "company/update", JSON.stringify(company), this.options);
}

  //method for category master 
  getAllCourseCategory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CourseCategory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<CourseCategory[]>(this.url + "courseCategory/get", JSON.stringify(data), this.options);
  }
  
  //method for category master 
 
  createCourseCategory(courseCategory: CourseCategory): Observable<number> {
 
    courseCategory.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "courseCategory/create/", JSON.stringify(courseCategory), this.options);
  }
 
  createCourseMetaTags(courseCategory: MetaTag): Observable<number> {
 
    courseCategory.CLIENT_ID=this.clientId;
    console.log(courseCategory)
    return this.httpClient.post<number>(this.url + "contentProperty/create", JSON.stringify(courseCategory), this.options);
  }
  updateCourseMetaTags(courseCategory: MetaTag): Observable<number> {
 
    courseCategory.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "contentProperty/update", JSON.stringify(courseCategory), this.options);
  }
  getAllCourseMetaTags(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<number> {
 
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<number>(this.url + "contentProperty/get", JSON.stringify(data), this.options);
  }
  getAllCourseMetaTagsDetails(courseID:any): Observable<number> {
 
   var data={
    COURSE_ID:courseID
   }
    return this.httpClient.post<number>(this.url + "courseContentPropertyDetails/getData", JSON.stringify(data), this.options);
  }
  addBulkContents(courseID:any,datas:MetaTag[]): Observable<number> {
 
    var data={
     COURSE_ID:courseID,
     data:datas,
     
    }
     return this.httpClient.post<number>(this.url + "courseContentPropertyDetails/addBulk", JSON.stringify(data), this.options);
   }
  updateCourseCategory(courseCategory: CourseCategory): Observable<number> {
    
    courseCategory.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "courseCategory/update/", JSON.stringify(courseCategory), this.options);
  }

  //method for que category master 
  getAllQueCategory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<FreeQueCategory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<FreeQueCategory[]>(this.url + "shikuyaaQuestionCategory/get", JSON.stringify(data), this.options);
  }
  
  //method for category master 
 
  createQueCategory(courseCategory: FreeQueCategory): Observable<number> {
 
    courseCategory.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "shikuyaaQuestionCategory/create", JSON.stringify(courseCategory), this.options);
  }

  updateQueCategory(courseCategory: FreeQueCategory): Observable<number> {
    
    courseCategory.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "shikuyaaQuestionCategory/update", JSON.stringify(courseCategory), this.options);
  }
   //method for que category master 
   getAllLiveCategory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<LiveClassesCategory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<LiveClassesCategory[]>(this.url + "web/liveClassesCategory/get", JSON.stringify(data), this.options);
  }
  
  //method for category master 
 
  createLiveCategory(courseCategory: LiveClassesCategory): Observable<number> {
 
    courseCategory.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/liveClassesCategory/create", JSON.stringify(courseCategory), this.options);
  }

  updateLiveCategory(courseCategory: LiveClassesCategory): Observable<number> {
    
    courseCategory.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/liveClassesCategory/update", JSON.stringify(courseCategory), this.options);
  }
  //methods for role related opearation  - ROLE
  getAllRoles(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Role[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Role[]>(this.url + "role/get", JSON.stringify(data), this.options);
  }

  createRole(application: Role): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "role/create/", JSON.stringify(application), this.options);
  }

  updateRole(application: Role): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "role/update/", JSON.stringify(application), this.options);
  }

  //get all form assigned - ROLE_DETAILS
  getRoleDetails(roleId: number) {
    var data = {
      ROLE_ID: roleId
    };
    console.log(roleId)
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }

  //assign all method forms - ROLE_DETAILS
  addRoleDetails(roleId: number, data1: string[]): Observable<number> {
    console.log(roleId)
    var data = {
      ROLE_ID: roleId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "roleDetails/addBulk", data, this.options);
  }

  //method for user replated opearation - USER
  getAllUsers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<User[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<User[]>(this.url + "user/get", JSON.stringify(data), this.options);
  }

  createUser(user: User): Observable<number> {
    user.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "user/create/", JSON.stringify(user), this.options);
  }

  updateUser(user: User): Observable<number> {
    user.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "user/update/", JSON.stringify(user), this.options);
  }

  //method for Part replated opearation - PARTS
  getAllParts(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Part[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Part[]>(this.url + "part/get", JSON.stringify(data), this.options);
  }

  //method for Part replated opearation - PARTS
  getAllChaptersDirect(): Observable<string> {
    var data = {

    };
    return this.httpClient.post<string>(this.url + "chapter/getChapters", JSON.stringify(data), this.options);
  }

  createPart(part: Part): Observable<number> {
    part.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "part/create/", JSON.stringify(part), this.options);
  }

  updatePart(part: Part): Observable<number> {

    part.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "part/update/", JSON.stringify(part), this.options);
  }


  //method for Section replated opearation - SECTION

  getAllSections(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Section[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Section[]>(this.url + "section/get", JSON.stringify(data), this.options);
  }

  createSection(section: Section): Observable<number> {
    section.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "section/create/", JSON.stringify(section), this.options);
  }

  updateSection(section: Section): Observable<number> {
    section.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "section/update/", JSON.stringify(section), this.options);
  }

  //method for Section replated opearation - CHAPTER
  getAllChapters(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Chapter[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Chapter[]>(this.url + "chapter/get", JSON.stringify(data), this.options);
  }

  createChapter(chapter: Chapter): Observable<number> {

    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "chapter/create/", JSON.stringify(chapter), this.options);
  }

  updateChapter(chapter: Chapter): Observable<number> {

    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "chapter/update/", JSON.stringify(chapter), this.options);
  }

  //method for Question Type replated opearation - QUESTION_TYPE
  getAllQuestionTypes(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Questiontype[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Questiontype[]>(this.url + "questionType/get", JSON.stringify(data), this.options);
  }

  createQuestionType(questionType: Questiontype): Observable<number> {

    questionType.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "questionType/create/", JSON.stringify(questionType), this.options);
  }

  updateQuestionType(questionType: Questiontype): Observable<number> {

    questionType.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "questionType/update/", JSON.stringify(questionType), this.options);
  }

  //method for Level replated opearation - LEVEL

  getAllLevels(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Level[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Level[]>(this.url + "level/get", JSON.stringify(data), this.options);
  }

  createLevel(level: Level): Observable<number> {

    level.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "level/create/", JSON.stringify(level), this.options);
  }

  updateLevel(level: Level): Observable<number> {

    level.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "level/update/", JSON.stringify(level), this.options);
  }

  //method for State replated opearation - STATE
  getAllStates(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<State[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<State[]>(this.url + "state/get", JSON.stringify(data), this.options);
  }

  createState(state: State): Observable<number> {
    state.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shikuyaaState/create/", JSON.stringify(state), this.options);
  }

  updateState(state: State): Observable<number> {
    state.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "shikuyaaState/update/", JSON.stringify(state), this.options);
  }

  //method for Region replated opearation - REGION
  getAllRegions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Region[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Region[]>(this.url + "region/get", JSON.stringify(data), this.options);
  }

  createRegion(region: Region): Observable<number> {

    region.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "region/create/", JSON.stringify(region), this.options);
  }

  updateRegion(region: Region): Observable<number> {

    region.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "region/update/", JSON.stringify(region), this.options);
  }

  //method for Region replated opearation - DISTRICT

  getAllDistricts(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<District[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<District[]>(this.url + "shikuyaaDistrict/get", JSON.stringify(data), this.options);
  }

  createDistrict(district: District): Observable<number> {

    district.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shikuyaaDistrict/create/", JSON.stringify(district), this.options);
  }

  updateDistrict(district: District): Observable<number> {

    district.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "shikuyaaDistrict/update/", JSON.stringify(district), this.options);
  }

  //method for College Type replated opearation - COLLEGE_TYPE
  getAllCollegetypes(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Collegetype[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Collegetype[]>(this.url + "collegetype/get", JSON.stringify(data), this.options);
  }

  createCollegetype(collegetype: Collegetype): Observable<number> {

    collegetype.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "collegetype/create/", JSON.stringify(collegetype), this.options);
  }

  updateCollegetype(collegetype: Collegetype): Observable<number> {

    collegetype.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "collegetype/update/", JSON.stringify(collegetype), this.options);
  }

  //method for Designation replated opearation - DESIGNATION
  getAllDesignations(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Designation[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Designation[]>(this.url + "designation/get", JSON.stringify(data), this.options);
  }

  createDesignation(designation: Designation): Observable<number> {

    designation.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "designation/create/", JSON.stringify(designation), this.options);
  }

  updateDesignation(designation: Designation): Observable<number> {

    designation.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "designation/update/", JSON.stringify(designation), this.options);
  }

  //method for Document Type replated opearation - DOCUMENT_TYPE
  getAllDocumentTypes(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Documenttype[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Documenttype[]>(this.url + "documentType/get", JSON.stringify(data), this.options);
  }

  createDocumentType(documentType: Documenttype): Observable<number> {
    documentType.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "documentType/create/", JSON.stringify(documentType), this.options);
  }

  updateDocumentType(documentType: Documenttype): Observable<number> {
    documentType.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "documentType/update/", JSON.stringify(documentType), this.options);
  }


  //method for Branch replated opearation - BRANCH
  getAllBranches(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Branch[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Branch[]>(this.url + "branch/get", JSON.stringify(data), this.options);
  }

  createBranch(branch: Branch): Observable<number> {

    branch.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shikuyaaBranch/create/", JSON.stringify(branch), this.options);
  }

  updateBranch(branch: Branch): Observable<number> {

    branch.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "shikuyaaBranch/update/", JSON.stringify(branch), this.options);
  }



  //method for Passing Year replated opearation - PASSING_YEAR
  getAllPassingYears(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Passingyear[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Passingyear[]>(this.url + "passingYear/get", JSON.stringify(data), this.options);
  }


  createPassingYear(passingYear: Passingyear): Observable<number> {
    passingYear.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "passingYear/create/", JSON.stringify(passingYear), this.options);
  }

  updatePassingYear(passingYear: Passingyear): Observable<number> {
    passingYear.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "passingYear/update/", JSON.stringify(passingYear), this.options);
  }

  //method for Division replated opearation - DIVISION
  getAllDivisions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Division[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Division[]>(this.url + "division/get", JSON.stringify(data), this.options);
  }

  createDivision(division: Division): Observable<number> {

    division.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "division/create/", JSON.stringify(division), this.options);
  }

  updateDivision(division: Division): Observable<number> {

    division.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "division/update/", JSON.stringify(division), this.options);
  }


  //method for CourseType replated opearation - COURSE_TYPE
  getAllCourseTypes(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Coursetype[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Coursetype[]>(this.url + "courseType/get", JSON.stringify(data), this.options);
  }

  createCourseType(courseType: Coursetype): Observable<number> {

    courseType.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "courseType/create/", JSON.stringify(courseType), this.options);
  }

  updateCourseType(courseType: Coursetype): Observable<number> {

    courseType.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "courseType/update/", JSON.stringify(courseType), this.options);
  }

  //method for Vehicle replated opearation - VEHICLE
  getAllVehicles(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Vehicle[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Vehicle[]>(this.url + "vehicle/get", JSON.stringify(data), this.options);
  }

  createVehicle(vehicle: Vehicle): Observable<number> {

    vehicle.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "vehicle/create/", JSON.stringify(vehicle), this.options);
  }

  updateVehicle(vehicle: Vehicle): Observable<number> {

    vehicle.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "vehicle/update/", JSON.stringify(vehicle), this.options);
  }


  //method for Question Pararaph replated opearation - QUESTION_PARAGRAPH
  getAllQuestionParagraphs(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Questionparagraph[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Questionparagraph[]>(this.url + "questionParagraph/get", JSON.stringify(data), this.options);
  }

  createQuestionParagraph(questionParagraph: Questionparagraph): Observable<number> {
    questionParagraph.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "questionParagraph/create/", JSON.stringify(questionParagraph), this.options);
  }

  updateQuestionParagraph(questionParagraph: Questionparagraph): Observable<number> {
    questionParagraph.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "questionParagraph/update/", JSON.stringify(questionParagraph), this.options);
  }
//load all student webinar data
getAllRegisteredUsers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,webinarId,enrolledId): Observable<Course[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    WEBINAR_ID:webinarId,
    IS_ENROLLED:enrolledId
  };
  return this.httpClient.post<Course[]>(this.baseUrl + "api/reports/getStudentWebinarEnrollmentDetails", JSON.stringify(data), this.options);
}
//mobile Otp Report
getMobileOtpReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Course[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
   
  };
  return this.httpClient.post<Course[]>(this.baseUrl + "api/registrationOtpDetails/get", JSON.stringify(data), this.options);
}
//email Otp Report
getEmailOtpReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Course[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
   
  };
  return this.httpClient.post<Course[]>(this.baseUrl + "api/registrationOtpDetails/get", JSON.stringify(data), this.options);
}
  //method for Courses replated opearation - COURSE
  getAllCourses(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Course[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Course[]>(this.url + "course/get", JSON.stringify(data), this.options);
  }

  createCourse(course: Course): Observable<number> {

    course.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "course/create", JSON.stringify(course), this.options);
  }

 
  updateCourse(course: Course): Observable<number> {

    course.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "course/update", JSON.stringify(course), this.options);
  }

  //method for Training Courses replated opearation - TRAINING_COURSE
  getAllTrainingCourses(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Trainingcourse[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Trainingcourse[]>(this.url + "trainingCourse/get", JSON.stringify(data), this.options);
  }

  createTrainingCourse(trainingCourse: Trainingcourse): Observable<number> {

    trainingCourse.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "trainingCourse/create/", JSON.stringify(trainingCourse), this.options);
  }

  updateTrainingCourse(trainingCourse: Trainingcourse): Observable<number> {

    trainingCourse.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "trainingCourse/update/", JSON.stringify(trainingCourse), this.options);
  }

  //method for Training Courses replated opearation - TRAINING_COURSE
  getAllHolidayCalendars(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Holidaycalender[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Holidaycalender[]>(this.url + "holidayCalender/get", JSON.stringify(data), this.options);
  }

  updateHolidayCalendar(holidayCalendar: Holidaycalender): Observable<number> {
    holidayCalendar.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "holidayCalender/update/", JSON.stringify(holidayCalendar), this.options);
  }

  //assign - HOLIDAY_DETAILS
  addHolidayDeatails(year: string): Observable<number> {
    var data = {
      YEAR: year,
      CLIENT_ID: this.clientId
    };
    console.log(year, this.clientId)
    return this.httpClient.post<number>(this.url + "holidayCalender/create", data, this.options);
  }

  //get all Question Assigned - MAPPED_QUESTIONS
  getQuestionsOfParagraph(ParagraphId: number) {
    var data = {
      PARAGRAPH_ID: ParagraphId
    };
    return this.httpClient.post<string[]>(this.url + "asssign/get", JSON.stringify(data), this.options);
  }

  //assign all Questions To Pragraph - MAP_QUESTIONS
  addQuestionsToParagraph(ParagraphId: number, data1: string[]): Observable<number> {
    var data = {
      PARAGRAPH_ID: ParagraphId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "asssign/addBulk", data, this.options);
  }

  //method for SubChapter replated opearation - SUB_CHAPTER
  getAllSubChapters(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Subchapter[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Subchapter[]>(this.url + "subChapter/get", JSON.stringify(data), this.options);
  }

  createSubChapter(chapter: Subchapter): Observable<number> {
    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "subChapter/create/", JSON.stringify(chapter), this.options);
  }

  updateSubChapter(chapter: Subchapter): Observable<number> {
    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "subChapter/update/", JSON.stringify(chapter), this.options);
  }


  //method for College replated opearation - COLLEGE
  getAllColleges(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<College[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<College[]>(this.url + "college/get", JSON.stringify(data), this.options);
  }
  createCollege(college: any): Observable<number> {

    college.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shikuyaaCollege/create/", JSON.stringify(college), this.options);
  }
  updateCollege(college: any): Observable<number> {
    college.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "shikuyaaCollege/update/", JSON.stringify(college), this.options);
  }


  //method for CollegeContact replated opearation - COLLEGE_CONTACT_DETAILS
  getAllCollegeContactDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Collegecontact[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Collegecontact[]>(this.url + "collegeContactDetails/get", JSON.stringify(data), this.options);
  }
  createCollegeContactDetails(collegec: Collegecontact): Observable<number> {

    collegec.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "collegeContactDetails/create/", JSON.stringify(collegec), this.options);
  }
  updateCollegeContactDetails(collegec: Collegecontact): Observable<number> {
    console.log(collegec)
    collegec.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "collegeContactDetails/update/", JSON.stringify(collegec), this.options);
  }


  //method for Documents replated opearation - COLLEGE_DOCUMENTS
  getAllCollegeDocuments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Collegedocument[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Collegedocument[]>(this.url + "collegeDocumentArchieve/get", JSON.stringify(data), this.options);
  }
  createCollegeDocument(colleged: Collegedocument): Observable<number> {
    var data = {
      COLLEGE_ID: colleged.COLLEGE_ID,
      DOCUMENT_TYPE_ID: colleged.DOCUMENT_TYPE_ID,
      DOCUMENT_URL: colleged.DOCUMENT_URL,
      IS_ACTIVE: colleged.IS_ACTIVE,
      NAME: colleged.NAME,
      CLIENT_ID: this.clientId,
      DATA: colleged.USERS
    };
    return this.httpClient.post<number>(this.url + "collegeDocumentArchieve/create/", data, this.options);
  }
  updateCollegeDocument(colleged: Collegedocument): Observable<number> {
    var data = {
      ID: colleged.ID,
      COLLEGE_ID: colleged.COLLEGE_ID,
      DOCUMENT_TYPE_ID: colleged.DOCUMENT_TYPE_ID,
      DOCUMENT_URL: colleged.DOCUMENT_URL,
      IS_ACTIVE: colleged.IS_ACTIVE,
      NAME: colleged.NAME,
      CLIENT_ID: this.clientId,
      DATA: colleged.USERS
    };

    return this.httpClient.put<number>(this.url + "collegeDocumentArchieve/update/", data, this.options);
  }

  getAllCollegeDocumentsSharing(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "documentSharing/get", JSON.stringify(data), this.options);
  }

  //get all college type assigned - COLLEGE_TYPE_MAPPING
  getCollegeTypeMapping(collegeId: number) {
    var data = {
      COLLEGE_ID: collegeId
    };
    console.log(collegeId)
    return this.httpClient.post<Collegetypemapping[]>(this.url + "collegeTypeMapping/getData", JSON.stringify(data), this.options);
  }

  //assign all College Type - COLLEGE_TYPE_MAPPING
  addCollegeTypeMapping(collegeId: number, data1: string[]): Observable<number> {
    console.log(data1)
    var data = {
      COLLEGE_ID: collegeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "collegeTypeMapping/addBulk", data, this.options);
  }



  //get all Branch assigned - COLLEGE_BRANCH_MAPPING
  getBranchMapping(collegeId: number) {
    var data = {
      COLLEGE_ID: collegeId
    };
    console.log("college Id: " + collegeId)
    return this.httpClient.post<Collegebranchmapping[]>(this.url + "collegeBranchMapping/getData", JSON.stringify(data), this.options);
  }

  //assign all College Branch - COLLEGE_BRANCH_MAPPING
  addCollegeBranchMapping(collegeId: number, data1: string[]): Observable<number> {
    var data = {
      COLLEGE_ID: collegeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "collegeBranchMapping/addBulk", data, this.options);
  }
  getAllCollegeHolidayCalender(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Collegeholidaycalender[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Collegeholidaycalender[]>(this.url + "collegeHoliday/get", JSON.stringify(data), this.options);
  }

  updateCollegeHolidayCalender(holiday: Collegeholidaycalender): Observable<number> {
    holiday.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "collegeHoliday/update/", JSON.stringify(holiday), this.options);
  }


  //get all batches - Batch Formation
  getAllBatches(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batch[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batch[]>(this.url + "batch/get", JSON.stringify(data), this.options);
  }

  //get all Training Course assigned - COLLEGE_TRAINING_COURSE_MAPPING
  getTrainingCourseMapping(collegeId: number) {
    var data = {
      COLLEGE_ID: collegeId
    };
    return this.httpClient.post<Collegetrainingcoursemapping[]>(this.url + "batchFormation/getCollegeTrainingCourses", JSON.stringify(data), this.options);
  }

  //assign all Training Course - TRAINING_COURSE_MAPPING
  addTrainingCourseMapping(collegeId: number, data1: string[]): Observable<number> {
    var data = {
      COLLEGE_ID: collegeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "batchFormation/putCollegeTrainingCourses", data, this.options);
  }

  //get all Training Course assigned - COLLEGE_TRAINING_COURSE_BARNCH_MAPPING
  getTrainingCourseBranchMapping(collegeId: number) {
    var data = {
      COLLEGE_ID: collegeId
    };
    return this.httpClient.post<string[]>(this.url + "batchFormation/getCollegeTrainingBranches", JSON.stringify(data), this.options);
  }

  //assign all Training Course Branches - TRAINING_COURSE_BRANCH_MAPPING
  addTrainingCourseBranchMapping(collegeId: number, data1: string[]): Observable<number> {
    var data = {
      COLLEGE_ID: collegeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "batchFormation/putCollegeTrainingBranches", data, this.options);
  }

  //get all Training Course Branch Year assigned - COLLEGE_TRAINING_COURSE_BARNCH_YEAR_MAPPING
  getTrainingCourseBranchYearMapping(collegeId: number) {
    var data = {
      COLLEGE_ID: collegeId
    };
    return this.httpClient.post<string[]>(this.url + "batchFormation/getCollegeTrainingYears", JSON.stringify(data), this.options);
  }
  //assign all Training Course Branch Year - TRAINING_COURSE_BRANCH_YEAR_MAPPING
  addTrainingCourseBranchYearMapping(collegeId: number, data1: string[]): Observable<number> {
    var data = {
      COLLEGE_ID: collegeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "batchFormation/putCollegeTrainingYears", data, this.options);
  }
  //get all Training Course Branch Year Division assigned - COLLEGE_TRAINING_COURSE_BARNCH_YEAR_DIVISION_MAPPING
  getTrainingCourseBranchYearDivisionMapping(collegeId: number) {
    var data = {
      COLLEGE_ID: collegeId
    };
    return this.httpClient.post<string[]>(this.url + "batchFormation/getCollegeTrainingDivisions", JSON.stringify(data), this.options);
  }

  //assign all Training Course Branch Year Division - TRAINING_COURSE_BRANCH_YEAR_DIVISION_MAPPING
  addTrainingCourseBranchYearDivisionMapping(collegeId: number, data1: string[]): Observable<number> {
    var data = {
      COLLEGE_ID: collegeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "batchFormation/putCollegeTrainingDivisions", data, this.options);
  }

  //get Passkeys - PASSKEYS
  getBatches(collegeId: number) {
    var data = {
      COLLEGE_ID: collegeId
    };
    return this.httpClient.post<string[]>(this.url + "batchFormation/getCollegeTrainingPasskeys", JSON.stringify(data), this.options);
  }

  //Add passkeys - Passkeys
  addBatches(collegeId: number, data1: string[]): Observable<number> {
    var data = {
      COLLEGE_ID: collegeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "batchFormation/putCollegeTrainingPasskeys", data, this.options);
  }

  updateBatch(batch: Batch): Observable<number> {
    batch.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "batch/update/", JSON.stringify(batch), this.options);
  }

  //get all Sub Batches assigned - SUB_BATCH
  getAllSubBatch(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batch[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batch[]>(this.url + "batch/get", JSON.stringify(data), this.options);
  }

  //assign all Sub Batches - SubBatch
  addSubBatches(data1: string[]): Observable<number> {
    var data = {
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "batch/createSubBatches", data, this.options);
  }

  deleteSubBatches(batchId: number): Observable<number> {
    var data = {
      BATCH_ID: batchId
    };
    return this.httpClient.post<number>(this.url + "batch/deleteSubbatch", data, this.options);
  }


  //method for Master Session replated opearation - MASTER_SESSION
  getAllMasterSessions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Mastersession[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Mastersession[]>(this.url + "masterSession/get", JSON.stringify(data), this.options);
  }
  createMasterSession(masterSession: Mastersession): Observable<number> {
    masterSession.CLIENT_ID = this.clientId
    return this.httpClient.post<number>(this.url + "masterSession/Create", JSON.stringify(masterSession), this.options);
  }

  updateMasterSession(masterSession: Mastersession): Observable<number> {
    masterSession.CLIENT_ID = this.clientId
    return this.httpClient.put<number>(this.url + "masterSession/update", JSON.stringify(masterSession), this.options);
  }

  getAllMasterSessionDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, sessionId: number, chapterId: number): Observable<Mastersessiondetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      MASTER_SESSION_ID: sessionId,
      CHAPTER_ID: chapterId,
    };
    return this.httpClient.post<Mastersessiondetails[]>(this.url + "masterSession/getSessionSubChapters", JSON.stringify(data), this.options);
  }

  getAllSyllabusBatchGroup(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Syllabusbatchgroup[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Syllabusbatchgroup[]>(this.url + "syllabusBatchGroup/get", JSON.stringify(data), this.options);
  }

  createSyllabusBatchGroup(level: Syllabusbatchgroup): Observable<number> {
    level.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "syllabusBatchGroup/create/", JSON.stringify(level), this.options);
  }

  updateSyllabusBatchGroup(level: Syllabusbatchgroup): Observable<number> {

    level.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "syllabusBatchGroup/update/", JSON.stringify(level), this.options);
  }

  getBatchGroupSyllabusMapped(groupId: number, filter: string) {
    var data = {
      GROUP_ID: groupId,
      FILTER: filter
    };
    return this.httpClient.post<string[]>(this.url + "syllabusBatchGroupBatchMapping/getData", JSON.stringify(data), this.options);
  }

  addBatchGroupSyllabusMapping(groupId: number, data1): Observable<number> {
    var data = {
      GROUP_ID: groupId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "syllabusBatchGroupBatchMapping/addBulk", data, this.options);
  }


  getAllbatchSyllabusGroupMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "syllabusBatchGroupBatchMapping/get", JSON.stringify(data), this.options);
  }

  getAllBatchSyllabusDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batchleveltimer[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batchleveltimer[]>(this.url + "batchLevelTimer/get", JSON.stringify(data), this.options);
  }

  getbatchGroupSyllabusDetails(groupId: number): Observable<string[]> {
    var data = {
      GROUP_ID: groupId
    };
    return this.httpClient.post<string[]>(this.url + "batchSessionSyllabus/getSessionSyllabus", JSON.stringify(data), this.options);
  }

  addBatchSyllabusMapping(groupId, timerData, batchSyllabusDetails): Observable<number> {
    var data = {
      GROUP_ID: groupId,
      data1: timerData,
      data2: batchSyllabusDetails
    };
    return this.httpClient.post<number>(this.url + "batchSessionSyllabus/createSessionSyllabus", data, this.options);
  }

  getAllSchedulingBatchGroups(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Schedulingbatchgroup[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Schedulingbatchgroup[]>(this.url + "schedulingBatchGroup/get", JSON.stringify(data), this.options);
  }

  createSchedulingBatchGroup(schedulingBatchGroup: Schedulingbatchgroup): Observable<number> {
    schedulingBatchGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "schedulingBatchGroup/create/", JSON.stringify(schedulingBatchGroup), this.options);
  }

  updateSchedulingBatchGroup(schedulingBatchGroup: Schedulingbatchgroup): Observable<number> {
    schedulingBatchGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "schedulingBatchGroup/update/", JSON.stringify(schedulingBatchGroup), this.options);
  }

  getAllScheduledData(collegeId: number, month: string, year: string): Observable<string[]> {
    var data = {
      COLLEGE_ID: collegeId,
      MONTH: month,
      YEAR: year,
    };
    return this.httpClient.post<string[]>(this.url + "batchSchedule/retrieveSchedule", JSON.stringify(data), this.options);
  }


  createBatchSchedule(createdData, fromDate, ToDate, batchGroupId: number): Observable<number> {
    var data = {
      data: createdData,
      CLIENT_ID: this.clientId,
      BATCH_GROUP_ID: batchGroupId,
      FROM_DATE: fromDate,
      TO_DATE: ToDate,
    };
    return this.httpClient.post<number>(this.url + "batchSchedule/createSchedule", data, this.options);
  }

  getAllScheduleStatsOrOverlappedData(collegeId, fromDate, ToDate, days: string, slots: string[], batchGroupId: number) {
    var data = {
      COLLEGE_ID: collegeId,
      FROM_DATE: fromDate,
      TO_DATE: ToDate,
      DAYS: days,
      SLOTS_DATA: slots,
      GROUP_ID: batchGroupId
    };
    return this.httpClient.post<string[]>(this.url + "batchSchedule/retrieveScheduleStat", JSON.stringify(data), this.options);
  }

  getAllScheduledDataPerDay(collegeId: number, date): Observable<string[]> {
    var data = {
      COLLEGE_ID: collegeId,
      DATE: date
    };
    return this.httpClient.post<string[]>(this.url + "batchSchedule/retrieveDaySchedule", JSON.stringify(data), this.options);
  }


  getBatchGroupScheduleMapped(groupId: number, filter: string) {
    var data = {
      GROUP_ID: groupId,
      FILTER: filter
    };
    return this.httpClient.post<string[]>(this.url + "schedulingBatchGroupBatchMapping/getData", JSON.stringify(data), this.options);
  }

  addBatchGroupScheduleMapping(groupId: number, data1): Observable<number> {
    var data = {
      GROUP_ID: groupId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "schedulingBatchGroupBatchMapping/addBulk", data, this.options);
  }

  getAllResourceBatchGroups(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Resourcebatchgroup[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Resourcebatchgroup[]>(this.url + "resourceBatchGroup/get", JSON.stringify(data), this.options);
  }

  createResourceBatchGroup(resourceBatchGroup: Resourcebatchgroup): Observable<number> {
    resourceBatchGroup.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(this.url + "resourceBatchGroup/create/", JSON.stringify(resourceBatchGroup), this.options);
  }

  updateResourceBatchGroup(resourceBatchGroup: Resourcebatchgroup): Observable<number> {
    return this.httpClient.put<number>(this.url + "resourceBatchGroup/update/", JSON.stringify(resourceBatchGroup), this.options);
  }

  getBatchGroupResourceMapped(groupId: number, filter: string) {
    var data = {
      GROUP_ID: groupId,
      FILTER: filter
    };
    return this.httpClient.post<string[]>(this.url + "resourceBatchGroupBatchMapping/getData", JSON.stringify(data), this.options);
  }

  addBatchGroupResourceMapping(groupId: number, data1): Observable<number> {
    var data = {
      GROUP_ID: groupId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "resourceBatchGroupBatchMapping/addBulk", data, this.options);
  }

  getBatchGroupResourceTrainerMapped(groupId: number) {
    var data = {
      GROUP_ID: groupId
    };
    return this.httpClient.post<string[]>(this.url + "resourceBatchGroupTrainerMapping/getData", JSON.stringify(data), this.options);
  }

  addBatchGroupResourceTrainerMapping(groupId: number, data1): Observable<number> {
    var data = {
      GROUP_ID: groupId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "resourceBatchGroupTrainerMapping/addBulk", data, this.options);
  }

  getPerBatchScheduledData(scheduleId: number): Observable<string[]> {
    var data = {
      SCHEDULE_ID: scheduleId,
    };
    return this.httpClient.post<string[]>(this.url + "batchSchedule/retrieveScheduleDetails", JSON.stringify(data), this.options);
  }

  updatePerBatchScheduledData(scheduleId, IsForAllBatches, newStartTime, newEndTime, newBreakStartTime, newBreakEndTime, tillFlag, tillDate, scheduleStartDate, scheduleEndDate, seletedDate): Observable<string[]> {
    var data = {
      SCHEDULE_ID: scheduleId,
      IS_FOR_ALL_BATCHES: IsForAllBatches,
      NEW_START_TIME: newStartTime,
      NEW_END_TIME: newEndTime,
      NEW_BREAK_START_TIME: newBreakStartTime,
      NEW_BREAK_END_TIME: newBreakEndTime,
      TILL_FLAG: tillFlag,
      TILL_DATE: tillDate,
      SCHEDULE_START_DATE: scheduleStartDate,
      SCHEDULE_END_DATE: scheduleEndDate,
      SELECTED_DATE: seletedDate
    };
    return this.httpClient.post<string[]>(this.url + "batchSchedule/changeSchedule", JSON.stringify(data), this.options);
  }

  deleteBatchScheduledData(scheduleId, isForallBatches, tillFlag, tillDate, selectedDate): Observable<string[]> {
    var data = {
      SCHEDULE_ID: scheduleId,
      IS_FOR_ALL_BATCHES: isForallBatches,
      TILL_FLAG: tillFlag,
      TILL_DATE: tillDate,
      SELECTED_DATE: selectedDate
    };
    return this.httpClient.post<string[]>(this.url + "batchSchedule/deleteSchedule", JSON.stringify(data), this.options);
  }

  getAllbatchSchedulingGroupId(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "schedulingBatchGroupBatchMapping/get", JSON.stringify(data), this.options);
  }

  getAllResourceAllocationData(month, year) {
    var data = {
      MONTH: month,
      YEAR: year
    };
    return this.httpClient.post<Resouceallocation[]>(this.url + "resourceAllocation/getResourceAllocation", JSON.stringify(data), this.options);
  }

  getSpecificResourceAllocatedData(resourceGroupId, collegeId, date) {
    var data = {
      RESOURCE_GROUP_ID: resourceGroupId,
      COLLEGE_ID: collegeId,
      DATE: date
    };
    return this.httpClient.post<string[]>(this.url + "resourceAllocation/getTrainers", JSON.stringify(data), this.options);
  }

  assignTrainers(date, resourceGroupId, collegeId, trainerData) {
    var data = {
      DATE: date,
      RESOURCE_GROUP_ID: resourceGroupId,
      COLLEGE_ID: collegeId,
      data: trainerData,
      CLIENT_ID: this.clientId,
    };
    return this.httpClient.post<string[]>(this.url + "resourceAllocation/addTrainers", JSON.stringify(data), this.options);
  }

  deleteTrainers(date, resourceGroupId) {
    var data = {
      DATE: date,
      RESOURCE_GROUP_ID: resourceGroupId,
    };
    return this.httpClient.post<string[]>(this.url + "resourceAllocation/deleteTrainers", JSON.stringify(data), this.options);
  }

  getAllSessions(fromDate, toDate, collegeId) {
    var data = {
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      COLLEGE_ID: collegeId
    };
    return this.httpClient.post<string[]>(this.url + "session/getSessions", JSON.stringify(data), this.options);
  }

  getSessionDeatils(batchScheduleId) {
    var data = {
      BATCH_SCHEDULE_ID: batchScheduleId
    };
    return this.httpClient.post<string[]>(this.url + "session/getSessionDetails", JSON.stringify(data), this.options);
  }

  createSession(syllabusData, batchScheduleId, sessionName, trainerId, time, documentType, url) {
    var data = {
      SYLLABUS_DATA: syllabusData,
      BATCH_SCHEDULE_ID: batchScheduleId,
      NAME: sessionName,
      TRAINER_ID: trainerId,
      START_TIME_END_TIME_TEXT: time,
      DOCUMENT_TYPE: documentType,
      DOCUMENT_URL: url,
      CLIENT_ID: this.clientId
    };
    return this.httpClient.post<string[]>(this.url + "session/createSession", JSON.stringify(data), this.options);
  }

  getSingleSessionData(batchScheduleId) {
    var data = {
      BATCH_SCHEDULE_ID: batchScheduleId
    };
    return this.httpClient.post<string[]>(this.url + "session/getSessionDetails", JSON.stringify(data), this.options);
  }


  sendMail(to, subject, body) {
    var data = {
      TO: to,
      SUBJECT: subject,
      BODY: body
    };
    return this.httpClient.post<string[]>(this.url + "sendMail", JSON.stringify(data), this.options);
  }

  getAllMasterSessionData(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Mastersession[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Mastersession[]>(this.url + "masterSession/get", JSON.stringify(data), this.options);
  }

  updateMasterSessionInSessionCreation(masterSession: Mastersession): Observable<number> {
    masterSession.CLIENT_ID = this.clientId
    return this.httpClient.put<number>(this.url + "masterSession/update", JSON.stringify(masterSession), this.options);
  }


  getCombinedSections(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "section/getSections", JSON.stringify(data), this.options);
  }

  getCombinedChapters(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "chapter/getChapters", JSON.stringify(data), this.options);
  }


  getCombinedRegions(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "region/getRegions", JSON.stringify(data), this.options);
  }

  getCombinedDistricts(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "district/getDistricts", JSON.stringify(data), this.options);
  }


  getAllChapter(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "chapter/getChaptersMultiSelect", JSON.stringify(data), this.options);
  }

  getAllSubChapter(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "subchapter/getSubChaptersMultiSelect", JSON.stringify(data), this.options);
  }

  getAllDistrict(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "district/getDistrictsMultiSelect", JSON.stringify(data), this.options);
  }


  getAllSection(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "section/getSectionsMultiSelect", JSON.stringify(data), this.options);
  }

  getAllRegion(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "region/getRegionsMultiSelect", JSON.stringify(data), this.options);
  }

  getAllQuestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Questionmaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Questionmaster[]>(this.url + "question/get", JSON.stringify(data), this.options);
  }

  createQuestion(question: Questionmaster): Observable<number> {
    console.log(question)
    var data = {
      ID: question.ID,
      OPTIONS: question.optionList,
      SUB_CHAPTER_ID: question.SUB_CHAPTER_ID,
      QUESTION_TYPE_ID: question.QUESTION_TYPE_ID,
      LEVEL_ID: question.LEVEL_ID,
      GROUP_TYPE: question.GROUP_TYPE,
      PARAGRAPH_ID: question.PARAGRAPH_ID,
      DIRECTION: question.DIRECTION,
      DIRECTION_URL: question.DIRECTION_URL,
      DESCRIPTION: question.DESCRIPTION,
      DESCRIPTION_URL: question.DESCRIPTION_URL,
      RANDOMIZE_OPTIONS: question.RANDOMIZE_OPTIONS,
      IS_SEQUENCE_MARKING: question.IS_SEQUENCE_MARKING,
      QUESTION: question.QUESTION,
      QUESTION_URL: question.QUESTION_URL,
      SOLUTION: question.SOLUTION,
      SOLUTION_IMAGE_URL: question.SOLUTION_IMAGE_URL,
      SOLUTION_VIDEO_URL: question.SOLUTION_VIDEO_URL,
      ANSWER_TEXT: "",
      CLIENT_ID: this.clientId
    };
    return this.httpClient.post<number>(this.url + "question/createQuestion/", JSON.stringify(data), this.options);
  }

  updateQuestion(question: Questionmaster): Observable<number> {
    var data = {
      ID: question.ID,
      OPTIONS: question.optionList,
      SUB_CHAPTER_ID: question.SUB_CHAPTER_ID,
      QUESTION_TYPE_ID: question.QUESTION_TYPE_ID,
      LEVEL_ID: question.LEVEL_ID,
      GROUP_TYPE: question.GROUP_TYPE,
      PARAGRAPH_ID: question.PARAGRAPH_ID,
      DIRECTION: question.DIRECTION,
      DIRECTION_URL: question.DIRECTION_URL,
      DESCRIPTION: question.DESCRIPTION,
      DESCRIPTION_URL: question.DESCRIPTION_URL,
      RANDOMIZE_OPTIONS: question.RANDOMIZE_OPTIONS,
      IS_SEQUENCE_MARKING: question.IS_SEQUENCE_MARKING,
      QUESTION: question.QUESTION,
      QUESTION_URL: question.QUESTION_URL,
      SOLUTION: question.SOLUTION,
      SOLUTION_IMAGE_URL: question.SOLUTION_IMAGE_URL,
      SOLUTION_VIDEO_URL: question.SOLUTION_VIDEO_URL,
      ANSWER_TEXT: "",
      CLIENT_ID: this.clientId
    };
    return this.httpClient.post<number>(this.url + "question/updateQuestion", JSON.stringify(data), this.options);
  }


  getEditSessionContentDeatils(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "batchSessionSyllabusContentDeatailsSessionMapping/get", JSON.stringify(data), this.options);
  }


  getAllSessionsReal(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "session/get", JSON.stringify(data), this.options);
  }

  generateClassworkFile(sessionId) {
    var data = {
      SESSION_ID: sessionId,
    };

    return this.httpClient.post<string[]>(this.url + "session/generatePdf", JSON.stringify(data), this.options);
  }


  editSessionContentDeatils(sessionId, contentData) {
    var data = {
      SESSION_ID: sessionId,
      SYLLABUS_DATA: contentData
    };

    return this.httpClient.post<string[]>(this.url + "session/updateSessionSyllabus", JSON.stringify(data), this.options);
  }


  deleteSessionContentDeatils(id) {
    var data = {
      ID: id
    };
    return this.httpClient.post<string[]>(this.url + "batchSessionSyllabusContentDeatailsSessionMapping/get", JSON.stringify(data), this.options);
  }

  getAllVehicleAllocationDetails(fromDate, toDate) {
    var data = {
      FROM_DATE: fromDate,
      TO_DATE: toDate
    };
    return this.httpClient.post<string[]>(this.url + "vehicleAllocation/getVehicleAllocation", JSON.stringify(data), this.options);
  }

  getVehicleAllocationDetailsForAdd(date, vehicleId) {
    var data = {
      DATE: date,
      VEHICAL_ID: vehicleId
    };
    return this.httpClient.post<string[]>(this.url + "vehicleAllocation/getVehicleAllocationDetails", JSON.stringify(data), this.options);
  }

  assignTrainersForVehicle(trainerData, employeeData, locationName, driverId, DepartureTime, vehicleId, date, isOther) {
    var data = {
      TRAINER_DATA: trainerData,
      EMPLOYEE_DATA: employeeData,
      LOCATION_NAME: locationName,
      DRIVER_ID: driverId,
      DEPARTURE_TIME: DepartureTime,
      VEHICAL_ID: vehicleId,
      DATE: date,
      IS_OTHERS: isOther,
      CLIENT_ID: this.clientId,
    };
    return this.httpClient.post<string[]>(this.url + "vehicleAllocation/manageVehicleAllocation", JSON.stringify(data), this.options);
  }

  getSubchapterForNode(): Observable<string[]> {
    var data = {

    };
    return this.httpClient.post<string[]>(this.url + "subChapter/getSubChapters", JSON.stringify(data), this.options);
  }

  getAllOptions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "questionOption/get", JSON.stringify(data), this.options);
  }

  getDataForSyllabusTrack(batchId: number): Observable<string[]> {
    var data = {
      BATCH_ID: batchId
    };
    return this.httpClient.post<string[]>(this.url + "batch/get", JSON.stringify(data), this.options);
  }

  getAllUserMappingData(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "userRoleMapping/get", JSON.stringify(data), this.options);
  }

  getSessionStudentAttendance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "sessionSlotsAttendance/get", JSON.stringify(data), this.options);
  }


  getAllAssignmentGroups(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Assignmentgroup[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Assignmentgroup[]>(this.url + "assignmentGroup/get", JSON.stringify(data), this.options);
  }

  createAssignmentGroup(assignmentGroup: Assignmentgroup): Observable<number> {

    assignmentGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "assignmentGroup/create/", JSON.stringify(assignmentGroup), this.options);
  }

  updateAssignmentGroup(assignmentGroup: Assignmentgroup): Observable<number> {

    assignmentGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "assignmentGroup/update/", JSON.stringify(assignmentGroup), this.options);
  }

  getAllInstructions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Instruction[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Instruction[]>(this.url + "instruction/get", JSON.stringify(data), this.options);
  }

  createInstruction(instruction: Instruction): Observable<number> {

    instruction.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "instruction/create/", JSON.stringify(instruction), this.options);
  }

  updateInstruction(instruction: Instruction): Observable<number> {

    instruction.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "instruction/update/", JSON.stringify(instruction), this.options);
  }

  getAllAssignments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Assignment[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Assignment[]>(this.url + "assignment/get", JSON.stringify(data), this.options);
  }

  createAssignment(assignment: Assignment): Observable<number> {
    assignment.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "assignment/create/", JSON.stringify(assignment), this.options);
  }

  updateAssignment(assignment: Assignment): Observable<number> {
    assignment.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "assignment/update/", JSON.stringify(assignment), this.options);
  }

  getAssignmentInstructions(assignmentId: number) {
    var data = {
      ASSIGNMENT_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "assignmentInstructionMapping/getData", JSON.stringify(data), this.options);
  }

  addAssignmentInstructions(assignmentId: number, data1: string[]): Observable<number> {
    var data = {
      ASSIGNMENT_ID: assignmentId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "assignmentInstructionMapping/addBulk", data, this.options);
  }


  getAssignmentDetails(assignmentId: number, partId: number, sectionId: number, chapterId: number) {
    var data = {
      ASSIGNMENT_ID: assignmentId,
      PART_ID: partId,
      SECTION_ID: sectionId,
      CHAPTER_ID: chapterId
    };
    return this.httpClient.post<string[]>(this.url + "assignment/getQuestionsDetails", JSON.stringify(data), this.options);
  }


  getAssignmentQuestionDetails(assignmentId: number, partId: number, sectionId: number, chapterId: number, subchapterId: number, groupType: string, levelId: number,): Observable<number> {
    var data = {
      ASSIGNMENT_ID: assignmentId,
      PART_ID: partId,
      SECTION_ID: sectionId,
      CHAPTER_ID: chapterId,
      SUB_CHAPTER_ID: subchapterId,
      GROUP_TYPE: groupType,
      LEVEL_ID: levelId
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "assignment/getQuestionsData", data, this.options);
  }

  getMappedAssignmentQuestionDetails(assignmentId: number, partId: number, sectionId: number, chapterId: number, subchapterId: number, groupType: string, levelId: number,): Observable<number> {
    var data = {
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId,
      PART_ID: partId,
      SECTION_ID: sectionId,
      CHAPTER_ID: chapterId,
      SUB_CHAPTER_ID: subchapterId,
      GROUP_TYPE: groupType,
      LEVEL_ID: levelId
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mappedAssignment/getQuestionsData", data, this.options);
  }

  addAssignmentDetails(partId: number, sectionId: number, chapterId: number, assignmentId: number, data1, totalQuestions: number, totalTime: string): Observable<number> {
    var data = {
      PART_ID: partId,
      SECTION_ID: sectionId,
      CHAPTER_ID: chapterId,
      ASSIGNMENT_ID: assignmentId,
      data: data1,
      TOTAL_QUESTIONS: totalQuestions,
      TOTAL_TIME: totalTime
    };
    return this.httpClient.post<number>(this.url + "assignment/manageQuestionDetails", data, this.options);
  }

  getAllCollegeBranches(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "collegeBranchMapping/get", JSON.stringify(data), this.options);
  }

  getAllCollegeBranchesPassingYears(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "collegeTrainingCourseBranchYearMapping/get", JSON.stringify(data), this.options);
  }

  getAllCollegeBranchesPassingYearsDivisions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "collegeTrainingCourseBranchYearDivisionMapping/get", JSON.stringify(data), this.options);
  }

  getAssignmentBatches(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batch[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batch[]>(this.url + "assignment/getBatches", JSON.stringify(data), this.options);
  }


  getAssignmentMapping(assignmentId: number) {
    var data = {
      ASSIGNMENT_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "assignment/getQuestionsDetails", JSON.stringify(data), this.options);
  }


  addAssignmentMapping(name: string, scope: string, assignmentId: number, data1: string[]): Observable<number> {
    var data = {
      SCOPE: scope,
      ASSIGNMENT_ID: assignmentId,
      NAME: name,
      data: data1,
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "assignment/mapAssignment", data, this.options);
  }

  getAllMappedAssignments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Mappedassignment[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Mappedassignment[]>(this.url + "mappedAssignment/get", JSON.stringify(data), this.options);
  }

  createMappedAssignment(mappedAssignment: Mappedassignment): Observable<number> {

    mappedAssignment.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "mappedAssignment/create/", JSON.stringify(mappedAssignment), this.options);
  }

  updateMappedAssignment(mappedAssignment: Mappedassignment): Observable<number> {
    mappedAssignment.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "mappedAssignment/update/", JSON.stringify(mappedAssignment), this.options);
  }

  getMappedAssignmentInstructions(assignmentId: number) {
    var data = {
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "mappedMockInstructionMapping/getData", JSON.stringify(data), this.options);
  }

  addMappedAssignmentInstructions(assignmentId: number, data1: string[]): Observable<number> {
    var data = {
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "mappedMockInstructionMapping/addBulk", data, this.options);
  }

  getMappedAssignmentDetails(assignmentId: number, partId: number, sectionId: number, chapterId: number) {
    this.httpHeaders123 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      'timeout': `${20000}`
    });
    this.options123 = {
      headers: this.httpHeaders123
    };
    var data = {
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId,
      PART_ID: partId,
      SECTION_ID: sectionId,
      CHAPTER_ID: chapterId
    };
    console.log(data)
    return this.httpClient.post<string[]>(this.url + "mappedAssignment/getMappingquestionDetails", JSON.stringify(data), this.options);
  }

  addMappedAssignmentDetails(partId: number, sectionId: number, chapterId: number, assignmentId: number, data1, totalQuestions: number, totalTime: string): Observable<number> {
    var data = {
      PART_ID: partId,
      SECTION_ID: sectionId,
      CHAPTER_ID: chapterId,
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId,
      data: data1,
      TOTAL_QUESTIONS: totalQuestions,
      TOTAL_TIME: totalTime
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mappedAssignment/updateMappingQuestionDetails", data, this.options);
  }

  getMappedAssignmentMappingData(assignmentId: number, filter: string) {
    var data = {
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId,
      filter: filter
    };
    console.log(data)
    return this.httpClient.post<string[]>(this.url + "mappedAssignment/getMappedData", JSON.stringify(data), this.options);
  }


  addMappedAssignmentMapping(name: string, scope: string, assignmentId: number, data1: string[]): Observable<number> {
    var data = {
      SCOPE: scope,
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId,
      NAME: name,
      data: data1,
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mappedAssignment/updateMappingData", data, this.options);
  }

  addBulkQuestions(data1: Questionmaster[]): Observable<number> {
    var data = {
      DATA: data1
    };

    return this.httpClient.post<number>(this.url + "question/addBulk", data, this.options);
  }

  getAllCombinedMappingData(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, scope: string, flag: string, collegeId: number): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      SCOPE: scope,
      FLAG: flag,
      COLLEGE_ID: collegeId
    };
    return this.httpClient.post<string[]>(this.url + "mappedAssignment/getMasterData", JSON.stringify(data), this.options);
  }

  getAllDivision(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "college/getCollegesMultiSelect", JSON.stringify(data), this.options);
  }

  getPasskeyReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Passkeyreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Passkeyreport[]>(this.url + "reports/getPasskeyReport", JSON.stringify(data), this.options);
  }
  getBatchReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batchreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batchreport[]>(this.url + "reports/getBatchReport", JSON.stringify(data), this.options);
  }

  getBatchStudentReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batchstudentreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batchstudentreport[]>(this.url + "reports/getBatchStudentsReport", JSON.stringify(data), this.options);
  }

  getPartReport(chapterID): Observable<Partreport[]> {
    var data = {
      
      PART_ID:chapterID
    };
    return this.httpClient.post<Partreport[]>(this.url + "reports/getPartSummaryReport", JSON.stringify(data), this.options);
  }

  getSectionReport(sectionID): Observable<Scetionreport[]> {
    var data = {
      SECTION_ID:sectionID,
     
     
    };
    return this.httpClient.post<Scetionreport[]>(this.url + "reports/getSectionSummaryReport", JSON.stringify(data), this.options);
  }

  getChapterReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Chapterreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Chapterreport[]>(this.url + "reports/getChapterReport", JSON.stringify(data), this.options);
  }


  getSubChapterReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Subchapterreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Subchapterreport[]>(this.url + "reports/getSubChapterReport", JSON.stringify(data), this.options);
  }

  getMasterSessionReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Mastersessionreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Mastersessionreport[]>(this.url + "reports/getMasterSessionReport", JSON.stringify(data), this.options);
  }
  getMasterSessionDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Mastersessiondetailsreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Mastersessiondetailsreport[]>(this.url + "reports/getMasterSessionDetailsReport", JSON.stringify(data), this.options);
  }

  getBatchSessionReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batchsessionreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batchsessionreport[]>(this.url + "reports/getBatchSessionReport", JSON.stringify(data), this.options);
  }


  getBatchSessionDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batchsessiodetailsnreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batchsessiodetailsnreport[]>(this.url + "reports/getBatchSessionDetailsReport", JSON.stringify(data), this.options);
  }

  getMasterSyllbusReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Mastersyllabusreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Mastersyllabusreport[]>(this.url + "reports/getMasterSyllabusReport", JSON.stringify(data), this.options);
  }

  getBatchSyllabusReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batchsyllabusreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batchsyllabusreport[]>(this.url + "report/getBatchSyllabus", JSON.stringify(data), this.options);
  }

  checkStatusOfSchedule(batchGroupId: number): Observable<string[]> {
    var data = {
      BATCH_GROUP_ID: batchGroupId
    };
    return this.httpClient.post<string[]>(this.url + "batchSchedule/checkStatus", JSON.stringify(data), this.options);
  }


  getCurrentStatusAssignment(assignmentId: number) {
    var data = {
      MAPPED_ASSIGNMENT_MASTER_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "studentAssignmentResultDetails/getStatus", JSON.stringify(data), this.options);
  }


  getResultAssignment(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "studentAssignmentResultDetails/get", JSON.stringify(data), this.options);
  }

  getAllNotification(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Notification[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Notification[]>(this.url + "notification/get", JSON.stringify(data), this.options);
  }

  notificationAcknowledgement(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Notification[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Notification[]>(this.url + "notificationAcknowledgement/get", JSON.stringify(data), this.options);
  }
  createNotification(notification): Observable<number> {
    notification.VISIBILITY = notification.VISIBILITY ? 1 : 0
    notification.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "notification/create/", JSON.stringify(notification), this.options);
  }

  updateNotification(notification): Observable<number> {
    notification.VISIBILITY = notification.VISIBILITY ? 1 : 0
    notification.CLIENT_ID = this.clientId;
    console.log(notification)
    return this.httpClient.put<number>(this.url + "notification/update/", JSON.stringify(notification), this.options);
  }


  getAllAssignmentDestails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Chapter[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Chapter[]>(this.url + "assignmentDetail/get", JSON.stringify(data), this.options);
  }

  getAllMappedAssignmentDetailsMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "mappedAssignmentDetailsMapping/get", JSON.stringify(data), this.options);
  }

  getAllAssignmentQuestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "assignmentDetailQuestionMapping/get", JSON.stringify(data), this.options);
  }

  getAllMappedAssignmentQuestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "mappedAssignmentDetailsQuestionMapping/get", JSON.stringify(data), this.options);
  }
  getAllEmailtemplateInformation(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Emailtemplate[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Emailtemplate[]>(this.url + "sliderInformation/get", JSON.stringify(data), this.options);
  }

  createEmailtemplateInformation(chapter: Emailtemplate): Observable<number> {
    chapter['REPLACE_KEYS'] = chapter.REPLACE_KEYS.toString()
    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "sliderInformation/create/", JSON.stringify(chapter), this.options);
  }

  updateEmailtemplateInformation(chapter: Emailtemplate): Observable<number> {
    chapter['REPLACE_KEYS'] = chapter.REPLACE_KEYS.toString()
    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "sliderInformation/update/", JSON.stringify(chapter), this.options);
  }

  getAllSliderInformation(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Slider[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Slider[]>(this.url + "sliderInformation/get", JSON.stringify(data), this.options);
  }

  createSliderInformation(chapter: Slider): Observable<number> {

    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "sliderInformation/create/", JSON.stringify(chapter), this.options);
  }

  updateSliderInformation(chapter: Slider): Observable<number> {

    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "sliderInformation/update/", JSON.stringify(chapter), this.options);
  }
  getAllShikuyaaChapters(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Shikuyaachapter[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Shikuyaachapter[]>(this.url + "shikuyaaChapter/get", JSON.stringify(data), this.options);
  }

  createShikuyaaChapter(chapter: Shikuyaachapter): Observable<number> {

    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shikuyaaChapter/createWebsiteChapter/", JSON.stringify(chapter), this.options);
  }

  updateShikuyaaChapter(chapter: Shikuyaachapter): Observable<number> {

    chapter.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "shikuyaaChapter/updateWebsiteChapter/", JSON.stringify(chapter), this.options);
  }

  getAllDocuments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Document[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Document[]>(this.url + "document/get", JSON.stringify(data), this.options);
  }

  createDocument(document: Document): Observable<number> {

    document.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "document/create/", JSON.stringify(document), this.options);
  }

  updateDocument(document: Document): Observable<number> {

    document.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "document/update/", JSON.stringify(document), this.options);
  }

  getAllVideos(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Video[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Video[]>(this.url + "video/get", JSON.stringify(data), this.options);
  }

  getAllVideoss(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,category,course,chapter): Observable<Video[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COURSE_SUB_CATEGORY_ID:category,
      COURSE_ID:course,
      CHAPTER_ID:chapter
    };
    return this.httpClient.post<Video[]>(this.url + "video/get", JSON.stringify(data), this.options);
  }
  createVideo(video: Video): Observable<number> {

    video.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "video/create/", JSON.stringify(video), this.options);
  }

  updateVideo(video: Video): Observable<number> {

    video.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "video/update/", JSON.stringify(video), this.options);
  }
  getAllVideoquestionType(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Videoquestiontype[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Videoquestiontype[]>(this.url + "videoQuestionType/get", JSON.stringify(data), this.options);
  }

  createVideoQuestionType(videoQuestionType: Videoquestiontype): Observable<number> {

    videoQuestionType.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "videoQuestionType/create/", JSON.stringify(videoQuestionType), this.options);
  }

  updateVideoQuestionType(videoQuestionType: Videoquestiontype): Observable<number> {

    videoQuestionType.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "videoQuestionType/update/", JSON.stringify(videoQuestionType), this.options);
  }
  getAllShikuyaaParagraphs(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Paragraph[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Paragraph[]>(this.url + "shikuyaaParagraph/get", JSON.stringify(data), this.options);
  }

  createShikuyaaParagraph(shikuyaaParagraph: Paragraph): Observable<number> {

    shikuyaaParagraph.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shikuyaaParagraph/create", JSON.stringify(shikuyaaParagraph), this.options);
  }

  updateShikuyaaParagraph(shikuyaaParagraph: Paragraph): Observable<number> {

    shikuyaaParagraph.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "shikuyaaParagraph/update", JSON.stringify(shikuyaaParagraph), this.options);
  }

  getAllPredefinedmocks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Predefinedmock[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Predefinedmock[]>(this.url + "predefinedMock/get", JSON.stringify(data), this.options);
  }

  createPredefinedMock(predefinedMock: Predefinedmock): Observable<number> {

    predefinedMock.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "predefinedMock/create/", JSON.stringify(predefinedMock), this.options);
  }

  updatePredefinedMock(predefinedMock: Predefinedmock): Observable<number> {

    predefinedMock.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "predefinedMock/update/", JSON.stringify(predefinedMock), this.options);
  }

  getAllLiveVideo(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Livevideo[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Livevideo[]>(this.url + "liveVideos/get", JSON.stringify(data), this.options);
  }

  createLiveVideos(liveVideos: Livevideo): Observable<number> {
    console.log(liveVideos)
    liveVideos.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "liveVideos/create/", JSON.stringify(liveVideos), this.options);
  }

  updateLiveVideos(liveVideos: Livevideo): Observable<number> {

    liveVideos.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "liveVideos/update/", JSON.stringify(liveVideos), this.options);
  }
// live session
getAllLiveSession(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Livevideo[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
  };
  return this.httpClient.post<Livevideo[]>(this.url + "web/liveClasses/get", JSON.stringify(data), this.options);
}

createLiveSession(liveVideos: LiveSessions): Observable<number> {
  console.log(liveVideos)
  liveVideos.CLIENT_ID = this.clientId;
  return this.httpClient.post<number>(this.url + "web/liveClasses/create", JSON.stringify(liveVideos), this.options);
}

updateLiveSession(liveVideos: LiveSessions): Observable<number> {

  liveVideos.CLIENT_ID = this.clientId;
  return this.httpClient.put<number>(this.url + "web/liveClasses/update", JSON.stringify(liveVideos), this.options);
}
getAllLiveSessionTags(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<LivesessionTag[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
  };
  return this.httpClient.post<LivesessionTag[]>(this.url + "web/tag/get", JSON.stringify(data), this.options);
}

createLiveSessionTags(liveVideos: LivesessionTag): Observable<number> {
  console.log(liveVideos)
  liveVideos.CLIENT_ID = this.clientId;
  return this.httpClient.post<number>(this.url + "web/tag/create", JSON.stringify(liveVideos), this.options);
}
createLiveSessionTagss(name:string): Observable<number> {
  var data={
    NAME:name,
    CLIENT_ID:this.clientId,
    IS_ACTIVE:true

  }
  return this.httpClient.post<number>(this.url + "web/tag/create", JSON.stringify(data), this.options);
}
updateLiveSessionTags(liveVideos: LivesessionTag): Observable<number> {

  liveVideos.CLIENT_ID = this.clientId;
  return this.httpClient.put<number>(this.url + "web/tag/update", JSON.stringify(liveVideos), this.options);
}
  getAllCouponfacilities(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Coupanfacility[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Coupanfacility[]>(this.url + "couponFacility/get", JSON.stringify(data), this.options);
  }

  createCouponFacility(couponFacility: Coupanfacility): Observable<number> {

    couponFacility.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "couponFacility/create/", JSON.stringify(couponFacility), this.options);
  }

  updateCouponFacility(couponFacility: Coupanfacility): Observable<number> {

    couponFacility.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "couponFacility/update/", JSON.stringify(couponFacility), this.options);
  }
// Exe Download Report Service
getAllExeDownloadreport (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    // LIKE_STRING:likestring,
    // FROM_DATE:fromdate,
    // TO_DATE:todate
  };
  this.httpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      });
      this.options = {
    headers: this.httpHeaders
  };
  return this.httpClient.post<any>(this.url + "exe/get", JSON.stringify(data), this.options);
}
getAllSubStudDetailedreport (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    // LIKE_STRING:likestring,
    // FROM_DATE:fromdate,
    // TO_DATE:todate
  };
  this.httpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      });
      this.options = {
    headers: this.httpHeaders
  };
  return this.httpClient.post<any>(this.url + "web/newsletter/get", JSON.stringify(data), this.options);
}
  getAllCoupons(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Coupan[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Coupan[]>(this.url + "coupon/get", JSON.stringify(data), this.options);
  }

  createCoupon(coupon: Coupan): Observable<number> {

    coupon.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "coupon/create/", JSON.stringify(coupon), this.options);
  }

  updateCoupon(coupon: Coupan): Observable<number> {

    coupon.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "coupon/update/", JSON.stringify(coupon), this.options);
  }
  getAllVideoquestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Videoquestion[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Videoquestion[]>(this.url + "videoQuestion/get", JSON.stringify(data), this.options);
  }
  getvideoQuestionResult(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Videoquestion[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Videoquestion[]>(this.url + "videoQuestionResult/get", JSON.stringify(data), this.options);
  }
  createVideoQuestion(videoQuestion: Videoquestion): Observable<number> {

    videoQuestion.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "videoQuestion/create/", JSON.stringify(videoQuestion), this.options);
  }

  updateVideoQuestion(videoQuestion: Videoquestion): Observable<number> {

    videoQuestion.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "videoQuestion/update/", JSON.stringify(videoQuestion), this.options);
  }

  getAllShikuyaaQuestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Shikuyaaquestion[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Shikuyaaquestion[]>(this.url + "shikuyaaQuestion/get", JSON.stringify(data), this.options);
  }
  getAllShikuyaaQuestionss(type:any,category:any,course:any,chapter:any): Observable<Shikuyaaquestion[]> {
    var data = {
      GROUP_TYPE:type,
      COURSE_SUB_CATEGORY_ID:category,
      COURSE_ID:course,
      CHAPTER_ID:chapter
    };
    return this.httpClient.post<Shikuyaaquestion[]>(this.url + "shikuyaaQuestion/get", JSON.stringify(data), this.options);
  }
    getAllShikuyaaQuestionss1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,type:any,category:any,course:any,chapter:any,com,cate): Observable<Shikuyaaquestion[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      GROUP_TYPE:type,
      COURSE_SUB_CATEGORY_ID:category,
      COURSE_ID:course,
      CHAPTER_ID:chapter,
      COMPANY_ID:com,
      CATEGORY_ID:cate
    };
    return this.httpClient.post<Shikuyaaquestion[]>(this.url + "shikuyaaQuestion/get", JSON.stringify(data), this.options);
  }
  getAllShikuyaaQuestions1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,chapterID): Observable<Shikuyaaquestion[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      CHAPTER_ID:chapterID
    };
    return this.httpClient.post<Shikuyaaquestion[]>(this.url + "reports/getCoursePracticeQuestionDetails", JSON.stringify(data), this.options);
  }
  createShikuyaaQuestion1(question: Shikuyaaquestion): Observable<number> {
    var data = {
      ID: question.ID,
      IS_ACTIVE:question.IS_ACTIVE,
      OPTIONS: question.optionList,
      CHAPTER_ID: '0',
      ANSWER_TEXT:question.ANSWER_TEXT,
      QUESTION_TYPE_ID: question.QUESTION_TYPE_ID,
      LEVEL_ID: question.LEVEL_ID,
      GROUP_TYPE: question.GROUP_TYPE,
      PARAGRAPH_ID: question.PARAGRAPH_ID,
      DIRECTION: question.DIRECTION,
      DIRECTION_IMG: question.DIRECTION_IMG == undefined ? " " : question.DIRECTION_IMG,
      DESCRIPTION: question.DESCRIPTION,
      DESCRIPTION_IMG: question.DESCRIPTION_IMG == undefined ? " " : question.DESCRIPTION_IMG,
      IS_RANDOMIZATION_OPTION: question.IS_RANDOMIZATION_OPTION,
      IS_SEQUENCE_MARKING: question.IS_SEQUENCE_MARKING,
      QUESTION: question.QUESTION,
      QUESTION_URL: question.QUESTION_URL == undefined ? " " : question.QUESTION_URL,
      SOLUTION: question.SOLUTION,
      SOLUTION_IMAGE_URL: question.SOLUTION_IMAGE_URL == undefined ? " " : question.SOLUTION_IMAGE_URL,
      SOLUTION_VIDEO_URL: question.SOLUTION_VIDEO_URL,
     
      CLIENT_ID: this.clientId,
      COMPANY_ID:question.COMPANY_ID,
      CATEGORY_ID:question.CATEGORY_ID
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "shikuyaaQuestion/createQuestion/", JSON.stringify(data), this.options);
  }

  updateShikuyaaQuestion1(question: Shikuyaaquestion): Observable<number> {
    var data = {
      ID: question.ID,
      IS_ACTIVE:question.IS_ACTIVE,
      OPTIONS: question.optionList,
      CHAPTER_ID: '0',
      QUESTION_TYPE_ID: question.QUESTION_TYPE_ID,
      LEVEL_ID: question.LEVEL_ID,
      GROUP_TYPE: question.GROUP_TYPE,
      PARAGRAPH_ID: question.PARAGRAPH_ID,
      DIRECTION: question.DIRECTION,
      DIRECTION_IMG: question.DIRECTION_IMG,
      DESCRIPTION: question.DESCRIPTION,
      DESCRIPTION_IMG: question.DESCRIPTION_IMG,
      IS_RANDOMIZATION_OPTION: question.IS_RANDOMIZATION_OPTION,
      IS_SEQUENCE_MARKING: question.IS_SEQUENCE_MARKING,
      QUESTION: question.QUESTION,
      QUESTION_URL: question.QUESTION_URL,
      SOLUTION: question.SOLUTION,
      SOLUTION_IMAGE_URL: question.SOLUTION_IMAGE_URL,
      SOLUTION_VIDEO_URL: question.SOLUTION_VIDEO_URL,
      ANSWER_TEXT:question.ANSWER_TEXT,
      CLIENT_ID: this.clientId,
      COMPANY_ID:question.COMPANY_ID,
      CATEGORY_ID:question.CATEGORY_ID
    };
    return this.httpClient.post<number>(this.url + "shikuyaaQuestion/updateQuestion", JSON.stringify(data), this.options);
  }
  createShikuyaaQuestion(question: Shikuyaaquestion): Observable<number> {
    var data = {
      ID: question.ID,
      IS_ACTIVE:question.IS_ACTIVE,
      OPTIONS: question.optionList,
      CHAPTER_ID: question.CHAPTER_ID,
      QUESTION_TYPE_ID: question.QUESTION_TYPE_ID,
      LEVEL_ID: question.LEVEL_ID,
      GROUP_TYPE: question.GROUP_TYPE,
      PARAGRAPH_ID: question.PARAGRAPH_ID,
      DIRECTION: question.DIRECTION,
      DIRECTION_IMG: question.DIRECTION_IMG == undefined ? " " : question.DIRECTION_IMG,
      DESCRIPTION: question.DESCRIPTION,
      DESCRIPTION_IMG: question.DESCRIPTION_IMG == undefined ? " " : question.DESCRIPTION_IMG,
      IS_RANDOMIZATION_OPTION: question.IS_RANDOMIZATION_OPTION,
      IS_SEQUENCE_MARKING: question.IS_SEQUENCE_MARKING,
      QUESTION: question.QUESTION,
      QUESTION_URL: question.QUESTION_URL == undefined ? " " : question.QUESTION_URL,
      SOLUTION: question.SOLUTION,
      SOLUTION_IMAGE_URL: question.SOLUTION_IMAGE_URL == undefined ? " " : question.SOLUTION_IMAGE_URL,
      SOLUTION_VIDEO_URL: question.SOLUTION_VIDEO_URL,
      ANSWER_TEXT:question.ANSWER_TEXT,
      CLIENT_ID: this.clientId,
      COMPANY_ID:'0',
      COURSE_SUB_CATEGORY_ID:question.COURSE_SUB_CATEGORY_ID,
      COURSE_ID:question.COURSE_ID
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "shikuyaaQuestion/createQuestion/", JSON.stringify(data), this.options);
  }

  updateShikuyaaQuestion(question: Shikuyaaquestion): Observable<number> {
    var data = {
      ID: question.ID,
      IS_ACTIVE:question.IS_ACTIVE,
      OPTIONS: question.optionList,
      CHAPTER_ID: question.CHAPTER_ID,
      QUESTION_TYPE_ID: question.QUESTION_TYPE_ID,
      LEVEL_ID: question.LEVEL_ID,
      GROUP_TYPE: question.GROUP_TYPE,
      PARAGRAPH_ID: question.PARAGRAPH_ID,
      DIRECTION: question.DIRECTION,
      DIRECTION_IMG: question.DIRECTION_IMG,
      DESCRIPTION: question.DESCRIPTION,
      DESCRIPTION_IMG: question.DESCRIPTION_IMG,
      IS_RANDOMIZATION_OPTION: question.IS_RANDOMIZATION_OPTION,
      IS_SEQUENCE_MARKING: question.IS_SEQUENCE_MARKING,
      QUESTION: question.QUESTION,
      QUESTION_URL: question.QUESTION_URL,
      SOLUTION: question.SOLUTION,
      SOLUTION_IMAGE_URL: question.SOLUTION_IMAGE_URL,
      SOLUTION_VIDEO_URL: question.SOLUTION_VIDEO_URL,
      ANSWER_TEXT:question.ANSWER_TEXT,
      CLIENT_ID: this.clientId,
      COMPANY_ID:'0',
      COURSE_SUB_CATEGORY_ID:question.COURSE_SUB_CATEGORY_ID,
      COURSE_ID:question.COURSE_ID
    };
    return this.httpClient.post<number>(this.url + "shikuyaaQuestion/updateQuestion", JSON.stringify(data), this.options);
  }

  getFacilityMapping(couponId: number) {
    var data = {
      COUPON_ID: couponId
    };
    console.log("Coupon Id: " + couponId)
    return this.httpClient.post<string[]>(this.url + "couponFacilityMapping/getData", JSON.stringify(data), this.options);
  }

  //assign all College Branch - COLLEGE_BRANCH_MAPPING
  addFacilityMapping(couponId: number, data1: string[]): Observable<number> {
    var data = {
      COUPON_ID: couponId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "couponFacilityMapping/addBulk", data, this.options);
  }

  getCombinedCourses(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "course/getCourse", JSON.stringify(data), this.options);
  }
  getCategoryHierarchy(): Observable<string[]> {
    var data = {
      COURSE_CATEGORY_ID:0,
      IS_PARENT_FILTER:1
    };
    return this.httpClient.post<string[]>(this.url + "coursecategory/getHierarchy", JSON.stringify(data), this.options);
  }
  getCategoryHierarchys(ids): Observable<string[]> {
    var data = {
      COURSE_CATEGORY_ID:ids,
      IS_PARENT_FILTER:''
    };
    return this.httpClient.post<string[]>(this.url + "coursecategory/getHierarchy", JSON.stringify(data), this.options);
  }

  getCombinedShikuyaaChapters(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getShikuyaaChapters", JSON.stringify(data), this.options);
  }
  getCombinedShikuyaaChapters1(ids): Observable<string[]> {
    var data = {
      COURSE_SUB_CATEGORY_ID:ids
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaQuestion/getShikuyaaChapterHierarchy", JSON.stringify(data), this.options);
  }
  
  getCombinedShikuyaaChapters2(COURSE_ID,enabled): Observable<string[]> {
    var data = {
      COURSE_ID: COURSE_ID,
      ENABLED:true
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getShikuyaaChapterByCourse", JSON.stringify(data), this.options);
  }
  getCombinedShikuyaaChapters3(COURSE_ID,enabled): Observable<string[]> {
    var data = {
      COURSE_ID: COURSE_ID,
      ENABLED:true
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getShikuyaaChapterByCourse", JSON.stringify(data), this.options);
  }
  getAllChaptersByCourseID(COURSE_ID): Observable<string[]> {
    var data = {
      COURSE_IDS: COURSE_ID
    
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getCourseWiseShikuyaaChapters", JSON.stringify(data), this.options);
  }
  getAllShikyaaChapterMultiselect(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getShikuyaaChaptersMultiSelect", JSON.stringify(data), this.options);
  }

  getAllShikuyaaOptions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaQuestionOptionMapping/get", JSON.stringify(data), this.options);
  }
  shikuyaaQuestionsImporter(data1: Shikuyaaquestion[]): Observable<number> {
    var data = {
      DATA: data1
    };
    return this.httpClient.post<number>(this.url + "shikuyaaQuestion/addBulk", data, this.options);
  }
  shikuyaaVideoImporter(chapter,data1): Observable<number> {
    var data = {
      
      CHAPTER_ID:chapter,
      DATA: data1,
      CLIENT_ID:this.clientId
    };
    console.log('api data=',data)
    return this.httpClient.post<number>(this.url + "video/addBulk", data, this.options);
  }
courseChapterImporter(data1): Observable<number> {
    var data = {
      CHAPTER_DATA:data1,
      CLIENT_ID:this.clientId
    };
    console.log('api data=',data)
    return this.httpClient.post<number>(this.url + "shikuyaaChapter/addBulk", data, this.options);
  }


  getAllShikyaaCoursesMultiselect(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "course/getCourseMultiselect", JSON.stringify(data), this.options);
  }

  getAllCourseStudentList(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "studentCourseMapping/get", JSON.stringify(data), this.options);
  }

  getVideoStats(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "videoStat/get", JSON.stringify(data), this.options);
  }

  getAllLiveVideoComments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "liveVideoComment/get", JSON.stringify(data), this.options);
  }

  createLiveVideosComments(liveVideosComments: Livevideocomments): Observable<number> {
    liveVideosComments.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "liveVideoComment/create/", JSON.stringify(liveVideosComments), this.options);
  }
  getAllPoles(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Pole[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Pole[]>(this.url + "pole/get", JSON.stringify(data), this.options);
  }

  createPole(pole: Pole): Observable<number> {

    pole.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "pole/create/", JSON.stringify(pole), this.options);
  }

  updatePole(pole: Pole): Observable<number> {

    pole.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "pole/update/", JSON.stringify(pole), this.options);
  }

  getLivePoleMapping(liveId: number) {
    var data = {
      LIVE_ID: liveId
    };
    console.log("live Id: " + liveId)
    return this.httpClient.post<Collegebranchmapping[]>(this.url + "livePoleMapping/getData", JSON.stringify(data), this.options);
  }

  addLivePoleMapping(liveId: number, data1: string[]): Observable<number> {
    var data = {
      LIVE_ID: liveId,
      data: data1,
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "livePoleMapping/addBulk", data, this.options);
  }

  getAllCourseStats(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "studentCourseMapping/get", JSON.stringify(data), this.options);
  }

  getAllChapterStats(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "studentCourseMapping/get", JSON.stringify(data), this.options);
  }

  getAllLiveVideoSummery(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,courseid): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COURSE_ID:courseid
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoSummaryReport", JSON.stringify(data), this.options);
  }
//video summary
getAllVideoSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,courseid,chapterid): Observable<string[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    COURSE_ID:courseid,
    CHAPTER_ID:''
  };
  return this.httpClient.post<string[]>(this.url + "reports/getVideoSummaryReport", JSON.stringify(data), this.options);
}
//student video summary
getAllStudentVideoSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,courseid,chapterid): Observable<string[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    COURSE_ID:courseid,
    CHAPTER_ID:''
  };
  return this.httpClient.post<string[]>(this.url + "reports/getStudentSummaryReport", JSON.stringify(data), this.options);
}//practicesummary
getAllPracticeSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,courseid,chapterid): Observable<string[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    COURSE_ID:courseid,
    CHAPTER_ID:''
  };
  return this.httpClient.post<string[]>(this.url + "reports/getStudentPracticeSummaryReport", JSON.stringify(data), this.options);
}
//student practice
getAllStudentPracticeSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,courseid,chapterid): Observable<string[]> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter,
    COURSE_ID:courseid,
    CHAPTER_ID:''
  };
  return this.httpClient.post<string[]>(this.url + "reports/getPracticeSummaryReport", JSON.stringify(data), this.options);
}
//
  getAllLiveVideoReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "liveVideoUser/get", JSON.stringify(data), this.options);
  }

  getAllLiveVideoUserReponses(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "poleUserResponses/get", JSON.stringify(data), this.options);
  }

  getAllLivevideonotificationsettings(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Livenotificationsetting[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Livenotificationsetting[]>(this.url + "liveVideoNotificationSettingMaster/get", JSON.stringify(data), this.options);
  }

  createLivevideonotificationsetting(livevideonotificationsetting: Livenotificationsetting): Observable<number> {

    livevideonotificationsetting.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "liveVideoNotificationSettingMaster/create/", JSON.stringify(livevideonotificationsetting), this.options);
  }

  updateLivevideonotificationsetting(livevideonotificationsetting: Livenotificationsetting): Observable<number> {

    livevideonotificationsetting.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "liveVideoNotificationSettingMaster/update/", JSON.stringify(livevideonotificationsetting), this.options);
  }

  updateLivePoleMapping(livePoleMapping: Livepolemapping): Observable<number> {

    livePoleMapping.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "livePoleMapping/update/", JSON.stringify(livePoleMapping), this.options);
  }

  getAllLivepolemappings(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Livepolemapping[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Livepolemapping[]>(this.url + "livePoleMapping/get", JSON.stringify(data), this.options);
  }


  getAllTrainerMasters(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Trainer[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Trainer[]>(this.url + "trainer/get", JSON.stringify(data), this.options);
  }

  createTrainerMaster(trainerMaster: Trainer): Observable<number> {

    trainerMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "trainer/create/", JSON.stringify(trainerMaster), this.options);
  }

  updateTrainerMaster(trainerMaster: Trainer): Observable<number> {

    trainerMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "trainer/update/", JSON.stringify(trainerMaster), this.options);
  }
  getSessionReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batchsessionreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batchsessionreport[]>(this.url + "reports/getSessionReport", JSON.stringify(data), this.options);
  }

  getAllLivevideonotificationMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "liveVideoNotificationSetting/get", JSON.stringify(data), this.options);
  }

  getbatchSyllabusDetails(batchId: number): Observable<string[]> {
    var data = {
      BATCH_ID: batchId
    };

    return this.httpClient.post<string[]>(this.url + "batchSessionSyllabus/getBatchSessionBatchSyllabus", JSON.stringify(data), this.options);
  }

  addBatchSingleSyllabusMapping(batchId, timerData, batchSyllabusDetails): Observable<number> {
    var data = {
      BATCH_ID: batchId,
      data1: timerData,
      data2: batchSyllabusDetails
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "batchSessionSyllabus/updateBatchSyllabus", data, this.options);
  }

  getMandayCalculationsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getMandaysCalculationReport", JSON.stringify(data), this.options);
  }


  getRenumerationCalculationsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getRenumerationCalculationReport", JSON.stringify(data), this.options);
  }

  getRatingReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getRatingReport", JSON.stringify(data), this.options);
  }
  getAllFffQuestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Fffquestionmaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Fffquestionmaster[]>(this.url + "fffQuestion/get", JSON.stringify(data), this.options);
  }

  createFffQuestion(fffQuestion: Fffquestionmaster): Observable<number> {

    fffQuestion.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "fffQuestion/create/", JSON.stringify(fffQuestion), this.options);
  }

  updateFffQuestion(fffQuestion: Fffquestionmaster): Observable<number> {

    fffQuestion.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "fffQuestion/update/", JSON.stringify(fffQuestion), this.options);
  }
  getLiveFFFMapping(liveId: number) {
    var data = {
      LIVE_ID: liveId
    };
    return this.httpClient.post<string[]>(this.url + "fffLiveMapping/getData", JSON.stringify(data), this.options);
  }

  addLiveFFFMapping(liveId: number, data1: string[]): Observable<number> {
    var data = {
      LIVE_ID: liveId,
      data: data1,
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "fffLiveMapping/addBulk", data, this.options);
  }
  getAllLiveVideofffUserReponses(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "fffUserResponses/get", JSON.stringify(data), this.options);
  }
  getAllLiveFFFMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "fffLiveMapping/get", JSON.stringify(data), this.options);
  }

  getAllLiveComments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "liveVideoComment/get", JSON.stringify(data), this.options);
  }

  updateLiveComments(liveVideos): Observable<number> {
    liveVideos.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "liveVideoComment/update/", JSON.stringify(liveVideos), this.options);
  }
  updateLiveUsers(liveVideos): Observable<number> {
    liveVideos.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(this.url + "liveVideoUser/update/", JSON.stringify(liveVideos), this.options);
  }
  updateLiveFFFMapping(livefffMapping: Livefffmapping): Observable<number> {

    livefffMapping.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "fffLiveMapping/update/", JSON.stringify(livefffMapping), this.options);
  }

  getParentChapters(courseId: number): Observable<string[]> {
    var data = {
      COURSE_ID: courseId
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getShikuyaaChapterByCourse", JSON.stringify(data), this.options);
  }
  getParentChapters1(courseId: number): Observable<string[]> {
    var data = {
      COURSE_ID: courseId,
      ENABLED:false
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getShikuyaaChapterByCourse", JSON.stringify(data), this.options);
  }
  getParentChaptersNew(courseId: number): Observable<string[]> {
    var data = {
      COURSE_ID: courseId,
      
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getCourseWIseShikuyaaChapterHierachy", JSON.stringify(data), this.options);
  }
  getParentChaptersNews(courseId: number): Observable<string[]> {
    var data = {
      COURSE_ID: courseId,
      IS_FOR_CHAPTER:1
      
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaChapter/getCourseWIseShikuyaaChapterHierachy", JSON.stringify(data), this.options);
  }
  getAllCoupontypes(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Coupontype[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Coupontype[]>(this.url + "coupontype/get", JSON.stringify(data), this.options);
  }

  createCoupontype(coupontype: Coupontype): Observable<number> {

    coupontype.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "coupontype/create/", JSON.stringify(coupontype), this.options);
  }

  updateCoupontype(coupontype: Coupontype): Observable<number> {

    coupontype.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "coupontype/update/", JSON.stringify(coupontype), this.options);
  }

  getAssignmentDetails1(assignmentId: number, partId: number, sectionId: number, chapterId: number, subChapterId: number, groupType: string) {
    var data = {
      ASSIGNMENT_ID: assignmentId,
      PART_ID: partId,
      SECTION_ID: sectionId,
      CHAPTER_ID: chapterId,
      SUB_CHAPTER_ID: subChapterId,
      GROUP_TYPE: groupType
    };
    return this.httpClient.post<string[]>(this.url + "assignment/getAssignmentDetails", JSON.stringify(data), this.options);
  }

  createAssignmentDeatails(assignmentDetailsData: Assignmentdetails): Observable<number> {
    assignmentDetailsData.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "assignmentDetail/create/", JSON.stringify(assignmentDetailsData), this.options);
  }


  getStudentCourseJoiningReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCourseJoiningReport", JSON.stringify(data), this.options);
  }
  getStudentCoursePaymentsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCoursePaymentsReport", JSON.stringify(data), this.options);
  }

  getShikuyaaVideoStatReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getShikuyaaVideoStatReport", JSON.stringify(data), this.options);
  }

  getStudentVideoWatchTimeReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentVideoWatchTimeReport", JSON.stringify(data), this.options);
  }


  ///////////
  getStudents(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "student/get", JSON.stringify(data), this.options);
  }
  getStudentRegistrationReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,yearid,college,branch,state): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COLLEGE_ID:college,
      BRANCH_ID:branch,
      COLLEGE_STATE_ID:state,
      PASSING_YEAR_ID:yearid
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentRegistrationReport", JSON.stringify(data), this.options);
  }


  getStudentAppUsageReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,cur,yearid,college,branch,state): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      CURR_COURSE_ID:cur,
      COLLEGE_ID:college,
      BRANCH_ID:branch,
      COLLEGE_STATE_ID:state,
      PASSING_YEAR_ID:yearid
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentAppUsageReport", JSON.stringify(data), this.options);
  }

  getStudentCourseStatReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCourseStatReport", JSON.stringify(data), this.options);
  }
  getStudentCourseSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, FROM_DATE, TO_DATE,IS_PURCHASED,IS_FREE_TRAIL_TAKEN): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COURSE_ID: COURSE_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      IS_PURCHASED:IS_PURCHASED,
      IS_FREE_TRIAL_TAKEN:IS_FREE_TRAIL_TAKEN
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCourseSummaryReport", JSON.stringify(data), this.options);
  }

  getStudentCoursePracticeSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCoursePracticeSummaryReport", JSON.stringify(data), this.options);
  }

  getStudentCourseVideoSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCourseVideoSummaryReport", JSON.stringify(data), this.options);
  }
  getStudentCourseLiveVideoSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCourseLiveVideoSummaryReport", JSON.stringify(data), this.options);
  }
  getStudentCourseAssesmentSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, courseid): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COURSE_ID: courseid,
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCourseAssesmentSummaryReport", JSON.stringify(data), this.options);
  }



  getStudentPracticeDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      STUDENT_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentPracticeDetailsReport", JSON.stringify(data), this.options);
  }

  getStudentVideoDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      STUDENT_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentVideoDetailsReport", JSON.stringify(data), this.options);
  }

  getStudentLiveDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      STUDENT_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentLiveDetailsReport", JSON.stringify(data), this.options);
  }
  getStudentAssesmentDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      STUDENT_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentAssesmentDetailsReport", JSON.stringify(data), this.options);
  }
  getStudentLiveVideoCommentsDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, VIDEO_ID, STUDENT_ID,COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: VIDEO_ID,
      STUDENT_ID: STUDENT_ID,
      COURSE_ID:COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentLiveVideoCommentsDetailsReport", JSON.stringify(data), this.options);
  }

  getStudentLiveVideoPoleDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, VIDEO_ID, STUDENT_ID,COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: VIDEO_ID,
      STUDENT_ID: STUDENT_ID,
      COURSE_ID:COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentLiveVideoPoleDetailsReport", JSON.stringify(data), this.options);
  }
  getStudentLiveVideoFFFDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, VIDEO_ID, STUDENT_ID,COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: VIDEO_ID,
      STUDENT_ID: STUDENT_ID,
      COURSE_ID:COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentLiveVideoFFFDetailsReport", JSON.stringify(data), this.options);
  }
  getStudentPaymentReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentPaymentReport", JSON.stringify(data), this.options);
  }
  getStudentPaymentDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      USER_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentPaymentDetailedReport", JSON.stringify(data), this.options);
  }
  getStudentWalletSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentWalletSummaryReport", JSON.stringify(data), this.options);
  }
  getCourseSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseSummaryReport", JSON.stringify(data), this.options);
  }
  getCoursePaymentReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCoursePaymentReport", JSON.stringify(data), this.options);
  }
  getCourseStructureSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseStructureSummaryReport", JSON.stringify(data), this.options);
  }
  getStudentChapterPracticeQuestionsReport( CHAPTER_ID, STUDENT_ID): Observable<string[]> {
    var data = {
     
      CHAPTER_ID: CHAPTER_ID,
      STUDENT_ID: STUDENT_ID,
     
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentChapterPracticeQuestionsReport", JSON.stringify(data), this.options);
  }
  getCourseChapterStatus(CHAPTER_ID, STUDENT_ID): Observable<string[]> {
    var data = {
      CHAPTER_ID: CHAPTER_ID,
      STUDENT_ID: STUDENT_ID
    };
    return this.httpClient.post<string[]>(this.url + "student/getCourseChapterStatus", JSON.stringify(data), this.options);
  }

  getCourseChapterSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, CHAPTER_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      CHAPTER_ID: CHAPTER_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseChapterSummaryReport", JSON.stringify(data), this.options);
  }
  getCourseVideoSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, CHAPTER_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      CHAPTER_ID: CHAPTER_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseVideoSummaryReport", JSON.stringify(data), this.options);
  }
  getCourseVideoQuestionDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, VIDEO_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      VIDEO_ID: VIDEO_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseVideoQuestionDetailsReport", JSON.stringify(data), this.options);
  }

  getCourseLiveVideoSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoSummaryReport", JSON.stringify(data), this.options);
  }
  getCourseLiveVideoStudentDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, STUDENT_ID, LIVE_MASTER_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      STUDENT_ID: STUDENT_ID,
      filter: filter,
      LIVE_MASTER_ID: LIVE_MASTER_ID
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoStudentDetailsReport", JSON.stringify(data), this.options);
  }
  getCourseLiveVideoPoleDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, LIVE_VIDEO_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: LIVE_VIDEO_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoPoleDetailsReport", JSON.stringify(data), this.options);
  }

  getCourseLiveVideoPoleStudentDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, LIVE_VIDEO_ID, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: LIVE_VIDEO_ID,
      STUDENT_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoPoleStudentDetailsReport", JSON.stringify(data), this.options);
  }

  getCourseLiveVideoFFFStudentDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, LIVE_VIDEO_ID, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: LIVE_VIDEO_ID,
      STUDENT_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoFFFStudentDetailsReport", JSON.stringify(data), this.options);
  }

  getCourseLiveVideoCommentsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, LIVE_VIDEO_ID,COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: LIVE_VIDEO_ID,
      COURSE_ID:COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoCommentsReport", JSON.stringify(data), this.options);
  }


  getWalletTransactionDetailReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE, TO_DATE): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getWalletTransactionDetailReport", JSON.stringify(data), this.options);
  }
  getFreeTrialStudentDetailReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,

      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getFreeTrialStudentDetailReport", JSON.stringify(data), this.options);
  }
  getWalletTransactionDetailReport2(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, LIVE_VIDEO_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: LIVE_VIDEO_ID,

      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getWalletTransactionDetailReport", JSON.stringify(data), this.options);
  }

  getCourseLiveVideoFFFDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, LIVE_VIDEO_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIVE_VIDEO_ID: LIVE_VIDEO_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseLiveVideoFFFDetailsReport", JSON.stringify(data), this.options);
  }

  getCourseAssesmentSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
     
      filter: filter,
      COURSE_ID: COURSE_ID
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseAssesmentSummaryReport", JSON.stringify(data), this.options);
  }

  getCourseAssesmentDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, MOCK_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      MAPPED_MOCK_MASTER_ID: MOCK_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseAssesmentDetailsReport", JSON.stringify(data), this.options);
  }
  getPaymentSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE, TO_DATE): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getPaymentSummaryReport", JSON.stringify(data), this.options);
  }


  getDashboardData(): Observable<string[]> {
    var data = {

    };
    return this.httpClient.post<string[]>(this.url + "user/getDashboardData", JSON.stringify(data), this.options);
  }

  getLineChartData(FROM_DATE, TO_DATE): Observable<string[]> {
    var data = {
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<string[]>(this.url + "user/getLineChartData", JSON.stringify(data), this.options);
  }


  registrationAttemptDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "registrationAttemptDetails/get", JSON.stringify(data), this.options);
  }

  getStudentWalletDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, STUDENT_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      STUDENT_ID: STUDENT_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentWalletDetailedReport", JSON.stringify(data), this.options);
  }
  getStudentEmailSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
 
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: localStorage.getItem('filter')
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentEmailSummaryReport", JSON.stringify(data), this.options);
  }

  getStudentCouponSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCouponSummaryReport", JSON.stringify(data), this.options);
  }

  getCourseCouponDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseCouponDetailsReport", JSON.stringify(data), this.options);
  }

  getCourseCouponStudentDetailsReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID, COUPON_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      COUPON_ID: COUPON_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseCouponStudentDetailsReport", JSON.stringify(data), this.options);
  }

  getCourseWalletTransactionSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE, TO_DATE,COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      filter: filter,
      ID:COURSE_ID
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseWalletTransactionSummaryReport", JSON.stringify(data), this.options);
  }

  getCourseWalletTransactionDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, COURSE_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      COURSE_ID: COURSE_ID,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseWalletTransactionDetailedReport", JSON.stringify(data), this.options);
  }

  getStudentEmailDetailReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, EMAIL_ID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      EMAIL_ID: EMAIL_ID,
      filter:  localStorage.getItem('filter')
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentEmailDetailReport", JSON.stringify(data), this.options);
  }

  getStudentSMSSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,studentID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      STUDENT_ID:studentID,
    
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentSMSSummaryReport", JSON.stringify(data), this.options);
  }

  getStudentSMSDetailReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, MOBILE_NO): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      MOBILE_NO: MOBILE_NO,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentSMSDetailReport", JSON.stringify(data), this.options);
  }

  getSMSSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getSMSSummaryReport", JSON.stringify(data), this.options);
  }

  getEmailSummaryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getEmailSummaryReport", JSON.stringify(data), this.options);
  }
  getSMSDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE, TO_DATE): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getSMSDetailedReport", JSON.stringify(data), this.options);
  }

  getEmailDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE, TO_DATE): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getEmailDetailedReport", JSON.stringify(data), this.options);
  }
  getStudentCouponDetailedReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, STUDENT_ID,courseID): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      USER_ID: STUDENT_ID,
      filter: filter,
      COURSE_ID:courseID
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentCouponDetailedReport", JSON.stringify(data), this.options);
  }

  getAppRegistrationPendingReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,yearid,college,branch,state): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COLLEGE_ID:college,
      BRANCH_ID:branch,
      COLLEGE_STATE_ID:state,
      PASSING_YEAR_ID:yearid
    };
    return this.httpClient.post<string[]>(this.url + "reports/getAppRegistrationPendingReport", JSON.stringify(data), this.options);
  }

  getStudentNotificationReport(STUDENT_KEY): Observable<string[]> {
    var data = {
      STUDENT_KEY: STUDENT_KEY
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentNotificationReport", JSON.stringify(data), this.options);
  }

  getAllMockGroups(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Assignmentgroup[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Assignmentgroup[]>(this.url + "mockGroup/get", JSON.stringify(data), this.options);
  }

  createMockGroup(assignmentGroup: Assignmentgroup): Observable<number> {

    assignmentGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "mockGroup/create/", JSON.stringify(assignmentGroup), this.options);
  }

  updateMockGroup(assignmentGroup: Assignmentgroup): Observable<number> {

    assignmentGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "mockGroup/update/", JSON.stringify(assignmentGroup), this.options);
  }

  getAllMockInstructions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Instruction2[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Instruction2[]>(this.url + "mockInstruction/get", JSON.stringify(data), this.options);
  }

  createMockInstruction(instruction: Instruction2): Observable<number> {

    instruction.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "mockInstruction/create/", JSON.stringify(instruction), this.options);
  }

  updateMockInstruction(instruction: Instruction2): Observable<number> {

    instruction.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "mockInstruction/update/", JSON.stringify(instruction), this.options);
  }

  getAllMocks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Assignment2[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Assignment2[]>(this.url + "mock/get", JSON.stringify(data), this.options);
  }

  createMock(assignment: Assignment2): Observable<number> {
    assignment.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "mock/create/", JSON.stringify(assignment), this.options);
  }

  updateMock(assignment: Assignment2): Observable<number> {
    assignment.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "mock/update/", JSON.stringify(assignment), this.options);
  }

  getMockInstructions(assignmentId: number) {
    var data = {
      MOCK_MASTER_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "mockInstructionMapping/getData", JSON.stringify(data), this.options);
  }

  addMockInstructions(assignmentId: number, data1: string[]): Observable<number> {
    var data = {
      MOCK_MASTER_ID: assignmentId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "mockInstructionMapping/addBulk", data, this.options);
  }


  getMockDetails(assignmentId: number, chapterId: number) {
    var data = {
      MOCK_MASTER_ID: assignmentId,

      CHAPTER_ID: chapterId
    };
    return this.httpClient.post<string[]>(this.url + "mock/getQuestionsDetails", JSON.stringify(data), this.options);
  }


  getMockQuestionDetails(assignmentId: number, chapterId: number, levelId: number,): Observable<number> {
    var data = {
      MOCK_MASTER_ID: assignmentId,
      CHAPTER_ID: chapterId,
      LEVEL_ID: levelId
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mock/getMockQuestions", data, this.options);
  }
  getMockQuestionDetails1(assignmentId: number, chapterId: number, levelId: number,like:any): Observable<number> {
    var data = {
      MOCK_MASTER_ID: assignmentId,
      CHAPTER_ID: chapterId,
      LEVEL_ID: levelId,
      QUESTION_LIKE:like

    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mock/getMockQuestions", data, this.options);
  }
  getMappedMockQuestionDetails(assignmentId: number, chapterId: number, levelId: number,): Observable<number> {
    var data = {
      MAPPED_MOCK_MASTER_ID: assignmentId,
      CHAPTER_ID: chapterId,
      LEVEL_ID: levelId
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mappedMock/getMappedMockQuestions", data, this.options);
  }

  addMockDetails(chapterId: number, assignmentId: number, data1, totalQuestions: number, totalTime: string): Observable<number> {
    var data = {

      CHAPTER_ID: chapterId,
      ASSIGNMENT_ID: assignmentId,
      data: data1,
      TOTAL_QUESTIONS: totalQuestions,
      TOTAL_TIME: totalTime
    };
    return this.httpClient.post<number>(this.url + "assignment/manageQuestionDetails", data, this.options);
  }

  getMockBatches(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Batch[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Batch[]>(this.url + "assignment/getBatches", JSON.stringify(data), this.options);
  }


  getMockMapping(assignmentId: number) {
    var data = {
      MOCK_MASTER_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "assignment/getQuestionsDetails", JSON.stringify(data), this.options);
  }


  addMockMapping(data: any): Observable<number> {

    console.log(data)
    return this.httpClient.post<number>(this.url + "mock/mapMocks", data[0], this.options);
  }
  mappedMockMappingInfo(data: any): Observable<number> {

    return this.httpClient.put<number>(this.url + "mappedMockMappingInfo/update", data[0], this.options);
  }
  getAllMappedMocks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Mappedassignment2[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Mappedassignment2[]>(this.url + "mappedmock/get", JSON.stringify(data), this.options);
  }
  getAllMappedMockInfo(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any[]>(this.url + "mappedmockmappingcoursesinfo/get", JSON.stringify(data), this.options);
  }
  createMappedMock(mappedAssignment: Mappedassignment2): Observable<number> {

    mappedAssignment.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "mappedmock/create/", JSON.stringify(mappedAssignment), this.options);
  }

  updateMappedMock(mappedAssignment: Mappedassignment2): Observable<number> {
    mappedAssignment.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "mappedMock/update/", JSON.stringify(mappedAssignment), this.options);
  }

  getMappedMockInstructions(assignmentId: number) {
    var data = {
      MAPPED_MOCK_MASTER_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "mappedMockInstructionMapping/getData", JSON.stringify(data), this.options);
  }

  addMappedMockInstructions(assignmentId: number, data1: string[]): Observable<number> {
    var data = {
      MAPPED_MOCK_MASTER_ID: assignmentId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "mappedMockInstructionMapping/addBulk", data, this.options);
  }

  getMappedMockDetails(assignmentId: number, chapterId: number) {
    this.httpHeaders123 = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      'timeout': `${20000}`
    });
    this.options123 = {
      headers: this.httpHeaders123
    };
    var data = {
      MAPPED_MOCK_MASTER_ID: assignmentId,

      CHAPTER_ID: chapterId
    };
    console.log(data)
    return this.httpClient.post<string[]>(this.url + "mappedMock/getMappingquestionDetails", JSON.stringify(data), this.options);
  }

  addMappedMockDetails(chapterId: number, assignmentId: number, data1, totalQuestions: number, totalTime: string): Observable<number> {
    var data = {

      CHAPTER_ID: chapterId,
      MAPPED_MOCK_MASTER_ID: assignmentId,
      data: data1,
      TOTAL_QUESTIONS: totalQuestions,
      TOTAL_TIME: totalTime
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mappedMock/updateMappingQuestionDetails", data, this.options);
  }

  getMappedMockMappingData(assignmentId: number, filter: string) {
    var data = {
      MAPPED_MOCK_MASTER_ID: assignmentId,
      filter: filter
    };
    console.log(data)
    return this.httpClient.post<string[]>(this.url + "mappedMock/getMappedData", JSON.stringify(data), this.options);
  }


  addMappedMockMapping(name: string, scope: string, assignmentId: number, data1: string[]): Observable<number> {
    var data = {
      SCOPE: scope,
      MAPPED_MOCK_MASTER_ID: assignmentId,
      NAME: name,
      data: data1,
    };
    console.log(data)
    return this.httpClient.post<number>(this.url + "mappedMock/updateMappingData", data, this.options);
  }

  addMockBulkQuestions(data1: Questionmaster[]): Observable<number> {
    var data = {
      DATA: data1
    };

    return this.httpClient.post<number>(this.url + "mockquestion/addBulk", data, this.options);
  }

  getAllMockCombinedMappingData(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, flag: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FLAG: flag,
      LIKE_STRING:''
    };
    return this.httpClient.post<string[]>(this.url + "mappedMock/getMasterData", JSON.stringify(data), this.options);
  }
  getAllMockCombinedMappingDatas(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, flag: string,str:any): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FLAG: flag,
      LIKE_STRING:str
    };
    return this.httpClient.post<string[]>(this.url + "mappedMock/getMasterData", JSON.stringify(data), this.options);
  }
  getAllMockDestails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Chapter[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Chapter[]>(this.url + "mockDetails/get", JSON.stringify(data), this.options);
  }

  getAllMappedMockDetailsMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "mappedMockDetails/get", JSON.stringify(data), this.options);
  }

  getAllMockQuestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "mockDetailsQuestionMapping/get", JSON.stringify(data), this.options);
  }

  getAllMappedMockQuestions(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "mappedMockDetailsQuestionMapping/get", JSON.stringify(data), this.options);
  }

  getMockDetails1(assignmentId: number, chapterId: number, mode) {
    var data = {
      MOCK_MASTER_ID: assignmentId,
      CHAPTER_ID: chapterId,
      MODE: mode
    };
    return this.httpClient.post<string[]>(this.url + "mock/getMockDetails", JSON.stringify(data), this.options);
  }

  createMockDeatails(assignmentDetailsData: Assignmentdetails2): Observable<number> {
    assignmentDetailsData.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "mockDetails/create/", JSON.stringify(assignmentDetailsData), this.options);
  }
  manageMockDetails(assignmentDetailsData: Assignmentdetails2): Observable<number> {
    assignmentDetailsData.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "mock/manageMockDetails", JSON.stringify(assignmentDetailsData), this.options);
  }
  getCurrentStatusMock(assignmentId: number) {
    var data = {
      MAPPED_MOCK_MASTER_ID: assignmentId
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaStudentMockResultDetails/getStatus", JSON.stringify(data), this.options);
  }
  

  getResultMock(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaStudentMockResultDetails/get", JSON.stringify(data), this.options);
  }
  getResultMocks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "shikuyaaStudentMockResult/getDetails", JSON.stringify(data), this.options);
  }
  mappedMockMappingCoursesInfo(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "mappedMockMappingCoursesInfo/get", JSON.stringify(data), this.options);
  }


  //reports
  getLiveAssessmentSummery(mappedMockMasterId: number, fromDate: string, toDate: string, date: string, courseId: number) {
    var data = {
      MAPPED_MOCK_MASTER_ID: mappedMockMasterId,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      DATE: date,
      COURSE_ID: courseId
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentSummaryReport", JSON.stringify(data), this.options);
  }

  getCollegewiseLiveAssessmentSummery(collegeId: number[], mappedMockMasterId: number, fromDate: string, toDate: string, date: string, courseId: number) {
    var data = {
      COLLEGE_ID: collegeId,
      MAPPED_MOCK_MASTER_ID: mappedMockMasterId,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      DATE: date,
      COURSE_ID: courseId,
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCollegeWiseLiveAssesmentSummaryReport", JSON.stringify(data), this.options);
  }

  getBranchwiseLiveAssessmentSummery(BranchId: number[], mappedMockMasterId: number, fromDate: string, toDate: string, date: string, courseId: number) {
    var data = {
      BRANCH_ID: BranchId,
      MAPPED_MOCK_MASTER_ID: mappedMockMasterId,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      DATE: date,
      COURSE_ID: courseId,
    };
    return this.httpClient.post<string[]>(this.url + "reports/getBranchWiseLiveAssesmentSummaryReport", JSON.stringify(data), this.options);
  }

  getCollegewiseBranchwiseLiveAssessmentSummery(mappedMockMasterId: number, fromDate: string, toDate: string, date: string, courseId: number) {
    var data = {
      //: number, pageSize: number, sortKey: string, sortValue:
      // pageIndex: pageIndex,
      // pageSize: pageSize,
      // sortKey: sortKey,
      // sortValue: sortValue,
      // filter: filter

      MAPPED_MOCK_MASTER_ID: mappedMockMasterId,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      DATE: date,
      COURSE_ID: courseId

    };
    return this.httpClient.post<string[]>(this.url + "reports/getBranchCollegeWiseLiveAssesmentSummaryReport", JSON.stringify(data), this.options);
  }

  getStatusLiveAssessmentSummery(flag: string, mappedMockMasterId: number, fromDate: string, toDate: string, date: string, courseId: number) {
    var data = {
      FLAG: flag,
      MAPPED_MOCK_MASTER_ID: mappedMockMasterId,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      DATE: date,
      COURSE_ID: courseId
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentDetailedReport(mappedMockMasterId: number, fromDate: string, toDate: string, date: string, courseId: string) {
    var data = {
      MAPPED_MOCK_MASTER_ID: mappedMockMasterId,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      DATE: date,
      COURSE_ID: courseId
    };
    return this.httpClient.post<string[]>(this.url + "/reports/getLiveAssesmentDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseLiveAssessmentDetailedSummery(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "", JSON.stringify(data), this.options);
  }

  getBranchwiseLiveAssessmentDetailedSummery(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentSummaryReport", JSON.stringify(data), this.options);
  }

  getAllStudentAssessmentQuestionDetailReport(studid,mapid) {
    var data = {
      
      STUDENT_ID:studid,
      MAPPED_MOCK_ID:mapid
    };
    return this.httpClient.post<string[]>(this.url + "reports/getStudentAssesmentQuestionDetailsReport", JSON.stringify(data), this.options);
  }

  getAllCourseStudentAssessmentDetailReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,courseid,mockid) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COURSE_ID:courseid,
      MAPPED_MOCK_MASTER_ID:mockid
     
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseAssesmentStudentDetailsReport", JSON.stringify(data), this.options);
  }
  getAllCourseAssessmentPartDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string,courseid,mockid) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      COURSE_ID:courseid,
      MAPPED_MOCK_MASTER_ID:mockid
     
    };
    return this.httpClient.post<string[]>(this.url + "reports/getCourseAssesmentPartDetailsReport", JSON.stringify(data), this.options);
  }


  getCollegeBranchwiseLiveAssessmentDetailedSummery(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentSummaryReport", JSON.stringify(data), this.options);
  }


  getLiveAssessmentPartSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentSummaryReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentPartDetailed(mappedMockMasterId: number, partId: number, studentId: number) {
    var data = {
      MAPPED_MOCK_MASTER_ID: mappedMockMasterId,
      PART_ID: partId,
      STUDENT_ID: studentId,
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentPartDetailedReport", JSON.stringify(data), this.options);
  }


  getLiveAssessmentCollegewisePartSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentPartDetailedReport", JSON.stringify(data), this.options);
  }


  getLiveAssessmentBranchwisePartSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentPartDetailedReport", JSON.stringify(data), this.options);
  }


  getLiveAssessmentBranchwisePartDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentPartDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseBranchwisePartSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentPartDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseBranchwisePartDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentPartDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentChapterDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentBranchwiseChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseChapterDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseBranchwiseChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentQuestionSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentQuestionSummaryReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentQuestionDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentBranchwiseQuestionDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseQuestionDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseBranchwiseQuestionSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getLiveAssessmentCollegewiseBranchwiseQuestionDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getLiveAssesmentChapterDetailedReport", JSON.stringify(data), this.options);
  }

  getPartSummary(partId: number) {
    var data = {
      PART_ID: partId,

    };
    return this.httpClient.post<string[]>(this.url + "reports/getPartSummaryReport", JSON.stringify(data), this.options);
  }

  getPartDetailed(partId: number, questionId: number) {
    var data = {
      PART_ID: partId,
      pageSize: questionId,

    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewisePartSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewisePartDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getBranchwisePartSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getBranchwisePartDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseBranchwisePartSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }



  getCollegewiseBranchwisePartDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }


  getChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getChapterDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }


  getCollegewiseChapterDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getBranchwiseChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewisePartwiseChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewisePartwiseChapterDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getBranchwisePartwiseChapterSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getBranchwisePartwiseChapterDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "reports/getpartDetailedReport", JSON.stringify(data), this.options);
  }

  getPracticeQuestionSummary(fromDate: string, toDate: string) {
    var data = {
      FROM_DATE: fromDate,
      TO_DATE: toDate,

    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionSummaryReport", JSON.stringify(data), this.options);
  }

  getPracticeQuestionDetailed(questionId: number) {
    var data = {
      QUESTION_ID: questionId,

    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }


  getBranchwisePracticeQuestionSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }


  getCollegewisePracticeQuestionSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseBranchwisePracticeQuestionDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getAssessmentSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getAssessmentDetailed(questionId: number) {
    var data = {
      QUESTION_ID: questionId,

    };
    return this.httpClient.post<string[]>(this.url + "report/getAssesmentDetailedReport", JSON.stringify(data), this.options);
  }

  getBranchwiseAssessmentSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getBranchwiseAssessmentDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseAssessmentSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseAssessmentDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseBranchwiseAssessmentSummary(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getCollegewiseBranchwiseAssessmentDetailed(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "report/getPracticeQuestionDetailedReport", JSON.stringify(data), this.options);
  }

  getAllShikyaaCCollegeMultiselect(): Observable<string[]> {
    var data = {
    };
    return this.httpClient.post<string[]>(this.url + "college/getColleges", JSON.stringify(data), this.options);
  }


  //Website Servies




  getAllMenu(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Menu[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Menu[]>(this.url + "web/menu/get", JSON.stringify(data), this.options);
  }

  createMenu(menu: Menu): Observable<number> {

    menu.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/menu/create/", JSON.stringify(menu), this.options);
  }

  updateMenu(menu: Menu): Observable<number> {
    console.log(menu)
    menu.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/menu/update/", JSON.stringify(menu), this.options);
  }


  getAllSeo(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Seo[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Seo[]>(this.url + "web/seo/get", JSON.stringify(data), this.options);
  }
  createSeo(seo: Seo): Observable<number> {

    seo.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/seo/create/", JSON.stringify(seo), this.options);
  }

  updateSeo(seo: Seo): Observable<number> {

    seo.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/seo/update/", JSON.stringify(seo), this.options);
  }
  getAllTeam(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Team[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Team[]>(this.url + "web/team/get", JSON.stringify(data), this.options);
  }

  createTeam(team: Team): Observable<number> {

    team.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/team/create/", JSON.stringify(team), this.options);
  }

  updateTeam(team: Team): Observable<number> {

    team.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/team/update/", JSON.stringify(team), this.options);
  }
  getAllAssociates(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Associates[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Associates[]>(this.url + "web/associates/get", JSON.stringify(data), this.options);
  }

  createAssociates(associates: Associates): Observable<number> {

    associates.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/associates/create/", JSON.stringify(associates), this.options);
  }

  updateAssociates(associates: Associates): Observable<number> {

    associates.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/associates/update/", JSON.stringify(associates), this.options);
  }
  getAllReview(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Review[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Review[]>(this.url + "web/review/get", JSON.stringify(data), this.options);
  }

  createReview(review: Review): Observable<number> {
    console.log(review)
    review.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/review/create/", JSON.stringify(review), this.options);
  }

  updateReview(review: Review): Observable<number> {

    review.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/review/update/", JSON.stringify(review), this.options);
  }


  getAllKnowledgebaseCategory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<KnowledgebaseCategory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<KnowledgebaseCategory[]>(this.url + "web/knowledgebaseCategory/get", JSON.stringify(data), this.options);
  }

  createKnowledgebaseCategory(knowledgebaseCategory: KnowledgebaseCategory): Observable<number> {

    knowledgebaseCategory.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/knowledgebaseCategory/create/", JSON.stringify(knowledgebaseCategory), this.options);
  }

  updateKnowledgebaseCategory(knowledgebaseCategory: KnowledgebaseCategory): Observable<number> {

    knowledgebaseCategory.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/knowledgebaseCategory/update/", JSON.stringify(knowledgebaseCategory), this.options);
  }
  getAllJobApplication(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<JobApplication[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<JobApplication[]>(this.url + "web/jobApplication/get", JSON.stringify(data), this.options);
  }

  createJobApplication(jobApplication: JobApplication): Observable<number> {

    jobApplication.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/jobApplication/create/", JSON.stringify(jobApplication), this.options);
  }

  updateJobApplication(jobApplication: JobApplication): Observable<number> {

    jobApplication.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/jobApplication/update/", JSON.stringify(jobApplication), this.options);
  }


  getAllSuccessStories(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<SuccessStories[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<SuccessStories[]>(this.url + "web/successStories/get", JSON.stringify(data), this.options);
  }

  createSuccessStories(successStories: SuccessStories): Observable<number> {

    successStories.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/successStories/create/", JSON.stringify(successStories), this.options);
  }

  updateSuccessStories(successStories: SuccessStories): Observable<number> {

    successStories.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/successStories/update/", JSON.stringify(successStories), this.options);
  }


  getAllBlog(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Blogs[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Blogs[]>(this.url + "web/blog/get", JSON.stringify(data), this.options);
  }

  createBlog(blog: Blogs): Observable<number> {

    blog.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/blog/create/", JSON.stringify(blog), this.options);
  }

  updateBlog(blog: Blogs): Observable<number> {

    blog.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/blog/update/", JSON.stringify(blog), this.options);
  }
  getAllWeidget(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Weidget[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Weidget[]>(this.url + "web/weidget/get", JSON.stringify(data), this.options);
  }

  createWeidget(weidget: Weidget): Observable<number> {

    weidget.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/weidget/create/", JSON.stringify(weidget), this.options);
  }

  updateWeidget(weidget: Weidget): Observable<number> {

    weidget.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/weidget/update/", JSON.stringify(weidget), this.options);
  }

  getAllContact(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Contact[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Contact[]>(this.url + "web/contact/get", JSON.stringify(data), this.options);
  }



  getAllCategory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Category[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Category[]>(this.url + "web/category/get", JSON.stringify(data), this.options);
  }

  createCategory(category: Category): Observable<number> {

    category.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/category/create/", JSON.stringify(category), this.options);
  }

  updateCategory(category: Category): Observable<number> {

    category.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/category/update/", JSON.stringify(category), this.options);
  }


  getAllDesignationWeb(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<DesignationWeb[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<DesignationWeb[]>(this.url + "web/WebsiteDesignation/get", JSON.stringify(data), this.options);
  }

  createDesignationWeb(designation: DesignationWeb): Observable<number> {

    designation.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/WebsiteDesignation/create/", JSON.stringify(designation), this.options);
  }

  updateDesignationWeb(designation: DesignationWeb): Observable<number> {

    designation.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/WebsiteDesignation/update/", JSON.stringify(designation), this.options);
  }

  getAllResume(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Resume[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Resume[]>(this.url + "web/resume/get", JSON.stringify(data), this.options);
  }

  createResume(resume: Resume): Observable<number> {

    resume.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/resume/create/", JSON.stringify(resume), this.options);
  }

  updateResume(resume: Resume): Observable<number> {

    resume.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/resume/update/", JSON.stringify(resume), this.options);
  }


  getAllChapterKnowledgeBase(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ChapterKnowledgeBase[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<ChapterKnowledgeBase[]>(this.url + "web/chapterKnowledgeBase/get", JSON.stringify(data), this.options);
  }

  createChapterKnowledgeBase(chapterKnowledgeBase: ChapterKnowledgeBase): Observable<number> {

    chapterKnowledgeBase.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/chapterKnowledgeBase/create/", JSON.stringify(chapterKnowledgeBase), this.options);
  }

  updateChapterKnowledgeBase(chapterKnowledgeBase: ChapterKnowledgeBase): Observable<number> {

    chapterKnowledgeBase.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/chapterKnowledgeBase/update/", JSON.stringify(chapterKnowledgeBase), this.options);
  }

  getAllCareer(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Career[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Career[]>(this.url + "web/career/get", JSON.stringify(data), this.options);
  }

  createCareer(career: Career): Observable<number> {

    career.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/career/create/", JSON.stringify(career), this.options);
  }

  updateCareer(career: Career): Observable<number> {

    career.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/career/update/", JSON.stringify(career), this.options);
  }

  getAllGame(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Game[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Game[]>(this.url + "web/game/get", JSON.stringify(data), this.options);
  }

  createGame(game: Game): Observable<number> {

    game.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/game/create/", JSON.stringify(game), this.options);
  }

  updateGame(game: Game): Observable<number> {

    game.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/game/update/", JSON.stringify(game), this.options);
  }

  getAllEnrollment(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Enrollment[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Enrollment[]>(this.url + "web/enrollment/get", JSON.stringify(data), this.options);
  }

  createEnrollment(enrollment: Enrollment): Observable<number> {

    enrollment.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/enrollment/create/", JSON.stringify(enrollment), this.options);
  }

  updateEnrollment(enrollment: Enrollment): Observable<number> {

    enrollment.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/enrollment/update/", JSON.stringify(enrollment), this.options);
  }
  getAllStudentGameProgress(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<StudentGameProgress[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<StudentGameProgress[]>(this.url + "web/studentGameProgress/get", JSON.stringify(data), this.options);
  }

  getAllCollegeAssociates(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CollegeAssociates[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<CollegeAssociates[]>(this.url + "web/collegeAssociates/get", JSON.stringify(data), this.options);
  }

  createCollegeAssociates(collegeAssociates: CollegeAssociates): Observable<number> {
    collegeAssociates.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/collegeAssociates/create/", JSON.stringify(collegeAssociates), this.options);
  }

  updateCollegeAssociates(collegeAssociates: CollegeAssociates): Observable<number> {
    collegeAssociates.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/collegeAssociates/update/", JSON.stringify(collegeAssociates), this.options);
  }

  getAllBlogImages(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Blogs[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Blogs[]>(this.url + "web/blogImg/get", JSON.stringify(data), this.options);
  }
  updateBlogImages(dataBlogImage): Observable<number> {
    dataBlogImage.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/blogImg/update/", JSON.stringify(dataBlogImage), this.options);
  }
  getAllBlogComments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "web/blogComments/get", JSON.stringify(data), this.options);
  }

  getAllPage(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Page[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Page[]>(this.url + "web/page/get", JSON.stringify(data), this.options);
  }

  createPage(page: Page): Observable<number> {

    page.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/page/create/", JSON.stringify(page), this.options);
  }

  updatePage(page: Page): Observable<number> {

    page.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/page/update/", JSON.stringify(page), this.options);
  }
  getAllBlock(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Blockss[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Blockss[]>(this.url + "web/block/get", JSON.stringify(data), this.options);
  }

  createBlock(block: Blockss): Observable<number> {

    block.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/block/create/", JSON.stringify(block), this.options);
  }

  updateBlock(block: Blockss): Observable<number> {

    block.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/block/update/", JSON.stringify(block), this.options);
  }

  getBlockDetails(pageId: number) {
    var data = {
      PAGE_ID: pageId
    };
    console.log(pageId)
    return this.httpClient.post<string[]>(this.url + "web/pageblockmapping/getData", JSON.stringify(data), this.options);
  }

  addBlockDetails(pageId: number, data1: string[]): Observable<number> {
    console.log(pageId)
    var data = {
      PAGE_ID: pageId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "web/pageblockmapping/addBulk", data, this.options);
  }

  getAllAppPromotion(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<AppPromotionMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<AppPromotionMaster[]>(this.url + "web/appPromotion/get", JSON.stringify(data), this.options);
  }

  createAppPromotion(appPromotion: AppPromotionMaster): Observable<number> {

    appPromotion.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/appPromotion/create/", JSON.stringify(appPromotion), this.options);
  }

  updateAppPromotion(appPromotion: AppPromotionMaster): Observable<number> {

    appPromotion.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/appPromotion/update/", JSON.stringify(appPromotion), this.options);
  }

  getAllAboutShikuyaa(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<AboutShikuyaa[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<AboutShikuyaa[]>(this.url + "web/aboutShikuyaa/get", JSON.stringify(data), this.options);
  }

  createAboutShikuyaa(aboutShikuyaa: AboutShikuyaa): Observable<number> {

    aboutShikuyaa.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/aboutShikuyaa/create/", JSON.stringify(aboutShikuyaa), this.options);
  }

  updateAboutShikuyaa(aboutShikuyaa: AboutShikuyaa): Observable<number> {

    aboutShikuyaa.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/aboutShikuyaa/update/", JSON.stringify(aboutShikuyaa), this.options);
  }

  getAllBanner(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Banner[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Banner[]>(this.url + "web/banner/get", JSON.stringify(data), this.options);
  }

  createBanner(banner: Banner): Observable<number> {

    banner.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/banner/create", JSON.stringify(banner), this.options);
  }

  updateBanner(banner: Banner): Observable<number> {

    banner.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/banner/update", JSON.stringify(banner), this.options);
  }

  getAllFeature(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Feature[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Feature[]>(this.url + "web/feature/get", JSON.stringify(data), this.options);
  }
  createFeature(feature: Feature): Observable<number> {

    feature.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/feature/create", JSON.stringify(feature), this.options);
  }

  updateFeature(feature: Feature): Observable<number> {

    feature.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/feature/update", JSON.stringify(feature), this.options);
  }
  getAllStudents(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "student/get", JSON.stringify(data), this.options);
  }
  getAllTypes(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "web/type/get", JSON.stringify(data), this.options);
  }
  createType(type: Type): Observable<number> {
                                                                                                                                                                                                                                                                                                                                                                                                                            
    type.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "web/type/create", JSON.stringify(type), this.options);
  }

  updateType(type: Type): Observable<number> {

    type.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "web/type/update", JSON.stringify(type), this.options);
  }

  getWidgetDetails(typeId: number) {
    var data = {
      TYPE_ID: typeId
    };
    return this.httpClient.post<string[]>(this.url + "web/typeWeidgetMapping/getData", JSON.stringify(data), this.options);
  }

  addWidgetDetails(typeId: number, data1: string[]): Observable<number> {
    var data = {
      TYPE_ID: typeId,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "web/typeWeidgetMapping/addbulk", data, this.options);
  }
  getAllTypeWidgetMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<string[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<string[]>(this.url + "web/typeWeidgetMapping/get", JSON.stringify(data), this.options);
  }
  getAllPageForm(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<PageForm[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<PageForm[]>(this.url + "web/pageForm/get", JSON.stringify(data), this.options);
  }

  createPageForm(pageForm: PageForm): Observable<number> {
 
    pageForm.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/pageForm/create/", JSON.stringify(pageForm), this.options);
  }

  updatePageForm(pageForm: PageForm): Observable<number> {
    
    pageForm.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/pageForm/update/", JSON.stringify(pageForm), this.options);
  }

  getAllCompany(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<PageForm[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      BLOCK_ID:16
    };
    return this.httpClient.post<PageForm[]>(this.url + "web/company/get", JSON.stringify(data), this.options);
  }
  createCompany(company: Company): Observable<number> {
 
    company.CLIENT_ID=this.clientId;
    company.BLOCK_ID=16
    return this.httpClient.post<number>(this.url + "web/company/create", JSON.stringify(company), this.options);
  }

  updateCompany(company: Company): Observable<number> {
    
    company.CLIENT_ID=this.clientId;
    company.BLOCK_ID=16
    return this.httpClient.put<number>(this.url + "web/company/update", JSON.stringify(company), this.options);
  }

  getAllSiteMap(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<SiteMap[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
  
    return this.httpClient.post<SiteMap[]>(this.url + "web/siteMap/get", JSON.stringify(data), this.options);
  }

  createSiteMap(siteMap: SiteMap): Observable<number> {
 
    siteMap.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/siteMap/create", JSON.stringify(siteMap), this.options);
  }

  updateSiteMap(siteMap: SiteMap): Observable<number> {
    
    siteMap.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/siteMap/update", JSON.stringify(siteMap), this.options);
  }
  

  getAllSitemapheading(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Sitemapheading[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
  
    return this.httpClient.post<Sitemapheading[]>(this.url + "web/sitemapheading/get", JSON.stringify(data), this.options);
  }

  createSitemapheading(sitemapheading: Sitemapheading): Observable<number> {
 
    sitemapheading.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/sitemapheading/create", JSON.stringify(sitemapheading), this.options);
  }

  updateSitemapheading(sitemapheading: Sitemapheading): Observable<number> {
    
    sitemapheading.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/sitemapheading/update", JSON.stringify(sitemapheading), this.options);
  }
   getAllCareerPage(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CareerPage[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
   
    return this.httpClient.post<CareerPage[]>(this.url + "web/careerPage/get", JSON.stringify(data), this.options);
  }

  createCareerPage(careerPage: CareerPage): Observable<number> {
 
    careerPage.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/careerPage/create", JSON.stringify(careerPage), this.options);
  }

  updateCareerPage(careerPage: CareerPage): Observable<number> {
    
    careerPage.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/careerPage/update", JSON.stringify(careerPage), this.options);
  }
  



  getAllContactPage(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ContactPage[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
   
    
    return this.httpClient.post<ContactPage[]>(this.url + "web/contactPage/get", JSON.stringify(data), this.options);
  }

  createContactPage(contactPage: ContactPage): Observable<number> {
 
    contactPage.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/contactPage/create", JSON.stringify(contactPage), this.options);
  }

  updateContactPage(contactPage: ContactPage): Observable<number> {
    
    contactPage.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/contactPage/update", JSON.stringify(contactPage), this.options);
  }
  


  getAllPolicyDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<PolicyDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    
    
    return this.httpClient.post<PolicyDetails[]>(this.url + "web/policyDetails/get", JSON.stringify(data), this.options);
  }

  createPolicyDetails(policyDetails: PolicyDetails): Observable<number> {
 
    policyDetails.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/policyDetails/create", JSON.stringify(policyDetails), this.options);
  }

  updatePolicyDetails(policyDetails: PolicyDetails): Observable<number> {
    
    policyDetails.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/policyDetails/update", JSON.stringify(policyDetails), this.options);
  }
  


  getAllTrainers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Trainer1[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    
    return this.httpClient.post<Trainer1[]>(this.url + "web/trainer/get", JSON.stringify(data), this.options);
  }

  createTrainer(trainer: Trainer1): Observable<number> {
 
    trainer.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/trainer/create", JSON.stringify(trainer), this.options);
  }

  updateTrainer(trainer: Trainer1): Observable<number> {
    
    trainer.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/trainer/update", JSON.stringify(trainer), this.options);
  }
  

  getAllAboutPage(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<AboutPage[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    
    return this.httpClient.post<AboutPage[]>(this.url + "web/aboutPage/get", JSON.stringify(data), this.options);
  }

  createAboutPage(aboutPage: AboutPage): Observable<number> {
 
    aboutPage.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/aboutPage/create", JSON.stringify(aboutPage), this.options);
  }

  updateAboutPage(aboutPage: AboutPage): Observable<number> {
    
    aboutPage.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/aboutPage/update ", JSON.stringify(aboutPage), this.options);
  }
  getAllSocialLink(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<SocialLink[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
  
    
    return this.httpClient.post<SocialLink[]>(this.url + "web/socialLink/get", JSON.stringify(data), this.options);
  }

  createSocialLink(socialLink: SocialLink): Observable<number> {
 
    socialLink.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/socialLink/create/", JSON.stringify(socialLink), this.options);
  }

  updateSocialLink(socialLink: SocialLink): Observable<number> {
    
    socialLink.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/socialLink/update/", JSON.stringify(socialLink), this.options);
  }
  getAllFooter(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Footer[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Footer[]>(this.url + "web/footercontent/get", JSON.stringify(data), this.options);
  }
  createFooter(footer: Footer): Observable<number> {
 
    footer.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/footercontent/create/", JSON.stringify(footer), this.options);
  }

  updateFooter(footer: Footer): Observable<number> {
    
    footer.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/footercontent/update/", JSON.stringify(footer), this.options);
  }
  getAllFooterPageLink(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<FooterPageLink[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<FooterPageLink[]>(this.url + "web/footerPageLink/get", JSON.stringify(data), this.options);
  }

  createFooterPageLink(footerPageLink: FooterPageLink): Observable<number> {
 
    footerPageLink.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/footerPageLink/create/", JSON.stringify(footerPageLink), this.options);
  }

  updateFooterPageLink(footerPageLink: FooterPageLink): Observable<number> {
    
    footerPageLink.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/footerPageLink/update/", JSON.stringify(footerPageLink), this.options);
  }

  getAllNavBarContent(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<NavBarContent[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<NavBarContent[]>(this.url + "web/navBarContent/get", JSON.stringify(data), this.options);
  }

  createNavBarContent(navBarContent: NavBarContent): Observable<number> {
 
    navBarContent.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/navBarContent/create", JSON.stringify(navBarContent), this.options);
  }

  updateNavBarContent(navBarContent: NavBarContent): Observable<number> {
    
    navBarContent.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/navBarContent/update", JSON.stringify(navBarContent), this.options);
  }

  getAllCourseUserReview(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CourseUserReview[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    
    return this.httpClient.post<CourseUserReview[]>(this.url + "web/courseUserReview/get", JSON.stringify(data), this.options);
  }

  createCourseUserReview(courseUserReview: CourseUserReview): Observable<number> {
 
    courseUserReview.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/courseUserReview/create/", JSON.stringify(courseUserReview), this.options);
  }

  updateCourseUserReview(courseUserReview: CourseUserReview): Observable<number> {
    
    courseUserReview.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/courseUserReview/update/", JSON.stringify(courseUserReview), this.options);
  }
  
  getAllInstructor(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Instructor[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
     
    return this.httpClient.post<Instructor[]>(this.url + "web/instructor/get", JSON.stringify(data), this.options);
  }

  createInstructor(instructor: Instructor): Observable<number> {
 
    instructor.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/instructor/create", JSON.stringify(instructor), this.options);
  }

  updateInstructor(instructor: Instructor): Observable<number> {
    
    instructor.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/instructor/update", JSON.stringify(instructor), this.options);
  }
  

  getAllInstructorLinks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<InstructorLinks[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    
    return this.httpClient.post<InstructorLinks[]>(this.url + "web/instructorLinks/get", JSON.stringify(data), this.options);
  }

  createInstructorLinks(instructorLinks: InstructorLinks): Observable<number> {
 
    instructorLinks.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/instructorLinks/create/", JSON.stringify(instructorLinks), this.options);
  }

  updateInstructorLinks(instructorLinks: InstructorLinks): Observable<number> {
    
    instructorLinks.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/instructorLinks/update/", JSON.stringify(instructorLinks), this.options);
  }

  getAllButtonLink(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ButtonLink[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
   
    return this.httpClient.post<ButtonLink[]>(this.url + "web/buttonLink/get", JSON.stringify(data), this.options);
  }

  createButtonLink(buttonLink: ButtonLink): Observable<number> {
 
    buttonLink.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/buttonLink/create/", JSON.stringify(buttonLink), this.options);
  }

  updateButtonLink(buttonLink: ButtonLink): Observable<number> {
    
    buttonLink.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/buttonLink/update/", JSON.stringify(buttonLink), this.options);
  }
  getAllWebinar(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Webinar[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<Webinar[]>(this.url + "web/webinar/get", JSON.stringify(data), this.options);
  }

  createWebinar(webinar: Webinar): Observable<number> {
 
    webinar.CLIENT_ID=this.clientId;
    webinar.STATUS='U'
    return this.httpClient.post<number>(this.url + "web/webinar/create/", JSON.stringify(webinar), this.options);
  }

  updateWebinar(webinar: Webinar): Observable<number> {
    
    webinar.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/webinar/update/", JSON.stringify(webinar), this.options);
  }
  getInstrutorDetails(courseId: number) {
    var data = {
      COURSE_ID: courseId
    };
    
    return this.httpClient.post<Roledetails[]>(this.url + "web/courseInstructorMapping/getData", JSON.stringify(data), this.options);
  }

  //assign all method forms - ROLE_DETAILS
  addInstructorData(courtseTd: number, data1: string[]): Observable<number> {
    var data = {
      COURSE_ID: courtseTd,
      data: data1,
    };
    return this.httpClient.post<number>(this.url + "web/courseInstructorMapping/addBulk", data, this.options);
  }

  getAllBlogWriter(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<BlogWriter[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
   
    return this.httpClient.post<BlogWriter[]>(this.url + "web/blogWriter/get", JSON.stringify(data), this.options);
  }

  createBlogWriter(blogWriter: BlogWriter): Observable<number> {
    blogWriter.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "web/blogWriter/create/", JSON.stringify(blogWriter), this.options);
  }

  updateBlogWriter(blogWriter: BlogWriter): Observable<number> {
    
    blogWriter.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "web/blogWriter/update/", JSON.stringify(blogWriter), this.options);
  }

  getAllCourseItemMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CourseItemMap[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<CourseItemMap[]>(this.url + "courseItemMapping/get", JSON.stringify(data), this.options);
  }

  createCourseItemMapping(courseItemMapping: CourseItemMap): Observable<number> {
 
    courseItemMapping.CLIENT_ID=this.clientId;
    return this.httpClient.post<number>(this.url + "courseItemMapping/create/", JSON.stringify(courseItemMapping), this.options);
  }

  updateCourseItemMapping(courseItemMapping: CourseItemMap): Observable<number> {
    
    courseItemMapping.CLIENT_ID=this.clientId;
    return this.httpClient.put<number>(this.url + "courseItemMapping/update/", JSON.stringify(courseItemMapping), this.options);
  }
  // Gamified Assesment Registration Service
getAllgamifiedregisteredreport (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    filter: filter
  };
  this.httpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      });
      this.options = {
    headers: this.httpHeaders
  };
  return this.httpClient.post<any>(this.url + "studentgamemapping/get", JSON.stringify(data), this.options);
}

// Gamified Assesment Detailed Service
getAllgamifieddetailedreport (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, likestring:string, fromdate:any, todate:any): Observable<any> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    // filter: filter,
    LIKE_STRING:likestring,
    FROM_DATE:fromdate,
    TO_DATE:todate
  };
  this.httpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      });
      this.options = {
    headers: this.httpHeaders
  };
  return this.httpClient.post<any>(this.url + "studentgameprogress/getDetailedReport", JSON.stringify(data), this.options);
}
// Coupon Summary Report Service
getAllcouponsummaryreport (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, likestring:string, fromdate:any, todate:any): Observable<any> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    // filter: filter,
    LIKE_STRING:likestring,
    FROM_DATE:fromdate,
    TO_DATE:todate
  };
  this.httpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      });
      this.options = {
    headers: this.httpHeaders
  };
  return this.httpClient.post<any>(this.url + "reports/getCouponStudentUsedCount", JSON.stringify(data), this.options);
}


// Coupon Detailed Report Service
getAllcouponuseddetailedreport (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, likestring:string, fromdate:any, todate:any, couponid:any): Observable<any> {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    // filter: filter,
    LIKE_STRING:likestring,
    FROM_DATE:fromdate,
    TO_DATE:todate,
    COUPON_ID:couponid
  };
  this.httpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      });
      this.options = {
    headers: this.httpHeaders
  };
  return this.httpClient.post<any>(this.url + "reports/getStudentCouponUsedDetails", JSON.stringify(data), this.options);
}

//remove user
removeUser (pageIndex): Observable<any> {
  var data = {
    MOBILE_NO: pageIndex,
 
  };
  this.httpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'apikey': '0urhdGdDdVOlZ95Vgmrc3374G1IowzfR',
      'applicationkey': 'pd1zAtB3Bg9pRJp6',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
      });
      this.options = {
    headers: this.httpHeaders
  };
  console.log('mobile',data)
  return this.httpClient.post<any>(this.url + "student/delete", JSON.stringify(data), this.options);
}

getAssessmentBranch (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter:string, ): Observable<any> {
// getAssessmentBranch(collegeId: number, ) {
  var data = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    sortKey: sortKey,
    sortValue: sortValue,
    // filter: filter,
    filter:filter,  };
  return this.httpClient.post<Collegebranchmapping[]>(this.url + "shikuyaaCollegeBranchMapping/get", JSON.stringify(data), this.options);
}
}

