import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { District } from 'src/app/Models/CollegeOnboarding/district';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { RegionComponent } from '../../Regions/region/region.component';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.css']
})
export class DistrictComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: District;
  @Input() dataList;

  isSpinning = false
  logtext: string = "";
  userId = sessionStorage.getItem('userId');
  regionsNodes=[]

  @ViewChild(RegionComponent,{static:false} ) regionCompoent: RegionComponent ;

  constructor(public api: ApiService, public api2: ShikuyaaapiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.loadAllRegions()
  }

  loadAllRegions()
  {
    this.api2.getCombinedRegions().subscribe(localName => {
      console.log(localName)
      this.regionsNodes = localName['data'];
    }, err => {
      console.log(err);
    });

  }


  select()
  {
    console.log("value")
  }




  // close(): void {
  //   this.drawerClose();
  //   this.logtext = 'CLOSED - District form';
  //   this.api2.addLog('A', this.logtext, this.api2.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }

  ////

  resetDrawer(districtMasterPage: NgForm) {
    this.data=new District();
    // districtMasterPage.form.reset();

    districtMasterPage.form.markAsPristine();
    districtMasterPage.form.markAsUntouched();
  }

  close(districtMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(districtMasterPage);
    this.api2.addLog('A', this.logtext, this.api2.emailId)
    .subscribe(successCode => {
      if (successCode['code'] == "200") {
        console.log(successCode);
      }
      else {
        console.log(successCode);
      }
    });
  }
  //save
  save(addNew: boolean,districtMasterPage: NgForm): void {
if(this.data.NAME!=undefined && this.data.REGION_ID!=undefined && this.data.NAME!="")
{

  if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
      {

  if (this.data.ID) {

    var filterData= this.dataList.filter(object => {
      return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
    });

    if(filterData.length>0)
    {
    this.message.error("Already Record Present in Database", "");
    }
    else
    {
    this.isSpinning = true;

      this.api2.updateDistrict(this.data)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("District information updated Successfully...", "");

          this.logtext = 'Update & Close - District form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - District ]";
          this.api2.addLog('A', this.logtext, this.api2.emailId)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                console.log(successCode);
              }
              else {
                console.log(successCode);
              }
            });

          if (!addNew)
            this.drawerClose();
          this.isSpinning = false;
        }
        else {

          this.logtext = 'Update & Close - District form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - District ]";
          this.api2.addLog('A', this.logtext, this.api2.emailId)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                console.log(successCode);
              }
              else {
                console.log(successCode);
              }
            });

          this.message.error("Failed to update district information...", "");
          this.isSpinning = false;
        }
      });
    }

     
    }
    else {
      
  

      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
      });

     if(filterData.length>0)
     {
      this.message.error("Already Record Present in Database", "");
     }
     else
     {
      this.isSpinning = true;
      this.api2.createDistrict(this.data)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("District information added successfully...", "");

          if (!addNew) {
            this.drawerClose();

            this.logtext = 'Save & Close - District form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - District ]";
            this.api2.addLog('A', this.logtext, this.api2.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
          else {
            this.data = new District();
            this.resetDrawer(districtMasterPage);
            this.data.NAME=""
            this.api2.getAllDistricts(0, 0, 'ID', 'desc', '').subscribe(data => {
              this.dataList = data['data'];
            }, err => {
              console.log(err);
            });
            this.setValues()
            this.logtext = 'Save & New - District form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - District ]";
            this.api2.addLog('A', this.logtext, this.api2.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

          }
          this.isSpinning = false;
        }
        else {
          this.message.error("Failed to add district information...", "");
          this.isSpinning = false;
          this.logtext = 'Save & Close - District form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - District ]";
          this.api2.addLog('A', this.logtext, this.api2.emailId)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                console.log(successCode);
              }
              else {
                console.log(successCode);
              }
            });
        }
      });
     }


     
    }
  }
  else
{
  this.message.error("Please Check Name", "");

}

  }
  else
  {
    this.message.error("Please Fill All Required Fields", "");
  }
  }

  setValues()
  {
    this.data.IS_ACTIVE=true
  }
}
