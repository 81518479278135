import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Shikuyaachapter } from 'src/app/Models/ShikuyaaModels/shikuyaachapter';
import { ApiService } from 'src/app/Service/api.service';
import { ShikuyaachapterComponent } from '../shikuyaachapter/shikuyaachapter.component';

@Component({
  selector: 'app-shikuyaachapters',
  templateUrl: './shikuyaachapters.component.html',
  styleUrls: ['./shikuyaachapters.component.css']
})
export class ShikuyaachaptersComponent implements OnInit {
courseCategory=1
  formTitle = "Manage Chapters";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext:string = "";
  filterValue=0
  filterClass: string = "filter-invisible";
  columns: string[][] =[ ["COURSE_NAME","Course Name"],["CHAPTER_PARENT_NAME","Parent Name"],["NAME","Name"],["DEPENDED_CHAPTER_NAME","Dependent Chapter Name"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Shikuyaachapter = new Shikuyaachapter();
  @ViewChild(ShikuyaachapterComponent,{static:false} ) shikuyaachapterVar: ShikuyaachapterComponent ;
  imageurl:any
  coursesNodes=[]
  isloadSpinning=false
  dataList1=[]
  pageSize2=10
  constructor(public api: ApiService,private message: NzNotificationService) { }
 category
 courseSubCategory=''
 subcategory
  loadCategories()
  {
    this.api.getAllCourseCategory(0, 0, '', '', 'AND PARENT_ID=0').subscribe(localName => {
      console.log(localName)
      this.category = localName['data'];
      this.courseCategory=localName['data'][0]['ID']
      this.loadcourseSubCategoryNg()
    
      // this.courseCategory=this.courseCategory
      // this.loadingCourseType = false;
    }, err => {
      console.log(err);
      // this.loadingCourseType = false;
    });
  
  }
  loadcourseSubCategoryNg()
  {
    this.api.getAllCourseCategory(0, 0, '', '', 'AND PARENT_ID='+this.courseCategory).subscribe(localName => {
      console.log(localName)
      this.subcategory = localName['data'];
      this.courseSubCategory=this.subcategory[0]['ID']
      this.loadAllCoursesNg()
      // this.loadingCourseType = false;
    }, err => {
      console.log(err);
      // this.loadingCourseType = false;
    });
   
  }
  loadAllCoursesNg() {
    this.api.getAllCourses(0,0,'','','AND COURSE_TYPE_ID=2'+' '+' AND COURSE_SUB_CATEGORY_ID='+this.courseSubCategory).subscribe(localName => {
      this.coursesNodes = localName['data'];
      this.filterValue=this.coursesNodes[0]['ID']
      this.applyFilter()
      // console.log("course multi slect")
      // console.log(this.coursesNodes)
    }, err => {
      console.log(err);
    });
   
  }
  loadcourseSubCategory(event:any)
  {
    this.api.getAllCourseCategory(0, 0, '', '', 'AND PARENT_ID='+event).subscribe(localName => {
      console.log(localName)
      this.subcategory = localName['data'];
      // this.courseSubCategory=this.subcategory[0]['ID']
      // this.loadingCourseType = false;
    }, err => {
      console.log(err);
      // this.loadingCourseType = false;
    });
  
  }
  ngOnInit() {
    // this.search1(true);
    // this.loadAllCourses();
this.loadCategories()


	this.logtext = 'OPENED - Chapters form KEYWORD[O - Chapters] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
  }

  loadAllCourses(event:any) {
    this.api.getAllCourses(0,0,'','','AND COURSE_TYPE_ID=2'+' '+' AND COURSE_SUB_CATEGORY_ID='+event).subscribe(localName => {
      this.coursesNodes = localName['data'];
      // this.filterValue=this.coursesNodes[0]['ID']
      // console.log("course multi slect")
      // console.log(this.coursesNodes)
    }, err => {
      console.log(err);
    });
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search1();
  }
  
  onKeyDownEvent(event)
  {
    if(event.key=='Enter')
    {
      event.preventDefault()
    }
this.search1(true)
  }
 
  search1(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
	
	this.logtext = 'Filter Applied - Chapters form"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

	this.logtext = 'Filter Applied - Chapters form "+ likeQuery +" KEYWORD [F - Chapters] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });
      // this.applyFilter()
      var filter = ""
      if (likeQuery)
        filter = this.filterQuery + likeQuery
      else
        filter = this.filterQuery
        console.log(filter)
        this.isloadSpinning=true
    this.api.getAllShikuyaaChapters(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
      this.isloadSpinning=false
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      if(this.totalRecords!=0)
      {
        this.dataList1 = data['data'];
      }
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
    
     this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.checks=''
    this.checks1=''
    this.drawerTitle = "Create New Chapter";
    this.drawerData = new  Shikuyaachapter();
    this.api.getAllShikuyaaChapters(0, 0, '', '', '').subscribe(data => {
      this.isloadSpinning=false
      this.loadingRecords = false;
      this.totalRecords = data['count'];
     this.dataList=data['data']
    }, err => {
      console.log(err);
    });
    if( this.totalRecords>0)
    {
      this.drawerData.SEQUENCE_NO = this.dataList[0]['SEQUENCE_NO']
      this.drawerData.SEQUENCE_NO = Number(this.dataList[0]['SEQUENCE_NO'])+1
    }
    else
    {
      this.drawerData.SEQUENCE_NO = 1
    }

    this.drawerVisible = true;
    this.shikuyaachapterVar.reset()
	this.logtext = 'ADD - Chapter form KEYWORD [A - Chapter] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
      });

  }
  courseName
  catName
  checks
  checks1
  edit(data:  Shikuyaachapter): void {
    console.log(data)
   
    if (data.THUMBNAIL_URL != "")
    data.THUMBNAIL_URL=this.api.retriveimgUrl + "CChapterThumbnail" + "/" + data.THUMBNAIL_URL;
    
    this.shikuyaachapterVar.reset()
    this.shikuyaachapterVar.loadChapterKnowledgeBase(data)
    this.shikuyaachapterVar.loadParentchapters(" and COURSE_ID=" + data.COURSE_ID + " AND IS_LEAF=0 AND ID <> "+data.ID)
    this.shikuyaachapterVar.loadDependentchapters(" and COURSE_ID=" + data.COURSE_ID + " AND ID <> "+ data.ID  )
    // if(data.THUMBNAIL_URL!="" && data.THUMBNAIL_URL!=" ")
    // {
    //   this.shikuyaachapterVar.isSpinning=true
    //   let TYPED_ARRAY
    //   this.api.getFile(data.THUMBNAIL_URL).subscribe(data => {
    //     console.log(data)
    //   TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
    //   const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
    //     return data + String.fromCharCode(byte);
    //     }, '');
    //     let base64String = btoa(STRING_CHAR);
    //     this.imageurl = 'data:image/jpg;base64,' + base64String;
    //     this.drawerData.THUMBNAIL_URL_CONVERTED=this.imageurl
    //   this.shikuyaachapterVar.isSpinning=false
        
    //     }, err => {
    //     console.log(err);
    //   });
    // }
    this.drawerTitle = "Update Chapter";
   
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.catName=data.COURSE_SUB_CATEGORY_NAME
    this.courseName=data.COURSE_NAME
    this.checks='edit'
    this.checks1='edit'
	this.logtext = 'EDIT - Chapter form KEYWORD [E - Chapter] ';
	this.api.addLog('A',this.logtext,this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
             console.log(successCode);
            }
            else {
		console.log(successCode);
            }
          });
  }

  drawerClose(): void {
    this.search1(true);
    this.drawerVisible = false;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }


  applyFilter() {
    this.filterQuery = ""
  if(this.filterValue==0)
  this.filterQuery ="AND COURSE_SUB_CATEGORY_ID="+this.courseSubCategory;
  else
        this.filterQuery ="AND COURSE_ID="+this.filterValue;
      console.log(this.filterQuery);
   
    this.api.getAllShikuyaaChapters(this.pageIndex, this.pageSize, this.sortKey, '', this.filterQuery).subscribe(data => {
      this.isloadSpinning=false
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      if(this.totalRecords!=0)
      {
        this.dataList1 = data['data'];
      }
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
  }


  clearFilter() {
    this.filterValue=0
    this.filterQuery=''
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.courseCategory=1
    this.courseSubCategory=''
    this.api.getAllShikuyaaChapters(this.pageIndex, this.pageSize, this.sortKey, '', this.filterQuery).subscribe(data => {
      this.isloadSpinning=false
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      if(this.totalRecords!=0)
      {
        this.dataList1 = data['data'];
      }
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }



}