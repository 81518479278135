import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AssessmentChapterMaster } from 'src/app/Models/AssessmeentModels/chapter';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-chapterlist',
  templateUrl: './chapterlist.component.html',
  styleUrls: ['./chapterlist.component.css']
})
export class ChapterlistComponent implements OnInit {

  drawerVisible!: boolean;
  drawerVisible2!: boolean;
  drawerVisible3!: boolean;
  drawerVisible4!: boolean;
  drawerTitle!: string;
  drawerData: AssessmentChapterMaster = new AssessmentChapterMaster();
  formTitle = " Chapter List ";
  dataList :any []= [];
  listdata2 = []
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc"; 
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  parentChapters: any;
  parentID:any;
  filterClass: string = "filter-invisible";
  
  columns: string[][] = [["NAME", " Chapter Name "],["PARENT_NAME", " Parent Name "],["DEPENDED_CHAPTER_NAME", " Dependent Chapter Name "]]

  constructor( public api: ApiService, private message: NzNotificationService ) { }

  ngOnInit(): void {
  this.dependChapter();

  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  dependChapter() {
    this.api.getAllChapterHierarchys(1).subscribe(localName => {
      // alert()
      this.parentChapters = localName['data']; 
    }, err => {
      console.log(err);
    });

  }
  keyup(event:any) {
    this.search();
  }  
  
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND(";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+")"
      console.log("likeQuery" + likeQuery);
    }
    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery
    this.api.getAllAssessmentChapters(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      // if(this.totalRecords==0){
      //   data.SEQUENCE_NO=1;
      // }else{
      //   data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
      // }
    }, err => {
      console.log(err);
    });

   
      this.api.getAllChapterHierarchys(1).subscribe(localName => {
        // var parentChapters
        // // alert()
        // parentChapters = localName['data'];
      }, err => {
        console.log(err);
      });
  
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }  
  applyFilter() {
    console.log('Value:',this.parentID)
    // this.isSpinning=true
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    if (
       this.parentID != undefined  
    ) {
      this.filterQuery =
      " AND PARENT_ID = "+ this.parentID 
      
        var likeQuery = "";

        if (this.searchText != "") {
          likeQuery = " AND";
          this.columns.forEach(column => {
            likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
          });
          likeQuery = likeQuery.substring(0, likeQuery.length - 2)
        }
        this.api
        .getAllAssessmentChapters(
          0,0,
          this.sortKey,
          sort,
          this.filterQuery
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.isFilterApplied = 'primary';
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            // this.isSpinning = false;
            this.filterClass = 'filter-invisible';
            // this.search();
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.message.error(" Please Select Chapter Name", "");
    }
   
    // this.dataList=[];
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.parentID = undefined;
    this.filterQuery = ""
    this.search()
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this); 
  }
  add(): void {
    this.drawerTitle = " Add New Chapter ";
    this.drawerData = new AssessmentChapterMaster();
    this.drawerData.PARENT_ID = 0;
    this.drawerData.NAME =''
    this.drawerData.DESCRIPTION =''
    this.api.getAllAssessmentChapters(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
      if (data['count']==0){
        this.drawerData.SEQUENCE_NO=1;
      }else
      {
        this.drawerData.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
      }
    },err=>{
      console.log(err);
    })
    this.api.getAllChapterHierarchys(1).subscribe(localName => {
      // alert()
      this.parentChapters = localName['data']; 
    }, err => {
      console.log(err);
    });
    // this.drawerData.STATUS=true;
    this.drawerVisible = true;
  }
  edit(data: AssessmentChapterMaster): void {
    this.drawerTitle = " Update Chapter";
    this.drawerData = Object.assign({}, data);
    this.api.getAllAssessmentChapters(0, 0, 'ID', 'desc', " AND ID !=" + data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log(this.listdata2)
      }
    }, err => {
      console.log(err);
    });
    this.drawerVisible = true;
  }


  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
    this.api.getAllChapterHierarchys(1).subscribe(localName => {
      // alert()
      // this.parentChapters = localName['data']; 
    }, err => {
      console.log(err);
    });
  }
sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

 
}

