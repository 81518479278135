import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ContactdetailsComponent } from '../contactdetails/contactdetails/contactdetails.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-college',
  templateUrl: './college.component.html',
  styleUrls: ['./college.component.css'],
  providers: [DatePipe]
})
export class CollegeComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: College;
  isSpinning = false
  logtext: string = "";

  fileData_ICON_URL: File = null
  fkey = "H83IDzNn"
  lkey = ""
  userId = sessionStorage.getItem('userId');

  ID: number
  ABBREVIATION: string
  dataList = []
  collegeData = []
  contactDrawerVisible: boolean
  contactDrawerTitle: string = ""

  districtsNodes = []

  @ViewChild(ContactdetailsComponent, { static: false }) collegedetails: ContactdetailsComponent;
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  isokfile=true
  constructor(public api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
    //this.loadStates()
    this.loadAllDistricts()
    this.loadAllCollegeData()
  }

  loadAllCollegeData() {
    this.api.getAllStudentColleges(0, 0, 'ID', 'asc', '').subscribe(data => {
      this.collegeData = data['data'];
    }, err => {
      console.log(err);
    });
  }

  loadAllDistricts() {
    this.api.getCombinedDistricts().subscribe(localName => {
      this.districtsNodes = localName['data'];
    }, err => {
      console.log(err);
    });

  }


  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.fileData_ICON_URL=null
    this.isokfile=true
  }

  close(): void {
    this.drawerClose();
    this.logtext = 'CLOSED - College form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  isValidPincode(pincode)
  {
      const expression = /^[1-9][0-9]{5}/;
      return expression.test(String(pincode).toLowerCase())
  }


  save(): void {

    if (this.data.DISTRICT_ID != undefined && this.data.NAME != undefined && this.data.NAME != "" && this.data.ABBREVATION != undefined && this.data.ABBREVATION != "" && this.data.ADDRESS != undefined && this.data.ADDRESS != "" && this.data.CITY != undefined && this.data.CITY != "" && this.data.PINCODE != undefined && this.data.PINCODE != "" && this.data.LATITUDE != undefined && this.data.LATITUDE != "" && this.data.LONGITUDE != undefined && this.data.LONGITUDE != "" ) {
      if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true) 
      {
    if(this.isValidPincode(this.data.PINCODE))    {
      if (this.isokfile) {
      if (this.fileData_ICON_URL) {
        var fileExt = this.fileData_ICON_URL.name.split('.').pop();
        var lkey = ""
        console.log(this.fileData_ICON_URL)
        console.log(fileExt)
        console.log(this.fkey)

        this.api.onUploadNewMethod(this.fileData_ICON_URL, fileExt, this.fkey)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == 200) {
              lkey = successCode['data'][0]['L_KEY']
              this.data.LOGO_URL = lkey
              this.updateData()
            }
            else {
              console.log(successCode)
              this.isSpinning = false;
            }
          });
      }
      else {
        if (this.data.ID)
          this.updateData()
        else {
          this.data.LOGO_URL = ""
          this.updateData()
        }
      }
    }
    else {
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    }
  }
  else
  {
    this.message.error("Please Check Pincode", "");
  }
      }
      else
      {
        this.message.error("Please Check Name", "");
      }
    }

    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }

  updateData() {
    // this.data.TRAINING_DEFAULT_VEHICAL_DEPARTURE_TIME=this.datePipe.transform(this.data.TRAINING_DEFAULT_VEHICAL_DEPARTURE_TIME,"HH:mm:ss")
    console.log(this.data.TRAINING_DEFAULT_VEHICAL_DEPARTURE_TIME)

    if (this.data.DISTRICT_ID != undefined && this.data.NAME != undefined && this.data.NAME != "" && this.data.ABBREVATION != undefined && this.data.ABBREVATION != "" && this.data.CITY != undefined && this.data.CITY != "" && this.data.PINCODE != undefined && this.data.PINCODE != "" && this.data.LATITUDE != undefined && this.data.LATITUDE != "" && this.data.LONGITUDE != undefined && this.data.LONGITUDE != "") {
      if (this.data.ID) {

        var filterCollegeData = this.collegeData.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
        });

        var filterCollegeData1 = this.collegeData.filter(object => {
          return object['ABBREVATION'].toLowerCase() == this.data.ABBREVATION.toLowerCase() && object['ID'] != this.data.ID
        });

        if (filterCollegeData.length > 0 || filterCollegeData1.length > 0) {
          if (filterCollegeData.length > 0)
            this.message.error("Name Already Present in database...", "");
          if (filterCollegeData1.length > 0)
            this.message.error("Abbrevation Already Present in database...", "");

        }
        else {

          if(this.data.LOGO_URL=="")
      this.data.LOGO_URL=" "

          this.isSpinning = true;
          this.api.updateCollege(this.data)
            .subscribe(successCode => {
              if (successCode['code'] == 200) {
                this.message.success("College information updated Successfully...", "");
                this.logtext = 'Update & Close - College form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - College ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == 200) {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
                this.drawerClose()
                this.isSpinning = false;
              }
              else {
                this.logtext = 'Update & Close - College form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - College ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
                this.message.error("Failed to update College information...", "");
                this.isSpinning = false;
              }
            });
        }
      }
      else {
        var filterCollegeData = this.collegeData.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase()
        });

        var filterCollegeData1 = this.collegeData.filter(object => {
          return object['ABBREVATION'].toLowerCase() == this.data.ABBREVATION.toLowerCase()
        });

        if (filterCollegeData.length > 0 || filterCollegeData1.length > 0) {
          if (filterCollegeData.length > 0)
            this.message.error("Name Already Present in database...", "");
          if (filterCollegeData1.length > 0)
            this.message.error("Abbrevation Already Present in database...", "");

        }
        else {
          this.isSpinning = true;
          this.api.createCollege(this.data)
            .subscribe(successCode => {
              console.log(successCode)
              if (successCode['code'] == "200") {

                this.message.success("College information added successfully...", "");
                this.loadCollegeData(0, '')
                this.logtext = 'Save & Close - College form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - College ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
                this.isSpinning = false;
              }
              else {
                this.message.error("Please Fill Holiday Calender...", "");
                this.isSpinning = false;
                this.logtext = 'Save & Close - College form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - College ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
            });
        }
      }
    }
    else {
      this.message.error("Please Fill All Required Fields...", "");
    }
  }


  loadCollegeData(collegeId, name) {
    if (collegeId == 0) {
      this.api.getAllStudentColleges(0, 0, '', '', '').subscribe(data => {
        console.log(data['data'])
        this.dataList = data['data'];
        console.log("College Id from Add :" + collegeId)
        this.ID = this.dataList[0]['ID']
        this.contactDrawerTitle = "Manage Contacts for " + this.dataList[0]['ABBREVATION']
        //  this.collegedetails.search(this.dataList[0]['ID'])
        console.log(this.ID)
      }, err => {
        console.log(err);
      });
    }
    else {
      console.log("College Id from Edit :" + collegeId)
      this.ID = collegeId
      this.contactDrawerTitle = "Manage Contacts for " + name
      this.collegedetails.search(this.ID)
    }
    this.contactDrawerVisible = true
    this.ABBREVIATION = name
  }

  contactDrawerClose() {
    this.contactDrawerVisible = false
  }

  get contactCloseCallback() {
    return this.contactDrawerClose.bind(this);
  }


  setValues() {
    this.data.IS_ACTIVE = true;
  }


  onFileSelected_LOGO_URL(event) {

    this.isokfile = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfile = false
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    }

    if (this.isokfile) {
      this.fileData_ICON_URL = <File>event.target.files[0];
    }
    
  }



}
