import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-questionsdata2',
  templateUrl: './questionsdata2.component.html',
  styleUrls: ['./questionsdata2.component.css']
})
export class QuestionsdataComponent2 implements OnInit {
  @Input() assignmentId:any
  @Output() myOutput:EventEmitter<string>= new EventEmitter();  
  outputMessage:string="I am child component."  
  @Input() level:any
  @Input() selects:any
  @Input() drawerClose: Function;
  @Input() dataList
  @Input() node
  @Input() TYPE
  @Input() questionDataLength
  @Output() questionCount = new EventEmitter<string[]>()
  selectedQuestionCount: string
  isSpinning = false
  checkAll = false
  disableQuestion = false
  dataListLevels = []
  selectedQuesCount: number = 0
  maxQuesCount: number = 0
  constructor(public api: ApiService) { 
  
  }

  ngOnInit() {

    //this.loadLevels()
    this.allGetData()
  }

  loadLevels(id, name) {
    let filter = " AND ID=" + id
    this.api.getAllLevels(0, 0, 'ID', 'asc', filter).subscribe(data => {
      this.dataListLevels = data['data'];
      if (this.node[name] == 0)
        this.node[name] = this.dataListLevels[0]['SECONDS']


    }, err => {
      console.log(err);
    });
  }
shows
  select() {
    console.log(this.dataList)
    var data1 = []
    data1 = this.dataList.filter(object => {
      return object['IS_SELECTED'] == 1;
    });
    console.log('count=',data1.length)
    this.shows=data1.length
    localStorage.setItem('count',JSON.stringify(data1.length))
    // if (this.dataList[0]['LEVEL_ID'] == 1)
    // {

    //   this.node['L1_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L1_QUESTION_DATA']=this.dataList
    //   this.loadLevels(1,"L1_TIME")

    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 2)
    // {
    //   this.node['L2_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L2_QUESTION_DATA']=this.dataList
    //   this.loadLevels(2,"L2_TIME")
    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 3)
    // {
    //   this.node['L3_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L3_QUESTION_DATA']=this.dataList
    //   this.loadLevels(3,"L3_TIME")
    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 4)
    // {
    //   this.node['L4_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L4_QUESTION_DATA']=this.dataList
    //   this.loadLevels(4,"L4_TIME")
    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 5)
    // {
    //   this.node['L5_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L5_QUESTION_DATA']=this.dataList
    //   this.loadLevels(5,"L5_TIME")

    // }

    this.drawerClose()




  }
allGetData()
{

    this.api.getMockQuestionDetails(this.assignmentId, this.selects, this.level)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
     this.dataList=successCode['data']
     console.log(this.dataList)
        }
        else {
         
        }
      }, err => {
        console.log(err);
      
      });
      this.dataList.forEach(element => {
        element['IS_SELECTED'] = 1
      });

  
}
  checkAllChanged(value, data) {
    if (value) {
      data.forEach(element => {
        element['IS_SELECTED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_SELECTED'] = 0
      });
    }
    this.getCheckSelectedQuestionCount()
  }

  changeQuestion(value, data) {
    if (value)
      data.IS_SELECTED = 1;
    else
      data.IS_SELECTED = 0

    this.getCheckSelectedQuestionCount()

  }

  getDataList(dataList, node,count) {
    this.dataList = dataList
    this.checkAll = false
    this.node = node
    this.maxQuesCount = count
  }

  getCheckSelectedQuestionCount() {

    var data1filter = []

    data1filter = this.dataList.filter(object => {
      return object['IS_SELECTED'] == 1;
    });
    this.selectedQuesCount = data1filter.length


    if (this.dataList[0]['LEVEL_ID'] == 1) {
      if (data1filter.length <= this.node['L1_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 2) {
      if (data1filter.length <= this.node['L2_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 3) {
      if (data1filter.length <= this.node['L3_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 4) {
      if (data1filter.length <= this.node['L4_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 5) {
      if (data1filter.length <= this.node['L5_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }

    // console.log(this.disableQuestion)

  }



  close() {
    this.myOutput.emit(this.outputMessage);  
    this.drawerClose()

  }
  onKeyDownEvent(event)
  {
this.allGetData()
  }
  searchText
  search(event:any)
  {
    this.api.getMockQuestionDetails1(this.assignmentId, this.selects, this.level,event)
    .subscribe(successCode => {
      console.log(successCode)
      if (successCode['code'] == "200") {
   this.dataList=successCode['data']
   console.log(this.dataList)
      }
      else {
       
      }
    }, err => {
      console.log(err);
    
    });

  }
}
