import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
// import * as moment from 'moment';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-studentregsummary',
  templateUrl: './studentregsummary.component.html',
  styleUrls: ['./studentregsummary.component.css']
})
export class StudentregsummaryComponent implements OnInit {

  isExportloading = false
  formTitle = " Bundle Registration Summary ";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";
  filterValue :any
  filterClass: string = "filter-invisible";
  current = new Date();
  //chapter name,l1 questions,l1 time,l2 question,l2 time,l3 question,l3 time, l4 question,l4 time,l5 question,l5 time, total time,total question
  columns: string[][] = [['STUDENTS', ' Total Registered Bundles'], ['ONLINE_STUDENTS', ' Online Registered Bundles'], 
  ['COLLEGE_MAPPED_STUDENTS', ' College Mapped Bundles'],
  ['COLLEGE_PICKED_STUDENTS', ' College Picked Bundles']]
  
  coursesNodes = []
  isloadSpinning = false
  dataList1 = []
  pageSize2 = 10
  selectedDate: Date[] = []
  value1: string = ""
  value2: string = ""
  dateFormat = 'dd/MM/yyyy';
  datePipe = new DatePipe("en-US");
  loadingcourse = false
  loadingmocks = false
  COLLEGE_ID = ''
  mocks = []
  colleges = [] 
  startValue: any;
  endValue: any;
  endOpen = false;
  startOpen = false;

  constructor(public api: ApiService, private message: NzNotificationService) { }
  ngOnInit() {
    this.selectedDate = [new Date(), new Date()]
    this.changeDate(this.selectedDate)


    this.logtext = 'OPENED - Course Assessment Details KEYWORD[O - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

      // this.api.getAllTPO(0, 0, 'id', 'asc', ' AND IS_ACTIVE = 1').subscribe(data => {
      //   // this.loadingmocks = false
      //   this.colleges = data['data'];
      // }, err => {
      //   console.log(err);
      // });
  }
  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd")
  }

  
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || '';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search(false);
  }

  onKeyDownEvent(event)
  {if(event.key=='Enter')
  {
    event.preventDefault()
  }

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - Course Assessment Details"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
    }

    this.logtext = 'Filter Applied - Course Assessment Details ' + likeQuery + ' KEYWORD [F - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

    // console.log(filter)
    this.isloadSpinning = true
    this.api.getAllStudentRegSummaryReport('','').subscribe(data => {
      this.isloadSpinning = false
      this.loadingRecords = false;
      if (data['code'] == '200') {
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.dataList1 = data['data'];
        console.log('jasdj',this.dataList1);
    // if(data['count']>'0')
    // this.dataList1 = data['data'];
    // console.log('jasdj',this.dataList1);
    
      }
    }, err => {
      console.log(err);
    });
  
  }
  downloadExcel(){    
    // excel
    console.log('startValue',this.startValue);
    console.log('endValue',this.endValue);
    if(this.startValue != undefined && this.endValue != undefined){
    this.isloadSpinning = true
    this.api.getAllStudentRegSummaryReport(this.startValue, this.endValue).subscribe(data => {
      if(data['code']==200){
      this.isloadSpinning = false
     this.dataList1 = data['data'];
     console.log('this.dataList1:',this.dataList1);
     const element = window.document.getElementById('downloadFile');
    //  if (element != null) element.focus();
    if (element != null) element.click();

    } 
   },
   (err) => {
     console.log(err);
     this.isloadSpinning = false
   });
  } else
  if(this.startValue == undefined && this.endValue == undefined){
    this.isloadSpinning = true
    this.api.getAllStudentRegSummaryReport('','').subscribe(data => {
      if(data['code']==200){
      this.isloadSpinning = false
     this.dataList1 = data['data'];
     console.log('this.dataList1:',this.dataList1);
     const element = window.document.getElementById('downloadFile');
    //  if (element != null) element.focus();
    if (element != null) element.click();

    } 
   },
   (err) => {
     console.log(err);
     this.isloadSpinning = false
   });
  } else {}
  }
  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }


  // applyFilter() {
  //   console.log('Value:',this.filterValue)
  //   // this.isSpinning=true
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
  //   } catch (error) {
  //     sort = '';
  //   }
  //   if (
  //     this.filterValue != ''
  //   ) {
  //     this.filterQuery =
  //       " AND TYPE = '" +
  //       this.filterValue +"'"

  //       var likeQuery = "";

  //       if (this.searchText != "") {
  //         likeQuery = " AND";
  //         this.columns.forEach(column => {
  //           likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
  //         });
  //         likeQuery = likeQuery.substring(0, likeQuery.length - 2)
  //       }
  //       this.api
  //       .getAllStudentRegSummaryReport(
  //         0,0,
  //         this.sortKey,
  //         sort,
  //         this.filterQuery
  //       )
  //       .subscribe(
  //         (data) => {
  //           console.log(data);
  //           this.loadingRecords = false;
  //           this.isFilterApplied = 'primary';
  //           this.totalRecords = data['count'];
  //           this.dataList = data['data'];
  //           // this.isSpinning = false;
  //           this.filterClass = 'filter-invisible';
  //           // this.search();
  //         },
  //         (err) => {
  //           console.log(err);
  //         }
  //       );
  //   } else {
  //     this.message.error(" Please Select Type", "");
  //   }
   
  //   // this.dataList=[];
  // }
  applyFilter() {
    console.log('startValue',this.startValue);
    console.log('endValue',this.endValue);
  
    // this.isSpinning=true
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd HH:mm:ss');
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd HH:mm:ss');

    if (
      this.startValue != undefined &&
      this.endValue != undefined 
    ) {
      this.api
    .getAllStudentRegSummaryReport(
     this.startValue,
     this.endValue
    )
    .subscribe(
      (data) => {
        console.log(data);
        this.loadingRecords = false;
        this.isFilterApplied = 'primary';
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        // this.isSpinning = false;
        this.filterClass = 'filter-invisible';
        // this.search();
      },
      (err) => {
        console.log(err);
      }
    );
    } else if(this.startValue != undefined &&
      this.endValue == undefined ){
        this.message.error(" Please Select To Date","")

    } else if(this.startValue == undefined &&
      this.endValue != undefined){
        this.message.error(" Please Select From Date","")

    }else{

    }
   

    // this.dataList=[];
  }


  clearFilter() {
    this.startValue = ''
    this.endValue = ''
    this.filterValue=""
    this.filterQuery = ""
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true)
  }



disabledStartDate = (current: Date): boolean =>
  differenceInCalendarDays(current, this.current) > 0;
/////////
// disabledEndDate = (current: Date): boolean =>
//   differenceInCalendarDays(current, this.startValue) > 0;

// dates: any = [];

// startDateChange() {
//   var startDate = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
//   var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

//   console.log(this.getDaysArray(startDate, endDate));
//   console.log(this.dates);
// }

// getDaysArray(start: any, end: any) {
//   for (
//     var arr = [], dt = new Date(start);
//     dt <= new Date(end);
//     dt.setDate(dt.getDate() + 1)
//   ) {
//     arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
//     this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
//   }
//   return arr;
// }
}
