import { Component, OnInit, Input, ViewChild, EventEmitter,Output } from '@angular/core';
import { Assignment2 } from 'src/app/Models/AssignmentModule/assignment';
import { Assignmentgroup } from '../../../../../Models/AssignmentModule/assignmentgroup';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Branch } from 'src/app/Models/BatchMgmt/branch';
import { Passingyear } from 'src/app/Models/BatchMgmt/passingyear';
import { Division } from 'src/app/Models/BatchMgmt/division';
import { DatePipe } from '@angular/common';
import { Part } from 'src/app/Models/MasterSyllabus/part';
import { Section } from 'src/app/Models/MasterSyllabus/section';
import { Chapter } from 'src/app/Models/MasterSyllabus/chapter';
import { QuestionsdataComponent2 } from '../questionsdata/questionsdata2.component';
import { Assignmentdetails2 } from 'src/app/Models/AssignmentModule/assignmentdetails';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormArrayName } from '@angular/forms';



@Component({
  selector: 'app-assignment2',
  templateUrl: './assignment2.component.html',
  styleUrls: ['./assignment2.component.css'],
  providers: [DatePipe]
})
export class AssignmentComponent2 implements OnInit {
  coursesNodes = []
  @Output() show: EventEmitter<any> = new EventEmitter();
  @Input() drawerClose: Function;
  @Input() data: Assignment2;
  current = 0;
  assignmentGroups: Assignmentgroup[]
  loadingGroups = false
  loadingInstructionsData = false
  instructionsData = []
  logtext: string = "";
  userId = sessionStorage.getItem('userId')
  loadingSaveAssignments = false
  loadingMapInstructions = false
  assignmentId: number
  assignmentDetails = []
  TOTAL_L1_QUESTIONS: number = 0
  TOTAL_L1_TIME: number = 0
  TOTAL_L2_QUESTIONS: number = 0
  TOTAL_L2_TIME: number = 0
  TOTAL_L3_QUESTIONS: number = 0
  TOTAL_L3_TIME: number = 0
  TOTAL_L4_QUESTIONS: number = 0
  TOTAL_L4_TIME: number = 0
  TOTAL_L5_QUESTIONS: number = 0
  TOTAL_L5_TIME: number = 0
  TOTAL_PARTS_SELECTED: number = 0
  TOTAL_SECTION_SELECTED: number = 0
  TOTAL_CHAPTER_SELECTED: number = 0
  TOTAL_SUBCHAPTERS: number = 0
  TOTAL_QUESTIONS: number = 0
  CLASSWORK_QUESTIONS: number = 0
  HOMEWORK_QUESTIONS: number = 0
  ASSIGNMNETS_QUESTIONS: number = 0
  TOTAL_TEMPLATES: number = 0
  TIME: string = "0"
  addTime: number = 0
  PRACTICE_QUESTIONS: number = 0
  AQGS: number = 0
  questionDetailsData = []
  drawerTitle: string = ""
  drawerVisible: boolean = false
  scope = "O"
  ENABLE_TIME
  DISABLE_TIME
  IS_RESULT_SHARING_TIME = true
  TIME_BASED = true
  RESULT_SHARING_TIME
  PASSKEY = ""
  LINK = ""
  mode = 0
  levelId = 0
  filterBranches: string[] = ['AL']
  filterPassingYear: string[] = ['AL']
  filterDivision: string[] = ['AL']
  passingYears: Passingyear[]
  divisions: Division[]
  branches: Branch[]
  batchDetailsData = []
  checkAll = false
  BATCH_DISABLE_TIME: Date
  BATCH_ENABLE_TIME: Date
  BATCH_IS_PUBLISHED = false
  BATCH_PUBLISHED_TIME: Date
  BATCH_IS_RESULT_SHARING_TIME: Date
  loadingSaveClose = false
  L1_TIME_DEFAULT: number
  L2_TIME_DEFAULT: number
  L3_TIME_DEFAULT: number
  L4_TIME_DEFAULT: number
  L5_TIME_DEFAULT: number
  BATCH_RESULT_SHARING_TIME: Date
  nodeObject = []
  isLocadingAssignmentDetails = false
  today = new Date();
  loadingBranches = false
  loadingPassingYears = false
  loadingDivisions = false
  loadingFilter = false
  loadbatchDetailsFilter = true
  loadingMapAssignment = false
  collegeId = sessionStorage.getItem('collegeId')
  modeType: string = ""
  branchfilterQuery = ""
  NAME: string = ""
  totalQuestions: number
  totalTime
  disableButton = false
  loadingParts = false
  parts: Part[]
  PART_ID: number
  loadingSections = false
  sections: Section[]
  SECTION_ID: number
  loadingChapters = false
  chapters: Chapter[]
  CHAPTER_ID: number
  isStatsSpinning = false
  assignmentDetailsCount = []
  visibleData = false
  TYPE: string
  flag: boolean = false
  @ViewChild(QuestionsdataComponent2, { static: false }) questionData: QuestionsdataComponent2;
  questionDataLength: number = 0
  TOTAL_TIME_IN_SECOND: number = 0
  SELECTED_ID = 0
  GROUP_TYPE: string = "M"
  L1_QUESTIONS: number = 0
  L1_TIME: number = 0
  L2_QUESTIONS: number = 0
  L2_TIME: number = 0
  L3_QUESTIONS: number = 0
  L3_TIME: number = 0
  L4_QUESTIONS: number = 0
  L4_TIME: number = 0
  L5_QUESTIONS: number = 0
  L5_TIME: number = 0
  filterValue = []
  subChaptersNodes = []
  ChaptersNodes = []
  groupTypeVisible: boolean = true
  partId = 0
  sectionId = 0
  chapterId = 0
  subchapterId = 0
  TOTAL_QUESTION: number = 0
  TOTAL_TIME: number = 0
  TOTAL_TIME_STRING: string = "0"
  L1_QUESTIONS_Allocated = 0
  L1_QUESTIONS_COUNT = 0
  L2_QUESTIONS_Allocated = 0
  L2_QUESTIONS_COUNT = 0
  L3_QUESTIONS_Allocated = 0
  L3_QUESTIONS_COUNT = 0
  L4_QUESTIONS_Allocated = 0
  L4_QUESTIONS_COUNT = 0
  L5_QUESTIONS_Allocated = 0
  L5_QUESTIONS_COUNT = 0
  LEVEL_IDS = []
  MOCK_DETAILS_ID = 0
  assignmentDetailsData: Assignmentdetails2 = new Assignmentdetails2();
  loadingAddQuestion = false
  courseID
  categoryID
  isOk=true;
  constructor(public api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { 

  }
  levels1
  levels
  loadingLevels = false
  GetChildData(data){  
    console.log(data);  
 }  
  loadCategories()
  {
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log("courses")
      // console.log(this.levels)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
  }
  loadCourses(event:any) {

    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND IS_ACTIVE=1 AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+event).subscribe(localName => {
      this.levels = localName['data'];
      console.log("All course:",this.levels);
      
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  ngOnInit() {
    this.loadAssignmentGroups()
    // this.loadcourseCategory()
    this.loadData()
    // this.loadCategories()


    // this.loadAllStats()
   
  }


  setDedicated(event) {
    this.data.SECTION_SWITCHING = event;

    if (this.data.SECTION_SWITCHING == 'B')
    {
      this.data.IS_DEDICATED = false;
      this.data.TIMER_TYPE = "S"
      this.data.TEST_SUBMISSION= false;
    }
    else if (this.data.TIMER_TYPE != "S")
      this.data.IS_DEDICATED = true;
  }

  setDedicated2(event) {
    this.data.TIMER_TYPE = event;

    if (this.data.TIMER_TYPE == "S")
      this.data.IS_DEDICATED = false;
    else if (this.data.SECTION_SWITCHING != 'B')
    {
      this.data.IS_DEDICATED = true;
      
    }
  }

  loadAssignmentGroups() {
    this.loadingGroups = true;
    this.api.getAllMockGroups(0, 0, '', '', '').subscribe(localName => {
      this.assignmentGroups = localName['data'];
      this.loadingGroups = false;
    }, err => {
      console.log(err);
      this.loadingGroups = false;
    });
  }

  cancel() {
    this.current -= 1;
  }
  dataLists=[]
   loadData() {
    this.api.getAllMocks(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataLists = data['data'];
    }, err => {
      console.log(err);
    });
  }
  similarData() {
    // this.loadingSaveAssignments = true
    var filterData = this.dataLists.filter(object => {
      return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase()
    });

    if (filterData.length > 0) {
      // this.isSpinning = false;
      if (filterData.length > 0)
        this.message.error("Name Already Present in Database", "");
        this.loadingSaveAssignments = false        
      return false
    }
    else {
      return true
    }



  }
  saveAssignments() {

    if (this.data.SECTION_SWITCHING == 'B'){
      this.data.IS_DEDICATED = false;
    }
    else if (this.data.TIMER_TYPE == "S")
      this.data.IS_DEDICATED = false;


    if (this.data.GROUP_ID != undefined && this.data.NAME != undefined) {
      // if (/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true) {
      if (this.data.NAME.trim() != '') {

        console.log(this.data.ID)
        this.loadingSaveAssignments = true
        if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == false && this.data.platforms[2]['checked'] == false)
          this.data.PLATFORM = 'M'
        else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == true && this.data.platforms[2]['checked'] == false)
          this.data.PLATFORM = 'W'
        else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == false && this.data.platforms[2]['checked'] == true)
          this.data.PLATFORM = 'D'
        else if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == true && this.data.platforms[2]['checked'] == true)
          this.data.PLATFORM = 'B'

        if (this.data.DESCRIPTION == "")
          this.data.DESCRIPTION = " "

        //create assignment method
        if (this.data.ID) {
          this.api.updateMock(this.data)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Assesment information updated Successfully...", "");
                this.current += 1;
                this.getAssignmentId()
                this.loadingSaveAssignments = false
                this.logtext = 'Update & Close - Assesment form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Assesment ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.logtext = 'Update & Close - Assesment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - AssesmentGroup ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
                this.message.error("Failed to update information...", "");
                this.loadingSaveAssignments = false;
              }
            }, err => {
              console.log(err);
              this.loadingSaveAssignments = false
            });
        }
        else {
          var returnData = this.similarData()
         if(returnData)
         {
          this.api.createMock(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              this.message.success("Assesment information added successfully...", "");
              this.current += 1;
              this.getAssignmentId()
              this.loadingSaveAssignments = false
              this.logtext = 'Save & Close - Assesment form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Assesment ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
            else {
              this.message.error("Failed to add Assesment information...", "");
              this.loadingSaveAssignments = false;
              this.logtext = 'Save & Close - Assesment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Assesment ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
          }, err => {
            console.log(err);
            this.loadingSaveAssignments = false
          });
         }
         else{
          //  this.message.error('Same Name Found! Try Another Name','')
           this.data.NAME=''
         }
        }
      }
      else {
        this.message.error("Please Enter Name...", "");

      }
    }
    else {
      if(this.data.GROUP_ID == undefined)
      this.message.error("Please Select Group Name", "");
      else if(this.data.NAME == undefined || this.data.NAME == "")
      this.message.error("Please Enter Name", "");
    }
    // this.current += 1;
  }

  changeIntructionSelect(value, data) {
    if (value)
      data.IS_SELECTED = 1;
    else
      data.IS_SELECTED = 0
  }

  getAssignmentId() {
    if (this.data.ID) {
      this.modeType = "U"
      this.assignmentId = this.data.ID
      this.loadInstructionData(this.assignmentId)
    }
    else {
      this.api.getAllMocks(0, 0, "ID", "DESC", "").subscribe(data => {
        console.log("assignment data")
        this.modeType = "C"
        console.log(data['data'][0])
        this.assignmentId = data['data'][0]['ID']
        if (data['data'][0].PLATFORM == 'B')
          data['data'][0].platforms = [{ label: 'Mobile', value: 'M', checked: true },
          { label: 'Web', value: 'W', checked: true }, { label: 'Desktop', value: 'D', checked: true }]
        else if (data['data'][0].PLATFORM == 'M')
          data['data'][0].platforms = [{ label: 'Mobile', value: 'M', checked: true },
          { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
        else if (data['data'][0].PLATFORM == 'W')
          data['data'][0].platforms = [{ label: 'Mobile', value: 'M', checked: true },
          { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: false }]
        else
          data['data'][0].platforms = [{ label: 'Mobile', value: 'M', checked: false },
          { label: 'Web', value: 'W', checked: false }, { label: 'Desktop', value: 'D', checked: true }]

        this.data = data['data'][0]
        this.loadInstructionData(this.assignmentId)
      }, err => {
        console.log(err);
      });
    }

  }


  loadInstructionData(assignmentId) {
    this.loadingInstructionsData = true;
    this.api.getMockInstructions(assignmentId).subscribe(data => {
      console.log(data)
      data['data'] = data['data'].sort(this.sortFunction1)
      const getPlayerMax_on = cod => data['data'].reduce((a, c) => ((+a[cod]) < (+c[cod])) ? c : a)

      const maxvalue = getPlayerMax_on('SEQ_NO')
      console.log(maxvalue)

      console.log(maxvalue['SEQ_NO'])
      let seqNo = maxvalue['SEQ_NO'] + 1
      data['data'].forEach(element => {
        if (element['SEQ_NO'] == 0) {
          element['SEQ_NO'] = seqNo
          seqNo++
        }

      });

      console.log(data['data'])

      this.instructionsData = data['data'].sort(this.sortFunction)
      this.loadingInstructionsData = false;
    }, err => {
      console.log(err);
      this.loadingInstructionsData = false;
    });
  }
  sortFunction1(a, b) {
    var dateA = a.ID
    var dateB = b.ID
    return dateA > dateB ? 1 : -1;
  };

  sortFunction(a, b) {
    var dateA = a.SEQ_NO
    var dateB = b.SEQ_NO
    return dateA > dateB ? 1 : -1;
  };

  saveMapInstructions() {

    console.log(this.instructionsData)

    var data1 = this.instructionsData.filter(object => {
      return object['IS_SELECTED'] == 1;
    });

    console.log(data1.length)

    if (data1.length > 0) {
      this.loadingMapInstructions = true;
      this.api.addMockInstructions(this.assignmentId, this.instructionsData)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Instruction Mapped Successfully ...", "");
            this.loadingMapInstructions = false;
            this.assignmentDetails = []
            this.current += 1;
            this.visibleData = false
            // this.loadAssignmentDeatils()
            this.flag = false
            //this.loadParts()
            // this.loadAllSubchapters()
            this.loadAllStats()
            this.dependChapter()
          }
          else {
            this.message.error("Instruction Mapping Failed...", "");
            this.loadingMapInstructions = false;
          }
        }, err => {
          console.log(err);
          this.loadingMapInstructions = false;
        });
    }
    else {
      this.message.error("Please Select Instructions...", "");
    }
    // this.loadQuestionsData()

    // this.current += 1;
  }

  category
  x
  y
  loadcourseCategory() {
    // this.loadingCourseType = true;
    
    this.api.getAllCourseCategory(0, 0, '', '', 'AND PARENT_ID=0').subscribe(localName => {
      console.log(localName)
      this.category = localName['data'];
      // this.loadingCourseType = false;
    }, err => {
      console.log(err);
      // this.loadingCourseType = false;
    });
  }
  subcategory
  loadingCourseSub:boolean=true
  loadcourseSubCategory(event: any) {
    this.loadingCourseSub = true;
    // if (typeof (this.data.COURSE_SUB_CATEGORY_ID) === 'object') {
    //   for (var i = 0; i < this.data.COURSE_SUB_CATEGORY_ID.length; i++) {
    //     this.data.COURSE_SUB_CATEGORY_ID[i] = Number(this.data.COURSE_SUB_CATEGORY_ID[i]);
    //   }

    // }
    // else
    //   this.data.COURSE_SUB_CATEGORY_ID = this.data.COURSE_SUB_CATEGORY_ID.split(',')
    
    //   console.log(this.data.COURSE_SUB_CATEGORY_ID)

    this.api.getCategoryHierarchys(event).subscribe(localName => {
      console.log(localName)
      this.subcategory = localName['data'];
      if(this.subcategory==null)
    {
      this.message.error('No SubCategories are found ! Please select another category','')
    this.data.COURSE_CATEGORY_ID=undefined
    }
      this.loadingCourseSub = false;
    }, err => {
      console.log(err);
      this.loadingCourseSub = false;
    });
    
  }
  loadAllSubchapters() {
 
    this.api.getParentChapters1(this.courseID).subscribe(localName => {
      this.subChaptersNodes = localName['data'];


    }, err => {
      console.log(err);

    });
  }
  dependChapter() {
    this.api.getAllChapterHierarchys(0).subscribe(localName => {
      // alert()
      this.ChaptersNodes = localName['data'];
    }, err => {
      console.log(err);
    });

  }
  loadAssignmentDetails(mode) {


    this.isStatsSpinning = true;
    this.api.getMockDetails1(this.assignmentId, this.chapterId, mode).subscribe(data => {
      console.log(data)
      this.api.getAllChapterHierarchys('AND ID='+this.chapterId).subscribe(localName => {
        this.partName=localName['data'][0]['PART_NAME']
       }, err => {
         console.log(err);
     
       });
      if (data['code'] == 200) {
        this.L1_QUESTIONS_COUNT = data['data'][0]['L1_QUESTIONS_COUNT']
        this.L2_QUESTIONS_COUNT = data['data'][0]['L2_QUESTIONS_COUNT']
        this.L3_QUESTIONS_COUNT = data['data'][0]['L3_QUESTIONS_COUNT']
        this.L4_QUESTIONS_COUNT = data['data'][0]['L4_QUESTIONS_COUNT']
        this.L5_QUESTIONS_COUNT = data['data'][0]['L5_QUESTIONS_COUNT']
        this.L1_QUESTIONS = data['data'][0]['L1_QUESTIONS']
        this.L2_QUESTIONS = data['data'][0]['L2_QUESTIONS']
        this.L3_QUESTIONS = data['data'][0]['L3_QUESTIONS']
        this.L4_QUESTIONS = data['data'][0]['L4_QUESTIONS']
        this.L5_QUESTIONS = data['data'][0]['L5_QUESTIONS']
        this.L1_TIME = data['data'][0]['L1_TIME']
        this.L2_TIME = data['data'][0]['L2_TIME']
        this.L3_TIME = data['data'][0]['L3_TIME']
        this.L4_TIME = data['data'][0]['L4_TIME']
        this.L5_TIME = data['data'][0]['L5_TIME']
        this.L1_QUESTIONS_Allocated = data['data'][0]['L1_QUESTIONS_SELECTED_COUNT']
        this.L2_QUESTIONS_Allocated = data['data'][0]['L2_QUESTIONS_SELECTED_COUNT']
        this.L3_QUESTIONS_Allocated = data['data'][0]['L3_QUESTIONS_SELECTED_COUNT']
        this.L4_QUESTIONS_Allocated = data['data'][0]['L4_QUESTIONS_SELECTED_COUNT']
        this.L5_QUESTIONS_Allocated = data['data'][0]['L5_QUESTIONS_SELECTED_COUNT']
        this.assignmentDetailsData['L1_QUESTIONS_DATA'] = []
        this.assignmentDetailsData['L2_QUESTIONS_DATA'] = []
        this.assignmentDetailsData['L3_QUESTIONS_DATA'] = []
        this.assignmentDetailsData['L4_QUESTIONS_DATA'] = []
        this.assignmentDetailsData['L5_QUESTIONS_DATA'] = []
        this.TOTAL_QUESTION = this.L1_QUESTIONS + this.L2_QUESTIONS + this.L3_QUESTIONS + this.L4_QUESTIONS + this.L5_QUESTIONS
        this.TOTAL_TIME = (this.L1_QUESTIONS * this.L1_TIME) + (this.L2_QUESTIONS * this.L2_TIME) + (this.L3_QUESTIONS * this.L3_TIME) + (this.L4_QUESTIONS * this.L4_TIME) + (this.L5_QUESTIONS * this.L5_TIME)
        this.TOTAL_TIME_STRING = this.api.getSecondsToHms(this.TOTAL_TIME)
      }
      else if (data['code'] == 300) {
        this.message.error("Cannot add...", "");
      }

      this.isStatsSpinning = false;
    }, err => {
      console.log(err);
      this.isStatsSpinning = false;
    });

  }


  subchapterChanged(id) {
    this.chapterId = id
    this.mode = 0
    this.loadAssignmentDetails(this.mode)
    this.MOCK_DETAILS_ID = 0
  }

  subchapterChanged1(id, MOCK_DETAILS_ID) {
    this.SELECTED_ID = id
    this.chapterId = id
    this.mode = 1
    this.loadAssignmentDetails(this.mode)
    this.MOCK_DETAILS_ID = MOCK_DETAILS_ID

  }
  groupTypeChanged(groupType) {
    this.GROUP_TYPE = groupType
    this.loadAssignmentDetails(0)
  }


  changeLevelQuestionChanged(questionCount, levelType) {
  //  console.log('questionCount',questionCount);
  //  console.log('this.L1_QUESTIONS_COUNT',this.L1_QUESTIONS_COUNT);
   
    if (levelType == 'L1') {
      console.log('questionCount',questionCount);
      
      if(questionCount <= this.L1_QUESTIONS_COUNT){
      this.L1_QUESTIONS = questionCount
      } else{
      this.message.error(" Your Count Of Question Is More Than Present Questions In Chapter","")
      this.L1_QUESTIONS = 0
      console.log('this.L1_QUESTIONS',this.L1_QUESTIONS);
      }
    }
    if (levelType == 'L2') {
      if(questionCount <= this.L2_QUESTIONS_COUNT){
        this.L2_QUESTIONS = questionCount
        } else{
        this.message.error(" Your Count Of Question Is More Than Present Questions In Chapter","")
        this.L2_QUESTIONS = 0
        }
    }
    if (levelType == 'L3') {
      if(questionCount <= this.L3_QUESTIONS_COUNT){
        this.L3_QUESTIONS = questionCount
        } else{
        this.message.error(" Your Count Of Question Is More Than Present Questions In Chapter","")
        this.L3_QUESTIONS = 0
        }
    }
    if (levelType == 'L4') {
      if(questionCount <= this.L4_QUESTIONS_COUNT){
        this.L4_QUESTIONS = questionCount
        } else{
        this.message.error(" Your Count Of Question Is More Than Present Questions In Chapter","")
        this.L4_QUESTIONS = 0
        }
    }
    if (levelType == 'L5') {
      if(questionCount <= this.L5_QUESTIONS_COUNT){
        this.L5_QUESTIONS = questionCount 
        } else{
        this.message.error(" Your Count Of Question Is More Than Present Questions In Chapter","")
        this.L5_QUESTIONS = 0
        }
    }
    this.TOTAL_QUESTION = this.L1_QUESTIONS + this.L2_QUESTIONS + this.L3_QUESTIONS + this.L4_QUESTIONS + this.L5_QUESTIONS
  }

  changeLevelTimeChanged(time, levelType) {
    if (levelType == 'L1') {
      if(this.L1_QUESTIONS == 0){
        this.message.error(' First Add The Questions','')
         this.L1_TIME == 0
      }else{
      this.L1_TIME = time
      }
    }
    if (levelType == 'L2') {
      if(this.L2_QUESTIONS == 0){
        this.message.error(' First Add The Questions','')
         this.L2_TIME == 0
      }else{
      this.L2_TIME = time
      }
    }
    if (levelType == 'L3') {
      if(this.L3_QUESTIONS == 0){
        this.message.error(' First Add The Questions','')
         this.L3_TIME == 0
      }else{
      this.L3_TIME = time
      }
    }
    if (levelType == 'L4') {
      if(this.L4_QUESTIONS == 0){
        this.message.error(' First Add The Questions','')
         this.L4_TIME == 0
      }else{
      this.L4_TIME = time
      }
    }
    if (levelType == 'L5') {
      if(this.L5_QUESTIONS == 0){
        this.message.error(' First Add The Questions','')
         this.L5_TIME == 0
      }else{
      this.L5_TIME = time
      }
    }

    this.TOTAL_TIME = (this.L1_QUESTIONS * this.L1_TIME) + (this.L2_QUESTIONS * this.L2_TIME) + (this.L3_QUESTIONS * this.L3_TIME) + (this.L4_QUESTIONS * this.L4_TIME) + (this.L5_QUESTIONS * this.L5_TIME)
    this.TOTAL_TIME_STRING = this.api.getSecondsToHms(this.TOTAL_TIME)
  }
  partName
getParts(event:any)
{

  this.api.getAllShikuyaaChapters(0,0,'','','AND ID='+event).subscribe(localName => {
   this.partName=localName['data'][0]['PART_NAME']
  }, err => {
    console.log(err);

  });
}

addQuestions(){
  // this.isSpinning = false;
  this.isOk=true;

  console.log('this.L1_QUESTIONS_COUNT',this.L1_QUESTIONS_COUNT);
  console.log('this.L1_QUESTIONS_COUNT',this.L2_QUESTIONS_COUNT);
  console.log('this.L1_QUESTIONS_COUNT',this.L3_QUESTIONS_COUNT);
  console.log('this.L1_QUESTIONS_COUNT',this.L4_QUESTIONS_COUNT);
  console.log('this.L1_QUESTIONS_COUNT',this.L5_QUESTIONS_COUNT);

  
  if ( this.L1_QUESTIONS == 0 && this.L1_TIME == 0 &&
    this.L2_QUESTIONS == 0 && this.L2_TIME == 0 &&
    this.L3_QUESTIONS == 0 && this.L3_TIME == 0 &&
    this.L4_QUESTIONS == 0 && this.L4_TIME == 0 &&
     this.L5_QUESTIONS == 0 && this.L5_TIME == 0  ){
    this.isOk =false 
   this.message.error(" Please First Add Questions On Selected Chapter !", ""); 
  }else
  if(this.L1_QUESTIONS_COUNT == 0 && this.L1_QUESTIONS > 0){
   this.isOk =false
    this.message.error(" Your Count Of Question Is More Than Present Questions In L1 Que ", ""); 
  } else
  if(this.L2_QUESTIONS_COUNT == 0 && this.L2_QUESTIONS > 0){
   this.isOk =false
    this.message.error(" Your Count Of Question Is More Than Present Questions In L2 Que ", ""); 
  } else
  if(this.L3_QUESTIONS_COUNT == 0 && this.L3_QUESTIONS > 0){
   this.isOk =false
    this.message.error(" Your Count Of Question Is More Than Present Questions In L3 Que ", ""); 
  } else  
  if(this.L4_QUESTIONS_COUNT == 0 && this.L4_QUESTIONS > 0){
   this.isOk =false
    this.message.error(" Your Count Of Question Is More Than Present Questions In L4 Que ", ""); 
  } else
  if(this.L5_QUESTIONS_COUNT == 0 && this.L5_QUESTIONS > 0){
   this.isOk =false
    this.message.error(" Your Count Of Question Is More Than Present Questions In L5 Que ", ""); 
  } else
  if(this.L1_QUESTIONS > 0 && (this.L1_TIME == 0 || this.L1_TIME == null)){
    this.isOk =false
    this.message.error('Please Enter L1 Time','')
  }else 

  if(this.L2_QUESTIONS > 0 && (this.L2_TIME == 0 || this.L2_TIME == null)){
   this.isOk =false
   this.message.error('Please Enter L2 Time','')

  }else 

  if(this.L3_QUESTIONS > 0 && (this.L3_TIME == 0 || this.L3_TIME == null)){
   this.isOk =false
   this.message.error('Please Enter L3 Time','')

  }else 

  if(this.L4_QUESTIONS > 0 && (this.L4_TIME == 0 || this.L4_TIME == null)){
   this.isOk =false
   this.message.error('Please Enter L4 Time','')

  }else 

  if(this.L5_QUESTIONS > 0 && (this.L5_TIME == 0 || this.L5_TIME == null)){
   this.isOk =false
   this.message.error('Please Enter L5 Time','')
  
}
if(this.isOk)
{

  // if (this.SELECTED_ID != undefined && this.L1_QUESTIONS != undefined && this.L1_QUESTIONS.toString() != "" && this.L1_TIME.toString() != "" && this.L2_QUESTIONS != undefined && this.L2_QUESTIONS.toString() != "" && this.L2_TIME.toString() != "" && this.L3_QUESTIONS != undefined && this.L3_QUESTIONS.toString() != "" && this.L3_TIME.toString() != "" && this.L4_QUESTIONS != undefined && this.L4_QUESTIONS.toString() != "" && this.L4_TIME.toString() != "") {
    console.log(this.SELECTED_ID)
// this.getParts(this.SELECTED_ID)
    let selectedChapterId = 0
    selectedChapterId = this.SELECTED_ID;

    this.assignmentDetailsData.MOCK_DETAILS_ID = this.MOCK_DETAILS_ID
    this.assignmentDetailsData.MOCK_MASTER_ID = this.assignmentId
    this.assignmentDetailsData.CHAPTER_ID = selectedChapterId
    this.assignmentDetailsData.L1_QUESTIONS = this.L1_QUESTIONS
    this.assignmentDetailsData.L1_TIME = this.L1_TIME
    this.assignmentDetailsData.L2_QUESTIONS = this.L2_QUESTIONS
    this.assignmentDetailsData.L2_TIME = this.L2_TIME
    this.assignmentDetailsData.L3_QUESTIONS = this.L3_QUESTIONS
    this.assignmentDetailsData.L3_TIME = this.L3_TIME
    this.assignmentDetailsData.L4_QUESTIONS = this.L4_QUESTIONS
    this.assignmentDetailsData.L4_TIME = this.L4_TIME
    this.assignmentDetailsData.L5_QUESTIONS = this.L5_QUESTIONS
    this.assignmentDetailsData.L5_TIME = this.L5_TIME
    this.assignmentDetailsData['TOTAL_TIME'] = (this.L1_QUESTIONS * this.L1_TIME) + (this.L2_QUESTIONS * this.L2_TIME) + (this.L3_QUESTIONS * this.L3_TIME) + (this.L4_QUESTIONS * this.L4_TIME) + (this.L5_QUESTIONS * this.L5_TIME)
    this.assignmentDetailsData['TOTAL_QUESTION'] = this.L1_QUESTIONS + this.L2_QUESTIONS + this.L3_QUESTIONS + this.L4_QUESTIONS + this.L5_QUESTIONS
    this.assignmentDetailsData['LEVEL_IDS'] = this.LEVEL_IDS

    console.log(this.assignmentDetailsData)
    this.loadingAddQuestion = true
    this.api.manageMockDetails(this.assignmentDetailsData)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.loadingAddQuestion = false
          this.message.success("Assesment Details added successfully...", "");
          this.loadAllStats();
          this.flag = true
          this.resetValues()
        }
        else {
          this.message.error("Failed to add Assesment Details information...", "");
          this.loadingAddQuestion = false
        }
      });
  // }
}
}

  addQuestions2() {
    console.log('this.L1_QUESTIONS',this.L1_QUESTIONS);
    console.log('this.L1_TIME',this.L1_TIME);
    console.log('this.L2_QUESTIONS',this.L2_QUESTIONS);
    console.log('this.L2_TIME',this.L2_TIME);
    
  
    if(this.L1_QUESTIONS_COUNT!=0 || this.L2_QUESTIONS_COUNT!=0 || this.L3_QUESTIONS_COUNT!=0 || this.L4_QUESTIONS_COUNT!=0 || this.L5_QUESTIONS_COUNT!=0)
{
  if (this.SELECTED_ID != undefined && this.L1_QUESTIONS != undefined && this.L1_QUESTIONS.toString() != "" && this.L1_TIME.toString() != "" && this.L2_QUESTIONS != undefined && this.L2_QUESTIONS.toString() != "" && this.L2_TIME.toString() != "" && this.L3_QUESTIONS != undefined && this.L3_QUESTIONS.toString() != "" && this.L3_TIME.toString() != "" && this.L4_QUESTIONS != undefined && this.L4_QUESTIONS.toString() != "" && this.L4_TIME.toString() != "") {
    console.log(this.SELECTED_ID)
// this.getParts(this.SELECTED_ID)
    let selectedChapterId = 0
    selectedChapterId = this.SELECTED_ID;

    this.assignmentDetailsData.MOCK_DETAILS_ID = this.MOCK_DETAILS_ID
    this.assignmentDetailsData.MOCK_MASTER_ID = this.assignmentId
    this.assignmentDetailsData.CHAPTER_ID = selectedChapterId
    this.assignmentDetailsData.L1_QUESTIONS = this.L1_QUESTIONS
    this.assignmentDetailsData.L1_TIME = this.L1_TIME
    this.assignmentDetailsData.L2_QUESTIONS = this.L2_QUESTIONS
    this.assignmentDetailsData.L2_TIME = this.L2_TIME
    this.assignmentDetailsData.L3_QUESTIONS = this.L3_QUESTIONS
    this.assignmentDetailsData.L3_TIME = this.L3_TIME
    this.assignmentDetailsData.L4_QUESTIONS = this.L4_QUESTIONS
    this.assignmentDetailsData.L4_TIME = this.L4_TIME
    this.assignmentDetailsData.L5_QUESTIONS = this.L5_QUESTIONS
    this.assignmentDetailsData.L5_TIME = this.L5_TIME
    this.assignmentDetailsData['TOTAL_TIME'] = (this.L1_QUESTIONS * this.L1_TIME) + (this.L2_QUESTIONS * this.L2_TIME) + (this.L3_QUESTIONS * this.L3_TIME) + (this.L4_QUESTIONS * this.L4_TIME) + (this.L5_QUESTIONS * this.L5_TIME)
    this.assignmentDetailsData['TOTAL_QUESTION'] = this.L1_QUESTIONS + this.L2_QUESTIONS + this.L3_QUESTIONS + this.L4_QUESTIONS + this.L5_QUESTIONS
    this.assignmentDetailsData['LEVEL_IDS'] = this.LEVEL_IDS

    console.log(this.assignmentDetailsData)
    this.loadingAddQuestion = true
    this.api.manageMockDetails(this.assignmentDetailsData)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.loadingAddQuestion = false
          this.message.success("Assesment Details added successfully...", "");
          this.loadAllStats();
          this.flag = true
          this.resetValues()
        }
        else {
          this.message.error("Failed to add Assesment Details information...", "");
        }
      });
  }
  else {
    this.message.error("Please fill all Details", "");
  }
     }
    else
    {
      this.message.error('Failed to add questions . Please first add questions on selected chapter !','')
    }
  }


  loadParts() {
    this.loadingParts = true;
    this.api.getAllParts(0, 0, 'ID', 'asc', '').subscribe(localName => {
      this.parts = localName['data'];
      this.PART_ID = localName['data'][0]['ID']
      this.partChange(this.PART_ID)
      this.loadingParts = false;
    }, err => {
      console.log(err);
      this.loadingParts = false;
    });
  }

  partChange(partId) {
    let filter = " AND PART_ID=" + partId
    this.loadingSections = true;
    this.api.getAllSections(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      this.sections = localName['data'];
      this.SECTION_ID = undefined
      this.CHAPTER_ID = undefined
      this.loadingSections = false;
    }, err => {
      console.log(err);
      this.loadingSections = false;
    });
  }

  sectionChange(sectionId) {
    let filter = " AND SECTION_ID=" + sectionId
    this.loadingChapters = true;
    this.api.getAllChapters(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      this.CHAPTER_ID = undefined
      this.chapters = localName['data'];
      this.loadingChapters = false;
    }, err => {
      console.log(err);
      this.loadingChapters = false;
    });
  }
partss
  loadAllStats() {
    this.isStatsSpinning = true
    let filter = " AND MOCK_MASTER_ID=" + this.assignmentId
    this.api.getAllMockDestails(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      console.log("Assesment Details gird localName")
      console.log(localName)
     this.partss=localName['data']
      this.assignmentDetailsCount = localName['data'];
      this.TOTAL_QUESTIONS = 0
      this.TIME = "0"
      this.getAllStats()
      this.isStatsSpinning = false;
    }, err => {
      console.log(err);
      this.isStatsSpinning = false;
    });
  }

  getAllStats() {
    let l1time = 0
    let l2time = 0
    let l3time = 0
    let l4time = 0
    let l5time = 0
    let totalTime = 0
    this.assignmentDetailsCount.forEach(element => {
      this.TOTAL_QUESTIONS = this.TOTAL_QUESTIONS + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
      l1time = l1time + element['L1_QUESTIONS'] * element['L1_TIME']
      l2time = l2time + element['L2_QUESTIONS'] * element['L2_TIME']
      l3time = l3time + element['L3_QUESTIONS'] * element['L3_TIME']
      l4time = l4time + element['L4_QUESTIONS'] * element['L4_TIME']
      l5time = l5time + element['L5_QUESTIONS'] * element['L5_TIME']
      totalTime = l1time + l2time + l3time + l4time + l5time
      this.TOTAL_L1_QUESTIONS = this.TOTAL_L1_QUESTIONS + element['L1_QUESTIONS'];
      this.TOTAL_L2_QUESTIONS = this.TOTAL_L2_QUESTIONS + element['L2_QUESTIONS'];
      this.TOTAL_L3_QUESTIONS = this.TOTAL_L3_QUESTIONS + element['L3_QUESTIONS'];
      this.TOTAL_L4_QUESTIONS = this.TOTAL_L4_QUESTIONS + element['L4_QUESTIONS'];
      this.TOTAL_L5_QUESTIONS = this.TOTAL_L5_QUESTIONS + element['L5_QUESTIONS'];
      this.TOTAL_TIME_IN_SECOND = totalTime
      this.TIME = this.api.getSecondsToHms(totalTime)
    });
    this.TOTAL_CHAPTER_SELECTED = this.assignmentDetailsCount.length;
    console.log("flag", this.flag)

    if (this.flag) {
      this.data.TOTAL_QUESTIONS = this.TOTAL_QUESTIONS
      this.data.TOTAL_TIME = this.TOTAL_TIME_IN_SECOND


      this.api.updateMock(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            // this.message.success("Assignment information updated Successfully...", "");

            this.logtext = 'Update & Close - Assesment form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Assesment ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
          else {
            this.logtext = 'Update & Close - Assesment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Assesment  ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.message.error("Failed to update information...", "");
          }
        });

    }

  }

  loadAssignmentDeatils() {

    if (this.CHAPTER_ID != undefined) {
      this.isLocadingAssignmentDetails = true
      this.api.getMockDetails(this.assignmentId, this.CHAPTER_ID).subscribe(data => {
        console.log("details JSON")
        console.log(data)
        if (data['code'] == 200) {
          this.assignmentDetails = data['data'];
          this.TOTAL_QUESTIONS = data['data1'][0]['TOTAL_QUESTIONS']
          this.TIME = this.api.getSecondsToHms(data['data1'][0]['TOTAL_TIME'])
          this.getStats(this.assignmentDetails)
          this.TOTAL_QUESTIONS = 0
          this.TIME = "0"
          this.getAllStats()
          this.flag = false
          this.isLocadingAssignmentDetails = false
          this.visibleData = true
        }
        else {
          this.message.error("Failed To Get  ...", "");
          this.assignmentDetails = []
          this.isLocadingAssignmentDetails = false
        }
      }, err => {
        this.assignmentDetails = []
        this.isLocadingAssignmentDetails = false
        console.log(err);
      });
    }
    else {
      this.message.error("Please Select Section & Chapter...", "");
    }
  }

  viewQuestion(data) {
    this.TYPE = ""
    let filter = " AND MOCK_DETAILS_ID=" + data.ID
    this.api.getAllMockQuestions(0, 0, 'ID', 'asc', filter).subscribe(data => {
      this.questionDetailsData = data['data'];
      this.questionDataLength = data['count']
      console.log(this.questionDetailsData)
      this.drawerTitle = "Question For ";
      this.drawerVisible = true;
    }, err => {
      console.log(err);
    });

  }

  getStats(data) {
    this.resetValues()
    console.log('mapping update data')
    if (this.modeType == "C") {

    } else {
      console.log(data)

      data.forEach(element => {
        if (element['checked']) {
          //part

          if (element['key'].startsWith("p")) {
            this.TOTAL_PARTS_SELECTED = this.TOTAL_PARTS_SELECTED + 1
          }


        }
        if (element['children']) {
          //sections
          element['children'].forEach(element => {

            if (element['checked']) {
              if (element['key'].startsWith("s")) {
                this.TOTAL_SECTION_SELECTED = this.TOTAL_SECTION_SELECTED + 1
              }
            }

            if (element['children']) {
              //chapters
              element['children'].forEach(element => {
                if (element['checked']) {
                  if (element['key'].startsWith("c")) {
                    this.TOTAL_CHAPTER_SELECTED = this.TOTAL_CHAPTER_SELECTED + 1
                  }
                }

                if (element['children']) {
                  element['children'].forEach(element => {
                    if (element['checked']) {
                      if (element['key'].startsWith("r")) {
                        this.TOTAL_SUBCHAPTERS = this.TOTAL_SUBCHAPTERS + 1
                      }
                    }

                    if (element['children']) {
                      element['children'].forEach(element => {

                        if (element['checked'] && element['key'].startsWith("Classwork")) {
                          this.CLASSWORK_QUESTIONS = this.CLASSWORK_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Homework")) {
                          this.HOMEWORK_QUESTIONS = this.HOMEWORK_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Practice")) {
                          this.PRACTICE_QUESTIONS = this.PRACTICE_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Assignment")) {
                          this.ASSIGNMNETS_QUESTIONS = this.ASSIGNMNETS_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("AQG")) {
                          this.AQGS = this.AQGS + 1
                          if (element['children']) {
                            element['children'].forEach(element => {
                              if (element['checked'] && element['key'].startsWith == 'T')
                                this.TOTAL_TEMPLATES = this.TOTAL_TEMPLATES + 1
                            });
                          }
                        }

                      });
                    }

                  });
                }


              });
            }



          });
        }

      });


    }
  }



  changeLevelTimeChange(seconds, levelText) {
    switch (levelText) {
      case "L1":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L1_TIME'] = seconds


          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L1_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L1_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L1_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L1_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L1_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L2":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L2_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L2_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L2_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L2_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L2_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L2_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L3":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L3_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L3_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L3_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L3_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L3_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L3_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L4":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L4_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L4_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L4_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L4_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L4_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L4_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L5":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L5_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L5_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L5_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L5_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L5_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L5_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;
    }
  }

  getcheckChange(event?) {

    var keys = event.keys
    this.resetValues()
    if (keys.length > 0) {
      keys.forEach(element => {
        if (element.startsWith("p")) {
          this.TOTAL_PARTS_SELECTED = this.TOTAL_PARTS_SELECTED + 1
        }
        else if (element.startsWith("s")) {
          this.TOTAL_SECTION_SELECTED = this.TOTAL_SECTION_SELECTED + 1
        }
        else if (element.startsWith("c")) {
          this.TOTAL_CHAPTER_SELECTED = this.TOTAL_CHAPTER_SELECTED + 1
        }
        else if (element.startsWith("r")) {
          this.TOTAL_SUBCHAPTERS = this.TOTAL_SUBCHAPTERS + 1
        }
        else if (element.startsWith("Classwork")) {
          this.CLASSWORK_QUESTIONS = this.CLASSWORK_QUESTIONS + 1
        }
        else if (element.startsWith("Homework")) {
          this.HOMEWORK_QUESTIONS = this.HOMEWORK_QUESTIONS + 1
        }
        else if (element.startsWith("Practice")) {
          this.PRACTICE_QUESTIONS = this.PRACTICE_QUESTIONS + 1
        }
        else if (element.startsWith("Assignment")) {
          this.ASSIGNMNETS_QUESTIONS = this.ASSIGNMNETS_QUESTIONS + 1
        }
        else if (element.startsWith("AQG")) {
          this.AQGS = this.AQGS + 1
        }
        else if (element.startsWith("T")) {
          this.TOTAL_TEMPLATES = this.TOTAL_TEMPLATES + 1
        }

      });

    }
    else {
      this.resetValues()
    }

    if (event.node.origin.checked) {
      if (event.node.origin.children = '') {
        if (event.node.origin.children.length >= 0) {
          if (event.node.origin.children = '') {
            event.node.origin.children.forEach(element => {
              element['disabled'] = true
              if (element['children'] = '') {
                element['children'].forEach(element => {
                  element['disabled'] = true
                  if (element['children'] = '') {
                    element['children'].forEach(element => {
                      element['disabled'] = true
                      if (element['children'] = '') {
                        element['children'].forEach(element => {
                          element['disabled'] = true
                          if (element['children'] = '') {
                            element['children'].forEach(element => {
                              element['disabled'] = true
                            });
                          }
                        });
                      }

                    });
                  }

                });
              }
            });
          }
        }
      }
    }
    else {
      console.log(event.node.origin.checked)
      if (event.node.origin.children = '') {
        if (event.node.origin.children.length >= 0) {
          event.node.origin.children.forEach(element => {
            element['disabled'] = false
            if (element['children'] = '') {
              this.disableButton = false
              element['children'].forEach(element => {
                element['disabled'] = false
                if (element['children'] = '') {
                  element['children'].forEach(element => {
                    element['disabled'] = false
                    if (element['children'] = '') {
                      element['children'].forEach(element => {
                        element['disabled'] = false
                        if (element['children'] = '') {
                          element['children'].forEach(element => {
                            element['disabled'] = false
                          });
                        }
                      });
                    }

                  });
                }

              });
            }
          });
        }
      }
    }


    this.disableButtonNew()
  }

  disableButtonNew() {
    var count = 0

    let partcheck = false
    let sectioncheck = false
    let chaptercheck = false
    let subchaptercheck = false
    let othercheck = false
    this.assignmentDetails.forEach(element => {
      partcheck = element['checked']
      if (element['children'] = '')
        element['children'].forEach(element => {
          sectioncheck = element['checked']
          if (element['disabled'] == true && element['checked'] == true)
            count++;
          else {
            if (element['children'] = '') {
              element['children'].forEach(element => {
                chaptercheck = element['checked']
                if (element['disabled'] == true && element['checked'] == true)
                  count++;
                else
                  if (element['children'] = '') {
                    element['children'].forEach(element => {
                      if (element['checked'])
                        subchaptercheck = element['checked']

                      if (element['disabled'] == true && element['checked'] == true)
                        count++;
                      else {
                        if (element['children'] = '') {
                          element['children'].forEach(element => {
                            if (element['checked'])
                              othercheck = element['checked']
                            if (element['disabled'] == true && element['checked'] == true)
                              count++;
                          });
                        }
                      }
                    });
                  }
              });
            }
          }

        });
    });



    if (partcheck)
      if (sectioncheck || chaptercheck || subchaptercheck || othercheck)
        count++
    if (sectioncheck)
      if (chaptercheck || subchaptercheck || othercheck)
        count++
    if (chaptercheck)
      if (subchaptercheck || othercheck)
        count++


    if (count == 0)
      this.disableButton = false
    else {
      this.disableButton = true
    }


    console.log(count)
  }

  resetValues() {
    this.SELECTED_ID = 0
    this.TOTAL_QUESTION = 0
    this.TOTAL_TIME_STRING = "0"
    this.TOTAL_TIME = 0
    this.L1_QUESTIONS_COUNT = 0
    this.L2_QUESTIONS_COUNT = 0
    this.L3_QUESTIONS_COUNT = 0
    this.L4_QUESTIONS_COUNT = 0
    this.L5_QUESTIONS_COUNT = 0
    this.L1_QUESTIONS = 0
    this.L2_QUESTIONS = 0
    this.L3_QUESTIONS = 0
    this.L4_QUESTIONS = 0
    this.L5_QUESTIONS = 0
    this.L1_TIME = 0
    this.L2_TIME = 0
    this.L3_TIME = 0
    this.L4_TIME = 0
    this.L5_TIME = 0
    this.L1_QUESTIONS_Allocated = 0
    this.L2_QUESTIONS_Allocated = 0
    this.L3_QUESTIONS_Allocated = 0
    this.L4_QUESTIONS_Allocated = 0
    this.L5_QUESTIONS_Allocated = 0
    this.MOCK_DETAILS_ID = 0
    this.LEVEL_IDS = []
    this.mode = 0
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    //this.loadAssignmentDeatils()

    if(localStorage.getItem('levels')=='L1')
    this.L1_QUESTIONS=JSON.parse(localStorage.getItem('count'))


    if(localStorage.getItem('levels')=='L2')
    this.L2_QUESTIONS=JSON.parse(localStorage.getItem('count'))


    if(localStorage.getItem('levels')=='L3')
    this.L3_QUESTIONS=JSON.parse(localStorage.getItem('count'))

    if(localStorage.getItem('levels')=='L4')
    this.L4_QUESTIONS=JSON.parse(localStorage.getItem('count'))

    if(localStorage.getItem('levels')=='L5')
    this.L5_QUESTIONS=JSON.parse(localStorage.getItem('count'))

    this.drawerVisible = false;

    var data1filter = []
    data1filter = this.questionDetailsData.filter(object => {
      return object['IS_SELECTED'] == 1;
    });

    var index = this.LEVEL_IDS.indexOf(this.levelId);

    if (data1filter.length == 0) {
      if (index == -1) {
        this.LEVEL_IDS.push(this.levelId);
      }
      else {
        if (index !== -1) {
          this.LEVEL_IDS.splice(index, 1);
        }
      }
    }


    if (this.levelId == 1)
      this.assignmentDetailsData['L1_QUESTIONS_DATA'] = data1filter

    if (this.levelId == 2)
      this.assignmentDetailsData['L2_QUESTIONS_DATA'] = data1filter

    if (this.levelId == 3)
      this.assignmentDetailsData['L3_QUESTIONS_DATA'] = data1filter

    if (this.levelId == 4)
      this.assignmentDetailsData['L4_QUESTIONS_DATA'] = data1filter

    if (this.levelId == 5)
      this.assignmentDetailsData['L5_QUESTIONS_DATA'] = data1filter

      this.shows()
  }
  shows()
  {
this.show.emit('any')
  }
select:any
level:any
  getQuestionClick(levelId, count,type) {
    this.levelId = levelId;

    localStorage.setItem('levels',JSON.stringify(type).replace(/"/g, ''))
    this.isLocadingAssignmentDetails = true
    this.select= Number(this.SELECTED_ID)
    this.level=Number(levelId)
    this.api.getMockQuestionDetails(this.assignmentId, Number(this.SELECTED_ID), Number(levelId))
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.isLocadingAssignmentDetails = false
          // this.nodeObject = node
          this.questionDetailsData = successCode['data']
          this.questionData.checkAll = false
          this.questionData.selectedQuesCount = 0
          this.questionData.getDataList(this.questionDetailsData, this.assignmentDetails[0], count)
          this.questionData.getCheckSelectedQuestionCount()
          this.questionDataLength = successCode['data'].length
          this.TYPE = "SAVE"
   
          this.drawerTitle = "Question For ";
          this.drawerVisible = true;
         
         
        
        }
        else {
          this.message.error("Assesment Details Getting Failed...", "");
          this.isLocadingAssignmentDetails = false;
        }
      }, err => {
        console.log(err);
        this.isLocadingAssignmentDetails = false;
      });


  }

  getTotalQuestionCount(value1) {


    this.getTotalStats()

    // this.TOTAL_QUESTIONS = this.TOTAL_QUESTIONS + value
    // console.log(this.TOTAL_QUESTIONS)

  }

  getTotalTime(value) {

    // this.addTime = this.addTime + value
    // this.TIME = this.api.getSecondsToHms(this.addTime)
  }

  getTime(l1ques, l1Time, l2ques, l2Time, l3ques, l3Time, l4ques, l4Time, l5ques, l5Time) {
    return (l1ques * l1Time) + (l2ques * l2Time) + (l3ques * l3Time) + (l4ques * l4Time) + (l5ques * l5Time)
  }


  getTotalStats() {
    console.log(this.assignmentDetails)
    let totalQuestions = 0
    let totalTime = 0
    let totalTimel1 = 0
    let totalTimel2 = 0
    let totalTimel3 = 0
    let totalTimel4 = 0
    let totalTimel5 = 0
    this.assignmentDetails.forEach(element => {
      //part
      if (element['checked']) {
        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
      }
      if (element['children'] = '') {
        //sections
        element['children'].forEach(element => {
          if (element['checked']) {
            totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
            totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
            totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
            totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
            totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
            totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
            totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
          }
          if (element['children'] = '') {
            //chapter
            element['children'].forEach(element => {
              if (element['checked']) {
                totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
              }
              if (element['children'] = '') {
                //subchapter
                element['children'].forEach(element => {
                  if (element['checked']) {
                    totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                    totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                    totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                    totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                    totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                    totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                    totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                  }
                  if (element['children'] = '') {
                    //classwork
                    element['children'].forEach(element => {
                      if (element['checked'] && element['key'].startsWith("Classwork")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                      }
                      if (element['checked'] && element['key'].startsWith("Homework")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                      }
                      if (element['checked'] && element['key'].startsWith("Practice")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                      }
                      if (element['checked'] && element['key'].startsWith("Assignment")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                      }
                      if (element['checked'] && element['key'].startsWith("AQG")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                        if (element['children']) {
                          element['children'].forEach(element => {
                            if (element['checked'] && element['key'].startsWith == 'T')
                              totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                            totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                            totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                            totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                            totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                            totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                            totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                          });
                        }
                      }

                    });

                  }


                });
              }

            });
          }

        });
      }
    });
    this.totalQuestions = totalQuestions
    this.totalTime = totalTime

    this.TOTAL_QUESTIONS = this.totalQuestions

    console.log(this.totalQuestions, totalTime)

  }

  getQuestionType(questionType) {
    if (questionType == "M")
      return "Mock"
    else if (questionType == "P")
      return "Practice"
    else if (questionType == "N")
      return "All"
  }

  saveMapQuestions() {

    // console.log( JSON.stringify(this.assignmentDetails))

    // if(this.assignmentDetails[0]['checked']==true)
    // {

    //   console.log(this.assignmentDetails[0]['key'],this.assignmentDetails[0]['L1_QUESTIONS'],this.assignmentDetails[0]['L2_QUESTIONS'],this.assignmentDetails[0]['L3_QUESTIONS'],this.assignmentDetails[0]['L4_QUESTIONS'],this.assignmentDetails[0]['L5_QUESTIONS'],this.assignmentDetails[0]['L1_TIME'],this.assignmentDetails[0]['L2_TIME'],this.assignmentDetails[0]['L3_TIME'],this.assignmentDetails[0]['L4_TIME'],this.assignmentDetails[0]['L5_TIME'])

    //   this.assignmentDetails.forEach(element => {
    //      element['children']
    //   });
    // }






    this.getTotalStats()
    this.TOTAL_QUESTIONS = this.totalQuestions
    this.TIME = this.api.getSecondsToHms(this.totalTime)

    this.loadingMapAssignment = true

    this.api.addMockDetails(this.CHAPTER_ID, this.assignmentId, this.assignmentDetails, this.totalQuestions, this.totalTime)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Assesment Details Mapped Successfully ...", "");
          this.loadingMapAssignment = false
          this.flag = true
          this.loadAllStats()
          this.resetValues()
          this.visibleData = false
        }
        else {
          this.message.error("Assesment Details Mapped Mapping Failed...", "");
          this.loadingMapAssignment = false;
        }
      }, err => {
        console.log(err);
        this.loadingMapAssignment = false;
      });




    // this.getTotalStats()
    // this.TOTAL_QUESTIONS = this.totalQuestions

    // this.TIME = this.api.getSecondsToHms(this.totalTime)
    // console.log(this.totalTime)
    // console.log(this.TIME)
    // // let tTime = this.api.getSecondsToHms(this.totalTime)


    // if (flag == "onlysave")
    //   this.loadingMapQuestions = true
    // else
    //   this.loadingMapAssignment = true

    // this.api.addAssignmentDetails(this.assignmentId, this.assignmentDetails, this.totalQuestions, this.totalTime)
    //   .subscribe(successCode => {
    //     if (successCode['code'] == "200") {
    //       this.message.success("Mock Details Mapped Successfully ...", "");
    //       if (flag == "onlysave") {
    //         this.drawerClose()
    //         this.loadingMapQuestions = false;
    //       }
    //       else {
    //         this.current += 1;
    //         this.loadingMapAssignment = false
    //       }
    //       this.getDeatilsData()
    //     }
    //     else {
    //       this.message.error("Mock Details Mapped Mapping Failed...", "");
    //       this.loadingMapQuestions = false;
    //     }
    //   }, err => {
    //     console.log(err);
    //     this.loadingMapQuestions = false;
    //   });


  }
  
  saveAndNext() {
    // this.current += 1;
    this.getDeatilsData()

  }

  saveAndclose() {
    this.drawerClose()
  }
  getDeatilsData() {

    this.loadBranches()
    // this.getBatchDetailsData()
    // this.assignmentId = this.data.ID;
    console.log(this.scope)
    console.log(this.assignmentId)
    this.ENABLE_TIME = undefined
    this.IS_RESULT_SHARING_TIME = true
    // this.IS_RESULT_SHARING_TIME=this.IS_RESULT_SHARING_TIME?true:false
    this.DISABLE_TIME = undefined
    this.RESULT_SHARING_TIME = undefined

    if (this.data.NAME != undefined)
      this.NAME = this.data.NAME
    this.filterValue = []
    this.drawerClose()
    // this.PASSKEY = this.api.generateRandomNumber(6).toString()
    // this.LINK = this.api.randomstring(9)
  }

  loadBranches() {
    //get all mapped branches to college
    // this.loadingBranches = true

    // let filter = " AND COLLEGE_ID=" + this.collegeId
    // this.api.getAllCollegeBranches(0, 0, '', '', filter).subscribe(data => {
    //   this.branches = data['data'];
    //   this.loadPassingYears()
    //   this.loadingBranches = false
    // }, err => {
    //   console.log(err);
    // });
    // this.api.getAllCourses(0, 0, 'ID', 'ASC', ' AND COURSE_TYPE_ID = 2').subscribe(localName => {

    //   this.coursesNodes = localName['data'];
    // }, err => {
    //   console.log(err);
    // });
  }
loadcoureses(event)
{
  this.api.getAllCourses(0, 0, 'ID', 'ASC', ' AND COURSE_TYPE_ID = 2 AND COURSE_SUB_CATEGORY_ID='+event).subscribe(localName => {

    this.coursesNodes = localName['data'];
  }, err => {
    console.log(err);
  });
}

  getBranchIdss(value) {
    this.loadPassingYears(value)
  }

  loadPassingYears(value?) {
    let filterQuery = ""
    this.loadingPassingYears = true

    let barnchstring: string = "";
    if (value != undefined) {
      value.forEach(passingYears => {
        barnchstring += "'" + passingYears + "'" + ",";
      }); (value)

      if (barnchstring.toString() != "") {
        if (barnchstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " BRANCH_ID IN (" + barnchstring.substring(0, barnchstring.length - 1) + ") ";
        }
      }
    }

    this.branchfilterQuery = filterQuery
    console.log(this.branchfilterQuery)
    if (filterQuery != "") {
      let filter = "AND ID in (select YEAR_ID from view_college_training_course_branch_year_mapping where " + filterQuery + ")"
      this.api.getAllPassingYears(0, 0, '', '', filter).subscribe(data => {
        console.log("year")
        this.passingYears = data['data'];
        console.log(this.passingYears)
        this.loadingPassingYears = false
      }, err => {
        console.log(err);
      });
    }
    else {
      let filter = ""
      this.api.getAllPassingYears(0, 0, '', '', filter).subscribe(data => {
        this.passingYears = data['data'];
        console.log("year with college")
        console.log(this.passingYears)
        this.loadDivisions()
        this.loadingPassingYears = false
      }, err => {
        console.log(err);
      });
    }
  }

  getPassingYearIdss(value) {
    this.loadDivisions(value)
  }

  loadDivisions(value?) {
    this.loadingDivisions = true
    let filterQuery = ""
    let passingyearstring: string = "";
    if (value != undefined) {
      value.forEach(passingYears => {
        passingyearstring += "'" + passingYears + "'" + ",";
      }); (value)

      if (passingyearstring.toString() != "") {
        if (passingyearstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " YEAR_ID IN (" + passingyearstring.substring(0, passingyearstring.length - 1) + ") ";
        }
      }
    }

    if (filterQuery != "") {
      let filter = " AND ID in (select DIVISION_ID from view_college_training_course_branch_year_division_mapping where " + this.branchfilterQuery + " and " + filterQuery + ")"
      this.api.getAllDivisions(0, 0, '', '', filter).subscribe(data => {
        this.divisions = data['data'];
        this.loadingDivisions = false
      }, err => {
        console.log(err);
      });
    }
    else {

      let filter = " "
      this.api.getAllDivisions(0, 0, '', '', filter).subscribe(data => {
        this.divisions = data['data'];
        this.loadingDivisions = false
      }, err => {
        console.log(err);
      });
    }

    // this.api.getAllCollegeBranchesPassingYearsDivisions(0, 0, '', '', filter).subscribe(data => {
    //   this.divisions = data['data'];
    //   console.log("divisions")
    //   console.log(this.divisions)
    //   this.loadingDivisions = false
    // }, err => {
    //   console.log(err);
    // });


  }

  getBatchDetailsData() {
    this.loadbatchDetailsFilter = true
    this.loadingFilter = true

    let filterQuery = ""
    console.log(this.filterBranches)
    console.log(this.filterPassingYear)
    console.log(this.filterDivision)

    //Branch Filter

    let branchesString: string = "";
    if (this.filterBranches != undefined) {
      this.filterBranches.forEach(branches => {
        branchesString += "'" + branches + "'" + ",";
      }); (this.filterBranches)

      if (branchesString.toString() != "") {
        if (branchesString.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " AND BRANCH_ID IN (" + branchesString.substring(0, branchesString.length - 1) + ") ";
        }
      }
    }

    let passingyearstring: string = "";
    if (this.filterPassingYear != undefined) {
      this.filterPassingYear.forEach(passingYears => {
        passingyearstring += "'" + passingYears + "'" + ",";
      }); (this.filterPassingYear)

      if (passingyearstring.toString() != "") {
        if (passingyearstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " AND PASSING_YEAR_ID IN (" + passingyearstring.substring(0, passingyearstring.length - 1) + ") ";
        }
      }
    }

    let divisionsString: string = "";
    if (this.filterDivision != undefined) {
      this.filterDivision.forEach(divisions => {
        divisionsString += "'" + divisions + "'" + ",";
      }); (this.filterDivision)

      if (divisionsString.toString() != "") {
        if (divisionsString.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " AND DIVISION_ID IN (" + divisionsString.substring(0, divisionsString.length - 1) + ") ";
        }
      }
    }



    if (filterQuery == "") {
      filterQuery = " AND COLLEGE_ID=" + this.collegeId
    }
    else {
      filterQuery = " AND COLLEGE_ID=" + this.collegeId + filterQuery
      // filterQuery = " AND COLLEGE_ID="+this.collegeId+ filterQuery.substring(0, filterQuery.length - 5);
    }
    console.log(filterQuery)
    this.api.getMockBatches(0, 0, '', '', filterQuery).subscribe(data => {
      console.log("batch Details Data")
      console.log(data)
      this.batchDetailsData = data['data'];
      this.loadingFilter = false
      this.loadbatchDetailsFilter = false
    }, err => {
      console.log(err);
    });
  }

  checkAllBranchesChanged(value, data) {
    if (value) {
      data.forEach(element => {
        element['IS_SELECTED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_SELECTED'] = 0
      });
    }
  }

  checkAllIsPublishChanged(value, data) {
    if (value) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['IS_PUBLISH_ENABLED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_PUBLISH_ENABLED'] = 0
      });
    }
  }

  checkAllIsResultTimeSharingChanged(value, data) {
    if (value) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['IS_RESULT_SHARING_ENABLED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_RESULT_SHARING_ENABLED'] = 0
      });
    }
  }

  getChangeEnableTime(enableTime, data) {
    if (enableTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['ENABLE_TIME'] = enableTime
      });
    }
    else {
      data.forEach(element => {
        element['ENABLE_TIME'] = undefined
      });
    }
  }

  getChangeDisableTime(disableTime, data) {
    if (disableTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['DISABLE_TIME'] = disableTime
      });
    }
    else {
      data.forEach(element => {
        element['DISABLE_TIME'] = undefined
      });
    }
  }

  getChangeResultSharingTime(resultsharingTime, data) {
    if (resultsharingTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1 && element['IS_RESULT_SHARING_ENABLED'] == 1)
          element['RESULT_SHARING_TIME'] = resultsharingTime
      });
    }
    else {
      data.forEach(element => {
        element['RESULT_SHARING_TIME'] = undefined
      });
    }
  }

  getChangePublishedTime(publishedTime, data) {
    if (publishedTime != undefined) {

      data.forEach(element => {
        if (element['IS_SELECTED'] == 1 && element['IS_PUBLISH_ENABLED'] == 1)
          element['PUBLISH_TIME'] = publishedTime
      });
    }
    else {
      data.forEach(element => {
        element['PUBLISH_TIME'] = undefined
      });
    }
  }


  changeBatchCheck(value, data) {
    if (value)
      data.IS_SELECTED = 1
    else
      data.IS_SELECTED = 0
  }

  changePublished(value, data) {
    if (value)
      data.IS_PUBLISH_ENABLED = 1
    else
      data.IS_PUBLISH_ENABLED = 0
  }

  changeResultSharingTime(value, data) {
    if (value)
      data.IS_RESULT_SHARING_ENABLED = 1
    else
      data.IS_RESULT_SHARING_ENABLED = 0
  }

  // disabledDate = (current: Date): boolean => {
  //   // Can not select days before today and today

  //   return differenceInCalendarDays(current, this.today) < 0;
  // };
  getSharingTime(value) {
    if (!value) {
      this.RESULT_SHARING_TIME = ""
    }
  }
  getSharingTime2(value) {
    if (!value) {
      this.RESULT_SHARING_TIME = ""
    }
  }
  saveAssignments2(finalData) {

    this.loadingSaveClose = true
    if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == false && this.data.platforms[2]['checked'] == false)
      this.data.PLATFORM = 'M'
    else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == true && this.data.platforms[2]['checked'] == false)
      this.data.PLATFORM = 'W'
    else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == false && this.data.platforms[2]['checked'] == true)
      this.data.PLATFORM = 'D'
    else if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == true && this.data.platforms[2]['checked'] == true)
      this.data.PLATFORM = 'B'

    if (this.data.DESCRIPTION == "")
      this.data.DESCRIPTION = " "

    //create assignment method
    if (this.data.ID) {
      this.api.updateMock(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            // this.message.success("Assignment information updated Successfully...", "");
            this.saveMapping(finalData)
            // this.getAssignmentId()

            this.logtext = 'Update & Close - Assesment form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Assesment ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {

                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
          else {
            this.logtext = 'Update & Close - Assesment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - AssesmentGroup ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.message.error("Failed to update information...", "");
            this.loadingSaveClose = false
          }
        }, err => {
          console.log(err);
          this.loadingSaveClose = false
        });
    }


  }
  isValid(value) {
    const expression =/^[a-zA-Z]+$/;
    return expression.test(String("" + value).toLowerCase())
  
  }
  nodes = [
    {
      title: 'parent 1',
      key: 100,
      disabled:true,
      children: [
        {
          title: 'parent 1-0',
          key: '1001',
         
          children: [
            { title: 'leaf 1-0-0', key: '10010', isLeaf: true },
            { title: 'leaf 1-0-1', key: '10011', isLeaf: true }
          ]
        },
        {
          title: 'parent 1-1',
          key: '1002',
          children: [{ title: 'leaf 1-1-0', key: '10020', isLeaf: true }]
        }
      ]
    }
  ];
checkDesc()
{
if(this.data.DESCRIPTION==undefined || this.data.DESCRIPTION=='')
{

}
else
{
  if(!this.isValid(this.data.DESCRIPTION))
  {
  
    setTimeout(() => {
      this.message.error("Please Enter Valid Description", "");
    }, 2000);
  }
}
}
checkDate()
{
  if(this.ENABLE_TIME > this.DISABLE_TIME)
  {
    setTimeout(() => {
      this.message.error("Please Enter Proper Disable Time", "");
      this.DISABLE_TIME=''
    }, 2000);
  }
}
  save() {
    var finalData = []

    if (this.filterValue.length > 0) {
if(this.data.POSITIVE_MARKS!=0 || this.data.POSITIVE_MARKS>0){
      if (new Date(this.ENABLE_TIME) >= this.today || new Date(this.DISABLE_TIME) >= this.today || new Date(this.RESULT_SHARING_TIME) >= this.today) {

        if (new Date(this.DISABLE_TIME) >= new Date(this.ENABLE_TIME) || (new Date(this.RESULT_SHARING_TIME) >= new Date(this.DISABLE_TIME) && new Date(this.RESULT_SHARING_TIME) >= new Date(this.ENABLE_TIME))) {
          this.ENABLE_TIME = this.datePipe.transform(this.ENABLE_TIME, "yyyy-MM-dd HH:mm:ss")
          this.DISABLE_TIME = this.datePipe.transform(this.DISABLE_TIME, "yyyy-MM-dd HH:mm:ss")
          var isSharingTime = this.IS_RESULT_SHARING_TIME ? 1 : 0
          if (this.RESULT_SHARING_TIME != "")
            this.RESULT_SHARING_TIME = this.datePipe.transform(this.RESULT_SHARING_TIME, "yyyy-MM-dd HH:mm:ss")
          else
            this.RESULT_SHARING_TIME = null

          if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == false && this.data.platforms[2]['checked'] == false)
            this.data.PLATFORM = 'M'
          else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == true && this.data.platforms[2]['checked'] == false)
            this.data.PLATFORM = 'W'
          else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == false && this.data.platforms[2]['checked'] == true)
            this.data.PLATFORM = 'D'
          else if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == true && this.data.platforms[2]['checked'] == true)
            this.data.PLATFORM = 'B'

          if (this.data.DESCRIPTION == "")
            this.data.DESCRIPTION = " "

          finalData = [{
            ENABLE_TIME: this.ENABLE_TIME,
            DISABLE_TIME: this.DISABLE_TIME,
            IS_RESULT_SHARING_ENABLED: isSharingTime,
            RESULT_SHARING_TIME: this.RESULT_SHARING_TIME,
            CLIENT_ID: this.api.clientId,
            MOCK_MASTER_ID: this.assignmentId,
            NAME: this.NAME,
            DESCRIPTION: this.data.DESCRIPTION,
            POSITIVE_MARKS: this.data.POSITIVE_MARKS,
            NEGATIVE_MARKS: this.data.NEGATIVE_MARKS,
            PLATFORM: this.data.PLATFORM,
            CONTRAVENTION_COUNT: this.data.CONTRAVENTION_COUNT,
            VALIDATION_COUNT: this.data.IS_VALIDATION_COMPULSORY ? this.data.VALIDATION_COUNT : 0,
            IS_VALIDATION_COMPULSORY: this.data.IS_VALIDATION_COMPULSORY,
            IS_TIME_BASED: this.TIME_BASED,
            COURSE_ID: this.filterValue,
            VALIDATION_METHOD: this.data.IS_VALIDATION_COMPULSORY ? this.data.VALIDATION_METHOD : ''
          }]

          this.saveMapping(finalData)
        }
        else {
          this.message.error("Please Check Time...", "");
        }
      }
      else {
        this.message.error("Invalid Date Selected ...", "");
      }
    }
    else
    {
      this.message.error("Please Enter +ve Marks ...", "");
    }
    }
    else {
      this.message.error("Please Select Courses ...", "");
    }
  }

  saveMapping(finalData) {
    this.loadingSaveClose = true

    this.api.addMockMapping(finalData)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.message.success("Assesment Mapping Successfully ...", "");

          this.loadingSaveClose = false
          this.drawerClose()
          this.NAME = ""
          this.assignmentId = 0
          this.filterValue = []
        }
        else {
          this.message.error("Assesment Mapping Failed...", "");
          this.loadingSaveClose = false
        }
      }, err => {
        console.log(err);
        this.message.error("Server Error...", "");
        this.loadingSaveClose = false
      });
  }


}
