export class Shikuyaaquestion {
    ID: number;
    CLIENT_ID: number;
    CHAPTER_ID: number;
    QUESTION_TYPE_ID: number;
    LEVEL_ID: number;
    COURSE_ID:number
    GROUP_TYPE: string;
    PARAGRAPH_ID: number;
    DIRECTION: string=null;
    DIRECTION_IMG: string="";
    DIRECTION_IMG_CONVERTED:string
    DESCRIPTION: string=null;
    DESCRIPTION_IMG: string="";
    DESCRIPTION_IMG_CONVERTED:string
    IS_RANDOMIZATION_OPTION: boolean=false;
    IS_SEQUENCE_MARKING: boolean=false;
    QUESTION: string;
    QUESTION_URL_CONVERTED:string
    QUESTION_URL: string="";
    SOLUTION: string=null;
    SOLUTION_IMAGE_URL: string="";
    SOLUTION_VIDEO_URL: string=null;
    SOLUTION_IMAGE_URL_CONVERTED:string
    ANSWER_TEXT: string="";
    optionList=[]
    OPTIONS=[]
    IS_ACTIVE:boolean=true;
    COMPANY_ID:number;
    COMPANY_NAME:string
    COURSE_SUB_CATEGORY_ID:number
    CATEGORY_ID:number
   
   
}
