import { Component, OnInit, Input } from '@angular/core';
import { Questionparagraph } from 'src/app/Models/MasterSyllabus/questionparagraph';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService} from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-questionparagraph',
  templateUrl: './questionparagraph.component.html',
  styleUrls: ['./questionparagraph.component.css']
})
export class QuestionparagraphComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Questionparagraph;

  isSpinning = false
  logtext: string = "";
  userId = sessionStorage.getItem('userId');
  fileData_PARAGRAPH_IMAGE_URL: File = null
  fkey = "7b6eAHh2"
  dataList = []
  imageurl: any
  isokfile=true
  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {

  }

  loadAllQuestionPara() {
    this.api.getAllQuestionParagraphs(0, 0, 'ID', 'asc', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  // close(): void {
  //   this.drawerClose();

  //   this.logtext = 'CLOSED - QuestionParagraph form';
  //   this.api.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }

  ////

  resetDrawer(questionParagraphPage: NgForm) {
    this.data=new Questionparagraph();
    // questionParagraphPage.form.reset();

    questionParagraphPage.form.markAsPristine();
    questionParagraphPage.form.markAsUntouched();
  }

  close(questionParagraphPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(questionParagraphPage);
    questionParagraphPage.form.reset();
    this.logtext = 'CLOSED - QuestionParagraph form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  
  }
  //save
  save(addNew: boolean,questionParagraphPage: NgForm): void {
    if (this.data.PARAGRAPH_TEXT != undefined && this.data.PARAGRAPH_TEXT != "" && this.data.KEYWORD != undefined && this.data.KEYWORD != "") {

      if (/^[a-zA-Z\s-,._-]*$/.test(this.data.KEYWORD) == true) {


        if(this.isokfile)
        {
          this.isSpinning = true;
          if (this.fileData_PARAGRAPH_IMAGE_URL) {
            var fileExt = this.fileData_PARAGRAPH_IMAGE_URL.name.split('.').pop();
            var lkey = ""
            this.api.onUploadNewMethod(this.fileData_PARAGRAPH_IMAGE_URL, fileExt, this.fkey)
              .subscribe(successCode => {
                console.log(successCode)
                if (successCode['code'] == 200) {
                  lkey = successCode['data'][0]['L_KEY']
                  this.data.PARAGRAPH_IMAGE_VIDEO_URL = lkey
                  this.updateData(addNew)
                }
                else {
                  console.log(successCode)
                }
              });
          }
          else {
            if (this.data.ID)
              this.updateData(false)
            else {
              if (this.data.PARAGRAPH_TYPE == "I") {
                this.data.PARAGRAPH_IMAGE_VIDEO_URL = ""
              }
              this.updateData(addNew)
  
            }
          }
        }
        else
        {
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");

        }
     
      }
      else {
        this.message.error("Please Check Keyword", "");
      }
    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }

  updateData(addNew: boolean) {

    console.log(this.data)


    if (this.data.ID) {

      var filterData = this.dataList.filter(object => {
        return object['KEYWORD'].toLowerCase() == this.data.KEYWORD.toLowerCase() && object['ID'] != this.data.ID
      });


      if (filterData.length > 0) {
        if (filterData.length > 0)
          this.message.error("Keyword Already Present in Database", "");
      }
      else {

        this.api.updateQuestionParagraph(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("QuestionParagraph information updated Successfully...", "");
              this.logtext = 'Update & Close - QuestionParagraph form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - QuestionParagraph ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

              this.logtext = 'Update & Close - QuestionParagraph form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - QuestionParagraph ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              this.message.error("Failed to update questionParagraph information...", "");
              this.isSpinning = false;
            }
          });
      }
    }
    else {
      var filterData = this.dataList.filter(object => {
        return object['KEYWORD'].toLowerCase() == this.data.KEYWORD.toLowerCase()
      });


      if (filterData.length > 0) {
        if (filterData.length > 0)
          this.message.error("Keyword Already Present in Database", "");
      }
      else {

        this.api.createQuestionParagraph(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("QuestionParagraph information added successfully...", "");

              if (!addNew) {
                this.drawerClose();

                this.logtext = 'Save & Close - QuestionParagraph form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionParagraph ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.data = new Questionparagraph();
                // this.resetDrawer(questionParagraphPage);
                this.logtext = 'Save & New - QuestionParagraph form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionParagraph ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add questionParagraph information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - QuestionParagraph form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionParagraph ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
          });
      }
    }


  }



  onFileSelected_PARAGRAPH_IMAGE_URL(event) {
    this.isokfile = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfile = false
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    }

    if (this.isokfile) {
      this.fileData_PARAGRAPH_IMAGE_URL = <File>event.target.files[0];
    }



  }

  getUrl(url) {
    var newTab = window.open();
    newTab.document.body.innerHTML = '<img src="' + url + '" width="500px" height="100%" style="left: 408px;position: absolute;"> ';
  }

}
