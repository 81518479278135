import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Questionmaster } from 'src/app/Models/MasterSyllabus/questionmaster';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
// import { QuestionComponent } from '../../../../pages/MasterSyllabus/QuestionMaster/question/question.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { QuestionComponent } from '../question/question.component';
import { Questiontype } from 'src/app/Models/MasterSyllabus/questiontype';
import { Questionparagraph } from 'src/app/Models/MasterSyllabus/questionparagraph';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  formTitle = "Manage Questions";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  subChaptersNodes=[]
  isloadSpinning=false
  @ViewChild(QuestionComponent, { static: false }) question1: QuestionComponent;
  columns: string[][] = [["CHAPTER_NAME", "Chapter Name"], ["QUESTION_TYPE_NAME", "Question Type Name"], ["LEVEL_NAME", "Level Name"],["QUESTION","Question"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Questionmaster = new Questionmaster();
  filterClass: string = "filter-invisible";
  imageDirectionurl:any
  imageDescriptionurl:any
  imageParagraphurl:any
  imageQuestionurl:any
  imageSolutionurl:any
  pageSize2=10
  loadingTypes = false
  loadingLevels = false
  loadingParagraph = false
  questionTypes: Questiontype[]
  levels: Level[]
  paragraphs: Questionparagraph[]
  chaptersNodes = [] 
  chapterID:any
  queTypeID:any
  levelID:any
  filterValue :any

  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }
  ngOnInit() {

    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.search();
      this.loadAllSubChapters()
      this.logtext = "OPENED - Questions form KEYWORD[O - Questions] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {

          }
          else {
          }
        });
    }
    this.loadLevels()
    this.loadQuestionTypes()
    this.loadSubChpater()

  }

  loadLevels() {
    this.loadingLevels = true;
    this.api.getAllLevels(0, 0, 'value', 'asc', ' AND IS_ACTIVE=1').subscribe(localName => {
      if(localName['code']==200){
      this.levels = localName['data'];
      // this.data.LEVEL_ID = localName['data'][0]['ID']
      this.loadingLevels = false;
      }
      else{
        this.message.error('Cant Load The Level','')
        this.loadingLevels = false;
      
       }
    });
  }
  
  loadQuestionTypes() {
    this.loadingTypes = true;
    this.api.getAllQuestionTypes(0, 0, 'ID', 'desc', ' AND IS_ACTIVE=1').subscribe(localName => {
      console.log(localName)
      if(localName['code']==200){
        this.questionTypes = localName['data'];
        // this.data.QUESTION_TYPE_ID = localName['data'][0]['ID']
        this.loadingTypes = false;
      }
     else{
      this.message.error('Cant Load The Question Types','')
      this.loadingTypes = false;

     }
    });
  }

  loadSubChpater() {
    this.api.getAllChapterHierarchys(0).subscribe(localName => {
      if(localName['count']==0)
      {
        this.message.error('No Chapetrs Found','')
      }
      else{
        this.chaptersNodes = localName['data'];
        // console.log('Chapter:',this.subChaptersNodes)
      }
     
    }, err => {
      console.log(err);
    });

  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  onKeyDownEvent(event)
  {
this.search(true)
  }
  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    // try {
    //   sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    //   this.logtext = "Filter Applied - Questions form "+ sort +" "+this.sortKey +" KEYWORD [F - Questions] ";
    //   this.api.addLog('A', this.logtext, this.api.emailId)
    //     .subscribe(successCode => {
    //       if (successCode['code'] == "200") {
    //       }
    //       else {
    //       }
    //     });

    // } catch (error) {
    //   sort = "";
    // }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND(";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+")"
      console.log("likeQuery" + likeQuery);
    }
    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery
    // this.logtext = "Filter Applied - Questions form "+ filter +" KEYWORD [F - Questions] ";
    // this.api.addLog('A', this.logtext, this.api.emailId)
    //   .subscribe(successCode => {
    //     if (successCode['code'] == "200") {
    //     }
    //     else {
    //     }
    //   });
      this.isloadSpinning=true
    this.api.getAllQuestions(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.isloadSpinning=false

      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  applyFilter() {
    console.log('Value:',this.chapterID)
    console.log('Value:',this.queTypeID)
    console.log('Value:',this.levelID)
    
    // this.isSpinning=true
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    if (
       this.chapterID != undefined ||
       this.queTypeID != undefined ||
       this.levelID != undefined    
    ) {
    if (
       this.chapterID != undefined  &&
       this.queTypeID != undefined  &&
       this.levelID != undefined     
    ) {
      this.filterQuery =
        " AND CHAPTER_ID = "+ this.chapterID +
        " AND QUESTION_TYPE_ID = "+ this.queTypeID +
        " AND LEVEL_ID = "+ this.levelID 
    } else if (   
        this.chapterID != undefined &&
       this.queTypeID != undefined  &&
       this.levelID == undefined    ){
        this.filterQuery =
        " AND CHAPTER_ID = "+ this.chapterID +
        " AND QUESTION_TYPE_ID = "+ this.queTypeID 
      }
        else if (   
        this.chapterID != undefined &&
       this.queTypeID == undefined  &&
       this.levelID != undefined    ){
        this.filterQuery =
        " AND CHAPTER_ID = "+ this.chapterID +
        " AND LEVEL_ID = "+ this.levelID 
      }
        else if (   
          this.chapterID == undefined &&
         this.queTypeID != undefined  &&
         this.levelID != undefined     ){
          this.filterQuery =
          " AND QUESTION_TYPE_ID = "+ this.queTypeID +
          " AND LEVEL_ID = "+ this.levelID 
       }else if (   
         this.chapterID != undefined &&
        this.queTypeID == undefined  &&
        this.levelID == undefined     ){
         this.filterQuery =
         " AND CHAPTER_ID = "+ this.chapterID 
       } else if (   
        this.chapterID == undefined &&
        this.queTypeID != undefined &&
        this.levelID == undefined    ){
         this.filterQuery =
         " AND QUESTION_TYPE_ID = "+ this.queTypeID 
        } else if (   
          this.chapterID == undefined &&
          this.queTypeID == undefined &&
          this.levelID != undefined    ){
           this.filterQuery =
           " AND LEVEL_ID = "+ this.levelID 
          }

        var likeQuery = "";

        if (this.searchText != "") {
          likeQuery = " AND";
          this.columns.forEach(column => {
            likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
          });
          likeQuery = likeQuery.substring(0, likeQuery.length - 2)
        }
        this.api
        .getAllQuestions(
          0,0,
          this.sortKey,
          sort,
          this.filterQuery
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.isFilterApplied = 'primary';
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            // this.isSpinning = false;
            this.filterClass = 'filter-invisible';
            // this.search();
          },
          (err) => {
            console.log(err);
          }
        );
    } else if(
       this.chapterID == undefined && 
       this.queTypeID == undefined && 
        this.levelID == undefined    
    ){
      this.message.error(" Please Select Filter Values", "");
    }else{}
   
    // this.dataList=[];
  }


  clearFilter() {
    this.filterValue=[]
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.chapterID = undefined;
    this.queTypeID = undefined;
    this.levelID = undefined;
    this.filterQuery = ""
    this.search()
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  loadAllSubChapters() {
    this.api.getAllSubChapter().subscribe(localName => {
      this.subChaptersNodes = localName['data'];
      console.log(this.subChaptersNodes)
    }, err => {
      console.log(err);
    });
  }
  
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {    
    this.drawerTitle = "Create New Question";
    this.drawerData = new Questionmaster();
    this.drawerVisible = true;
    this.setValues()
    
    this.question1.reset()
    this.logtext = "ADD - Question form KEYWORD [A - Question] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
        }
        else {
        }
      });

  }

  edit(data: Questionmaster): void {

    console.log(data)
    // this.question1.reset()
    this.question1.loadSubChpater()
    
    // if(data.DIRECTION_URL)
    // {
    //   this.question1.isSpinning=true
    //   let TYPED_ARRAY
    //   this.api.getFile(data.DIRECTION_URL).subscribe(data => {
    //     console.log(data)
    
    //     TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
    //     const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    //       return data + String.fromCharCode(byte);
    //     }, '');
    //     let base64String = btoa(STRING_CHAR);
    //     this.imageDescriptionurl = 'data:image/jpg;base64,' + base64String;
    //     this.drawerData.DIRECTION_URL_CONVERTED = this.imageDescriptionurl
    //     this.question1.isSpinning=false

    //   }, err => {
    //     console.log(err);
    //   });

    // }

    // if(data.DESCRIPTION_URL)
    // {
    //   this.question1.isSpinning=true
    //   let TYPED_ARRAY
    //   this.api.getFile(data.DESCRIPTION_URL).subscribe(data => {
    //     console.log(data)
    //     TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
    //     const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    //       return data + String.fromCharCode(byte);
    //     }, '');
    //     let base64String = btoa(STRING_CHAR);
    //     this.imageDescriptionurl = 'data:image/jpg;base64,' + base64String;
    //     this.drawerData.DESCRIPTION_URL_CONVERTED = this.imageDescriptionurl
    //     this.question1.isSpinning=false
    //   }, err => {
    //     console.log(err);
    //   });

    // }

    // if(data.PARAGRAPH_IMAGE_VIDEO_URL)
    // {
    //   this.question1.isSpinning=true
    //   let TYPED_ARRAY
    //   this.api.getFile(data.PARAGRAPH_IMAGE_VIDEO_URL).subscribe(data => {
    //     console.log(data)
    //     TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
    //     const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    //       return data + String.fromCharCode(byte);
    //     }, '');
    //     let base64String = btoa(STRING_CHAR);
    //     this.imageParagraphurl = 'data:image/jpg;base64,' + base64String;
    //     this.drawerData.PARAGRAPH_IMAGE_VIDEO_URL_CONVERTED = this.imageParagraphurl
    //     this.question1.isSpinning=false
    //   }, err => {
    //     console.log(err);
    //   });

    // }

    // if(data.QUESTION_URL)
    // {
    //   this.question1.isSpinning=true
    //   let TYPED_ARRAY
    //   this.api.getFile(data.QUESTION_URL).subscribe(data => {
    //     console.log(data)
    //     TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
    //     const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    //       return data + String.fromCharCode(byte);
    //     }, '');
    //     let base64String = btoa(STRING_CHAR);
    //     this.imageQuestionurl = 'data:image/jpg;base64,' + base64String;
    //     this.drawerData.QUESTION_URL_CONVERTED = this.imageQuestionurl
    //     this.question1.isSpinning=false
    //   }, err => {
    //     console.log(err);
    //   });

    // }

    // if(data.SOLUTION_IMAGE_URL)
    // {
    //   this.question1.isSpinning=true
    //   let TYPED_ARRAY
    //   this.api.getFile(data.SOLUTION_IMAGE_URL).subscribe(data => {
    //     console.log(data)
    //     TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
    //     const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    //       return data + String.fromCharCode(byte);
    //     }, '');
    //     let base64String = btoa(STRING_CHAR);
    //     this.imageSolutionurl = 'data:image/jpg;base64,' + base64String;
    //     this.drawerData.SOLUTION_IMAGE_URL_CONVERTED = this.imageSolutionurl
    //     this.question1.isSpinning=false
    //   }, err => {
    //     console.log(err);
    //   });

    // }

    this.drawerTitle = "Update Question";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.question1.typeChange(data.QUESTION_TYPE_ID)
    this.question1.getOptionList(data.ID)

    this.logtext = "EDIT - Question form KEYWORD [E - Question] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
        }
        else {
        }
      });

  }

  setValues() {
    // this.drawerData.DIRECTION = "No"
    // this.drawerData.DESCRIPTION = "No"
    // this.drawerData.SOLUTION = "No"
    // this.drawerData.SOLUTION_VIDEO_URL = "No"
 
    this.drawerData.RANDOMIZE_OPTIONS = true
    this.drawerData.IS_SEQUENCE_MARKING = true
    this.question1.OptionList=[]
    this.question1.urlList=[]
  }
  
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}