export class TPOMaster {
    ID:number=0;
    ROLE_ID:number=2;
    COLLEGE_ID:number=0;
    COLLEGE_NAME:string="";
    NAME:string="";    
    MOBILE_NO:number;
    EMAIL_ID:string=""; 
    PASSWORD:string='';
    IS_ACTIVE:boolean=true; 
} 

    