import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Section } from 'src/app/Models/MasterSyllabus/section';
import { Part } from 'src/app/Models/MasterSyllabus/part';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService} from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Section;
  isSpinning = false
  logtext: string = "";
  parts: Part[];
  userId = sessionStorage.getItem('userId');
  loadingParts = false
  fileData_ICON_URL: File = null
  fkey = "C7oUsLum"
  partData: Part = new Part()
  partVisible: boolean
  partTitle: string = ""
  //for part drower
  dataList=[]
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  isokfile=true
   data1: Section=new Section();

  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
this.loadParts()
this.loadSections()
  }
  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.fileData_ICON_URL=null
    this.isokfile=true
  }
  loadSections()
  {
    this.api.getAllSections(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataList = data['data'];
      
    }, err => {
      console.log(err);
    });
  }

  addNewPart() {
    this.partVisible = true;
    this.partTitle = "Create New Part";
    this.partData = new Part();
 
    this.setValueOfPart()
  }

  setValueOfPart() {
    this.partData.IS_ACTIVE = true
    this.partData.COLOR_CODE="#ffffff" 
  }

  loadParts() {
    this.loadingParts = true;
    this.api.getAllParts(0, 0, '', '', ' AND IS_ACTIVE=1').subscribe(localName => {
      console.log(localName)
      this.parts = localName['data'];
     this.data.PART_ID=localName['data'][0]['ID']
      this.loadingParts = false;
    }, err => {
      console.log(err);
      this.loadingParts = false;
    });
  }


  partClose(): void {
    this.loadParts() 
    this.partVisible = false;
  }

  get callbackPartClose() {
    return this.partClose.bind(this);
  }

  close(): void {
    this.drawerClose();
    this.logtext = 'CLOSED - Section form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  removeIcon()
  {
    console.log("sad")
    console.log(this.data.ICON_URL)
    this.data.ICON_URL=""
  }

  partChange(partId): void 
  {
    let filter=" AND PART_ID="+partId
    this.api.getAllSections(0, 0, 'ID', 'desc', filter).subscribe(data => {
      if(data['count']>0)
      {
        this.data1 = data['data'][0];
        this.data.SEQUENCE_NO= Number(this.data1.SEQUENCE_NO)+1
        console.log(this.data.SEQUENCE_NO)
      }
      else
      {
        this.data.SEQUENCE_NO=1
      }
    
    }, err => {
      console.log(err);
    });
  }

  save(addNew: boolean): void {
    console.log(this.data)
    if (this.data.PART_ID!=undefined && this.data.NAME!=undefined && this.data.NAME!="" && this.data.WEIGHTAGE!=undefined && this.data.WEIGHTAGE.toString()!="") {
      if(this.data.DESCRIPTION=="")
      this.data.DESCRIPTION=" "
      var returnData=this.similarData()
      if (this.isokfile) {
       
        if(returnData)
        {
          this.isSpinning = true;
      if (this.fileData_ICON_URL) {
      
        var fileExt = this.fileData_ICON_URL.name.split('.').pop();
        var lkey = ""
        this.api.onUploadNewMethod(this.fileData_ICON_URL, fileExt, this.fkey)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == 200) {
              lkey = successCode['data'][0]['L_KEY']
              this.data.ICON_URL = lkey
              this.updateData(addNew)
            }
            else {
              console.log(successCode)
            }
          });
        
      }
      else {
        if(returnData)
        {
        if (this.data.ID)
          this.updateData(false)
        else {
          this.data.ICON_URL = " "
          this.updateData(addNew)
        }
      }
      }
    }


    }
    else
    {
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    }
    }
    else {
      this.message.error("Please Fill All Required Fields", "");
    }
  }

  similarData()
  {
    if (this.data.ID) {
      
      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
      });

      var filterData1= this.dataList.filter(object => {
        return object['SEQUENCE_NO'] == this.data.SEQUENCE_NO && object['ID']!=this.data.ID && object['PART_ID']==this.data.PART_ID
      });

      var filterData2= this.dataList.filter(object => {
        return object['COLOR_CODE'] == this.data.COLOR_CODE && object['ID']!=this.data.ID
      });

      if(filterData.length>0 || filterData1.length>0 || filterData2.length>0)
      {
        if(filterData.length>0)
      this.message.error("Name Already Present in Database", "");

      if(filterData1.length>0)
      this.message.error("Sequence Number Already Present in Database", "");

      if(filterData2.length>0)
      this.message.error("Color Code Already Present in Database", "");
    
      return false

      }
      else
      {
        return true
      }
    }
    else
    {
      var filterData= this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase()
      });

      var filterData1= this.dataList.filter(object => {
        return object['SEQUENCE_NO'] == this.data.SEQUENCE_NO && object['PART_ID']==this.data.PART_ID
      });

      var filterData2= this.dataList.filter(object => {
        return object['COLOR_CODE'] == this.data.COLOR_CODE
      });

      if(filterData.length>0 || filterData1.length>0 ||  filterData2.length>0)
      {
        if(filterData.length>0)
        this.message.error("Name Already Present in Database", "");

      if(filterData1.length>0)
      this.message.error("Sequence Number Already Present in Database", "");

      if(filterData2.length>0)
      this.message.error("Color Code Already Present in Database", "");
        
      return false
      }
      else
      {
        return true
      }
    }

  }

  updateData(addNew: boolean)
  {
    if (this.data.ID) {
      
      if(this.data.ICON_URL=="")
      this.data.ICON_URL=" "

      this.api.updateSection(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.partChange(this.data.PART_ID)
            this.message.success("Section information updated Successfully...", "");

            this.logtext = 'Update & Close - Section form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Section ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            if (!addNew)
              this.drawerClose();
              
            this.isSpinning = false;
          }
          else {

            this.logtext = 'Update & Close - Section form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Section ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            this.message.error("Failed to update section information...", "");
            this.isSpinning = false;
          }
        });
      
    }
    else {      
      this.api.createSection(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Section information added successfully...", "");
            this.partChange(this.data.PART_ID)
            if (!addNew) {
              this.drawerClose();
            
              this.logtext = 'Save & Close - Section form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Section ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }
            else {
              //this.data = new Section();
              this.setValue()
             // this.loadParts()
             
              this.reset()
              this.logtext = 'Save & New - Section form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Section ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add section information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - Section form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Section ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
        });
    }
  }

  getUrl(url)
  {
    
        var newTab = window.open();
        newTab.document.body.innerHTML = '<img src="' + url +'" width="500px" height="100%" style="left: 408px;position: absolute;"> ';
    
  }

  setValue()
  {
    this.data.IS_ACTIVE=true;
    this.data.COLOR_CODE="#ffffff" 
    this.data.NAME=""
    this.data.WEIGHTAGE=undefined
    this.data.SEQUENCE_NO=undefined
    this.data.DESCRIPTION=" "
    
  }

  onFileSelected_ICON_URL(event) {
    this.isokfile = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!allowed_types.toString().match(event.target.files[0].type)) {
      this.isokfile = false
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    }

    if (this.isokfile) {
      this.fileData_ICON_URL = <File>event.target.files[0];
    }
    
    console.log(this.fileData_ICON_URL)
  }


}