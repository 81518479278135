import { Component, OnInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BundleMapping } from 'src/app/Models/AssessmeentModels/bundlemapping';
import { BundleMaster } from 'src/app/Models/AssessmeentModels/bundlemaster';
import { CollegeMap } from 'src/app/Models/AssessmeentModels/collegemap';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-bundlemappinglist',
  templateUrl: './bundlemappinglist.component.html',
  styleUrls: ['./bundlemappinglist.component.css']
})
export class BundlemappinglistComponent implements OnInit {

  drawerVisible!: boolean;
  drawerVisible2!: boolean;
  drawerVisible3!: boolean;
  drawerTitle!: string;
  drawerData: BundleMapping = new BundleMapping();
  drawerData3: any = new CollegeMap();
  formTitle = " Bundle Mapping List ";
  dataList :any []= [];
  loadingRecords = true;
  isSpinning = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  collegeTotalRecords = 1;
  collegePageIndex = 1;
  collegePageSize = 10;
  sortValue: string = "desc"; 
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  checkedAssess = false;
  indeterminateAssess = false;
  setOfCheckedIdAssess = new Set<number>();
  columns: string[][] = [["BUNDLE_NAME", " Bundle Name "],["TYPE_NAME", " Type Name "],
  ["START_DATE", " Start Date "],["END_DATE", " End Date "],["PRICE", " Price "]] 
  
  constructor( public api: ApiService, ) { }

  ngOnInit(): void {
  // this.loadingRecords = false;
  // this.search();

  }

  keyup(event:any) {
    this.search();
  }  
  
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api.getAllBundleMap(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      // if(this.totalRecords==0){
      //   data.SEQUENCE_NO=1;
      // }else{
      //   data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
      // }
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = " Map New Bundle ";
    this.drawerData = new BundleMapping();
    // this.api.getAllwebsiteBanner(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
    //   if (data['count']==0){
    //     this.drawerData.SEQUENCE_NO=1;
    //   }else
    //   {
    //     this.drawerData.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
    //   }
    // },err=>{
    //   console.log(err);
    // })
    // this.drawerData.STATUS=true;
    this.drawerVisible = true;
  }
  edit(data: BundleMapping): void {
    this.drawerTitle = " Update Mapped Bundle ";
    this.drawerData = Object.assign({}, data);
    console.log('Data',this.drawerData);
    
    this.drawerVisible = true;
  }


  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  collegeName = [];
  enddate
  startdate
  mapcollege(data2: BundleMaster): void {
    this.isSpinning = true;
    // console.log("id:", data2);
    this.drawerTitle = " Map College ";
    // this.drawerData = new BundleMaster();
    this.drawerData3 = Object.assign({}, data2);
    // localStorage.setItem("startdate",this.drawerData3.START_DATE)
    // localStorage.setItem("enddate",this.drawerData3.END_DATE)

    this.startdate = new Date(this.drawerData3.START_DATE)
    this.enddate =  new Date(this.drawerData3.END_DATE)
    console.log('this.startdate',this.startdate);
    console.log('this.enddate',this.enddate);    
    this.collegeName = [];
    this.api.getAllMappedCollege(this.collegePageIndex,this.collegePageSize,'', '',this.drawerData3.ID,'').subscribe(
      (data) => {
        this.isSpinning = false;
        this.collegeTotalRecords = data['count'];
        this.collegeName = data['data'];
        this.collegeName.forEach((item) => {
          if (item.IS_ACTIVE) {
            this.setOfCheckedIdAssess.add(item.COLLEGE_ID);
          } else {
            this.setOfCheckedIdAssess.delete(item.COLLEGE_ID);
          }
        });
        this.checkedAssess = this.collegeName.every((item) => {
          this.setOfCheckedIdAssess.has(item.COLLEGE_ID);
        });
        this.indeterminateAssess =
          this.collegeName.some((item) =>
            this.setOfCheckedIdAssess.has(item.COLLEGE_ID)
          ) && !this.checkedAssess;
        // this.collegeName = data["data"];
      },
      (err) => {
        console.log(err);
      }
    );
    this.drawerVisible3 = true;
  }
  drawerClose3(): void {
    this.search();
    this.drawerVisible3 = false;
  }

  get closeCallback3() {
    return this.drawerClose3.bind(this);
  }
}

