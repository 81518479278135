export class StudentMapping {
    ID:number=0;
    COLLEGE_ID:number=0;
    BRANCH_ID:number=0;
    YEAR_ID:number=0;
    STUDENT_ID:any=[];
    MAP_TYPE:string="";
    START_TIME:any;
    MAP_TIME:any;
    AMOUNT:number=0; 


    BUNDLE_ID:number=0;
    BUNDLE_MAPPING_ID:number=0;
    
    BUNDLE_COLLGE_MAPPING_ID:number=0;
    CART_ID:number=0;
    IS_STARTED:boolean=false
    STARTED_TIME:any=new Date();
    MAPPED_TIME :any=new Date();
    IS_ACTIVE:any

    BUNDLE_STUDENT_MAPPING_ID:number=0
} 

 