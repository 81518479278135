import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeQuestion'
})
export class ChangeQuestionPipe implements PipeTransform {

  transform(value: string): string {
    return value? value.replace(/&#91;/g, '[')
    .replace(/''/g,'\\n' )
      .replace(/''/g, '\\t')
      
      .replace(/&#34;/g, '\\"')
      .replace(/&#39;/g, "\\'")
  .replace(/&#34;/g, '\"')
     
  .replace(/&#39;/g,"'")
  .replace(/&#39;/g,'’')
  .replace(/&#39;/g,'’')
  .replace(/&#39;/g,'`')
  .replace(/&#39;/g,"'")
  .replace(/&#39;/g,'‘')
      .replace(/&#34;/g,'"')
  .replace(/&#34;/g,'"')
  .replace(/&#34;/g,'“')
  .replace(/&#34;/g,'”')
  .replace(/&#34;/g,'"')
      
      .replace(/&#93;/g, '/]')
    : value;
  }

}
