import { Component, OnInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { TPOMaster } from 'src/app/Models/AssessmeentModels/tpomaster';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { ApiService } from 'src/app/Service/api.service';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';

@Component({
  selector: 'app-tpolist',
  templateUrl: './tpolist.component.html',
  styleUrls: ['./tpolist.component.css']
})
export class TpolistComponent implements OnInit {

  drawerVisible!: boolean; 
  drawerTitle!: string;
  drawerData: TPOMaster = new TPOMaster();
  formTitle = " TPO List ";
  dataList :any []= [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc"; 
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  listdata2 = []
  collegeName: College[] = [];
  columns: string[][] = [["COLLEGE_NAME", " College Name "],["NAME", " TPO Name "],
        [" MOBILE_NO "," Contact Number "],["EMAIL_ID "," Email ID "],[" PASSWORD"," Password"]]

  constructor( public api: ApiService, public api2:ShikuyaaapiService,) { }

  ngOnInit(): void {
  this.loadingRecords = false;
  this.search();

  }

  keyup(event:any) {
    this.search();
  }  
  
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api.getAllTPO(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      // if(this.totalRecords==0){
      //   data.SEQUENCE_NO=1;
      // }else{
      //   data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
      // }
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = " Add New TPO ";
    this.drawerData = new TPOMaster();
    this.api2.getAllStudentColleges(0, 0, '', ' desc', '').subscribe(
      (datas) => {
        var datacode
        datacode = datas['code'];
// console.log("code:",datacode);

        if(datacode=="200")
        {
        // this.api.getAllcouponuseddetailedreport(0,0,'','',' AND STATUS=1').subscribe(data =>{
        this.collegeName = datas['data'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.drawerVisible = true;
  }
  edit(data: TPOMaster): void {
    this.drawerTitle = " Update TPO Information";
    this.drawerData = Object.assign({}, data);
    this.api.getAllTPO(0, 0, 'ID', 'desc', " AND ID !=" + data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log('this.listdata2',this.listdata2)
      }
    }, err => {
      console.log(err);
    });
    this.drawerVisible = true;
  }


  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

 
}

