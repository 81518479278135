import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Videoquestiontype } from 'src/app/Models/ShikuyaaModels/videoquestiontype';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-videoquestiontype',
  templateUrl: './videoquestiontype.component.html',
  styleUrls: ['./videoquestiontype.component.css']
})
export class VideoquestiontypeComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Videoquestiontype;
  isSpinning = false
  logtext: string = "";
  dataList=[]


  constructor(public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
this.loadVideoQuestionTypes()
  }

  loadVideoQuestionTypes() {
    this.api.getAllVideoquestionType(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  close(): void {
    this.drawerClose();
    this.logtext = 'CLOSED - VideoQuestionType form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  save(addNew: boolean): void {

    if(this.data.NAME!=undefined && this.data.NAME!="" )
{
  if (/^[a-zA-Z0-9\s-,._-]*$/.test(this.data.NAME) == true) {

    

  

    if (this.data.ID) {

      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
      });

      if (filterData.length > 0) {
        this.message.error("Name Already Record Present in Database", "");
      }
      else {
        this.isSpinning = true;
      this.api.updateVideoQuestionType(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("VideoQuestionType information updated Successfully...", "");

            this.logtext = 'Update & Close - VideoQuestionType form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - VideoQuestionType ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {

            this.logtext = 'Update & Close - VideoQuestionType form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - VideoQuestionType ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            this.message.error("Failed to update videoQuestionType information...", "");
            this.isSpinning = false;
          }
        });
      
      }
    
      }
    else {
      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
      });

      if (filterData.length > 0) {
        this.message.error("Name Already Record Present in Database", "");
      }
      else {
        this.isSpinning = true;

      this.api.createVideoQuestionType(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Video Question Type information added successfully...", "");

            if (!addNew) {
              this.drawerClose();

              this.logtext = 'Save & Close - VideoQuestionType form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - VideoQuestionType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }

            else {
              this.data = new Videoquestiontype();
              this.logtext = 'Save & New - VideoQuestionType form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - VideoQuestionType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add videoQuestionType information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - VideoQuestionType form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - VideoQuestionType ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

          }
        });
      }
      }
  }
  else
  {
    this.message.error("Name is invalid value", "");
  }
  }
  else
  {
    this.message.error("Please Enter Name", "");

  }

  }


}