import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Coupan } from 'src/app/Models/ShikuyaaModels/coupan';
import { Coupanfacility } from 'src/app/Models/ShikuyaaModels/coupanfacility';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-couponfacilitymapping',
  templateUrl: './couponfacilitymapping.component.html',
  styleUrls: ['./couponfacilitymapping.component.css']
})
export class CouponfacilitymappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Coupan;
  @Input() couponFacilityMappingData: string[];
  @Input() drawerVisible: boolean;
  @Input() dataList: Coupanfacility[] = [];
  isSpinning = false
  logtext:string=""
  searchText:string=""
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  constructor(public api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {

  }

  close(): void {
    this.drawerClose();
  }

  save()
  {
  
    this.isSpinning = true;
      this.api.addFacilityMapping(this.data.ID,this.couponFacilityMappingData)
      .subscribe(successCode => {
       
        if(successCode['code']==200)
        { 
            this.message.success("Coupon Facility Details added Successfully ...", "");
            this.drawerClose();
            this.isSpinning = false;
            this.logtext = 'Save & Close - couponFacilityMappingData form - SUCCESS - ' + "CouponId="+this.data.ID+" "+ JSON.stringify(this.couponFacilityMappingData) + " KEYWORD [C - couponFacilityMappingData ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
        }
        else
        {
          this.message.error("Coupon Facility Details assigning Failed...", "");
          this.isSpinning = false;
          this.logtext = 'Save & Close - Failed To Map couponFacilityMappingData form - ERROR - ' + "CollegeId="+this.data.ID+" "+ JSON.stringify(this.couponFacilityMappingData) + " KEYWORD [F- couponFacilityMappingData ]";
          this.api.addLog('A', this.logtext, this.api.emailId)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                console.log(successCode);
              }
              else {
                console.log(successCode);
              }
            });
        }
      });
  }

  onItemChecked(id: number, checked: boolean, ind: any): void {
    console.log(id,ind,checked,'ggg')
    if (checked) {
      this.dataList[ind].IS_ACTIVE = true
    } else {
      this.dataList[ind].IS_ACTIVE = false
    }
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.dataList.forEach(item => {
      if (value) {
        item.IS_ACTIVE = true
      } else {
        item.IS_ACTIVE = false
      }
      this.updateCheckedSet(item.ID, value)
    });
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    console.log(id,checked,'ggg2')
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.dataList.every(item => {
      // console.log(this.setOfCheckedId.has(item.COLLEGE_ID),'ggg33')
      this.setOfCheckedId.has(item.ID)
    });
    this.indeterminate = this.dataList.some(item => this.setOfCheckedId.has(item.ID)) && !this.checked;
  }
}
