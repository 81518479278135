import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Questiontype } from 'src/app/Models/MasterSyllabus/questiontype';
import { CookieService } from 'ngx-cookie-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-questiontype',
  templateUrl: './questiontype.component.html',
  styleUrls: ['./questiontype.component.css']
})
export class QuestiontypeComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Questiontype;
  isSpinning = false
  logtext: string = "";
  userId = sessionStorage.getItem('userId');
dataList=[]
  constructor(public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
this.loadAllQuestionTypes()
  }

loadAllQuestionTypes()
{
  this.api.getAllQuestionTypes(0, 0, 'ID', 'asc', '').subscribe(data => {
    this.dataList = data['data'];
  }, err => {
    console.log(err);
  });
}

  // close(): void {
  //   this.drawerClose();

  //   this.logtext = 'CLOSED - QuestionType form';
  //   this.api.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }
////

resetDrawer(queTypeMasterPage: NgForm) {
  this.data=new Questiontype();
  // queTypeMasterPage.form.reset();

  queTypeMasterPage.form.markAsPristine();
  queTypeMasterPage.form.markAsUntouched();
}

close(queTypeMasterPage: NgForm) {
  this.drawerClose();
  this.resetDrawer(queTypeMasterPage);
  this.logtext = 'CLOSED - QuestionType form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

}
//save
save(addNew: boolean,queTypeMasterPage: NgForm): void {


    if(this.data.NAME!=undefined && this.data.NAME!="")
    {
      if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true && /^[a-zA-Z\s-,._-]*$/.test(this.data.DESCRIPTION) == true) 
      {
        if(this.data.DESCRIPTION=="")
        this.data.DESCRIPTION=" "
  
      if (this.data.ID) {
  
        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
        });
  
  
        if(filterData.length>0)
        {
          if(filterData.length>0)
        this.message.error("Name Already Present in Database", "");
        }
        else
        {
          this.isSpinning = true;
  
        this.api.updateQuestionType(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("QuestionType information updated Successfully...", "");
  
              this.logtext = 'Update & Close - QuestionType form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - QuestionType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
  
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
  
              this.logtext = 'Update & Close - QuestionType form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - QuestionType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
  
              this.message.error("Failed to update questionType information...", "");
              this.isSpinning = false;
            }
          });
        }
      }
      else {

        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() 
        });
        if(filterData.length>0)
        {
          if(filterData.length>0)
        this.message.error("Name Already Present in Database", "");
        }
        else
        {
          this.isSpinning = true;
        this.api.createQuestionType(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("QuestionType information added successfully...", "");
  
              if (!addNew) {
                this.drawerClose();
  
                this.logtext = 'Save & Close - QuestionType form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionType ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.data = new Questiontype();
                this.resetDrawer(queTypeMasterPage);
                this.loadAllQuestionTypes()
                this.setValues()
                this.logtext = 'Save & New - QuestionType form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionType ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
  
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add questionType information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - QuestionType form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionType ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
  
            }
          });
        }
      }
  
      }
      else
      {
        if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == false)
        this.message.error("Please Check Name ", "");
        else  if(/^[a-zA-Z\s-,._-]*$/.test(this.data.DESCRIPTION) == false)
        this.message.error("Please Check Description ", "");
  
  
      }
    }
    else
    {
    this.message.error("Please Fill All Required Fields", "");

    }


    

  

  }
  setValues() {
    this.data.IS_ACTIVE = true
  }

}