import { Component, OnInit, ViewChild } from '@angular/core';
import { MappedassignmentComponent } from '../mappedassignment/mappedassignment.component';
import { ApiService } from 'src/app/Service/api.service';
import { Mappedassignment } from 'src/app/Models/AssignmentModule/mappedassignment';
import { CurrentstatusComponent } from '../../currentstatus/currentstatus.component';
import { ResultComponent } from '../../result/result.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-mappedassignments',
  templateUrl: './mappedassignments.component.html',
  styleUrls: ['./mappedassignments.component.css']
})
export class MappedassignmentsComponent implements OnInit {

  formTitle = "Manage Mapped Assignments";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  collegeId = sessionStorage.getItem('collegeId')

  logtext: string = "";
  filterClass: string = "filter-invisible";
  STATUS = "U"
  SCOPE="O"
  ismapSpinning=false
  mappingName:string=""
  columns: string[][] = [["NAME", "Assignment Name"],["MAPPING_NAME", "Mapping Name"],["PASSKEY", "Passkey"], ["ENABLE_TIME", "Enable Time"],['DISABLE_TIME','Disable Time'], ['PUBLISH_TIME','Publish Time'],["POSITIVE_MARKS", "Positive Marks"], ["NEGATIVE_MARKS", "Negative Marks"], ["TOTAL_QUESTIONS", "Total Questions"]]
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Mappedassignment = new Mappedassignment();
  visible=false
  visible1=false
  visible2=false

  currentStatusVisible: boolean;
  currentStatusTitle: string;
  resultVisible: boolean;
  resultTitle: string;

  @ViewChild(MappedassignmentComponent, { static: false }) assignmentAdd: MappedassignmentComponent;

  @ViewChild(CurrentstatusComponent, { static: false }) currentstatus: CurrentstatusComponent;
  
  @ViewChild(ResultComponent, { static: false }) resultShow: ResultComponent;
  pageSize2=10
  
  constructor(public api: ApiService) { }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null || this.collegeId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.search();
      this.logtext = "OPENED - Assignments form KEYWORD[O - Assignments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }

  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  setToDefault() {
    this.mappingName = ""
   
  }

  getTotal(timeinseconds)
  {
    return this.api.getSecondsToHms(timeinseconds)
  }

  check(data) {
    var name = data.MAPPING_NAME ;
    if (this.mappingName == name) {
      this.mappingName = name;
      return false;
    }
    else {
      this.mappingName = name;
      return true;
    }

  }

  getMappingNameCount(data, name)
  {
    return data.filter(object => {
      return object['MAPPING_NAME'] == name;
    });
  
  }

  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Assignments form "+ sort +" "+this.sortKey +" KEYWORD [F - Assignments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND COLLEGE_ID="+ this.collegeId +"(";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)+")"
      console.log("likeQuery" + likeQuery);
      this.logtext = "Filter Applied - Assignments form "+ likeQuery +" KEYWORD [F - Assignments] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });
    }
    else
    {
      likeQuery=" AND COLLEGE_ID="+ this.collegeId
    }

   

    // this.applyFilter()
    // var filter = ""
    // if (likeQuery)
    //   filter = this.filterQuery + likeQuery
    // else
    
    //   filter = this.filterQuery
    this.visible=false
    this.visible1=false
    this.visible2=false
if(this.STATUS=="U")
  this.visible=true
else if(this.STATUS=="O")
  this.visible1=true
  else if(this.STATUS=="C")
  this.visible2=true

this.ismapSpinning=true
    this.api.getAllCombinedMappingData(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery,this.SCOPE,this.STATUS,Number(this.collegeId)).subscribe(data => {
     console.log(data['data'])
      this.loadingRecords = false;
      this.ismapSpinning=false
      this.filterClass= "filter-invisible";
      this.isFilterApplied= "primary";
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.STATUS = "U"
    this.SCOPE = "S"
    this.search()
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  
  add(): void {
    this.drawerTitle = "Create New Assignment";
    this.drawerData = new Mappedassignment();
    this.assignmentAdd.current = 0
    this.drawerVisible = true;

    this.logtext = "ADD - Assignment form KEYWORD [A - Assignment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  edit(data: Mappedassignment): void {
    data.RESULT_SHARING_TIME=data.MAPPED_ASSIGNMENT_MASTER_RESULT_SHARING_TIME
    data.ID=data.MAPPED_ASSIGNMENT_MASTER_ID
    console.log(data)
    this.drawerTitle = "Update Assignment (" + data.NAME + ")";
    this.drawerData = Object.assign({}, data);

    if (this.drawerData.PLATFORM == 'B')
      this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
      { label: 'Web', value: 'W', checked: true },]
    else if (this.drawerData.PLATFORM == 'M')
      this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: true },
      { label: 'Web', value: 'W', checked: false },]
    else
      this.drawerData.platforms = [{ label: 'Mobile', value: 'M', checked: false },
      { label: 'Web', value: 'W', checked: true },]

    this.drawerVisible = true;

    this.assignmentAdd.current = 0

    this.logtext = "EDIT - Assignment form KEYWORD [E - Assignment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }


  mapping(data: Mappedassignment) {
    this.drawerTitle = "Map Assignment (" + data.NAME + ")";
    this.drawerVisible = true;
    this.assignmentAdd.current = 3
    this.assignmentAdd.NAME = ""
    this.assignmentAdd.assignmentId = data.MAPPED_ASSIGNMENT_MASTER_ID
    this.assignmentAdd.getDeatilsData()
    this.logtext = "Mapping - Assignment form KEYWORD [M - Assignment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
   
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  currentStatusClose(): void {
    this.search();
    this.currentStatusVisible = false;
  }
  get currentStatuscloseCallback() {
    return this.drawerClose.bind(this);
  }
  currentStatus(data: Mappedassignment)
  {
    this.currentStatusTitle = "Current Assignment (" + data.NAME + ")";
    this.drawerData = Object.assign({}, data);
    this.currentstatus.getassignmentId(data.MAPPED_ASSIGNMENT_MASTER_ID)
    this.currentStatusVisible=true
  }
  resultClose(): void {
    this.search();
    this.resultVisible = false;
  }
  get resultcloseCallback() {
    return this.drawerClose.bind(this);
  }

  result(data: Mappedassignment)
  {
    console.log(data)
    this.resultTitle = "Result of Assignment (" + data.NAME + ")";
    this.drawerData = Object.assign({}, data);
   this.resultShow.getassignmentId(data.MAPPED_ASSIGNMENT_MASTER_ID)
    this.resultVisible=true
  }


  
}
