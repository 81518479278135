import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { Shikuyaachapter } from 'src/app/Models/ShikuyaaModels/shikuyaachapter';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-shikuyaachapter',
  templateUrl: './shikuyaachapter.component.html',
  styleUrls: ['./shikuyaachapter.component.css'],
  providers: [DatePipe]

})
export class ShikuyaachapterComponent implements OnInit {
  COURSE_ID=0
  @Input() drawerClose: Function;
  @Input() data: Shikuyaachapter;
  @Input() checks:any=''
  @Input() checks1:any=''
  @Input() courseName:any
 
  @Input() catName:any
  isSpinning = false
  logtext: string = "";

  parentChapters: Shikuyaachapter[];
  chapters: Shikuyaachapter[]
  courseNodes = []
  dataList = []
  fileDataTHUMBNAIL_URL: File = null
  fkey = "DZLQ5Nrc"
  isokfile = true
  ID = 0
  NONE_VALUE = "None"
  thumbnailImgHeight: number
  thumbnailImgWeight: number
  isheightwidth: boolean
  folderName = "CChapterThumbnail"
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  courseId: number
  isLeaf
  TITLE
  CONTENTS
  STATUS = false
  chapterBaseData = []
  i = 2
  index = -1
  levels: Level[]
  loadingChapterBase = false
  constructor(public api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) {
  }
  levels1
  loadingLevels = false
  loadCategories()
  {
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log("courses")
      // console.log(this.levels)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
  }
  loadCourses(event:any) {

    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+event).subscribe(localName => {
      this.levels = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  ngOnInit() {
    this.loadCategories()
    this.loadShikuyaaChapters()
  }
  back1()
  {
    this.checks1=''
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+this.data.COURSE_SUB_CATEGORY_ID).subscribe(localName => {
      this.levels1 = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  back()
  {
    this.checks=''
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels = localName['data'];
      console.log('levels',this.levels1 )
      // console.log(this.levels)
      // this.loadingLevels = false;
      // this.data.COURSE_SUB_CATEGORY_ID=this.data.COURSE_SUB_CATEGORY_ID
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });

  }

not:boolean=false
  loadShikuyaaChapters() {
    this.api.getAllShikuyaaChapters(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.dataList = data['data'];
      this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO']
      this.data.SEQUENCE_NO = Number(this.dataList[0]['SEQUENCE_NO']) + 1
    }, err => {
      console.log(err);
    });
  }

  ChangeDependantName(val) { }
  reset() {
    this.myInputVariable.nativeElement.value = null;
    this.fileDataTHUMBNAIL_URL = null
    this.isokfile = true
    console.log(this.data)

  }

  loadChapterKnowledgeBase(data) {
    this.loadingChapterBase = true
    console.log(data.ID)
    this.api.getAllChapterKnowledgeBase(0, 0, 'ID', 'desc', " AND CHAPTER_ID=" + data.ID).subscribe(data => {
      this.loadingChapterBase = false

      console.log(data)
      this.chapterBaseData = data['data'];
    }, err => {
      console.log(err);
    });
  }

  // loadCourses() {

  //   // this.isSpinning = true;
  //   // this.api.getAllCourses(0,0,'','','AND IS_ACTIVE=1').subscribe(localName => {
  //   //   this.courseNodes = localName['data'];
  //   //   console.log("courses")
  //   //   console.log(this.courseNodes)
  //   //   this.isSpinning = false;
  //   // }, err => {
  //   //   console.log(err);
  //   //   this.isSpinning = false;
  //   // });



  //   this.isSpinning = true;
  //   this.api.getCombinedCourses().subscribe(localName => {
  //     this.courseNodes = localName['data'];
  //     console.log("courses")
  //     console.log(this.courseNodes)
  //     this.isSpinning = false;
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });

  // }

  ChangeCourse(courseId) {
    this.courseId = courseId
    this.loadParentchapters(" and COURSE_ID=" + courseId + " AND IS_LEAF=0")
    this.loadDependentchapters(" and COURSE_ID=" + courseId)
  }



  loadParentchapters(filter1) {

    //let filter = filter1
    this.api.getParentChaptersNews(this.courseId).subscribe(localName => {
      console.log("parent Form")
      if(localName['count']==0)
      {
        this.message.error('No Chapetrs Found','')
      }
      else{
        this.parentChapters = localName['data'];
      console.log(this.parentChapters)
      }
      

    }, err => {
      console.log(err);
    });
  }

  loadDependentchapters(filter1) {
    console.log(filter1)

    let filter = filter1
   
    this.api.getParentChaptersNews(this.courseId).subscribe(localName => {
      if(localName['count']==0)
      {
        this.message.error('No Chapetrs Found','')
      }
      else{
        this.chapters = localName['data'];
        console.log(this.chapters)
      }
     
    }, err => {
      console.log(err);
    });

    this.api.getAllShikuyaaChapters(0, 0, '', '', filter).subscribe(localName => {
      this.dataList = localName['data']
    }, err => {
      console.log(err);
    });
  }


  close(): void {
    this.drawerClose();
    this.logtext = 'CLOSED - Chapter form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  similarData() {
    if (this.data.ID) {

      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID && object['PARENT_ID'] == this.data.PARENT_ID
      });
      var filterData1 = this.dataList.filter(object => {
        return  object['SEQUENCE_NO'] == this.data.SEQUENCE_NO
      });
     
      if (filterData.length > 0) {
        this.message.error("Name Already Present in Database", "");
        return false
      }
      else if(filterData1.length > 0)
      {
        this.message.error("Sequence number already assigned to chapter", "");
        return false
      }
      else {
        return true
      }
    }
  
    else {
      var filterData = this.dataList.filter(object => {
        return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['PARENT_ID'] == this.data.PARENT_ID
      });
      var filterData1 = this.dataList.filter(object => {
        return  object['SEQUENCE_NO'] == this.data.SEQUENCE_NO
      });
     
      if (filterData.length > 0) {
        this.message.error("Name Already Present in Database", "");
        return false
      }
      else if(filterData1.length > 0)
      {
        this.message.error("Sequence number already assigned to chapter", "");
        return false
      }
      else {
        return true
      }
     
    }
  }


  // save(addNew: boolean): void {
  //   console.log(this.data)
  //   var is_ok = true
  //   if (this.data.IS_DEPEND) {
  //     if (this.data.DEPENDED_CHAPTER_ID == undefined || this.data.DEPENDED_CHAPTER_ID == 0) {
  //       is_ok = false
  //     }
  //   }
  //   else {
  //     this.data.DEPENDED_CHAPTER_ID = 0
  //   }

  //   if (this.data.COURSE_ID != undefined && this.data.PARENT_ID != undefined && this.data.NAME != "" && this.data.NAME != undefined && is_ok) {
  //     if (/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true) {


  //       var returnData = this.similarData()

  //       if (this.isokfile) {
  //         if (returnData) {
  //           this.isSpinning = true;
  //           if (this.fileDataTHUMBNAIL_URL) {

  //             var fileExt = this.fileDataTHUMBNAIL_URL.name.split('.').pop();
  //             var lkey = ""
  //             this.api.onUploadNewMethod(this.fileDataTHUMBNAIL_URL, fileExt, this.fkey)
  //               .subscribe(successCode => {
  //                 console.log(successCode)
  //                 if (successCode['code'] == 200) {
  //                   lkey = successCode['data'][0]['L_KEY']
  //                   this.data.THUMBNAIL_URL = lkey
  //                   this.updateData(addNew)
  //                 }
  //                 else {
  //                   console.log(successCode)
  //                 }
  //               });

  //           }
  //           else {

  //             this.isSpinning = true;
  //             if (this.data.ID)
  //               this.updateData(false)
  //             else {
  //               this.data.THUMBNAIL_URL = ""
  //               this.updateData(addNew)
  //             }
  //           }
  //         }

  //       }
  //       else {
  //         this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
  //       }
  //     }
  //     else {
  //       this.message.error("Name is invalid value", "");
  //     }
  //   }
  //   else {
  //     this.message.error("Please Fill All Required Fields", "");
  //   }

  // }

  save(addNew: boolean): void {
    console.log(this.data)
    var is_ok = true
    if (this.data.IS_DEPEND) {
      if (this.data.DEPENDED_CHAPTER_ID == undefined || this.data.DEPENDED_CHAPTER_ID == 0) {
        is_ok = false
      }
    }
    else {
      this.data.DEPENDED_CHAPTER_ID = 0
    }

    if (this.data.COURSE_ID != undefined && this.data.PARENT_ID != undefined && this.data.NAME != "" && this.data.NAME != undefined && is_ok) {
      if (/^[a-zA-Z0-9\s-,&._-]*$/.test(this.data.NAME) == true) {

        console.log(this.data.NAME);
      

        if (this.isokfile) {
         
            this.isSpinning = true;


            if (this.data.DESCRIPTION == "")
              this.data.DESCRIPTION = " "


            this.data.CONTENTS = this.chapterBaseData
            console.log(this.data)

            if (this.data.ID) {



              if (this.fileDataTHUMBNAIL_URL) {
                if (this.data.THUMBNAIL_URL == "") {
                  this.data.THUMBNAIL_URL = this.genarateKeyThumbnail_URL();
                }
                else {
                  console.log("update")
                  var str = this.data.THUMBNAIL_URL.substring(this.data.THUMBNAIL_URL.lastIndexOf('/') + 1).split('.')
                  var fileExt = this.fileDataTHUMBNAIL_URL.name.split('.').pop();
                  var url = str[0] + "." + fileExt;
                  console.log(this.fileDataTHUMBNAIL_URL)
                  this.api.onUpload(this.folderName, this.fileDataTHUMBNAIL_URL, url)
                  // this.data.THUMBNAIL_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
                  this.data.THUMBNAIL_URL = url;

                }
              }
              else {
                this.data.THUMBNAIL_URL = ""
              }


              this.api.updateShikuyaaChapter(this.data)
                .subscribe(successCode => {
                  console.log(successCode)
                  if (successCode['code'] == "200") {
                    this.message.success("Chapter information updated Successfully...", "");

                    this.logtext = 'Update & Close - Chapter form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Chapter ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });

                    if (!addNew)
                      this.drawerClose();
                    this.isSpinning = false;
                  }
                  else {

                    this.logtext = 'Update & Close - Chapter form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Chapter ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });

                      this.message.error(successCode['message'], "");
                    this.isSpinning = false;
                  }
                });
            }
            else {

              if (this.fileDataTHUMBNAIL_URL) {
                this.data.THUMBNAIL_URL = this.genarateKeyThumbnail_URL()
                console.log(this.data.THUMBNAIL_URL)
              }
              else {
                this.data.THUMBNAIL_URL = "";
              }
              var returnData = this.similarData()
              if (returnData) {
                this.api.createShikuyaaChapter(this.data)
                .subscribe(successCode => {
                  console.log(successCode)

                  if (successCode['code'] == "200") {
                    this.message.success("Chapter information added successfully...", "");
                    this.api.getParentChapters1(this.courseId).subscribe(localName => {
                      console.log("parent Form")
                
                      this.parentChapters = localName['data'];
                      console.log(this.parentChapters)
                
                    }, err => {
                      console.log(err);
                    });
                    if (!addNew) {
                      this.drawerClose();

                      this.logtext = 'Save & Close - Chapter form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });
                    }

                    else {
                      //this.data = new Shikuyaachapter();
                      this.data.IS_LEAF=false
                      this.data.NAME=""
                      this.data.DESCRIPTION=""
                      this.data.THUMBNAIL_URL=""
                      this.data.IS_ACTIVE=true
                      this.data.IS_DEPEND=false
                      this.data.DEPENDED_CHAPTER_ID=undefined
                      this.data.IS_PRACTICE_FREE=false
                      this.data.CONTENTS=[]
                      this.data.SEQUENCE_NO=undefined

                      this.logtext = 'Save & New - Chapter form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
                      this.api.addLog('A', this.logtext, this.api.emailId)
                        .subscribe(successCode => {
                          if (successCode['code'] == "200") {
                            console.log(successCode);
                          }
                          else {
                            console.log(successCode);
                          }
                        });

                    }
                    this.isSpinning = false;
                  }
                  else {
                    this.message.error(successCode['message'], "");
                    this.isSpinning = false;
                    this.logtext = 'Save & Close - Chapter form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
                    this.api.addLog('A', this.logtext, this.api.emailId)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log(successCode);
                        }
                        else {
                          console.log(successCode);
                        }
                      });

                  }
                });
              }
              
            }
          
        }
        else {
          this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
        }
      }
      else {
        this.message.error("Name is invalid value", "");
      }
    }
    else {
      if (this.data.COURSE_ID == undefined)
        this.message.error("Please Select Course Name", "");
      else if (this.data.PARENT_ID == undefined)
        this.message.error("Please Select Parent Name", "");
      else if (this.data.NAME == undefined || this.data.NAME == "")
        this.message.error("Please Enter Name", "");
    }

  }

  genarateKeyThumbnail_URL() {
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.fileDataTHUMBNAIL_URL.name.split('.').pop();
    var url = this.date1 + number + "." + fileExt
    console.log("dsf")
    console.log(this.fileDataTHUMBNAIL_URL)
    this.api.onUpload(this.folderName, this.fileDataTHUMBNAIL_URL, url)
    // this.data.THUMBNAIL_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
    this.data.THUMBNAIL_URL = url;

    return this.data.THUMBNAIL_URL
  }


  updateData(addNew: boolean) {
    if (this.data.DESCRIPTION == "")
      this.data.DESCRIPTION = " "


    if (this.data.ID) {


      this.api.updateShikuyaaChapter(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.message.success("Chapter information updated Successfully...", "");

            this.logtext = 'Update & Close - Chapter form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Chapter ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {

            this.logtext = 'Update & Close - Chapter form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Chapter ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

            this.message.error("Failed to update chapter information...", "");
            this.isSpinning = false;
          }
        });
    }
    else {

      this.api.createShikuyaaChapter(this.data)
        .subscribe(successCode => {
          console.log(successCode)

          if (successCode['code'] == "200") {
            this.message.success("Chapter information added successfully...", "");

            if (!addNew) {
              this.drawerClose();

              this.logtext = 'Save & Close - Chapter form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
            }

            else {

              //this.data = new Shikuyaachapter();
              this.drawerClose();

              this.data.NAME = ""
              this.data.DESCRIPTION = ""
              this.data.THUMBNAIL_URL = ""
              this.data.IS_ACTIVE = true;
              this.data.IS_DEPEND = false;
              this.data.DEPENDED_CHAPTER_ID = undefined
              this.data.IS_PRACTICE_FREE = false
              this.data.THUMBNAIL_URL_CONVERTED = ""

              this.logtext = 'Save & New - Chapter form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add chapter information...", "");
            this.isSpinning = false;
            this.logtext = 'Save & Close - Chapter form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Chapter ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });

          }
        });
    }
  }

  onFileSelectedTHUMBNAIL_URL(event) {

    //................... second ................

    // this.isokfile = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // console.log(event.target.files[0])
    // const isLt2M = event.target.files[0].size <= 10485760;
    // const Img = new Image();
    // Img.src = URL.createObjectURL(event.target.files[0]);
    // Img.onload = (e: any) => {
    //   this.thumbnailImgHeight = e.path[0].height;
    //   this.thumbnailImgWeight = e.path[0].width;
    //   console.log(this.thumbnailImgHeight, this.thumbnailImgWeight);

    //   if (this.thumbnailImgHeight == 100 && this.thumbnailImgWeight == 100)
    //     this.isheightwidth = true
    //   else
    //     this.isheightwidth = false

    //   console.log("diamention match" + this.isheightwidth)

    //   console.log(" size" + isLt2M)

    //   if (isLt2M && this.isheightwidth) {
    //     if (!allowed_types.toString().match(event.target.files[0].type)) {
    //       this.isokfile = false
    //       this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    //     }

    //     if (this.isokfile) {
    //       this.fileDataTHUMBNAIL_URL = <File>event.target.files[0];
    //     }
    //   }
    //   else {
    //     this.myInputVariable.nativeElement.value = null;
    //     if (!isLt2M)
    //       this.message.error('File Size must be less than 10240kb', '');

    //     if (!this.isheightwidth)
    //       this.message.error('Height and Width must be 100 X 100.', '');
    //   }

    // }


    //...................first................
    // this.isokfile = true
    // const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    // if (!allowed_types.toString().match(event.target.files[0].type)) {
    //   this.isokfile = false
    //   this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
    // }

    // if (this.isokfile) {
    //   this.fileDataTHUMBNAIL_URL = <File>event.target.files[0];
    // }


    this.isokfile = true
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    console.log(event.target.files[0])
    const isLt2M = event.target.files[0].size <= 10485760;


    console.log(" size" + isLt2M)

    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isokfile = false
        this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");
      }

      if (this.isokfile) {
        this.fileDataTHUMBNAIL_URL = <File>event.target.files[0];
      }
    }
    else {
      this.myInputVariable.nativeElement.value = null;
      if (!isLt2M)
        this.message.error('File Size must be less than 10240kb', '');


    }
  }


  clear() {
    this.fileDataTHUMBNAIL_URL = null
  }

  ChangeParentName(value) {
    let id: number
    if (this.data.ID) {

      this.api.getAllShikuyaaChapters(0, 0, 'ID', 'asc', " AND ID=" + value).subscribe(data => {
        this.isLeaf = data['data'][0]['IS_LEAF']
        id = data['data'][0]['ID']

        if (this.isLeaf == 1 || this.data.ID == id)
          this.data.PARENT_ID = null

      }, err => {
        console.log(err);
      });
    }
    else {
      this.api.getAllShikuyaaChapters(0, 0, 'ID', 'asc', " AND ID=" + value).subscribe(data => {
        this.isLeaf = data['data'][0]['IS_LEAF']
        if (this.isLeaf == 1)
          this.data.PARENT_ID = null
      }, err => {
        console.log(err);
      });
    }


  }


  addChapterBaseData() {
    console.log(this.chapterBaseData)
    if (this.TITLE != undefined && this.TITLE != "") {
      if (this.chapterBaseData.length == 0) {
        this.chapterBaseData = [
          {
            ID: 1,
            TITLE: this.TITLE,
            CONTENTS: this.CONTENTS,
            STATUS: this.STATUS,
          }
        ];
      }
      else {
        let sameName = this.chapterBaseData.filter(object => {
          return object['TITLE'] == this.TITLE
        });
        if (sameName.length == 0) {
          this.chapterBaseData = [
            ...this.chapterBaseData,
            {
              ID: this.i,
              TITLE: this.TITLE,
              CONTENTS: this.CONTENTS,
              STATUS: this.STATUS,
            }
          ];
          this.i++;
        }
        else {
          this.message.error("Same Time Found", "");
        }
      }
      this.TITLE = undefined
      this.CONTENTS = undefined
      this.STATUS = true

    }
    else {
      this.message.error("Please Enter Required Details", "");
    }
  }

  editRow(data, i1: number) {
    this.index = i1
    this.TITLE = data.TITLE
    this.CONTENTS = data.CONTENTS

  }
  
}