import { Component, OnInit, Input } from '@angular/core';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.css']
})
export class OnboardComponent implements OnInit {

  PEOPLES_EMAIL_ID:string
  @Input() modelOnboardClose: Function;
  @Input() data: College;
  userId = Number(this.cookie.get('userId'));
  logtext: string = "";
  isSpinning=false
  loadingFilterEmailIds=false
  userEmailIds=[]
  userEmailIds1=[]
  combinedEmails=[]
  EMAIL_IDS
  constructor(public api: ApiService, private cookie: CookieService) { }

  ngOnInit() {
    this.loadData()
  }

  loadData()
  {
    this.loadingFilterEmailIds = true
    var filter = " AND ROLE_ID in (1,5,6,7,9,10)"
    this.api.getAllUserMappingData(0, 0, '', '', filter).subscribe(data => {
      this.userEmailIds = data['data'];
      console.log(this.userEmailIds)
      this.loadingFilterEmailIds = false

      var filter = " AND PARENT_ID in (1,5,6,7,9,10)"
      this.api.getAllUserMappingData(0, 0, '', '', filter).subscribe(data => {
        this.userEmailIds1 = data['data'];
        console.log(this.userEmailIds)
        this.loadingFilterEmailIds = false
      }, err => {
        console.log(err);
      });

      this.combinedEmails = [].concat(this.userEmailIds, this.userEmailIds1)
    

    }, err => {
      console.log(err);
    });

   

   

  }


  close()
  {
    this.modelOnboardClose()
  }
 
  save()
  {
    this.isSpinning=true
    //send mail ID for comma saparate string
    this.data.IS_ONBOARDED=true
    this.EMAIL_IDS.forEach(element => {
    this.api.sendMail(element,"College Onboarded Please check,", this.data.NAME + " is onboarded").subscribe(data => {
      if(data['code']==200)
      {
        console.log(data)
      }
    }, err => {
      console.log(err);
      this.isSpinning=false
    });
    });

    this.api.updateCollege(this.data)
    .subscribe(successCode => {
      if (successCode['code'] == "200") {
        console.log(successCode);
        this.logtext = 'Update & Close - College form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - College ]";
        this.api.addLog('A', this.logtext, this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning=false
              this.modelOnboardClose()
            }
            else {
              console.log(successCode);
            }
          });
      }
      else {
        this.logtext = 'Update & Close - College form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - College ]";
        this.api.addLog('A', this.logtext, this.api.emailId)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              console.log(successCode);
            }
            else {
              console.log(successCode);
            }
          });
      }
    });

    
   
  }


}
