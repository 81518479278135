import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-courseassessmentdetail',
  templateUrl: './courseassessmentdetail.component.html',
  styleUrls: ['./courseassessmentdetail.component.css']
})
export class CourseassessmentdetailComponent implements OnInit {

  isExportloading = false
  formTitle = "Course Assessment Details";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  logtext: string = "";
  filterValue = 1
  filterClass: string = "filter-invisible";
  //chapter name,l1 questions,l1 time,l2 question,l2 time,l3 question,l3 time, l4 question,l4 time,l5 question,l5 time, total time,total question
  columns: string[][] = [['CHAPTER_NAME', 'Chapter Name'], ['L1_QUESTIONS', 'L1 Questions'], ['L1_TIME', 'L1 Time'], ['L2_QUESTIONS', 'L2 Questions'], ['L2_TIME', 'L2 Time'],
  ['L3_QUESTIONS', 'L3 Questions'], ['L3_TIME', 'L3 Time'], ['L4_QUESTIONS', 'L4 Questions'], ['L4_TIME', 'L4 Time'],
  ['L5_QUESTIONS', 'L5 Questions'], ['L5_TIME', 'L5 Time'], ['TOTAL_TIME', 'Total Time'], ['TOTAL_QUESTION', 'Total Questions']]

  coursesNodes = []
  isloadSpinning = false
  dataList1 = []
  pageSize2 = 10
  selectedDate: Date[] = []
  value1: string = ""
  value2: string = ""
  dateFormat = 'dd/MM/yyyy';
  datePipe = new DatePipe("en-US");
  loadingcourse = false
  loadingmocks = false
  MOCK_ID = ''
  mocks = []
  constructor(public api: ApiService, private message: NzNotificationService) { }
  ngOnInit() {
    this.selectedDate = [new Date(), new Date()]
    this.changeDate(this.selectedDate)

    this.loadAllCourses();

    this.logtext = 'OPENED - Course Assessment Details KEYWORD[O - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }
  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd")
  }

  loadAllCourses() {
    this.loadingcourse = true
    this.api.getAllCourses(0, 0, 'ID', 'ASC', ' AND COURSE_TYPE_ID = 2').subscribe(localName => {
      this.loadingcourse = false
      this.coursesNodes = localName['data'];
   
     
      this.loadingmocks = true
      this.api.mappedMockMappingCoursesInfo(0, 0, 'id', 'asc', ' AND COURSE_ID = ' + this.filterValue).subscribe(data => {
        this.loadingmocks = false
        this.mocks = data['data'];

      }, err => {
        console.log(err);
      });
      
    }, err => {
      console.log(err);
    });
  }
  loadAllCourses2(event) {
    this.filterValue = event
    this.mocks = []
    this.loadingmocks = true
    this.api.mappedMockMappingCoursesInfo(0, 0, 'id', 'asc', ' AND COURSE_ID = ' + this.filterValue).subscribe(data => {
      this.loadingmocks = false
      this.mocks = data['data'];
    }, err => {
      console.log(err);
    });

  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || '';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :" + sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search(false);
  }

  onKeyDownEvent(event)
  {if(event.key=='Enter')
  {
    event.preventDefault()
  }

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = 'Filter Applied - Course Assessment Details"+ sort +" "+this.sortKey +" KEYWORD [F - Chapters] ';
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
      console.log("likeQuery" + likeQuery);
    }

    this.logtext = 'Filter Applied - Course Assessment Details ' + likeQuery + ' KEYWORD [F - Chapters] ';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

    console.log(filter)
    this.isloadSpinning = true
    this.api.getAllCourseAssesmentDetailsReport(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
      this.isloadSpinning = false
      this.loadingRecords = false;
      if (data['code'] == '200') {
        this.totalRecords = data['count'];
        this.dataList = data['data'];
    if(data['count']>'0')
    this.dataList1 = data['data'];
      }
    }, err => {
      console.log(err);
    });
  
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }


  applyFilter() {
    this.filterQuery = ""
    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
    this.search(true)
  }

  clearFilter() {
    this.MOCK_ID = ''
    this.filterValue=1
    this.filterQuery = ""
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true)
  }



}
