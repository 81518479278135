import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CollegeMap } from 'src/app/Models/AssessmeentModels/collegemap';
import { StudentMapping } from 'src/app/Models/AssessmeentModels/studentmapping';
import { Branch } from 'src/app/Models/BatchMgmt/branch';
import { Passingyear } from 'src/app/Models/BatchMgmt/passingyear';
import { ApiService } from 'src/app/Service/api.service';
import { ShikuyaaapiService } from 'src/app/Service/shikuyaaapi.service';

@Component({
  selector: 'app-studentmap',
  templateUrl: './studentmap.component.html',
  styleUrls: ['./studentmap.component.css']
})
export class StudentmapComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  // data: BundleMaster = new BundleMaster;
  @Input() data: any;

  @Input()
  drawerVisible: boolean = false;
  // dataList :any []= [];
  isSpinning = false;
  isOk=true;
  emailpattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt=/[a-zA-Z][a-zA-Z ]+/
  mobpattern=/^[6-9]\d{9}$/ 
  @Input() collegeName: any[] = [];
  studentName:any[]=[]
  @Input() dataList: StudentMapping[] = [];
  passingYear: Passingyear[] = [];
  @Input() branches: any[] = [];
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc"; 
  sortKey: string = "id";
  searchText: string = "";
  totalRecords=1
  drawerData: StudentMapping = new StudentMapping();
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  collegeid:any
  columns: string[][] = [["COLLEGE_ID", " College Name "]]

  constructor(private api:ApiService,public api2:ShikuyaaapiService, private message: NzNotificationService) { }

 

  ngOnInit(): void {
    // this.loadColleges();
    this.loadYear();
    // this.loadBranchs() 

  }

   //Colleges Name
   loadColleges() {
    this.collegeid= sessionStorage.getItem('collegeID')
    this.api.getAllMappedCollegelist(0,0,'','',this.collegeid).subscribe(
      (data) => {
        this.collegeName = data["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  loadbranch(event){
    this.api2.getAllStudentBranches(0,0,'','','').subscribe(
    // this.api2.getAllStudentBranches(0,0,'','',' AND COLLEGE_ID = '+event).subscribe(
      (data) => {
        // this.api.getAllcouponuseddetailedreport(0,0,'','',' AND STATUS=1').subscribe(data =>{
        this.branches = data['data'];
        this.isSpinning = false;
      },
      (err) => {
        console.log(err);
        this.isSpinning = false;
      }
    );
    
  }
  // loadBranchs() {
  //   this.api2.getAllStudentBranches(0, 0, '', ' desc', ' AND IS_ACTIVE=1').subscribe(
  //     (data) => {
  //       // this.api.getAllcouponuseddetailedreport(0,0,'','',' AND STATUS=1').subscribe(data =>{
  //       this.branches = data['data'];
  //     },
  //     (err) => {
  //       console.log(err);
  //       this.isSpinning = false;
  //     }
  //   );
  // }

  loadYear() {
    this.api2.getAllStudentYears(0, 0, '', ' desc', ' AND IS_ACTIVE=1').subscribe(
      (data) => {
        // this.api.getAllcouponuseddetailedreport(0,0,'','',' AND STATUS=1').subscribe(data =>{
        this.passingYear = data['data'];
      },
      (err) => {
        console.log(err);
        this.isSpinning = false;
      }
    );
  }

  // close(): void {
  //   this.drawerClose();
  // }
  keyup(event){
    this.search()
  }
   //// Only number
   omit(event:any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ////

  resetDrawer(studentbundlemaster: NgForm) {
    this.data=new StudentMapping();
    // collegeMapPage.form.reset();

    studentbundlemaster.form.markAsPristine();
    studentbundlemaster.form.markAsUntouched();
  }

  close(studentbundlemaster: NgForm) {
    this.drawerClose();
    this.resetDrawer(studentbundlemaster);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    // if (this.searchText != "") {
    //   likeQuery = " AND";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    //   console.log("likeQuery" + likeQuery);
    // }
    // this.isSpinning = true;
    this.api.getAllAssessmentStudent(this.pageIndex, this.pageSize ,'','',this.collegeName[0].ID,Number(sessionStorage.getItem('collegeID')),this.data.BRANCH_ID,this.data.YEAR_ID,this.searchText).subscribe(
      (data) => {
        this.isSpinning = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];        
      },
      (err) => {
        console.log(err);
        this.isSpinning = false;
      }
    );
  }
  //save
  save(addNew: boolean,studentbundlemaster: NgForm): void {
   
    if(this.isOk)
    {
      // this.isSpinning=false; 
      // this.data.LATITUDE = this.latitude.toString();
      // this.data.LONGITUDE   = this.longitude.toString();
     
      this.isSpinning=true; 
    if(this.data.ID)
    {
    //   for(var i=0;i< this.dataList.length; i++){
    //   if (this.dataList[i].START_DATE==undefined){
    //     this.dataList[i].START_DATE=null;
    //   }else{
    //     this.dataList[i].START_DATE=this.datePipe.transform(this.dataList[i].START_DATE,"yyyy-MM-dd HH:mm:ss"); 
    //   }
    //   if (this.dataList[i].END_DATE==undefined){
    //     this.dataList[i].END_DATE=null;
    //   }else{
    //     this.dataList[i].END_DATE=this.datePipe.transform(this.dataList[i].END_DATE,"yyyy-MM-dd HH:mm:ss"); 
    //   }
    //   if (this.dataList[i].MAX_STUDENTS<=0){
    //     this.dataList[i].MAX_STUDENTS=0;
    //   }else{
    //     this.dataList[i].MAX_STUDENTS = this.dataList[i].MAX_STUDENTS
    //   }
    // }
        this.api.updateAssessmengtStudMap(this.dataList,this.data.ID,this.data.ID,this.collegeName[0].BUNDLE_MAPPING_ID)
    
        .subscribe(successCode => {
          if(successCode.code=="200")
          {
            this.message.success(' Information updated successfully...', '');
            if(!addNew)
              this.drawerClose();
              this.isSpinning = false;
          }   
          else
          {
            this.message.error(' Failed to update information...', '');
            this.isSpinning = false;
          }
        });
      }
      // else{
      
      //     this.api.createCollegeMapping(this.data)
      //     .subscribe(successCode => {
      //       if(successCode.code=="200")
      //       {
      //         this.message.success(' Information save successfully...', '');
      //         if(!addNew)
      //        this.drawerClose();
      //           else
      //           {
      //             this.data=new CollegeMap();
      //             this.resetDrawer(collegeMapPage);
      //             // this.data.img= '';
                  
      //             this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
      //               // if (data['count']==0){
      //               //   this.data.SEQUENCE_NO=1;
      //               // }else
      //               // {
      //               //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
      //               // }
      //             },err=>{
      //               console.log(err);
      //             })
      //           }
      //           this.isSpinning = false;
      //         }
      //          else
      //          {
      //           this.message.error(' Failed to save information...', '');
      //           this.isSpinning = false;
      //          }
      //         });
      //       }
    }
    
    // else
    // {
    //   this.message.error("Please Fill All Required Fields...","");
    //   this.isSpinning = false;
    // }
      }

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id'; 
    // const sortOrder = (currentSort && currentSort.value) || 'asc';
    const sortOrder = (currentSort && currentSort.value) || 'desc';

    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  apply(data:any){
    console.log('DATA:',data);
    this.api.getAllAssessmentStudent(this.pageIndex,this.pageSize,'','',this.collegeName[0].ID,Number(sessionStorage.getItem('collegeID')),this.data.BRANCH_ID,this.data.YEAR_ID,'').subscribe(
      (data) => {
        this.dataList = data["data"];
        this.totalRecords = data['count'];
        // console.log('Student:',this.studentName);
        
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onItemChecked(id: number, checked: boolean, ind: any): void {
    console.log(id,ind,checked,'ggg')
    if (checked) {
      this.dataList[ind].IS_ACTIVE = true
    } else {
      this.dataList[ind].IS_ACTIVE = false
    }
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.dataList.forEach(item => {
      if (value) {
        item.IS_ACTIVE = true
      } else {
        item.IS_ACTIVE = false
      }
      this.updateCheckedSet(item.STUDENT_ID, value)
    });
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    console.log(id,checked,'ggg2')
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.dataList.every(item => {
      // console.log(this.setOfCheckedId.has(item.COLLEGE_ID),'ggg33')
      this.setOfCheckedId.has(item.STUDENT_ID)
    });
    this.indeterminate = this.dataList.some(item => this.setOfCheckedId.has(item.STUDENT_ID)) && !this.checked;
  }
}
