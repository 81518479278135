export class Instruction {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    INSTRUCTION_TEXT:string;
    IS_DEFAULT_SELECTED:boolean;
}


export class Instruction2 {
    ID:number;
    CLIENT_ID:number;
    NAME:string="";
    INSTRUCTION_TEXT:string="";
    IS_DEFAULT_SELECTED:boolean;
    ALLOWED_FOR_MOBILE:boolean;
    ALLOWED_FOR_WEB:boolean;
    ALLOWED_FOR_DESKTOP:boolean;
}