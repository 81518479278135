export class BundleAssessmentMapping {
    ID:number=0;
    BUNDLE_ID:number=0;
    MOCK_ID:any=[];
    IS_ACTIVE:boolean=false;
    ENABLE_TIME:any=new Date();
    DISABLE_TIME:any=new Date();

} 

 