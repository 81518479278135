import { Component, OnInit, Input } from '@angular/core';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-collegetypemapping',
  templateUrl: './collegetypemapping.component.html',
  styleUrls: ['./collegetypemapping.component.css']
})
export class CollegetypemappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: College;
  @Input() collegeTypeMappingData: string[];
  @Input() drawerVisible: boolean;
  logtext:string=""
  isSpinning = false
  drawerDataBranchMapping=[]
  drawerVisibleBranchMapping:boolean
  drawerTitleBranchMapping:string=""
  drawerData: College = new College();
  searchText:string=""
  constructor(public api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {
  }

  close(): void {
    this.drawerClose();
  }

  get closeCallbackBranchMapping() {
    return this.drawerCloseBranchMapping.bind(this);
  }
  
  drawerCloseBranchMapping(): void {
    this.drawerVisibleBranchMapping = false;
  }

  save()
  {
    console.log(this.data.ID)
    console.log(this.collegeTypeMappingData)
    this.isSpinning = true;
      this.api.addCollegeTypeMapping(this.data.ID,this.collegeTypeMappingData)
      .subscribe(successCode => {
        console.log(successCode)
        if(successCode['code']==200)
        { 
           this.api.getBranchMapping(this.data.ID).subscribe(data => {
              console.log(data);
              console.log(data['data']);
             this.drawerDataBranchMapping = data['data'];
              }, err => {
              console.log(err);
            }); 
            this.drawerTitleBranchMapping = "Branch Mapping for " + this.data.ABBREVATION + "";
            this.drawerData.ID=this.data.ID
            this.drawerVisibleBranchMapping = true;
            this.isSpinning = false;
            this.logtext = 'Save & Close - CollegeTypeMapping form - SUCCESS - ' + "CollegeId="+this.data.ID+" "+ JSON.stringify(this.collegeTypeMappingData) + " KEYWORD [C - CollegeTypeMapping ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
        }
        else
        {
          this.message.error("College Type Details assigning Failed...", "");
          this.isSpinning = false;
          this.logtext = 'Save & Close - CollegeTypeMapping form - ERROR - ' + "CollegeId="+this.data.ID+" "+ JSON.stringify(this.collegeTypeMappingData) + " KEYWORD [C - CollegeTypeMapping ]";
          this.api.addLog('A', this.logtext, this.api.emailId)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                console.log(successCode);
              }
              else {
                console.log(successCode);
              }
            });
        }
      });
  }
}
