import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import {

  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexPlotOptions,
  ApexFill,
  ApexTooltip,
  ApexNonAxisChartSeries,
  ApexResponsive
} from "ng-apexcharts";
import { DatePipe } from '@angular/common';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};
export type ChartOptions3 = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
interface DataItem {
  CHAPTER_NAME: number
}
interface DataItem11 {
  LIVE_VIDEO_NAME: string,
  LIKE_COUNT: number
}
interface DataItem22 {
  VIDEO_NAME: string, VIEWS_COUNT: number, LIKE_COUNT: number, AVERAGE_WATCH_TIME: number
}

interface DataItem1 {
  NAME: string
  SUBSCRIBERS: number
  FREE_TRIAL_TAKEN_COUNT: number
  RATINGS: number
  VIDEO_COUNT: number
  MOCK_COUNT: number
  QUESTION_COUNT: number
  CHAPTER_COUNT: number
  LIVE_VIDEO_COUNT: number
}
interface DataItem2 {
  NAME: string
  WATCHED_COUNT: number
  WATCHING_TIME: number
  LIKE_COUNT: number
  TOTAL_COMMENTS: number
}


@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.css'],
  providers: [DatePipe]
})
export class AdmindashboardComponent implements OnInit {
  TYPE = true;
  isSpinning = false
  logtext = ""
  filterQuery: string = "";

  pincodeWiseData = [];
  pincodeWiseData11 = [];
  pincodeWiseData22 = [];
  liveVideoCount = [];
  courseWiseCount = [];

  listOfColumn = [
    {
      title: 'Chapter',
      compare: (a: DataItem, b: DataItem) => a.CHAPTER_NAME - b.CHAPTER_NAME,
    }

  ];
  listOfColumn11 = [
    {
      title: 'Video Name',
      compare: (a: DataItem11, b: DataItem11) => a.LIVE_VIDEO_NAME.localeCompare(b.LIVE_VIDEO_NAME),
    },
    {
      title: 'Like Count',
      compare: (a: DataItem11, b: DataItem11) => a.LIKE_COUNT - b.LIKE_COUNT,
    }

  ];
  // VIDEO_NAME:string, VIEWS_COUNT:number, LIKE_COUNT:number, AVERAGE_WATCH_TIME:number
  listOfColumn22 = [
    {
      title: 'Video Name',
      compare: (a: DataItem22, b: DataItem22) => a.VIDEO_NAME.localeCompare(b.VIDEO_NAME),
    },
    {
      title: 'Views Count',
      compare: (a: DataItem22, b: DataItem22) => a.VIEWS_COUNT - b.VIEWS_COUNT,
    },
    {
      title: 'Like Count',
      compare: (a: DataItem22, b: DataItem22) => a.LIKE_COUNT - b.LIKE_COUNT,
    },
    {
      title: 'Average Watch Time',
      compare: (a: DataItem22, b: DataItem22) => a.AVERAGE_WATCH_TIME - b.AVERAGE_WATCH_TIME,
    }

  ];

  listOfColumn1 = [
    {
      title: 'Course Name',
      compare: (a: DataItem1, b: DataItem1) => a.NAME.localeCompare(b.NAME),
    },
    {
      title: 'Subscriber Count',
      compare: (a: DataItem1, b: DataItem1) => a.SUBSCRIBERS - b.SUBSCRIBERS,
    },
    {
      title: 'Trial Count',
      compare: (a: DataItem1, b: DataItem1) => a.FREE_TRIAL_TAKEN_COUNT - b.FREE_TRIAL_TAKEN_COUNT,
    },
    {
      title: 'Ratings',
      compare: (a: DataItem1, b: DataItem1) => a.RATINGS - b.RATINGS,
    },
    {
      title: 'Video Count',
      compare: (a: DataItem1, b: DataItem1) => a.VIDEO_COUNT - b.VIDEO_COUNT,
    },
    {
      title: 'Mock Count',
      compare: (a: DataItem1, b: DataItem1) => a.MOCK_COUNT - b.MOCK_COUNT,
    },
    {
      title: 'Questions Count',
      compare: (a: DataItem1, b: DataItem1) => a.QUESTION_COUNT - b.QUESTION_COUNT,
    },
    {
      title: 'Chapters Count',
      compare: (a: DataItem1, b: DataItem1) => a.CHAPTER_COUNT - b.CHAPTER_COUNT,
    },
    {
      title: 'Live Completed Videos',
      compare: (a: DataItem1, b: DataItem1) => a.LIVE_VIDEO_COUNT - b.LIVE_VIDEO_COUNT,
    },

  ];
  listOfColumn2 = [
    {
      title: 'Video Name',
      compare: (a: DataItem2, b: DataItem2) => a.NAME.localeCompare(b.NAME),
    },
    {
      title: 'Watched Count',
      compare: (a: DataItem2, b: DataItem2) => a.WATCHED_COUNT - b.WATCHED_COUNT,
    },
    {
      title: 'Watching Time',
      compare: (a: DataItem2, b: DataItem2) => a.WATCHING_TIME - b.WATCHING_TIME,
    },
    {
      title: 'Total Likes',
      compare: (a: DataItem2, b: DataItem2) => a.LIKE_COUNT - b.LIKE_COUNT,
    },
    {
      title: 'Total Comments',
      compare: (a: DataItem2, b: DataItem2) => a.TOTAL_COMMENTS - b.TOTAL_COMMENTS,
    },

  ];



  columns: string[][] = [['NAME', 'Video Name'], ['WATCHED_COUNT', 'Watched Count'], ['WATCHING_TIME', 'Watching Time'], ['LIKE_COUNT', 'Total Likes'], ['TOTAL_COMMENTS', 'Total Comments']]
  columns2: string[][] = [['NAME', 'Course Name'], ['CHAPTER_COUNT', 'Chapters'], ['BATCH_COUNT', 'Batches'], ['VIDEO_COUNT', 'Videos']]

  public chartOptions: Partial<ChartOptions>;
  public chartOptions3: Partial<ChartOptions3>;
  public chartOptions4: Partial<ChartOptions2>;


  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;



  date = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1

  lineChartLabels = []
  lineChartData = []
  pieData = []

  TOTAL_COURSES = 0
  TOTAL_STUDENT_REGISTERD = 0
  ENROLLED_STUDENT_COUNT = 0
  TOTAL_LIVE_LECTURES = 0
  TOTAL_VIDEOS = 0
  TOTAL_USERS_ACTIVE_TODAY = 0
  TOTAL_CHAPTERS = 0
  TOTAL_QUESTIONS = 0
  COUPAN_COUNTS = 0
  COUPAN_USED_BY_STUDENTS = 0
  showPie = false

  selectedDate: Date[] = []
  value1: string = ""
  value2: string = ""
  dateFormat = 'dd/MM/yyyy';
  datePipe = new DatePipe("en-US");
  isSpinning2 = false;
  constructor(public api: ApiService, private message: NzNotificationService) {

  }

  ngOnInit() {

    this.setLineChart();
    this.isSpinning = true;
    this.api.getDashboardData().subscribe(data => {
      console.log(data)
      if (data['code'] == 200) {

        this.TOTAL_COURSES = data['dashboardCounts'][0]['TOTAL_COURSES']
        this.TOTAL_STUDENT_REGISTERD = data['dashboardCounts'][0]['TOTAL_STUDENTS']
        this.ENROLLED_STUDENT_COUNT = data['dashboardCounts'][0]['TOTAL_ENROLLED_STUDENTS']
        this.TOTAL_LIVE_LECTURES = data['dashboardCounts'][0]['TOTAL_LIVE_VIDEOS']
        this.TOTAL_VIDEOS = data['dashboardCounts'][0]['TOTAL_VIDEOS']
        this.TOTAL_USERS_ACTIVE_TODAY = data['dashboardCounts'][0]['TODAYS_ACTIVE_STUDENTS']
        this.TOTAL_CHAPTERS = data['dashboardCounts'][0]['TOTAL_CHAPTERS']
        this.TOTAL_QUESTIONS = data['dashboardCounts'][0]['TOTAL_QUESIONS']
        this.COUPAN_COUNTS = data['dashboardCounts'][0]['TOTAL_COUPONS']
        this.COUPAN_USED_BY_STUDENTS = data['dashboardCounts'][0]['COUPONS_USED_COUNT']

        this.pincodeWiseData=data['topTenSolvedChapters']
        this.courseWiseCount = data['courseSummary']

        this.pincodeWiseData22 = data['topTenvideo'];
        this.pincodeWiseData11 = data['topTenLiveVideo']

        this.pieData = data['pieChart']
        this.setPieChart();
      }
      else {
        this.message.error(data['message'], "");
      }
      this.isSpinning = false;
    }, err => {
      this.isSpinning = false;
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
    this.selectedDate = [new Date(), new Date()]
    this.onChange(this.selectedDate);
  }

  onChange(result): void {
    if (result[0] != "" && result[1] != "") {
      this.value1 = this.datePipe.transform(result[0], "yyyy-MM-dd")
      this.value2 = this.datePipe.transform(result[1], "yyyy-MM-dd")
      this.isSpinning2 = true;
      this.lineChartLabels = []
      this.lineChartData = [];

      this.api.getLineChartData(this.value1, this.value2).subscribe(data => {
        this.isSpinning2 = false;
        if (data['code'] == 200) {
          data['lineChart'].forEach((e) => {
            this.lineChartData.push(e.STUDENT_COUNT)
            this.lineChartLabels.push(e.NAME)
          })


          this.setLineChart();
        }
      });
    }else {
      this.isSpinning2 = false;
      this.message.error("Please Select Date", "");
    }


  }



  setLineChart() {
    this.chartOptions = {
      series: [
        {
          name: " Student Joined",
          data: this.lineChartData
        }
      ],
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ["#77B6EA"],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "",
        align: "left"
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: this.lineChartLabels,
        title: {
          text: "Course Name"
        }
      },
      yaxis: {
        title: {
          text: "Count"
        },

      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    };


  }

  setPieChart() {
    this.showPie = true
    this.chartOptions3 = {
      series: [this.pieData[0]['TOTAL_REGISTERED_STUDENTS'], this.pieData[0]['TOTAL_SUBSCRIBED_STUDENTS']],
      chart: {
        width: 350,
        type: "pie"
      },
      labels: ["Registered ", "Subsribed "],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  getTime(sec) {
    return this.api.getSecondsToHms(sec)
  }



}

