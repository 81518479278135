import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzTableQueryParams } from "ng-zorro-antd/table";
// import { BundleAssessmentMapping } from 'src/app/Models/AssessmeentModels/bundleassessmentmapping';
import { BundleAssessmentMapping } from "src/app/Models/AssessmeentModels/bundleassessmentmapping";
import { CollegeMap } from "src/app/Models/AssessmeentModels/collegemap";
import { ApiService } from "src/app/Service/api.service";
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: "app-bundleassessmentmap",
  templateUrl: "./bundleassessmentmap.component.html",
  styleUrls: ["./bundleassessmentmap.component.css"],
})
export class BundleassessmentmapComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  // data: BundleAssessmentMapping = new BundleAssessmentMapping;
  @Input()
  data2: BundleAssessmentMapping;
  @Input() data: any;
  @Input()
  drawerVisible: boolean = false;
  @Input() dataList: BundleAssessmentMapping[] = [];
  @Input() totalRecords = 1;
  roleDetailsData: any[] = [];
  isSpinning = false;
  isOk = true;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  mobpattern = /^[6-9]\d{9}$/;
  // collegeName: CollegeMap[] = [];
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  loadingRecords = true;
  // totalRecords = 1;
  listOfSelection: any;
  radio = true;
  @Input() time
  @Input() checked = false;
  @Input() indeterminate = false;
  @Input() setOfCheckedId = new Set<number>();
  today2 = new Date();
  endDate: any
  endDateId: any
  array = []
  checkedCount = 1
  columns: string[][] = [["COLLEGE_ID", " College Name "]];

  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    // this.loadColleges()



  }

  //Colleges Name
  //  loadColleges() {
  //   this.api.getAllColleges(0, 0, '', ' desc', '').subscribe(
  //     (data) => {
  //       // this.api.getAllcouponuseddetailedreport(0,0,'','',' AND STATUS=1').subscribe(data =>{
  //       this.collegeName = data['data'];
  //     },
  //     (err) => {
  //       console.log(err);
  //       this.isSpinning = false;
  //     }
  //   );
  // }
  // close(): void {
  //   this.drawerClose();
  // }

  //// Only number
  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ////

  resetDrawer(collegeMapPage: NgForm) {
    this.data2 = new BundleAssessmentMapping();
    // collegeMapPage.form.reset();

    collegeMapPage.form.markAsPristine();
    collegeMapPage.form.markAsUntouched();
  }

  close(collegeMapPage: NgForm) {
    this.drawerClose();
    this.searchText = "";
    this.resetDrawer(collegeMapPage);
  }

  search(reset: boolean = false) {
    console.log('checked', this.checked);
    console.log('checkedCount', this.checkedCount);

    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc";
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    // if (this.searchText != "") {
    //   likeQuery = " AND";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    //   console.log("likeQuery" + likeQuery);
    // }
    // this.api.getAllstate(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
    this.isSpinning = true;
    // if(this.checked = true){
    //   console.log('this.checkedCount2',this.checkedCount);

    //   this.api
    //   .getAllAssessment(
    //     this.pageIndex,
    //     this.pageSize,
    //     this.sortKey,
    //     sort,
    //     this.data.ID,
    //     this.searchText
    //   )
    //   .subscribe(
    //     (data) => {
    //       this.isSpinning = false;
    //       this.totalRecords = data["count"];
    //       console.log("this.totalRecords", this.totalRecords);
    //       this.dataList = data["data"];
    //       this.dataList.forEach((item) => {    
    //         item.IS_ACTIVE = true;
    //       this.updateCheckedSet(item.MOCK_ID, true);
    //     });
    //     this.refreshCheckedStatus();
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    // } else 
    // if(this.checked = false){
    //   console.log('this.checkedCount3',this.checkedCount);
    //   this.api
    //   .getAllAssessment(
    //     this.pageIndex,
    //     this.pageSize,
    //     this.sortKey,
    //     sort,
    //     this.data.ID,
    //     this.searchText
    //   )
    //   .subscribe(
    //     (data) => {

    //       this.isSpinning = false;
    //       this.totalRecords = data["count"];
    //       console.log("this.totalRecords", this.totalRecords);
    //       this.dataList = data["data"];
    //       this.dataList.forEach((item) => {    
    //         item.IS_ACTIVE = true;
    //       this.updateCheckedSet(item.MOCK_ID, false);
    //     });
    //     this.refreshCheckedStatus();
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    // } else {
    //   console.log('this.checkedCount1',this.checkedCount);
    this.api
      .getAllAssessment(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.data.ID,
        this.searchText
      )
      .subscribe(
        (data) => {
          this.isSpinning = false;
          this.totalRecords = data["count"];
          console.log("this.totalRecords", this.totalRecords);

          this.dataList = data["data"];
          this.dataList.forEach((item) => {
            if (item.IS_ACTIVE) {
              this.setOfCheckedId.add(item.MOCK_ID);
            } else {
              this.setOfCheckedId.delete(item.MOCK_ID);
            }
          });
          this.checked = this.dataList.every((item) => {
            this.setOfCheckedId.has(item.MOCK_ID);
          });
          this.indeterminate =
            this.dataList.some((item) =>
              this.setOfCheckedId.has(item.MOCK_ID)
            ) && !this.checked;
          // if(this.totalRecords==0){
          //   data.SEQUENCE_NO=1;
          // }else{
          //   data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
          // }
        },
        (err) => {
          console.log(err);
        }
      );
    // }
  }
  //save
  //   save(addNew: boolean,collegeMapPage: NgForm): void {
  //     this.isSpinning = false;
  //     this.isOk=true;

  //     if (this.data.BUNDLE_ID==0  && this.data.ASSESSMENT_ID==0  ){
  //       this.isOk =false
  //      this.message.error(" Please fill all required fields", "");
  //     }else

  //     if(this.data.BUNDLE_ID== undefined || this.data.BUNDLE_ID<=0){
  //      this.isOk =false
  //      this.message.error('Please select bundle name','')
  //     }else

  //     if(this.data.ASSESSMENT_ID.length==0 ){
  //       this.isOk =false
  //       this.message.error('Please select assessment name','')

  // }
  // if(this.isOk)
  // {
  //   this.isSpinning=false;
  //   // this.data.LATITUDE = this.latitude.toString();
  //   // this.data.LONGITUDE   = this.longitude.toString();
  //   this.isSpinning=true;
  // if(this.data.ID)
  // {
  //     this.api.updateCollegeMapping(this.data)
  //     .subscribe(successCode => {
  //       if(successCode.code=="200")
  //       {
  //         this.message.success(' Information updated successfully...', '');
  //         if(!addNew)
  //           this.drawerClose();
  //           this.isSpinning = false;
  //       }
  //       else
  //       {
  //         this.message.error(' Failed to update information...', '');
  //         this.isSpinning = false;
  //       }
  //     });
  //   }
  //   else{

  //       this.api.createCollegeMapping(this.data)
  //       .subscribe(successCode => {
  //         if(successCode.code=="200")
  //         {
  //           this.message.success(' Information save successfully...', '');
  //           if(!addNew)
  //          this.drawerClose();
  //             else
  //             {
  //               this.data=new BundleAssessmentMapping();
  //               this.resetDrawer(collegeMapPage);
  //               // this.data.img= '';

  //               this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
  //                 // if (data['count']==0){
  //                 //   this.data.SEQUENCE_NO=1;
  //                 // }else
  //                 // {
  //                 //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
  //                 // }
  //               },err=>{
  //                 console.log(err);
  //               })
  //             }
  //             this.isSpinning = false;
  //           }
  //            else
  //            {
  //             this.message.error(' Failed to save information...', '');
  //             this.isSpinning = false;
  //            }
  //           });
  //         }
  // }

  // // else
  // // {
  // //   this.message.error("Please Fill All Required Fields...","");
  // //   this.isSpinning = false;
  // // }
  //   }
  //save
  display: boolean = false;
  save(addNew: boolean, collegeMapPage: NgForm): void {

    this.isOk = true;
    // console.log(this.dataList)
    //     if (this.data.COLLEGE_ID==0  && this.data.VALID_FROM!= undefined
    //       && this.data.VALID_TO!= undefined && this.data.STUDENT_COUNT==0 ){
    //       this.isOk =false
    //      this.message.error(" Please fill all required fields", "");
    //     }else

    //     if(this.data.COLLEGE_ID.length==0 ){
    //       this.isOk =false
    //       this.message.error('Please select college name','')
    //     }else

    //     if(this.data.VALID_FROM== undefined || this.data.VALID_FROM== null){
    //      this.isOk =false
    //      this.message.error('Please select valid from date','')
    //     }else

    //     if(this.data.VALID_TO== undefined || this.data.VALID_TO== null){
    //      this.isOk =false
    //      this.message.error('Please select valid to date','')
    //     }else

    //     if(this.data.STUDENT_COUNT== undefined || this.data.STUDENT_COUNT<=0){
    //      this.isOk =false
    //      this.message.error('Please enter maximum student count','')
    // }
    for (var i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].IS_ACTIVE == true) {
        if (
          this.dataList[i].ENABLE_TIME == null ||
          this.dataList[i].DISABLE_TIME == null
        ) {
          this.isOk = false;
        }
      }

      if (i + 1 == this.dataList.length) {
        if (this.isOk == false) {
          this.message.error(
            "Please Select Both Enable Date And Disable Date",
            ""
          );
        } else {
          if (this.isOk) {
            // this.isSpinning=false;
            // this.data.LATITUDE = this.latitude.toString();
            // this.data.LONGITUDE   = this.longitude.toString();

            this.isSpinning = true;
            if (this.data.ID) {
              for (var i = 0; i < this.dataList.length; i++) {
                if (this.dataList[i].ENABLE_TIME == undefined) {
                  this.dataList[i].ENABLE_TIME = null;
                } else {
                  this.dataList[i].ENABLE_TIME = this.datePipe.transform(
                    this.dataList[i].ENABLE_TIME,
                    "yyyy-MM-dd HH:mm:ss"
                  );
                }
                if (this.dataList[i].DISABLE_TIME == undefined) {
                  this.dataList[i].DISABLE_TIME = null;
                } else {
                  this.dataList[i].DISABLE_TIME = this.datePipe.transform(
                    this.dataList[i].DISABLE_TIME,
                    "yyyy-MM-dd HH:mm:ss"
                  );
                }
              }
              // this.isOk = true
              // for(var j=0;j< this.dataList.length; j++){
              //   if(this.dataList[j].IS_ACTIVE == true){
              //     this.dataList[j].ENABLE_TIME != undefined &&
              //     this.dataList[j].DISABLE_TIME != undefined
              //   }else{
              //     this.dataList[j].ENABLE_TIME=this.datePipe.transform(this.dataList[j].ENABLE_TIME,"yyyy-MM-dd HH:mm:ss");
              //     this.dataList[j].DISABLE_TIME=this.datePipe.transform(this.dataList[j].DISABLE_TIME,"yyyy-MM-dd HH:mm:ss");

              //   }
              // }
              this.api
                .updateAssessmengtMapping(this.dataList, this.data.ID)
                .subscribe((successCode) => {
                  if (successCode.code == "200") {
                    this.message.success(
                      " Information updated successfully...",
                      ""
                    );
                    if (!addNew) this.drawerClose();
                    this.isSpinning = false;
                    this.searchText = "";
                  } else {
                    this.message.error(
                      " Failed to update information...",
                      ""
                    );
                    this.isSpinning = false;
                  }
                });
            } else {
              this.api
                .createCollegeMapping(this.data2)
                .subscribe((successCode) => {
                  if (successCode.code == "200") {
                    this.message.success(
                      " Information save successfully...",
                      ""
                    );
                    this.searchText = "";
                    if (!addNew) this.drawerClose();
                    else {
                      this.data2 = new BundleAssessmentMapping();
                      this.resetDrawer(collegeMapPage);
                      // this.data.img= '';

                      // this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
                      // if (data['count']==0){
                      //   this.data.SEQUENCE_NO=1;
                      // }else
                      // {
                      //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                      // }
                      // },err=>{
                      //   console.log(err);
                      // })
                    }
                    this.isSpinning = false;
                  } else {
                    this.message.error(" Failed to save information...", "");
                    this.isSpinning = false;
                  }
                });
            }
          }
        }
      }
    }

    // else
    // {
    //   this.message.error("Please Fill All Required Fields...","");
    //   this.isSpinning = false;
    // }
  }
// b =[]
  onItemChecked(
    id: number,
    checked: boolean,
    ind: any,
    dates: any,
    disable: any
  ): void {
    console.log(id, ind, checked, dates, disable, "ggg");
    if (checked) {
      this.dataList[ind].IS_ACTIVE = true;
      if (dates != null && disable != null) {
        this.dataList[ind].ENABLE_TIME = dates;
        this.dataList[ind].DISABLE_TIME = disable;
      }
  // this.b=[
  //       {
  //        ID: id,
  //       },
  //     ];

    //  console.log('b::',this.b.length);
     
      // else {
      //   // this.message.error("Also Select Enable Date And Disable Date", "");
      //   // this.message.info("Also Select Enable Date And Disable Date", "");

      // }
    } else
      if (this.dataList[ind].ENABLE_TIME < this.time && this.dataList[ind].DISABLE_TIME < this.time) {
        this.dataList[ind].IS_ACTIVE = false;
        this.dataList[ind].ENABLE_TIME = this.dataList[ind].ENABLE_TIME;
        this.dataList[ind].DISABLE_TIME = this.dataList[ind].DISABLE_TIME;
      } else
        if (this.dataList[ind].ENABLE_TIME < this.time) {
          this.dataList[ind].IS_ACTIVE = false;
          this.dataList[ind].ENABLE_TIME = this.dataList[ind].ENABLE_TIME;
          this.dataList[ind].DISABLE_TIME = null;
        } else {
          this.dataList[ind].IS_ACTIVE = false;
          this.dataList[ind].ENABLE_TIME = null;
          this.dataList[ind].DISABLE_TIME = null;
        }

    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  // onAllChecked(value: boolean): void {
  //   console.log('value',value);
  //   // if(value == true){
  //   //   // this.checkedCount = 2
  //   //   // console.log('this.checkedCount22',this.checkedCount);
  //   //   this.isSpinning = true;
  //   //   this.api
  //   //   .getAllAssessment(
  //   //     0,
  //   //     0,
  //   //     '',
  //   //     '',
  //   //     this.data.ID,
  //   //     this.searchText
  //   //   )
  //   //   .subscribe(
  //   //     (data) => {
  //   //       if(data['code']==200){
  //   //       var listOfData = data["data"];
  //   //       listOfData.forEach((item) => {    
  //   //           item.IS_ACTIVE = true;
  //   //         this.updateCheckedSet(item.MOCK_ID, true);
  //   //       });
  //   //       this.refreshCheckedStatus();
  //   //       this.isSpinning = false;
  //   //     }
  //   //     },
  //   //     (err) => {
  //   //       console.log(err);
  //   //     }
  //   //   );
  //   // } else if(value == false)
  //   // {
  //   //   // this.checkedCount = 3
  //   //   // console.log('this.checkedCount33',this.checkedCount);
  //   //   this.isSpinning = true;
  //   //   this.api
  //   //   .getAllAssessment(
  //   //     0,
  //   //     0,
  //   //     '',
  //   //     '',
  //   //     this.data.ID,
  //   //     this.searchText
  //   //   )
  //   //   .subscribe(
  //   //     (data) => {
  //   //       if(data['code']==200){         
  //   //       var listOfData = data["data"];
  //   //       listOfData.forEach((item) => {    
  //   //           item.IS_ACTIVE = false;
  //   //         this.updateCheckedSet(item.MOCK_ID, false);
  //   //       });
  //   //       this.refreshCheckedStatus();
  //   //       this.isSpinning = false;
  //   //     }
  //   //     },
  //   //     (err) => {
  //   //       console.log(err);
  //   //     }
  //   //   );
  //   // } else{}
  //   this.dataList.forEach((item) => {
  //     if (value) {
  //       item.IS_ACTIVE = true;
  //     } else {
  //       item.IS_ACTIVE = false;
  //     }
  //     this.updateCheckedSet(item.MOCK_ID, value);
  //   });
  //   this.refreshCheckedStatus();
  // }

  onAllChecked(value: boolean): void {
    this.dataList.forEach((item) => {
      if (value) {
        item.IS_ACTIVE = true;
        
      } else {
        item.IS_ACTIVE = false;
      }
      this.updateCheckedSet(item.MOCK_ID, value);
    });
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    console.log(id, checked, "ggg2");
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.dataList.every((item) => {
      // console.log(this.setOfCheckedId.has(item.MOCK_ID),'ggg33')
      this.setOfCheckedId.has(item.MOCK_ID);
    });
    this.indeterminate =
      this.dataList.some((item) => this.setOfCheckedId.has(item.MOCK_ID)) &&
      !this.checked;
  }

  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || "";
    const sortOrder = (currentSort && currentSort.value) || "desc";
    console.log(currentSort);

    console.log("sortOrder :" + sortOrder);
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    if (this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }

  // disabledStartDate = (current: Date): boolean =>
  // differenceInCalendarDays(current, this.today2) < 0;

  // startDateChange(event,id){
  //   this.endDate = event
  //   this.endDateId = id 
  //     this.array.push(id)
  //   console.log('Event:',this.endDate);
  //   console.log('ID:',id);
  //   this.disabledEndDate()
  // }
  // disabledEndDate(){
  //   console.log('array:',this.array);
  //   console.log('array:',this.array.length);
  //   for (var l = 0; l < this.dataList.length; l++) {
  //     if (this.dataList[l].ENABLE_TIME != undefined) {
  //       if(this.endDateId == this.dataList[l].ID){
  //     this.a=(current: Date): boolean =>
  //     differenceInCalendarDays(current, this.endDate) < 0;
  //       }
  // } else{
  //   this.a=(current: Date): boolean =>
  //   differenceInCalendarDays(current, this.today2) < 0;
  // }
  //   }
  // }

  disableEndDate2(i) {
    if (this.dataList[i].ENABLE_TIME != undefined) {
      return (current: Date): boolean =>
        differenceInCalendarDays(current, new Date(this.dataList[i].ENABLE_TIME)) < 0;
    } else {
      return (current: Date): boolean =>
        differenceInCalendarDays(current, this.today2) < 0;
    }
  }
  disabledStartDate2(i) {
    if (this.dataList[i].DISABLE_TIME != undefined) {
      return (current: Date): boolean =>
        differenceInCalendarDays(current, new Date(this.dataList[i].DISABLE_TIME)) > 0
        ||
        differenceInCalendarDays(current, this.today2) < 0
    } else {
      return (current: Date): boolean =>
        differenceInCalendarDays(current, this.today2) < 0;
    }
  }
}
