import { Component, OnInit, Input } from '@angular/core';
import { Mappedassignment2 } from 'src/app/Models/AssignmentModule/mappedassignment';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-result2',
  templateUrl: './result2.component.html',
  styleUrls: ['./result2.component.css']
})
export class ResultComponent2 implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Mappedassignment2;
  formTitle = "Result"
  searchText: string = ""
  dataList = []
  loadingRecords = false
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  sortValue: string = "desc";
  sortKey: string = "ID";
  logtext: string = "";
  ASSIGNMENT_ID: number
  columns: string[][] = [['STUDENT_NAME', 'Student Name'] ,['STARTED_TIME', 'Started Time'], ['COMPLETED_TIME', 'Completed Time'], ['TOTAL_QUESTION_GENERATED','Total Question Generated'],['SOLVED','Solved Quetions'],['TOTAL_SEC','Total Time'],['SOLVED_SEC','Solved Time'],['AVG_SEC','Avg. Time'],['CORRECT','Correct'],['INCORRECT','Incorrect'],['POSITIVE_MARKS','+ve Marks'],['NEGATIVE_MARKS','-ve Marks'],['PERCENTAGE','Percentage'],['PERFORMANCE_INDEX','Performance Index'],['CONTRAVENTION_COUNT','Contravention Count'],['RESUME_COUNT','Resume Count']]
  columns1: string[][] = [['STUDENT_NAME', 'Student Name'] ,['STARTED_TIME', 'Started Time'], ['COMPLETED_TIME', 'Completed Time'], ['CONTRAVENTION_COUNT', 'Contravation Count'], ['RESUME_COUNT', 'Resume Count'], ['MARKS', 'Marks'], ['PERCENTAGE', 'Percentage']]
  string1 = "&#39;<-Single quote,double quote-> &#34;&#92;(ax^2 + bx + c = 0&#92;)&#34;&#39;"

  constructor(public api: ApiService) { }

  ngOnInit() {
  }

  getFullName(fname, mname, lname) {
    var fname1 = fname == null ? "" : fname
    var mname1 = mname == null ? "" : mname
    var lname = lname == null ? "" : lname
    return fname1 + " " + mname1 + " " + lname
  }

  getTestStatus(value) {
    if (value == 'S')
      return "Started"
    else if (value == 'V')
      return "Violated"
    else if (value == 'T')
      return "Terminated"
    else
      return "Completed"
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    
    if(this.ASSIGNMENT_ID != undefined)
    this.search(true);

  }


  getassignmentId(assignmentId) {
    this.ASSIGNMENT_ID = assignmentId
    console.log('getassignmentId called')
    this.search()
  }

  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - CurrentStatusOfAssesment form " + sort + " " + this.sortKey + " KEYWORD [F - CurrentStatusOfAssesment] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            console.log(successCode);
          }
          else {
            console.log(successCode);
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND MAPPED_MOCK_MASTER_ID=" + this.ASSIGNMENT_ID + " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }
    else {
      likeQuery = " AND MAPPED_MOCK_MASTER_ID=" + this.ASSIGNMENT_ID
      // likeQuery=""
    }

    this.logtext = "Filter Applied - CurrentStatusOfAssesment form " + likeQuery + " KEYWORD [F - CurrentStatusOfAssesment] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    console.log(likeQuery)

    this.api.getResultMocks(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      console.log("data")
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList)
    }, err => {
      console.log(err);
    });
  }

}
