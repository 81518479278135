import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-mappedquestiondata2',
  templateUrl: './mappedquestiondata2.component.html',
  styleUrls: ['./mappedquestiondata2.component.css']
})
export class MappedquestiondataComponent2 implements OnInit {

  @Input() drawerClose: Function;
  @Input() dataList
  @Input() node
  @Input() TYPE
  @Input() questionDataLength
  @Output() questionCount = new EventEmitter<string[]>()
  selectedQuestionCount: string
  isSpinning = false
  checkAll = false
  disableQuestion=false
  selectedQuesCount:number=0
  dataListLevels=[]
  maxQuesCount =0
  constructor(public api: ApiService) { }

  ngOnInit() {


  }

  loadLevels(id, name) {
    let filter = " AND ID=" + id
    this.api.getAllLevels(0, 0, 'ID', 'asc', filter).subscribe(data => {
      this.dataListLevels = data['data'];
      if (this.node[name] == 0)
        this.node[name] = this.dataListLevels[0]['SECONDS']


    }, err => {
      console.log(err);
    });
  }

  select() {
    console.log(this.dataList)
    var data1 = []
    data1 = this.dataList.filter(object => {
      return object['IS_SELECTED'] == 1;
    });

    // if (this.dataList[0]['LEVEL_ID'] == 1)
    // {

    //   this.node['L1_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L1_QUESTION_DATA']=this.dataList
    //   this.loadLevels(1,"L1_TIME")

    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 2)
    // {
    //   this.node['L2_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L2_QUESTION_DATA']=this.dataList
    //   this.loadLevels(2,"L2_TIME")
    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 3)
    // {
    //   this.node['L3_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L3_QUESTION_DATA']=this.dataList
    //   this.loadLevels(3,"L3_TIME")
    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 4)
    // {
    //   this.node['L4_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L4_QUESTION_DATA']=this.dataList
    //   this.loadLevels(4,"L4_TIME")
    // }
    // else if (this.dataList[0]['LEVEL_ID'] == 5)
    // {
    //   this.node['L5_QUESTIONS_SELECTED_COUNT'] = data1.length
    //   this.node['L5_QUESTION_DATA']=this.dataList
    //   this.loadLevels(5,"L5_TIME")

    // }

    this.drawerClose()




  }

  checkAllChanged(value, data) {
    if (value) {
      data.forEach(element => {
        element['IS_SELECTED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_SELECTED'] = 0
      });
    }
    this.getCheckSelectedQuestionCount()
  }

  changeQuestion(value, data) {
    if (value)
      data.IS_SELECTED = 1;
    else
      data.IS_SELECTED = 0

    this.getCheckSelectedQuestionCount()

  }

  getDataList(dataList, node,count) {
    this.dataList = dataList
    this.checkAll = false
    this.node = node
    this.maxQuesCount = count
  }

  getCheckSelectedQuestionCount() {

    var data1filter = []

    data1filter = this.dataList.filter(object => {
      return object['IS_SELECTED'] == 1;
    });
    this.selectedQuesCount = data1filter.length


    if (this.dataList[0]['LEVEL_ID'] == 1) {
      if (data1filter.length <= this.node['L1_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 2) {
      if (data1filter.length <= this.node['L2_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 3) {
      if (data1filter.length <= this.node['L3_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 4) {
      if (data1filter.length <= this.node['L4_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }
    else if (this.dataList[0]['LEVEL_ID'] == 5) {
      if (data1filter.length <= this.node['L5_QUESTIONS'])
        this.disableQuestion = false
      else
        this.disableQuestion = true
    }

    // console.log(this.disableQuestion)

  }



  close() {
    this.drawerClose()
  }
}

