import { Component, OnInit, Input } from '@angular/core';
import { Instruction2 } from 'src/app/Models/AssignmentModule/instruction';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-instruction2',
  templateUrl: './instruction2.component.html',
  styleUrls: ['./instruction2.component.css']
})
export class InstructionComponent2 implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Instruction2;
  isSpinning = false
  logtext: string = "";
  userId = Number(sessionStorage.getItem('userId'));
  dataList = []
  isOk = true;
  listdata1 = []
  @ Input() listdata2 = []

  constructor(public api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
    // this.loadData()
  }
 /////check double record
   getallorg1() {
    this.api.getAllMockInstructions(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1)
      }
    }, err => {
      console.log(err);
    });
  }

  // loadData() {
  //   this.api.getAllInstructions(0, 0, 'ID', 'desc', "").subscribe(data => {
  //     this.dataList = data['data'];
  //   }, err => {
  //     console.log(err);
  //   });
  // }


  // close(): void {
  //   this.drawerClose();

  //   this.logtext = 'CLOSED - Instruction form';
  //   this.api.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }

////

resetDrawer(mockInstructionPage: NgForm) {
  this.data=new Instruction2();
  // mockInstructionPage.form.reset();

  mockInstructionPage.form.markAsPristine();
  mockInstructionPage.form.markAsUntouched();
}

close(mockInstructionPage: NgForm) {
  this.drawerClose();
  this.resetDrawer(mockInstructionPage);
  // mockInstructionPage.form.reset();
  this.logtext = 'CLOSED - Instruction form';
  this.api.addLog('A', this.logtext, this.api.emailId)
    .subscribe(successCode => {
      if (successCode['code'] == "200") {
        console.log(successCode);
      }
      else {
        console.log(successCode);
      }
    });

}
//save
save(addNew: boolean,mockInstructionPage: NgForm): void {            
  this.isSpinning = false;
  this.isOk=true;
  if (
    this.data.NAME.trim() == '' &&
    this.data.INSTRUCTION_TEXT.trim()==''
  ) {
    this.isOk = false;
    this.message.error(' Please Fill All Required Fields ', '');

  } else if (this.data.NAME.trim() == "" || this.data.NAME == null) {
    this.isOk = false;
    this.message.error('Please Enter Name ', '');
    
  } else if (this.data.INSTRUCTION_TEXT.trim() == "" || this.data.NAME == null) {
    this.isOk = false;
    this.message.error('Please Enter Instruction Text ', '');
}
if(this.isOk)
{
// this.isSpinning=false; 
// this.data.LATITUDE = this.latitude.toString();
// this.data.LONGITUDE   = this.longitude.toString();
this.isSpinning=true; 
if (this.data.ID) {
  var chapterData2 = this.listdata2.filter(obj => {
    return obj.NAME == this.data.NAME;
  });
  console.log('this.listdata2:',this.listdata2);

  console.log('Name:',chapterData2);
  console.log('Name:',chapterData2.length);
  
  if (chapterData2.length == 0) {
    // if(this.data.PARENT_ID != this.data.ID){
  this.api.updateMockInstruction(this.data)
  .subscribe(successCode => {
    if(successCode['code']=="200")
    {
      this.message.success(' Information updated successfully...', '');
      if(!addNew)
        this.drawerClose();
        this.isSpinning = false;
    }   
    else
    {
      this.message.error(' Failed to update information...', '');
      this.isSpinning = false;
    }
  });
// }
// else {
} else {
  this.message.error(" Name Already Exist. Please Enter Other Name...", "");
  this.isSpinning = false;
}
}
else {
  var chapterData = this.listdata1.filter(obj => {
    return obj.NAME == this.data.NAME;
  });
  console.log('this.listdata1:',this.listdata1);
  
  console.log('Name2:',chapterData);
  console.log('Name2:',chapterData.length);
  
  console.log(this.data);

  if (chapterData.length == 0) {

    this.api.createMockInstruction(this.data)
    .subscribe(successCode => {
      if(successCode['code']=="200")
      {
        this.message.success(' Information save successfully...', '');
        if(!addNew)
       this.drawerClose();
          else
          {
            this.data=new Instruction2();
            this.resetDrawer(mockInstructionPage);
            // this.data.img= '';
            // this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
              // if (data['count']==0){
              //   this.data.SEQUENCE_NO=1;
              // }else
              // {
              //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
              // }
            // },err=>{
            //   console.log(err);
            // })
          }
          this.isSpinning = false;
        }
         else if(successCode['code']=="400")
         {
          this.message.error(' Failed to save information...', '');
          this.isSpinning = false;
         } else{
          this.message.error(" Name Already Exist. Please Enter Other Name...", "");
        this.isSpinning = false;
         }
        });
      } else {
        // this.message.error("Chapter Name Already Exist. Please Enter Other Chapter Name...", "");
        // this.isSpinning = false;
      }
      }
}
}
// save(addNew: boolean,mockInstructionPage: NgForm): void {


//     if (this.data.NAME != undefined && this.data.NAME != "" && this.data.INSTRUCTION_TEXT != undefined && this.data.INSTRUCTION_TEXT != "") {
//       if (/^(\w+\s)*\w+$/.test(this.data.NAME) == true && /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(this.data.INSTRUCTION_TEXT) == true) {


//         if (this.data.ID) {


//           var filterData = this.dataList.filter(object => {
//             return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID
//           });

//           if (filterData.length > 0) {
//             this.message.error("Already Record Present in Database", "");
//           }
//           else {

//             this.isSpinning = true;

//             this.api.updateMockInstruction(this.data)
//               .subscribe(successCode => {
//                 if (successCode['code'] == "200") {
//                   this.message.success("Instruction information updated Successfully...", "");

//                   this.logtext = 'Update & Close - Instruction form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Instruction ]";
//                   this.api.addLog('A', this.logtext, this.api.emailId)
//                     .subscribe(successCode => {
//                       if (successCode['code'] == "200") {
//                         console.log(successCode);
//                       }
//                       else {
//                         console.log(successCode);
//                       }
//                     });

//                   if (!addNew)
//                     this.drawerClose();
//                     mockInstructionPage.form.markAsPristine();
//                     mockInstructionPage.form.markAsUntouched();
//                   this.isSpinning = false;
//                 }
//                 else {

//                   this.logtext = 'Update & Close - Instruction form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Instruction ]";
//                   this.api.addLog('A', this.logtext, this.api.emailId)
//                     .subscribe(successCode => {
//                       if (successCode['code'] == "200") {
//                         console.log(successCode);
//                       }
//                       else {
//                         console.log(successCode);
//                       }
//                     });

//                   this.message.error("Failed to update instruction information...", "");
//                   this.isSpinning = false;
//                 }
//               });
//           }
//         }
//         else {

//           var filterData = this.dataList.filter(object => {
//             return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID'] != this.data.ID;
//           });

//           if (filterData.length > 0) {
//             this.message.error("Already Record Present in Database", "");
//           }
//           else {
//             this.isSpinning = true
//             if (/^(\w+\s)*\w+$/.test(this.data.NAME) == true && /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(this.data.INSTRUCTION_TEXT) == true) {
//               this.api.createMockInstruction(this.data)
//               .subscribe(successCode => {
//                 if (successCode['code'] == "200") {
//                   this.message.success("Instruction information added successfully...", "");

//                   if (!addNew) {
//                     this.drawerClose();
//                     mockInstructionPage.form.markAsPristine();
//                     mockInstructionPage.form.markAsUntouched();
//                     this.logtext = 'Save & Close - Instruction form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Instruction ]";
//                     this.api.addLog('A', this.logtext, this.api.emailId)
//                       .subscribe(successCode => {
//                         if (successCode['code'] == "200") {
//                           console.log(successCode);
//                         }
//                         else {
//                           console.log(successCode);
//                         }
//                       });
//                   }
//                   else {
//                     this.data = new Instruction2();
//                     this.resetDrawer(mockInstructionPage);
//                     // this.loadData()
//                     this.data.IS_DEFAULT_SELECTED = true
//                     this.logtext = 'Save & New - Instruction form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Instruction ]";
//                     this.api.addLog('A', this.logtext, this.api.emailId)
//                       .subscribe(successCode => {
//                         if (successCode['code'] == "200") {
//                           console.log(successCode);
//                         }
//                         else {
//                           console.log(successCode);
//                         }
//                       });

//                   }
//                   this.isSpinning = false;
//                 }
//                 else {
//                   if(successCode['code'] == "401"){
//                     this.message.error(" Name Already Exists ...", "");
//                     this.isSpinning = false;
//                   } else {
//                   this.message.error("Failed to add instruction information...", "");
//                   this.isSpinning = false;
//                   this.logtext = 'Save & Close - Instruction form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Instruction ]";
//                   this.api.addLog('A', this.logtext, this.api.emailId)
//                     .subscribe(successCode => {
//                       if (successCode['code'] == "200") {
//                         console.log(successCode);
//                       }
//                       else {
//                         console.log(successCode);
//                       }
//                     });
//                   }
//                 }
//               });
//             }
//             else
//             {
//               this.message.error("Please Enter Valid  Name", "");
//             }
           
//           }
//         }
//       }
     
//     }
//     else {
//       if(this.data.NAME == undefined || this.data.NAME == "")
//       this.message.error("Please Enter Name", "");
//       else if(this.data.INSTRUCTION_TEXT == undefined || this.data.INSTRUCTION_TEXT == "")
//       this.message.error("Please Enter Instruction Text", "");
//     }

//   }


}
