import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Models/Commonmodule/user';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';



@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  formTitle = "Manage Users";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [ ["NAME", "Name"], ["EMAIL_ID", "Email"], ["MOBILE_NUMBER", "Mobile"], ["PASSWORD", "Password"]]

  drawerData2: string[];
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: User = new User();
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  pageSize2=10
  
  constructor(public api: ApiService, private message: NzNotificationService) { }
  ngOnInit() {
    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.search();
    }
  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  onKeyDownEvent(event)
  {
this.search(true)
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    var likeQuery = "";
    if (this.searchText != "") {
      // if(this.roleId!="1")
      // likeQuery=" AND ID IN (select USER_ID from view_user_role_mapping where PARENT_ID="+this.roleId +") AND("
      // else
      // likeQuery = " AND ID IN (select USER_ID from view_user_role_mapping) AND("
      
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) +")"
    }
    // else
    // {
    //   if(this.roleId!="1")
    //   likeQuery=" AND ID IN (select USER_ID from view_user_role_mapping where PARENT_ID="+this.roleId +") "
    //   else
    //   likeQuery=" AND ID IN (select USER_ID from view_user_role_mapping)"
    // }
    this.api.getAllUsers(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    // this.api.getAllUserMappingData(this.pageIndex, this.pageSize, this.sortKey, sort, "").subscribe(data => {
    //   console.log(data)
    //   this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    // }, err => {
    //   console.log(err);
    //   if (err['ok'] == false)
    //     this.message.error("Server Not Found", "");
    //  });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New User";
    this.drawerData = new User();
    this.drawerData.IS_ACTIVE = true
    this.drawerVisible = true;
  }

  edit(data: User): void {
    
    let roleIds = ""
    let filter = " AND USER_ID=" + data.ID
    this.api.getAllUserMappingData(0, 0, '', '', filter).subscribe(data1 => {
      console.log(data1['data'])
      data1['data'].forEach(element => {
        roleIds = roleIds + element['ROLE_ID'] + ","
      });
      let userId1 = roleIds.substring(0, roleIds.length - 1)
      var arrOfRoleId = userId1.split(',').map(function (item) {
        return parseInt(item, 10);
      });
      
      if (userId1 == "") {
        data.ROLE_DATA = []
      }
      else {
        data.ROLE_DATA = arrOfRoleId
      }
      
      this.drawerTitle = "Update User";
      this.drawerData = Object.assign({}, data);
      this.drawerVisible = true;
    }, err => {
      console.log(err);
    });

  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

}
