import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { Questionparagraph } from 'src/app/Models/MasterSyllabus/questionparagraph';
import { Paragraph } from 'src/app/Models/ShikuyaaModels/paragraph';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-shikuyaaparagraph',
  templateUrl: './shikuyaaparagraph.component.html',
  styleUrls: ['./shikuyaaparagraph.component.css'] 
})
export class ShikuyaaparagraphComponent implements OnInit {
  folderNameParagraph='QuestionParagraphImg'
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMdd')
  editorConfigPara: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50',
    minHeight: '50',
    maxHeight: '50',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter paragraph here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: ''
  }
  @Input() drawerClose: Function;
  @Input() data: Paragraph;

  isSpinning = false
  logtext: string = "";
  userId = sessionStorage.getItem('userId');
  fileData_PARAGRAPH_IMAGE_URL: File = null
  fkey = "hAB89AG4"
  dataList = []
  imageurl: any
  isokfile=true
  thumbnailImgHeight:number
thumbnailImgWeight:number
isheightwidth:boolean
@ViewChild('file', { static: false }) myInputVariable: ElementRef;

  constructor(private datePipe: DatePipe,public api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
 
  }


  // close(): void {
  //   this.drawerClose();

  //   this.logtext = 'CLOSED - QuestionParagraph form';
  //   this.api.addLog('A', this.logtext, this.api.emailId)
  //     .subscribe(successCode => {
  //       if (successCode['code'] == "200") {
  //         console.log(successCode);
  //       }
  //       else {
  //         console.log(successCode);
  //       }
  //     });

  // }

    ////

    resetDrawer(paragraphPage: NgForm) {
      this.data=new Paragraph();
      // paragraphPage.form.reset();
  
      paragraphPage.form.markAsPristine();
      paragraphPage.form.markAsUntouched();
    }
  
    close(paragraphPage: NgForm) {
      this.drawerClose();
      this.resetDrawer(paragraphPage);
      paragraphPage.form.reset();
      this.logtext = 'CLOSED - QuestionParagraph form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
    
    }
    //save
    save(addNew: boolean,paragraphPage: NgForm): void {
    
    console.log(this.data)
    if (this.data.PARAGRAPH_TEXT != undefined && this.data.PARAGRAPH_TEXT != "" ) {

        if(this.isokfile)
        {
          this.isSpinning = true;
          if (this.fileData_PARAGRAPH_IMAGE_URL) {
      
            var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.purpose.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.url=this.date1+number+"."+fileExt
            console.log("urlpara=",this.url)
            //console.log(this.fileDataIMAGE)
            this.api.onUploadnew(this.folderNameParagraph, this.purpose, this.url).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.fileData_PARAGRAPH_IMAGE_URL=this.url
                this.data.PARAGRAPH_IMG_VIDEO_URL=this.url
               
        this.updateData(addNew)
              }
            });
  
         
      }
          else {
            if (this.data.ID)
              this.updateData(false)
            else {
              if (this.data.PARAGRAPH_TYPE == "I") {
                this.data.PARAGRAPH_IMG_VIDEO_URL = ""
              }
              this.updateData(addNew)
  
            }
          }
        }
        else
        {
      this.message.error("Only Images are allowed ( JPG | PNG | JPEG)", "");

        }
     
      
    }
    else {

      if(this.data.PARAGRAPH_TEXT == undefined || this.data.PARAGRAPH_TEXT == "")
      this.message.error("Please Enter Paragraph Text", "");
    }
  }

  updateData(addNew: boolean) {

    console.log(this.data)

    console.log('PARAGRAPH_TEXT',this.data.PARAGRAPH_TEXT.length);
    if (this.data.PARAGRAPH_TEXT.length <= 2048) {
    if (this.data.ID) {

    

        this.api.updateShikuyaaParagraph(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("QuestionParagraph information updated Successfully...", "");
              this.logtext = 'Update & Close - QuestionParagraph form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - QuestionParagraph ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {

              this.logtext = 'Update & Close - QuestionParagraph form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - QuestionParagraph ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

              this.message.error("Failed to update questionParagraph information...", "");
              this.isSpinning = false;
            }
          });
      
    }
    else {
     
        this.api.createShikuyaaParagraph(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              this.message.success("QuestionParagraph information added successfully...", "");

              if (!addNew) {
                this.drawerClose();

                this.logtext = 'Save & Close - QuestionParagraph form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionParagraph ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.data = new Paragraph();
                // this.resetDrawer(paragraphPage);
                this.logtext = 'Save & New - QuestionParagraph form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionParagraph ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });

              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add questionParagraph information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - QuestionParagraph form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - QuestionParagraph ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });

            }
          });
     
    }
  } else{
    this.message.error(" The Size Of Paragraph Text Is Greater Than Its Limit...", "");
    this.isSpinning = false;
  }

  }


url
purpose
  onFileSelected_PARAGRAPH_IMAGE_URL(event) {

    this.isokfile = true
    //console.log(event)
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg']
    
    const isLt2M = event.target.files[0].size <= 10485760;
    
  
    //console.log(" size" + isLt2M)
    if (isLt2M) {
      if (!allowed_types.toString().match(event.target.files[0].type)) {
        this.isokfile = false
        this.message.error("Only Images are allowed in ( JPG | PNG | JPEG)", "");
        // this.showing=true
        setTimeout(() => {                          
          // this.showing=false
        }, 5000);
       
      }
  
      if (this.isokfile) {
        this.fileData_PARAGRAPH_IMAGE_URL = <File>event.target.files[0];
        this.purpose=<File>event.target.files[0];
        if (this.fileData_PARAGRAPH_IMAGE_URL) {
       
            var number = Math.floor(100000 + Math.random() * 900000)
            var fileExt = this.fileData_PARAGRAPH_IMAGE_URL.name.split('.').pop();
            // this.urls = 'Profile' + number + "." + fileExt
            this.url=this.date1+number+"."+fileExt
            console.log("urlpara=",this.url)
            //console.log(this.fileDataIMAGE)
            this.api.onUploadnew(this.folderNameParagraph, this.fileData_PARAGRAPH_IMAGE_URL, this.url).subscribe(res => {
              //console.log(res);
              if (res['code'] == "200") {
                this.fileData_PARAGRAPH_IMAGE_URL=this.url
                this.data.PARAGRAPH_IMG_VIDEO_URL=this.url
        
              }
            });
  
         
      }
    }
    else {
      // this.myInputVariable.nativeElement.value = null;
      if (!isLt2M){
      
        setTimeout(() => {                          
         
        }, 5000);
       
      }
       
  
  
    }
  
  }
  }

}