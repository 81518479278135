export class Questions {
    ID: number;
    CLIENT_ID: number;
    DIRECTION: string
    DESCRIPTION: string
    QUESTION: string
    OPTION_A: string
    OPTION_B: string
    OPTION_C: string
    OPTION_D: string
    OPTION_E: string
    ANSWER:string
}
