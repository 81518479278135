import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Assignment } from 'src/app/Models/AssignmentModule/assignment';
import { Assignmentgroup } from '../../../../Models/AssignmentModule/assignmentgroup';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService} from 'ng-zorro-antd/notification';
import { Branch } from 'src/app/Models/BatchMgmt/branch';
import { Passingyear } from 'src/app/Models/BatchMgmt/passingyear';
import { Division } from 'src/app/Models/BatchMgmt/division';
import { DatePipe } from '@angular/common';
import { Part } from 'src/app/Models/MasterSyllabus/part';
import { Section } from 'src/app/Models/MasterSyllabus/section';
import { Chapter } from 'src/app/Models/MasterSyllabus/chapter';
import { QuestionsdataComponent } from '../questionsdata/questionsdata.component';
import { Assignmentdetails } from 'src/app/Models/AssignmentModule/assignmentdetails';


@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css'],
  providers: [DatePipe]
})
export class AssignmentComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Assignment;
  current = 0;
  assignmentGroups: Assignmentgroup[]
  loadingGroups = false
  loadingInstructionsData = false
  instructionsData = []
  logtext: string = "";
  userId = sessionStorage.getItem('userId')
  loadingSaveAssignments = false
  loadingMapInstructions = false
  assignmentId: number
  assignmentDetails = []
  TOTAL_PARTS_SELECTED: number = 0
  TOTAL_SECTION_SELECTED: number = 0
  TOTAL_CHAPTER_SELECTED: number = 0
  TOTAL_SUBCHAPTERS: number = 0
  TOTAL_QUESTIONS: number = 0
  CLASSWORK_QUESTIONS: number = 0
  HOMEWORK_QUESTIONS: number = 0
  ASSIGNMNETS_QUESTIONS: number = 0
  TOTAL_TEMPLATES: number = 0
  TIME: string = "0"
  addTime: number = 0
  PRACTICE_QUESTIONS: number = 0
  AQGS: number = 0
  questionDetailsData = []
  drawerTitle: string = ""
  drawerVisible: boolean = false
  scope = "O"
  ENABLE_TIME
  DISABLE_TIME
  IS_RESULT_SHARING_TIME = true
  RESULT_SHARING_TIME
  PASSKEY = ""
  LINK = ""

  filterBranches: string[] = ['AL']
  filterPassingYear: string[] = ['AL']
  filterDivision: string[] = ['AL']
  passingYears: Passingyear[]
  divisions: Division[]
  branches: Branch[]
  batchDetailsData = []
  checkAll = false
  BATCH_DISABLE_TIME: Date
  BATCH_ENABLE_TIME: Date
  BATCH_IS_PUBLISHED = false
  BATCH_PUBLISHED_TIME: Date
  BATCH_IS_RESULT_SHARING_TIME: Date
  loadingSaveClose = false
  L1_TIME_DEFAULT: number
  L2_TIME_DEFAULT: number
  L3_TIME_DEFAULT: number
  L4_TIME_DEFAULT: number
  L5_TIME_DEFAULT: number
  BATCH_RESULT_SHARING_TIME: Date
  nodeObject = []
  isLocadingAssignmentDetails = false
  today = new Date();
  loadingBranches = false
  loadingPassingYears = false
  loadingDivisions = false
  loadingFilter = false
  loadbatchDetailsFilter = true
  loadingMapAssignment = false
  collegeId = sessionStorage.getItem('collegeId')
  modeType: string = ""
  branchfilterQuery = ""
  NAME: string = ""
  totalQuestions: number
  totalTime
  disableButton = false
  loadingParts = false
  parts: Part[]
  PART_ID: number
  loadingSections = false
  sections: Section[]
  SECTION_ID: number
  loadingChapters = false
  chapters: Chapter[]
  CHAPTER_ID: number
  isStatsSpinning = false
  assignmentDetailsCount = []
  visibleData = false
  TYPE: string
  flag: boolean = false
  @ViewChild(QuestionsdataComponent, { static: false }) questionData: QuestionsdataComponent;
  questionDataLength: number = 0
  TOTAL_TIME_IN_SECOND: number = 0
  SELECTED_ID
  GROUP_TYPE:string="N"
  L1_QUESTIONS:number=0
  L1_TIME:number
  L2_QUESTIONS:number=0
  L2_TIME:number
  L3_QUESTIONS:number=0
  L3_TIME:number
  L4_QUESTIONS:number=0
  L4_TIME:number
  L5_QUESTIONS:number=0
  L5_TIME:number

  subChaptersNodes=[]
  groupTypeVisible:boolean=true
  partId=0
  sectionId=0
  chapterId=0
  subchapterId=0
  TOTAL_QUESTION:number=0
  TOTAL_TIME:number=0
  TOTAL_TIME_STRING:string="0"
  L1_QUESTIONS_Allocated=0
  L1_QUESTIONS_COUNT=0
  L2_QUESTIONS_Allocated=0
  L2_QUESTIONS_COUNT=0
  L3_QUESTIONS_Allocated=0
  L3_QUESTIONS_COUNT=0
  L4_QUESTIONS_Allocated=0
  L4_QUESTIONS_COUNT=0
  L5_QUESTIONS_Allocated=0
  L5_QUESTIONS_COUNT=0

  assignmentDetailsData: Assignmentdetails = new Assignmentdetails();
  loadingAddQuestion=false

  constructor(public api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.loadAssignmentGroups()
  }




  loadAssignmentGroups() {
    this.loadingGroups = true;
    this.api.getAllAssignmentGroups(0, 0, '', '', '').subscribe(localName => {
      this.assignmentGroups = localName['data'];
      this.loadingGroups = false;
    }, err => {
      console.log(err);
      this.loadingGroups = false;
    });
  }

  cancel() {
    this.current -= 1;
  }

  saveAssignments() {

    if (this.data.GROUP_ID != undefined && this.data.NAME != undefined) {
      if (/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true) {

        console.log(this.data.ID)
        this.loadingSaveAssignments = true
        if (this.data.platforms[0]['checked'] == true && this.data.platforms[1]['checked'] == false)
          this.data.PLATFORM = 'M'
        else if (this.data.platforms[0]['checked'] == false && this.data.platforms[1]['checked'] == true)
          this.data.PLATFORM = 'W'
        else if (this.data.platforms[1]['checked'] == true && this.data.platforms[1]['checked'] == true)
          this.data.PLATFORM = 'B'

        if (this.data.DESCRIPTION == "")
          this.data.DESCRIPTION = " "

        //create assignment method
        if (this.data.ID) {
          this.api.updateAssignment(this.data)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Assignment information updated Successfully...", "");
                this.current += 1;
                this.getAssignmentId()
                this.loadingSaveAssignments = false
                this.logtext = 'Update & Close - Assignment form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Assignment ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.logtext = 'Update & Close - Assignment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - AssignmentGroup ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
                this.message.error("Failed to update information...", "");
                this.loadingSaveAssignments = false;
              }
            }, err => {
                console.log(err);
                this.loadingSaveAssignments = false
            });
        }
        else {
          this.api.createAssignment(this.data)
            .subscribe(successCode => {
              console.log(successCode)
              if (successCode['code'] == "200") {
                this.message.success("Assignment information added successfully...", "");
                this.current += 1;
                this.getAssignmentId()
                this.loadingSaveAssignments = false
                this.logtext = 'Save & Close - Assignment form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Assignment ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
              else {
                this.message.error("Failed to add Assignment information...", "");
                this.loadingSaveAssignments = false;
                this.logtext = 'Save & Close - Assignment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Assignment ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
              }
            }, err => {
              console.log(err);
              this.loadingSaveAssignments = false
          });
        }
      }
      else {
        this.message.error("Please Check Name...", "");

      }
    }
    else {
      this.message.error("Please Fill All Required Details...", "");
    }
    // this.current += 1;
  }

  changeIntructionSelect(value, data) {
    if (value)
      data.IS_SELECTED = 1;
    else
      data.IS_SELECTED = 0
  }

  getAssignmentId() {
    if (this.data.ID) {
      this.modeType = "U"
      this.assignmentId = this.data.ID
      this.loadInstructionData(this.assignmentId)
    }
    else {
      this.api.getAllAssignments(0, 0, "ID", "DESC", "").subscribe(data => {
        console.log("assignment data")
        this.modeType = "C"
        console.log(data['data'][0])
        this.assignmentId = data['data'][0]['ID']
        if (data['data'][0].PLATFORM == 'B')
          data['data'][0].platforms = [{ label: 'Mobile', value: 'M', checked: true },
          { label: 'Web', value: 'W', checked: true },]
        else if (data['data'][0].PLATFORM == 'M')
          data['data'][0].platforms = [{ label: 'Mobile', value: 'M', checked: true },
          { label: 'Web', value: 'W', checked: false },]
        else
          data['data'][0].platforms = [{ label: 'Mobile', value: 'M', checked: false },
          { label: 'Web', value: 'W', checked: true },]
        this.data = data['data'][0]
        this.loadInstructionData(this.assignmentId)
      }, err => {
        console.log(err);
      });
    }

  }


  loadInstructionData(assignmentId) {
    this.loadingInstructionsData = true;
    this.api.getAssignmentInstructions(assignmentId).subscribe(data => {
      console.log(data)
      data['data']=data['data'].sort(this.sortFunction1)
      const getPlayerMax_on = cod => data['data'].reduce((a,c)=>((+a[cod])<(+c[cod]))?c:a)

      const maxvalue = getPlayerMax_on('SEQ_NO')
      console.log(maxvalue)

      console.log(maxvalue['SEQ_NO'])
      let seqNo=maxvalue['SEQ_NO']+1
      data['data'].forEach(element => {
        if(element['SEQ_NO']==0)
        {
          element['SEQ_NO']=seqNo
          seqNo++
        }
        
      });

      console.log(data['data'])

      this.instructionsData =  data['data'].sort(this.sortFunction)
      this.loadingInstructionsData = false;
    }, err => {
      console.log(err);
      this.loadingInstructionsData = false;
    });
  }
  sortFunction1(a, b) {
    var dateA = a.ID
    var dateB = b.ID
    return dateA > dateB ? 1 : -1;
  };

  sortFunction(a, b) {
    var dateA = a.SEQ_NO
    var dateB = b.SEQ_NO
    return dateA > dateB ? 1 : -1;
  };

  saveMapInstructions() {

    console.log(this.instructionsData)

    var data1 = this.instructionsData.filter(object => {
      return object['IS_SELECTED'] == 1;
    });

    console.log(data1.length)

    if (data1.length > 0) {
      this.loadingMapInstructions = true;
      this.api.addAssignmentInstructions(this.assignmentId, this.instructionsData)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Instruction Mapped Successfully ...", "");
            this.loadingMapInstructions = false;
            this.assignmentDetails = []
            this.current += 1;
            this.visibleData = false
            // this.loadAssignmentDeatils()
            this.flag = false
            //this.loadParts()
            this.loadAllSubchapters()
            this.loadAllStats()
           
          }
          else {
            this.message.error("Instruction Mapping Failed...", "");
            this.loadingMapInstructions = false;
          }
        }, err => {
          console.log(err);
          this.loadingMapInstructions = false;
        });
    }
    else {
      this.message.error("Please Select Instructions...", "");
    }
    // this.loadQuestionsData()

    // this.current += 1;
  }



  loadAllSubchapters()
  {
    this.api.getAllSubChapter().subscribe(localName => {
      this.subChaptersNodes = localName['data'];

   
    }, err => {
      console.log(err);

    });
  }

  loadAssignmentDetails()
  {
    console.log(this.assignmentId,this.partId,this.sectionId,this.chapterId,this.subchapterId,this.GROUP_TYPE)
   
    this.isStatsSpinning = true;
    this.api.getAssignmentDetails1(this.assignmentId,this.partId,this.sectionId,this.chapterId,this.subchapterId,this.GROUP_TYPE).subscribe(data => {
      console.log(data)
     if(data['code']==200)
     {

     }
     else if(data['code']==300)
     {
      this.message.error("Cannot add...", "");
     }
    
      this.isStatsSpinning = false;
    }, err => {
      console.log(err);
      this.isStatsSpinning = false;
    });

  }


  subchapterChanged(id)
  {

    if(id.startsWith("b"))
    this.groupTypeVisible=true
    else
    this.groupTypeVisible=false

    this.partId=0
    this.sectionId=0
    this.chapterId=0
    this.subchapterId=0
 
    if (id.startsWith("p"))
    this.partId = id.substring(1)
    else if (id.startsWith("s"))
    this.sectionId = id.substring(1)
    else if (id.startsWith("c"))
    this.chapterId = id.substring(1)
    else if (id.startsWith("b"))
    this.subchapterId = id.substring(1)

    this.loadAssignmentDetails()
    
  }

  groupTypeChanged(groupType)
  {
    this.GROUP_TYPE=groupType
    this.loadAssignmentDetails()
  }


  changeLevelQuestionChanged(questionCount,levelType)
  {
    this.TOTAL_QUESTION=this.L1_QUESTIONS+this.L2_QUESTIONS+this.L3_QUESTIONS+this.L4_QUESTIONS+this.L5_QUESTIONS
  }

  changeLevelTimeChanged(time,levelType)
  {
    this.TOTAL_TIME=(this.L1_QUESTIONS * this.L1_TIME) + (this.L2_QUESTIONS * this.L2_TIME)+ (this.L3_QUESTIONS * this.L3_TIME) + (this.L4_QUESTIONS * this.L4_TIME) + (this.L5_QUESTIONS * this.L5_TIME)
    this.TOTAL_TIME_STRING=this.api.getSecondsToHms(this.TOTAL_TIME)
  }

  addQuestions()
  {
    
    if (this.SELECTED_ID!=undefined && this.L1_QUESTIONS!=undefined && this.L1_QUESTIONS.toString()!="" && this.L1_TIME.toString()!="" && this.L2_QUESTIONS!=undefined && this.L2_QUESTIONS.toString()!="" && this.L2_TIME.toString()!="" && this.L3_QUESTIONS!=undefined && this.L3_QUESTIONS.toString()!="" && this.L3_TIME.toString()!="" && this.L4_QUESTIONS!=undefined && this.L4_QUESTIONS.toString()!="" && this.L4_TIME.toString()!="" ) {
      console.log(this.SELECTED_ID)
      let selectedPartId=0
      let selectedSectionId=0
      let selectedChapterId=0
      let selectedSubchapterId=0

      if(this.SELECTED_ID.startsWith("p"))
      selectedPartId=this.SELECTED_ID.substring(1)
      else if(this.SELECTED_ID.startsWith("s"))
      selectedSectionId=this.SELECTED_ID.substring(1)
      else if(this.SELECTED_ID.startsWith("c"))
      selectedChapterId=this.SELECTED_ID.substring(1)
      else if(this.SELECTED_ID.startsWith("b"))
      selectedSubchapterId=this.SELECTED_ID.substring(1)
    
      this.assignmentDetailsData.ASSIGNMENT_ID = this.assignmentId
      this.assignmentDetailsData.PART_ID = selectedPartId
      this.assignmentDetailsData.SECTION_ID = selectedSectionId
      this.assignmentDetailsData.CHAPTER_ID = selectedChapterId
      this.assignmentDetailsData.SUBCHAPTER_ID = selectedSubchapterId
      this.assignmentDetailsData.QUESTION_TYPE=this.GROUP_TYPE
      this.assignmentDetailsData.AQG_ID=0
      this.assignmentDetailsData.L1_QUESTIONS=this.L1_QUESTIONS
      this.assignmentDetailsData.L1_TIME=this.L1_TIME
      this.assignmentDetailsData.L2_QUESTIONS=this.L2_QUESTIONS
      this.assignmentDetailsData.L2_TIME=this.L2_TIME
      this.assignmentDetailsData.L3_QUESTIONS=this.L3_QUESTIONS
      this.assignmentDetailsData.L3_TIME=this.L3_TIME
      this.assignmentDetailsData.L4_QUESTIONS=this.L4_QUESTIONS
      this.assignmentDetailsData.L4_TIME=this.L4_TIME
      this.assignmentDetailsData.L5_QUESTIONS=this.L5_QUESTIONS
      this.assignmentDetailsData.L5_TIME=this.L5_TIME


      console.log(this.assignmentDetailsData)
      this.loadingAddQuestion=true
      this.api.createAssignmentDeatails(this.assignmentDetailsData)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.loadingAddQuestion=false
          this.message.success("Assignment Details added successfully...", "");
        }
        else {
          this.message.error("Failed to add assignment details information...", "");
        }
      });
    }
    else
    {
      this.message.error("Please fill all Details", "");
    }
  }

  
  loadParts() {
    this.loadingParts = true;
    this.api.getAllParts(0, 0, 'ID', 'asc', '').subscribe(localName => {
      this.parts = localName['data'];
      this.PART_ID = localName['data'][0]['ID']
      this.partChange(this.PART_ID)
      this.loadingParts = false;
    }, err => {
      console.log(err);
      this.loadingParts = false;
    });
  }

  partChange(partId) {
    let filter = " AND PART_ID=" + partId
    this.loadingSections = true;
    this.api.getAllSections(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      this.sections = localName['data'];
      this.SECTION_ID = undefined
      this.CHAPTER_ID = undefined
      this.loadingSections = false;
    }, err => {
      console.log(err);
      this.loadingSections = false;
    });
  }

  sectionChange(sectionId) {
    let filter = " AND SECTION_ID=" + sectionId
    this.loadingChapters = true;
    this.api.getAllChapters(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      this.CHAPTER_ID = undefined
      this.chapters = localName['data'];
      this.loadingChapters = false;
    }, err => {
      console.log(err);
      this.loadingChapters = false;
    });
  }

  loadAllStats() {
    this.isStatsSpinning = true
    let filter = " AND ASSIGNMENT_ID=" + this.assignmentId
    this.api.getAllAssignmentDestails(0, 0, 'ID', 'asc', filter).subscribe(localName => {
      console.log("assignment details gird localName")
      console.log(localName)
      this.assignmentDetailsCount = localName['data'];
      this.TOTAL_QUESTIONS = 0
      this.TIME = "0"
      this.getAllStats()
      this.isStatsSpinning = false;
    }, err => {
      console.log(err);
      this.isStatsSpinning = false;
    });
  }

  getAllStats() {
    let l1time = 0
    let l2time = 0
    let l3time = 0
    let l4time = 0
    let l5time = 0
    let totalTime = 0
    this.assignmentDetailsCount.forEach(element => {
      this.TOTAL_QUESTIONS = this.TOTAL_QUESTIONS + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
      l1time = l1time + element['L1_QUESTIONS'] * element['L1_TIME']
      l2time = l2time + element['L2_QUESTIONS'] * element['L2_TIME']
      l3time = l3time + element['L3_QUESTIONS'] * element['L3_TIME']
      l4time = l4time + element['L4_QUESTIONS'] * element['L4_TIME']
      l5time = l5time + element['L5_QUESTIONS'] * element['L5_TIME']
      totalTime = l1time + l2time + l3time + l4time + l5time
      this.TOTAL_TIME_IN_SECOND = totalTime
      this.TIME = this.api.getSecondsToHms(totalTime)
    });
    console.log(this.TOTAL_QUESTIONS, this.TOTAL_TIME_IN_SECOND)

    console.log(this.flag)

    if (this.flag) {
      this.data.TOTAL_QUESTIONS = this.TOTAL_QUESTIONS
      this.data.TOTAL_TIME = this.TOTAL_TIME_IN_SECOND

      console.log(this.data)
      this.api.updateAssignment(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            // this.message.success("Assignment information updated Successfully...", "");

            this.logtext = 'Update & Close - Assignment form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Assignment ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
          }
          else {
            this.logtext = 'Update & Close - Assignment form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - AssignmentGroup ]";
            this.api.addLog('A', this.logtext, this.api.emailId)
              .subscribe(successCode => {
                if (successCode['code'] == "200") {
                  console.log(successCode);
                }
                else {
                  console.log(successCode);
                }
              });
            this.message.error("Failed to update information...", "");
          }
        });

    }

  }

  loadAssignmentDeatils() {

    if (this.SECTION_ID != undefined && this.CHAPTER_ID != undefined) {
      this.isLocadingAssignmentDetails = true
      this.api.getAssignmentDetails(this.assignmentId, this.PART_ID, this.SECTION_ID, this.CHAPTER_ID).subscribe(data => {
        console.log("details JSON")
        console.log(data)
        if (data['code'] == 200) {
          this.assignmentDetails = data['data'];
          this.TOTAL_QUESTIONS = data['data1'][0]['TOTAL_QUESTIONS']
          this.TIME = this.api.getSecondsToHms(data['data1'][0]['TOTAL_TIME'])
          this.getStats(this.assignmentDetails)
          this.TOTAL_QUESTIONS = 0
          this.TIME = "0"
          this.getAllStats()
          this.flag = false
          this.isLocadingAssignmentDetails = false
          this.visibleData = true
        }
        else {
          this.message.error("Failed To Get  ...", "");
          this.assignmentDetails = []
          this.isLocadingAssignmentDetails = false
        }
      }, err => {
        this.assignmentDetails = []
        this.isLocadingAssignmentDetails = false
        console.log(err);
      });
    }
    else {
      this.message.error("Please Select Section & Chapter...", "");
    }
  }

  viewQuestion(data) {
    this.TYPE = ""
    let filter = " AND ASSIGNMENT_DETAILS_ID=" + data.ID
    this.api.getAllAssignmentQuestions(0, 0, 'ID', 'asc', filter).subscribe(data => {
      this.questionDetailsData = data['data'];
      this.questionDataLength = data['count']
      console.log(this.questionDetailsData)
      this.drawerTitle = "Question For ";
      this.drawerVisible = true;
    }, err => {
      console.log(err);
    });

  }

  getStats(data) {
    this.resetValues()
    console.log('mapping update data')
    if (this.modeType == "C") {

    } else {
      console.log(data)

      data.forEach(element => {
        if (element['checked']) {
          //part

          if (element['key'].startsWith("p")) {
            this.TOTAL_PARTS_SELECTED = this.TOTAL_PARTS_SELECTED + 1
          }


        }
        if (element['children']) {
          //sections
          element['children'].forEach(element => {

            if (element['checked']) {
              if (element['key'].startsWith("s")) {
                this.TOTAL_SECTION_SELECTED = this.TOTAL_SECTION_SELECTED + 1
              }
            }

            if (element['children']) {
              //chapters
              element['children'].forEach(element => {
                if (element['checked']) {
                  if (element['key'].startsWith("c")) {
                    this.TOTAL_CHAPTER_SELECTED = this.TOTAL_CHAPTER_SELECTED + 1
                  }
                }

                if (element['children']) {
                  element['children'].forEach(element => {
                    if (element['checked']) {
                      if (element['key'].startsWith("r")) {
                        this.TOTAL_SUBCHAPTERS = this.TOTAL_SUBCHAPTERS + 1
                      }
                    }

                    if (element['children']) {
                      element['children'].forEach(element => {

                        if (element['checked'] && element['key'].startsWith("Classwork")) {
                          this.CLASSWORK_QUESTIONS = this.CLASSWORK_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Homework")) {
                          this.HOMEWORK_QUESTIONS = this.HOMEWORK_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Practice")) {
                          this.PRACTICE_QUESTIONS = this.PRACTICE_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("Assignment")) {
                          this.ASSIGNMNETS_QUESTIONS = this.ASSIGNMNETS_QUESTIONS + 1
                        }
                        if (element['checked'] && element['key'].startsWith("AQG")) {
                          this.AQGS = this.AQGS + 1
                          if (element['children']) {
                            element['children'].forEach(element => {
                              if (element['checked'] && element['key'].startsWith == 'T')
                                this.TOTAL_TEMPLATES = this.TOTAL_TEMPLATES + 1
                            });
                          }
                        }

                      });
                    }

                  });
                }


              });
            }



          });
        }

      });


    }
  }



  changeLevelTimeChange(seconds, levelText) {
    switch (levelText) {
      case "L1":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L1_TIME'] = seconds


          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L1_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L1_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L1_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L1_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L1_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L2":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L2_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L2_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L2_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L2_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L2_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L2_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L3":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L3_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L3_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L3_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L3_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L3_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L3_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L4":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L4_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L4_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L4_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L4_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L4_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L4_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;

      case "L5":
        this.assignmentDetails.forEach(element => {
          if (element['checked'] == true)
            element['L5_TIME'] = seconds

          if (element['children'].length > 0) {
            element['children'].forEach(element => {
              if (element['checked'] == true)
                element['L5_TIME'] = seconds

              if (element['children'].length > 0) {
                element['children'].forEach(element => {
                  if (element['checked'] == true)
                    element['L5_TIME'] = seconds

                  if (element['children'].length > 0) {
                    element['children'].forEach(element => {
                      if (element['checked'] == true)
                        element['L5_TIME'] = seconds

                      if (element['children'].length >= 0) {
                        element['children'].forEach(element => {
                          if (element['checked'] == true)
                            element['L5_TIME'] = seconds

                          if (element['children'].length >= 0) {
                            element['children'].forEach(element => {
                              if (element['checked'] == true)
                                element['L5_TIME'] = seconds
                            });
                          }

                        });
                      }
                    });
                  }

                });
              }
            });
          }
        });
        break;
    }
  }

  getcheckChange(event?) {

    var keys = event.keys
    this.resetValues()
    if (keys.length > 0) {
      keys.forEach(element => {
        if (element.startsWith("p")) {
          this.TOTAL_PARTS_SELECTED = this.TOTAL_PARTS_SELECTED + 1
        }
        else if (element.startsWith("s")) {
          this.TOTAL_SECTION_SELECTED = this.TOTAL_SECTION_SELECTED + 1
        }
        else if (element.startsWith("c")) {
          this.TOTAL_CHAPTER_SELECTED = this.TOTAL_CHAPTER_SELECTED + 1
        }
        else if (element.startsWith("r")) {
          this.TOTAL_SUBCHAPTERS = this.TOTAL_SUBCHAPTERS + 1
        }
        else if (element.startsWith("Classwork")) {
          this.CLASSWORK_QUESTIONS = this.CLASSWORK_QUESTIONS + 1
        }
        else if (element.startsWith("Homework")) {
          this.HOMEWORK_QUESTIONS = this.HOMEWORK_QUESTIONS + 1
        }
        else if (element.startsWith("Practice")) {
          this.PRACTICE_QUESTIONS = this.PRACTICE_QUESTIONS + 1
        }
        else if (element.startsWith("Assignment")) {
          this.ASSIGNMNETS_QUESTIONS = this.ASSIGNMNETS_QUESTIONS + 1
        }
        else if (element.startsWith("AQG")) {
          this.AQGS = this.AQGS + 1
        }
        else if (element.startsWith("T")) {
          this.TOTAL_TEMPLATES = this.TOTAL_TEMPLATES + 1
        }

      });

    }
    else {
      this.resetValues()
    }

    if (event.node.origin.checked) {
      if (event.node.origin.children ='') {
        if (event.node.origin.children.length >= 0) {
          if (event.node.origin.children ='') {
            event.node.origin.children.forEach(element => {
              element['disabled'] = true
              if (element['children'] ='') {
                element['children'].forEach(element => {
                  element['disabled'] = true
                  if (element['children'] ='') {
                    element['children'].forEach(element => {
                      element['disabled'] = true
                      if (element['children'] ='') {
                        element['children'].forEach(element => {
                          element['disabled'] = true
                          if (element['children'] ='') {
                            element['children'].forEach(element => {
                              element['disabled'] = true
                            });
                          }
                        });
                      }

                    });
                  }

                });
              }
            });
          }
        }
      }
    }
    else {
      console.log(event.node.origin.checked)
      if (event.node.origin.children ='') {
        if (event.node.origin.children.length >= 0) {
          event.node.origin.children.forEach(element => {
            element['disabled'] = false
            if (element['children'] ='') {
              this.disableButton = false
              element['children'].forEach(element => {
                element['disabled'] = false
                if (element['children'] ='') {
                  element['children'].forEach(element => {
                    element['disabled'] = false
                    if (element['children'] ='') {
                      element['children'].forEach(element => {
                        element['disabled'] = false
                        if (element['children'] ='') {
                          element['children'].forEach(element => {
                            element['disabled'] = false
                          });
                        }
                      });
                    }

                  });
                }

              });
            }
          });
        }
      }
    }


    this.disableButtonNew()
  }

  disableButtonNew() {
    var count = 0

    let partcheck = false
    let sectioncheck = false
    let chaptercheck = false
    let subchaptercheck = false
    let othercheck = false
    this.assignmentDetails.forEach(element => {
      partcheck = element['checked']
      if (element['children'] ='')
        element['children'].forEach(element => {
          sectioncheck = element['checked']
          if (element['disabled'] == true && element['checked'] == true)
            count++;
          else {
            if (element['children'] ='') {
              element['children'].forEach(element => {
                chaptercheck = element['checked']
                if (element['disabled'] == true && element['checked'] == true)
                  count++;
                else
                  if (element['children'] ='') {
                    element['children'].forEach(element => {
                      if (element['checked'])
                        subchaptercheck = element['checked']

                      if (element['disabled'] == true && element['checked'] == true)
                        count++;
                      else {
                        if (element['children'] ='') {
                          element['children'].forEach(element => {
                            if (element['checked'])
                              othercheck = element['checked']
                            if (element['disabled'] == true && element['checked'] == true)
                              count++;
                          });
                        }
                      }
                    });
                  }
              });
            }
          }

        });
    });



    if (partcheck)
      if (sectioncheck || chaptercheck || subchaptercheck || othercheck)
        count++
    if (sectioncheck)
      if (chaptercheck || subchaptercheck || othercheck)
        count++
    if (chaptercheck)
      if (subchaptercheck || othercheck)
        count++


    if (count == 0)
      this.disableButton = false
    else {
      this.disableButton = true
    }


    console.log(count)
  }

  resetValues() {
    this.TOTAL_PARTS_SELECTED = 0
    this.TOTAL_SECTION_SELECTED = 0
    this.TOTAL_CHAPTER_SELECTED = 0
    this.TOTAL_SUBCHAPTERS = 0
    this.CLASSWORK_QUESTIONS = 0
    this.PRACTICE_QUESTIONS = 0
    this.HOMEWORK_QUESTIONS = 0
    this.TOTAL_TEMPLATES = 0
    this.AQGS = 0
    this.ASSIGNMNETS_QUESTIONS = 0
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    //this.loadAssignmentDeatils()
    this.drawerVisible = false;
    console.log(this.assignmentDetails)
  }

  getQuestionClick(data, node, levelId) {

    console.log(node)
    console.log(data)

    if (data.length > 0) {

      this.nodeObject = node
      this.questionDetailsData = data
      this.drawerTitle = "Question For ";
      this.drawerVisible = true;
      this.questionData.getDataList(data,node)
      this.questionData.getCheckSelectedQuestionCount()
    }
    else {
      let partId = 0
      let sectionId = 0
      let chapterId = 0
      let subchapterId = 0
      let groupType = "N"
      if (node['key'].startsWith("p"))
        partId = node['key'].substring(1)
      if (node['key'].startsWith("s"))
        sectionId = node['key'].substring(1)
      if (node['key'].startsWith("c"))
        chapterId = node['key'].substring(1)
      if (node['key'].startsWith("r"))
        subchapterId = node['key'].substring(1)

      if (node['key'].startsWith("Classwork")) {
        groupType = "C"
        subchapterId = node['key'].substring(9)
      }
      if (node['key'].startsWith("Homework")) {
        groupType = "H"
        subchapterId = node['key'].substring(8)
      }
      if (node['key'].startsWith("Practice")) {
        groupType = "P"
        subchapterId = node['key'].substring(8)
      }
      if (node['key'].startsWith("Assignment")) {
        groupType = "A"
        subchapterId = node['key'].substring(10)
      }
      console.log(partId, sectionId, chapterId, subchapterId, groupType, levelId, this.assignmentId)

      this.isLocadingAssignmentDetails = true
      this.api.getAssignmentQuestionDetails(this.assignmentId, Number(partId), Number(sectionId), Number(chapterId), Number(subchapterId), groupType, Number(levelId))
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.isLocadingAssignmentDetails = false
            this.nodeObject = node
            this.questionDetailsData = successCode['data']
            this.questionData.checkAll = false
            this.questionData.selectedQuesCount = 0
            this.questionData.getDataList(this.questionDetailsData,node)
            this.questionData.getCheckSelectedQuestionCount()
            this.questionDataLength = successCode['data'].length
            this.TYPE = "SAVE"
            this.drawerTitle = "Question For ";
            this.drawerVisible = true;

          }
          else {
            this.message.error("Assignment Details Getting Failed...", "");
            this.isLocadingAssignmentDetails = false;
          }
        }, err => {
          console.log(err);
          this.isLocadingAssignmentDetails = false;
        });

    }


  }

  getTotalQuestionCount(value1) {


    this.getTotalStats()

    // this.TOTAL_QUESTIONS = this.TOTAL_QUESTIONS + value
    // console.log(this.TOTAL_QUESTIONS)

  }

  getTotalTime(value) {

    // this.addTime = this.addTime + value
    // this.TIME = this.api.getSecondsToHms(this.addTime)
  }

  getTime(l1ques, l1Time, l2ques, l2Time, l3ques, l3Time, l4ques, l4Time, l5ques, l5Time) {
    return (l1ques * l1Time) + (l2ques * l2Time) + (l3ques * l3Time) + (l4ques * l4Time) + (l5ques * l5Time)
  }


  getTotalStats() {
    console.log(this.assignmentDetails)
    let totalQuestions = 0
    let totalTime = 0
    let totalTimel1 = 0
    let totalTimel2 = 0
    let totalTimel3 = 0
    let totalTimel4 = 0
    let totalTimel5 = 0
    this.assignmentDetails.forEach(element => {
      //part
      if (element['checked']) {
        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
      }
      if (element['children'] ='') {
        //sections
        element['children'].forEach(element => {
          if (element['checked']) {
            totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
            totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
            totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
            totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
            totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
            totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
            totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
          }
          if (element['children'] ='') {
            //chapter
            element['children'].forEach(element => {
              if (element['checked']) {
                totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
              }
              if (element['children'] ='') {
                //subchapter
                element['children'].forEach(element => {
                  if (element['checked']) {
                    totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                    totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                    totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                    totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                    totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                    totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                    totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                  }
                  if (element['children'] ='') {
                    //classwork
                    element['children'].forEach(element => {
                      if (element['checked'] && element['key'].startsWith("Classwork")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                      }
                      if (element['checked'] && element['key'].startsWith("Homework")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                      }
                      if (element['checked'] && element['key'].startsWith("Practice")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                      }
                      if (element['checked'] && element['key'].startsWith("Assignment")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                      }
                      if (element['checked'] && element['key'].startsWith("AQG")) {
                        totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                        totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                        totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                        totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                        totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                        totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                        totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                        console.log(totalTime)
                        if (element['children']) {
                          element['children'].forEach(element => {
                            if (element['checked'] && element['key'].startsWith == 'T')
                              totalQuestions = totalQuestions + element['L1_QUESTIONS'] + element['L2_QUESTIONS'] + element['L3_QUESTIONS'] + element['L4_QUESTIONS'] + element['L5_QUESTIONS']
                            totalTimel1 = element['L1_QUESTIONS'] * element['L1_TIME']
                            totalTimel2 = element['L2_QUESTIONS'] * element['L2_TIME']
                            totalTimel3 = element['L3_QUESTIONS'] * element['L3_TIME']
                            totalTimel4 = element['L4_QUESTIONS'] * element['L4_TIME']
                            totalTimel5 = element['L5_QUESTIONS'] * element['L5_TIME']
                            totalTime = totalTime + totalTimel1 + totalTimel2 + totalTimel3 + totalTimel4 + totalTimel5
                          });
                        }
                      }

                    });

                  }


                });
              }

            });
          }

        });
      }
    });
    this.totalQuestions = totalQuestions
    this.totalTime = totalTime

    this.TOTAL_QUESTIONS = this.totalQuestions

    console.log(this.totalQuestions, totalTime)

  }

  getQuestionType(questionType) {
    if (questionType == "C")
      return "Classwork"
    else if (questionType == "H")
      return "Homework"
    else if (questionType == "P")
      return "Practice"
    else if (questionType == "A")
      return "Assignment"
    else if (questionType == "N")
      return "All"
  }

  saveMapQuestions() {

    // console.log( JSON.stringify(this.assignmentDetails))

    // if(this.assignmentDetails[0]['checked']==true)
    // {

    //   console.log(this.assignmentDetails[0]['key'],this.assignmentDetails[0]['L1_QUESTIONS'],this.assignmentDetails[0]['L2_QUESTIONS'],this.assignmentDetails[0]['L3_QUESTIONS'],this.assignmentDetails[0]['L4_QUESTIONS'],this.assignmentDetails[0]['L5_QUESTIONS'],this.assignmentDetails[0]['L1_TIME'],this.assignmentDetails[0]['L2_TIME'],this.assignmentDetails[0]['L3_TIME'],this.assignmentDetails[0]['L4_TIME'],this.assignmentDetails[0]['L5_TIME'])

    //   this.assignmentDetails.forEach(element => {
    //      element['children']
    //   });
    // }






    this.getTotalStats()
    this.TOTAL_QUESTIONS = this.totalQuestions
    this.TIME = this.api.getSecondsToHms(this.totalTime)

    this.loadingMapAssignment = true

    this.api.addAssignmentDetails(this.PART_ID, this.SECTION_ID, this.CHAPTER_ID, this.assignmentId, this.assignmentDetails, this.totalQuestions, this.totalTime)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Assignment Details Mapped Successfully ...", "");
          this.loadingMapAssignment = false
          this.flag = true
          this.loadAllStats()
          this.resetValues()
          this.visibleData = false
        }
        else {
          this.message.error("Assignment Details Mapped Mapping Failed...", "");
          this.loadingMapAssignment = false;
        }
      }, err => {
        console.log(err);
        this.loadingMapAssignment = false;
      });




    // this.getTotalStats()
    // this.TOTAL_QUESTIONS = this.totalQuestions

    // this.TIME = this.api.getSecondsToHms(this.totalTime)
    // console.log(this.totalTime)
    // console.log(this.TIME)
    // // let tTime = this.api.getSecondsToHms(this.totalTime)


    // if (flag == "onlysave")
    //   this.loadingMapQuestions = true
    // else
    //   this.loadingMapAssignment = true

    // this.api.addAssignmentDetails(this.assignmentId, this.assignmentDetails, this.totalQuestions, this.totalTime)
    //   .subscribe(successCode => {
    //     if (successCode['code'] == "200") {
    //       this.message.success("Assignment Details Mapped Successfully ...", "");
    //       if (flag == "onlysave") {
    //         this.drawerClose()
    //         this.loadingMapQuestions = false;
    //       }
    //       else {
    //         this.current += 1;
    //         this.loadingMapAssignment = false
    //       }
    //       this.getDeatilsData()
    //     }
    //     else {
    //       this.message.error("Assignment Details Mapped Mapping Failed...", "");
    //       this.loadingMapQuestions = false;
    //     }
    //   }, err => {
    //     console.log(err);
    //     this.loadingMapQuestions = false;
    //   });


  }

  saveAndNext() {
    this.current += 1;
    this.getDeatilsData()

  }

  getDeatilsData() {

    this.loadBranches()
    this.getBatchDetailsData()

    console.log(this.scope)
    console.log(this.data)
    this.ENABLE_TIME = undefined
    this.IS_RESULT_SHARING_TIME = true
    // this.IS_RESULT_SHARING_TIME=this.IS_RESULT_SHARING_TIME?true:false
    this.DISABLE_TIME = undefined
    this.RESULT_SHARING_TIME = undefined

    if(this.data.NAME!=undefined)
    this.NAME = this.data.NAME
   
    this.PASSKEY = this.api.generateRandomNumber(6).toString()
    this.LINK = this.api.randomstring(9)
  }

  loadBranches() {
    //get all mapped branches to college
    this.loadingBranches = true

    let filter = " AND COLLEGE_ID=" + this.collegeId
    this.api.getAllCollegeBranches(0, 0, '', '', filter).subscribe(data => {
      this.branches = data['data'];
      this.loadPassingYears()
      this.loadingBranches = false
    }, err => {
      console.log(err);
    });
  }

  getBranchIdss(value) {
    this.loadPassingYears(value)
  }

  loadPassingYears(value?) {
    let filterQuery = ""
    this.loadingPassingYears = true

    let barnchstring: string = "";
    if (value != undefined) {
      value.forEach(passingYears => {
        barnchstring += "'" + passingYears + "'" + ",";
      }); (value)

      if (barnchstring.toString() != "") {
        if (barnchstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " BRANCH_ID IN (" + barnchstring.substring(0, barnchstring.length - 1) + ") ";
        }
      }
    }

    this.branchfilterQuery = filterQuery
    console.log(this.branchfilterQuery)
    if (filterQuery != "") {
      let filter = "AND ID in (select YEAR_ID from view_college_training_course_branch_year_mapping where " + filterQuery + ")"
      this.api.getAllPassingYears(0, 0, '', '', filter).subscribe(data => {
        console.log("year")
        this.passingYears = data['data'];
        console.log(this.passingYears)
        this.loadingPassingYears = false
      }, err => {
        console.log(err);
      });
    }
    else {
      let filter = ""
      this.api.getAllPassingYears(0, 0, '', '', filter).subscribe(data => {
        this.passingYears = data['data'];
        console.log("year with college")
        console.log(this.passingYears)
        this.loadDivisions()
        this.loadingPassingYears = false
      }, err => {
        console.log(err);
      });
    }
  }

  getPassingYearIdss(value) {
    this.loadDivisions(value)
  }

  loadDivisions(value?) {
    this.loadingDivisions = true
    let filterQuery = ""
    let passingyearstring: string = "";
    if (value != undefined) {
      value.forEach(passingYears => {
        passingyearstring += "'" + passingYears + "'" + ",";
      }); (value)

      if (passingyearstring.toString() != "") {
        if (passingyearstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " YEAR_ID IN (" + passingyearstring.substring(0, passingyearstring.length - 1) + ") ";
        }
      }
    }

    if (filterQuery != "") {
      let filter = " AND ID in (select DIVISION_ID from view_college_training_course_branch_year_division_mapping where " + this.branchfilterQuery + " and " + filterQuery + ")"
      this.api.getAllDivisions(0, 0, '', '', filter).subscribe(data => {
        this.divisions = data['data'];
        this.loadingDivisions = false
      }, err => {
        console.log(err);
      });
    }
    else {

      let filter = " "
      this.api.getAllDivisions(0, 0, '', '', filter).subscribe(data => {
        this.divisions = data['data'];
        this.loadingDivisions = false
      }, err => {
        console.log(err);
      });
    }

    // this.api.getAllCollegeBranchesPassingYearsDivisions(0, 0, '', '', filter).subscribe(data => {
    //   this.divisions = data['data'];
    //   console.log("divisions")
    //   console.log(this.divisions)
    //   this.loadingDivisions = false
    // }, err => {
    //   console.log(err);
    // });


  }

  getBatchDetailsData() {
    this.loadbatchDetailsFilter = true
    this.loadingFilter = true

    let filterQuery = ""
    console.log(this.filterBranches)
    console.log(this.filterPassingYear)
    console.log(this.filterDivision)

    //Branch Filter

    let branchesString: string = "";
    if (this.filterBranches != undefined) {
      this.filterBranches.forEach(branches => {
        branchesString += "'" + branches + "'" + ",";
      }); (this.filterBranches)

      if (branchesString.toString() != "") {
        if (branchesString.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " AND BRANCH_ID IN (" + branchesString.substring(0, branchesString.length - 1) + ") ";
        }
      }
    }

    let passingyearstring: string = "";
    if (this.filterPassingYear != undefined) {
      this.filterPassingYear.forEach(passingYears => {
        passingyearstring += "'" + passingYears + "'" + ",";
      }); (this.filterPassingYear)

      if (passingyearstring.toString() != "") {
        if (passingyearstring.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " AND PASSING_YEAR_ID IN (" + passingyearstring.substring(0, passingyearstring.length - 1) + ") ";
        }
      }
    }

    let divisionsString: string = "";
    if (this.filterDivision != undefined) {
      this.filterDivision.forEach(divisions => {
        divisionsString += "'" + divisions + "'" + ",";
      }); (this.filterDivision)

      if (divisionsString.toString() != "") {
        if (divisionsString.match("AL")) {
          filterQuery = filterQuery;
        }
        else {
          filterQuery += " AND DIVISION_ID IN (" + divisionsString.substring(0, divisionsString.length - 1) + ") ";
        }
      }
    }



    if (filterQuery == "") {
      filterQuery = " AND COLLEGE_ID=" + this.collegeId
    }
    else {
      filterQuery = " AND COLLEGE_ID=" + this.collegeId + filterQuery
      // filterQuery = " AND COLLEGE_ID="+this.collegeId+ filterQuery.substring(0, filterQuery.length - 5);
    }
    console.log(filterQuery)
    this.api.getAssignmentBatches(0, 0, '', '', filterQuery).subscribe(data => {
      console.log("batch Details Data")
      console.log(data)
      this.batchDetailsData = data['data'];
      this.loadingFilter = false
      this.loadbatchDetailsFilter = false
    }, err => {
      console.log(err);
    });
  }

  checkAllBranchesChanged(value, data) {
    if (value) {
      data.forEach(element => {
        element['IS_SELECTED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_SELECTED'] = 0
      });
    }
  }

  checkAllIsPublishChanged(value, data) {
    if (value) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['IS_PUBLISH_ENABLED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_PUBLISH_ENABLED'] = 0
      });
    }
  }

  checkAllIsResultTimeSharingChanged(value, data) {
    if (value) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['IS_RESULT_SHARING_ENABLED'] = 1
      });
    }
    else {
      data.forEach(element => {
        element['IS_RESULT_SHARING_ENABLED'] = 0
      });
    }
  }

  getChangeEnableTime(enableTime, data) {
    if (enableTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['ENABLE_TIME'] = enableTime
      });
    }
    else {
      data.forEach(element => {
        element['ENABLE_TIME'] = undefined
      });
    }
  }

  getChangeDisableTime(disableTime, data) {
    if (disableTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1)
          element['DISABLE_TIME'] = disableTime
      });
    }
    else {
      data.forEach(element => {
        element['DISABLE_TIME'] = undefined
      });
    }
  }

  getChangeResultSharingTime(resultsharingTime, data) {
    if (resultsharingTime != undefined) {
      data.forEach(element => {
        if (element['IS_SELECTED'] == 1 && element['IS_RESULT_SHARING_ENABLED'] == 1)
          element['RESULT_SHARING_TIME'] = resultsharingTime
      });
    }
    else {
      data.forEach(element => {
        element['RESULT_SHARING_TIME'] = undefined
      });
    }
  }

  getChangePublishedTime(publishedTime, data) {
    if (publishedTime != undefined) {

      data.forEach(element => {
        if (element['IS_SELECTED'] == 1 && element['IS_PUBLISH_ENABLED'] == 1)
          element['PUBLISH_TIME'] = publishedTime
      });
    }
    else {
      data.forEach(element => {
        element['PUBLISH_TIME'] = undefined
      });
    }
  }


  changeBatchCheck(value, data) {
    if (value)
      data.IS_SELECTED = 1
    else
      data.IS_SELECTED = 0
  }

  changePublished(value, data) {
    if (value)
      data.IS_PUBLISH_ENABLED = 1
    else
      data.IS_PUBLISH_ENABLED = 0
  }

  changeResultSharingTime(value, data) {
    if (value)
      data.IS_RESULT_SHARING_ENABLED = 1
    else
      data.IS_RESULT_SHARING_ENABLED = 0
  }

  // disabledDate = (current: Date): boolean => {
  //   // Can not select days before today and today

  //   return differenceInCalendarDays(current, this.today) < 0;
  // };
  getSharingTime(value) {
    if (!value) {
      this.RESULT_SHARING_TIME = ""
    }
  }


  save() {

    var finalData = []

    if (this.scope == "O") {
      if (new Date(this.ENABLE_TIME) >= this.today || new Date(this.DISABLE_TIME) >= this.today || new Date(this.RESULT_SHARING_TIME) >= this.today) {

        if (new Date(this.DISABLE_TIME) >= new Date(this.ENABLE_TIME) || (new Date(this.RESULT_SHARING_TIME) >= new Date(this.DISABLE_TIME) && new Date(this.RESULT_SHARING_TIME) >= new Date(this.ENABLE_TIME))) {
          this.ENABLE_TIME = this.datePipe.transform(this.ENABLE_TIME, "yyyy-MM-dd HH:mm:ss")
          this.DISABLE_TIME = this.datePipe.transform(this.DISABLE_TIME, "yyyy-MM-dd HH:mm:ss")
          var isSharingTime = this.IS_RESULT_SHARING_TIME ? 1 : 0
          if (this.RESULT_SHARING_TIME != "")
            this.RESULT_SHARING_TIME = this.datePipe.transform(this.RESULT_SHARING_TIME, "yyyy-MM-dd HH:mm:ss")
          else
            this.RESULT_SHARING_TIME = null

          finalData = [{ ENABLE_TIME: this.ENABLE_TIME, DISABLE_TIME: this.DISABLE_TIME, IS_RESULT_SHARING_ENABLED: isSharingTime, RESULT_SHARING_TIME: this.RESULT_SHARING_TIME, PASSKEY: this.PASSKEY, LINK: this.LINK, CLIENT_ID: this.api.clientId }]
          this.saveMapping(finalData)
        }
        else {
          this.message.error("Please Check Time...", "");
        }
      }
      else {
        this.message.error("Invalid Date Selected ...", "");
      }
    }
    else {
      if (this.NAME != "") {
        this.batchDetailsData.forEach(element => {
          element['ENABLE_TIME'] = this.datePipe.transform(element['ENABLE_TIME'], "yyyy-MM-dd HH:mm:ss")
          element['DISABLE_TIME'] = this.datePipe.transform(element['DISABLE_TIME'], "yyyy-MM-dd HH:mm:ss")
          element['RESULT_SHARING_TIME'] = this.datePipe.transform(element['RESULT_SHARING_TIME'], "yyyy-MM-dd HH:mm:ss")
          element['PUBLISH_TIME'] = this.datePipe.transform(element['PUBLISH_TIME'], "yyyy-MM-dd HH:mm:ss")
        });
        finalData = this.batchDetailsData
        this.saveMapping(finalData)
      }
      else {
        this.message.error("Please Enter Name ...", "");
      }
    }
  }

  saveMapping(finalData) {
    this.loadingSaveClose = true
    this.api.addAssignmentMapping(this.NAME, this.scope, this.assignmentId, finalData)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.message.success("Assignment Mapping Successfully ...", "");
          this.loadingSaveClose = false
          this.drawerClose()
          this.NAME = ""
        }
        else {
          this.message.error("Assignment Mapping Failed...", "");
          this.loadingSaveClose = false
        }
      }, err => {
        console.log(err);
        this.message.error("Server Error...", "");
        this.loadingSaveClose = false
      });
  }


}
