import { Component, OnInit, ViewChild } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CookieService } from 'ngx-cookie-service';
import { Shikuyaaquestion } from 'src/app/Models/ShikuyaaModels/shikuyaaquestion';
import { ApiService } from 'src/app/Service/api.service';
import { ShikuyaaquestionComponent } from '../shikuyaaquestion/shikuyaaquestion.component';
import { Level } from 'src/app/Models/MasterSyllabus/level';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-shikuyaaquestions',
  templateUrl: './shikuyaaquestions.component.html',
  styleUrls: ['./shikuyaaquestions.component.css']
})
export class ShikuyaaquestionsComponent implements OnInit {
  formTitle = "Manage Questions";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  companyVisible:boolean=false
  grpType=''
  COURSE_ID=0
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
  logtext: string = "";
  ChaptersNodes=[]
  isloadSpinning=false
  @ViewChild(ShikuyaaquestionComponent, { static: false }) question1: ShikuyaaquestionComponent;
  columns: string[][] = [["CHAPTER_NAME", "Chapter Name"],["COMPANY_NAME", "Company Name"],["CATEGORY_NAME", "Category Name"], ["QUESTION_TYPE_NAME", "Question Type Name"], ["LEVEL_NAME", "Level Name"], ["QUESTION", "Question"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Shikuyaaquestion = new Shikuyaaquestion();
  filterValue=[]
  filterClass: string = "filter-invisible";
  imageDirectionurl:any
  imageDescriptionurl:any
  imageParagraphurl:any
  imageQuestionurl:any
  imageSolutionurl:any
  pageSize2=10
  levels: Level[]
  constructor( private message: NzNotificationService,public api: ApiService, private cookie: CookieService) { }
  ngOnInit() {

    if (this.userId == null || this.userName == null || this.roleId == null) {
      this.api.logoutForSessionValues()
    }
    else {
      this.search();
      this.loadAllChapters()
      this.logtext = "OPENED - Questions form KEYWORD[O - Questions] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {

          }
          else {
          }
        });
    }
    this.loadCategories()
    this.loadCompanies()
    this.loadCats()
  }
  cats=[]
  loadCats() {

    
    // this.loadingLevels = true;
    this.api.getAllQueCategory(0,0,"",""," ").subscribe(localName => {
      this.cats = localName['data'];
      console.log("companies")
      // console.log(this.companies)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });

  }
  checkQue(event:any)
  {
if(event=='F')
{
  this.companyVisible=true
}
else{
  this.companyVisible=false
}
  }
  companies=[]
  loadCompanies() {

    
    this.isSpinning = true;
    this.api.getAllCompanys(0,0,"",""," ").subscribe(localName => {
      this.companies = localName['data'];
      console.log("companies")
      console.log(this.companies)
      this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });

  }
  CHAPTER_ID=0
  COMPANY_ID=0
  CATEGORY=0
  courseID=0
  chapters = []
  isSpinning:boolean
  loadchapters(courseId) {
 
    this.isSpinning = true;
    this.api.getParentChaptersNew(courseId).subscribe(localName => {
      this.chapters = localName['data'];
      console.log(this.chapters)
       this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }
  ChangeCourse(courseId)
  {

    this.loadchapters(courseId)
  }
  levels1
  loadingLevels = false;
  loadCategories()
  {
    this.api.getCategoryHierarchy().subscribe(localName => {
      this.levels1 = localName['data'];
      console.log("courses")
      // console.log(this.levels)
      // this.loadingLevels = false;
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
  }
  loadCourses(event:any) {

    // this.isSpinning = true;
    // this.api.getCombinedCourses().subscribe(localName => {
    //   this.courseNodes = localName['data'];
    //   console.log("courses")
    //   console.log(this.courseNodes)
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });

    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND COURSE_SUB_CATEGORY_ID="+event).subscribe(localName => {
      this.levels = localName['data'];
      console.log("courses")
      console.log(this.levels)
      this.loadingLevels = false;
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });

  }
  // Basic Methods
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'id';
    const sortOrder = (currentSort && currentSort.value) || 'desc';
    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize2 != pageSize) {
      this.pageIndex = 1;
      this.pageSize2 = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  searchText1=''
  onKeyDownEvent(event)
  {this.isFilterApplied = "default";
  this.filterClass = "filter-invisible";
this.search(true)

  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

      this.logtext = "Filter Applied - Questions form "+ sort +" "+this.sortKey +" KEYWORD [F - Questions] ";
      this.api.addLog('A', this.logtext, this.api.emailId)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
          }
          else {
          }
        });

    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      this.searchText1=this.searchText.replace(/\\r/g, '')
      .replace(/\\n/g, '')
      .replace(/\\t/g, '')
      
      .replace(/\\"/g, '&#34;')
      .replace(/\\'/g, '&#39;')
  .replace(/\"/g, '&#34;')
      .replace(/\'/g, '&#39;')
  .replace(/'/g,'&#39;')
  .replace(/’/g,'&#39;')
  .replace(/’/g,'&#39;')
  .replace(/`/g,'&#39;')
  .replace(/'/g,'&#39;')
  .replace(/‘/g,'&#39;')
      .replace(/"/g,'&#34;')
  .replace(/"/g,'&#34;')
  .replace(/“/g,'&#34;')
  .replace(/”/g,'&#34;')
  .replace(/"/g,'&#34;')
      .replace(/\[/g, '&#91;')
      .replace(/\]/g, '&#93;');
      console.log(this.searchText1)
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " +column[0]  + " like '%" + this.searchText1 + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    // this.applyFilter()
    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

    this.logtext = "Filter Applied - Questions form "+ filter +" KEYWORD [F - Questions] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
        }
        else {
        }
      });
      this.isloadSpinning=true

      console.log("filterQuery")
      console.log(filter)
      this.api.getAllShikuyaaQuestionss1(this.pageIndex, this.pageSize, this.sortKey, sort, filter,this.grpType,this.COURSE_ID,this.courseID,this.CHAPTER_ID,this.COMPANY_ID,this.CATEGORY).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.isloadSpinning=false
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        // this.isFilterApplied = "default";
        this.filterClass = "filter-invisible";
      }, err => {
        console.log(err);
      });

    // this.api.getAllShikuyaaQuestions(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
    //   console.log(data)
    //   this.loadingRecords = false;
    //   this.isloadSpinning=false
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    //   // this.isFilterApplied = "default";
    //   this.filterClass = "filter-invisible";
    // }, err => {
    //   console.log(err);
    // });
  }
 
  applyFilter() {
    this.loadingRecords=true
    this.filterClass = "filter-invisible";
if(this.grpType!='' || this.courseID!=0 || this.COURSE_ID!=0 || this.CHAPTER_ID!=0 || this.COMPANY_ID!=0 || this.CATEGORY!=0)
{
  this.api.getAllShikuyaaQuestionss1(this.pageIndex, this.pageSize, this.sortKey, '', '',this.grpType,this.COURSE_ID,this.courseID,this.CHAPTER_ID,this.COMPANY_ID,this.CATEGORY).subscribe(data => {
    console.log(data)
    this.loadingRecords = false;
    this.isloadSpinning=false
    this.totalRecords = data['count'];
    this.dataList = data['data'];
    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
  }, err => {
    console.log(err);
  });
}
  else if(this.grpType=='' && this.COURSE_ID==0 && this.courseID==0 && this.CHAPTER_ID==0 && this.COMPANY_ID==0 && this.CATEGORY==0)
  this.message.error('Please selecte filter','')
}

  clearFilter() {
    this.filterValue=[]
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.courseID=0
    this.COURSE_ID=0
    this.CHAPTER_ID=0
    this.grpType=''
    this.COMPANY_ID=0
    this.CATEGORY=0
    
    this.search(true)
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  loadAllChapters() {
    this.api.getAllShikyaaChapterMultiselect().subscribe(localName => {
      this.ChaptersNodes = localName['data'];
      console.log("chapter multi slect")
      console.log(this.ChaptersNodes)
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  val:string='false'
  add(): void {
    this.checks=''
    this.checks1=''
    this.val='false'
    this.drawerTitle = "Create New Question";
    this.drawerData = new Shikuyaaquestion();
    this.drawerVisible = true;
    
    this.setValues()
    this.question1.reset()
    this.logtext = "ADD - Question form KEYWORD [A - Question] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
        }
        else {
        }
      });

  }
  level2
  courseName
  catName
  checks
  checks1
  edit(data: Shikuyaaquestion): void {
this.checks='edit'
this.checks1='edit'
    this.question1.reset()
    localStorage.setItem('visible','visible')
    console.log(data)
    this.drawerData = Object.assign({}, data);
    this.drawerData.COURSE_SUB_CATEGORY_ID=data.COURSE_SUB_CATEGORY_ID
    this.drawerData.COURSE_ID=data.COURSE_ID
    console.log(  this.drawerData.COURSE_SUB_CATEGORY_ID, this.drawerData.COURSE_ID)
   if(data.GROUP_TYPE=='F')
   {
    this.val='true'
   }
   if(data.GROUP_TYPE=='P' || data.GROUP_TYPE=='M'){
    this.val='false'
    
   }

    this.drawerTitle = "Update Question";
    this.question1.typeChange(data.QUESTION_TYPE_ID)
    // this.question1.loadCategoriesID(  this.drawerData.COURSE_SUB_CATEGORY_ID)
    this.api.getCategoryHierarchys(this.drawerData.COURSE_SUB_CATEGORY_ID).subscribe(localName => {
      this.catName=localName['data'][0]['title']
   
    }, err => {
      console.log(err);
      // this.loadingLevels = false;
    });
    this.question1.loadSubChpater(data.COURSE_ID)
    this.question1.getOptionList(data.ID)
    this.loadingLevels = true;
    this.api.getAllCourses(0,0,"ID","desc"," AND COURSE_TYPE_ID=2 AND ID="+this.drawerData.COURSE_ID).subscribe(localName => {
     this.courseName=localName['data'][0]['NAME']
    }, err => {
      console.log(err);
      this.loadingLevels = false;
    });
    this.drawerVisible = true;
    this.logtext = "EDIT - Question form KEYWORD [E - Question] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
        }
        else {
        }
      });

  }

  setValues() {
    // this.drawerData.DIRECTION = "No"
    // this.drawerData.DESCRIPTION = "No"
    // this.drawerData.SOLUTION = "No"
    // this.drawerData.SOLUTION_VIDEO_URL = "No"
 
    this.drawerData.IS_RANDOMIZATION_OPTION = true
    this.drawerData.IS_SEQUENCE_MARKING = true
    this.question1.OptionList=[]
    this.question1.urlList=[]
  }
  
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}