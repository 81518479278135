import { Component, OnInit, Input } from '@angular/core';
import { Designation } from 'src/app/Models/CollegeOnboarding/designation';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService} from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Designation;
  @Input() dataList;

  isSpinning = false
  logtext: string = "";
  userId = sessionStorage.getItem('userId');

  constructor(public api: ApiService, private message: NzNotificationService, private cookie: CookieService) {
  }

  ngOnInit() {

  }



  close(): void {
    this.drawerClose();

    this.logtext = 'CLOSED - Designation form';
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

  }

  save(addNew: boolean): void {   

   if(this.data.NAME!=undefined && this.data.NAME!="")
   {

    if(/^[a-zA-Z\s-,._-]*$/.test(this.data.NAME) == true)  
    {
      if(this.data.DESCRIPTION=="")
      this.data.DESCRIPTION=" "
  
      if (this.data.ID) {

        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase() && object['ID']!=this.data.ID
        });
    
        if(filterData.length>0)
        {
        this.message.error("Already Record Present in Database", "");
        }
        else
        {
          this.isSpinning = true;
          this.api.updateDesignation(this.data)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Designation information updated Successfully...", "");
    
                this.logtext = 'Update & Close - Designation form - SUCCESS ' + JSON.stringify(this.data) + " KEYWORD [U - Designation ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
    
                if (!addNew)
                  this.drawerClose();
                this.isSpinning = false;
              }
              else {
    
                this.logtext = 'Update & Close - Designation form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [U - Designation ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") {
                      console.log(successCode);
                    }
                    else {
                      console.log(successCode);
                    }
                  });
    
                this.message.error("Failed to update designation information...", "");
                this.isSpinning = false;
              }
            });
        }
      }
      else {

        var filterData= this.dataList.filter(object => {
          return object['NAME'].toLowerCase() == this.data.NAME.toLowerCase();
        });
  
       if(filterData.length>0)
       {
        this.message.error("Already Record Present in Database", "");
       }
       else
       {
        
        this.isSpinning = true;
        this.api.createDesignation(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Designation information added successfully...", "");
  
              if (!addNew)
              {
              this.drawerClose();
              this.logtext = 'Save & Close - Designation form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Designation ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    console.log(successCode);
                  }
                  else {
                    console.log(successCode);
                  }
                });
              }
                else {
                this.data = new Designation();
                this.setValues()
                this.api.getAllDesignations(0, 0, 'ID', 'desc', '').subscribe(data => {
                  this.dataList = data['data'];
                }, err => {
                  console.log(err);
                });
                this.logtext = 'Save & New - Designation form - SUCCESS - ' + JSON.stringify(this.data) + " KEYWORD [C - Designation ]";
                this.api.addLog('A', this.logtext, this.api.emailId)
                  .subscribe(successCode => {
                    if (successCode['code'] == "200") 
                      console.log(successCode);
                    else
                     console.log(successCode);
                  });
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add designation information...", "");
              this.isSpinning = false;
              this.logtext = 'Save & Close - Designation form - ERROR - ' + JSON.stringify(this.data) + " KEYWORD [C - Designation ]";
              this.api.addLog('A', this.logtext, this.api.emailId)
                .subscribe(successCode => {
                  if (successCode['code'] == "200") 
                    console.log(successCode);
                  else 
                    console.log(successCode);
                });
            }
          });

       }
      
        }
    }
    else
    {
      this.message.error("Please Check Name", "");

    }

   
  
    }
  else
  {
    this.message.error("Please Fill All Required Fields", "");
  }
  }

  setValues()
  {
    this.data.IS_ACTIVE=true
  }

}