// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCPJ6QTPxdO8Y_JbSfTCE2BAanXNbDI8uE",
    authDomain: "shikuyaa-rn.firebaseapp.com",
    databaseURL: "https://shikuyaa-rn.firebaseio.com",
    projectId: "shikuyaa-rn",
    storageBucket: "shikuyaa-rn.appspot.com",
    messagingSenderId: "691912959817",
    appId: "1:691912959817:web:c5e894f53cb59205db5c98",
    measurementId: "G-77H3P19JL5"
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
