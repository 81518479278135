import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BundleMaster } from 'src/app/Models/AssessmeentModels/bundlemaster';
import { CollegeMap } from 'src/app/Models/AssessmeentModels/collegemap';
import { ApiService } from 'src/app/Service/api.service';
import * as moment from 'moment';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({
  selector: 'app-collegesmap',
  templateUrl: './collegesmap.component.html',
  styleUrls: ['./collegesmap.component.css']
})
export class CollegesmapComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  // data: BundleMaster = new BundleMaster;
  @Input() data: any;
  @Input() data3: CollegeMap;
  @Input() data2: BundleMaster= new BundleMaster();

  @Input()
  drawerVisible: boolean = false;
  @Input() dataList: CollegeMap[] = [];
  @Input() isSpinning = false;
  isOk=true;
  isCount=true;

  emailpattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt=/[a-zA-Z][a-zA-Z ]+/
  mobpattern=/^[6-9]\d{9}$/ 
  @Input() totalRecords = 1;
  loadingRecords = false
  // collegeName: CollegeMap[] = [];
  // dataList: any[]=[]
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc"; 
  sortKey: string = "";
  searchText: string = "";
  @Input() checked = false;
  @Input() indeterminate = false;
  @Input() setOfCheckedId = new Set<number>();
  @Input() startdate:any
  @Input() enddate:any

  // totalRecords=1
  columns: string[][] = [["COLLEGE_ID", " College Name "],["NAME","College Name"]]

  constructor(private api:ApiService, private message: NzNotificationService, private datePipe:DatePipe) { }

 

  ngOnInit(): void {
    this.loadColleges()
    // alert(this.data.ID)
    // this.isSpinning = true;
    // this.isSpinning = false;

    // this.startdate = localStorage.getItem("startdate")
    // this.enddate = localStorage.getItem("enddate")
  
    
  }
  keyup(event){
    this.search()
  }
   //Colleges Name
   loadColleges() {
    console.log('this.startdate',this.startdate);
    console.log('this.enddate',this.enddate);   
    // this.isSpinning = true;
    // this.api.getAllMappedCollege(this.pageIndex,this.pageSize,'','', this.data.ID,'').subscribe(
    //   (data) => {
    //     this.isSpinning = false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];        
    //   },
    //   (err) => {
    //     console.log(err);
    //     this.isSpinning = false;
    //   }
    // );
  }
  // close(): void {
  //   this.drawerClose();
  // }

   //// Only number
   omit(event:any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ////

  resetDrawer(collegeMapPage: NgForm) {
    this.data=new CollegeMap();
    // collegeMapPage.form.reset();

    collegeMapPage.form.markAsPristine();
    collegeMapPage.form.markAsUntouched();
  }

  close(collegeMapPage: NgForm) {
    this.drawerClose();
    this.searchText = "";
    this.resetDrawer(collegeMapPage);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    // if (this.searchText != "") {
    //   likeQuery = " AND";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    //   console.log("likeQuery" + likeQuery);
    // }
    this.isSpinning = true;
    this.api.getAllMappedCollege(this.pageIndex,this.pageSize,this.sortKey, sort, this.data.ID,this.searchText).subscribe(
      (data) => {
        this.isSpinning = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];   
        this.dataList.forEach((item) => {
          if (item.IS_ACTIVE) {
            this.setOfCheckedId.add(item.COLLEGE_ID);
          } else {
            this.setOfCheckedId.delete(item.COLLEGE_ID);
          }
        });
        this.checked = this.dataList.every((item) => {
          this.setOfCheckedId.has(item.COLLEGE_ID);
        });
        this.indeterminate =
          this.dataList.some((item) =>
            this.setOfCheckedId.has(item.COLLEGE_ID)
          ) && !this.checked;
        console.log('this.data.BUNDLE_MAPPING_ID',this.dataList);     
      },
      (err) => {
        console.log(err);
        this.isSpinning = false;
      }
    );
  }
  //save
  save(addNew: boolean,collegeMapPage: NgForm): void {
//     console.log('CID:',this.data.COLLEGE_ID);
//     console.log('Valid:',this.data.START_DATE);
//     console.log('To:',this.data.END_DATE);
//     console.log('Count:',this.data.STUDENT_COUNT);
//     this.isSpinning = false;
var count  = 0
    this.isOk=true;
    this.isCount=true;


//     if (this.data.COLLEGE_ID==0  && this.data.START_DATE!= undefined 
//       && this.data.END_DATE!= undefined && this.data.STUDENT_COUNT==0 ){
//       this.isOk =false 
//      this.message.error(" Please fill all required fields", ""); 
//     }else

//     if(this.data.COLLEGE_ID.length==0 ){
//       this.isOk =false
//       this.message.error('Please select college name','')
//     }else 

//     if(this.data.START_DATE== undefined || this.data.START_DATE== null){
//      this.isOk =false
//      this.message.error('Please select valid from date','')
//     }else 

//     if(this.data.END_DATE== undefined || this.data.END_DATE== null){
//      this.isOk =false
//      this.message.error('Please select valid to date','')
//     }else 

//     if(this.data.STUDENT_COUNT== undefined || this.data.STUDENT_COUNT<=0){
//      this.isOk =false
//      this.message.error('Please enter maximum student count','')
// }
for (var i = 0; i < this.dataList.length; i++) {
  if (this.dataList[i].IS_ACTIVE == true) {
    if (
      this.dataList[i].START_DATE == null ||
      this.dataList[i].END_DATE == null || this.dataList[i].MAX_STUDENTS <= 0
    ) {
      this.isOk = false;
    }
    // count ++;
  }

  if ((i + 1) == this.dataList.length) {
    if (this.isOk == false) {
      this.message.error(
        "Please Select Both Enable Date, Disable Date and Max. Student Count",
        ""
      );
    } else {
    for (var j = 0; j < this.dataList.length; j++) {
      if (this.dataList[j].PICKED_STUDENTS > this.dataList[j].MAX_STUDENTS) {
        this.isCount = false;
      }    
    }  if (this.isCount == false) {
      this.message.error(
        "Max Student Count Is Less Than Bundle Picked Student Count.",
        ""
      );
    } else
    {
      
        // this.isSpinning=false;
        // this.data.LATITUDE = this.latitude.toString();
        // this.data.LONGITUDE   = this.longitude.toString();
        console.log('this.dataList.length',this.dataList);
        this.isSpinning = true;
        if(this.data.ID)
        {
          
          
          for(var i=0;i< this.dataList.length; i++){
          if (this.dataList[i].START_DATE==undefined){
            this.dataList[i].START_DATE=null;
          }else{
            this.dataList[i].START_DATE=this.datePipe.transform(this.dataList[i].START_DATE,"yyyy-MM-dd HH:mm:ss"); 
          }
          if (this.dataList[i].END_DATE==undefined){
            this.dataList[i].END_DATE=null;
          }else{
            this.dataList[i].END_DATE=this.datePipe.transform(this.dataList[i].END_DATE,"yyyy-MM-dd HH:mm:ss"); 
          }
          if (this.dataList[i].MAX_STUDENTS<=0){
            this.dataList[i].MAX_STUDENTS=0;
          }else{
            this.dataList[i].MAX_STUDENTS = this.dataList[i].MAX_STUDENTS
          }
        }
            this.api.updateCollegeMapping(this.dataList,this.data.ID)
        
            .subscribe(successCode => {
              if(successCode.code=="200")
              {
                this.message.success(' Information updated successfully...', '');
                if(!addNew)
                  this.drawerClose();
                  this.searchText = ""
                  this.isSpinning = false;
              }   
              else
              {
                this.message.error(' Failed to update information...', '');
                this.isSpinning = false;
              }
            });
          }
          else{
          
              this.api.createCollegeMapping(this.data)
              .subscribe(successCode => {
                if(successCode.code=="200")
                {
                  this.message.success(' Information save successfully...', '');
                  this.searchText = ""
                  if(!addNew)
                 this.drawerClose();
                    else
                    {
                      this.data=new CollegeMap();
                      this.resetDrawer(collegeMapPage);
                      // this.data.img= '';
                      
                      this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
                        // if (data['count']==0){
                        //   this.data.SEQUENCE_NO=1;
                        // }else
                        // {
                        //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                        // }
                      },err=>{
                        console.log(err);
                      })
                    }
                    this.isSpinning = false;
                  }
                   else
                   {
                    this.message.error(' Failed to save information...', '');
                    this.isSpinning = false;
                   }
                  });
                }         
      
    }
  }
  }
}

// if(this.isOk)
// {
//   // this.isSpinning=false; 
//   // this.data.LATITUDE = this.latitude.toString();
//   // this.data.LONGITUDE   = this.longitude.toString();
 
//   this.isSpinning=true; 
// if(this.data.ID)
// {
//   console.log('this.dataList.length',this.dataList);
  
//   for(var i=0;i< this.dataList.length; i++){
//   if (this.dataList[i].START_DATE==undefined){
//     this.dataList[i].START_DATE=null;
//   }else{
//     this.dataList[i].START_DATE=this.datePipe.transform(this.dataList[i].START_DATE,"yyyy-MM-dd HH:mm:ss"); 
//   }
//   if (this.dataList[i].END_DATE==undefined){
//     this.dataList[i].END_DATE=null;
//   }else{
//     this.dataList[i].END_DATE=this.datePipe.transform(this.dataList[i].END_DATE,"yyyy-MM-dd HH:mm:ss"); 
//   }
//   if (this.dataList[i].MAX_STUDENTS<=0){
//     this.dataList[i].MAX_STUDENTS=0;
//   }else{
//     this.dataList[i].MAX_STUDENTS = this.dataList[i].MAX_STUDENTS
//   }
// }
//     this.api.updateCollegeMapping(this.dataList,this.data.ID)

//     .subscribe(successCode => {
//       if(successCode.code=="200")
//       {
//         this.message.success(' Information updated successfully...', '');
//         if(!addNew)
//           this.drawerClose();
//           this.searchText = ""
//           this.isSpinning = false;
//       }   
//       else
//       {
//         this.message.error(' Failed to update information...', '');
//         this.isSpinning = false;
//       }
//     });
//   }
//   else{
  
//       this.api.createCollegeMapping(this.data)
//       .subscribe(successCode => {
//         if(successCode.code=="200")
//         {
//           this.message.success(' Information save successfully...', '');
//           this.searchText = ""
//           if(!addNew)
//          this.drawerClose();
//             else
//             {
//               this.data=new CollegeMap();
//               this.resetDrawer(collegeMapPage);
//               // this.data.img= '';
              
//               this.api.getAllBundle(1,1,'','desc','').subscribe (data =>{
//                 // if (data['count']==0){
//                 //   this.data.SEQUENCE_NO=1;
//                 // }else
//                 // {
//                 //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
//                 // }
//               },err=>{
//                 console.log(err);
//               })
//             }
//             this.isSpinning = false;
//           }
//            else
//            {
//             this.message.error(' Failed to save information...', '');
//             this.isSpinning = false;
//            }
//           });
//         }
// }

// else
// {
//   this.message.error("Please Fill All Required Fields...","");
//   this.isSpinning = false;
// }
  }
  sort(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || ''; 
    // const sortOrder = (currentSort && currentSort.value) || 'asc';
    const sortOrder = (currentSort && currentSort.value) || 'desc';

    console.log(currentSort)

    console.log("sortOrder :"+sortOrder)
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if(this.pageSize != pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    }    
    
    if( this.sortKey != sortField) {
      this.pageIndex = 1;
      this.pageSize =pageSize;
    }

    this.sortKey = sortField;
    this.sortValue = sortOrder;
    this.search();
  }
  
  onItemChecked(id: number, checked: boolean, ind: any): void {
    console.log(id,ind,checked,'onItemChecked')
    if (checked) {
      this.dataList[ind].IS_ACTIVE = true
      // var a = []
      // var b = a.push(id.toString().length)
      // // var b =localStorage.setItem("id",c.toString())
      // // console.log(b);
      
      // console.log('a',b);
      
    } else {
      // this.dataList[ind].IS_ACTIVE = false
      this.dataList[ind].IS_ACTIVE = false;
      this.dataList[ind].START_DATE = null;
      this.dataList[ind].END_DATE = null;
      this.dataList[ind].MAX_STUDENTS = 0;

    }
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.dataList.forEach(item => {
      if (value) {
        console.log('Value:',value);
        
        item.IS_ACTIVE = true
      } else {
        console.log('Value2:',value);

        item.IS_ACTIVE = false
      }
      this.updateCheckedSet(item.COLLEGE_ID, value)
    });
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    console.log(id,checked,'ggg2')
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.dataList.every(item => {
      // console.log(this.setOfCheckedId.has(item.COLLEGE_ID),'ggg33')
      this.setOfCheckedId.has(item.COLLEGE_ID)
    });
    this.indeterminate = this.dataList.some(item => this.setOfCheckedId.has(item.COLLEGE_ID)) && !this.checked;
  }
  
  today = new Date().setDate(new Date().getDate());


  disabledDate = (current: Date): boolean =>{
    var a =  differenceInCalendarDays(current, this.startdate);
    var b = differenceInCalendarDays(current, this.enddate);
      
    if(a < 0  || b > 0)
    return true
    else
    return false;
  }
 
  
}
