export class CollegeMap {
    ID:number=0;
    CLIENT_ID:number
    COLLEGE_ID:any;
    START_DATE:any=new Date();
    END_DATE:any=new Date();
    STUDENT_COUNT:number=0;
    IS_ACTIVE:boolean=false;
    MAX_STUDENTS:number=0
    PICKED_STUDENTS:number=0;
}

 