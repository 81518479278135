import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Collegedocument } from 'src/app/Models/CollegeOnboarding/collegedocument';
import { DocumentComponent } from '../document/document.component';
import { CollegetypemappingComponent } from '../../collegetypemapping/collegetypemapping.component';
import { College } from 'src/app/Models/CollegeOnboarding/college';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  @Input() drawerCloseDocuments: Function;
  @Input() COLLEGE_ID: number

  filterQuery: string = ""
  dataList: Collegedocument[]
  documentData: Collegedocument = new Collegedocument();
  DocumentdrawerVisible: boolean
  DocumentdrawerTitle: string = ""
  logtext: string = "";
  userId = Number(this.cookie.get('userId'));
  loadingDocuments = false
  searchText: string = ""
  imageurl: any

  collegeTypedrawerData: []
  collegeDrawerData: College = new College();
  collegeTypeDrawerVisible: boolean
  collegeTypedrawerTitle: string = ""

  columns: string[][] = [['DOCUMENT_TYPE_ID', 'Number'], ['DOCUMENT_URL', 'Number'], ['DOCUMENT_TYPE_NAME']]

  @ViewChild(DocumentComponent, { static: false }) document: DocumentComponent;
  isSpinning=false

  constructor(public api: ApiService, private cookie: CookieService,private message: NzNotificationService) { }

  ngOnInit() {
  }

  search(collegeId) {
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"
    }

    this.loadingDocuments = true
    var filter = ""
    this.filterQuery = "AND COLLEGE_ID=" + collegeId
    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

    console.log(filter)

    this.logtext = "Filter Applied - CollegeDocument form "+ filter +" KEYWORD [F - CollegeDocument] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });

    this.api.getAllCollegeDocuments(0, 0, '', '', filter).subscribe(data => {
      console.log(data['data'])
      this.dataList = data['data'];
      this.loadingDocuments = false
    }, err => {
      console.log(err);
    });
  }

  close() {
    this.drawerCloseDocuments()
  }

  add() {
    this.DocumentdrawerTitle = "Create New Document";
    this.DocumentdrawerVisible = true;
    this.documentData = new Collegedocument();
    this.document.reset()
    //this.document.loadData()
    //this.setValues()
    this.logtext = "ADD - CollegeDocument form KEYWORD [A - CollegeDocument] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });


  }

  edit(data: Collegedocument) {
    this.document.reset()
    
    let usersIds = ""
    let filter = " AND COLLEGE_ID=" + data.COLLEGE_ID + " AND ARCHIVE_ID=" + data.ID
    this.api.getAllCollegeDocumentsSharing(0, 0, '', '', filter).subscribe(data1 => {
      console.log(data1['data'])
      data1['data'].forEach(element => {
        usersIds = usersIds + element['USER_ID'] + ","
      });
      let userId1 = usersIds.substring(0, usersIds.length - 1)
      var arrOfUserId = userId1.split(',').map(function (item) {
        return parseInt(item, 10);
      });
      data.USERS = arrOfUserId
      this.DocumentdrawerTitle = "Update Contact";
      this.documentData = Object.assign({}, data);
      this.DocumentdrawerVisible = true;
    }, err => {
      console.log(err);
    });




    this.logtext = "EDIT - CollegeContact form KEYWORD [E - CollegeContact] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
  }

  getUrl(url: string) {
    this.isSpinning=true
    console.log(url)
    this.api.getFile(url).subscribe(data => {
      console.log("response of file")
      console.log(data)
      if(data['code']==200)
      {
        const TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
          return data + String.fromCharCode(byte);
          }, '');
        let base64String = btoa(STRING_CHAR);
        const linkSource = "data:application/pdf;base64," + base64String;
        let pdfWindow = window.open("")
pdfWindow.document.write("<iframe width='100%' height='100%' src='"+linkSource+"'></iframe>")
this.isSpinning=false

      }
      else
      {
      this.message.error(data['message'], "");
      }
     }, err => {
      console.log(err);
    });

      this.logtext = "VIEW Document - CollegeDocument form KEYWORD [E - CollegeDocument] ";
    this.api.addLog('A', this.logtext, this.api.emailId)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          console.log(successCode);
        }
        else {
          console.log(successCode);
        }
      });
 
  }

  DocumentdrawerClose(): void {
    console.log(this.COLLEGE_ID)
    this.DocumentdrawerVisible = false
    this.search(this.COLLEGE_ID)
  }

  get DocumentcloseCallback() {
    return this.DocumentdrawerClose.bind(this);
  }


  collegeTypeDrawerClose(): void {
    this.collegeTypeDrawerVisible = false
    this.search(this.COLLEGE_ID)
  }

  get collegeTypeCloseCallback() {
    return this.collegeTypeDrawerClose.bind(this);
  }

  manageTypes(collegeId) {
    
    this.api.getCollegeTypeMapping(collegeId).subscribe(data => {
      this.collegeTypedrawerData = data['data'];
      this.collegeTypedrawerTitle = "Type Mapping for " + this.dataList[0]['ABBREVATION'] + "";
      this.collegeDrawerData.ID = collegeId;
      this.collegeDrawerData.ABBREVATION = this.dataList[0]['ABBREVATION']
      this.collegeTypeDrawerVisible = true;
    }, err => {
      console.log(err);
    });

  }


  
}
