export class BundleMaster {
    ID:number=0;
    NAME:string='';
    DESCRIPTION:string="";
    IS_ACTIVE:boolean=true;
    THUMBNAIL_IMAGE: string='';
    BANNER_IMAGE: string='';
    SEQ_NO: number=0;

} 

 